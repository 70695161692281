export class ContactDetail {
  constructor(private _id: string, private _value: string, private _description: string, private _type: string) {}

  get id(): string {
    return this._id;
  }

  get value(): string {
    return this._value;
  }

  get description(): string {
    return this._description;
  }

  get type(): string {
    return this._type;
  }

  get valueHref(): string {
    return this.type === 'PHONE'
      ? `tel:${this.value}`
      : this.value;
  }

  static fromJson(json: ContactDetailJson): ContactDetail {
    return new ContactDetail(
      json.id,
      json.value,
      json.description,
      json.type
    );
  }
}

export interface ContactDetailJson {
  id: string;
  value: string;
  description: string;
  type: string;
}
