import { ConventionStatus } from '@enums/convention-status.enum';
import { Option } from '@interfaces/option.interface';
import { Specialty, SpecialtyJson } from '@models/specialty.model';

export class Doctor {
  constructor(
    private _id: string,
    private _riziv: string,
    private _firstName: string,
    private _lastName: string,
    private _convention: ConventionStatus,
    private _profession: string = null,
    private _qualification: string = null,
    private _qualificationDate: Date = null,
    private _userId: string = null,
    private _specialty: Specialty = null
  ) {}

  get id(): string {
    return this._id;
  }

  get riziv(): string {
    return this._riziv;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get fullName(): string {
    return `${this._firstName} ${this._lastName}`;
  }

  get profession(): string {
    return this._profession;
  }

  get convention(): ConventionStatus {
    return this._convention;
  }

  get qualification(): string {
    return this._qualification;
  }

  get qualificationDate(): Date {
    return this._qualificationDate;
  }

  get userId(): string {
    return this._userId;
  }

  get specialty(): Specialty {
    return this._specialty;
  }

  static fromJson(json: DoctorJson): Doctor {
    return new Doctor(
      json.id,
      json.riziv,
      json.first_name,
      json.last_name,
      json.convention,
      json.profession,
      json.qualification,
      new Date(json.qualification_date),
      json.user_id,
      json.specialty ? Specialty.fromJson(json.specialty) : null
    );
  }

  toOption(): Option {
    return {
      value: this.riziv,
      label: `${this.fullName} - ${this.riziv}`,
    };
  }
}

export interface DoctorJson {
  id: string;
  riziv: string;
  first_name: string;
  last_name: string;
  profession: string | null;
  convention: ConventionStatus;
  qualification: string | null;
  qualification_date: string | null;
  user_id: string | null;
  specialty: SpecialtyJson | null;
}
