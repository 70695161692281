import { Directive, EventEmitter, Output } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { MapViewport } from '@interfaces/map-viewport.interface';
import { GoogleApiService } from '@services/google-api.service';
import { LocationService } from '@services/location.service';
import { Observable, Subscription } from 'rxjs';
import { COORDINATES } from '@modules/booking/constants/country-coordinates';
import { ZOOM_LEVELS } from '@constants/zoom-levels.constant';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Directive()
export abstract class BaseMapComponent extends BreakpointObserverComponent {
  protected _geolocationErrorTranslationKey?: string;

  googleMapsIsInitializedSubscription: Subscription;
  googleMapsIsInitialized$: Observable<boolean>;

  mapComponent?: GoogleMap;
  mapCenter?: google.maps.LatLngLiteral;
  mapBounds?: google.maps.LatLngBounds;
  mapOptions?: google.maps.MapOptions;
  mapZoom?: number;

  userLocationMarker?: google.maps.Marker;
  userLocation?: GeolocationPosition;

  @Output() mapViewPortUpdated: EventEmitter<MapViewport> = new EventEmitter<MapViewport>();
  @Output() userLocationErrorOccurred: EventEmitter<string> = new EventEmitter<string>();
  @Output() userLocationUpdated: EventEmitter<GeolocationPosition> = new EventEmitter<GeolocationPosition>();

  constructor(
    protected readonly breakpointObserver: BreakpointObserver,
    protected readonly googleApiService: GoogleApiService,
    protected readonly locationService: LocationService,
    protected readonly translate: TranslateService
  ) {
    super(breakpointObserver);
    this.googleMapsIsInitialized$ = this.googleApiService.googleMapsAndPlacesApiInitialized$;
  }

  get geolocationErrorTranslationKey(): string {
    return this._geolocationErrorTranslationKey;
  }

  set geolocationErrorTranslationKey(value: string) {
    this._geolocationErrorTranslationKey = value;
    this.userLocationErrorOccurred.emit(this._geolocationErrorTranslationKey);
  }

  onMapTilesLoaded(): void {
    this.googleApiService.initializeAllServices(this.mapComponent.googleMap, this.mapOptions);
  }

  getMapRadius(): number {
    if (!this.mapComponent?.getCenter() || !this.mapComponent.getBounds()) {
      return;
    }

    // Radius in kilometers
    return google.maps.geometry.spherical.computeDistanceBetween(this.mapComponent.getCenter(), this.mapComponent.getBounds().getNorthEast()) / 1000;
  }

  showUserLocation(wasTriggeredByUserInteraction: boolean): void {
    // if (isDevMode()) {
    //   this.updateMapWithUserGeolocationPosition(LocationService.getLocationEndare());
    //
    //   return;
    // }

    if (!this.locationService.isGeoLocationSupported()) {
      this.geolocationErrorTranslationKey = this.locationService.getTranslationKeyForMostSuitableGeolocationError();
      this.showFlanders();

      return;
    }

    if (this.locationService.shouldShowPermissionDeniedDialog() && wasTriggeredByUserInteraction) {
      this.locationService.showLocationPermissionDeniedDialog();

      return;
    }

    this.locationService.getUserLocation$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pos: GeolocationPosition) => {
          this.onUserLocationFetched(pos);
        },
        error: (err: GeolocationPositionError) => {
          this.onErrorWhenGettingUserLocation(err);
        },
      });
  }

  showFlanders(): void {
    this.updateMapViewport(COORDINATES.flanders.latitude, COORDINATES.flanders.longitude, ZOOM_LEVELS.region);
  }

  showBelgium(): void {
    this.updateMapViewport(COORDINATES.belgium.latitude, COORDINATES.belgium.longitude, ZOOM_LEVELS.country);
  }

  updateMapViewport(latitude: number, longitude: number, zoom: number): void {
    if (!latitude || !longitude || !zoom) {
      return;
    }

    this.mapCenter = {
      lat: latitude,
      lng: longitude,
    };

    this.mapZoom = zoom;

    this.mapViewPortUpdated.emit({
      ...this.mapCenter,
      radius: this?.getMapRadius(),
    });
  }

  addUserLocationMarker(userLocationMarkerStyle: string = 'default'): void {
    if (!this.mapComponent || !this.userLocation) {
      return;
    }

    this.userLocationMarker = new google.maps.Marker({
      position: { lat: this.userLocation.coords.latitude, lng: this.userLocation.coords.longitude },
      map: this.mapComponent.googleMap,
      icon: userLocationMarkerStyle === 'default' ? LocationService.getUserLocationMapMarkerIcon() : null,
      clickable: false,
      title: this.translate.instant('common.myLocation'),
    });
  }

  protected onUserLocationFetched(position: GeolocationPosition, zoom: number = ZOOM_LEVELS.default): void {
    this.userLocation = position;
    this.userLocationUpdated.emit(this.userLocation);
    this.updateMapViewport(position.coords.latitude, position.coords.longitude, zoom);
  }

  protected onErrorWhenGettingUserLocation(positionError: GeolocationPositionError): void {
    this.geolocationErrorTranslationKey = this.locationService.getTranslationKeyForMostSuitableGeolocationError(positionError);
  }

  protected fitCoordinatesToBoundsIncludingUserPosition(coordinates: google.maps.LatLngLiteral[]): void {
    this.mapBounds = new google.maps.LatLngBounds();

    // Optimize view when no user position or coordinates are available
    if (coordinates.length === 0 && !this.userLocation) {
      this.showFlanders();

      return;
    }

    // Add the user position if available
    if (this.userLocation) {
      this.mapBounds.extend({ lat: this.userLocation.coords.latitude, lng: this.userLocation.coords.longitude });
    }

    // Optimize view when only the user marker is available
    if (this.userLocation && coordinates.length === 0) {
      this.onUserLocationFetched(this.userLocation, ZOOM_LEVELS.highlight);

      return;
    }

    // Add all the given coordinates to the map
    coordinates.forEach((coordinate: google.maps.LatLngLiteral) => this.mapBounds.extend(coordinate));

    // Optimize view when only a single coordinate is available
    if (!this.userLocation && coordinates.length === 1) {
      this.updateMapViewport(coordinates[0].lat, coordinates[0].lng, ZOOM_LEVELS.highlight);

      return;
    }

    // Optimize view for combination of one or more coordinates combined with the user position
    this.mapComponent?.fitBounds(this.mapBounds);
  }
}
