import { Pipe, PipeTransform } from '@angular/core';
import { Institution } from '@models/institution.model';

@Pipe({
  name: 'vhFilterInstitutions',
})
export class FilterInstitutionsPipe implements PipeTransform {
  transform(institutions: Institution[], searchTerm: string): Institution[] {
    if (!institutions || !searchTerm) {
      return institutions;
    }

    return institutions.filter((institution: Institution) =>
      institution.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
