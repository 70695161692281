import { Component, OnInit } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';
import { CardType } from '@modules/settings/enums/card-type.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '@interfaces/option.interface';
import { getSettingsCardStatusOptions } from '@modules/settings/enums/settings-card-status.enum';

@Component({
  selector: 'vh-care-programs-page',
  templateUrl: './care-programs-page.component.html',
  styleUrl: './care-programs-page.component.scss',
})
@UntilDestroy()
export class CareProgramsPageComponent implements OnInit {
  addRoute: string = NAVIGATION.adminDashboardSettings.createCareProgram.route;
  editRoute: string = NAVIGATION.adminDashboardSettings.updateCareProgram.route;
  cardType: CardType = CardType.CARE_PROGRAM;
  careProgramStatusOptions: Option [];

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.careProgramStatusOptions = getSettingsCardStatusOptions().map((option: Option): Option => ({
      ...option,
      label: this.translate.instant(option.label),
    }));
  }
}
