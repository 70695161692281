import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'vh-skeleton-group',
  templateUrl: './skeleton-group.component.html',
  styleUrls: ['./skeleton-group.component.scss'],
})
export class SkeletonGroupComponent {
  @Input() template: TemplateRef<unknown>;
  @Input() amountSkeletons: number;
  @Input() skeletonIsEmpty: boolean;
  @Input() emptySkeletonMessage: string;
}
