import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';

@Component({
  selector: 'vh-token-balance',
  templateUrl: './token-balance.component.html',
  styleUrls: ['./token-balance.component.scss'],
})
@UntilDestroy()
export class TokenBalanceComponent implements OnInit {
  @Input() balance: number | null = null;
  @Input() containerClass: string | null = null;

  wallet: Wallet;

  constructor(
    private readonly walletService: WalletService
  ) {}

  ngOnInit(): void {
    if (!this.balance) {
      this.loadWallet();
    }
  }

  private loadWallet(): void {
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.wallet = wallet;
      });
  }
}
