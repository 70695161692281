import { Component, OnInit } from '@angular/core';
import { BasePaymentStatusPageComponent } from '@modules/payment/pages/base-payment-status-page/base-payment-status-page.component';
import { AuthenticationService } from '@services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Order } from '@models/order.model';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'vh-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.scss'],
})
@UntilDestroy()
export class PaymentSuccessPageComponent extends BasePaymentStatusPageComponent implements OnInit {

  wallet: Wallet;
  order: Order;

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    private readonly walletService: WalletService,
    private readonly orderService: OrderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super(authenticationService, router);

  }

  ngOnInit(): void {
    this.resolveOrder();
    this.loadWallet();
  }

  private loadWallet(): void {
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.wallet = wallet;
      });
  }

  private resolveOrder(): void {
    this.order = this.router.getCurrentNavigation()?.extras?.state?.order as Order;

    // If we get here, we probably did something wrong like navigating to this page directly
    if (!this.order && !this.activatedRoute.snapshot.queryParams.order_id) {
      redirectToMostRelevantHome(this.authenticationService, this.router);

      return;
    }

    this.orderService.getOrderById$(this.activatedRoute.snapshot.queryParams.order_id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (order: Order): void => {
          this.order = order;
        },
        error: (error: any): void => {
          redirectToMostRelevantHome(this.authenticationService, this.router);
        },
      });
  }
}
