import { Room } from '@models/room.model';
import { IObject } from '@app-types/iobject.type';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { format, isAfter, isValid, parse } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Institution } from '@models/institution.model';
import { stripFalsyPropertiesFromObject } from '@utils/helpers/form.util';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';

export class VisitFilter {
  static readonly PAGE_SIZE_CALENDAR: number = 250;
  static readonly PAGE_SIZE_SEARCH: number = 20;

  private _allRooms: Room[];
  private _selectedRooms: Map<string, Room>;
  private _from: Date;
  private _to: Date;
  private _patientName: string;
  private _patientBirthdate: string;
  private _patientSocialSecurityNumber: string;
  private _institution: Institution;
  private _currentPage: number;
  private _cancelled: boolean;
  private _cancellationActionId: string;
  private _areDateFiltersEnabled: boolean;
  private _pageSize: number;

  constructor() {
    this._allRooms = [];
    this._selectedRooms = new Map<string, Room>();
    this._currentPage = 1;
    this._areDateFiltersEnabled = true;
    this._cancelled = false;
    this._pageSize = 20;
  }

  get allRooms(): Room[] {
    return this._allRooms;
  }

  set allRooms(value: Room[]) {
    this._allRooms = value;
    this._selectedRooms = new Map<string, Room>(value.map((room: Room) => [room.id, room]));
  }

  get selectedRooms(): Map<string, Room> {
    return this._selectedRooms;
  }

  get selectedRoomsArray(): Room[] {
    return Array.from(this.selectedRooms.values());
  }

  get from(): Date {
    return this._from;
  }

  set from(value: Date) {
    this._from = value;
  }

  get to(): Date {
    return this._to;
  }

  set to(value: Date) {
    this._to = value;
  }

  get patientBirthdate(): string {
    return this._patientBirthdate;
  }

  set patientBirthdate(value: string) {
    this._patientBirthdate = value;
  }

  get patientName(): string {
    return this._patientName;
  }

  set patientName(value: string) {
    this._patientName = value;
  }

  get patientSocialSecurityNumber(): string {
    return this._patientSocialSecurityNumber;
  }

  set patientSocialSecurityNumber(value: string) {
    this._patientSocialSecurityNumber = value;
  }

  get institution(): Institution {
    return this._institution;
  }

  set institution(value: Institution) {
    this._institution = value;
  }

  get cancelled(): boolean {
    return this._cancelled;
  }

  set cancelled(value: boolean) {
    this._cancelled = value;
  }

  get cancellationActionId(): string {
    return this._cancellationActionId;
  }

  set cancellationActionId(value: string) {
    this._cancellationActionId = value;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  get areDateFiltersEnabled(): boolean {
    return this._areDateFiltersEnabled;
  }

  set areDateFiltersEnabled(value: boolean) {
    this._areDateFiltersEnabled = value;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    this._pageSize = value;
  }

  parsePatientBirthdate(): Date | null {
    return this.patientBirthdate ? parse(this.patientBirthdate, DATE_FORMATS.serverDate, new Date()) : null;
  }

  isPatientBirthdateIsValid(): boolean {
    const parsedPatientBirthdate: Date = this.parsePatientBirthdate();

    return !!this.patientBirthdate &&
      isValid(parsedPatientBirthdate) &&
      isAfter(parsedPatientBirthdate, new Date(1900, 0, 1));
  }

  hasPatientFilterActive(): boolean {
    return !!(this.patientName || this.isPatientBirthdateIsValid() || this.patientSocialSecurityNumber);
  }

  resetPatientFields(): void {
    this.patientName = null;
    this.patientBirthdate = null;
    this.patientSocialSecurityNumber = null;
    this.currentPage = 1;
  }

  toQueryStringParams(): IObject {
    const parsedPatientBirthdate: Date = this.parsePatientBirthdate();
    const values = {
      [ENDPOINTS.getVisitsForInstitution.queryParams.startDate]: this.from && this.areDateFiltersEnabled ? getTimeStringWithTimeZone(this.from) : null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.endDate]: this.to && this.areDateFiltersEnabled ? getTimeStringWithTimeZone(this.to) : null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.patientName]: this.patientName,
      [ENDPOINTS.getVisitsForInstitution.queryParams.patientBirthdate]: isValid(parsedPatientBirthdate) ? format(parsedPatientBirthdate, DATE_FORMATS.serverDate) : null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.patientSocialSecurityNumber]: this.patientSocialSecurityNumber ?? null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.institution]: this.institution?.id,
      [ENDPOINTS.getVisitsForInstitution.queryParams.cancelled]: this.cancelled,
      [ENDPOINTS.getVisitsForInstitution.queryParams.cancellationActionId]: this.cancellationActionId ?? null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.rooms]: this.selectedRooms.size !== this.allRooms.length ? Array.from(this.selectedRooms.values()).map((room: Room) => room.id).join(',') : null,
      [ENDPOINTS.getVisitsForInstitution.queryParams.sort]: '+start',
      [ENDPOINTS.getVisitsForInstitution.queryParams.page]: this.currentPage,
      [ENDPOINTS.getVisitsForInstitution.queryParams.page_size]: this._pageSize,
    };

    return stripFalsyPropertiesFromObject(values);
  }
}
