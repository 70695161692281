export class VisitReschedulingImpact {
  constructor(
    private readonly _targetTimeSameAsVisitTime: boolean,
    private readonly _visitAlreadyBookedAtSameTime: boolean,
    private readonly _targetTimeNotWithinRecurrenceRange: boolean,
    private readonly _targetTimeIsInPast: boolean,
    private readonly _targetTimeViolatesBookingRestrictions: boolean
  ) {}

  get targetTimeSameAsVisitTime(): boolean {
    return this._targetTimeSameAsVisitTime;
  }

  get visitAlreadyBookedAtSameTime(): boolean {
    return this._visitAlreadyBookedAtSameTime;
  }

  get targetTimeNotWithinRecurrenceRange(): boolean {
    return this._targetTimeNotWithinRecurrenceRange;
  }

  get targetTimeIsInPast(): boolean {
    return this._targetTimeIsInPast;
  }

  get targetTimeViolatesBookingRestrictions(): boolean {
    return this._targetTimeViolatesBookingRestrictions;
  }

  get message(): string {
    if (this.targetTimeIsInPast) {
      return 'admin.pages.dashboard.rescheduleConfirmation.targetTimeIsInPast';
    } else if (this.targetTimeNotWithinRecurrenceRange) {
      return 'admin.pages.dashboard.rescheduleConfirmation.outsidePlanningWarning';
    } else if (this.visitAlreadyBookedAtSameTime) {
      return 'admin.pages.dashboard.rescheduleConfirmation.doubleBookingWarning';
    } else if (this.targetTimeSameAsVisitTime) {
      return 'admin.pages.dashboard.rescheduleConfirmation.targetTimeSameAsVisitTime';
    } else if (this._targetTimeViolatesBookingRestrictions) {
      return 'admin.pages.dashboard.rescheduleConfirmation.violatesRestrictionsMessage';
    }

    return 'admin.pages.dashboard.rescheduleConfirmation.noImpactMessage';
  }

  hasImpact(): boolean {
    return this.visitAlreadyBookedAtSameTime || this.targetTimeNotWithinRecurrenceRange || this.targetTimeSameAsVisitTime || this.targetTimeIsInPast || this.targetTimeViolatesBookingRestrictions;
  }

  static fromJson(json: VisitReschedulingImpactJson): VisitReschedulingImpact {
    return new VisitReschedulingImpact(
      json.target_time_same_as_visit_time,
      json.visit_already_booked_at_same_time,
      json.target_time_not_within_recurrence_range,
      json.target_time_is_in_past,
      json.target_time_violates_booking_restrictions
    );
  }
}

export interface VisitReschedulingImpactJson {
  target_time_same_as_visit_time: boolean;
  visit_already_booked_at_same_time: boolean;
  target_time_not_within_recurrence_range: boolean;
  target_time_is_in_past: boolean;
  target_time_violates_booking_restrictions: boolean;
}
