import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[vhOutsideClick]',
})
export class OutsideClickDirective {
  @Input() outsideClickDisabled: boolean = false;

  @Output() outsideClick: EventEmitter<Event> = new EventEmitter<Event>();

  private mouseDownPos: { x: number; y: number; } | null = null;
  private dragThreshold: number = 5;

  constructor(private readonly elementRef: ElementRef) { }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.mouseDownPos = { x: event.clientX, y: event.clientY };
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (!this.mouseDownPos) {
      return;
    }

    const mouseUpPos = { x: event.clientX, y: event.clientY };
    const distance = Math.sqrt(
      Math.pow(mouseUpPos.x - this.mouseDownPos.x, 2) +
      Math.pow(mouseUpPos.y - this.mouseDownPos.y, 2)
    );

    // Reset mouseDownPos after determining the distance
    this.mouseDownPos = null;

    if (distance > this.dragThreshold) {
      return;
    }

    this.handleClick(event);
  }

  private handleClick(event: MouseEvent): void {
    if (this.outsideClickDisabled) {
      return;
    }

    const hostElement: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    const clickedElement: HTMLElement = event.target as HTMLElement;

    const hostRect: DOMRect = hostElement.getBoundingClientRect();

    const clickX: number = event.clientX;
    const clickY: number = event.clientY;

    const isWithinHost: boolean =
      clickX >= hostRect.left &&
      clickX <= hostRect.right &&
      clickY >= hostRect.top &&
      clickY <= hostRect.bottom;

    if (isWithinHost || hostElement.contains(clickedElement)) {
      return;
    }

    this.outsideClick.emit(event);
  }
}
