<vh-page class="page">
  <div class="grid-container">
    <div class="header">
      <vh-navigation-return-link
        (navigateBackClick)="leave()">
      </vh-navigation-return-link>
      <h1>{{ 'conversations.create.title' | translate }}</h1>
      <div></div>
    </div>
    <div class="content">
      <vh-wizard-progress-indicator
        [state]="wizardState">
      </vh-wizard-progress-indicator>

      <ng-container *ngIf="shouldShowLowTokensWarning">
        <div class="flex-row-l-c flex-col-l-c-when-small gap-h-4 clickable tokens border-error padding-m"
          [vhMarginTop]="8"
          (click)="onTokensClicked()">
          <div class="flex-row-l-c gap-h-4 text-overflow">
            <vh-icon
              src="assets/icons/wallet.svg"
              width="16"
              height="16">
            </vh-icon>

          <p class="font-medium-18-22">{{ currentUserTokens ?? '0' }}
            <span class="font-light-12-14">{{ 'navigationBar.tokens' | translate }}</span>
          </p>
          </div>

          <div class="full-width">
            <p class="align-end font-medium-16-19 has-text-error">
              <ng-container *ngIf="shouldShowNoTokensWarning">
                {{ 'navigationBar.noTokens' | translate }}
              </ng-container>

              <ng-container *ngIf="shouldShowLowTokensWarning">
                {{ 'navigationBar.lowTokens' | translate }}
              </ng-container>
            </p>
          </div>
        </div>
      </ng-container>

      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</vh-page>
