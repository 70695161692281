<div
  vhSkeleton
  [skeletonIsVisible]="!date"
  [skeletonIsEmpty]="skeletonIsEmpty"
  class="flex-row container"
  [ngStyle]="{ 'background-color': backgroundColor }"
  [ngClass]="{ 'size-small': layout === DateCardLayout.SECONDARY && breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall] }">
  <div
    class="content"
    [ngClass]="{ 'flex-col-c-s': layout === DateCardLayout.PRIMARY, 'flex-col-c-c': layout === DateCardLayout.SECONDARY }">
    <ng-container *ngIf="layout === DateCardLayout.PRIMARY">
      <span class="font-light-14-17">{{ dayTextTranslationKey | translate }}</span>
      <span class="font-medium-28-34">{{ date | date : DATE_FORMATS.clientDay }}</span>
      <span class="font-regular-14-17">{{ monthTextTranslationKey | translate }}</span>
    </ng-container>
    <ng-container *ngIf="layout === DateCardLayout.SECONDARY">
      <span [ngClass]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall] ? 'font-medium-19-23' : 'font-medium-28-34'">{{
        date | date : DATE_FORMATS.clientTimeShort
      }}</span>
    </ng-container>
  </div>
</div>
