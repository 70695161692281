export const CREATE_ROOM_FORM_KEYS = new Map<string, string>([
  ['name', 'name'],
  ['description', 'description'],
  ['modality', 'modality_id'],
  ['color', 'color'],
  ['serviceGroups', 'service_groups'],
  ['serviceGroup', 'serviceGroup'],
  ['color', 'color'],
  ['services', 'services'],
]);
