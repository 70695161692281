import { Injectable } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { LocationPermissionService } from '@services/location-permission.service';
import { Observable, of, Subscriber } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends LocationPermissionService {
  static getLocationEndare(): GeolocationPosition {
    return {
      coords: {
        latitude: 51.016548,
        longitude: 3.732980,
        accuracy: 123,
        altitude: 0,
        altitudeAccuracy: 0,
        heading: 0,
        speed: 0,
      },
      timestamp: null,
    };
  }

  static getUserLocationMapMarkerIcon(): google.maps.Symbol {
    return {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 6,
      fillOpacity: 1,
      strokeWeight: 2,
      fillColor: COLORS.googleMapsUserLocation,
      strokeColor: COLORS.white,
    };
  }

  getTranslationKeyForMostSuitableGeolocationError(positionError?: GeolocationPositionError): string {
    // Following cases cover no hardware support for geolocation
    if (!window.isSecureContext) {
      return 'pages.createAppointment.step3.insecureContextError';
    }

    if (!navigator.geolocation) {
      return 'pages.createAppointment.step3.geolocationNotSupportedError';
    }

    // Following case covers no permission granted to request user location
    if (!this.hasPermission()) {
      return 'pages.createAppointment.step3.geolocationPermissionDeniedError';
    }

    // Following case means there is no error
    if (!positionError) {
      return null;
    }

    // Following case means location was requested but something went wrong in the process
    switch (positionError.code) {
      case positionError.PERMISSION_DENIED:
        return 'pages.createAppointment.step3.geolocationPermissionDeniedError';
      case positionError.POSITION_UNAVAILABLE:
        return 'pages.createAppointment.step3.geolocationPositionUnavailableError';
      case positionError.TIMEOUT:
        return 'pages.createAppointment.step3.geolocationTimeoutError';
      default:
        return 'common.unknownError';
    }
  }

  isGeoLocationSupported(): boolean {
    return navigator.geolocation && window.isSecureContext;
  }

  getUserLocation$(options?: PositionOptions): Observable<GeolocationPosition> {
    if (this.lastKnownUserPosition) {
      return of(this.lastKnownUserPosition);
    }

    return new Observable<GeolocationPosition>((observer: Subscriber<GeolocationPosition>) => {
      window.navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          observer.next(position);
          observer.complete();
        },
        (error: GeolocationPositionError) => {
          observer.error(error);
        },
        this.mergeOptionsWithDefaults(options)
      );
    }).pipe(tap((position: GeolocationPosition) => this._lastKnownUserPosition = position));
  }

  /**
   * Merge requesting position options with the defaults
   */
  private mergeOptionsWithDefaults(options?: PositionOptions): PositionOptions {
    return options
      ? {
        enableHighAccuracy: true,
        timeout: 5000,
        ...options,
      }
      : undefined;
  }
}
