import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CANCEL_VISIT_FORM_KEYS } from '@constants/form-keys/cancel-visit-form-keys.constant';
import { UntypedFormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Institution } from '@models/institution.model';
import { VisitCancellationAction } from '@models/visit-cancellation-action.model';
import { VisitCancellationReason } from '@models/visit-cancellation-reason.model';
import { InstitutionService } from '@services/institution.service';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'vh-cancel-visit-form',
  templateUrl: './cancel-visit-form.component.html',
  styleUrls: ['./cancel-visit-form.component.scss'],
})
@UntilDestroy()
export class CancelVisitFormComponent implements OnInit {
  CANCEL_VISIT_FORM_KEYS: typeof CANCEL_VISIT_FORM_KEYS = CANCEL_VISIT_FORM_KEYS;

  cancelReasonOptions: Option[];
  cancelActionOptions: Option[];

  @Input() errorMessage: string;
  @Input() formGroup: UntypedFormGroup;

  @Output() formGroupUpdated: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor(private readonly institutionService: InstitutionService, private readonly authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.formGroupUpdated.emit(this.formGroup);
      });
    this.refreshInstitution();
  }

  private refreshInstitution(): void {
    this.institutionService.getInstitutionByIdOrSlug$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((institution: Institution) => {
        this.cancelActionOptions = institution.cancellationActions.map((action: VisitCancellationAction) => action.toOption());
        this.cancelReasonOptions = institution.cancellationReasons.map((reason: VisitCancellationReason) => reason.toOption());
      });
  }
}
