<vh-primary-page
  [navigationItems]="navigationItems"
  [headerContentTemplate]="headerContentTemplate"
  [hasHorizontalContentSpacing]="false"
  [hasVerticalContentSpacing]="false"
  [profileTemplate]="profileTemplate">
  <router-outlet></router-outlet>
</vh-primary-page>

<ng-template #headerContentTemplate>
  <div class="flex-row nav-header">
    <ng-container *ngFor="let item of workListNavigationItems">
      <a class="font-regular-18-22" [routerLink]="item.route" [routerLinkActive]="'active'">{{ item.labelTranslationKey |
        translate }}</a>
    </ng-container>
  </div>
</ng-template>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
