/**
 * Limiting the function calls to be one call over a set amount of time (milliseconds).
 *
 * Source: https://github.com/Gendie/angular-debounce-throttle/blob/master/projects/angular-debounce-throttle/src/lib/debounce.decorator.ts
 *
 * @param {number} delay - The duration between function calls in Milliseconds.
 * @param {boolean} atBegin - Execute the first call immediately then debounce. Default is false.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function debounce(delay: number, atBegin: boolean = false) {
  if (delay < 0) {
    throw new Error('Limit should be zero or greater');
  }

  let timeout = null;

  return (targetClass: unknown, functionName: string, descriptor: PropertyDescriptor): void => {
    const source = descriptor.value;
    let inProgress = false;

    descriptor.value = function(...args: unknown[]): unknown {
      if (atBegin && !inProgress) {
        inProgress = true;
        timeout = setTimeout(() => {
          inProgress = false;
        }, delay);

        return source.call(this, ...args);
      }
      clearTimeout(timeout);
      inProgress = true;
      timeout = setTimeout(() => {
        inProgress = false;

        return source.call(this, ...args);
      }, delay);
    };
  };
}
