<div class="card-primary">
  <h2 *ngIf="title" class="card-title">{{ title }}</h2>
  <div class="card-body">
    <h3 *ngIf="subtitle" class="card-subtitle" [vhMarginTop]="8">{{ subtitle }}</h3>
    <div *ngIf="description" [vhMarginTop]="8">{{ description }}</div>
    <vh-message
      *ngFor="let service of services"
      textClass="not-clickable flex-grow"
      [vhMarginTop]="8"
      [text]="service.displayFullName">
<!-- TODO: deleting is disabled for now, re-enable properties below to re-enable the feature -->
<!--      iconRightClass="clickable"-->
<!--      iconRightPath="assets/icons/trash-alt.svg"-->
<!--      [iconRightTooltip]="'common.delete' | translate"-->
<!--      [iconRightFill]="COLORS.secondary"-->
<!--      [iconRightHoverFill]="COLORS.error"-->
<!--      (rightIconClick)="serviceRemoved.emit(service)"-->
    </vh-message>
  </div>
</div>
