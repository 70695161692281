<div class="gap-v-16" [vhMarginTop]="16">
  <ng-container *ngIf="state === 'recommendations'">
    <ng-container [ngSwitch]="cardTemplate">
      <ng-container *ngSwitchCase="'institution'" [ngTemplateOutlet]="institutionRecommendationsTemplate"></ng-container>
      <ng-container *ngSwitchCase="'careProgram'" [ngTemplateOutlet]="careProgramRecommendationsTemplate"></ng-container>
      <ng-container *ngSwitchCase="'slot'" [ngTemplateOutlet]="slotRecommendationsTemplate"></ng-container>
      <ng-container *ngSwitchCase="'multi-slot'" [ngTemplateOutlet]="multiSlotRecommendationsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="listFooterTemplate" [ngTemplateOutlet]="listFooterTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="state === 'error'" [ngTemplateOutlet]="errorTemplate"></ng-container>
  <ng-container *ngIf="state === 'loading'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
  <ng-container *ngIf="state === 'empty'" [ngTemplateOutlet]="emptyTemplate"></ng-container>
</div>

<ng-template #emptyTemplate>
  <p>{{ 'pages.createAppointment.step4.noResultsText' | translate }}</p>
</ng-template>

<ng-template #loadingTemplate>
  <vh-generic-card-skeleton class="gap-v-16" *ngFor="let i of [].constructor(5)"></vh-generic-card-skeleton>
</ng-template>

<ng-template #errorTemplate>
  <vh-message
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="getAvailableAppointmentsErrorMessageTranslationKey | translate">
  </vh-message>
</ng-template>

<ng-template #slotRecommendationsTemplate>
  <vh-visit-card-group
    [noResultsMessage]="{
      title: 'pages.createAppointment.step4.noResultsTitle' | translate,
      text: 'pages.createAppointment.step4.noResultsText' | translate
    }"
    [isLoading]="isLoading"
    [visits]="recommendations.data"
    [onCardClickFunction]="onRecommendationClicked">
  </vh-visit-card-group>
</ng-template>

<ng-template #multiSlotRecommendationsTemplate>
  <vh-multi-visit-card
    *ngFor="let multiSlot of recommendations.data"
    class="clickable"
    [slots]="multiSlot">
  </vh-multi-visit-card>
</ng-template>

<ng-template #institutionRecommendationsTemplate>
  <vh-message
    *ngIf="recommendations?.data.length === 0 && !getAvailableAppointmentsErrorMessageTranslationKey"
    [vhFontClass]="'font-regular-16-19'"
    [text]="'pages.createAppointment.step4.fallbackToInstitutionsDisclaimer' | translate">
  </vh-message>

  <div [vhMarginTop]="8" class="flex-col gap-v-16">
    <vh-institution-card
      vhInstitutionTracker
      *ngFor="let institution of recommendations.data"
      [institution]="institution"
      (moreInfoClick)="onRecommendationClicked($event)">
    </vh-institution-card>
  </div>
</ng-template>

<ng-template #careProgramRecommendationsTemplate>
  <div [vhMarginTop]="8" class="flex-col gap-v-16">
    <p>This will be a card to select a careprogram</p>
  </div>
</ng-template>
