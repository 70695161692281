import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { AnswerListService } from '@services/answer-list.service';
import { AnswerListTrackingEventService } from '@services/tracking/answer-list-tracking-event.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { AnswerList } from '@models/answer-list.model';

@Component({
  selector: 'vh-answer-list-argumentation-popup',
  templateUrl: './answer-list-argumentation-popup.component.html',
  styleUrls: ['./answer-list-argumentation-popup.component.scss'],
})
export class AnswerListArgumentationPopupComponent implements OnInit {
  @Input() answerList: AnswerList;
  @Input() formGroup: FormGroup;
  @Input() statusOptions: Option[];
  @Input() optionClick: (fg: FormGroup) => void;

  selectedFormGroup: FormGroup;

  constructor(
    private readonly answerListService: AnswerListService,
    private readonly answerListTrackingEventService: AnswerListTrackingEventService
  ) {}

  ngOnInit(): void {
    this.selectedFormGroup = this.formGroup;
  }

  saveStatus(): void {
    this.answerListService.evaluateAnswerList$(this.formGroup.value)
      .subscribe(() => {
        this.optionClick(this.formGroup);
        this.answerListTrackingEventService.track(TrackingEventType.ANSWER_LIST_EVALUATION_EDITED, this.answerList);
      });
  }
}
