<vh-dialog-frame
    [title]="'admin.pages.cardOverview.deleteCardDialog.title' | translate: {name: card.name, cardType: cardType}"
    (closeClicked)="dialog.close(false)">
    <mat-dialog-content>
        <ng-container *ngIf="impactedItems?.length > 0">
            <p>{{ 'admin.pages.cardOverview.deleteCardDialog.messageWhenImpacted' | translate: {impactType: impactType, cardType: cardType} }}</p>
            <ul [vhMarginTop]="16">
                <li class="clickable" *ngFor="let item of impactedItems" (click)="onImpactedItemClicked(item)">{{ item.label }}
                </li>
            </ul>
        </ng-container>

        <ng-container *ngIf="impactedItems?.length === 0">
            <p>{{ 'admin.pages.cardOverview.deleteCardDialog.messageWhenNoImpact' | translate: {impactType: impactType, cardType: cardType} }}</p>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
        <vh-button [label]="'common.ok' | translate" (buttonClick)="onConfirmClicked()"> </vh-button>
        <vh-button [label]="'common.cancel' | translate" [style]="ButtonStyle.TERTIARY"
            (buttonClick)="dialog.close(false)">
        </vh-button>
    </mat-dialog-actions>
</vh-dialog-frame>
