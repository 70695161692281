import { AuthenticationService } from '@services/authentication.service';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { ModuleService } from '@services/module.service';
import { inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { Mode } from '@enums/mode.enum';
import { ModuleKey } from '@enums/module-key.enum';
import { Role } from '@enums/role.enum';

export const filterNavigationItems = (items: NavigationItem[]): NavigationItem[] => {
  // Inject the services here to avoid having to pass them as a parameter
  let authenticationService: AuthenticationService = null;
  let moduleService: ModuleService = null;

  try {
    authenticationService = inject(AuthenticationService);
    moduleService = inject(ModuleService);
  } catch (error: unknown) {
    if ((error as { code: number; }).code === -203) {
      console.error('filterNavigationItems() should only be called in an injection context such as a constructor');
      throw error;
    }

    return [];
  }

  if (authenticationService.currentUser?.hasRole(Role.ADMIN)) {
    return items;
  }

  const filterItems = (navigationTree: NavigationItem[]): NavigationItem[] => {
    // Filter on modules, if any navigation items require any
    const inaccessibleNavigationItems: NavigationItem[] = navigationTree
      .filter((item: NavigationItem) => item.visibleForModules && item.visibleForModules.length > 0 && !moduleService.hasAccessToModules(item.visibleForModules));

    return navigationTree
      .filter((item: NavigationItem) => !inaccessibleNavigationItems.some((navItem: NavigationItem) => navItem.labelTranslationKey === item.labelTranslationKey))
      .map((item: NavigationItem) => {
        item.visibleForRoles = item.visibleForRoles || [];

        // Recursively filter sub-navigation items if they exist
        if (item.subNavigationItems) {
          item.subNavigationItems = filterItems(item.subNavigationItems);
        }

        return item;
      })
      .filter((item: NavigationItem) => {
        if (!item.visibleForRoles || item.visibleForRoles.length === 0 || authenticationService.currentUser?.hasRole(Role.ADMIN)) {
          return true;
        }

        // If visibleForRoles is missing, the item is visible for any role
        const hasVisibleSubItems = item.subNavigationItems?.length > 0;

        return authenticationService.currentUser?.hasAnyRole(item.visibleForRoles) || hasVisibleSubItems;
      });
  };

  return filterItems(items);
};

/**
 * This function redirects the user to the root page which makes the most sense given the current context.
 */
export const redirectToMostRelevantHome = (authenticationService: AuthenticationService, router: Router, extras?: NavigationExtras): void => {
  if (!authenticationService) {
    throw new Error('redirectToMostRelevantHome() requires an AuthenticationService instance');
  }

  if (!router) {
    throw new Error('redirectToMostRelevantHome() requires a Router instance');
  }

  // Unauthenticated users will be redirected to the home page.
  if (!authenticationService.isLoggedIn()) {
    void router.navigate([NAVIGATION.root.route], extras);

    return;
  }

  // Referring physicians will be redirected to the referring physician dashboard
  if (authenticationService.currentUser?.hasAnyReferringPhysicianRole() && authenticationService.mode === Mode.ADMIN) {
    void router.navigate([NAVIGATION.referringPhysician.route], extras);

    return;
  }

  // If the user was a former MEDPLaNNER user, then they will be redirected to the reservation dashboard
  if (authenticationService.currentUser?.hasAnyInstitutionRole() && authenticationService.mode === Mode.ADMIN && authenticationService.institution?.hasModule(ModuleKey.BOOKING)) {
    void router.navigate([NAVIGATION.adminDashboardAppointments.route], extras);

    return;
  }

  // Admins will be redirected to the admin dashboard.
  if (authenticationService.currentUser?.hasAnyInstitutionRole() && authenticationService.mode === Mode.ADMIN) {
    void router.navigate([NAVIGATION.adminDashboardConversations.route], extras);

    return;
  }

  // Patients will be redirected to the private dashboard
  void router.navigate([NAVIGATION.dashboard.route], extras);
};
