import { Component, Input } from '@angular/core';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  readonly SUCCESS_ICON_PATH: string = 'assets/icons/circle-check.svg';
  readonly FAILED_ICON_PATH: string = 'assets/icons/circle-x.svg';

  @Input() isSuccessful: boolean;
  @Input() title: string;
  @Input() message: string;
}
