import { Injectable } from '@angular/core';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { Option } from '@interfaces/option.interface';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  getTimeZones(): string[] {
    return getTimeZones()
      .map((tz: TimeZone) => tz.name)
      .sort();

  }

  getTimeZonesAsOptions(): Option[] {
    return this.getTimeZones()
      .map((name: string): Option => ({ label: name, value: name }));
  }
}
