import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { TimeslotPickerComponent } from '@modules/shared/core/components/timeslot-picker/timeslot-picker.component';
import { LoaderComponent } from '@modules/shared/core/components/loader/loader.component';
import { BackButtonComponent } from '@modules/shared/core/components/back-button/back-button.component';
import { ButtonComponent } from '@modules/shared/core/components/button/button.component';
import { ButtonGroupComponent } from '@modules/shared/core/components/button-group/button-group.component';
import { MessageComponent } from '@modules/shared/core/components/message/message.component';
import { TabMenuComponent } from '@modules/shared/core/components/tab-menu/tab-menu.component';
import { HorizontalDividerComponent } from '@modules/shared/core/components/horizontal-divider/horizontal-divider.component';
import { NavigationBarComponent } from '@modules/shared/core/components/navigation-bar/navigation-bar.component';
import { NavigationReturnLinkComponent } from '@modules/shared/core/components/navigation-return-link/navigation-return-link.component';
import { SideNavComponent } from '@modules/shared/core/components/side-nav/side-nav.components';
import { AlertDialogComponent } from '@modules/shared/core/dialogs/alert-dialog/alert-dialog.component';
import { QrDialogComponent } from '@modules/shared/core/dialogs/qr-dialog/qr-dialog.component';
import { HumanBodyComponent } from '@modules/shared/core/components/human-body/human-body.component';
import { PillComponent } from '@modules/shared/core/components/pill/pill.component';
import { RadioButtonGroupComponent } from '@modules/shared/core/components/radio-button-group/radio-button-group.component';
import { IconComponent } from '@modules/shared/core/components/icon/icon.component';
import { SkeletonTableComponent } from '@modules/shared/core/components/skeleton-table/skeleton-table.component';
import { DialogFrameComponent } from '@modules/shared/core/components/dialog-frame/dialog-frame.component';
import { WizardLayoutComponent } from '@modules/shared/core/components/wizard-layout/wizard-layout.component';
import { SkeletonGroupComponent } from '@modules/shared/core/components/skeleton-group/skeleton-group.component';
import { TaskListComponent } from '@modules/shared/core/components/task-list/task-list.component';
import { CopyToClipboardComponent } from '@modules/shared/core/components/copy-to-clipboard/copy-to-clipboard.component';
import { PageComponent } from '@modules/shared/core/components/page/page.component';
import { PrimaryPageComponent } from '@modules/shared/core/components/primary-page/primary-page.component';
import { SecondaryPageComponent } from '@modules/shared/core/components/secondary-page/secondary-page.component';
import { TertiaryPageComponent } from '@modules/shared/core/components/tertiary-page/tertiary-page.component';
import { TertiaryPageAltComponent } from '@modules/shared/core/components/tertiary-page-alt/tertiary-page-alt.component';
import { ContentLayoutComponent } from '@modules/shared/core/components/content-layout/content-layout.component';
import { SkeletonDirective } from '@modules/shared/core/directives/skeleton.directive';
import { SkewMarginDirective } from '@modules/shared/core/directives/skew-margin.directive';
import { MarginTopDirective } from '@modules/shared/core/directives/margin-top.directive';
import { FontClassDirective } from '@modules/shared/core/directives/font-class.directive';
import { TextColorDirective } from '@modules/shared/core/directives/text-color.directive';
import { DragAndDropDirective } from '@modules/shared/core/directives/drag-and-drop.directive';
import { VisibilityTrackerDirective } from '@modules/shared/core/directives/visibility-tracker.directive';
import { SlotTrackerDirective } from '@modules/shared/core/directives/slot-tracker.directive';
import { InstitutionTrackerDirective } from '@modules/shared/core/directives/institution-tracker.directive';
import { AnswerListTrackerDirective } from '@modules/shared/core/directives/answer-list-tracker.directive';
import { PopoverHostDirective } from '@modules/shared/core/directives/popover-host.directive';
import { HoverClassDirective } from '@modules/shared/core/directives/hover-class.directive';
import { RequiresModuleDirective } from '@modules/shared/core/directives/requires-module.directive';
import { RequiresRolesDirective } from '@modules/shared/core/directives/requires-roles.directive';
import { OutsideClickDirective } from '@modules/shared/core/directives/outside-click.directive';
import { CtrlZoomDirective } from '@modules/shared/core/directives/ctrl-zoom.directive';
import { TruncateMiddlePipe } from '@modules/shared/core/pipes/truncate-middle.pipe';
import { MinutesToTimeStringPipe } from '@modules/shared/core/pipes/minutes-to-hour.pipe';
import { FormatPipeModule } from 'ngx-date-fns';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AutoRefreshDirective } from '@modules/shared/core/directives/auto-refresh.directive';
import { WizardProgressIndicatorComponent } from '@modules/shared/core/components/wizard-progress-indicator/wizard-progress-indicator.component';
import { TimelineComponent } from '@modules/shared/core/components/timeline/timeline.component';
import { MatStep, MatStepLabel, MatStepper, MatStepperIcon } from '@angular/material/stepper';
import { CountdownTimerComponent } from '@modules/shared/core/components/countdown-timer/countdown-timer.component';
import { RequiresModeDirective } from '@modules/shared/core/directives/requires-mode.directive';
import { AutoScrollToBottomDirective } from '@modules/shared/core/directives/auto-scroll-to-bottom.directive';
import { StatusMessageComponent } from '@modules/shared/core/components/status-message/status-message.component';
import { UserCardComponent } from '@modules/shared/core/components/user-card/user-card.component';
import { EscapePressedDirective } from '@modules/shared/core/directives/escape-pressed.directive';
import { FeedbackDialogComponent } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.component';
import { FilterInstitutionsPipe } from '@modules/shared/core/pipes/filter-institutions.pipe';

const sharedIngredients: any[] = [
  TimeslotPickerComponent,
  LoaderComponent,
  TimelineComponent,
  BackButtonComponent,
  ButtonComponent,
  ButtonGroupComponent,
  MessageComponent,
  TabMenuComponent,
  HorizontalDividerComponent,
  NavigationBarComponent,
  NavigationReturnLinkComponent,
  SideNavComponent,
  AlertDialogComponent,
  QrDialogComponent,
  HumanBodyComponent,
  PillComponent,
  RadioButtonGroupComponent,
  IconComponent,
  SkeletonTableComponent,
  DialogFrameComponent,
  WizardProgressIndicatorComponent,
  CountdownTimerComponent,
  StatusMessageComponent,
  UserCardComponent,
  FeedbackDialogComponent,

  // Collective components
  WizardLayoutComponent,
  SkeletonGroupComponent,
  TaskListComponent,
  CopyToClipboardComponent,

  // Page components
  PageComponent,
  PrimaryPageComponent,
  SecondaryPageComponent,
  TertiaryPageComponent,
  TertiaryPageAltComponent,
  ContentLayoutComponent,

  // Directives
  SkeletonDirective,
  SkewMarginDirective,
  MarginTopDirective,
  FontClassDirective,
  TextColorDirective,
  DragAndDropDirective,
  VisibilityTrackerDirective,
  SlotTrackerDirective,
  InstitutionTrackerDirective,
  AnswerListTrackerDirective,
  PopoverHostDirective,
  HoverClassDirective,
  RequiresModuleDirective,
  RequiresRolesDirective,
  RequiresModeDirective,
  OutsideClickDirective,
  CtrlZoomDirective,
  AutoScrollToBottomDirective,
  EscapePressedDirective,

  // Pipes
  TruncateMiddlePipe,
  MinutesToTimeStringPipe,
  FilterInstitutionsPipe,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatTooltipModule,
    MatExpansionModule,

    // Translate module
    TranslateModule,
    FormatPipeModule,
    AngularSvgIconModule,

    // Calendar modules
    MatDialogModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AutoRefreshDirective,
    MatStepper,
    MatStep,
    MatStepLabel,
    MatStepperIcon,
  ],
  exports: [
    ...sharedIngredients,
    FeedbackDialogComponent,
    // Angular modules
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatExpansionModule,
    // Translate module
    TranslateModule,
    AngularSvgIconModule,
  ],
})
export class CoreModule { }
