import { User } from '@models/user.model';
import { Service } from '@models/service.model';
import { VirtualSlot } from '@models/virtual-slot.model';
import { Visit } from '@models/visit.model';
import { IObject } from '@app-types/iobject.type';
import { Task } from '@models/task.model';

export class Booking {
  constructor(
    private _visits: Visit[] = []
  ) {
  }

  get visits(): Visit[] {
    return this._visits;
  }

  set visits(value: Visit[]) {
    this._visits = value;
  }

  get services(): Service[] {
    return this._visits?.map((visit: Visit) => visit.service) ?? [];
  }

  get slots(): VirtualSlot[] {
    return this._visits?.map((visit: Visit) => visit.virtualSlot) ?? [];
  }

  get leadingVisit(): Visit | null {
    return this._visits?.find((visit: Visit) => visit.isLeading) ?? null;
  }

  get patient(): User | null {
    return this._visits[0]?.patient ?? null;
  }

  get tasks(): Task[] {
    return this._visits.flatMap((visit: Visit) => visit.tasks);
  }

  addVisitForServiceIfNotExists(service: Service): void {
    if (this.services.some((existingService: Service) => existingService.id === service.id)) {
      return;
    }

    // TODO: remove if-check when combination services are supported
    if (this._visits.length > 0) {
      this._visits[0].service = service;

      return;
    }

    const visit: Visit = new Visit();
    visit.service = service;
    visit.isLeading = this._visits.length === 0;

    this._visits = [visit];
    // TODO: re-enable when combination services are supported
    // this._visits.push(visit);
  }

  removeVisitByService(service: Service): void {
    this._visits = this._visits.filter((visit: Visit) => visit.service.id === service.id);
  }

  selectSlot(slots: VirtualSlot | VirtualSlot[]): void {
    if (!Array.isArray(slots)) {
      slots = [slots];
    }

    slots.forEach((slot: VirtualSlot) => {
      const index: number = this._visits.findIndex((visit: Visit) => visit.service.id === slot.service.id);
      this._visits[index].recurrenceId = slot.recurrenceId;
      this._visits[index].start = slot.start;
      this._visits[index].end = slot.end;
    });
  }

  selectPatient(patient: User): void {
    this._visits.forEach((visit: Visit) => visit.patient = patient);
  }

  toJson(): IObject {
    return this._visits.map((visit: Visit) => visit.toJson());
  }
}
