export const LOCAL_STORAGE_KEYS = {
  lastSelectedRoleOptions: 'e1aa7d19-cf67-41fc-a53f-a897c07d13b5',
  openedSideNavOption: 'b4ee319e-34a9-400f-b6b3-422f99a1cd33',
  cookiePopupIsClosed: 'f2d89fbe-34e2-4d55-965d-a08629397a0d',
  isCookiesAccepted: '5006d036-4024-4f5a-84d9-8eb829b4a352',
  loggedInUser: '8b6ac7a9-5195-43af-bfdb-07474b1dbba1',
  token: 'f4310988-3111-4ac6-aa79-09fcd8ba2ec5',
  selectedInstitution: '654b3080-e712-49e1-b996-5f0de3dc55c7',
  lastSelectedInstitutionId: 'de93e438-d25d-46e2-8c4f-59404802f8cb',
  displayMode: '59f06450-1ee9-11ed-861d-0242ac120002',
  preferredLanguage: '5c93ecc3-d828-4a58-9c7e-f1cdc5aa1abc',
  preferences: 'f2d89fde-34e2-4d55-965d-a08629397a0d',
  navigationMenuExpanded: '9bc9f209-c96b-44c1-996b-74f3c6702668',
  conversationFilter: '85f08cba4-72fd-96bb-813afe97dc0a',
  onboardingPayload: '8738bf8f-731d-4707-9242-3c9a47ace47e',
};

export const SESSION_KEYS = {
  onboardingPaymentId: '493af2a7-c7fb-4f9a-8afe-6694c31c8413',
  isPerformingDoctorOnboardingDuringFirstSession: 'bcf364a3-9eb8-4f2a-993f-188cb8023051',
  acceptedPrivacyPolicy: 'f2d89fde-34e2-4d55-965d-a08629397a0d',
};
