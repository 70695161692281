import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-conversations-communication-page.component',
  templateUrl: './conversations-communication-page.component.html',
  styleUrls: ['./conversations-communication-page.component.scss'],
})
@UntilDestroy()
export class ConversationsCommunicationPageComponent {

}
