<ng-container *ngIf="visits.length === 0" [ngTemplateOutlet]="noImpactedPatientsTemplate"></ng-container>
<ng-container *ngIf="visits.length > 0" [ngTemplateOutlet]="impactedPatientsTemplate"></ng-container>

<ng-template #noImpactedPatientsTemplate>
  <vh-dialog-frame
    [title]="'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.title' | translate"
    (closeClicked)="onCancelClick()">
    <p>
      {{ 'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.informationTextNoPatientsImpacted' | translate }}
    </p>
    <mat-dialog-actions class="flex-row-c-c gap-h-8">
      <vh-button
        [label]="'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.confirmationAction' | translate"
        [style]="ButtonStyle.PRIMARY"
        (buttonClick)="onConfirmClicked()"></vh-button>
      <vh-button [label]="'common.cancel' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="onCancelClick()"></vh-button>
    </mat-dialog-actions>
  </vh-dialog-frame>
</ng-template>

<ng-template #impactedPatientsTemplate>
  <vh-dialog-frame
    [title]="'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.title' | translate"
    (closeClicked)="onCancelClick()">
    <vh-tab-menu
      [tabLabelTranslationKeys]="tabLabelTranslationKeys"
      [selectedTab]="selectedTab"
      (selectedTabChange)="onTabClicked($event)"></vh-tab-menu>

    <ng-container *ngIf="selectedTab === 1">
      <p [vhMarginTop]="16">
        {{ 'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.informationTextPatientsImpacted' | translate }}
      </p>
      <vh-cancel-visit-form [vhMarginTop]="32" [formGroup]="cancellationFormGroup" (formGroupUpdated)="onFormGroupUpdated($event)">
      </vh-cancel-visit-form>

      <mat-dialog-actions [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
        <vh-button
          [label]="'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.confirmationAction' | translate"
          [style]="ButtonStyle.PRIMARY"
          (buttonClick)="onConfirmClicked()"></vh-button>
        <vh-button [label]="'common.cancel' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="onCancelClick()"></vh-button>
      </mat-dialog-actions>
    </ng-container>

    <ng-container *ngIf="selectedTab === 2">
      <table class="patients-list" [vhMarginTop]="32">
        <thead>
          <tr>
            <th>{{ 'common.fields.start' | translate }}</th>
            <th>{{ 'common.fields.end' | translate }}</th>
            <th>{{ 'common.fields.name' | translate }}</th>
            <th>{{ 'common.fields.phone' | translate }}</th>
            <th>{{ 'common.fields.email' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let visit of visits" class="patient-row">
            <td>
              <p>{{ visit.start | dfnsFormat : DateFormat.clientDate }}</p>
              <p>{{ visit.start | dfnsFormat : DateFormat.clientTimeShort }}</p>
            </td>
            <td>
              <p>{{ visit.end | dfnsFormat : DateFormat.clientDate }}</p>
              <p>{{ visit.end | dfnsFormat : DateFormat.clientTimeShort }}</p>
            </td>
            <td>{{ visit.patient?.fullName }}</td>
            <td>{{ visit.patient?.phone }}</td>
            <td>{{ visit.patient?.email }}</td>
          </tr>
        </tbody>
      </table>
      <mat-dialog-actions>
        <vh-button
          [vhMarginTop]="8"
          class="align-center"
          [style]="ButtonStyle.PRIMARY"
          iconLeftPath="assets/icons/clipboard-notes-white.svg"
          [label]="'common.copyToClipboard' | translate"
          (buttonClick)="copyToClipboard()"></vh-button>
        <p *ngIf="showCopiedToClipboardConfirmation" class="has-text-primary align-center font-light-12-14" [vhMarginTop]="4">
          {{ 'admin.pages.slotsOverview.slotsPage.showImpactedPatientsDialog.copyConfirmation' | translate }}
        </p>
      </mat-dialog-actions>
    </ng-container>
  </vh-dialog-frame>
</ng-template>
