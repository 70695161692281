export enum DoctorOnboardingState {

  /**
   * The user made an account for themselves.
   */
  REGISTERED = 'registered',

  /**
   * The user has created their own institution.
   */
  INSTITUTION_CREATED = 'institution_created',

  /**
   * The user has added a payment option.
   */
  PAYMENT_OPTION_ADDED = 'payment_option_added',

  /**
   * The user has completed the onboarding process.
   */
  DONE = 'done',
}
