import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Mode } from '@enums/mode.enum';
import { AuthenticationService } from '@services/authentication.service';

@Directive({
  selector: '[vhRequiresMode]',
})
export class RequiresModeDirective {
  @Input() set vhRequiresMode(mode: Mode) {
    this.updateView(mode);
  }

  constructor(
    protected readonly element: ElementRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authenticationService: AuthenticationService
  ) {}

  private updateView(mode: Mode): void {
    if (this.authenticationService.mode === mode) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    this.viewContainer.clear();
  }
}
