import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { LoginAndRegisterPageComponent } from '@modules/account/pages/login-and-register-page/login-and-register-page.component';
import { ResetPasswordPageComponent } from '@modules/account/pages/reset-password-page/reset-password-page.component';
import { CoreModule } from '@modules/shared/core/core.module';
import { ExpiredLinkPageComponent } from '@modules/account/pages/expired-link-page/expired-link-page.component';
import { ProfilePageComponent } from '@modules/account/pages/profile-page/profile-page.component';
import { NgxMaskPipe } from 'ngx-mask';
import { NgOptimizedImage } from '@angular/common';
import { FormatPipeModule } from 'ngx-date-fns';
import { ForgotPasswordPageComponent } from '@modules/account/pages/forgot-password-page/forgot-password-page.component';
import { ForgotUsernamePageComponent } from '@modules/account/pages/forgot-username-page/forgot-username-page.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { unauthenticatedGuard } from '@guards/unauthenticated.guard';
import { UpdatePasswordPageComponent } from '@modules/account/pages/update-password-page/update-password-page.component';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { FormModule } from '@modules/shared/form/form.module';
import { AutomaticTopUpSettingsDialogComponent } from '@modules/account/dialogs/automatic-top-up-settings-dialog/automatic-top-up-settings-dialog.component';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { PostActionStatusComponent } from '@modules/account/components/post-action-status/post-action-status.component';
import { VerifyEmailSuccessPageComponent } from '@modules/account/pages/verify-email-success-page/verify-email-success-page.component';
import { RegisterSuccessPageComponent } from '@modules/account/pages/register-success-page/register-success-page.component';

const routes: Routes = [
  {
    path: NAVIGATION.root.path,
    redirectTo: NAVIGATION.login.path,
    pathMatch: 'full',
  },
  {
    path: NAVIGATION.login.path,
    component: LoginAndRegisterPageComponent,
    canActivate: [unauthenticatedGuard()],
  },
  {
    path: NAVIGATION.register.path,
    component: LoginAndRegisterPageComponent,
    canActivate: [unauthenticatedGuard()],
  },
  {
    // this route is not protected by unauthenticatedGuard() because it can be accessed from the email link
    path: NAVIGATION.resetPassword.path,
    component: ResetPasswordPageComponent,
  },
  {
    path: NAVIGATION.forgotPassword.path,
    component: ForgotPasswordPageComponent,
    canActivate: [unauthenticatedGuard()],
  },
  {
    path: NAVIGATION.forgotUsername.path,
    component: ForgotUsernamePageComponent,
    canActivate: [unauthenticatedGuard()],
  },
  {
    path: NAVIGATION.expiredLink.path,
    component: ExpiredLinkPageComponent,
  },
  {
    path: NAVIGATION.profile.path,
    component: ProfilePageComponent,
    canActivate: [authenticationGuard()],
  },
  {
    path: NAVIGATION.updatePassword.path,
    component: UpdatePasswordPageComponent,
    canActivate: [authenticationGuard()],
  },
  {
    path: NAVIGATION.verifyEmailSuccess.path,
    component: VerifyEmailSuccessPageComponent,
  },
  {
    path: NAVIGATION.verifyEmailSuccess.path,
    component: VerifyEmailSuccessPageComponent,
  },
  {
    path: NAVIGATION.success.path,
    component: RegisterSuccessPageComponent,
  },
];

@NgModule({
  declarations: [
    LoginAndRegisterPageComponent,
    ResetPasswordPageComponent,
    ExpiredLinkPageComponent,
    ProfilePageComponent,
    ForgotPasswordPageComponent,
    ForgotUsernamePageComponent,
    UpdatePasswordPageComponent,
    VerifyEmailSuccessPageComponent,
    AutomaticTopUpSettingsDialogComponent,
    PostActionStatusComponent,
    RegisterSuccessPageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreModule,
    AccountSharedModule,
    FormModule,
    NgxMaskPipe,
    NgOptimizedImage,
    FormatPipeModule,
    MatDialogContent,
    MatDialogActions,
  ],
  exports: [],
})
export class AccountModule {}
