<mat-stepper
  orientation="vertical"
  [selected]="null"
  [linear]="true"
  [disableRipple]="true"
  [selectedIndex]="-1">

  <mat-step
    *ngFor="let item of items"
    [completed]="true"
    [state]="'done'"
    [editable]="false"
    [label]="item">
  </mat-step>
</mat-stepper>
