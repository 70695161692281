import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-onboarding-payment-redirect-page',
  templateUrl: './onboarding-payment-redirect-page.component.html',
  styleUrls: ['./onboarding-payment-redirect-page.component.scss'],
})
@UntilDestroy()
export class OnboardingPaymentRedirectPageComponent implements OnInit {

  private readonly REDIRECT_AFTER_MS: number = 5000;

  ngOnInit(): void {
    this.scheduleRedirect();
  }

  private scheduleRedirect(): void {
    setTimeout(function() {
      window.open('', '_self');
      window.close();
    }, this.REDIRECT_AFTER_MS);
  }
}
