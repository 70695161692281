import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';

export enum ConversationStatus {
  REMOVED = 'removed',
  PENDING = 'pending',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
  EXPIRED = 'expired',
}

export function getConversationStatusOptions(translate: TranslateService): Option[] {
  return Object.values(ConversationStatus).map((status: string) => ({
    value: status,
    label: translate.instant(`conversations.status.${status}`),
  }));
}

export function isConversationInactive(status: ConversationStatus): boolean {
  return status === ConversationStatus.REMOVED || status === ConversationStatus.EXPIRED || status === ConversationStatus.FINISHED;
}
