<vh-secondary-page (navigateBackClick)="onExitClicked()">
  <vh-content-layout [hasVerticalSpacing]="true" [hasHorizontalSpacing]="true">
    <div class="flex-row-l-c">
      <div class="flex-col">
        <h1>{{ 'pages.createAppointment.submitted.title' | translate }}</h1>
        <p>{{ 'pages.createAppointment.submitted.intro1' | translate }}</p>
      </div>
      <vh-countdown-timer *vhRequiresMode="Mode.ADMIN" class="align-end" [timeLimit]="5" (timeUp)="exitAndClearState()"></vh-countdown-timer>
    </div>

    <vh-dynamic-visit-card [vhMarginTop]="16" [visits]="bookingState.booking.visits"></vh-dynamic-visit-card>
    <vh-user-card [user]="bookingState.booking.patient"></vh-user-card>

    <ng-template *vhRequiresMode="Mode.ADMIN" [ngTemplateOutlet]="adminContentTemplate"></ng-template>
    <ng-template *vhRequiresMode="Mode.PRIVATE" [ngTemplateOutlet]="patientContentTemplate"></ng-template>

    <vh-button
      [vhMarginTop]="32"
      [label]="'common.close' | translate"
      [iconRightPath]="'assets/icons/arrow-right-white.svg'"
      [alignment]="'center'"
      (buttonClick)="exitAndClearState()">
    </vh-button>
  </vh-content-layout>
</vh-secondary-page>

<ng-template #adminContentTemplate>
</ng-template>

<ng-template #patientContentTemplate>
  <p [vhMarginTop]="16">{{ 'pages.createAppointment.submitted.text' | translate }}</p>
  <vh-task-list
    *ngIf="bookingState.booking?.tasks?.length"
    [vhMarginTop]="32"
    [visit]="bookingState.booking.leadingVisit"
    [title]="'pages.viewAppointmentTasks.listLabel' | translate">
  </vh-task-list>
</ng-template>
