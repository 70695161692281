import { Injectable } from '@angular/core';
import { CareProgram, CareProgramJson } from '@models/care-program.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IObject } from '@app-types/iobject.type';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CareProgramService {
  private readonly httpOptions: IObject = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  getCareProgramsOfInstitution$ = (institutionId: string): Observable<CareProgram[]> => {
    const endpoint: string = ENDPOINTS.getCareProgramsOfInstitution.route
      .replace(`{${ENDPOINTS.getCareProgramsOfInstitution.pathParams.institutionId}}`, institutionId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: CareProgramJson[]): CareProgram[] => json.map(CareProgram.fromJson)));
  };

  getCareProgramById$ = (careProgramId: string): Observable<CareProgram> => {
    const endpoint: string = ENDPOINTS.getCareProgramById.route
      .replace(`{${ENDPOINTS.getCareProgramById.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getCareProgramById.pathParams.careProgramId}}`, careProgramId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(CareProgram.fromJson));
  };

  createCareProgram$ = (careProgram: IObject): Observable<CareProgram> => {
    const endpoint: string = ENDPOINTS.createCareProgram.route
      .replace(`{${ENDPOINTS.createCareProgram.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const requestParams: IObject = {
      ...this.httpOptions,
    };

    return this.http
      .post(environment.apiBaseUrl + endpoint, careProgram, requestParams)
      .pipe(map(CareProgram.fromJson));
  };

  updateCareProgram$ = (careProgram: IObject, careProgramId: string): Observable<CareProgram> => {
    const endpoint: string = ENDPOINTS.updateCareProgram.route
      .replace(`{${ENDPOINTS.updateCareProgram.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.updateCareProgram.pathParams.careProgramId}}`, careProgramId);

    const requestParams: IObject = {
      ...this.httpOptions,
    };

    return this.http
      .put(environment.apiBaseUrl + endpoint, careProgram, requestParams)
      .pipe(map(CareProgram.fromJson));
  };

  deleteCareProgram$ = (careProgramId: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.deleteCareProgram.route
      .replace(`{${ENDPOINTS.deleteCareProgram.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.deleteCareProgram.pathParams.careProgramId}}`, careProgramId);

    const requestParams: IObject = {
      ...this.httpOptions,
    };

    return this.http
      .delete(environment.apiBaseUrl + endpoint, requestParams)
      .pipe(switchToEmptyObservable());
  };

  setCareProgramStatus$ = (careProgramId: string, status: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.careProgramSetStatus.route
      .replace(`{${ENDPOINTS.careProgramSetStatus.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.careProgramSetStatus.pathParams.careProgramId}}`, careProgramId);

    const requestParams: IObject = {
      ...this.httpOptions,
    };

    return this.http.put(environment.apiBaseUrl + endpoint, { status: status }, requestParams).pipe(switchToEmptyObservable());
  };

  duplicateCareProgram$ = (careProgram: CareProgram): Observable<CareProgram> => {
    const endpoint: string = ENDPOINTS.duplicateCareProgram.route
      .replace(`{${ENDPOINTS.duplicateCareProgram.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.duplicateCareProgram.pathParams.careProgramId}}`, careProgram.id);

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map(CareProgram.fromJson));
  };
}
