export const VISIT_FORM_KEYS: Map<string, string> = new Map([
  ['startDate', 'start_date'],
  ['startTime', 'start_time'],
  ['endTime', 'end_time'],
  ['internalComment', 'internal_comment'],
  ['externalComment', 'external_comment'],
  ['patient', 'patient'],
  ['service', 'service'],
  ['room', 'room'],
  ['recurrence', 'recurrence'],
  ['customTitle', 'custom_title'],
  ['visitType', 'visit_type'],
  ['cancelOverlappingVisits', 'cancel_overlapping_visits'],
]);
