import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SuggestionsTextInputComponent } from '@modules/shared/form/components/suggestions-text-input/suggestions-text-input.component';
import { Observable, throwError } from 'rxjs';
import { Option } from '@interfaces/option.interface';
import { catchError, map } from 'rxjs/operators';
import { GoogleApiService } from '@services/google-api.service';
import { PopoverService } from '@services/ui/popover.service';
import { ErrorMessageService } from '@services/error-message.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vh-address-suggestions-input',
  templateUrl: '../suggestions-text-input/suggestions-text-input.component.html',
  styleUrls: [
    '../suggestions-text-input/suggestions-text-input.component.scss',
    '../base-select-input/base-select-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSuggestionsInputComponent),
      multi: true,
    },
  ],
})
export class AddressSuggestionsInputComponent extends SuggestionsTextInputComponent implements OnInit {

  /**
   * In order to use the places API, you need to pass on a container where to results will be displayed.
   * This can be either a google.maps.Map or a (hidden) HTMLDivElement.
   */
  @Input() displayContainer: google.maps.Map | HTMLDivElement;

  @Output() googleSuggestionsErrorOccurred: EventEmitter<string> = new EventEmitter<string>();
  @Output() suggestionClicked: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

  constructor(
  @Optional() controlContainer: ControlContainer,
    @Optional() translateService: TranslateService,
    popoverService: PopoverService,
    errorMessageService: ErrorMessageService,
    protected readonly googleApiService: GoogleApiService
  ) {
    super(controlContainer, translateService, popoverService, errorMessageService);
  }

  ngOnInit(): void {
    if (!this.displayContainer) {
      throw new Error('displayContainer is required for AddressSuggestionsInputComponent');
    }

    this.getOptionsFunction$ = this.getPlacesSuggestions$;
    this.googleApiService.googleMapsAndPlacesApiInitialized$
      .pipe(untilDestroyed(this))
      .subscribe((isInitialised: boolean): void => {
        if (!isInitialised) {
          return;
        }

        this.googleApiService.initializeAllServices(this.displayContainer);
      });

    super.ngOnInit();
  }

  private getPlacesSuggestions$ = (searchValue: string): Observable<Option[]> => {
    return this.googleApiService.getPlacePredictions$(searchValue).pipe(
      map((placePredictions: google.maps.places.AutocompletePrediction[]): Option[] => {
        return placePredictions.map((placePrediction: google.maps.places.AutocompletePrediction): Option => {
          return {
            label: placePrediction.description,
            value: placePrediction.place_id,
          };
        });
      }),
      catchError((status: google.maps.places.PlacesServiceStatus): Observable<never> => {
        switch (status) {
          case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            this.googleSuggestionsErrorOccurred.emit('common.noResults');
            break;
          default:
            this.googleSuggestionsErrorOccurred.emit('common.unknownError');
        }

        return throwError(() => new Error(`Google Places API error: ${status}`));
      })
    );
  };

  handleOptionClick(value: string | null): void {
    super.handleOptionClick(value);
    this.resolvePlaceId(value);
  }

  resolvePlaceId(placeId: string): void {
    if (!placeId) {
      return;
    }

    this.googleApiService.getPlaceDetails$(placeId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (placeDetails: google.maps.places.PlaceResult): void => {
          this.suggestionClicked.emit(placeDetails);
        },
        error: (): void => {
          this.googleSuggestionsErrorOccurred.emit('common.unknownError');
        },
      });
  }
}
