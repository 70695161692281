import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { Room, RoomJson } from '@models/room.model';
import { AuthenticationService } from '@services/authentication.service';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { IObject } from '@app-types/iobject.type';
import { Service } from '@models/service.model';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  private _roomsOfInstitution: Room[];
  private _dataForInstitution: string;

  getRoomsOfInstitution$ = (forceLoad: boolean = false, serviceUuid: Service | null = null): Observable<Room[]> => {
    if (!forceLoad && this.roomsOfInstitution?.length !== 0) {
      return of(this.roomsOfInstitution);
    }

    let options: IObject = {};

    if (serviceUuid) {
      options = {
        params: {
          [ENDPOINTS.getRoomsOfInstitution.queryParams.service]: serviceUuid,
        },
      };
    }

    this._dataForInstitution = this.authenticationService.institution.id;
    const ENDPOINT = ENDPOINTS.getRoomsOfInstitution.route.replace(/{.*}/, this._dataForInstitution);

    return this.http.get(environment.apiBaseUrl + ENDPOINT, options)
      .pipe(map((json: RoomJson[]) => json.map(Room.fromJson)))
      .pipe(tap((rooms: Room[]) => this.roomsOfInstitution = rooms));
  };

  createRoom$ = (room: Room): Observable<Room> => {
    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.getRooms.route, room.toJSON(this.authenticationService.institution.id))
      .pipe(map((json: RoomJson): Room => Room.fromJson(json)));
  };

  getRoomById$ = (roomId: string): Observable<Room> => {
    const ENDPOINT = ENDPOINTS.getRoom.route.replace(/{.*}/, roomId.toString());

    return this.http
      .get(environment.apiBaseUrl + ENDPOINT)
      .pipe(map((json: RoomJson): Room => Room.fromJson(json)));
  };

  updateRoom$ = (roomId: string, room: Room): Observable<Room> => {
    const ENDPOINT = ENDPOINTS.getRoom.route.replace(/{.*}/, roomId.toString());

    return this.http
      .put(environment.apiBaseUrl + ENDPOINT, room.toJSON(this.authenticationService.institution.id))
      .pipe(map((json: RoomJson): Room => Room.fromJson(json)))
      .pipe(tap((updatedRoom: Room) => {
        const index = this._roomsOfInstitution.findIndex((r: Room) => r.id === updatedRoom.id);
        this._roomsOfInstitution[index] = updatedRoom;
      }));
  };

  deleteRoom$ = (roomId: string): Observable<void> => {
    const ENDPOINT = ENDPOINTS.getRoom.route.replace(/{.*}/, roomId.toString());

    return this.http.delete(environment.apiBaseUrl + ENDPOINT)
      .pipe(tap(() => {
        this.deleteRoom(roomId);
      }))
      .pipe(switchToEmptyObservable());
  };

  get roomsOfInstitution(): Room[] {
    return this._roomsOfInstitution ?? [];
  }

  set roomsOfInstitution(rooms: Room[]) {
    this._roomsOfInstitution = rooms;
  }

  deleteRoom(roomId: string): void {
    this._roomsOfInstitution = this._roomsOfInstitution.filter((r: Room) => r.id !== roomId);
  }
}
