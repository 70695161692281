import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Survey, SurveyJson } from '@models/survey.model';
import { AuthenticationService } from '@services/authentication.service';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { IObject } from '@app-types/iobject.type';
import { AnswerList, AnswerListJson } from '@models/answer-list.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { ServiceGroup, ServiceGroupJson } from '@models/service-group.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  private readonly httpOptions: IObject = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  getSurveyById$(
    surveyId: string,
    institutionId: string = null,
    language: string = null,
    loadAllTranslations: boolean = false
  ): Observable<Survey> {
    const ENDPOINT = ENDPOINTS.getSurveyById.route
      .replace(`{${ENDPOINTS.getSurveyById.pathParams.institutionId}}`, institutionId ?? this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getSurveyById.pathParams.surveyId}}`, surveyId);
    const requestParams: IObject = {
      ...this.httpOptions,
      params: {
        load_all_translations: loadAllTranslations ? 1 : 0,
      },
    };

    if (language) {
      requestParams.params.language = language;
    }

    return this.http
      .get(environment.apiBaseUrl + ENDPOINT, requestParams)
      .pipe(map(Survey.fromJson));
  }

  getSurveyDeletionImpact$(surveyId: string, institutionId: string = null): Observable<ServiceGroup[]> {
    const ENDPOINT = ENDPOINTS.getSurveyDeletionImpact.route
      .replace(`{${ENDPOINTS.getSurveyById.pathParams.institutionId}}`, institutionId ?? this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getSurveyById.pathParams.surveyId}}`, surveyId);

    return this.http
      .get(environment.apiBaseUrl + ENDPOINT, this.httpOptions)
      .pipe(map((json: ServiceGroupJson[]): ServiceGroup[] => json.map(ServiceGroup.fromJson)));
  }

  createSurvey$ = (survey: IObject, loadAllTranslations: boolean = false): Observable<Survey> => {
    const endpoint: string = ENDPOINTS.createSurvey.route
      .replace(`{${ENDPOINTS.createSurvey.pathParams.institutionId}}`, this.authenticationService.institution.id);
    const requestParams: IObject = {
      ...this.httpOptions,
      params: {
        load_all_translations: loadAllTranslations ? 1 : 0,
      },
    };

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .post(url, survey, requestParams)
      .pipe(map((json: SurveyJson): Survey => Survey.fromJson(json)));
  };

  updateSurvey$ = (surveyId: string, survey: IObject, loadAllTranslations: boolean = false): Observable<Survey> => {
    const endpoint: string = ENDPOINTS.updateSurvey.route
      .replace(`{${ENDPOINTS.updateSurvey.pathParams.surveyId}}`, surveyId)
      .replace(`{${ENDPOINTS.updateSurvey.pathParams.institutionId}}`, this.authenticationService.institution.id);
    const requestParams: IObject = {
      ...this.httpOptions,
      params: {
        load_all_translations: loadAllTranslations ? 1 : 0,
      },
    };

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .put(url, survey, requestParams)
      .pipe(map((json: SurveyJson): Survey => Survey.fromJson(json)));
  };

  deleteSurvey$ = (surveyId: string): Observable<void> => {
    const endpoint: string = environment.apiBaseUrl + ENDPOINTS.updateSurvey.route
      .replace(`{${ENDPOINTS.updateSurvey.pathParams.surveyId}}`, surveyId)
      .replace(`{${ENDPOINTS.updateSurvey.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http
      .delete(endpoint, this.httpOptions)
      .pipe(switchToEmptyObservable());
  };

  getSurveyTask$ = (visitId: string, taskId: string): Observable<AnswerList> => {
    const endpoint: string = ENDPOINTS.getSurveyTask.route
      .replace(`{${ENDPOINTS.getSurveyTask.pathParams.visitId}}`, visitId)
      .replace(`{${ENDPOINTS.getSurveyTask.pathParams.taskId}}`, taskId);

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .get(url, this.httpOptions)
      .pipe(map((listJson: AnswerListJson): AnswerList => AnswerList.fromJson(listJson)));
  };

  submitAnswerList$ = (answerList: IObject, taskId: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.submitAnswerList.route
      .replace(`{${ENDPOINTS.submitAnswerList.pathParams.visitId}}`, answerList.visit_id)
      .replace(`{${ENDPOINTS.submitAnswerList.pathParams.taskId}}`, taskId)
      .replace(`{${ENDPOINTS.submitAnswerList.pathParams.answersListId}}`, answerList.id);

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .post(url, answerList, this.httpOptions)
      .pipe(switchToEmptyObservable());
  };

  getSurveysOfInstitution$ = (): Observable<Survey[]> => {
    const endpoint: string = ENDPOINTS.getSurveys.route.replace(
      `{${ENDPOINTS.getSurveys.pathParams.institutionId}}`,
      this.authenticationService.institution.id
    );

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: SurveyJson[]): Survey[] => json.map((surveyJson: SurveyJson) => Survey.fromJson(surveyJson))));
  };
}
