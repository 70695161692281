import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ServiceGroup } from '@models/service-group.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceGroupService } from '@services/service-group.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SERVICE_GROUP_FORM_KEYS } from '@constants/form-keys/service-group-form-keys.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { Service } from '@models/service.model';
import { Option } from '@interfaces/option.interface';
import { COLORS } from '@constants/colors.constant';
import { Survey } from '@models/survey.model';
import { ModuleKey } from '@enums/module-key.enum';
import { ServiceGroupFormService } from '@services/form-services/service-group-form.service';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { popInOutAnimation } from '@modules/shared/core/animations/pop/pop-in-out.animation';

interface SurveyFormProperties {
  is_active: boolean;
  active_from: Date;
  active_to: Date;
}

@Component({
  selector: 'vh-service-group-settings',
  templateUrl: './service-group-settings.component.html',
  styleUrls: ['./service-group-settings.component.scss'],
  animations: [popInOutAnimation],
})
export class ServiceGroupSettingsComponent implements OnInit, OnChanges {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ModuleKey: typeof ModuleKey = ModuleKey;
  protected readonly SERVICE_GROUP_FORM_KEYS: typeof SERVICE_GROUP_FORM_KEYS = SERVICE_GROUP_FORM_KEYS;
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  errorMessageTranslationKey: string;
  formGroup: FormGroup;
  mode: 'read' | 'edit' = 'read';
  serviceOptions: Option[];
  amountOfSkeletons: number = 3;
  surveyOptions: Option[];
  surveys: FormArray;
  currentDateString: string;

  @Input() serviceGroup: ServiceGroup;
  @Input() parentService: Service;
  @Input() isLoading: boolean;
  @Input() surveysForInstitution: Survey[];

  @Output() serviceGroupCreated: EventEmitter<ServiceGroup> = new EventEmitter<ServiceGroup>();
  @Output() serviceGroupUpdated: EventEmitter<ServiceGroup> = new EventEmitter<ServiceGroup>();
  @Output() editClick: EventEmitter<ServiceGroup> = new EventEmitter<ServiceGroup>();
  @Output() removeClick: EventEmitter<ServiceGroup> = new EventEmitter<ServiceGroup>();

  createServicesSubscription: Subscription;
  updateServicesSubscription: Subscription;

  constructor(
    private readonly serviceGroupFormService: ServiceGroupFormService,
    private readonly serviceGroupService: ServiceGroupService
  ) {
    this.currentDateString = format(new Date(), DATE_FORMATS.serverDate);
  }

  ngOnInit(): void {
    this.resetFormGroup();
    this.rebuildServiceOptions();
    this.rebuildSurveyOptions();
    this.mode = this.serviceGroup?.id ? 'read' : 'edit';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentService && !changes.parentService.isFirstChange()) {
      this.rebuildServiceOptions();
    }
  }

  createServiceGroup = (): void => {
    this.formGroup.markAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    this.formGroup.get([SERVICE_GROUP_FORM_KEYS.get('services')]).setValue(this.serviceGroup.services.map((s: Service) => s.id));

    this.createServicesSubscription?.unsubscribe();
    this.createServicesSubscription = this.serviceGroupService.createServiceGroup$(this.formGroup.value).subscribe({
      next: (group: ServiceGroup): void => {
        this.createServicesSubscription = null;
        this.formGroup.reset();
        this.serviceGroup = group;
        this.mode = 'read';
        this.serviceGroupCreated.emit(this.serviceGroup);
      },
      error: (error: HttpErrorResponse | unknown): void => {
        this.createServicesSubscription = null;
        this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
      },
    });
  };

  updateServiceGroup = (serviceGroup: ServiceGroup): void => {
    this.formGroup.get([SERVICE_GROUP_FORM_KEYS.get('services')]).setValue(this.serviceGroup.services.map((s: Service) => s.id));

    this.updateServicesSubscription?.unsubscribe();
    this.updateServicesSubscription = this.serviceGroupService.updateServiceGroup$(this.formGroup.value, serviceGroup.id).subscribe({
      next: (group: ServiceGroup): void => {
        this.updateServicesSubscription = null;
        this.formGroup.reset();
        this.serviceGroup = group;
        this.mode = 'read';
        this.serviceGroupUpdated.emit();
      },
      error: (error: HttpErrorResponse | unknown): void => {
        this.updateServicesSubscription = null;
        this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
      },
    });
  };

  activeFromChange(event: Event, index: number): void {
    this.formGroup.controls.surveys.value[index].active_from = (event.target as HTMLInputElement).value;

    this.setActiveSurvey(index);
  }

  activeToChange(event: Event, index: number): void {
    this.formGroup.controls.surveys.value[index].active_to = (event.target as HTMLInputElement).value;

    this.setActiveSurvey(index);
  }

  onEditServiceGroupClicked = (): void => {
    this.mode = 'edit';
    this.resetFormGroup();
    this.editClick.emit(this.serviceGroup);
  };

  onRemoveServiceGroupClicked = (group: ServiceGroup): void => {
    this.removeClick.emit(group);
  };

  onServiceSelected = (serviceId: string): void => {
    const service = this.parentService.children.find((s: Service) => s.id === serviceId);

    if (!service) {
      return;
    }

    this.serviceGroup.services.push(service);
    this.formGroup.get([SERVICE_GROUP_FORM_KEYS.get('services')]).setValue(null);
    this.rebuildServiceOptions();
  };

  onSurveySelected = (surveyId: string): void => {
    const survey = this.surveysForInstitution.find((s: Survey) => s.id === surveyId);

    if (!survey) {
      return;
    }

    this.surveys.push(this.serviceGroupFormService.createSurveyFormGroup(survey));
    this.rebuildSurveyOptions();
  };

  onRemoveServiceClicked = (service: Service): void => {
    const serviceIndex = this.serviceGroup.services.findIndex((s: Service) => s.id === service.id);

    if (serviceIndex === -1) {
      return;
    }

    this.serviceGroup.services.splice(serviceIndex, 1);
    this.formGroup.get([SERVICE_GROUP_FORM_KEYS.get('services')]).setValue(null);
    this.rebuildServiceOptions();
  };

  onRemoveSurveyClicked = (survey: Survey, index: number): void => {
    const surveyIndex = index;

    if (surveyIndex === -1) {
      return;
    }

    this.surveys.removeAt(surveyIndex);
    this.setActiveSurvey();
    this.rebuildSurveyOptions();
  };

  onCancelClick = (): void => {
    if (!this.serviceGroup.id) {
      this.removeClick.emit(this.serviceGroup);

      return;
    }

    this.mode = 'read';
  };

  private resetFormGroup(): void {
    this.formGroup = this.serviceGroupFormService.createFormGroup(this.serviceGroup);
    this.surveys = this.formGroup.controls.surveys as FormArray;
  }

  private getHttpErrorMessageTranslationKey = (error: HttpErrorResponse | unknown): string => {
    if (!(error instanceof HttpErrorResponse && error.status === 422)) {
      return 'common.unknownError';
    }
  };

  private rebuildServiceOptions = (): void => {
    const serviceGroupServiceIds = this.serviceGroup.services?.map((s: Service) => s.id);

    this.serviceOptions = this.parentService?.children
      .filter((service: Service) => !serviceGroupServiceIds?.includes(service.id))
      .map((service: Service) => service.toOption());
  };

  private rebuildSurveyOptions = (): void => {
    if (!this.surveysForInstitution) {
      return;
    }

    const surveyIds: string[] = [];

    for (const control of this.surveys.controls) {
      const id = control.value.id;
      surveyIds.push(id);
    }

    this.surveyOptions = this.surveysForInstitution
      .filter((survey: Survey) => !surveyIds.includes(survey.id))
      .map((survey: Survey) => survey.toOption());
  };

  private setActiveSurvey = (index: number = null): void => {
    // Sort array rows by active_from, from most recent down to the oldest date
    const surveys: FormArray<FormControl<SurveyFormProperties>> = this.formGroup.controls.surveys as FormArray<FormControl<SurveyFormProperties>>;

    if (index) {
      const dates = surveys.value.map((s: SurveyFormProperties) => s.active_from);

      // Use separate array for preserving formArray order. Otherwise, the validation isn't working as expected
      // See https://angular.io/api/forms/FormArray#adding-or-removing-controls-from-a-form-array
      const sortedDates = dates.sort((a: Date, b: Date) => {
        const date1 = new Date(a);
        const date2 = new Date(b);
        if (date1.getTime() > date2.getTime()) {
          return -1;
        }
        if (date1.getTime() < date2.getTime()) {
          return 1;
        }

        return 0;
      });

      // Use new position of the value in the dates array, apply it to form array
      const newIndex = sortedDates.indexOf(surveys.value[index].active_from);
      const currentGroup = surveys.at(index);
      surveys.removeAt(index);
      surveys.insert(newIndex, currentGroup);
    }

    // reset is_active to false for all array rows
    surveys.value.forEach((s: SurveyFormProperties) => {
      s.is_active = false;
    });

    // Check for every entry in the sorted list if active_from is before now. If it is, set isActive to true and stop the loop.
    surveys.value.every((s: SurveyFormProperties) => {
      const now = new Date(Date.now());
      const dateFrom = new Date(s.active_from);
      const dateTo = s.active_to ? new Date(s.active_to) : now;
      if (dateFrom <= now && dateTo >= now) {
        s.is_active = true;

        return false;
      }

      return true;
    });

    this.formGroup.controls.surveys.setValue(surveys.value);
  };
}
