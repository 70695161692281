import { Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'vh-wysiwyg-input',
  templateUrl: './wysiwyg-input.component.html',
  styleUrls: ['./wysiwyg-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygInputComponent),
      multi: true,
    },
  ],
})
export class WysiwygInputComponent extends QuillEditorComponent {
  @HostBinding('style.position') position: string = 'relative';

  @Input() isMultiLanguage: boolean = false;
  @Input() languageOptions: Option[] = [];
  @Input() selectedLanguage: string | null = null;

  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();
}
