import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { EXPORT_INSTITUTION_DATA_FORM_KEYS } from '@constants/form-keys/export-institution-data-form-keys.constant';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Injectable({
  providedIn: 'root',
})
export class ExportInstitutionDataFormService extends AbstractFormService {
  createFormGroup(from: Date, to: Date): UntypedFormGroup {
    return this.formBuilder.group({
      [EXPORT_INSTITUTION_DATA_FORM_KEYS.get('from')]: [
        format(from, DATE_FORMATS.serverDate),
        [Validators.required],
      ],
      [EXPORT_INSTITUTION_DATA_FORM_KEYS.get('to')]: [
        format(to, DATE_FORMATS.serverDate),
        [Validators.required],
      ],
      [EXPORT_INSTITUTION_DATA_FORM_KEYS.get('export_tasks')]: [
        // Below is hardcoded for now as there is only one export type at this point in time
        ['referral_letters'],
        [Validators.required],
      ],
    });
  }
}
