/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const CONTACT_FORM_KEYS: Map<string, string> = new Map();
CONTACT_FORM_KEYS.set('firstName', 'first_name');
CONTACT_FORM_KEYS.set('lastName', 'last_name');
CONTACT_FORM_KEYS.set('phone', 'phone');
CONTACT_FORM_KEYS.set('email', 'email');
