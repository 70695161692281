import { Directive } from '@angular/core';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OptionalVisitDetailsDialogData, VisitDetailsDialogComponent, VisitDetailsDialogData, VisitDetailsDialogResult } from '@modules/shared/visits/dialogs/visit-details-dialog/visit-details-dialog.component';
import { OtherVisitDetailsDialogComponent } from '@modules/shared/visits/dialogs/other-visit-details-dialog/other-visit-details-dialog.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Visit } from '@models/visit.model';
import { VisitCalendarMetaType, CalendarEventMapperService } from '@services/calendar-event-mapper.service';
import { MatDialog } from '@angular/material/dialog';
import { Option } from '@interfaces/option.interface';
import { OnManageState } from '@interfaces/on-manage-state.interface';
import { CalendarEvent } from 'angular-calendar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isValid, parse, startOfDay } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

/**
 * This class shares some common logic for views that handle showing and managing visits in an overview.
 */
@Directive()
export abstract class BaseVisitManagementComponent extends BreakpointObserverComponent implements OnManageState {
  isAutoRefreshEnabled: boolean = true;
  filter: VisitFilter = new VisitFilter();
  selectedVisit: Visit = null;
  visitStatusOptions: Option[] = [];
  events: CalendarEvent<VisitCalendarMetaType>[] = [];
  selectedDate: Date;

  protected autoRefreshEnabledUserPreference: boolean = true;
  protected visits: Visit[] = [];
  protected loadVisitsSubscription: Subscription = null;

  protected constructor(
    breakpointObserver: BreakpointObserver,
    protected readonly dialogService: MatDialog,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly calendarEventMapperService: CalendarEventMapperService
  ) {
    super(breakpointObserver);
    this.selectedDate = startOfDay(new Date());
  }

  onStoreState(params?: Params): void {
    void this.router.navigate([this.getCurrentRoute()], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      fragment: this.selectedVisit?.id,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  onRestoreState(): void {
    const params: Params = this.activatedRoute.snapshot.queryParams;

    if (params.date) {
      const parsedDate = parse(params.date, DATE_FORMATS.clientDate, new Date());
      this.selectedDate = isValid(parsedDate) ? parsedDate : new Date();
    }

    if (this.getSelectedVisitIdFromRouteFragment()) {
      this.selectedVisit = new Visit(this.getSelectedVisitIdFromRouteFragment());
    }
  }

  protected onVisitChanged(newVisit: Visit): void {
    const visitIndex: number = this.visits.findIndex((visit: Visit) => visit.id === newVisit.id);

    this.visits[visitIndex] = newVisit;

    this.recreateEvent(visitIndex);
  }

  protected onVisitsLoaded(): void {
    this.selectedVisit = null;
    this.loadVisitsSubscription = null;
    this.recreateEvents();

    if (this.getSelectedVisitIdFromRouteFragment()) {
      this.selectedVisit = this.visits.find((visit: Visit) => visit.id === this.getSelectedVisitIdFromRouteFragment()) ?? null;
    }

    if (this.selectedVisit) {
      this.openVisitDetailsDialog();
    }
  }

  protected onVisitDetailsDialogClosed(result?: VisitDetailsDialogResult): void {
    this.autoResumeAutoRefresh();
    this.selectedVisit = null;
    this.onStoreState();

    if (result?.canceledVisit) {
      this.visits = this.visits.filter((visit: Visit) => visit.id !== result.canceledVisit.id);
      this.recreateEvents();

      return;
    }

    if (result?.visitChanged) {
      this.onVisitChanged(result.visitChanged);
    }
  }

  /**
   * This function recreates the event at the given index in the events array. The syntax may seem weird but by re-assigning the events array
   * in this particular way, Angular is able to detect the changes without recreating the whole array.
   */
  protected recreateEvent(event: number | Visit | CalendarEvent<VisitCalendarMetaType>): void {
    if (typeof event === 'number') {
      this.events = [
        ...this.events.slice(0, event),
        this.calendarEventMapperService.visitToCalenderEvent(this.visits[event]),
        ...this.events.slice(event + 1),
      ];

      return;
    }

    if (event instanceof Visit) {
      const index: number = this.events.findIndex((calendarEvent: CalendarEvent) => calendarEvent.id === event.id);
      this.events = [
        ...this.events.slice(0, index),
        this.calendarEventMapperService.visitToCalenderEvent(this.visits[index]),
        ...this.events.slice(index + 1),
      ];
    }
  }

  protected recreateEvents(): void {
    this.events = this.calendarEventMapperService.visitsToCalendarEvents(this.visits);
  }

  protected openVisitDetailsDialog(data: OptionalVisitDetailsDialogData = null): void {
    const dialogData: VisitDetailsDialogData = {
      ...data,
      visit: this.selectedVisit,
      visitStatusOptions: this.visitStatusOptions,
    };

    this.autoPauseAutoRefresh();
    this.dialogService
      .open(VisitDetailsDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: VisitDetailsDialogResult) => {
        this.onVisitDetailsDialogClosed(result);
      });
  }

  protected openOtherVisitDetailsDialog(data: OptionalVisitDetailsDialogData = null): void {
    const dialogData: VisitDetailsDialogData = {
      ...data,
      visit: this.selectedVisit,
      visitStatusOptions: this.visitStatusOptions,
    };

    this.autoPauseAutoRefresh();
    this.dialogService
      .open(OtherVisitDetailsDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: VisitDetailsDialogResult) => {
        this.onVisitDetailsDialogClosed(result);
      });
  }

  /**
   * When the auto-refresh mechanism is enabled, but the user is interacting with the view, we want to pause the auto-refresh mechanism.
   * However, we want to remember the user's preference for auto-refresh so that we can resume it when the user is done interacting.
   * @protected
   */
  protected autoPauseAutoRefresh(): void {
    this.autoRefreshEnabledUserPreference = this.isAutoRefreshEnabled;
    this.isAutoRefreshEnabled = false;
  }

  /**
   * When the user is done interacting with the view, we want to resume the auto-refresh mechanism if the user had it enabled before interacting.
   * @protected
   */
  protected autoResumeAutoRefresh(): void {
    this.isAutoRefreshEnabled = this.autoRefreshEnabledUserPreference;
  }

  protected getSelectedVisitIdFromRouteFragment(): string {
    return this.activatedRoute.snapshot.fragment;
  }

  protected abstract getCurrentRoute(): string;
}
