import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { Doctor } from '@models/doctor.model';
import { ConversationQuestionList } from '@models/conversation-question-list.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreateConversationService {
  private _user: User | null = null;
  private _email: string | null = null;
  private _phone: string | null = null;
  private _doctor: Doctor | null = null;
  private _questionList: ConversationQuestionList | null = null;
  private _dueDate: Date | null = null;
  private _resultsEmail: string | null = null;
  private _language: string | null = null;

  private _setStepSource: Subject<number> = new Subject<number>();
  setStep$: Observable<number> = this._setStepSource.asObservable();

  setStep(step: number): void {
    this._setStepSource.next(step);
  }

  reset(): void {
    this._user = null;
    this._email = null;
    this._phone = null;
    this._doctor = null;
    this._questionList = null;
    this._dueDate = null;
    this._resultsEmail = null;
    this._language = null;
    this.setStep(1);
  }

  set user(user: User) {
    this._user = user;
  }

  get user(): User | null {
    return this._user;
  }

  set email(email: string) {
    this._email = email;
  }

  get email(): string | null {
    return this._email;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  get phone(): string | null {
    return this._phone;
  }

  set doctor(doctor: Doctor) {
    this._doctor = doctor;
  }

  get doctor(): Doctor | null {
    return this._doctor;
  }

  set questionList(questionList: ConversationQuestionList) {
    this._questionList = questionList;
  }

  get questionList(): ConversationQuestionList | null {
    return this._questionList;
  }

  set dueDate(dueDate: Date) {
    this._dueDate = dueDate;
  }

  get dueDate(): Date | null {
    return this._dueDate;
  }

  set resultsEmail(email: string) {
    this._resultsEmail = email;
  }

  get resultsEmail(): string | null {
    return this._resultsEmail;
  }

  get language(): string | null {
    return this._language;
  }

  set language(value: string | null) {
    this._language = value;
  }
}
