import { User, UserJson } from '@models/user.model';
import { Institution, InstitutionJson } from '@models/institution.model';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { CommunicationMethod } from '@enums/communication-methods';
import { ConversationEvent, ConversationEventJson } from '@models/conversation-event.model';

export class Conversation {
  constructor(
    private _id: string,
    private _patient: User,
    private _doctor: User,
    private _dueDate: Date | null,
    private _createdAt: Date | null,
    private _finishedAt: Date | null,
    private _status: ConversationStatus,
    private _communicationMethod: CommunicationMethod,
    private _questionList: string,
    private _language: string,
    private _cause: string | null = null,
    private _institution: Institution | null = null,
    private _activeWhatsapp: boolean | null = null,
    private _chatUrl: string | null = null,
    private _summary: string | null = null,
    private _summaryJson: Record<string, unknown> | null = null,
    private _summaryUpdatedAt: Date | null = null,
    private _events: ConversationEvent[] | null = null
  ) { }

  get id(): string {
    return this._id;
  }

  get patient(): User {
    return this._patient;
  }

  get doctor(): User {
    return this._doctor;
  }

  get dueDate(): Date | null {
    return this._dueDate;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get finishedAt(): Date {
    return this._finishedAt;
  }

  get status(): ConversationStatus {
    return this._status;
  }

  set status(value: ConversationStatus) {
    this._status = value;
  }

  get communicationMethod(): CommunicationMethod {
    return this._communicationMethod;
  }

  get questionList(): string {
    return this._questionList;
  }

  get institution(): Institution {
    return this._institution;
  }

  get activeWhatsapp(): boolean {
    return this._activeWhatsapp;
  }

  get chatUrl(): string {
    return this._chatUrl;
  }

  get summary(): string {
    return this._summary;
  }

  get summaryJson(): Record<string, unknown> {
    return this._summaryJson;
  }

  get summaryUpdatedAt(): Date | null {
    return this._summaryUpdatedAt;
  }

  get events(): ConversationEvent[] {
    return this._events;
  }

  get language(): string {
    return this._language;
  }

  get cause(): string | null {
    return this._cause;
  }

  static fromJson(json: ConversationJson): Conversation {
    return json
      ? new Conversation(
        json.id,
        User.fromJson(json.patient),
        User.fromJson(json.doctor),
        json.due_date ? new Date(json.due_date) : null,
        json.created_at ? new Date(json.created_at) : null,
        json.finished_at ? new Date(json.finished_at) : null,
        json.status,
        json.communication_method,
        json.question_list,
        json.language,
        json.cause,
        json.institution ? Institution.fromJson(json.institution) : null,
        json.active_whatsapp,
        json.chat_url,
        json.summary,
        json.summary_json ? JSON.parse(json.summary_json) : null,
        json.summary_updated_at ? new Date(json.summary_updated_at) : null,
        json.events ? json.events.map((event: ConversationEventJson) => ConversationEvent.fromJson(event)) : null
      )
      : null;
  }
}

export interface ConversationMinimalJson {
  id: string;
  patient: UserJson;
  doctor: UserJson;
  due_date: string | null;
  created_at: string;
  finished_at: string;
  status: ConversationStatus;
  communication_method: CommunicationMethod;
  question_list: string;
  language: string;
  cause: string | null;
}

export interface ConversationJson extends ConversationMinimalJson {
  institution?: InstitutionJson;
  active_whatsapp?: boolean | null;
  chat_url?: string;
  summary?: string | null;
  summary_json?: string | null;
  summary_updated_at?: string | null;
  events?: ConversationEventJson[];
}
