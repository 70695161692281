import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Service } from '@models/service.model';
import { SERVICE_FORM_KEYS } from '@constants/form-keys/service-form-keys.constant';
import { IObject } from '@app-types/iobject.type';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormService extends AbstractFormService {
  createFormGroup(language: string, service: Service = null): UntypedFormGroup {
    return this.formBuilder.group({
      [SERVICE_FORM_KEYS.get('code')]: [
        service?.code,
        [],
      ],
      [SERVICE_FORM_KEYS.get('duration')]: [
        service?.duration,
        [Validators.required],
      ],
      [SERVICE_FORM_KEYS.get('can_be_booked_by_patient')]: [
        service?.canBeBookedByPatient ?? true,
        [Validators.required],
      ],
      [SERVICE_FORM_KEYS.get('is_public')]: [
        service?.isPublic ?? true,
        [Validators.required],
      ],
      [SERVICE_FORM_KEYS.get('service')]: [
        service?.id,
        [Validators.required],
      ],
      [SERVICE_FORM_KEYS.get('alternative_name')]: [
        service?.alternativeName?.get(language),
        null,
      ],
      [SERVICE_FORM_KEYS.get('previous_service_id')]: [
        service?.id,
        null,
      ],
    });
  }

  toRequestObject(formGroup: UntypedFormGroup, language: string): IObject {
    const data: IObject = formGroup.value;

    data[SERVICE_FORM_KEYS.get('alternative_name')] = {
      [language]: formGroup.get([SERVICE_FORM_KEYS.get('alternative_name')])?.value ?? null,
    };

    return data;
  }
}
