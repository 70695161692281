import { InstitutionService } from '@services/institution.service';
import { Injectable } from '@angular/core';
import { Visit, VisitJson } from '@models/visit.model';
import { InstitutionSettings } from '@models/institution-settings.model';
import { differenceInHours } from 'date-fns';
import { AuthenticationService } from '@services/authentication.service';
import { IObject } from '@app-types/iobject.type';
import { Observable, of } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { VisitService } from '@services/visits/visit.service';
import { Role } from '@enums/role.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class VisitCancellationService {
  constructor(
    private translate: TranslateService,
    private readonly http: HttpClient,
    private readonly visitService: VisitService,
    private readonly authenticationService: AuthenticationService,
    private readonly institutionService: InstitutionService
  ) {
  }

  cancelVisit$ = (visitId: string, body: IObject = null): Observable<void> => {
    const endpoint: string = ENDPOINTS.cancelVisit.route
      .replace(/{.*}/, visitId);

    return this.http
      .post(environment.apiBaseUrl + endpoint, body)
      .pipe(switchToEmptyObservable())
      .pipe(tap(() => {
        this.visitService.visits = this.visitService.visits?.filter((visit: Visit) => visit.id === visitId);
      }));
  };

  bulkCancelVisit$ = (options: IObject = null): Observable<void> => {
    const endpoint: string = ENDPOINTS.bulkCancelVisits.route;

    return this.http
      .post(environment.apiBaseUrl + endpoint, options)
      .pipe(switchToEmptyObservable());
  };

  getCancelledVisitsRequiringAction$ = (cancellationActionId: string): Observable<Visit[]> => {
    const endpoint: string = ENDPOINTS.getVisitsForInstitutionRequiringAction.route
      .replace(`{${ENDPOINTS.getVisitsForInstitutionRequiringAction.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getVisitsForInstitutionRequiringAction.pathParams.actionId}}`, cancellationActionId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: VisitJson[]): Visit[] => json.map(Visit.fromJson)));
  };

  getVisitNotCancellableReason(visit: Visit): Observable<string | null> {

    if (this.authenticationService.currentUser.hasAnyRole([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])) {
      return of(null);
    }

    if (visit.isPast) {
      return this.translate.get('admin.pages.dashboard.visitDialog.cancellationTooltip.inPast');
    }

    if (visit.wasExecuted) {
      return this.translate.get('admin.pages.dashboard.visitDialog.cancellationTooltip.markedAsConducted');
    }

    if (this.authenticationService.currentUser.hasAnyReferringPhysicianRole()) {
      const settings: InstitutionSettings = this.authenticationService.institution.settings;

      if (!settings.referringPhysicianCanCancelVisit) {
        return this.translate.get('admin.pages.dashboard.visitDialog.cancellationTooltip.limitationsApplied');
      }

      if (differenceInHours(visit.start, new Date()) < settings.referringPhysicianCanCancelVisitHoursAtForehand) {
        return this.translate.get('admin.pages.dashboard.visitDialog.cancellationTooltip.limitationsAppliedThreshold');
      }
    }

    if (this.authenticationService.currentUser.hasRole(Role.PATIENT)) {
      return this.institutionService.getPublicSettings$(visit.institution.id).pipe(switchMap((settings: InstitutionSettings) => {
        if (!settings.patientCanCancelVisit) {
          return this.translate.get('pages.dashboard.visitDialog.cancelDisabledByInstitute');
        }

        if (differenceInHours(visit.start, new Date()) < settings.patientCanCancelVisitHoursAtForehand) {
          return this.translate.get('pages.dashboard.visitDialog.tooLateToCancel', { hour: settings.patientCanCancelVisitHoursAtForehand });
        }

        return of(null);
      }));
    }

    return of(null);
  }
}

