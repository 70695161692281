export class Wallet {
  constructor(
    private _id: string,
    private _tokens: number,
    private _minTokensThreshold: number,
    private _isAutoReplenishEnabled: boolean,
    private _autoReplenishBucketId: string
  ) {
  }

  get id(): string {
    return this._id;
  }

  get tokens(): number {
    return this._tokens;
  }

  get minTokensThreshold(): number {
    return this._minTokensThreshold;
  }

  get isAutoReplenishEnabled(): boolean {
    return this._isAutoReplenishEnabled;
  }

  get autoReplenishBucketId(): string {
    return this._autoReplenishBucketId;
  }

  static fromJson(json: WalletJson): Wallet {
    return new Wallet(
      json.id,
      json.tokens,
      json.min_tokens_threshold,
      json.is_auto_replenish_enabled,
      json.auto_replenish_bucket_id
    );
  }
}

export interface WalletJson {
  id: string;
  tokens: number;
  min_tokens_threshold: number;
  is_auto_replenish_enabled: boolean;
  auto_replenish_bucket_id: string;
}
