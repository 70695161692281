import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';
import { REGEX } from '@constants/regex.constant';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private readonly translate: TranslateService) {}

  resolveErrorToMessage(errors?: ValidationErrors, fieldName: string = null): string {
    if (errors?.required || errors?.requiredTrue) {
      return fieldName
        ? this.translate.instant('common.errors.requiredWithLabel', { field: fieldName })
        : this.translate.instant('common.errors.required');
    }

    if (errors?.email) {
      return this.translate.instant('common.errors.invalidEmail');
    }

    if (errors?.maxlength) {
      return this.translate.instant('common.errors.maxLength', {
        max: errors.maxlength.requiredLength,
        actual: errors.maxlength.actualLength,
      });
    }

    if (errors?.minlength) {
      return this.translate.instant('common.errors.minLength', { min: errors.minlength.requiredLength });
    }

    if (errors?.pattern) {
      return this.convertRegexPatternToErrorMessage(errors.pattern.requiredPattern);
    }

    if (errors?.rizivControlNr) {
      return this.translate.instant('common.errors.invalidRizivControlNr');
    }

    if (errors?.rizivLast3) {
      return this.translate.instant('common.errors.invalidRizivLast3');
    }

    return null;
  }

  protected convertRegexPatternToErrorMessage(pattern: string): string {
    if (pattern.includes(REGEX.clientDate)) {
      return this.translate.instant('common.errors.formatShortDate');
    }

    if (pattern.includes(REGEX.phoneBelgium)) {
      return this.translate.instant('common.errors.formatBelgianPhoneNumber');
    }

    if (pattern.includes(REGEX.socialSecurityNumber)) {
      return this.translate.instant('common.errors.formatBelgianSocialSecurityNumber');
    }

    if (pattern.includes(REGEX.uuidV4)) {
      return this.translate.instant('common.errors.formatUuidV4');
    }

    if (pattern.includes(REGEX.username)) {
      return this.translate.instant('common.errors.formatUsername');
    }

    if (pattern.includes(REGEX.rizivNumber)) {
      return this.translate.instant('common.errors.formatRizivNumber');
    }

    if (pattern.includes(REGEX.password)) {
      return this.translate.instant('common.errors.passwordFormat');
    }

    return null;
  }
}
