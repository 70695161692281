import { Component } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { Router } from '@angular/router';

@Component({
  selector: 'vh-verify-email-success-page',
  templateUrl: './verify-email-success-page.component.html',
  styleUrls: ['./verify-email-success-page.component.scss'],
})
export class VerifyEmailSuccessPageComponent {
  routerLink: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  onButtonClick(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}
