import { PendingBookingData, PendingBookingDataJson } from '@models/pending-booking-data.model';
import { Visit, VisitJson } from '@models/visit.model';

export class PendingBooking {
  static CURRENT_VERSION: number = 1;

  constructor(
    private readonly _id: string,
    private _version: number,
    private _data: PendingBookingData,
    private _visits: Visit[]
  ) {
  }

  get id(): string {
    return this._id;
  }

  get version(): number {
    return this._version;
  }

  get data(): PendingBookingData {
    return this._data;
  }

  set data(value: PendingBookingData) {
    this._data = value;
  }

  get visits(): Visit[] {
    return this._visits;
  }

  set visits(value: Visit[]) {
    this._visits = value;
  }

  static fromJson(json: PendingBookingJson): PendingBooking {
    return new PendingBooking(
      json.id,
      json.version,
      PendingBookingData.fromJson(json.data),
      json.visits?.map((visitJson: VisitJson) => Visit.fromJson(visitJson)) ?? []
    );
  }
}

export interface PendingBookingJson {
  id: string;
  version: number;
  data: PendingBookingDataJson;
  visits: VisitJson[] | null;
}
