<div>
  <ul>
    <li *ngFor="let rule of rules">
      <vh-message
        class="not-clickable"
        [textClass]="!checkRule(rule.regex) ? 'has-text-error' : 'has-text-success'"
        [iconLeftPath]="getIconPath(checkRule(rule.regex))"
        [iconLeftFill]="getIconColor(getIconPath(checkRule(rule.regex)), checkRule(rule.regex), 'fill')"
        [iconLeftStroke]="getIconColor(getIconPath(checkRule(rule.regex)), checkRule(rule.regex), 'stroke')"
        [text]="rule.name | translate">
      </vh-message>
    </li>
    <li>
      <vh-message
        class="not-clickable"
        *ngIf="repeatPasswordControlName"
        [textClass]="!passwordsMatch() ? 'has-text-error' : 'has-text-success'"
        [iconLeftPath]="getIconPath(passwordsMatch())"
        [iconLeftFill]="getIconColor(getIconPath(passwordsMatch()), passwordsMatch(), 'fill')"
        [iconLeftStroke]="getIconColor(getIconPath(passwordsMatch()), passwordsMatch(), 'stroke')"
        [text]="'pages.register.passwordFeedback.match' | translate">
      </vh-message>
    </li>
  </ul>
</div>
