import { AnswerOption, AnswerOptionJson } from '@models/answer-option.model';
import { Question, QuestionJson } from '@models/question.model';

export class Answer {
  constructor(
    private _id: string,
    private _answerListId: string,
    private _question: Question,
    private _description?: string,
    private _valueBoolean?: boolean,
    private _valueText?: string,
    private _valueDate?: string,
    private _valuesAnswerOption?: AnswerOption[]
  ) {}

  get id(): string {
    return this._id;
  }

  get answerListId(): string {
    return this._answerListId;
  }

  get question(): Question {
    return this._question;
  }

  get description(): string {
    return this._description;
  }

  get valueBoolean(): boolean {
    return this._valueBoolean;
  }

  get valueText(): string {
    return this._valueText;
  }

  get valueDate(): string {
    return this._valueDate;
  }

  get valuesAnswerOption(): AnswerOption[] {
    return this._valuesAnswerOption;
  }

  static fromJson(json: AnswerJson): Answer {
    return json
      ? new Answer(
        json.id,
        json.answer_list_id,
        json?.question ? Question.fromJson(json?.question) : null,
        json.description,
        json.value_boolean,
        json.value_text,
        json.value_date,
        json?.values_answer_option
          ? json?.values_answer_option.map(AnswerOption.fromJson)
          : []
      )
      : null;
  }
}

export interface AnswerJson {
  id: string;
  answer_list_id: string;
  question: QuestionJson;
  description: string;
  value_boolean?: boolean;
  value_text?: string;
  value_date?: string;
  values_answer_option?: AnswerOptionJson[];
}
