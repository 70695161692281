import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: [
    './search-input.component.scss',
    '../base-input/base-input.component.scss',
    '../text-input/text-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent extends BaseInputComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  onClearClicked(): void {
    this.handleValueChangeWithCheck(null);
  }
}
