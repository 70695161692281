import { Injectable } from '@angular/core';
import { RecurrenceException } from '@models/recurrence-exception.model';
import { CalendarEvent } from 'angular-calendar';
import { COLORS } from '@constants/colors.constant';
import { TranslateService } from '@ngx-translate/core';
import { Occurrence } from '@models/occurrence.model';
import { Visit } from '@models/visit.model';
import { GenericDailyCount } from '@models/generic-daily-count.model';
import { endOfDay, startOfDay } from 'date-fns';
import { VisitType } from '@enums/visit-type.enum';
import { makeColorFaded } from '@utils/helpers/color.util';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventMapperService {
  constructor(private readonly translate: TranslateService) {}

  recurrenceExceptionsToCalendarEvents(exceptions: RecurrenceException[]): CalendarEvent[] {
    return exceptions.map((exception: RecurrenceException) => this.recurrenceExceptionToCalendarEvent(exception));
  }

  recurrenceExceptionToCalendarEvent(recurrenceException: RecurrenceException): CalendarEvent {
    return {
      id: recurrenceException.recurrenceId ?? null,
      title: this.translate.instant('admin.pages.slotsOverview.slotsPage.blockedSlot'),
      start: recurrenceException.start,
      end: recurrenceException.end,
      color: {
        primary: COLORS.white,
        secondary: COLORS.backgroundDarkGrey,
        secondaryText: COLORS.white,
      },
    };
  }

  occurrencesToCalendarEvents(occurrences: Occurrence[]): CalendarEvent[] {
    return occurrences.map((occurrence: Occurrence) => this.occurrenceToCalendarEvent(occurrence));
  }

  occurrenceToCalendarEvent(occurrence: Occurrence): CalendarEvent {
    return {
      id: occurrence.recurrenceId ?? null,
      title: occurrence.displayName,
      start: occurrence.start,
      end: occurrence.end,
      color: {
        primary: COLORS.white,
        secondary: occurrence.color,
        secondaryText: COLORS.white,
      },
    };
  }

  visitsToCalendarEvents(visits: Visit[]): CalendarEvent<VisitCalendarMetaType>[] {
    return visits.map((visit: Visit) => this.visitToCalenderEvent(visit));
  }

  visitToCalenderEvent(visit: Visit): CalendarEvent<VisitCalendarMetaType> {
    const wasExecuted: boolean = visit.wasExecuted;

    // Define fallback colors directly in the method
    const fallbackColor = {
      primary: COLORS.white,
      secondary: COLORS.backgroundDarkGrey,
      secondaryText: COLORS.white,
    };

    // Get room color or fallback
    const roomColor = visit.room?.color || fallbackColor.primary;

    // Determine colors based on visit type
    const primaryColor = visit.visitType === VisitType.OTHER ? makeColorFaded(roomColor) : roomColor;
    const secondaryColor = primaryColor;

    return {
      id: visit.id,
      title: visit.title,
      start: visit.start,
      end: visit.end,
      draggable: !wasExecuted,
      color: {
        primary: !wasExecuted ? primaryColor : `${primaryColor}25`,
        secondary: !wasExecuted ? secondaryColor : `${secondaryColor}25`,
        secondaryText: fallbackColor.secondaryText,
      },
      meta: {
        popoverId: `popover-calendar-event-${visit.id}`,
        visit: visit,
      },
    };
  }

  genericDailyCountsToCalendarEvents(items: GenericDailyCount[]): CalendarEvent[] {
    return items.flatMap((item: GenericDailyCount) => this.genericDailyCountToCalenderEvents(item));
  }

  genericDailyCountToCalenderEvents(item: GenericDailyCount): CalendarEvent[] {
    const result = [];

    for (let i = 0; i < item.count; i++) {
      result.push({
        start: startOfDay(item.date),
        end: endOfDay(item.date),
        title: item.date.toDateString(),
      });
    }

    return result;
  }
}

export interface VisitCalendarMetaType {
  popoverId: string;
  visit: Visit;
}
