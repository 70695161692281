<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-row-l-c gap-h-16">
    <vh-search-input
      class="flex-grow"
      [placeholder]="'admin.pages.settings.services.search' | translate"
      [value]="searchValue"
      (inputValueChange)="onSearchQueryEntered($event)">
    </vh-search-input>
    <vh-button
      matTooltipPosition="after"
      [matTooltipDisabled]="serviceOptions?.length !== 0"
      [matTooltip]="'admin.pages.settings.services.allServicesCoupledTooltip' | translate"
      [label]="'admin.pages.settings.services.addService' | translate"
      iconLeftPath="assets/icons/plus.svg"
      [iconLeftFill]="COLORS.white"
      [isDisabled]="isServicePendingCreation || serviceOptions?.length === 0"
      (buttonClick)="onAddServiceClicked()">
    </vh-button>
  </div>

  <div class="flex-col" [vhMarginTop]="16">
    <h2>{{ 'admin.pages.settings.services.pickModality' | translate }}</h2>
    <vh-radio-button-group [items]="modalityOptions" (itemClick)="onModalitySelected($event)"></vh-radio-button-group>

    <div class="flex-row-l-c align-end gap-h-8" [vhMarginTop]="8">
      <ng-container *vhRequiresRoles="Role.ADMIN">
        <vh-icon
          class="clickable"
          src="assets/icons/import.svg"
          [matTooltip]="'admin.pages.settings.services.actions.importCSV' | translate"
          (click)="onImportClicked()"></vh-icon>
        <vh-icon
          class="clickable"
          src="assets/icons/export.svg"
          [matTooltip]="'admin.pages.settings.services.actions.exportCSV' | translate"
          (click)="onExportClicked()"></vh-icon>
        <input #uploadCsvInput type="file" style="display: none" (change)="onFileToImportSelected($event)" />
      </ng-container>
      <vh-language-picker
        [value]="selectedLanguage"
        [scopeToInstitutionSettings]="true"
        [changeAppLanguageOnValueChange]="false"
        (languageChanged)="onLanguageChanged($event)"></vh-language-picker>
    </div>
  </div>

  <table [vhMarginTop]="8">
    <thead>
      <tr>
        <th>{{ 'admin.pages.settings.services.settings.service' | translate }}</th>
        <th>{{ 'admin.pages.settings.services.settings.code' | translate }}</th>
        <th>{{ 'admin.pages.settings.services.settings.duration' | translate }}</th>
        <th>
          <vh-message
            iconRightPath="assets/icons/question-circle.svg"
            [iconRightFill]="COLORS.tertiary"
            [text]="'admin.pages.settings.services.settings.alternativeName' | translate"
            [iconRightTooltip]="'admin.pages.settings.services.settings.alternativeNameDescription' | translate">
          </vh-message>
        </th>
        <th class="table-column-s">
          <vh-message
            iconRightPath="assets/icons/question-circle.svg"
            [iconRightFill]="COLORS.tertiary"
            [text]="'admin.pages.settings.services.settings.patientCanBook' | translate"
            [iconRightTooltip]="'admin.pages.settings.services.settings.patientCanBookDescription' | translate">
          </vh-message>
        </th>
        <th class="table-column-s">
          <vh-message
            iconRightPath="assets/icons/question-circle.svg"
            [iconRightFill]="COLORS.tertiary"
            [text]="'admin.pages.settings.services.settings.isPublic' | translate"
            [iconRightTooltip]="
              'admin.pages.settings.services.settings.isPublicDescription' | translate : { institution: institution.name }
            ">
          </vh-message>
        </th>
        <th>{{ 'admin.pages.settings.services.settings.actions' | translate }}</th>
      </tr>
    </thead>
    <vh-service-settings
      *ngFor="let service of attachedServices"
      @fadeInAnimation
      [service]="service"
      [serviceOptions]="serviceOptions"
      [canRemove]="attachedServices.length !== 1"
      [selectedLanguage]="selectedLanguage"
      [defaultLanguage]="defaultLanguage"
      (saveClick)="onSaveClicked()"
      (removeClick)="onRemoveServiceClicked($event)">
    </vh-service-settings>
  </table>
</vh-content-layout>
