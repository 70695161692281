import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() isDisabled: boolean;
  @Input() backTo: string;
  @Input() marginTop: number = 0;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  handleButtonClick = (): void => {
    if (this.isDisabled) {
      return;
    }

    this.buttonClick.emit();
  };
}
