import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Institution, InstitutionJson } from '@models/institution.model';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { User, UserJson } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { IObject } from '@app-types/iobject.type';
import { InstitutionInsight } from '@models/institution-insight.model';
import { InstitutionSettings } from '@models/institution-settings.model';
import { PublicInstitutionSettings } from '@models/public-institution-settings.model';
import { Survey, SurveyJson } from '@models/survey.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { stripFalsyPropertiesFromObject } from '@utils/helpers/form.util';
import { Role } from '@enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  private _usersOfInstitution: User[];
  private _institutions: Institution[];
  private _surveysForInstitution: Survey[];

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  get institutions(): Institution[] {
    return this._institutions;
  }

  get currentUsers(): User[] {
    return this._usersOfInstitution;
  }

  set currentUsers(users: User[]) {
    this._usersOfInstitution = users;
  }

  get currentSurveys(): Survey[] {
    return this._surveysForInstitution;
  }

  set currentSurveys(surveys: Survey[]) {
    this._surveysForInstitution = surveys;
  }

  getInstitutions$ = (filter?: IObject, forceLoad: boolean = true): Observable<Institution[]> => {
    if (this._institutions?.length > 0 && !forceLoad && filter !== null) {
      return of(this._institutions);
    }

    const endpoint: string = ENDPOINTS.getInstitutions.route;

    if (filter) {
      filter = stripFalsyPropertiesFromObject(filter);
    }

    return this.http
      .get(environment.apiBaseUrl + endpoint, { params: filter })
      .pipe(map((json: InstitutionJson[]): Institution[] => json.map(Institution.fromJson)))
      .pipe(tap((institutions: Institution[]) => this._institutions = institutions));
  };

  getInstitutionByIdOrSlug$ = (slugOrId: string, forceLoad: boolean = true): Observable<Institution> => {
    if (this._institutions?.length > 0 && !forceLoad) {
      const cachedInstitution: Institution = this._institutions.find((i: Institution) => i.id === slugOrId || i.slug === slugOrId);

      if (cachedInstitution) {
        return of(cachedInstitution);
      }
    }

    const endpoint: string = ENDPOINTS.getInstitutionById.route.replace(
      `{${ENDPOINTS.getInstitutionById.pathParams.institutionId}}`,
      slugOrId
    );

    const params: IObject = {
      params: {
        load_all_translations: true,
      },
    };

    return this.http.get(environment.apiBaseUrl + endpoint, params).pipe(map(Institution.fromJson));
  };

  refreshCurrentInstitution$ = (): Observable<Institution> => {
    return this.getInstitutionByIdOrSlug$(this.authenticationService.institution.id, true).pipe(
      tap((institution: Institution) => this.authenticationService.institution = institution)
    );
  };

  getUserOfCurrentInstitutionById$ = (userId: string): Observable<User> => {
    return this.getUserOfInstitutionById$(this.authenticationService.institution.id, userId);
  };

  getUserOfInstitutionById$ = (institutionId: string, userId: string): Observable<User> => {
    const endpoint: string = ENDPOINTS.getInstitutionUserById.route
      .replace(`{${ENDPOINTS.getInstitutionUserById.pathParams.institutionId}}`, institutionId)
      .replace(`{${ENDPOINTS.getInstitutionUserById.pathParams.userId}}`, userId);

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map(User.fromJson));
  };

  getUsersOfCurrentInstitution$ = (searchParams: IObject = null, forceLoad: boolean = true): Observable<User[]> => {
    if (this._usersOfInstitution?.length > 0 && !forceLoad) {
      return of(this._usersOfInstitution);
    }

    const endpoint: string = ENDPOINTS.getInstitutionUsers.route.replace(
      `{${ENDPOINTS.getInstitutionUsers.pathParams.institutionId}}`,
      this.authenticationService.institution.id
    );

    const params: IObject = searchParams ? { params: searchParams } : {};

    return this.http
      .get(environment.apiBaseUrl + endpoint, params)
      .pipe(map((json: UserJson[]): User[] => json.map(User.fromJson)))
      .pipe(tap((admins: User[]) => this._usersOfInstitution = admins));
  };

  attachUserToCurrentInstitution$ = (userId: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.attachUserToInstitution.route
      .replace(`{${ENDPOINTS.attachUserToInstitution.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.attachUserToInstitution.pathParams.userId}}`, userId);

    return this.http.post(environment.apiBaseUrl + endpoint, {}).pipe(switchToEmptyObservable());
  };

  attachUserToCurrentInstitutionAndRetrieveUser$ = (userId: string): Observable<User> => {
    return this.attachUserToCurrentInstitution$(userId).pipe(
      switchMap(() => this.getUserOfCurrentInstitutionById$(userId)),
      last()
    );
  };

  detachUserFromCurrentInstitution$ = (userId: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.detachUserFromInstitution.route
      .replace(`{${ENDPOINTS.detachUserFromInstitution.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.detachUserFromInstitution.pathParams.userId}}`, userId);

    return this.http.delete(environment.apiBaseUrl + endpoint).pipe(switchToEmptyObservable());
  };

  getInsightsForInstitution$ = (institutionId?: string): Observable<InstitutionInsight> => {
    const endpoint: string = ENDPOINTS.getInstitutionInsightsByInstitutionId.route.replace(
      `{${ENDPOINTS.getInstitutionInsightsByInstitutionId.pathParams.institutionId}}`,
      institutionId ?? this.authenticationService.institution?.id
    );

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map(InstitutionInsight.fromJson));
  };

  updateSettings$ = (body: IObject, institutionId?: string): Observable<InstitutionSettings> => {
    institutionId = institutionId ?? this.authenticationService.institution.id;

    const endpoint: string = ENDPOINTS.updateInstitutionSettings.route.replace(
      `{${ENDPOINTS.updateInstitutionSettings.pathParams.institutionId}}`,
      institutionId
    );

    if (!this.authenticationService.currentUser.hasRole(Role.ADMIN) && Object.prototype.hasOwnProperty.call(body, 'event_bus_enabled')) {
      delete body.event_bus_enabled;
    }

    const params = {
      params: {
        load_all_translations: true,
      },
    };

    return this.http
      .put(environment.apiBaseUrl + endpoint, body, params)
      .pipe(map(InstitutionSettings.fromJson))
      .pipe(
        tap((settings: InstitutionSettings) => {
          if (institutionId === this.authenticationService.institution.id) {
            this.authenticationService.institution.settings = settings;
            this.authenticationService.persistCurrentInstitution();

            const index = this.authenticationService.currentUser.institutions.findIndex((i: Institution) => i.id === institutionId);
            this.authenticationService.currentUser.institutions[index].settings = settings;
            this.authenticationService.persistLoggedInUser();
          }
        })
      );
  };

  getPublicSettings$ = (institutionId?: string): Observable<PublicInstitutionSettings> => {
    institutionId = institutionId ?? this.authenticationService.institution.id;

    const endpoint: string = ENDPOINTS.getPublicInstitutionSettings.route.replace(
      `{${ENDPOINTS.getPublicInstitutionSettings.pathParams.institutionId}}`,
      institutionId
    );

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map(PublicInstitutionSettings.fromJson));
  };

  sendVisitPreviewEmail$ = (institutionId?: string): Observable<void> => {
    institutionId = institutionId ?? this.authenticationService.institution.id;

    const endpoint: string = ENDPOINTS.sendVisitPreviewEmail.route.replace(
      `{${ENDPOINTS.sendVisitPreviewEmail.pathParams.institutionId}}`,
      institutionId
    );

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(switchToEmptyObservable());
  };

  exportData$ = (parameters: IObject, institutionId?: string): Observable<string> => {
    institutionId = institutionId ?? this.authenticationService.institution.id;

    const endpoint: string = ENDPOINTS.exportInstitutionData.route.replace(
      `{${ENDPOINTS.exportInstitutionData.pathParams.institutionId}}`,
      institutionId
    );

    const params = {
      params: {
        ...parameters,
      },
    };

    return this.http.get(environment.apiBaseUrl + endpoint, params).pipe(map((json: { password: string; }): string => json.password));
  };

  getSurveysByInstitution$ = (forceLoad: boolean = true): Observable<Survey[]> => {
    if (this._surveysForInstitution?.length > 0 && !forceLoad) {
      return of(this._surveysForInstitution);
    }

    const endpoint: string = ENDPOINTS.getSurveys.route.replace(
      `{${ENDPOINTS.getSurveys.pathParams.institutionId}}`,
      this.authenticationService.institution.id
    );

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: SurveyJson[]): Survey[] => json.map((surveyJson: SurveyJson) => Survey.fromJson(surveyJson))))
      .pipe(tap((surveys: Survey[]) => this._surveysForInstitution = surveys));
  };

  createInstitution$ = (data: IObject): Observable<Institution> => {
    const endpoint: string = ENDPOINTS.createInstitution.route;

    return this.http.post(environment.apiBaseUrl + endpoint, data)
      .pipe(map(Institution.fromJson));
  };
}
