<vh-dialog-frame [title]="data.titleTranslationKey | translate" [showCloseButton]="!dialogRef.disableClose" (closeClicked)="cancel()">
  <vh-message class="not-clickable" [vhFontClass]="'font-regular-18-22'" [text]="data.messageTranslationKey | translate"></vh-message>
  <div class="flex-row-c-c gap-h-8" [vhMarginTop]="16">
    <vh-button
      [style]="ButtonStyle.TERTIARY"
      [label]="data.confirmTextTranslationKey ?? 'common.ok' | translate"
      [alignment]="'center'"
      (buttonClick)="confirm()"></vh-button>
    <vh-button
      *ngIf="data.showCancelButton === true"
      [style]="ButtonStyle.TERTIARY"
      [label]="data.cancelTextTranslationKey ?? 'common.cancel' | translate"
      [alignment]="'center'"
      (buttonClick)="cancel()"></vh-button>
  </div>
</vh-dialog-frame>
