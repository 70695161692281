import { Component, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Conversation } from '@models/conversation.model';
import { ConversationService } from '@services/conversation.service';
import { COLORS } from '@constants/colors.constant';
import { DATE_FORMATS } from '@constants/date-formats.constant';

export interface TranscriptDialogData {
  conversation: Conversation;
}

@Component({
  selector: 'vh-logbook-dialog',
  templateUrl: './logbook-dialog.component.html',
  styleUrls: ['./logbook-dialog.component.scss'],
})
@UntilDestroy()
export class LogbookDialogComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  conversation: Conversation;

  constructor(
    protected readonly dialog: MatDialogRef<LogbookDialogComponent>,
    protected readonly conversationService: ConversationService,
    @Inject(MAT_DIALOG_DATA) public data: TranscriptDialogData
  ) {
    this.conversation = data.conversation;
  }
}
