<vh-primary-page [navigationItems]="navigationItems" [hasHorizontalContentSpacing]="true" [hasVerticalContentSpacing]="true" [profileTemplate]="profileTemplate">
  <p *ngIf="errorMessageKey" class="has-text-error font-medium-16-19">{{ errorMessageKey | translate }}</p>

  <div class="flex-col-c-c gap-v-32" [vhMarginTop]="errorMessageKey ? 32 : 0">
    <div class="card">
      <h2>{{ 'admin.pages.insights.institutionTitle' | translate }}</h2>

      <div
        [ngClass]="{
          'flex-row-c-c gap-h-32': !breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall],
          'flex-col-c-c gap-v-16': breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall]
        }">
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/eye.svg" [stroke]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.views' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.institutionViewCount }}</p>
        </div>
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/mouse-alt.svg" [fill]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.clicks' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.institutionClickCount }}</p>
        </div>
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/link.svg" [fill]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.uniqueLinkOpens' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.institutionUniqueLinkOpenCount }}</p>
        </div>
      </div>
    </div>

    <div class="card">
      <h2>{{ 'admin.pages.insights.slotsTitle' | translate }}</h2>
      <div
        [ngClass]="{
          'flex-row-c-c gap-h-32': !breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall],
          'flex-col-c-c gap-v-16': breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthSmall]
        }">
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/eye.svg" [stroke]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.views' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.slotViewCount }}</p>
        </div>
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/mouse-alt.svg" [fill]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.clicks' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.slotClickCount }}</p>
        </div>
        <div class="flex-col-c-c stat-container">
          <vh-icon src="assets/icons/calendar-alt.svg" [fill]="COLORS.secondary" width="30" height="30"></vh-icon>
          <p [vhMarginTop]="4">{{ 'admin.pages.insights.bookings' | translate }}</p>
          <p [vhMarginTop]="4" class="font-medium-24-29">{{ insights?.slotBookCount }}</p>
        </div>
      </div>
    </div>

    <div class="card flex-col">
      <h2>{{ 'admin.pages.insights.servicesTitle' | translate }}</h2>
      <canvas *ngIf="insights?.hasVisitsTotalGroupedByModality" #modalitiesPieChart class="modalities-pie-chart"></canvas>
      <div
        *ngIf="!insights?.hasVisitsTotalGroupedByModality"
        class="flex-col-c-c modalities-pie-chart has-text-tertiary font-medium-18-22 pie-chart-placeholder">
        <p class="modalities-pie-chart-placeholder">{{ 'common.noResults' | translate }}</p>
      </div>
    </div>
  </div>
</vh-primary-page>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
