<div class="file-list">
  <div class="file-item">
    <p class="original-filename" [title]="file?.filename">{{ file?.filename | vhTruncateMiddle : 33 }}</p>
    <vh-text-input
      type="text"
      [value]="file?.description"
      [placeholder]="'components.upload.filenamePlaceholder' | translate"
      (inputValueChangeOnBlur)="onDescriptionChange$.next($event)"
      (inputValueChange)="onDescriptionChange$.next($event)">
    </vh-text-input>

    <vh-icon
      height="25"
      width="25"
      [hoverFill]="file?.isUpdating ? COLORS.tertiary : COLORS.error"
      [ngClass]="file?.isUpdating ? 'anim-rotating' : 'clickable'"
      [src]="file?.isUpdating ? 'assets/icons/sync-secondary.svg' : 'assets/icons/cancel.svg'"
      (click)="onDeleteFileClicked()">
    </vh-icon>
  </div>
</div>
