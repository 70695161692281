import { Coordinate } from '@models/coordinate.model';

export class Address {
  constructor(
    private _id: string | null,
    private _street: string,
    private _houseNumber: string,
    private _box: string,
    private _postalCode: string,
    private _city: string,
    private _country: string,
    private _latitude: number,
    private _longitude: number
  ) {}

  get id(): string {
    return this._id;
  }

  get street(): string {
    return this._street;
  }

  get houseNumber(): string {
    return this._houseNumber;
  }

  get box(): string {
    return this._box;
  }

  get fullStreet(): string {
    return this.box
      ? `${this.street} ${this.houseNumber} ${this.box}`
      : `${this.street} ${this.houseNumber}`;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  get city(): string {
    return this._city;
  }

  get country(): string {
    return this._country;
  }

  get coordinate(): Coordinate {
    return new Coordinate(this._latitude, this._longitude);
  }

  static fromJson(json: AddressJson): Address {
    return json ? new Address(json.id, json.street, json.house_number, json.box, json.postal_code, json.city, json.country, json.latitude, json.longitude) : null;
  }

  static fromPlacesApiResult(placeResult: google.maps.places.PlaceResult): Address {
    return new Address(
      null,
      placeResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean => component.types.includes('route'))?.long_name ?? null,
      placeResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean => component.types.includes('street_number'))?.long_name ?? null,
      null,
      placeResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean => component.types.includes('postal_code'))?.long_name ?? null,
      placeResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean => component.types.includes('locality'))?.long_name ?? null,
      placeResult.address_components.find((component: google.maps.GeocoderAddressComponent): boolean => component.types.includes('country'))?.long_name ?? null,
      placeResult.geometry.location.lat(),
      placeResult.geometry.location.lng()
    );
  }

  toString(): string {
    const parts: string[] = [];

    if (this._street) {
      parts.push(this._street);
    }

    if (this._houseNumber) {
      parts.push(this._houseNumber);
    }

    if (this._box) {
      parts.push(`box ${this._box}`);
    }

    if (this._postalCode) {
      parts.push(this._postalCode);
    }

    if (this._city) {
      parts.push(this._city);
    }

    return parts.join(' ');
  }

  toJson(): AddressJson {
    return {
      id: this._id,
      street: this._street,
      house_number: this._houseNumber,
      box: this._box,
      postal_code: this._postalCode,
      city: this._city,
      country: this._country,
      longitude: this._longitude,
      latitude: this._latitude,
    };
  }
}

export interface AddressJson {
  id: string;
  street: string;
  house_number: string;
  box: string;
  postal_code: string;
  city: string;
  country: string;
  longitude: number;
  latitude: number;
}
