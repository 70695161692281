import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { COLORS } from '@constants/colors.constant';
import { Institution } from '@models/institution.model';
import { VisitCancellationAction } from '@models/visit-cancellation-action.model';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionService } from '@services/institution.service';
import { VisitService } from '@services/visits/visit.service';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { Visit } from '@models/visit.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { MASKS } from '@constants/masks.constant';
import { VisitDetailsDialogComponent } from '@modules/shared/visits/dialogs/visit-details-dialog/visit-details-dialog.component';
import { Option } from '@interfaces/option.interface';
import { MatDialog } from '@angular/material/dialog';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { BookingFlowInitiatorService } from '@modules/booking/services/booking-flow-initiator.service';
import { BookingFlow } from '@enums/booking-flow.enum';

@Component({
  selector: 'vh-rescheduling-work-list-page',
  templateUrl: './rescheduling-work-list-page.component.html',
  styleUrls: ['./rescheduling-work-list-page.component.scss'],
})
@UntilDestroy()
export class ReschedulingWorkListPageComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly MASKS: typeof MASKS = MASKS;

  cancelActionButtonOptions: ButtonOption[];
  cancelActionOptions: Option[];
  isLoadingVisits: boolean;

  visits: Visit[];
  selectedVisit: Visit;
  private visitFilter: VisitFilter;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly institutionService: InstitutionService,
    private readonly visitService: VisitService,
    private readonly visitCancellationService: VisitCancellationService,
    private readonly dialogService: MatDialog,
    private readonly alertDialogService: AlertDialogService,
    private readonly bookingFlowInitiatorService: BookingFlowInitiatorService
  ) {}

  ngOnInit(): void {
    this.refreshInstitution();
    this.initVisitFilter();
  }

  onCancelActionSelected(index: number): void {
    const id: string = this.cancelActionButtonOptions[index].id;

    if (this.visitFilter.cancellationActionId === id) {
      return;
    }

    this.visitFilter.cancellationActionId = id;
    this.fetchVisits();
  }

  onDetailsIconClick(visit: Visit): void {
    this.selectedVisit = visit;

    const dialogRef = this.dialogService.open(VisitDetailsDialogComponent, {
      data: {
        visit: visit,
        isReadOnly: false,
      },
    });

    // Subscribe to the visitUpdated event from VisitDetailsDialogComponent
    const componentInstance = dialogRef.componentInstance;
    if (componentInstance) {
      componentInstance.visitUpdated.subscribe((updatedVisit: Visit) => {
        this.updateVisitAction(updatedVisit, null);
      });
    }
  }

  onSaveClicked(visit: Visit, actionId?: string): void {
    this.updateVisitAction(visit, actionId);
  }

  onDeleteClicked(visit: Visit): void {
    this.showDeleteConfirmationDialog(visit);
  }

  onDeleteConfirmed(visit: Visit): void {
    this.updateVisitAction(visit, null);
  }

  onRescheduleClicked(visit: Visit): void {
    this.bookingFlowInitiatorService.initialiseFromExistingVisit$(visit, BookingFlow.INSTITUTION_EMPLOYEE)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private updateVisitAction(visit: Visit, actionId: string | null): void {
    this.visitService
      .updateVisit$(visit.id, {
        cancellation_action_id: actionId,
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.visits = this.visits.filter((v: Visit) => v.id !== visit.id);
      });
  }

  private fetchVisits(): void {
    this.isLoadingVisits = true;
    this.visitCancellationService
      .getCancelledVisitsRequiringAction$(this.visitFilter.cancellationActionId)
      .pipe(untilDestroyed(this))
      .subscribe((visits: Visit[]) => {
        this.isLoadingVisits = false;
        this.visits = visits;
      });
  }

  private refreshInstitution(): void {
    this.institutionService
      .getInstitutionByIdOrSlug$(this.authenticationService.institution.id)
      .pipe(untilDestroyed(this))
      .subscribe((institution: Institution) => {
        this.cancelActionButtonOptions = institution.cancellationActions.map((action: VisitCancellationAction, index: number) => ({
          id: action.id,
          label: action.name,
          isActive: index === 0,
          isClickable: true,
        }));
        this.cancelActionOptions = institution.cancellationActions.map((action: VisitCancellationAction) => action.toOption());

        if (this.cancelActionOptions.length > 0) {
          this.onCancelActionSelected(0);
        }
      });
  }

  private initVisitFilter(): void {
    this.visitFilter = new VisitFilter();
    this.visitFilter.cancelled = true;
    this.visitFilter.institution = this.authenticationService.institution;
  }

  private showDeleteConfirmationDialog(visit: Visit): void {
    this.alertDialogService
      .open({
        titleTranslationKey: 'admin.pages.workLists.rescheduling.deleteConfirmationDialog.title',
        messageTranslationKey: 'admin.pages.workLists.rescheduling.deleteConfirmationDialog.message',
        showCancelButton: true,
      })
      .pipe(untilDestroyed(this))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.onDeleteConfirmed(visit);
        }
      });
  }
}
