import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from '@services/payment.service';
import { Bucket } from '@models/bucket.model';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';
import { WalletFormService } from '@modules/account/services/wallet-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { WALLET_FORM_KEYS } from '@constants/form-keys/wallet-form-keys.constant';

@Component({
  selector: 'vh-automatic-top-up-settings-dialog',
  templateUrl: './automatic-top-up-settings-dialog.component.html',
  styleUrls: ['./automatic-top-up-settings-dialog.component.scss'],
})
@UntilDestroy()
export class AutomaticTopUpSettingsDialogComponent implements OnInit {
  protected readonly WALLET_FORM_KEYS: typeof WALLET_FORM_KEYS = WALLET_FORM_KEYS;

  buckets: Bucket[] = [];
  formGroup: UntypedFormGroup;
  isLoading: boolean = true;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly walletService: WalletService,
    private readonly walletFormService: WalletFormService,
    protected readonly dialog: MatDialogRef<AutomaticTopUpSettingsDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.walletFormService.createFormGroupFromWallet();
    this.loadBuckets();
  }

  onConfirmClicked(): void {
    this.walletService.updateWalletPreferences$(this.formGroup.value)
      .subscribe(() => {
        this.dialog.close(true);
      });
  }

  private loadBuckets(): void {
    this.paymentService.getAvailableBuckets$()
      .pipe(untilDestroyed(this))
      .subscribe((buckets: Bucket[]): void => {
        this.buckets = buckets;
        this.loadWallet();
      });
  }

  private loadWallet(): void {
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet): void => {
        this.formGroup = this.walletFormService.createFormGroupFromWallet(wallet);
        this.isLoading = false;
      });
  }
}
