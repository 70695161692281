import { Component, Input } from '@angular/core';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-tertiary-page',
  templateUrl: './tertiary-page.component.html',
  styleUrls: ['./tertiary-page.component.scss'],
})
export class TertiaryPageComponent extends BreakpointObserverComponent {
  @Input() returnLinkIsVisible: boolean = true;
  @Input() doctorIsVisible: boolean = false;
  @Input() contentMaxWidth: number = 600;
}
