<ng-container *ngIf="currentPage === 'answerListsOverview'" [ngTemplateOutlet]="answerListsOverview"></ng-container>
<ng-container *ngIf="currentPage === 'answerListResults'" [ngTemplateOutlet]="answerListResults"></ng-container>

<ng-template #answerListsOverview>
  <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <ng-container *ngIf="formGroup?.controls.answerLists.length === 0">
      <vh-message class="font-medium-16-19 has-text-tertiary not-clickable" [text]="'common.noResults' | translate"> </vh-message>
    </ng-container>
    <ng-container *ngIf="formGroup?.controls.answerLists.length > 0" [formGroup]="formGroup">
      <div class="gap-v-16 container flex-col" formArrayName="answerLists">
        <ng-container [formGroupName]="index" *ngFor="let answerList of formGroup.controls.answerLists.controls; let index = index">
          <div class="card clickable" (click)="changePage($event, 'answerListResults', answerList)">
            <div class="flex-row-s-c gap-h-8">
              <div>
                <h2>
                  {{ answerList.get('first_name').value }} {{ answerList.get('last_name').value }} {{ answerList.get('patient_id').value }}
                </h2>
                <p>{{ answerList.get('survey_name').value }}</p>
              </div>

              <div
                [vhPopoverHost]="POPUP_ID"
                [popoverTemplate]="popupTemplate"
                [popoverHasBackdrop]="true"
                popoverPositioningStrategy="top_or_bottom"
                popoverConnectToFirstHtmlElementWithTagName="vh-work-lists-page"
                [popoverPreferredPosition]="popoverPreferredPositioning"
                (click)="onSelectInputClicked(answerList)">
                <vh-select-input formControlName="status" [options]="statusOptions" [isDisabled]="true"></vh-select-input>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </vh-content-layout>
</ng-template>

<ng-template #answerListResults>
  <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <vh-answer-list-results
      [navigateTo]="navigate"
      [statusOptions]="statusOptions"
      [selectedFormGroup]="selectedFormGroup"></vh-answer-list-results>
  </vh-content-layout>
</ng-template>

<ng-template #popupTemplate>
  <div class="pop-up-container">
    <vh-answer-list-argumentation-popup
      vhOutsideClick
      [answerList]="selectedAnswerList"
      [formGroup]="selectedFormGroup"
      [statusOptions]="statusOptions">
    </vh-answer-list-argumentation-popup>
  </div>
</ng-template>
