export const COORDINATES = {
  belgium: {
    latitude: 50.5039,
    longitude: 4.4699,
    radius: 276.3003400556669,
  },
  flanders: {
    latitude: 51.0950,
    longitude: 4.4478,
    radius: 276.3003400556669,
  },
};
