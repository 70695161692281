import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IObject } from '@app-types/iobject.type';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private readonly http: HttpClient) {}

  submit = (body: IObject): Observable<void> => {
    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.submitContactRequest.route, body)
      .pipe(switchToEmptyObservable());
  };
}
