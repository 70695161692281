import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { LanguageService } from '@services/language.service';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@services/authentication.service';
import { generateRandomString } from '@utils/helpers/string.util';
import { ErrorMessageService } from '@services/error-message.service';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';

@Component({
  selector: 'vh-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguagePickerComponent),
      multi: true,
    },
  ],
})
export class LanguagePickerComponent extends BaseInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  allOptions: Option[];
  visibleOptions: Option[];
  selectedOption: Option;

  protected readonly popoverId: string;

  @Input() scopeToInstitutionSettings: boolean;
  @Input() requiresRefreshAfterChange: boolean = false;
  @Input() automaticallyStoreServerSideOnChange: boolean = true;
  @Input() changeAppLanguageOnValueChange: boolean = true;
  @Input() optionsPosition: PopoverPosition = 'bottom';
  @Input() showIcon: boolean = true;
  @Input() useShortLanguageNames: boolean = false;

  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();

  private languageChangedSubscription: Subscription;

  constructor(
    @Optional() protected readonly controlContainer: ControlContainer,
    private readonly languageService: LanguageService,
    private readonly authenticationService: AuthenticationService,
    protected readonly errorMessageService: ErrorMessageService
  ) {
    super(controlContainer, errorMessageService);
    this.popoverId = `languagePicker-${generateRandomString()}`;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.value) {
      this.value = this.scopeToInstitutionSettings
        ? this.authenticationService.institution.settings.defaultLanguage ?? this.languageService.getPreferredLanguage()
        : this.languageService.getPreferredLanguage();
    }

    this.reloadLanguageOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.value?.isFirstChange() && changes.value?.currentValue) {
      this.writeValue(changes.value.currentValue);
    }

    if (!changes.useShortLanguageNames?.isFirstChange() && changes.useShortLanguageNames?.currentValue) {
      this.reloadLanguageOptions();
    }
  }

  onLanguageSelected(langCode: string): void {
    this.handleValueChangeWithCheck(langCode);
    this.writeValue(langCode);

    this.languageChangedSubscription?.unsubscribe();
    this.languageChangedSubscription = this.languageService.languageSetSuccessfully$
      .pipe(untilDestroyed(this))
      .subscribe((success: boolean) => {
        this.languageChangedSubscription = null;

        if (!success) {
          return;
        }

        this.languageChanged.emit(this.selectedOption.value as string);
        this.languageService.getPreferredLanguage();

        if (this.requiresRefreshAfterChange) {
          window.location.reload();
        }
      });

    if (!this.changeAppLanguageOnValueChange) {
      return;
    }

    this.languageService.setLanguage(this.value as string, this.automaticallyStoreServerSideOnChange);
  }

  writeValue(value: string): void {
    value = value.toLowerCase();

    super.writeValue(value);

    this.refreshSelectedOption();
  }

  reloadLanguageOptions(): void {
    this.languageService.getPlatformLanguagesAsOptions$('value', this.useShortLanguageNames)
      .pipe(untilDestroyed(this))
      .subscribe((options: Option[]) => {
        this.allOptions = options;
        this.reloadOptions();
      });
  }

  private reloadOptions(): void {
    this.visibleOptions = this.scopeToInstitutionSettings && this.authenticationService.institution?.settings?.supportedLanguages.size > 0
      ? this.allOptions?.filter((option: Option) => this.authenticationService.institution.settings.supportedLanguages.has(option.value as string))
      : this.allOptions;
    this.refreshSelectedOption();
  }

  private refreshSelectedOption(): void {
    this.selectedOption = this.visibleOptions?.find((option: Option): boolean => option.value === this.value) ?? this.getDefaultOption();
  }

  private getDefaultOption(): Option | null {
    if (this.scopeToInstitutionSettings) {
      const institutionDefaultLanguage = this.visibleOptions?.find((option: Option): boolean => option.value === this.authenticationService.institution?.settings?.defaultLanguage);

      if (institutionDefaultLanguage) {
        return institutionDefaultLanguage;
      }
    }

    if (this.visibleOptions?.length > 0) {
      return this.visibleOptions[0];
    }

    return null;
  }
}
