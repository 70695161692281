import { ConversationEventType } from '@enums/conversation-event-type.enum';

export class ConversationEvent {
  constructor(
    private _createdAt: Date,
    private _type: ConversationEventType,
  ) { }

  get createdAt(): Date {
    return this._createdAt;
  }

  get type(): ConversationEventType {
    return this._type;
  }

  static fromJson(json: ConversationEventJson): ConversationEvent {
    return json ? new ConversationEvent(new Date(json.created_at), ConversationEventType[json.type]) : null;
  }
}

export interface ConversationEventJson {
  created_at: string;
  type: string;
}
