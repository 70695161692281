import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { FormatPipeModule } from 'ngx-date-fns';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormModule } from '@modules/shared/form/form.module';
import { SpecialtiesSelectInputComponent } from '@modules/conversation/components/specialties-select-input/specialties-select-input.component';
import { ConversationQuestionListsSelectInputComponent } from '@modules/conversation/components/conversation-question-lists-select-input/conversation-question-lists-select-input.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [
  SpecialtiesSelectInputComponent,
  ConversationQuestionListsSelectInputComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
    FormatPipeModule,
    MatTooltipModule,
  ],
  exports: [
    ...sharedIngredients,
    // App modules
    CoreModule,
    FormModule,
  ],
})
export class ConversationSharedModule { }
