import { Component, OnInit } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { User } from '@models/user.model';
import { InstitutionService } from '@services/institution.service';
import { AuthenticationService } from '@services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { COLORS } from '@constants/colors.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { getRolesAsOptionsForInstitutionEmployees } from '@enums/role.enum';
import { Option } from '@interfaces/option.interface';
import { UserPolicyService } from '@services/policies/user-policy.service';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { ToastService } from '@services/ui/toast.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LinkRizivDialogComponent } from '@modules/settings/dialogs/link-riziv-dialog/link-riziv-dialog.component';
import { DoctorService } from '@services/doctor.service';
import { PasswordService } from '@services/password.service';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';

@Component({
  selector: 'vh-institution-users-page',
  templateUrl: './institution-users-page.component.html',
  styleUrls: ['./institution-users-page.component.scss'],
  animations: [fadeInOutAnimation],
})
@UntilDestroy()
export class InstitutionUsersPageComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  private _displayedUsers: User[];
  navigationItems: NavigationItem[];
  searchValue: string;
  currentPage: 'usersOverview' | 'userCreate' = 'usersOverview';
  errorMessageTranslationKey: string;
  selectedUser: User;
  currentAuthenticatedUser: User;
  isLoadingOverview: boolean;

  options: Option[];
  selectedRoleOptions: Option[];

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly doctorService: DoctorService,
    private readonly authenticationService: AuthenticationService,
    private readonly passwordService: PasswordService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertDialogService: AlertDialogService,
    private readonly translate: TranslateService,
    private readonly userPolicy: UserPolicyService,
    private readonly toastService: ToastService,
    private readonly dialogService: MatDialog,
    private readonly localStorageService: LocalStorageCacheService
  ) {
    this.navigationItems = getNavigationItems();
  }

  get displayedUsers(): User[] {
    this.updateDisplayedUsers();

    return this._displayedUsers;
  }

  ngOnInit(): void {
    getRolesAsOptionsForInstitutionEmployees(this.translate)
      .pipe(untilDestroyed(this))
      .subscribe((options: Option[]): void => {
        this.setOptions(options);
        this.setSelectedRoleOptions(this.localStorageService.get<string>(LOCAL_STORAGE_KEYS.lastSelectedRoleOptions));
        this.loadDataForInstitution();
      });

    this.currentAuthenticatedUser = this.authenticationService.currentUser;
    this.setCurrentPageFromActivatedRoute();
  }

  setOptions(options: Option[]): void {
    this.options = options;
  }

  updateDisplayedUsers(): void {
    let queryPool: User[] = this.institutionService.currentUsers;

    if (this.searchValue) {
      const searchValue: string = this.searchValue.toLowerCase();

      queryPool = queryPool?.filter((user: User): boolean => {
        return user.fullName?.toLowerCase().includes(searchValue) || user.email?.toLowerCase().includes(searchValue);
      });
    }

    queryPool = queryPool?.filter((user: User) => {
      return Array.from(user.roles.values()).some((role: string): boolean =>
        this.selectedRoleOptions.some((option: Option): boolean => option.value === role)
      );
    });

    this._displayedUsers = queryPool;
  }

  onLinkRizivNumberClicked(event: MouseEvent, user: User): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      user: user,
      panelClass: 'dialog-size-medium',
    };
    this.dialogService
      .open(LinkRizivDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((success: boolean) => {
        if (success) {
          this.institutionService.getUserOfCurrentInstitutionById$(user.id).subscribe((updatedUser: User) => {
            const index = this.institutionService.currentUsers.indexOf(user);
            this.institutionService.currentUsers.splice(index, 1, updatedUser);
            this.updateDisplayedUsers();
            this.changePage('usersOverview');
            this.toastService
              .showInfo('admin.pages.settings.administrators.rizivLinkedToast', 'common.ok', { duration: 5000 })
              .pipe(untilDestroyed(this))
              .subscribe();
          });
        }
      });
  }

  onUnlinkRizivNumberClicked(event: MouseEvent, user: User): void {
    event.stopPropagation();
    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.settings.administrators.unlinkRizivNumberDialog.title',
      messageTranslationKey: this.translate.instant('admin.pages.settings.administrators.unlinkRizivNumberDialog.message', { user: user.fullName, riziv: user.riziv }),
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    }).pipe(untilDestroyed(this)).subscribe((isConfirmed: boolean) => {
      if (!isConfirmed) {
        return;
      }
      this.doctorService.unlinkDoctor$(user).subscribe({
        next: () => {
          this.institutionService.getUserOfCurrentInstitutionById$(user.id).subscribe((updatedUser: User) => {
            const index = this.institutionService.currentUsers.indexOf(user);
            this.institutionService.currentUsers.splice(index, 1, updatedUser);
            this.updateDisplayedUsers();
            this.changePage('usersOverview');
            this.toastService
              .showInfo('admin.pages.settings.administrators.rizivUnlinkedToast', 'common.ok', { duration: 5000 })
              .pipe(untilDestroyed(this))
              .subscribe();
          });
        },
        error: () => {
          this.toastService
            .showError('admin.pages.settings.administrators.rizivUnlinkErrorToast', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
    });
  }

  onRemoveUserClicked(event: MouseEvent, user: User): void {
    event.stopPropagation();

    this.alertDialogService
      .open({
        titleTranslationKey: 'admin.pages.settings.administrators.deleteDialog.title',
        messageTranslationKey: this.translate.instant('admin.pages.settings.administrators.deleteDialog.message', {
          email: user.email,
        }),
        confirmTextTranslationKey: 'common.yes',
        cancelTextTranslationKey: 'common.no',
      })
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onConfirmRemoveUserClicked(user);
        }
      });
  }

  onSendPasswordResetEmailClicked(event: MouseEvent, user: User): void {
    event.stopPropagation();

    this.passwordService.forgotPassword$(user.username)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.toastService
            .showInfo('admin.pages.settings.administrators.passwordResetToast', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
        },
        error: (): void => {
          this.toastService
            .showError('admin.pages.settings.administrators.passwordResetErrorToast', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  onConfirmRemoveUserClicked(user: User): void {
    this.institutionService
      .detachUserFromCurrentInstitution$(user.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          const index = this.institutionService.currentUsers.findIndex((admin: User): boolean => admin.id === user.id);
          if (index > -1) {
            this.institutionService.currentUsers.splice(index, 1);
          }

          this.updateDisplayedUsers();
          this.changePage('usersOverview');
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
        },
      });
  }

  onRolesFilterChanged(selectedOptions: Option[]): void {
    this.selectedRoleOptions = selectedOptions;
    const lastSelectedRoleOptionsString: string = selectedOptions.map((option: Option) => option.value.toString()).toString();
    this.localStorageService.set(LOCAL_STORAGE_KEYS.lastSelectedRoleOptions, lastSelectedRoleOptionsString);
  }

  addUser = (user: User): void => {
    const index = this.institutionService.currentUsers.findIndex((admin: User) => admin.id === user.id);

    if (index > -1) {
      this.institutionService.currentUsers[index] = user;
    } else {
      this.institutionService.currentUsers = [...this.institutionService.currentUsers, user];
    }

    this.updateDisplayedUsers();
    this.changePage('usersOverview');
  };

  changePage = (requestedPage: 'usersOverview' | 'userCreate', user?: User): void => {
    this.currentPage = requestedPage;
    this.selectedUser = user;

    if (requestedPage === 'userCreate' && !user) {
      void this.router.navigate([NAVIGATION.adminDashboardSettings.createUser.route]);

      return;
    }

    if (requestedPage === 'userCreate' && user) {
      const route = NAVIGATION.adminDashboardSettings.updateUser.route.replace(
        `:${NAVIGATION.adminDashboardSettings.updateUser.params.userId}`,
        user.id
      );
      void this.router.navigate([route]);

      return;
    }

    void this.router.navigate([NAVIGATION.adminDashboardSettings.users.route]);
  };

  canViewUserDetails(user: User): boolean {
    return this.userPolicy.canViewUserDetails(user);
  }

  canLinkRizivNumber(user: User): boolean {
    return user.riziv ? false : this.userPolicy.canEditUserDetails(user);
  }

  canRemoveUser(user: User): boolean {
    return this.userPolicy.canDetachUserFromInstitution(user);
  }

  canSendPasswordResetEmail(user: User): boolean {
    return this.userPolicy.canSendPasswordResetEmail(user) && !!user.email;
  }

  private loadDataForInstitution(): void {
    this.isLoadingOverview = this.currentPage === 'usersOverview';
    this.institutionService
      .getUsersOfCurrentInstitution$({ roles: this.options.map((option: Option) => option.value).join(','), include: 'roles' })
      .pipe(untilDestroyed(this))
      .subscribe((admins: User[]) => {
        this.isLoadingOverview = false;
        this.institutionService.currentUsers = admins;
        this.updateDisplayedUsers();
        this.setCurrentPageFromActivatedRoute();
      });
  }

  private setCurrentPageFromActivatedRoute(): void {
    if (this.activatedRoute.snapshot.routeConfig.path === NAVIGATION.adminDashboardSettings.createUser.path) {
      this.currentPage = 'userCreate';

      return;
    }

    if (this.activatedRoute.snapshot.routeConfig.path === NAVIGATION.adminDashboardSettings.updateUser.path) {
      this.currentPage = 'userCreate';
      this.selectedUser = this.displayedUsers?.find((user: User) => user.id === this.activatedRoute.snapshot.params.userId);

      return;
    }

    this.currentPage = 'usersOverview';
  }

  private getHttpErrorMessageTranslationKey = (error: HttpErrorResponse | unknown): string => {
    if (!(error instanceof HttpErrorResponse && error.status === 422)) {
      return 'common.unknownError';
    }

    if (error.error.errors?.email) {
      return 'pages.register.emailAlreadyTakenError';
    }
  };

  private setSelectedRoleOptions(lastSelectedRoleOptionsString?: string): void {
    if (!lastSelectedRoleOptionsString) {
      this.selectedRoleOptions = this.options;

      return;
    }
    const lastSelectedRoleOptionsArray = lastSelectedRoleOptionsString.split(',');
    this.selectedRoleOptions = this.options.filter((option: Option) => lastSelectedRoleOptionsArray.includes(option.value.toString()));
  }
}
