import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopoverService } from '@services/ui/popover.service';
import { AnswerListService } from '@services/answer-list.service';
import { AnswerList } from '@models/answer-list.model';
import { AnswerListFormService } from '@services/form-services/answer-list-form.service';
import { Answer } from '@models/answer.model';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';

@Component({
  selector: 'vh-answer-list-results',
  templateUrl: './answer-list-results.component.html',
  styleUrls: ['./answer-list-results.component.scss'],
})
@UntilDestroy()
export class AnswerListResultsComponent implements OnInit {
  protected readonly POPUP_ID: string = 'vh-answer-list-results-popup';

  formGroup: FormGroup;
  options: Option[];
  answerListFormGroup: FormGroup;
  answerList: AnswerList;

  popoverPreferredPositioning: PopoverPosition;

  @Input() selectedFormGroup: FormGroup;
  @Input() statusOptions: Option[];
  @Input() navigateTo: (page: string) => void;

  constructor(
    private readonly popoverService: PopoverService,
    private readonly answerListService: AnswerListService,
    private readonly answerListFormService: AnswerListFormService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.selectedFormGroup;
    this.answerListService.getAnswerList$(this.selectedFormGroup.value.id)
      .pipe(untilDestroyed(this))
      .subscribe((answerList: AnswerList) => {
        this.answerList = answerList;
        answerList.answers = answerList.answers.filter((answer: Answer) => answer.question.isVisibleForInstitution);
        this.answerListFormGroup = this.answerListFormService.createAnswerList(answerList);
      });
    this.options = this.statusOptions;
  }

  navigateBack(): void {
    this.formGroup = null;
    this.navigateTo('answerListsOverview');
  }

  onSelectInputClicked(formGroup: FormGroup): void {
    this.selectedFormGroup = formGroup;
    //TODO: prevent opening select-input menu
    this.openPopup();
  }

  openPopup(preferredPosition: PopoverPosition = 'top'): void {
    this.popoverPreferredPositioning = preferredPosition;
    this.popoverService.open(this.POPUP_ID);
  }

  selectOption = (fg: FormGroup): void => {
    //TODO: select input doesn't display the new form value
    this.formGroup = fg;
  };
}
