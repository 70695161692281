import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Option } from '@interfaces/option.interface';
import { map, tap } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { Service, ServiceJson } from '@models/service.model';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@services/authentication.service';
import { IObject } from '@app-types/iobject.type';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private readonly _servicesPerInstitution: Map<string, Service[]>;
  private _allServices: Service[];

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {
    this._servicesPerInstitution = new Map<string, Service[]>();
  }

  get allServices(): Service[] {
    return this._allServices ?? [];
  }

  get servicesPerInstitution(): Map<string, Service[]> {
    return this._servicesPerInstitution;
  }

  get servicesForCurrentInstitution(): Service[] {
    return this.servicesPerInstitution.has(this.authenticationService.institution.id)
      ? this.servicesPerInstitution.get(this.authenticationService.institution.id)
      : [];
  }

  findServiceById = (serviceId: string, services?: Service[]): Service => {
    return (services ?? this.allServices)?.find((service: Service) => {
      if (service.id === serviceId) {
        return service;
      }

      if (service.children) {
        return this.findServiceById(serviceId, service.children);
      }

      return null;
    });
  };

  getServices$ = (filters: ServiceFilter = null, forceLoad: boolean = false): Observable<Service[]> => {
    // If we filter on a specific institution, it's better to fetch the services for that institution specifically
    if (filters?.institution) {
      return this.getServicesForInstitution$(filters.institution, filters, forceLoad);
    }

    // Fetch from cache unless a force load is requested
    if (!forceLoad && this.allServices.length > 0) {
      return of(this.allServices);
    }

    const queryParams: IObject = {};

    if (filters?.serviceTypeKeys) {
      queryParams[ENDPOINTS.getServices.queryParams.serviceTypeKeys] = filters.serviceTypeKeys.join(',');
    }

    if (filters?.serviceTypeIds) {
      queryParams[ENDPOINTS.getServices.queryParams.serviceTypeIds] = filters.serviceTypeIds.join(',');
    }

    if (filters?.hasChildren) {
      queryParams[ENDPOINTS.getServices.queryParams.hasChildren] = filters.hasChildren;
    }

    return this.http
      .get(environment.apiBaseUrl + ENDPOINTS.getServices.route, { params: queryParams })
      .pipe(map((json: ServiceJson[]): Service[] => json.map(Service.fromJson)))
      .pipe(tap((services: Service[]) => this._allServices = services));
  };

  getServicesForInstitutionFlat$ = (institutionId: string): Observable<Service[]> => {
    const endpoint = ENDPOINTS.getServicesForInstitutionFlat.route
      .replace(`{${ENDPOINTS.getServicesForInstitution.pathParams.institutionId}}`, institutionId);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: ServiceJson[]): Service[] => json.map(Service.fromJson)))
      .pipe(tap((services: Service[]) => this.servicesPerInstitution.set(institutionId, services)));
  };

  getServicesForInstitution$ = (institutionId: string, filter: ServiceFilter = null, forceLoad: boolean = false): Observable<Service[]> => {
    // Fetch from cache unless a force load is requested
    if (!forceLoad && this.servicesPerInstitution.has(institutionId)) {
      return of(this.servicesPerInstitution.get(institutionId));
    }

    const requestParams: IObject = {
      params: {
        load_all_translations: filter?.loadAllTranslations ? 1 : 0,
      },
    };

    if (filter?.serviceTypeIds) {
      requestParams.params[ENDPOINTS.getServicesForInstitution.queryParams.serviceTypeIds] = filter.serviceTypeIds.join(',');
    }

    if (filter?.hasChildren) {
      requestParams.params[ENDPOINTS.getServicesForInstitution.queryParams.hasChildren] = filter.hasChildren;
    }

    const endpoint = ENDPOINTS.getServicesForInstitution.route
      .replace(`{${ENDPOINTS.getServicesForInstitution.pathParams.institutionId}}`, institutionId);

    return this.http.get(environment.apiBaseUrl + endpoint, requestParams)
      .pipe(map((json: ServiceJson[]): Service[] => json.map(Service.fromJson)))
      .pipe(tap((services: Service[]) => this.servicesPerInstitution.set(institutionId, services)));
  };

  getServicesForCurrentInstitution$ = (filter: ServiceFilter = null, forceLoad: boolean = false): Observable<Service[]> => {
    return this.getServicesForInstitution$(this.authenticationService.institution.id, filter, forceLoad);
  };

  getServiceOptionsForCurrentInstitution$ = (): Observable<Option[]> => {
    return this.getServicesForCurrentInstitution$()
      .pipe(map(
        (services: Service[]): Option[] => services.map(
          (service: Service) => {
            return this.flattenService(service).map(
              (childService: Service) => {
                return { label: childService.name, value: childService.id };
              });
          }).flat(1)
      ));
  };

  getServiceByIdOfInstitution$ = (institutionId: string, serviceId: string): Observable<Service | null> => {
    if (!this.authenticationService.currentUser.hasAnyInstitutionRole()) {
      return of(null);
    }
    if (!institutionId) {
      institutionId = this.authenticationService.institution.id;
    }
    const endpoint = ENDPOINTS.getServiceOfInstitutionById.route
      .replace(`{${ENDPOINTS.getServiceOfInstitutionById.pathParams.institutionId}}`, institutionId)
      .replace(`{${ENDPOINTS.getServiceOfInstitutionById.pathParams.serviceId}}`, serviceId);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map(Service.fromJson));
  };

  getServiceByIdOfCurrentInstitution$ = (serviceId: string): Observable<Service> => {
    return this.getServiceByIdOfInstitution$(this.authenticationService.institution.id, serviceId);
  };

  getServiceById$ = (serviceId: string): Observable<Service> => {
    const endpoint: string = ENDPOINTS.getServiceById.route.replace(/{.*}/, serviceId);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: ServiceJson): Service => Service.fromJson(json)));
  };

  getServiceRelationsTree$ = (serviceId: string): Observable<Service> => {
    const endpoint: string = ENDPOINTS.getServiceRelationsTree.route.replace(/{.*}/, serviceId);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: ServiceJson): Service => Service.fromJson(json)));
  };

  attachServiceToInstitution$ = (serviceId: string, institutionId: string, data: IObject): Observable<void> => {
    const endpoint: string = ENDPOINTS.attachServiceToInstitution.route
      .replace(`{${ENDPOINTS.attachServiceToInstitution.pathParams.institutionId}}`, institutionId)
      .replace(`{${ENDPOINTS.attachServiceToInstitution.pathParams.serviceId}}`, serviceId);

    return this.http.post(environment.apiBaseUrl + endpoint, data)
      .pipe(switchToEmptyObservable());
  };

  attachServiceToCurrentInstitution$ = (serviceId: string, data: IObject): Observable<void> => {
    return this.attachServiceToInstitution$(serviceId, this.authenticationService.institution.id, data);
  };

  detachServiceFromInstitution$ = (serviceId: string, institutionId: string): Observable<void> => {
    const endpoint: string = ENDPOINTS.attachServiceToInstitution.route
      .replace(`{${ENDPOINTS.detachServiceFromInstitution.pathParams.institutionId}}`, institutionId)
      .replace(`{${ENDPOINTS.detachServiceFromInstitution.pathParams.serviceId}}`, serviceId);

    return this.http.delete(environment.apiBaseUrl + endpoint)
      .pipe(switchToEmptyObservable());
  };

  detachServiceFromCurrentInstitution$ = (serviceId: string): Observable<void> => {
    return this.detachServiceFromInstitution$(serviceId, this.authenticationService.institution.id);
  };

  downloadInstitutionServicesCsv$ = (institutionId: string): Observable<ArrayBuffer> => {
    const endpoint: string = ENDPOINTS.downloadInstitutionServicesCsv.route
      .replace(`{${ENDPOINTS.downloadInstitutionServicesCsv.pathParams.institutionId}}`, institutionId);

    return this.http.get(environment.apiBaseUrl + endpoint, { responseType: 'arraybuffer' });
  };

  uploadInstitutionServicesCsv$ = (institutionId: string, file: File): Observable<void> => {
    const endpoint: string = ENDPOINTS.uploadInstitutionServicesCsv.route
      .replace(`{${ENDPOINTS.uploadInstitutionServicesCsv.pathParams.institutionId}}`, institutionId);

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(environment.apiBaseUrl + endpoint, formData)
      .pipe(switchToEmptyObservable());
  };

  private flattenService(service: Service): Service[] {
    const result = [];
    result.push(service);
    if (service.children) {

      service.children.forEach((child: Service) => {
        result.push(...this.flattenService(child));
      });
    }

    return result;

  }
}

export interface ServiceFilter {
  serviceTypeKeys?: string[];
  serviceTypeIds?: string[];
  hasChildren?: boolean;
  institution?: string;
  loadAllTranslations?: boolean;
}
