<h1>{{ 'pages.createAppointment.step2.title' | translate }}</h1>
<p [vhMarginTop]="8">{{ 'pages.createAppointment.step2.text' | translate }}</p>
<div [vhMarginTop]="24">
  <vh-upload-input-form
    [allowedExtensions]="allowedExtensions"
    [files]="uploadedFiles"
    [errors]="errors"
    (deleteClick)="onDeleteClicked($event)"
    (filePick)="onFilePicked($event)"
    (fileDescriptionChange)="onDescriptionUpdated($event)">
  </vh-upload-input-form>
  <div class="flex-row-l-c gap-h-8">
    <label [innerHTML]="'pages.createAppointment.step2.referringPhysicianLabel' | translate"></label>
    <vh-icon
      src="assets/icons/question-circle.svg"
      [matTooltip]="'pages.createAppointment.step2.referringPhysicianTooltip' | translate"
      [fill]="COLORS.secondary"
      [width]="20"
      [height]="20">
    </vh-icon>
    <vh-message
      @fadeInOutAnimation
      *ngIf="showSavedPhysicianSuccessfullyMessage"
      class="not-clickable has-text-tertiary font-light-12-14 align-end"
      iconLeftPath="assets/icons/check-circle.svg"
      [text]="'admin.pages.dashboard.visitDialog.saveSuccessDisclaimer' | translate"
      iconLeftWidth="20">
    </vh-message>
  </div>
  <vh-text-input
    [vhMarginTop]="8"
    [placeholder]="'pages.createAppointment.step2.referringPhysicianPlaceholder' | translate"
    [value]="visit?.referringPhysicianName"
    (inputValueChange)="onReferringPhysicianChanged($event)">
  </vh-text-input>
</div>
<div *ngIf="isDesktop && !hideContinueOnMobileBanner" [vhMarginTop]="64">
  <vh-continue-mobile-banner [generateFromCurrentUrl]="true"></vh-continue-mobile-banner>
</div>
