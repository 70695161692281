<table>
  <thead *ngIf="showHeader">
    <tr [style.height]="rowHeight">
      <td *ngFor="let columnIndex of [].constructor(amountOfColumns)"></td>
    </tr>
  </thead>
  <tr *ngFor="let rowIndex of [].constructor(amountOfRows)" [style.height]="rowHeight">
    <td *ngFor="let columnIndex of [].constructor(amountOfColumns)">
      <div vhSkeleton skeletonIsVisible="true" skeletonIsEmpty="false" skeletonWidth="80%" skeletonHeight="23px"></div>
    </td>
  </tr>
</table>
