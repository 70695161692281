import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'vh-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
})
export class BaseCardComponent {
  @Input() isLoading: boolean = false;
  @Input() isHighlighted: boolean = false;
  @Input() skeletonTemplate: TemplateRef<any> | null = null;
}
