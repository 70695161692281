import { Directive, ElementRef, NgZone } from '@angular/core';
import { AnswerList } from '@models/answer-list.model';
import { AnswerListTrackingEventService } from '@services/tracking/answer-list-tracking-event.service';
import { AbstractTrackerDirective } from '@modules/shared/core/directives/abstract-tracker.directive';

@Directive({
  selector: '[vhAnswerListTracker]',
})
export class AnswerListTrackerDirective extends AbstractTrackerDirective<AnswerList> {
  // eslint thinks this constructor is useless, but it's not. It injects the correct instance of the
  // trackingService which inherits from AbstractTrackingService<T>
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(el: ElementRef<HTMLElement>, ngZone: NgZone, trackingService: AnswerListTrackingEventService) {
    super(el, ngZone, trackingService);
  }
}
