import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private defaultAssetsFolder: string = 'production';

  private readonly selectors: string[] = [
    'link[rel="icon"]',
    'link[rel="apple-touch-icon"]',
    'link[rel="mask-icon"]',
    'link[rel="manifest"]',
    'link[rel="mask-icon"]',
  ];

  setFavicon(): void {
    if (environment.assetsFolder === this.defaultAssetsFolder) {
      return;
    }

    const assetsFolder: string = environment.assetsFolder;

    document.querySelectorAll(this.selectors.join(', ')).forEach((element: HTMLLinkElement) => {
      element.href = element.href.replace(this.defaultAssetsFolder, assetsFolder);
    });
  }
}
