import { animate, style, transition, trigger } from '@angular/animations';

export const fadeOutTransition = transition(
  ':leave',
  [
    style({ opacity: 1 }),
    animate(
      '0.5s ease-in',
      style({ opacity: 0 })
    ),
  ]
);

export const fadeOutAnimation = trigger(
  'fadeOutAnimation',
  [fadeOutTransition]
);
