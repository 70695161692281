import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { Mode } from '@enums/mode.enum';
import { Role } from '@enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class WalletPolicyService {
  static readonly EDITOR_ROLES: Role[] = [Role.ADMIN, Role.BILLING_ADMIN];

  constructor(private readonly authenticationService: AuthenticationService) {}

  canViewWallet(): boolean {
    return this.authenticationService.currentUser.hasAnyInstitutionRole() && this.authenticationService.mode === Mode.ADMIN;
  }

  canEditWallet(): boolean {
    return this.authenticationService.currentUser.hasAnyRole(WalletPolicyService.EDITOR_ROLES);
  }
}
