import { IObject } from '@app-types/iobject.type';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { endOfDay, startOfDay } from 'date-fns';
import { Institution } from '@models/institution.model';
import { stripFalsyPropertiesFromObject } from '@utils/helpers/form.util';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { ConversationDisplay } from '@modules/conversation/enums/conversation-display.enum';

export class ConversationFilter {
  static readonly PAGE_SIZE_CALENDAR: number = 250;
  static readonly PAGE_SIZE_SEARCH: number = 20;

  private _from: Date;
  private _to: Date;
  private _patient: string;
  private _doctorId: string;
  private _includeDeleted: boolean = false;
  private _status: ConversationStatus;
  private _questionList: string;
  private _institution: Institution;
  private _currentPage: number;
  private _areDateFiltersEnabled: boolean;
  private _pageSize: number;
  private _displayMode: ConversationDisplay = ConversationDisplay.AS_LIST;

  constructor() {
    this._currentPage = 1;
    this._areDateFiltersEnabled = true;
    this._pageSize = 10;
  }

  get from(): Date {
    return this._from;
  }

  set from(value: Date) {
    this._from = value;
  }

  get to(): Date {
    return this._to;
  }

  set to(value: Date) {
    this._to = value;
  }

  get patient(): string {
    return this._patient;
  }

  set patient(value: string) {
    this._patient = value;
  }

  get doctorId(): string {
    return this._doctorId;
  }

  set doctorId(value: string) {
    this._doctorId = value;
  }

  get includeDeleted(): boolean {
    return this._includeDeleted;
  }

  set includeDeleted(value: boolean) {
    this._includeDeleted = value;
  }

  get status(): ConversationStatus {
    return this._status;
  }

  set status(value: ConversationStatus) {
    this._status = value;
  }

  get questionList(): string {
    return this._questionList;
  }

  set questionList(value: string) {
    this._questionList = value;
  }

  get institution(): Institution {
    return this._institution;
  }

  set institution(value: Institution) {
    this._institution = value;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  get areDateFiltersEnabled(): boolean {
    return this._areDateFiltersEnabled;
  }

  set areDateFiltersEnabled(value: boolean) {
    this._areDateFiltersEnabled = value;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    this._pageSize = value;
  }

  get displayMode(): ConversationDisplay {
    return this._displayMode;
  }

  set displayMode(value: ConversationDisplay) {
    this._displayMode = value;
  }

  hasActiveFilters(): boolean {
    return !!this.doctorId ||
      !!this.status ||
      !!this.questionList ||
      !!this.institution?.id;
  }

  toQueryStringParams(): IObject {
    const values = {
      [ENDPOINTS.getAllConversations.queryParams.startDate]: this.from && this.areDateFiltersEnabled ? getTimeStringWithTimeZone(startOfDay(this.from)) : null,
      [ENDPOINTS.getAllConversations.queryParams.endDate]: this.to && this.areDateFiltersEnabled ? getTimeStringWithTimeZone(endOfDay(this.to)) : null,
      [ENDPOINTS.getAllConversations.queryParams.patient]: this.patient,
      [ENDPOINTS.getAllConversations.queryParams.doctor]: this.doctorId,
      [ENDPOINTS.getAllConversations.queryParams.includeDeleted]: this.includeDeleted,
      [ENDPOINTS.getAllConversations.queryParams.status]: this.status,
      [ENDPOINTS.getAllConversations.queryParams.questionList]: this.questionList,
      [ENDPOINTS.getAllConversations.queryParams.institution]: this.institution?.id,
      [ENDPOINTS.getAllConversations.queryParams.sort]: this.from && this.areDateFiltersEnabled ? '+due_date' : '-created_at',
      [ENDPOINTS.getAllConversations.queryParams.page]: this.currentPage,
      [ENDPOINTS.getAllConversations.queryParams.page_size]: this._pageSize,
    };

    return stripFalsyPropertiesFromObject(values);
  }
}
