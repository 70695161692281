export enum CreditCardNetwork {
  CARTA_SI = 'carta_si',
  CARTE_BLEUE = 'carte_bleue',
  DANKORT = 'dankort',
  DINERS_CLUB = 'diners_club',
  DISCOVER = 'discover',
  JCB = 'jcb',
  LASER = 'laser',
  MAESTRO = 'maestro',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}
