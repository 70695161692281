import { style, animate, transition, trigger, state } from '@angular/animations';

export const rotateTransition = transition(
  'true<=>false',
  animate('0.5s ease-out')
);

export const rotateAnimation = trigger(
  'rotateAnimation', [
    state('true', style({ transform: 'rotateX(180deg)' })),
    state('false', style({ transform: 'rotateX(0deg)' })),
    rotateTransition,
  ]
);

