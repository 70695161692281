import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Role } from '@enums/role.enum';
import { INSTITUTION_USER_FORM_KEYS } from '@constants/form-keys/institution-user-form-keys.constant';
import { User } from '@models/user.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InstitutionUserFormService extends AbstractFormService {
  createFormGroup(isNewUser: boolean): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_USER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('email')]: [
        null,
        [
          Validators.required,
          Validators.email,
        ],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('username')]: [
        null,
        isNewUser ? [Validators.required] : [],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('roles')]: [
        Role.INSTITUTION_ADMIN,
        [Validators.required],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('title')]: [
        null,
        [Validators.maxLength(100)],
      ],
      [INSTITUTION_USER_FORM_KEYS.get('organisationPosition')]: [
        null,
        [Validators.required],
      ],
    });
  }

  setUserToForm(formGroup: UntypedFormGroup, user: User, canBeEdited: boolean): void {
    const nameInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('lastName')]);
    const firstNameInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('firstName')]);
    const emailInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('email')]);
    const titleInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('title')]);
    const usernameInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('username')]);
    const organisationPositionInput = formGroup.get([INSTITUTION_USER_FORM_KEYS.get('organisationPosition')]);

    // set values
    nameInput.setValue(user.lastName);
    firstNameInput.setValue(user.firstName);
    emailInput.setValue(user.email);
    titleInput.setValue(user.title);
    usernameInput.setValue(user.username);
    organisationPositionInput.setValue(user.organisationPosition);

    // Disable fields are not sent for update
    usernameInput.disable();

    if (!canBeEdited) {
      formGroup.disable();
    }
  }
}
