import { Component, OnInit } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { WalletService } from '@services/wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { COLORS } from '@constants/colors.constant';
import { PaymentMethod } from '@models/payment-method.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddCreditCardDialogComponent } from '@modules/settings/dialogs/add-credit-card-dialog/add-credit-card-dialog.component';

@Component({
  selector: 'vh-payment-methods-page',
  templateUrl: './payment-methods-page.component.html',
  styleUrls: ['./payment-methods-page.component.scss'],
})
@UntilDestroy()
export class PaymentMethodsPageComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly COLORS: typeof COLORS = COLORS;

  currentUser: User;
  paymentMethods: PaymentMethod[] = [];
  isLoading: boolean = true;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly walletService: WalletService,
    private readonly dialog: MatDialog
  ) {
    this.currentUser = this.authenticationService.currentUser;
  }

  ngOnInit(): void {
    this.loadPaymentMethods();
  }

  onAddCreditCardClicked(): void {
    const options: MatDialogConfig = {
      width: '600px',
    };
    this.dialog.open(AddCreditCardDialogComponent, options)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadPaymentMethods();
      });
  }

  onMarkPaymentMethodAsDefaultClicked(paymentMethod: PaymentMethod): void {
    this.walletService.updatePaymentMethod$(paymentMethod.id, { is_default: true })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadPaymentMethods();
      });
  }

  onDeletePaymentMethodClicked(paymentMethod: PaymentMethod): void {
    this.walletService.deletePaymentMethod$(paymentMethod.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadPaymentMethods();
      });
  }

  private loadPaymentMethods(): void {
    this.walletService
      .getConfiguredPaymentMethods$()
      .pipe(untilDestroyed(this))
      .subscribe((paymentMethods: PaymentMethod[]) => {
        this.paymentMethods = paymentMethods;
        this.isLoading = false;
      });
  }
}
