import { Component, OnInit } from '@angular/core';
import { AnswerList } from '@models/answer-list.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnswerListService } from '@services/answer-list.service';
import { PopoverService } from '@services/ui/popover.service';
import { FormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { AnswerListFormService } from '@services/form-services/answer-list-form.service';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';

@Component({
  selector: 'vh-screening-forms-to-review-page',
  templateUrl: './screening-forms-to-review-page.component.html',
  styleUrls: ['./screening-forms-to-review-page.component.scss'],
})
@UntilDestroy()
export class ScreeningFormsToReviewPageComponent implements OnInit {
  protected readonly POPUP_ID: string = 'vh-screening-forms-to-review-answer-list-popup';

  formGroup: FormGroup;
  currentPage: 'answerListsOverview' | 'answerListResults';

  statusOptions: Option[];
  selectedFormGroup: FormGroup = null;
  selectedAnswerList: AnswerList = null;

  answerLists: AnswerList[];
  popoverPreferredPositioning: PopoverPosition;

  constructor(
    private readonly answerListService: AnswerListService,
    private readonly popoverService: PopoverService,
    private readonly answerListFormService: AnswerListFormService
  ) {
    this.currentPage = 'answerListsOverview';
  }

  ngOnInit(): void {
    this.loadAnswerLists();
    this.statusOptions = this.createOptions();
  }

  changePage($event: Event, requestedPage: 'answerListsOverview' | 'answerListResults', formGroup?: FormGroup): void {
    const clickedElement = $event.target as HTMLElement;

    // Check if clicked element is part of an input element. If not, perform page change.
    if (!clickedElement.parentElement.classList.contains('input-wrapper')) {
      this.selectedFormGroup = formGroup;
      this.selectedAnswerList = this.answerLists.find((answerList: AnswerList) => answerList.id === formGroup.value.id);
      this.navigate(requestedPage);
    }
  }

  navigate = (requestedPage: 'answerListsOverview' | 'answerListResults'): void => {
    this.currentPage = requestedPage;
  };

  private loadAnswerLists(): void {
    this.answerListService.getAnswerLists$()
      .pipe(untilDestroyed(this))
      .subscribe((answerLists: AnswerList[]) => {
        this.answerLists = answerLists;
        this.formGroup = this.answerListFormService.createFormArray(answerLists);
      });
  }

  onSelectInputClicked(formGroup: FormGroup): void {
    this.selectedFormGroup = formGroup;
    this.selectedAnswerList = this.answerLists.find((answerList: AnswerList) => answerList.id === formGroup.value.id);
    //TODO: prevent opening select-input menu
    this.openPopup();
  }

  openPopup(preferredPosition: PopoverPosition = 'top'): void {
    this.popoverPreferredPositioning = preferredPosition;
    this.popoverService.open(this.POPUP_ID);
  }

  private createOptions(): Option[] {
    return [
      {
        label: 'SUBMITTED',
        value: 'SUBMITTED',
      },
      {
        label: 'APPROVED',
        value: 'APPROVED',
      },
      {
        label: 'NOT_APPROVED',
        value: 'NOT APPROVED',
      },
    ];
  }
}
