import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { VisitStatus, VisitStatusJson } from '@models/visit-status.model';
import { Observable, of } from 'rxjs';
import { Institution } from '@models/institution.model';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class VisitStatusService {
  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  getVisitStatuses$ = (institution: Institution = null): Observable<VisitStatus[] | null> => {
    if (!this.authenticationService.currentUser.hasAnyInstitutionRole()) {
      return of(null);
    }

    institution = institution ?? this.authenticationService.institution;

    const endpoint: string = ENDPOINTS.getVisitStatuses.route
      .replace(`{${ENDPOINTS.getVisitStatuses.pathParams.institutionId}}`, institution.id);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: VisitStatusJson[]): VisitStatus[] => json.map(VisitStatus.fromJson)));
  };

  addStatusToVisit$ = (visitId: string, statusId: string): Observable<VisitStatus[]> => {
    if (!this.authenticationService.currentUser.hasAnyInstitutionRole()) {
      return of(null);
    }

    const endpoint: string = ENDPOINTS.addVisitStatus.route
      .replace(`{${ENDPOINTS.addVisitStatus.pathParams.visitId}}`, visitId);
    const payload = {
      visit_status_id: statusId,
    };

    return this.http
      .post(environment.apiBaseUrl + endpoint, payload)
      .pipe(map((json: VisitStatusJson[]): VisitStatus[] => json.map(VisitStatus.fromJson)));
  };
}
