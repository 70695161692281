import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { filterNavigationItems } from '@utils/helpers/navigation.util';

const navigationItems: NavigationItem[] = [
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.appointmentsLabel',
    route: NAVIGATION.dashboard.route,
    icon: 'calendar',
  },
];

export const getNavigationItems = (): NavigationItem[] => {
  return filterNavigationItems(navigationItems);
};
