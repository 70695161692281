import { Directive } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';

@Directive()
export abstract class BasePaymentStatusPageComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly router: Router
  ) {
  }

  onFinishClicked(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}
