<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <p *ngIf="isResuming" class="has-text-primary">{{ 'onboarding.resuming.text' | translate }}</p>
  <p [innerHTML]="'onboarding.finishingUp.intro1' | translate : { demoAmount: demoTokensAmount }"></p>
  <vh-token-balance></vh-token-balance>
</section>

<!--<form class="flex-col gap-v-16" [formGroup]="userFormGroup" [vhMarginTop]="16">-->

<!--  <vh-text-input-->
<!--    [label]="'pages.register.socialSecurityNumberInputLabel' | translate"-->
<!--    [formControlName]="PROFILE_FORM_KEYS.get('socialSecurityNumber')"-->
<!--    [placeholder]="'pages.register.socialSecurityNumberInputPlaceholder' | translate">-->
<!--  </vh-text-input>-->

<!--  <vh-text-input-->
<!--    type="date"-->
<!--    [label]="'pages.register.birthdateInputLabel' | translate"-->
<!--    [formControlName]="PROFILE_FORM_KEYS.get('birthdate')"-->
<!--    [placeholder]="'pages.register.birthdateInputLabel' | translate"-->
<!--    [min]="DATE_BOUNDARIES.min.dateString"-->
<!--    [max]="currentDate">-->
<!--  </vh-text-input>-->
<!--</form>-->

<form class="flex-col gap-v-16" [formGroup]="doctorFormGroup" [vhMarginTop]="16">
  <vh-specialties-select-input
    [formControlName]="DOCTOR_FORM_KEYS.get('specialty_id')"
    [label]="'onboarding.finishingUp.specialtyLabel' | translate">
  </vh-specialties-select-input>
  <vh-conversation-question-lists-select-input
    #conversationQuestionListsSelectInput
    [shouldAutomaticallyLoadStandardQuestions]="false"
    [specialtyId]="doctorFormGroup.get(DOCTOR_FORM_KEYS.get('specialty_id'))?.value ?? null"
    [label]="'onboarding.finishingUp.conversationQuestionListLabel' | translate"
    (optionsLoaded)="onConversationQuestionsLoaded($event)">
  </vh-conversation-question-lists-select-input>

  <p *ngIf="!!doctorFormGroup.get('specialty_id').value && conversationQuestionListsSelectInput.options?.length === 0"
    class="has-text-primary">
    {{ 'onboarding.finishingUp.noQuestionnairesDisclaimer' | translate }}
  </p>

  <vh-button
    class="align-center"
    iconRightPath="assets/icons/arrow-narrow-right.svg"
    [iconRightStroke]="COLORS.white"
    [isDisabled]="!doctorFormGroup.valid || conversationQuestionListsSelectInput.selectedOptions.length === 0 || isSubmitting"
    [label]="'common.next' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>
</form>


