import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonCornerStyle, ButtonStyle } from '@enums/button-style.enum';

@Component({
  selector: 'vh-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ButtonCornerStyle: typeof ButtonCornerStyle = ButtonCornerStyle;

  protected leftIconFill: string;
  protected rightIconFill: string;
  protected labelLineHeight: string;

  @Input() label: string;
  @Input() labelClass: string = 'font-regular-18-22';
  @Input() buttonClass: string | null = null;
  @Input() isDisabled: boolean;
  @Input() centered: boolean;
  @Input() addClickableClass: boolean = true;
  @Input() style: ButtonStyle = ButtonStyle.PRIMARY;
  @Input() cornerStyle: ButtonCornerStyle = ButtonCornerStyle.SUBTLE_ROUND;
  @Input() alignment: 'left' | 'right' | 'center';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @Input() iconLeftPath: string;
  @Input() iconLeftWidth: number = 16;
  @Input() iconLeftHeight: number = 16;
  @Input() iconLeftAlt: string;
  @Input() iconLeftHoverFill: string;
  @Input() iconLeftFill: string;
  @Input() iconLeftStroke: string;

  @Input() iconRightPath: string;
  @Input() iconRightWidth: number = 16;
  @Input() iconRightHeight: number = 16;
  @Input() iconRightAlt: string;
  @Input() iconRightHoverFill: string;
  @Input() iconRightFill: string;
  @Input() iconRightStroke: string;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.leftIconFill = this.iconLeftFill;
    this.rightIconFill = this.iconRightFill;
  }

  handleButtonClick = (): void => {
    if (this.isDisabled) {
      return;
    }

    this.buttonClick.emit();
  };

  onMouseOver(): void {
    this.leftIconFill = this.iconLeftHoverFill;
    this.rightIconFill = this.iconRightHoverFill;
  }

  onMouseOut(): void {
    this.leftIconFill = this.iconLeftFill;
    this.rightIconFill = this.iconRightFill;
  }
}
