import { Component, OnInit } from '@angular/core';
import { ButtonStyle } from '@enums/button-style.enum';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastService } from '@services/ui/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { triggerFormValidation } from '@utils/helpers/form.util';
import { UPDATE_PASSWORD_FORM_KEYS } from '@constants/form-keys/update-password-form-keys.constant';
import { Location } from '@angular/common';
import { PasswordService } from '@services/password.service';
import { UpdatePasswordFormService } from '@services/form-services/update-password-form.service';
import { Subscription } from 'rxjs';
import { NAVIGATION } from '@constants/navigation.constant';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-update-password-page',
  templateUrl: './update-password-page.component.html',
  styleUrls: ['./update-password-page.component.scss'],
})
@UntilDestroy()
export class UpdatePasswordPageComponent implements OnInit {
  protected readonly UPDATE_PASSWORD_FORM_KEYS: typeof UPDATE_PASSWORD_FORM_KEYS = UPDATE_PASSWORD_FORM_KEYS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  formGroup: UntypedFormGroup;
  updatePasswordSubscription: Subscription | null = null;

  constructor(
    private readonly passwordService: PasswordService,
    private readonly updatePasswordFormService: UpdatePasswordFormService,
    private readonly toastService: ToastService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.formGroup = this.updatePasswordFormService.createFormGroup();
  }

  onSaveClicked(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const currentPassword: string = this.formGroup.get(UPDATE_PASSWORD_FORM_KEYS.get('current_password'))?.value;
    const newPassword: string = this.formGroup.get(UPDATE_PASSWORD_FORM_KEYS.get('new_password'))?.value;
    const newPasswordConfirmation: string = this.formGroup.get(UPDATE_PASSWORD_FORM_KEYS.get('new_password_confirmation'))?.value;

    this.updatePasswordSubscription?.unsubscribe();
    this.updatePasswordSubscription = this.passwordService.updatePassword$(currentPassword, newPassword, newPasswordConfirmation)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.updatePasswordSubscription = null;
          this.toastService.showInfo('pages.updatePassword.passwordUpdateSuccess', 'common.ok', { duration: 5000 })
            .subscribe();
          this.location.back();
        },
        error: (err: HttpErrorResponse) => {
          this.updatePasswordSubscription = null;
          this.toastService.showError(this.determineToastMessage(err), 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
          triggerFormValidation(this.formGroup);
        },
      });
  }

  onCancelClicked(): void {
    this.location.back();
  }

  private determineToastMessage(err: HttpErrorResponse): string {
    if (err?.status !== 422) {
      return 'common.errors.generic';
    }

    if (err.error?.data?.current_password) {
      return 'pages.updatePassword.currentPasswordIncorrect';
    }

    return 'common.errors.genericValidation';
  }

  protected readonly NAVIGATION = NAVIGATION;
  protected readonly COLORS = COLORS;
}
