import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';

@Component({
  selector: 'vh-yes-no-input',
  templateUrl: './yes-no-input.component.html',
  styleUrls: [
    './yes-no-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoInputComponent),
      multi: true,
    },
  ],
})
export class YesNoInputComponent extends BaseInputComponent {
  handleInputClick = (): void => {
    if (this.isDisabled) {
      return;
    }

    if (this.value === false) {
      this.value = null;
    } else {
      this.value = !this.value;
    }

    this.formControlOnValueChangeFunction?.(this.value);
  };

  writeValue(value: string): void {
    // this.value is defined by the component input decorator: [value]="..."
    // value is defined by the FormControl: new FormControl('...', []) or FormControl.setValue(...)
    // Prioritize this.value from the input decorator over value from the FormControl
    this.value = value;
    this.formControlOnValueChangeFunction?.(this.value);
  }
}
