<vh-tertiary-page vhAnswerListTracker [trackingData]="answerList" [disableAutomaticClickTracking]="true">
  <ng-container *ngIf="formGroup" [formGroup]="formGroup">
    <h1>{{ formGroup.get('name').value }}</h1>
    <p [vhMarginTop]="8">{{ 'pages.survey.text' | translate }}</p>

    <div class="flex-col gap-v-32" [vhMarginTop]="32" formArrayName="answers">
      <div [formGroupName]="index" *ngFor="let answerForm of answers.controls; let index = index">
        <p>{{ answerForm.get('question').value }}</p>
        <div [vhMarginTop]="8" [ngSwitch]="answerForm.value.type">
          <ng-container *ngSwitchCase="'TEXT'">
            <ng-container *ngIf="answerForm.value.answer_type === 'SINGLE_LINE'">
              <vh-text-input [formControlName]="'value_text'" [isDisabled]="isDisabled"></vh-text-input>
            </ng-container>

            <ng-container *ngIf="answerForm.value.answer_type === 'MULTI_LINE'">
              <vh-textarea-input [formControlName]="'value_text'" [isDisabled]="isDisabled"></vh-textarea-input>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'BOOLEAN'">
            <vh-yes-no-input
              [formControlName]="'value_boolean'"
              [value]="answerForm.value.value_boolean"
              [isDisabled]="isDisabled"></vh-yes-no-input>
          </ng-container>

          <ng-container *ngSwitchCase="'RADIO'">
            <div class="flex-row-c-c gap-h-8">
              <vh-select-input
                class="flex-grow"
                formControlName="selected_option"
                [placeholder]="'pages.survey.multiSelectPlaceholder' | translate"
                [options]="answerForm.value.answer_options"
                (inputValueChange)="onOptionSelected($event, answerForm)"
                [isDisabled]="isDisabled">
              </vh-select-input>

              <vh-icon
                *ngIf="!isDisabled"
                src="assets/icons/cross.svg"
                width="16"
                height="16"
                class="clickable"
                [fill]="COLORS.tertiary"
                [hoverFill]="COLORS.error"
                (click)="onResetAnswerOptionClicked(answerForm)"
                [disabled]="isDisabled"></vh-icon>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'CHECKBOX'">
            <div class="flex-row gap-h-8" [vhMarginTop]="16" *ngFor="let answerOption of answerForm.value.answer_options">
              <vh-checkbox
                id="answerOption.id"
                [value]="valuesAnswerOptionContainsAnswerOption(answerForm, answerOption)"
                (click)="onCheckboxChanged(answerOption, answerForm)"
                [isDisabled]="isDisabled"></vh-checkbox>
              <label for="answerOption.id">{{ answerOption.value }}</label>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'DATE'">
            <vh-text-input
              type="date"
              formControlName="value_date"
              [value]="answerForm.value.value_date ? (answerForm.get(value_date) | date : 'yy/M/d') : null"
              [placeholder]="'dd/mm/yyyy'"
              [isDisabled]="isDisabled"></vh-text-input>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isDisabled">
      <div class="flex-row-c-c gap-h-8" [vhMarginTop]="32">
        <vh-button
          [label]="'pages.survey.submitButtonLabel' | translate"
          [iconRightPath]="'assets/icons/arrow-right-white.svg'"
          [alignment]="'center'"
          (buttonClick)="submitAnswerList()">
        </vh-button>
      </div>
    </ng-container>
  </ng-container>
</vh-tertiary-page>
