import { DoctorService } from '@services/doctor.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { Occurrence } from '@models/occurrence.model';
import { UntypedFormGroup } from '@angular/forms';
import { Room } from '@models/room.model';
import { ADD_RECURRENCE_FORM_KEYS } from '@constants/form-keys/add-recurrence-form-keys.constant';
import { ServiceGroup } from '@models/service-group.model';
import { RecurrenceFormService } from '@services/form-services/recurrence-form.service';
import { COLORS } from '@constants/colors.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MASKS } from '@constants/masks.constant';
import { bestEffortParseTimeInput } from '@utils/helpers/date.util';
import { IObject } from '@app-types/iobject.type';
import { RecurrenceService } from '@services/recurrence.service';
import { Recurrence } from '@models/recurrence.model';
import { RecurrenceFrequency } from '@enums/recurrence-frequency.enum';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { Doctor } from '@models/doctor.model';

type State = 'create' | 'update' | 'loading';

@Component({
  selector: 'vh-recurrence-settings-popup',
  templateUrl: './recurrence-settings-popup.component.html',
  styleUrls: ['./recurrence-settings-popup.component.scss'],
})
@UntilDestroy()
export class RecurrenceSettingsPopupComponent implements OnInit, OnChanges {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly ADD_RECURRENCE_FORM_KEYS: Map<string, string> = ADD_RECURRENCE_FORM_KEYS;
  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  @Input() selectedOccurrence: Occurrence | null = null;
  @Input() recurrenceOptionsSingular: Option[];
  @Input() recurrenceOptionsPlural: Option[];
  @Input() serviceGroupOptions: Option[];
  @Input() doctorOptions: Option[];
  @Input() room: Room;

  @Output() saveClick: EventEmitter<IObject> = new EventEmitter<UntypedFormGroup>();
  @Output() deleteClick: EventEmitter<Occurrence> = new EventEmitter<Occurrence>();
  @Output() valueChange: EventEmitter<Occurrence> = new EventEmitter<Occurrence>();

  formGroup: UntypedFormGroup;
  errorMessageTranslationKey: string | null = null;
  currentDate: Date = new Date();
  recurrence: Recurrence;

  state: State = 'loading';

  constructor(
    private readonly recurrenceFormService: RecurrenceFormService,
    private readonly recurrenceService: RecurrenceService,
    private readonly doctorService: DoctorService
  ) {
  }

  ngOnInit(): void {
    if (this.selectedOccurrence?.recurrenceId === null) {
      this.recurrence = this.createRecurrenceFromSelectedOccurrence();
      this.resetFormGroup();
      this.state = 'create';

      return;
    }

    this.loadRecurrence();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedOccurrence?.isFirstChange()) {
      this.loadRecurrence();
    }
  }

  onSaveClicked(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    const result: IObject = this.recurrenceFormService.formGroupToRequest(
      this.formGroup,
      this.selectedOccurrence.start,
      this.selectedOccurrence.end,
      this.room.id
    );

    this.formGroup.disable();

    this.saveClick.emit(result);
  }

  onServiceGroupSelected(serviceGroupId: string): void {
    const chosenGroup: ServiceGroup = this.room.serviceGroups.find((service: ServiceGroup) => service.id === serviceGroupId);
    this.selectedOccurrence.color = chosenGroup.color;
    this.selectedOccurrence.serviceGroup = chosenGroup;
  }

  onDoctorSelected(rizivNumber: string): void {
    if (!rizivNumber) {
      return;
    }

    this.doctorService.getDoctorByRizivNumber$(rizivNumber)
      .pipe(untilDestroyed(this))
      .subscribe((doctor: Doctor) => {
        this.selectedOccurrence.doctor = doctor;
      });
  }

  hourChange(event: Event, type: string): void {
    const value: string = bestEffortParseTimeInput((event.target as HTMLInputElement).value);

    if (type === 'start') {
      this.formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')).setValue(value);
      this.recalculateDuration();

      return;
    }

    this.formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceEnd')).setValue(value);
    this.recalculateDuration();
  }

  endDateChange(event: Event): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.selectedOccurrence.end = new Date(value);
  }

  private resetFormGroup(): void {
    this.formGroup = this.recurrenceFormService.createFormGroupFromOccurrence(this.selectedOccurrence, this.recurrence ?? null, this.room.serviceGroups[0].id);
    this.setRecurringSettingsEnableState();

    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.formGroup.invalid) {
          return;
        }

        this.valueChange.emit(this.recurrenceFormService.formGroupToOccurrence(this.formGroup, this.selectedOccurrence, this.recurrence));
      });
  }

  private createRecurrenceFromSelectedOccurrence(): Recurrence {
    return new Recurrence(
      this.selectedOccurrence?.recurrenceId ?? null,
      new Date(this.selectedOccurrence.start),
      DATE_BOUNDARIES.endless.date,
      this.selectedOccurrence.duration,
      null,
      RecurrenceFrequency.WEEKLY,
      1
    );
  }

  private loadRecurrence(): void {
    if (!this.selectedOccurrence?.recurrenceId) {
      return;
    }

    this.recurrenceService.getRecurrence$(this.selectedOccurrence.recurrenceId)
      .pipe(untilDestroyed(this))
      .subscribe((recurrence: Recurrence) => {
        this.recurrence = recurrence;
        this.resetFormGroup();
        this.state = 'update';
      });
  }

  changeRecurrenceFlag(): void {
    this.recurrenceFormService.changeRecurrenceFlag(this.formGroup, this.recurrence);
  }

  private setRecurringSettingsEnableState(): void {
    this.recurrenceFormService.toggleRecurrenceFields(this.formGroup, this.recurrence.isRecurring);
  }

  private recalculateDuration(): void {
    this.recurrenceFormService.recalculateDuration(this.formGroup, this.selectedOccurrence.start, this.selectedOccurrence.end);
  }
}
