import { Component, OnInit } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { ConversationNavigationService } from '@modules/conversation/services/conversation-flow-navigation.service';
import { ConversationService } from '@services/conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UrlParserService } from '@services/url-parser.service';
import { Router } from '@angular/router';
import { Conversation } from '@models/conversation.model';
import { MatDialog } from '@angular/material/dialog';
import { TranscriptDialogComponent } from '@modules/conversation/dialogs/transcript-dialog/transcript-dialog.component';
import { LogbookDialogComponent } from '@modules/conversation/dialogs/logbook-dialog/logbook-dialog.component';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { ToastService } from '@services/ui/toast.service';
import { ButtonStyle } from '@enums/button-style.enum';

@Component({
  selector: 'vh-conversation-details-page',
  templateUrl: './conversation-details-page.component.html',
  styleUrls: ['./conversation-details-page.component.scss'],
})
@UntilDestroy()
export class ConversationDetailsPageComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;

  navigationItems: NavigationItem[];
  uuid: string;
  activeWhatsapp: boolean;
  conversation: Conversation;

  loading: boolean = true;

  constructor(
    protected readonly navigationService: ConversationNavigationService,
    protected readonly conversationService: ConversationService,
    private readonly urlParserService: UrlParserService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly toastService: ToastService,
    private readonly alertDialogService: AlertDialogService
  ) {
    this.navigationItems = getNavigationItems();
  }

  ngOnInit(): void {
    this.uuid = this.urlParserService.extractUuid(this.router.url);

    this.getDetails();
  }

  private getDetails(): void {
    this.conversationService.getConversationDetails$(this.uuid)
      .pipe(untilDestroyed(this))
      .subscribe((result: Conversation): void => {
        this.conversation = result;
        this.activeWhatsapp = result.activeWhatsapp;
        this.loading = false;
      });
  }

  toConversations(): void {
    this.navigationService.navigateToConversationList();
  }

  setActiveWhatsapp(active: boolean): void {
    this.activeWhatsapp = active;
    if (active) {
      this.conversationService.activateWhatsApp$(this.uuid)
        .pipe(untilDestroyed(this))
        .subscribe();

      return;
    }

    this.conversationService.deactivateWhatsApp$(this.uuid)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  openTranscript(): void {
    this.dialog
      .open(TranscriptDialogComponent, {
        panelClass: 'dialog-grey',
        data: {
          conversation: this.conversation,
        },
      });
  }

  openLogbook(): void {
    this.dialog
      .open(LogbookDialogComponent, {
        panelClass: 'dialog-grey',
        data: {
          conversation: this.conversation,
        },
      });
  }

  clickDeleteConversation(): void {
    if (this.conversation.status === ConversationStatus.REMOVED) {
      this.toastService.showError('conversations.delete.alreadyDeleted', 'common.ok', { duration: 5000 }).pipe(untilDestroyed(this)).subscribe();

      return;
    }

    this.showDeleteConfirmationDialog();
  }

  showUndoDeleteConfirmationDialog(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'conversations.undoDelete.title',
      messageTranslationKey: 'conversations.undoDelete.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onUndoSoftDeleteConfirmed();
        }
      });
  }

  private onUndoSoftDeleteConfirmed(): void {
    this.conversationService.undoDeleteConversation$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: Conversation) => {
          this.conversation.status = response.status;
        },
      });
  }

  private onDeleteConfirmed(): void {
    this.conversationService.deleteConversation$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.conversation.status = ConversationStatus.REMOVED;
        },
      });
  }

  private showDeleteConfirmationDialog(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'conversations.delete.title',
      messageTranslationKey: 'conversations.delete.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onDeleteConfirmed();
        }
      });
  }
}
