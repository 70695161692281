import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { Title } from '@angular/platform-browser';
import { LanguageService } from '@services/language.service';
import { FaviconService } from '@services/meta/favicon.service';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { AnalyticsService } from '@services/analytics.service';
import { ButtonStyle } from '@enums/button-style.enum';

@Component({
  selector: 'vh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@UntilDestroy()
export class AppComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  cookiePopupIsClosed: boolean;
  isCookiesAccepted: boolean;

  constructor(
    private readonly title: Title,
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly faviconService: FaviconService,
    private readonly localStorageService: LocalStorageCacheService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.cookiePopupIsClosed = this.localStorageService.get<boolean>(LOCAL_STORAGE_KEYS.cookiePopupIsClosed);
    this.isCookiesAccepted = this.localStorageService.get<boolean>(LOCAL_STORAGE_KEYS.isCookiesAccepted);

    this.languageService.bootstrapTranslations();

    this.translateService.get('meta.title')
      .pipe(untilDestroyed(this))
      .subscribe((title: string) => {
        this.title.setTitle(title);
      });

    if (this.isCookiesAccepted) {
      this.analyticsService.bootstrap();
    }

    this.faviconService.setFavicon();
  }

  onAcceptCookies(): void {
    this.setCookies(true);
  }

  onDeclineCookies(): void {
    this.setCookies(false);
  }

  private setCookies(accepted: boolean): void {
    this.localStorageService.set(LOCAL_STORAGE_KEYS.cookiePopupIsClosed, true);
    this.localStorageService.set(LOCAL_STORAGE_KEYS.isCookiesAccepted, accepted);

    this.cookiePopupIsClosed = true;
    this.isCookiesAccepted = accepted;
  }
}
