import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@services/authentication.service';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { map, tap } from 'rxjs/operators';
import { ServiceGroup, ServiceGroupJson } from '@models/service-group.model';
import { IObject } from '@app-types/iobject.type';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class ServiceGroupService {
  private readonly _serviceGroupsPerInstitution: Map<string, ServiceGroup[]>;

  constructor(private readonly http: HttpClient, private readonly authService: AuthenticationService) {
    this._serviceGroupsPerInstitution = new Map<string, ServiceGroup[]>();
  }

  get serviceGroupsPerInstitution(): Map<string, ServiceGroup[]> {
    return this._serviceGroupsPerInstitution;
  }

  get serviceGroupsForCurrentInstitution(): ServiceGroup[] {
    return this.serviceGroupsPerInstitution.has(this.authService.institution.id)
      ? this.serviceGroupsPerInstitution.get(this.authService.institution.id)
      : [];
  }

  getServiceGroupsForCurrentInstitution$ = (forceLoad: boolean = false): Observable<ServiceGroup[]> => {
    return this.getServiceGroupsForInstitution$(this.authService.institution.id, forceLoad);
  };

  getServiceGroupsForInstitution$ = (institutionId: string, forceLoad: boolean = false): Observable<ServiceGroup[]> => {
    // Fetch from cache unless a force load is requested
    if (this.serviceGroupsPerInstitution.has(institutionId) && this.serviceGroupsPerInstitution.get(institutionId).length > 0 && !forceLoad) {
      return of(this.serviceGroupsPerInstitution.get(institutionId));
    }

    const endpoint = environment.apiBaseUrl + ENDPOINTS.getServiceGroups.route
      .replace(`{${ENDPOINTS.getServiceGroups.pathParams.institutionId}}`, institutionId);

    return this.http
      .get(endpoint)
      .pipe(map((json: ServiceGroupJson[]): ServiceGroup[] => json.map(ServiceGroup.fromJson)))
      .pipe(tap((groups: ServiceGroup[]) => {
        this.serviceGroupsPerInstitution.set(institutionId, groups.sort((a: ServiceGroup, b: ServiceGroup) => a.name.localeCompare(b.name)));
      }));
  };

  getServiceGroupById$ = (serviceGroupId: string, institutionId: string = null): Observable<ServiceGroup> => {
    const endpoint = environment.apiBaseUrl + ENDPOINTS.getServiceGroupById.route
      .replace(`{${ENDPOINTS.getServiceGroupById.pathParams.institutionId}}`, institutionId ?? this.authService.institution.id)
      .replace(`{${ENDPOINTS.getServiceGroupById.pathParams.serviceGroupId}}`, serviceGroupId);

    return this.http
      .get(endpoint)
      .pipe(map((json: ServiceGroupJson): ServiceGroup => ServiceGroup.fromJson(json)));
  };

  getServiceGroupsForRoom$ = (roomId: string): Observable<ServiceGroup[]> => {
    const endpoint = ENDPOINTS.getServiceGroupsForRoom.route.replace(`{${ENDPOINTS.getServiceGroupsForRoom.pathParams.roomId}}`, roomId.toString());

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(
      map((json: ServiceGroupJson[]): ServiceGroup[] => {
        return json.map(ServiceGroup.fromJson);
      })
    );
  };

  createServiceGroup$ = (data: IObject, institutionId: string = null): Observable<ServiceGroup> => {
    const endpoint = environment.apiBaseUrl + ENDPOINTS.getServiceGroups.route
      .replace(`{${ENDPOINTS.getServiceGroups.pathParams.institutionId}}`, institutionId ?? this.authService.institution.id);

    return this.http
      .post(endpoint, data)
      .pipe(map((json: ServiceGroupJson): ServiceGroup => ServiceGroup.fromJson(json)));
  };

  updateServiceGroup$ = (data: IObject, serviceGroupId: string, institutionId: string = null): Observable<ServiceGroup> => {
    const endpoint = environment.apiBaseUrl + ENDPOINTS.getServiceGroupById.route
      .replace(`{${ENDPOINTS.getServiceGroupById.pathParams.institutionId}}`, institutionId ?? this.authService.institution.id)
      .replace(`{${ENDPOINTS.getServiceGroupById.pathParams.serviceGroupId}}`, serviceGroupId);

    return this.http
      .put(endpoint, data)
      .pipe(map((json: ServiceGroupJson): ServiceGroup => ServiceGroup.fromJson(json)));
  };

  deleteServiceGroup$ = (serviceGroupId: string, institutionId: string = null): Observable<void> => {
    const endpoint = environment.apiBaseUrl + ENDPOINTS.deleteServiceGroup.route
      .replace(`{${ENDPOINTS.deleteServiceGroup.pathParams.institutionId}}`, institutionId ?? this.authService.institution.id)
      .replace(`{${ENDPOINTS.deleteServiceGroup.pathParams.serviceGroupId}}`, serviceGroupId);

    return this.http
      .delete(endpoint)
      .pipe(switchToEmptyObservable());
  };
}
