import { Component, OnInit } from '@angular/core';
import { Visit } from '@models/visit.model';
import { NAV_DATA_IDS } from '@constants/navigation-data-identifiers.constant';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@services/app.service';
import { VisitService } from '@services/visits/visit.service';

@Component({
  selector: 'vh-upload-document-page',
  templateUrl: './upload-document-page.component.html',
  styleUrls: ['./upload-document-page.component.scss'],
})
export class UploadDocumentPageComponent implements OnInit {
  visit: Visit;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly appService: AppService,
    private readonly appointmentService: VisitService
  ) {}

  ngOnInit(): void {
    this.loadVisit();
  }

  loadVisit(): void {
    this.visit = this.appService.getNavigationData(NAV_DATA_IDS.appointment) as Visit;

    if (this.visit) {
      return;
    }

    const appointmentId = this.activatedRoute.snapshot.params.appointmentId;
    this.appointmentService.getVisitById$(appointmentId).subscribe((appointment: Visit) => {
      this.visit = appointment;
    });
  }
}
