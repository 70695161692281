import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-secondary-page',
  templateUrl: './secondary-page.component.html',
  styleUrls: ['./secondary-page.component.scss'],
})
export class SecondaryPageComponent extends BreakpointObserverComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() headerTemplate: TemplateRef<HTMLElement>;
  @Input() title: string;

  @Output() navigateBackClick: EventEmitter<void> = new EventEmitter<void>();
}
