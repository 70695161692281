<div #container>
  <label *ngIf="label" [innerHTML]="label | translate"></label>
  <div
    class="flex-col input-wrapper"
    [ngClass]="{ clickable: !isDisabled, 'not-clickable': isDisabled }"
    [vhPopoverHost]="popoverId"
    [popoverTemplate]="optionsTemplate"
    [popoverEnforcedPosition]="'bottom'"
    [popoverCloseOnClickOutside]="true"
    [popoverWidth]="container.offsetWidth"
    (click)="toggleOptions()">
    <input
      type="text"
      [ngClass]="{
        error: hasError || (formControl?.invalid && formControl?.touched)
      }"
      [ngStyle]="{ 'background-color': !isDisabled ? 'unset' : null }"
      [placeholder]="placeholder || ''"
      [readOnly]="true"
      [ngModel]="displayedValue | translate"
      [disabled]="isDisabled" />
    <vh-icon class="chevron" src="assets/icons/chevron.svg"></vh-icon>
  </div>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ message }}
  </p>
  <p
    *ngIf="errorMessage"
    [vhMarginTop]="8"
    class="has-text-error font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ errorMessage }}
  </p>
</div>

<ng-template #optionsTemplate>
  <div class="options clickable" [ngStyle]="{ 'max-height': optionsHeight }">
    <div *ngIf="hasEmptyOption" (click)="handleOptionClick(null)">&nbsp;</div>
    <div
      *ngFor="let option of options; let index = index"
      [id]="index"
      [innerHTML]="option.label | translate"
      [ngClass]="{ highlighted: selectedOptions.includes(option) }"
      (click)="handleOptionClick(option.value)"></div>
    <hr [vhMarginTop]="4" />
    <div
      [vhMarginTop]="4"
      [ngClass]="{ highlighted: selectedOptions.length !== options.length }"
      *ngIf="hasSelectAll"
      (click)="handleSelectAllClick()">
      {{ selectAllButtonText() | translate }}
    </div>
  </div>
</ng-template>
