import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

@Component({
  selector: 'vh-register-success-page',
  templateUrl: './register-success-page.component.html',
  styleUrls: ['./register-success-page.component.scss'],
})
@UntilDestroy()
export class RegisterSuccessPageComponent {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  onNextClicked(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}
