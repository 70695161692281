import { Component } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';

@Component({
  selector: 'vh-expired-link-page',
  templateUrl: './expired-link-page.component.html',
  styleUrls: ['./expired-link-page.component.scss'],
})
export class ExpiredLinkPageComponent {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
}
