export class PublicInstitutionSettings {

  constructor(
    private readonly _idPublic: string,
    private readonly _patientCanCancelVisitPublic: boolean,
    private readonly _patientCanCancelVisitHoursAtForehandPublic: number
  ) {}

  get id(): string {
    return this._idPublic;
  }

  get patientCanCancelVisit(): boolean {
    return this._patientCanCancelVisitPublic;
  }

  get patientCanCancelVisitHoursAtForehand(): number {
    return this._patientCanCancelVisitHoursAtForehandPublic;
  }

  static fromJson(json: PublicInstitutionSettingsJson): PublicInstitutionSettings {
    return json
      ? new PublicInstitutionSettings(
        json.id,
        json.patient_can_cancel_visit,
        json.patient_can_cancel_visit_hours_at_forehand
      )
      : null;
  }
}

export interface PublicInstitutionSettingsJson {
  id: string;
  patient_can_cancel_visit: boolean;
  patient_can_cancel_visit_hours_at_forehand: number;
}
