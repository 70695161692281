import { Occurrence } from '@models/occurrence.model';
import { ServiceGroup, ServiceGroupJson } from '@models/service-group.model';
import { Service, ServiceJson } from '@models/service.model';
import { stringToColor } from '@utils/helpers/color.util';
import { generateRandomString } from '@utils/helpers/string.util';

export class Room {
  constructor(
    private _id?: string,
    private _name?: string,
    private _color?: string,
    private _modality?: Service,
    private _occurrences: Occurrence[] = [],
    private _description?: string,
    private _serviceGroups: ServiceGroup[] = [],
    private _iCalUrl: string = null
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get occurrences(): Occurrence[] {
    return this._occurrences;
  }

  set occurrences(value: Occurrence[]) {
    this._occurrences = value;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get serviceGroups(): ServiceGroup[] {
    return this._serviceGroups;
  }

  set serviceGroups(groups: ServiceGroup[]) {
    this._serviceGroups = groups;
  }

  get modality(): Service {
    return this._modality;
  }

  set modality(value: Service) {
    this._modality = value;
  }

  get iCalUrl(): string {
    return this._iCalUrl;
  }

  get services(): Service[] {
    return this._serviceGroups?.flatMap((group: ServiceGroup) => group.services);
  }

  get color(): string {
    return this._color ?? stringToColor(this._id ?? generateRandomString());
  }

  set color(value: string) {
    this._color = value;
  }

  appendOccurrence(occurrence: Occurrence): void {
    if (this._occurrences) {
      this._occurrences.push(occurrence);

      return;
    }

    this._occurrences = [occurrence];
  }

  static fromId(id: string): Room {
    return new Room(id, null, null, null, [], null, [], null);
  }

  static fromJson(json: RoomJson): Room {
    return json
      ? new Room(
        json.id,
        json.name,
        json.color ?? null,
        Service.fromJson(json.modality),
        [],
        json.description,
        json.service_groups?.map(ServiceGroup.fromJson),
        json.ical_url
      )
      : null;
  }

  toJSON(institutionId?: string): SimpleRoomJson {
    return {
      id: this._id,
      name: this._name,
      color: this._color,
      modality_id: this._modality?.id,
      description: this._description,
      service_groups: this._serviceGroups.map((s: ServiceGroup) => s.toJson()) ?? [],
      institution_id: institutionId,
    };
  }
}

export interface RoomJson {
  id: string;
  name: string;
  color: string;
  description: string;
  modality: ServiceJson;
  service_groups: ServiceGroupJson[];
  ical_url: string;
}

export interface SimpleRoomJson {
  id: string;
  name: string;
  color: string;
  description: string;
  modality_id?: string;
  service_groups: ServiceGroupJson[];
  institution_id: string;
}
