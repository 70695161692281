import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultOptions: MatSnackBarConfig = {
    panelClass: 'toast',
    duration: 5000,
  };

  constructor(private readonly snackBar: MatSnackBar, private readonly translate: TranslateService) {}

  showDefault(messageKey: string, actionKey: string = 'common.ok', options?: MatSnackBarConfig): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.show(messageKey, actionKey, options);
  }

  showInfo(messageKey: string, actionKey: string = 'common.ok', options?: MatSnackBarConfig): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.show(messageKey, actionKey, options);
  }

  showWarning(messageKey: string, actionKey: string = 'common.ok', options?: MatSnackBarConfig): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.show(messageKey, actionKey, options);
  }

  showError(messageKey: string, actionKey: string = 'common.ok', options?: MatSnackBarConfig): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    options = options ? { ...options, panelClass: ['toast', 'error'] } : { panelClass: ['toast', 'error'] };

    return this.show(messageKey, actionKey, options);
  }

  private show(messageKey: string, actionKey: string, options?: MatSnackBarConfig): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return forkJoin([
      this.translate.get(messageKey),
      this.translate.get(actionKey),
    ]).pipe(map(([message, action]: [string, string]) => {
      const config: MatSnackBarConfig = {
        ...this.defaultOptions,
        ...options,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      };

      return this.snackBar.open(message, action, config);
    }));
  }
}
