import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { FORGOT_USERNAME_FORM_KEYS } from '@constants/form-keys/forgot-username-form-keys.constant';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage } from '@utils/helpers/form.util';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-forgot-username-page',
  templateUrl: './forgot-username-page.component.html',
  styleUrls: ['./forgot-username-page.component.scss'],
})
@UntilDestroy()
export class ForgotUsernamePageComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly FORGOT_USERNAME_FORM_KEYS: typeof FORGOT_USERNAME_FORM_KEYS = FORGOT_USERNAME_FORM_KEYS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  formGroup: UntypedFormGroup;

  submitButtonIsDisabled: boolean;
  submitErrorMessageTranslationKey: string;

  isSubmitted: boolean;

  currentDateString: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService
  ) {
    this.currentDateString = format(new Date(), DATE_FORMATS.serverDate);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        [FORGOT_USERNAME_FORM_KEYS.get('birthdate')]: [
          null,
          [Validators.required],
        ],
        [FORGOT_USERNAME_FORM_KEYS.get('email')]: [
          null,
          [
            Validators.required,
            Validators.email,
          ],
        ],
      }
    );
  }

  submitUsername = (): void => {
    this.submitErrorMessageTranslationKey = extractErrorMessageFromFormValidation(
      FORGOT_USERNAME_FORM_KEYS,
      this.formGroup,
      this.getSubmitUsernameErrorMessage
    );

    if (this.submitErrorMessageTranslationKey) {
      return;
    }

    this.submitButtonIsDisabled = true;
    this.submitErrorMessageTranslationKey = null;

    this.authenticationService.forgotUsername$(this.formGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.isSubmitted = true;
        },
        error: (err: HttpErrorResponse): void => {
          this.submitButtonIsDisabled = false;
          this.submitErrorMessageTranslationKey = err?.status === 409
            ? 'pages.forgotUsername.errors.multipleMatches'
            : 'common.unknownError';
        },
      });
  };

  private getSubmitUsernameErrorMessage = (errorIdentifier: string, formKey: string): string => {
    if (errorIdentifier === 'username') {
      return 'pages.login.invalidUsernameError';
    }

    return getUnknownErrorMessage(errorIdentifier, formKey);
  };
}
