import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { CoreModule } from '@modules/shared/core/core.module';
import { roleGuard } from '@guards/role.guard';
import { getInstitutionManageRoles, Role } from '@enums/role.enum';
import { ReservationSettingsPageComponent } from '@modules/settings/pages/reservation-settings-page/reservation-settings-page.component';
import { GeneralSettingsPageComponent } from '@modules/settings/pages/general-settings-page/general-settings-page.component';
import { SurveysOverviewPageComponent } from '@modules/settings/pages/surveys-overview-page/surveys-overview-page.component';
import { moduleGuard } from '@guards/module.guard';
import { ModuleKey } from '@enums/module-key.enum';
import { SurveySettingsPageComponent } from '@modules/settings/pages/survey-settings-page/survey-settings-page.component';
import { SuperAdminSettingsPageComponent } from '@modules/settings/pages/super-admin-settings-page/super-admin-settings-page.component';
import { ServiceSettingsPageComponent } from '@modules/settings/pages/service-settings-page/service-settings-page.component';
import { ServiceGroupSettingsPageComponent } from '@modules/settings/pages/service-group-settings-page/service-group-settings-page.component';
import { PlanningPageComponent } from '@modules/settings/pages/planning-page/planning-page.component';
import { InstitutionUsersPageComponent } from '@modules/settings/pages/institution-users-page/institution-users-page.component';
import { CareProgramsPageComponent } from '@modules/settings/pages/care-programs-overview-page/care-programs-page.component';
import { CommunicationSettingsPageComponent } from '@modules/settings/pages/communication-settings-page/communication-settings-page.component';
import { SettingsPageComponent } from '@modules/settings/pages/settings-page/settings-page.component';
import { PlanningCalendarComponent } from '@modules/settings/components/planning-calendar/planning-calendar.component';
import { RecurrenceSettingsPopupComponent } from '@modules/settings/components/recurrence-settings-popup/recurrence-settings-popup.component';
import { ServiceGroupSettingsComponent } from '@modules/settings/components/service-group-settings/service-group-settings.component';
import { UserSettingsComponent } from '@modules/settings/components/user-settings/user-settings.component';
import { ServiceSettingsComponent } from '@modules/settings/components/service-settings/service-settings.component';
import { ExportPlanningDialogComponent } from '@modules/settings/dialogs/export-calendar-dialog/export-planning-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LinkRizivDialogComponent } from '@modules/settings/dialogs/link-riziv-dialog/link-riziv-dialog.component';
import { ImpactedPatientsDialogComponent } from '@modules/settings/dialogs/impacted-patients-dialog/impacted-patients-dialog.component';
import { QuestionSettingsComponent } from '@modules/settings/components/question-settings/question-settings.component';
import { AssignServiceGroupToRoomComponent } from '@modules/settings/components/assign-service-group-to-room/assign-service-group-to-room.component';
import { SurveySettingsComponent } from '@modules/settings/components/survey-settings/survey-settings.component';
import { RoomSettingsPageComponent } from '@modules/settings/pages/room-settings-page/room-settings-page.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormatDistanceToNowPipeModule, FormatPipeModule } from 'ngx-date-fns';
import { CareProgramsDetailPageComponent } from '@modules/settings/pages/care-programs-detail-page/care-programs-detail-page.component';
import { CancelVisitDialogComponent } from '@modules/settings/components/cancel-visit-dialog/cancel-visit-dialog.component';
import { CareProgramStepCardComponent } from '@modules/settings/components/care-program-step-card/care-program-step-card.component';
import { FormModule } from '@modules/shared/form/form.module';
import { CalendarModule } from '@modules/shared/calendar/calendar.module';
import { CardModule } from '@modules/shared/cards/card.module';
import { SettingsCardComponent } from '@modules/settings/components/settings-card/settings-card.component';
import { CardOverviewSettingsPageComponent } from '@modules/settings/components/card-overview-settings-page/card-overview-settings-page.component';
import { DeleteCardDialogComponent } from '@modules/settings/dialogs/delete-card-dialog/delete-card-dialog.component';
import { SecuritySettingsPageComponent } from '@modules/settings/pages/security-settings-page/security-settings-page.component';
import { WalletOverviewPageComponent } from '@modules/settings/pages/wallet-overview-page/wallet-overview-page.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { WalletHistoryComponent } from '@modules/settings/components/wallet-history/wallet-history.component';
import { WalletTransactionCardComponent } from '@modules/settings/components/wallet-transaction-card/wallet-transaction-card.component';
import { PaymentMethodsPageComponent } from '@modules/settings/pages/payment-methods-page/payment-methods-page.component';
import { WalletHistoryPageComponent } from '@modules/settings/pages/wallet-history-page/wallet-history-page.component';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { PaymentMethodCardComponent } from '@modules/settings/components/payment-method-card/payment-method-card.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { AddCreditCardDialogComponent } from '@modules/settings/dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import { PaymentMethodSettingsMenuComponent } from '@modules/settings/components/payment-method-settings-menu/payment-method-settings-menu.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ConversationsPageComponent } from '@modules/settings/pages/conversations-page/conversations-page.component';
import { ConversationsCommunicationPageComponent } from '@modules/settings/pages/conversations-communication-page/conversations-communication-page.component';
import { ConversationsPrivacyPageComponent } from '@modules/settings/pages/conversations-privacy-page/conversations-privacy-page.component';
import { SortRolesPipe } from '@modules/settings/pipes/sort-roles.pipe';
import { WalletPolicyService } from '@services/policies/wallet-policy.service';
import { SettingsSharedModule } from '@modules/settings/settings-shared.module';

const routes: Routes = [
  {
    path: NAVIGATION.adminDashboardRoomsCreate.path,
    component: RoomSettingsPageComponent,
    canActivate: [roleGuard(Role.ADMIN)],
  },
  {
    path: NAVIGATION.adminDashboardRoomsSettings.path,
    component: RoomSettingsPageComponent,
  },
  {
    path: NAVIGATION.adminDashboardSettings.path,
    component: SettingsPageComponent,
    canActivate: [roleGuard(getInstitutionManageRoles())],
    children: [
      {
        path: NAVIGATION.adminDashboardSettings.services.path,
        component: ServiceSettingsPageComponent,
      },
      {
        path: NAVIGATION.adminDashboardSettings.serviceGroups.path,
        component: ServiceGroupSettingsPageComponent,
      },
      {
        path: NAVIGATION.adminDashboardSettings.rooms.path,
        component: PlanningPageComponent,
        canActivate: [moduleGuard(ModuleKey.BOOKING)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.slots.path,
        component: PlanningPageComponent,
        canActivate: [moduleGuard(ModuleKey.BOOKING)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.users.path,
        component: InstitutionUsersPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.createUser.path,
        component: InstitutionUsersPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.updateUser.path,
        component: InstitutionUsersPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.communication.path,
        component: CommunicationSettingsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.reservationSettings.path,
        component: ReservationSettingsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.BOOKING)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.generalSettings.path,
        component: GeneralSettingsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])],
      },
      {
        path: NAVIGATION.wallet.path,
        component: WalletOverviewPageComponent,
        canActivate: [authenticationGuard(), roleGuard(WalletPolicyService.EDITOR_ROLES)],
      },
      {
        path: NAVIGATION.paymentMethods.path,
        component: PaymentMethodsPageComponent,
        canActivate: [authenticationGuard(), roleGuard(WalletPolicyService.EDITOR_ROLES)],
      },
      {
        path: NAVIGATION.walletHistory.path,
        component: WalletHistoryPageComponent,
        canActivate: [authenticationGuard(), roleGuard(WalletPolicyService.EDITOR_ROLES)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.surveys.path,
        component: SurveysOverviewPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.SURVEY)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.createSurvey.path,
        component: SurveySettingsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.SURVEY)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.updateSurvey.path,
        component: SurveySettingsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.SURVEY)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.careProgram.path,
        component: CareProgramsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.CARE_PROGRAM)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.createCareProgram.path,
        component: CareProgramsDetailPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.CARE_PROGRAM)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.updateCareProgram.path,
        component: CareProgramsDetailPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.CARE_PROGRAM)],
      },
      {
        path: NAVIGATION.adminDashboardSettings.superAdminSettings.path,
        component: SuperAdminSettingsPageComponent,
        canActivate: [roleGuard([Role.ADMIN])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.securitySettings.path,
        component: SecuritySettingsPageComponent,
        // For now only super admins can see this as it serves no purpose yet
        canActivate: [roleGuard([Role.ADMIN])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.conversationsGeneral.path,
        component: ConversationsPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.conversationsCommunication.path,
        component: ConversationsCommunicationPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])],
      },
      {
        path: NAVIGATION.adminDashboardSettings.conversationsPrivacy.path,
        component: ConversationsPrivacyPageComponent,
        canActivate: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER])],
      },
    ],
  },
];

@NgModule({
  declarations: [
    SettingsPageComponent,
    SuperAdminSettingsPageComponent,
    CareProgramsPageComponent,
    SurveySettingsPageComponent,
    SurveysOverviewPageComponent,
    SecuritySettingsPageComponent,
    ReservationSettingsPageComponent,
    CommunicationSettingsPageComponent,
    GeneralSettingsPageComponent,
    InstitutionUsersPageComponent,
    ServiceGroupSettingsPageComponent,
    PlanningPageComponent,
    ServiceSettingsPageComponent,
    PlanningCalendarComponent,
    RecurrenceSettingsPopupComponent,
    ServiceGroupSettingsComponent,
    UserSettingsComponent,
    ServiceSettingsComponent,
    ExportPlanningDialogComponent,
    SurveySettingsComponent,
    LinkRizivDialogComponent,
    ImpactedPatientsDialogComponent,
    QuestionSettingsComponent,
    AssignServiceGroupToRoomComponent,
    RoomSettingsPageComponent,
    CancelVisitDialogComponent,
    CareProgramsDetailPageComponent,
    CareProgramStepCardComponent,
    SettingsCardComponent,
    CardOverviewSettingsPageComponent,
    DeleteCardDialogComponent,
    WalletOverviewPageComponent,
    WalletHistoryComponent,
    WalletTransactionCardComponent,
    PaymentMethodsPageComponent,
    WalletHistoryPageComponent,
    PaymentMethodCardComponent,
    AddCreditCardDialogComponent,
    PaymentMethodSettingsMenuComponent,
    ConversationsPageComponent,
    ConversationsCommunicationPageComponent,
    ConversationsPrivacyPageComponent,
    SortRolesPipe,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreModule,
    SettingsSharedModule,
    FormModule,
    CardModule,
    CalendarModule,
    MatTooltipModule,
    MatDialogModule,
    FormatDistanceToNowPipeModule,
    FormatPipeModule,
    AccountSharedModule,
    InfiniteScrollDirective,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
  ],
})
export class SettingsModule { }
