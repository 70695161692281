import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Mode } from '@enums/mode.enum';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

export const modeGuard = (mode: Mode): CanActivateFn => {
  return (): boolean => {
    const router: Router = inject(Router);
    const authenticationService: AuthenticationService = inject(AuthenticationService);

    if (authenticationService.mode !== mode) {
      redirectToMostRelevantHome(authenticationService, router);

      return false;
    }

    return true;
  };
};
