/**
 * The enum mode is used to change the display for an admin user.
 *  Admin users have 2 options:
 *  - PRIVATE: own reservations
 *  - INSTITUTIONS: management of institution(s)
 */
export enum Mode {
  PRIVATE,
  ADMIN,
}
