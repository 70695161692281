import { Institution, InstitutionJson } from '@models/institution.model';
import { CareProgramStep, CareProgramStepJson } from '@models/care-program-step.model';
import { Doctor, DoctorJson } from '@models/doctor.model';
import { TranslatedProperty } from '@models/translated-property.model';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';
import { SettingsCardStatus, settingsCardStatusTranslationKey } from '@modules/settings/enums/settings-card-status.enum';

export class CareProgram implements SettingsCard {
  constructor(
    private _id: string,
    private _name: TranslatedProperty,
    private _description: TranslatedProperty,
    private _doctors: Doctor[] | null,
    private _institution: Institution,
    private _steps: CareProgramStep[],
    private _status: SettingsCardStatus
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): TranslatedProperty {
    return this._name;
  }

  get description(): TranslatedProperty {
    return this._description;
  }

  get doctors(): Doctor[] {
    return this._doctors;
  }

  get institution(): Institution {
    return this._institution;
  }

  get steps(): CareProgramStep[] {
    return this._steps;
  }

  get status(): SettingsCardStatus {
    return this._status;
  }

  get statusTranslationKey(): string {
    return settingsCardStatusTranslationKey(this._status);
  }

  get isReadOnly(): boolean {
    return this.status === SettingsCardStatus.ARCHIVED;
  }

  get isEditable(): boolean {
    return this.status === SettingsCardStatus.DRAFT;
  }

  readonly isDuplicable: boolean = true;

  archive(): void {
    this._status = SettingsCardStatus.ARCHIVED;
  }

  activate(): void {
    this._status = SettingsCardStatus.ACTIVE;
  }

  static fromJson(json: CareProgramJson): CareProgram {
    return json
      ? new CareProgram(
        json.id,
        new TranslatedProperty(json.name),
        new TranslatedProperty(json.description),
        json.doctors ? json.doctors.map(Doctor.fromJson) : null,
        Institution.fromJson(json.institution),
        json.steps.map(CareProgramStep.fromJson),
        json.status ? SettingsCardStatus[json.status] : SettingsCardStatus.DRAFT
      )
      : null;
  }
}

export interface CareProgramJson {
  id: string;
  name: string;
  description: string;
  doctors?: DoctorJson[] | null;
  institution: InstitutionJson;
  steps: CareProgramStepJson[];
  status?: string | null;
}
