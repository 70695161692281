import { AbstractCacheService, InternalStorageItem } from '@services/cache/abstract-cache.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageCacheService extends AbstractCacheService {
  private storageSubject: BehaviorSubject<{ key: string; value: any; }>;

  constructor() {
    super();
    this.storageSubject = new BehaviorSubject<{ key: string; value: any; }>(null);

    // Listen to storage events (for when localStorage is changed in another tab)
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.storageArea === localStorage) {
        this.storageSubject.next({ key: event.key, value: this.get(event.key) });
      }
    });
  }

  observe(key: string): Observable<any> {
    return this.storageSubject.asObservable().pipe(
      filter(item => item && item.key === key),
      map(item => item.value)
    );
  }

  get<T>(key: string): T {
    const item: string = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    let parsedItem: InternalStorageItem | null = null;
    try {
      parsedItem = JSON.parse(item);
    } catch (error) {
      return item as T;
    }

    // We keep this for backward compatibility
    if (!this.isInternalStorageItem(parsedItem)) {
      return item as T;
    }

    if (this.hasExpired(parsedItem)) {
      localStorage.removeItem(key);

      return null;
    }

    return parsedItem.value;
  }

  set<T>(key: string, value: T, expiry: number = -1): void {
    const item: InternalStorageItem = {
      value,
      expiry: expiry,
      storedAt: Date.now(),
    };

    localStorage.setItem(key, JSON.stringify(item));

    // Manually trigger the subject to notify subscribers
    this.storageSubject.next({ key, value });
  }

  remove(key: string): void {
    localStorage.removeItem(key);

    // Manually trigger the subject to notify subscribers
    this.storageSubject.next({ key, value: null });
  }

  clear(): void {
    localStorage.clear();

    // Clear is a bit tricky; in a real-world scenario, you'd likely want to inform subscribers individually
    // or broadcast that everything has been cleared. Here we broadcast a null key to indicate a clear.
    this.storageSubject.next({ key: null, value: null });
  }

  has(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}
