<vh-dialog-frame
  [title]="'pages.createAppointment.blockedByServiceDialog.title' | translate"
  [showCloseButton]="!dialog.disableClose"
  (closeClicked)="close('cancel')">

  <p [innerHTML]="'pages.createAppointment.blockedByServiceDialog.message1' | translate : { priorService: data.priorService?.displayFullName, selectedService: data.selectedService?.displayFullName }"></p>

  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button [label]="'common.yes' | translate" (buttonClick)="close('yes')"> </vh-button>
    <vh-button [label]="'common.no' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="close('no')"> </vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>
