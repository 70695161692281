import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take, retry, catchError } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AnalyticsService {
  private readonly _analyticsLibraryLoadedSuccessfully$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly router: Router,
    protected readonly localStorageService: LocalStorageCacheService
  ) {}

  bootstrap(): void {
    this.monitorIfAnalyticsLibraryIsLoaded();
    this.hookIntoRouterEvents();
  }

  track(category: string, action: string | null = null, name: string | null = null): void {
    this.waitForAnalyticsLibraryToLoad$()
      .subscribe(() => {
        const payload: string[] = [category];

        if (action) {
          payload.push(action);
        }

        if (name) {
          payload.push(name);
        }

        _paq.push(payload as MatomoCommand);
      });
  }

  trackEvent(action: string, name: string | null = null): void {
    if (this.localStorageService.get(LOCAL_STORAGE_KEYS.isCookiesAccepted)) {
      this.track('trackEvent', action, name);
    }
  }

  private hookIntoRouterEvents(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.waitForAnalyticsLibraryToLoad$()
          .subscribe({
            next: () => {
              this.track('setCustomUrl', event.urlAfterRedirects);
              this.track('trackPageView');
            },
          });
      });
  }

  private monitorIfAnalyticsLibraryIsLoaded(): void {
    this.isAnalyticsLibraryLoaded$()
      .pipe(
        retry({
          count: 3,
          delay: (err: unknown, retryCount: number): Observable<number> => {
            return of(100 * Math.pow(2, retryCount));
          },
        }),
        catchError(() => {
          return of(false);
        })
      )
      .subscribe((isLoaded: boolean) => {
        this._analyticsLibraryLoadedSuccessfully$.next(isLoaded);
      });
  }

  private isAnalyticsLibraryLoaded$(): Observable<boolean> {
    return new Observable<boolean>((observer: Subscriber<boolean>) => {
      if (typeof _paq !== 'undefined') {
        observer.next(true);
        observer.complete();
      } else {
        observer.error(new Error('_paq is not defined'));
      }
    });
  }

  private waitForAnalyticsLibraryToLoad$(): Observable<boolean> {
    return this._analyticsLibraryLoadedSuccessfully$.pipe(
      filter((isLoaded: boolean) => isLoaded),
      take(1)
    );
  }
}
