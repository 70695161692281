import { Directive, Input, HostListener, Self, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { triggerFormValidation } from '@utils/helpers/form.util';

/**
 * Use this directive to run form validation for a complete form group when clicking an HTML element (such as a button)
 */
@Directive({
  selector: '[vhRunFormValidation]',
})
export class RunFormValidationDirective {
  @Input('vhRunFormValidation') actionElement: HTMLElement;
  @Input() runFormValidationOn: FormGroup | null;

  constructor(@Optional() @Self() private readonly formGroupDirective: FormGroupDirective) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    this.validateForm();
  }

  private validateForm(): void {
    const formGroup: FormGroup = this.runFormValidationOn ?? this.formGroupDirective?.form ?? null;

    triggerFormValidation(formGroup);
  }
}
