import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOGIN_FORM_KEYS } from '@constants/form-keys/login-form-keys.constant';
import { NAVIGATION } from '@constants/navigation.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { AuthenticationService } from '@services/authentication.service';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage } from '@utils/helpers/form.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { COLORS } from '@constants/colors.constant';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'vh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@UntilDestroy()
export class LoginComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly LOGIN_FORM_KEYS: typeof LOGIN_FORM_KEYS = LOGIN_FORM_KEYS;

  @Input() registerButtonIsVisible: boolean;
  @Input() afterSuccessfulLoginFunction: () => void;

  formGroup: UntypedFormGroup;

  loginButtonIsDisabled: boolean;
  errorMessageTranslationKey: string;

  private tooManyAttemptsCountdownSubscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly translate: TranslateService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    const prefilledUsername = this.activatedRoute.snapshot.queryParamMap.get('username');

    this.formGroup = this.formBuilder.group({
      [LOGIN_FORM_KEYS.get('username')]: [
        prefilledUsername ?? null,
        [Validators.required],
      ],
      [LOGIN_FORM_KEYS.get('password')]: [
        null,
        [Validators.required],
      ],
    });
  }

  navigateToForgotPasswordPage = (): void => {
    void this.router.navigate([NAVIGATION.forgotPassword.route]);
  };

  login = (): void => {
    this.errorMessageTranslationKey = extractErrorMessageFromFormValidation(
      LOGIN_FORM_KEYS,
      this.formGroup,
      this.getFormErrorMessageTranslationKey
    );

    if (this.errorMessageTranslationKey) {
      return;
    }

    this.loginButtonIsDisabled = true;
    this.errorMessageTranslationKey = null;

    this.authenticationService.login$(this.formGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.analyticsService.trackEvent('User', 'login_successful');
          this.afterSuccessfulLoginFunction();
        },
        error: (response: HttpErrorResponse | unknown): void => {
          this.handleLoginError(response);
        },
      });
  };

  onForgotUsernameClicked(): void {
    void this.router.navigate([this.NAVIGATION.forgotUsername.path]);
  }

  private getFormErrorMessageTranslationKey = (errorIdentifier: string): string => {
    if (errorIdentifier === 'required') {
      return 'pages.login.missingFieldsError';
    }

    if (errorIdentifier === 'username') {
      return 'pages.login.invalidUsernameError';
    }

    return getUnknownErrorMessage(errorIdentifier);
  };

  private getHttpErrorMessageTranslationKey = (error: HttpErrorResponse | unknown): string => {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      return 'pages.login.invalidLoginMessage';
    }

    return 'common.unknownError';
  };

  private handleLoginError(response: HttpErrorResponse | unknown): void {
    if (response instanceof HttpErrorResponse && response.error.user_requires_password_reset) {
      this.forwardToResetPasswordPage(response);

      return;
    }

    if (response instanceof HttpErrorResponse && response.status === 429) {
      const retryAfterSeconds: number = this.getRetryAfterSeconds(response);
      if (retryAfterSeconds > 0) {
        this.startCountdown(retryAfterSeconds);

        return;
      }
    }

    this.loginButtonIsDisabled = false;
    this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(response);
  }

  private forwardToResetPasswordPage(response: HttpErrorResponse): void {
    void this.router.navigate([NAVIGATION.resetPassword.route], {
      queryParams: {
        token: response.error.password_reset_token,
        is_enforced: true,
        username: this.formGroup.get('username').value,
      },
    });
  }

  private getRetryAfterSeconds(response: HttpErrorResponse): number {
    const retryAfterHeader: string = response.headers.get('Retry-After');

    return retryAfterHeader ? parseInt(retryAfterHeader, 10) : 0;
  }

  private startCountdown(secondsRemaining: number): void {
    this.loginButtonIsDisabled = true;

    // Immediately show the message with the initial seconds value
    this.translate.get('pages.login.tooManyAttemptsError', { seconds: secondsRemaining })
      .pipe(untilDestroyed(this))
      .subscribe((translatedMessage: string): void => {
        this.errorMessageTranslationKey = translatedMessage;
      });

    // Start the interval to update the message every second after the initial display
    this.tooManyAttemptsCountdownSubscription?.unsubscribe();
    this.tooManyAttemptsCountdownSubscription = interval(1000).pipe(
      take(secondsRemaining),
      map((count: number) => secondsRemaining - count - 1),
      finalize(() => {
        this.loginButtonIsDisabled = false;
        this.errorMessageTranslationKey = null;
      })
    )
      .pipe(untilDestroyed(this))
      .subscribe((remainingSeconds: number) => {
        this.translate.get('pages.login.tooManyAttemptsError', { seconds: remainingSeconds })
          .pipe(untilDestroyed(this))
          .subscribe((translatedMessage: string): void => {
            this.errorMessageTranslationKey = translatedMessage;
          });
      });
  }
}
