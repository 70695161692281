import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { Visit } from '@models/visit.model';
import { COLORS } from '@constants/colors.constant';
import { VisitService } from '@services/visits/visit.service';
import { addMinutes, differenceInMinutes, format, parse } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MASKS } from '@constants/masks.constant';
import { PROFILE_FORM_KEYS } from '@constants/form-keys/profile-form-keys.constant';
import { UntypedFormGroup } from '@angular/forms';
import { TextInputStyle } from '@enums/text-input-style.enum';
import { VisitFormService } from '@services/form-services/visit-form.service';
import { VISIT_FORM_KEYS } from '@constants/form-keys/visit-form-keys.constant';
import { PopoverService } from '@services/ui/popover.service';
import { Option } from '@interfaces/option.interface';
import { CancelVisitDialogComponent, CancelVisitDialogResult } from '@modules/settings/components/cancel-visit-dialog/cancel-visit-dialog.component';
import { getEditorRoles, getInstitutionRoles, getAdminRoles, Role } from '@enums/role.enum';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { debounceTime, Subject } from 'rxjs';

export interface OptionalVisitDetailsDialogData {
  isReadOnly?: boolean;
}

export interface VisitDetailsDialogData extends OptionalVisitDetailsDialogData {
  visit: Visit;
  visitStatusOptions: Option[];
}

export interface VisitDetailsDialogResult {
  canceledVisit: Visit | null;
  visitChanged: Visit | null;
}

@Component({
  selector: 'app-other-visit-details-dialog',
  templateUrl: './other-visit-details-dialog.component.html',
  styleUrls: ['./other-visit-details-dialog.component.scss'],
  animations: [fadeInOutAnimation],
})

@UntilDestroy()
export class OtherVisitDetailsDialogComponent implements OnInit {
  protected readonly getInstitutionRoles: () => Role[] = getInstitutionRoles;
  protected readonly getEditorRoles: () => Role[] = getEditorRoles;
  protected readonly getAdminRoles: () => Role[] = getAdminRoles;
  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly PROFILE_FORM_KEYS: typeof PROFILE_FORM_KEYS = PROFILE_FORM_KEYS;
  protected readonly VISIT_FORM_KEYS: typeof VISIT_FORM_KEYS = VISIT_FORM_KEYS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly TextInputStyle: typeof TextInputStyle = TextInputStyle;

  currentDate: string;
  isLoading: boolean;
  isClosing: boolean = false;
  visitInfoViewMode: 'read' | 'edit' = 'read';
  isUpdatingVisitInfo: boolean = false;
  visitTimeViewMode: 'read' | 'edit' = 'read';
  visitInfoFormGroup: UntypedFormGroup;
  visitTimeFormGroup: UntypedFormGroup;
  rescheduleVisitDuration: { start: Date; end: Date; };
  visit: Visit;
  isReadOnly: boolean;
  cancellationButtonTooltip: string | null = null;
  didUpdateVisit: boolean = false;

  @Output() visitUpdated: EventEmitter<Visit> = new EventEmitter<Visit>();

  private timeInputSubject: Subject<'start' | 'end'> = new Subject<'start' | 'end'>();

  constructor(
    private readonly visitService: VisitService,
    private readonly visitFormService: VisitFormService,
    private readonly popoverService: PopoverService,
    private readonly visitCancellationService: VisitCancellationService,
    private readonly dialogService: MatDialog,
    protected readonly dialogRef: MatDialogRef<OtherVisitDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: VisitDetailsDialogData
  ) {
    this.visit = data.visit;
    this.isReadOnly = data.isReadOnly;
  }

  ngOnInit(): void {
    this.currentDate = format(new Date(), DATE_FORMATS.serverDate);
    this.visitInfoFormGroup = this.visitFormService.createUpdateOtherVisitInfoFormGroup(this.visit);
    this.visitTimeFormGroup = this.visitFormService.createUpdateOtherVisitTimeFormGroup(this.visit);
    // Setup debounced time input handler
    this.timeInputSubject.pipe(
      debounceTime(500),
      untilDestroyed(this)
    ).subscribe((changedField: 'start' | 'end') => {
      this.rescheduleVisitDuration = this.calculateNewStartAndEndDateTimeOfVisit(changedField);
      this.popoverService.open('reschedule');
    });
    // This is needed to close the dialog with a result when the user clicks outside of it
    this.dialogRef.beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.isClosing) {
          this.close();
        }
      });
    this.fetchVisitDetails(true);
  }

  close(didCancelVisit: boolean = false): void {
    this.isClosing = true;
    this.dialogRef.close({ canceledVisit: didCancelVisit ? this.visit : null, visitChanged: this.didUpdateVisit ? this.visit : null });
  }

  onCancelVisitClicked(): void {
    this.onCancelVisitClickedAsAdmin();
  }

  onEditVisitInfoClicked(): void {
    this.visitInfoViewMode = 'edit';
    this.visitInfoFormGroup = this.visitFormService.createUpdateOtherVisitInfoFormGroup(this.visit);
  }

  onCancelEditVisitInfoClicked(): void {
    this.visitInfoViewMode = 'read';
    this.visitInfoFormGroup = this.visitFormService.createUpdateOtherVisitInfoFormGroup(this.visit);
  }

  onVisitInfoSaveClicked(): void {
    this.isUpdatingVisitInfo = true;
    const formValue = this.visitInfoFormGroup.value;
    const updateData = {
      title: formValue[this.VISIT_FORM_KEYS.get('customTitle')],
    };
    this.visitService.updateVisit$(this.visit.id, updateData)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (visit: Visit) => {
          this.visit = visit;
          this.visitInfoViewMode = 'read';
          this.isUpdatingVisitInfo = false;
          this.didUpdateVisit = true;
        },
        error: () => {
          this.isUpdatingVisitInfo = false;
        },
      });
  }

  onEditVisitClicked(): void {
    this.visitTimeViewMode = 'edit';
    this.visitTimeFormGroup = this.visitFormService.createUpdateOtherVisitTimeFormGroup(this.visit);
  }

  onCancelEditVisitClicked(): void {
    this.visitTimeViewMode = 'read';
    this.visitTimeFormGroup = this.visitFormService.createUpdateOtherVisitTimeFormGroup(this.visit);
    this.popoverService.close('reschedule');
  }

  onVisitDateTimeChanged(changedField: 'start' | 'end'): void {
    this.timeInputSubject.next(changedField);
  }

  onVisitDateTimeSaved(visit: Visit): void {
    this.rescheduleVisitDuration = null;
    this.visit = visit;
    this.visitTimeFormGroup = this.visitFormService.createUpdateOtherVisitTimeFormGroup(this.visit);
    this.popoverService.close('reschedule');
    this.visitTimeViewMode = 'read';
    this.didUpdateVisit = true;
    this.visitUpdated.emit(visit);
  }

  private onCancelVisitClickedAsAdmin(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      visit: this.visit,
      panelClass: 'dialog-size-medium',
    };
    this.dialogService.open(CancelVisitDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: CancelVisitDialogResult) => {
        if (result.status === 'ok') {
          this.close(true);
        }
      });
  }

  private fetchVisitDetails(shouldShowLoadingSkeleton: boolean = true): void {
    this.isLoading = shouldShowLoadingSkeleton;
    // Fetch details of the visit which are only available after fetching by id
    this.visitService.getVisitById$(this.visit.id)
      .pipe(untilDestroyed(this))
      .subscribe((visit: Visit) => {
        this.visit = visit;
        this.didUpdateVisit = true;
        this.visitCancellationService.getVisitNotCancellableReason(this.visit)
          .pipe(untilDestroyed(this))
          .subscribe((reason: string | null) => {
            this.cancellationButtonTooltip = reason;
            this.isLoading = false;
          });
      });
  }

  private calculateNewStartAndEndDateTimeOfVisit(changedField: 'start' | 'end'): { start: Date; end: Date; } {
    const startDate = this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('startDate')).value;
    const startTime = this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('startTime')).value;
    const endTime = this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('endTime')).value;
    let start: Date = parse(
      `${startDate} ${startTime}`,
      DATE_FORMATS.serverDateTimeShort,
      new Date()
    );
    let end: Date = parse(
      `${startDate} ${endTime}`,
      DATE_FORMATS.serverDateTimeShort,
      new Date()
    );
    if (changedField === 'start') {
      if (differenceInMinutes(end, start) < 1) {
        end = addMinutes(start, 60);
        // Update the form control values for end time
        const endTimeString = format(end, 'HH:mm');
        const endDateString = format(end, 'yyyy-MM-dd');
        this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('endTime')).setValue(endTimeString, { emitEvent: false });
        this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('endDate')).setValue(endDateString, { emitEvent: false });
      }
    }
    if (changedField === 'end') {
      if (differenceInMinutes(end, start) < 1) {
        start = addMinutes(end, -60);
        // Update the form control values for start time
        const startTimeString = format(start, 'HH:mm');
        const startDateString = format(start, 'yyyy-MM-dd');
        this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('startTime')).setValue(startTimeString, { emitEvent: false });
        this.visitTimeFormGroup.get(VISIT_FORM_KEYS.get('startDate')).setValue(startDateString, { emitEvent: false });
      }
    }

    return { start, end };
  }
}
