import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models/user.model';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MASKS } from '@constants/masks.constant';
import { COLORS } from '@constants/colors.constant';
import { UserService } from '@services/user.service';

@Component({
  selector: 'vh-create-conversation-missing-info-page',
  templateUrl: './create-conversation-missing-info-page.component.html',
  styleUrl: './create-conversation-missing-info-page.component.scss',
})
@UntilDestroy()
export class CreateConversationMissingInfoPageComponent {
  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly COLORS: typeof COLORS = COLORS;

  user: User | null = null;
  email: string | null = null;
  phone: string | null = null;

  constructor(
    private readonly router: Router,
    private readonly conversationService: CreateConversationService,
    private readonly userService: UserService
  ) {
    this.conversationService.setStep(1);
    this.user = this.conversationService.user;
    this.email = this.user?.email ?? this.conversationService.email;
    this.phone = this.user?.phone ?? this.conversationService.phone;
  }

  onEmailChange(event) {
    // TODO: check if valid email
    this.email = event.target.value;
  }

  onPhoneChange(event) {
    // TODO: check if valid phone
    this.phone = event.target.value;
  }

  goToNextStep(): void {
    if (this.email == null && this.phone == null) {
      return; // Corresponding error message
    }

    this.userService.updateUser$(this.user.id, {
      phone: this.phone,
      email: this.email,
    }).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.conversationService.email = this.email;
        this.conversationService.phone = this.phone;
        void this.router.navigate([CONVERSATION_NAV.create.planning.route]);
      });
  }

  goBack(): void {
    history.back();
  }
}
