<div class="flex-row">
  <h1>{{ 'wallet.history.title' | translate }}</h1>
  <vh-button-group
    class="clickable align-end"
    [style]="ButtonGroupStyle.CONNECTED"
    [items]="viewingOptions"
    [singleItemSelectable]="true"
    (itemClick)="onViewingOptionClicked($event)">
  </vh-button-group>
</div>

<ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingTemplate"></ng-container>
<ng-container *ngIf="!isLoading" [ngTemplateOutlet]="contentTemplate"></ng-container>

<ng-template #contentTemplate>
  <div [vhMarginTop]="16" class="flex-col gap-v-16" infiniteScroll (scrolled)="onScrollEnd()">
    <vh-wallet-transactions-card
      *ngFor="let transaction of walletTransactions"
      [transaction]="transaction">
    </vh-wallet-transactions-card>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <vh-loader class="position-absolute-center"></vh-loader>
</ng-template>
