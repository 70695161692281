import { Injectable } from '@angular/core';
import { FormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { SURVEY_FORM_KEYS } from '@constants/form-keys/survey-form-keys.constant';
import { AnswerOption } from '@models/answer-option.model';
import { Survey } from '@models/survey.model';
import { Question } from '@models/question.model';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { IObject } from '@app-types/iobject.type';

@Injectable({
  providedIn: 'root',
})
export class SurveyFormService extends AbstractFormService {
  createFormGroup(survey?: Survey, language?: string): UntypedFormGroup {
    const group: FormArray = new FormArray([]);
    if (survey) {
      survey?.questions
        .sort((a: Question, b: Question) => a.listPositionNumber - b.listPositionNumber)
        .forEach((question: Question) => {
          group.push(this.createQuestion(question, 1, survey?.isReadOnly, language));
        });
    } else {
      group.push(this.createQuestion(null, 1, survey?.isReadOnly, language));
    }

    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [SURVEY_FORM_KEYS.get('name')]: [
        survey?.name.get(language) ?? '',
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('description')]: [
        survey?.description.get(language) ?? '',
        [],
      ],
      [SURVEY_FORM_KEYS.get('status')]: [
        survey?.status.toUpperCase() ?? 'DRAFT',
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('questions')]: group,
    });

    if (survey?.isReadOnly) {
      formGroup.disable();
    }

    return formGroup;
  }

  createQuestion(question?: Question, index: number = 1, disabled: boolean = false, language?: string): UntypedFormGroup {
    const answerOptionGroup: FormArray = new FormArray([]);

    if (question?.answerOptions) {
      question?.answerOptions.forEach((option: AnswerOption) => {
        answerOptionGroup.push(this.createAnswerOption(option, disabled, language));
      });
    }

    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [SURVEY_FORM_KEYS.get('listPositionNumber')]: [
        question?.listPositionNumber ?? (index ? index + 1 : 1),
        [Validators.min(1)],
      ],
      [SURVEY_FORM_KEYS.get('questionId')]: [
        question?.id ?? null,
        [],
      ],
      [SURVEY_FORM_KEYS.get('parentId')]: [
        question?.parentId ?? null,
        [],
      ],
      [SURVEY_FORM_KEYS.get('validationRuleId')]: [
        question?.validationRuleId ?? null,
        [],
      ],
      [SURVEY_FORM_KEYS.get('isVisibleForInstitution')]: [
        question?.isVisibleForInstitution ?? true,
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('isVisibleForPatient')]: [
        question?.isVisibleForPatient ?? true,
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('questionType')]: [
        question?.type ?? 'TEXT',
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('answerType')]: [
        question?.answerType ?? 'SINGLE_LINE',
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('question')]: [
        question?.question.get(language) ?? '',
        [Validators.required],
      ],
      [SURVEY_FORM_KEYS.get('description')]: [
        question?.description.get(language) ?? '',
        [],
      ],
      [SURVEY_FORM_KEYS.get('answerOptions')]: answerOptionGroup,
    });

    if (disabled) {
      formGroup.disable();
    }

    return formGroup;
  }

  createAnswerOption = (option?: AnswerOption, disabled: boolean = false, language?: string): UntypedFormGroup => {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [SURVEY_FORM_KEYS.get('answerOptionId')]: [
        option?.id ?? null,
        [],
      ],
      [SURVEY_FORM_KEYS.get('answerOptionValue')]: [
        option?.value.get(language) ?? '',
        [Validators.required],
      ],
    });

    if (disabled) {
      formGroup.disable();
    }

    return formGroup;
  };

  formGroupToRequestBody(formGroup: UntypedFormGroup, language: string): IObject {
    const questions: IObject[] = (formGroup.get(SURVEY_FORM_KEYS.get('questions')) as FormArray).controls
      .map((question: UntypedFormGroup) => {
        const answerOptions: IObject[] = (question.get(SURVEY_FORM_KEYS.get('answerOptions')) as FormArray).controls
          .map((option: UntypedFormGroup) => ({
            value: {
              [language]: option.get(SURVEY_FORM_KEYS.get('answerOptionValue')).value,
            },
          }));

        return {
          answer_options: answerOptions,
          question: {
            [language]: question.get(SURVEY_FORM_KEYS.get('question')).value,
          },
          is_visible_for_institution: question.get(SURVEY_FORM_KEYS.get('isVisibleForInstitution')).value,
          is_visible_for_patient: question.get(SURVEY_FORM_KEYS.get('isVisibleForPatient')).value,
          list_position_number: question.get(SURVEY_FORM_KEYS.get('listPositionNumber')).value,
          answer_type: question.get(SURVEY_FORM_KEYS.get('answerType')).value,
          description: {
            [language]: question.get(SURVEY_FORM_KEYS.get('description')).value,
          },
          type: question.get(SURVEY_FORM_KEYS.get('questionType')).value,
        };
      });

    return {
      name: {
        [language]: formGroup.get(SURVEY_FORM_KEYS.get('name')).value,
      },
      description: {
        [language]: formGroup.get(SURVEY_FORM_KEYS.get('description')).value,
      },
      status: formGroup.get(SURVEY_FORM_KEYS.get('status')).value,
      questions: questions,
    };
  }
}
