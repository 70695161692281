import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { MatDialog } from '@angular/material/dialog';
import { QrDialogComponent } from '@modules/shared/core/dialogs/qr-dialog/qr-dialog.component';
import { ToastService } from '@services/ui/toast.service';

@Component({
  selector: 'vh-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  animations: [fadeInOutAnimation],
})
export class CopyToClipboardComponent implements OnInit {
  protected readonly DEFAULT_CONFIRMATION_DURATION: number = 2000;

  @Input() text: string;
  @Input() confirmationMessage: string = 'common.copied';
  @Input() icon: string = 'assets/icons/copy.svg';

  @Input() showQr: boolean;
  @Input() qrUrl: string | null;
  @Input() qrTitle: string;
  @Input() qrIcon: string = 'assets/icons/qr.svg';


  protected isShowingConfirmationMessage: boolean = false;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly dialog: MatDialog,
    private readonly toastService: ToastService,
  ) { }

  ngOnInit(): void {
    if (this.showQr) {
      this.qrUrl = this.qrUrl || this.text;
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.text);
    this.toastService.showDefault(this.confirmationMessage, 'common.ok', { duration: this.DEFAULT_CONFIRMATION_DURATION }).subscribe();
  }

  showQrCode(): void {
    this.dialog
      .open(QrDialogComponent, {
        data: {
          url: this.qrUrl,
          title: this.qrTitle,
        },
      });
  }
}
