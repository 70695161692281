export const CONVERSATION_NAV = {
  create: {
    path: 'conversation/create',
    route: '/conversation/create',
    selectPatient: {
      path: 'patient/select',
      route: '/conversation/create/patient/select',
    },
    missingInfo: {
      path: 'patient/info',
      route: '/conversation/create/patient/info',
    },
    newPatient: {
      path: 'patient/new',
      route: '/conversation/create/patient/new',
    },
    planning: {
      path: 'planning',
      route: '/conversation/create/planning',
    },
    confirm: {
      path: 'confirm',
      route: '/conversation/create/confirm',
    },
  },
  details: {
    path: 'conversation/:conversation_uuid',
    route: '/conversation/:conversation_uuid',
    params: {
      conversation_uuid: 'conversation_uuid',
    },
  },
  conversation: {
    path: 'chat/:conversation_uuid',
    route: '/chat/:conversation_uuid',
    params: {
      conversation_uuid: 'conversation_uuid',
    },
  },
};
