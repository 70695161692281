<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section *ngIf="isSubmitting" class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.account.loading' | translate }}</h1>
</section>
<section *ngIf="isSubmitting" class="flex-col gap-v-16" [vhMarginTop]="16">
  <vh-loader></vh-loader>
</section>

<section *ngIf="!isSubmitting" class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.account.title' | translate }}</h1>
</section>
<form *ngIf="!isSubmitting" class="flex-col gap-v-16" [vhMarginTop]="24" [formGroup]="formGroup">
  <vh-text-input
    type="email"
    [formControlName]="REGISTER_FORM_KEYS.get('email')"
    [label]="'common.fields.email' | translate"
    [placeholder]="'common.fields.email' | translate"
    autocomplete="email">
  </vh-text-input>
  <vh-horizontal-divider
    [showDashLine]="true">
  </vh-horizontal-divider>
  <vh-username-input
    [label]="'common.fields.username' | translate"
    [formControlName]="REGISTER_FORM_KEYS.get('username')"
    [firstname]="onboardingPayload.first_name"
    [lastname]="onboardingPayload.last_name"
    [emailField]="formGroup.get(REGISTER_FORM_KEYS.get('email')).value">
  </vh-username-input>
  <vh-password-input
    [label]="'common.fields.password' | translate"
    [formControlName]="REGISTER_FORM_KEYS.get('password')">
  </vh-password-input>
  <vh-button
    class="align-center"
    iconRightPath="assets/icons/arrow-narrow-right.svg"
    [iconRightStroke]="COLORS.white"
    [isDisabled]="!formGroup.valid || isSubmitting"
    [label]="'new.onboarding.account.button' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>
</form>