import { Injectable } from '@angular/core';
import { BookingFlow } from '@enums/booking-flow.enum';
import { Institution } from '@models/institution.model';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PendingBooking } from '@models/pending-booking.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { PendingBookingService } from '@services/pending-booking.service';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { Mode } from '@enums/mode.enum';
import { getInstitutionEditorRoles, getReferringPhysicianRoles, Role } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { InstitutionService } from '@services/institution.service';
import { Visit } from '@models/visit.model';
import { VisitService } from '@services/visits/visit.service';

@Injectable({
  providedIn: 'root',
})
export class BookingFlowInitiatorService {
  constructor(
    private readonly pendingBookingService: PendingBookingService,
    private readonly bookingFlowNavigationService: BookingFlowNavigationService,
    private readonly bookingState: BookingFlowStateService,
    private readonly authenticationService: AuthenticationService,
    private readonly institutionService: InstitutionService,
    private readonly visitService: VisitService
  ) {}

  initialise$(flow: BookingFlow = null, institution: Institution | string = null): Observable<void> {
    flow = flow ?? this.determineBookingFlowBasedOnContext();

    return this.resolveInstitution(flow, institution).pipe(
      switchMap((resolvedInstitution: Institution) => {
        this.bookingState.reset(flow, resolvedInstitution);

        return this.pendingBookingService.createPendingBooking$(this.bookingState.toJson()).pipe(
          tap((pendingBooking: PendingBooking) => {
            this.bookingFlowNavigationService.navigateToBookingFlow(flow, pendingBooking, resolvedInstitution);
          })
        );
      })
    ).pipe(switchToEmptyObservable());
  }

  initialiseFromExistingVisit$(visit: Visit, flow: BookingFlow): Observable<void> {
    return this.visitService.getVisitById$(visit.id).pipe(
      switchMap((fetchedVisit: Visit) => {
        this.bookingState.reset(flow, fetchedVisit.institution);
        this.bookingState.services = [fetchedVisit.service];
        this.bookingState.serviceTypes = [fetchedVisit.service.type];
        this.bookingState.filter.selectedInstitutionId = fetchedVisit.institution.id;
        this.bookingState.booking.selectPatient(fetchedVisit.patient);

        return this.pendingBookingService.createPendingBooking$(this.bookingState.toJson()).pipe(
          tap((pendingBooking: PendingBooking) => {
            this.bookingFlowNavigationService.navigateToBookingFlow(flow, pendingBooking, fetchedVisit.institution);
          })
        );
      })
    ).pipe(switchToEmptyObservable());
  }

  restart(): Observable<void> {
    return this.initialise$(this.bookingState.flow, this.bookingState.scopedInstitution);
  }

  protected resolveInstitution(flow: BookingFlow = null, institution: Institution | string = null): Observable<Institution> {
    if (typeof institution === 'string') {
      return this.institutionService.getInstitutionByIdOrSlug$(institution);
    }

    return of(institution ?? this.determineInstitutionBasedOnContext(flow));
  }

  protected determineBookingFlowBasedOnContext(): BookingFlow {
    if (
      this.authenticationService.mode === Mode.ADMIN &&
      this.authenticationService.currentUser.hasAnyRole(getInstitutionEditorRoles()) ||
      this.authenticationService.currentUser.hasRole(Role.ADMIN)
    ) {
      return BookingFlow.INSTITUTION_EMPLOYEE;
    }

    if (this.authenticationService.mode === Mode.ADMIN && this.authenticationService.currentUser.hasAnyRole(getReferringPhysicianRoles())) {
      return BookingFlow.REFERRING_PHYSICIAN;
    }

    return BookingFlow.PUBLIC;
  }

  protected determineInstitutionBasedOnContext(flow: BookingFlow): Institution | null {
    if (flow === BookingFlow.INSTITUTION_EMPLOYEE || flow === BookingFlow.REFERRING_PHYSICIAN) {
      return this.authenticationService.institution;
    }

    return null;
  }
}
