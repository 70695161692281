export class Specialty {
  constructor(
    private _id: string,
    private _name: string,
    private _children: Specialty[] | null = null,
    private _parent: Specialty | null = null
  ) {
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get children(): Specialty[] {
    return this._children;
  }

  get parent(): Specialty | null {
    return this._parent;
  }

  static fromJson(json: SpecialtyJson): Specialty {
    return new Specialty(
      json.id,
      json.name,
      json.children?.map(Specialty.fromJson),
      json.parent ? Specialty.fromJson(json.parent) : null
    );
  }
}

export interface SpecialtyJson {
  id: string;
  name: string;
  parent: SpecialtyJson | null;
  children: SpecialtyJson[] | null;
}
