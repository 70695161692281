import { NgModule } from '@angular/core';
import { ContactModule } from '@modules/public/contact/contact.module';
import { PrivacyStatementModule } from '@modules/public/privacy-statement/privacy-statement.module';
import { FormModule } from '@modules/shared/form/form.module';

@NgModule({
  declarations: [],
  imports: [
    FormModule,
    ContactModule,
    PrivacyStatementModule,
  ],
})
export class PublicModule {}
