import { Injectable } from '@angular/core';
import { endOfMonth, format, isPast, isToday, startOfMonth } from 'date-fns';
import { DaysWithRoomPlanning } from '@models/days-with-room-planning.model';
import { PlanningService } from '@services/planning.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { invertDates } from '@utils/helpers/date.util';

@Injectable({
  providedIn: 'root',
})
export class SlotSelectionPlanningService {
  private _selectedDate: Date = new Date();
  private _unavailableDates: Date[] = [];
  private _availableDates: Date[] = [];
  private _firstDayOfSelectedMonth: Date;
  private _didChangeSelectedDate: boolean = false;

  constructor(private readonly planningService: PlanningService) {
    this._firstDayOfSelectedMonth = startOfMonth(this._selectedDate);
  }

  get selectedDate(): Date {
    return this._selectedDate;
  }

  set selectedDate(value: Date) {
    this._didChangeSelectedDate = true;
    this._selectedDate = value;
  }

  get unavailableDates(): Date[] {
    return this._unavailableDates;
  }

  get availableDates(): Date[] {
    return this._availableDates;
  }

  get firstDayOfSelectedMonth(): Date {
    return this._firstDayOfSelectedMonth;
  }

  set firstDayOfSelectedMonth(value: Date) {
    this._firstDayOfSelectedMonth = value;
  }

  get lastDayOfSelectedMonth(): Date {
    return endOfMonth(this._firstDayOfSelectedMonth);
  }

  get optimisedFirstDay(): Date {
    if (isPast(this.firstDayOfSelectedMonth) || isToday(this.firstDayOfSelectedMonth)) {
      return new Date();
    }

    return this.firstDayOfSelectedMonth;
  }

  fetchUnavailableDays$(startDate: Date = this.optimisedFirstDay, endDate: Date = this.lastDayOfSelectedMonth): Observable<Date[]> {
    return this.fetchOpenDays$(startDate, endDate)
      .pipe(map((openDays: Date[]) => {
        return invertDates(
          startDate,
          endDate,
          openDays
        );
      }))
      .pipe(tap((unavailableDates: Date[]) => {
        this._unavailableDates = unavailableDates;
      }));
  }

  fetchOpenDays$(startDate: Date = this.optimisedFirstDay, endDate: Date = this.lastDayOfSelectedMonth, attempt: number = 0, maxAttempts: number = 12): Observable<Date[]> {
    if (attempt >= maxAttempts) {
      return of([]);
    }

    return this.planningService.getDaysHavingPlannedSlots$(startDate, endDate)
      .pipe(
        map((result: DaysWithRoomPlanning[]): Date[] => {
          const dates: Date[] = result.flatMap((item: DaysWithRoomPlanning) => item.days);
          const uniqueDateStrings: string[] = [...new Set(dates.map((date: Date) => format(date, 'yyyy-MM-dd')))].sort();

          return uniqueDateStrings.map((dateString: string) => new Date(dateString));
        }),
        switchMap((dates: Date[]) => {
          if (dates.length === 0) {
          // No available dates, fetch for the next month
            const nextMonthStartDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
            const nextMonthEndDate = new Date(nextMonthStartDate.getFullYear(), nextMonthStartDate.getMonth() + 1, 0);

            return this.fetchOpenDays$(nextMonthStartDate, nextMonthEndDate, attempt + 1, maxAttempts);
          }

          return of(dates);
        })
      )
      .pipe(tap((dates: Date[]) => {
        this._availableDates = dates;

        if (!this._didChangeSelectedDate && this._availableDates.length > 0) {
          this._selectedDate = this._availableDates[0];
        }
      }));
  }
}
