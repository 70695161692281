<vh-centered-card-page>
  <vh-post-action-status
    status="success"
    [isStroke]="true"
    [actionText]="'common.ok' | translate"
    [message]="'pages.profile.verifyEmail.verifySuccess' | translate"
    [title]="'pages.resetPassword.submitted.title' | translate"
    (actionClick)="onButtonClick()">
  </vh-post-action-status>
</vh-centered-card-page>
