export const CARE_PROGRAM_FORM_KEYS: Map<string, string> = new Map<string, string>([
  ['name', 'name'],
  ['description', 'description'],
  ['doctors', 'doctors'],
  ['steps', 'steps'],
  ['status', 'status'],
]);

export const CARE_PROGRAM_STEP_FORM_KEYS: Map<string, string> = new Map<string, string>([
  ['id', 'id'],
  ['name', 'name'],
  ['description', 'description'],
  ['leading', 'leading'],
  ['timeToLeading', 'time_to_leading'],
  ['timeToLeadingConverted', 'time_to_leading_converted'],
  ['timeToLeadingUnit', 'time_to_leading_unit'],
  ['marginBefore', 'margin_before'],
  ['marginBeforeUnit', 'margin_before_unit'],
  ['marginAfter', 'margin_after'],
  ['marginAfterUnit', 'margin_after_unit'],
  ['services', 'services'],
  ['service', 'service'],
]);
