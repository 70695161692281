<mat-expansion-panel #mep hideToggle>
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <div class="flex-row-s-t">
            <div class="header">
                <ng-content select="[header]"></ng-content>
                <ng-content *ngIf="!mep.expanded" select="[header-closed]"></ng-content>
            </div>
            <vh-icon [@rotateAnimation]="mep.expanded" [src]="'assets/icons/chevron-down.svg'"
                [fill]="'var(--color-primary)'" [height]="16" [width]="16"></vh-icon>
        </div>
    </mat-expansion-panel-header>
    <ng-content select="[content]"></ng-content>
</mat-expansion-panel>
