import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Visit } from '@models/visit.model';

type CardTemplateType = 'single' | 'multiple' | 'skeleton';

/**
 * A card that can handle showing a single visit card or multiple based on the type of booking that was made.
 */
@Component({
  selector: 'vh-dynamic-visit-card',
  templateUrl: './dynamic-visit-card.component.html',
  styleUrls: ['./dynamic-visit-card.component.scss'],
})
export class DynamicVisitCardComponent implements OnChanges, AfterViewInit {
  protected cardTemplate: CardTemplateType = 'skeleton';

  @Input() isLoading: boolean = true;
  @Input() visits: Visit | Visit[] | null = null;

  @Output() cardClick: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visits || changes.isLoading) {
      this.cardTemplate = this.getCardTemplate();
    }
  }

  ngAfterViewInit(): void {
    this.cardTemplate = this.getCardTemplate();
  }

  onCardClicked = (): void => {
    this.cardClick.emit();
  };

  getCardTemplate(): CardTemplateType | null {
    if (!this.visits) {
      return null;
    }

    if (Array.isArray(this.visits) && this.visits.length === 1) {
      return 'single';
    }

    return 'multiple';
  }
}
