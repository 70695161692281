import { Injectable } from '@angular/core';
import { Occurrence } from '@models/occurrence.model';
import { map, Observable, of } from 'rxjs';
import { RoomPlanning } from '@models/room-planning.model';
import { tap } from 'rxjs/operators';
import { BaseOpeningsService } from '@modules/admin/admin-dashboard/services/openings/base-openings.service';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';

@Injectable({
  providedIn: 'root',
})
export class DailyOrWeeklyOpeningsService extends BaseOpeningsService {
  loadOpenings$(filter: VisitFilter): Observable<CalendarOpening[]> {
    if (filter.selectedRooms.size === 0) {
      return of([]);
    }

    if (this.shouldHitCache(filter)) {
      return of(this.filterOpeningsByRoom(filter.selectedRooms));
    }

    return this.planningService.getPlanningOfMultipleRooms$(filter.from, filter.to, filter.allRooms)
      .pipe(map((planning: RoomPlanning[]): CalendarOpening[] => this.mapRoomPlanningToOpenings(planning)))
      .pipe(
        tap((openings: CalendarOpening[]): void => {
          this.openings = openings;
        })
      );
  }

  private mapRoomPlanningToOpenings(planning: RoomPlanning[]): CalendarOpening[] {
    return planning.flatMap((item: RoomPlanning): CalendarOpening[] =>
      item.occurrences.map((occurrence: Occurrence): CalendarOpening => ({
        id: item.room.id,
        start: occurrence.start,
        end: occurrence.end,
        color: item.room.color,
      })));
  }
}
