import { bestEffortConvertTimeToTimeForUnit, bestEffortDetermineTimeUnit, timeInDaysFromTimeUnit } from '@utils/helpers/date.util';
import { CareProgramStep } from '@models/care-program-step.model';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { Injectable } from '@angular/core';
import { CareProgram } from '@models/care-program.model';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IObject } from '@app-types/iobject.type';
import { CARE_PROGRAM_FORM_KEYS, CARE_PROGRAM_STEP_FORM_KEYS } from '@constants/form-keys/care-program-form-keys.constant';
import { Service } from '@models/service.model';
import { AuthenticationService } from '@services/authentication.service';
import { SettingsCardStatus } from '@modules/settings/enums/settings-card-status.enum';

@Injectable({
  providedIn: 'root',
})
export class CareProgramFormService extends AbstractFormService {
  constructor(protected readonly formBuilder: UntypedFormBuilder, private readonly authenticationService: AuthenticationService) {
    super(formBuilder);
    this.language = this.authenticationService.institution.settings.defaultLanguage;
  }

  language: string;
  careProgramForm: UntypedFormGroup;

  createFormGroup(careProgram?: CareProgram): UntypedFormGroup {
    const steps: FormArray = new FormArray([]);
    if (careProgram) {
      careProgram?.steps.forEach((step: CareProgramStep) => {
        steps.push(this.createStep(step));
      });
      steps.validator = Validators.minLength(1);
    }

    this.careProgramForm = this.formBuilder.group({
      [CARE_PROGRAM_FORM_KEYS.get('name')]: [careProgram?.name.get(this.language) ?? '', [Validators.required]],
      [CARE_PROGRAM_FORM_KEYS.get('description')]: [careProgram?.description.get(this.language), []],
      [CARE_PROGRAM_FORM_KEYS.get('status')]: [careProgram?.status ?? SettingsCardStatus.DRAFT, []],
      [CARE_PROGRAM_FORM_KEYS.get('steps')]: steps,
    });

    return this.careProgramForm;
  }

  get stepsFormArray(): FormArray {
    return this.careProgramForm.get(CARE_PROGRAM_FORM_KEYS.get('steps')) as FormArray;
  }

  createStep(step?: CareProgramStep): UntypedFormGroup {
    const services: FormArray = new FormArray([]);
    if (step) {
      step.services.forEach((service: Service) => {
        services.push(this.createService(service));
      });
    }
    services.validator = Validators.minLength(1);

    return this.formBuilder.group({
      [CARE_PROGRAM_STEP_FORM_KEYS.get('id')]: [step?.id ?? 'new step' + this.stepsFormArray.length + 1, [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('name')]: [step?.name.get(this.language), [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('leading')]: [step?.leading ?? false, [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingConverted')]: [bestEffortConvertTimeToTimeForUnit(step?.timeToLeading), []],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeading')]: [step?.timeToLeading, [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('timeToLeadingUnit')]: [bestEffortDetermineTimeUnit(step?.timeToLeading).value, []],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('marginBefore')]: [bestEffortConvertTimeToTimeForUnit(step?.timeRangeBefore), [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('marginBeforeUnit')]: [bestEffortDetermineTimeUnit(step?.timeRangeBefore).value, []],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('marginAfter')]: [bestEffortConvertTimeToTimeForUnit(step?.timeRangeAfter), [Validators.required]],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('marginAfterUnit')]: [bestEffortDetermineTimeUnit(step?.timeRangeAfter).value, []],
      [CARE_PROGRAM_STEP_FORM_KEYS.get('services')]: services,
    });
  }

  createService(service?: Service): UntypedFormGroup {
    return this.formBuilder.group({
      [CARE_PROGRAM_STEP_FORM_KEYS.get('service')]: [service?.id, [Validators.required]],
    });
  }

  updateTimeToLeading(id: string, time: number, unit: string): UntypedFormGroup {
    const updatedStep = this.stepsFormArray.controls.find((step: UntypedFormGroup) => step.controls.id.value === id) as UntypedFormGroup;
    updatedStep.controls.time_to_leading.setValue(timeInDaysFromTimeUnit(time, unit));

    return this.sortSteps();
  }

  updateLeading(id: string): UntypedFormGroup {
    const oldLeadingStep = this.stepsFormArray.controls.find((step: UntypedFormGroup) => step.controls.leading.value) as UntypedFormGroup;
    const newLeadingStep = this.stepsFormArray.controls.find((step: UntypedFormGroup) => step.controls.id.value === id) as UntypedFormGroup;
    const timeToLeadingOffset = newLeadingStep.controls.time_to_leading.value;
    oldLeadingStep.controls.leading.setValue(false);
    newLeadingStep.controls.leading.setValue(true);
    this.stepsFormArray.controls.forEach((step: UntypedFormGroup) => {
      step.controls.time_to_leading.setValue(step.controls.time_to_leading.value - timeToLeadingOffset);
    });
    this.stepsFormArray.markAllAsTouched();

    return this.sortSteps();
  }

  sortSteps(): UntypedFormGroup {
    this.stepsFormArray.controls.forEach((step: UntypedFormGroup) => {
      step.controls.time_to_leading_converted.setValue(bestEffortConvertTimeToTimeForUnit(step.controls.time_to_leading.value));
      step.controls.time_to_leading_unit.setValue(bestEffortDetermineTimeUnit(step.controls.time_to_leading.value).value);
    });
    this.stepsFormArray.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup) => {
      return a.controls.time_to_leading.value - b.controls.time_to_leading.value;
    });

    return this.careProgramForm;
  }

  adjustTime(step: UntypedFormGroup, time: number): void {
    const newTime = timeInDaysFromTimeUnit(step.controls.time_to_leading.value, step.controls.time_to_leading_unit.value) + time;
    const newUnit = bestEffortDetermineTimeUnit(newTime);
    const newTimeValue = bestEffortConvertTimeToTimeForUnit(newTime);
    step.controls.time_to_leading.setValue(newTimeValue);
    step.controls.time_to_leading_unit.setValue(newUnit.value);
    this.sortSteps();
  }

  toRequestObject(formGroup: UntypedFormGroup, institutionId: string): IObject {
    const steps = [];
    for (const step of formGroup.controls.steps.value) {
      const services = [];
      for (const service of step.services) {
        services.push({ id: service.service });
      }
      steps.push({
        id: step.id,
        name: { [this.language]: step.name },
        leading: step.leading,
        days_to_leading: step.time_to_leading,
        margin_before: step.margin_before,
        margin_after: step.margin_after,
        services: services,
      });
    }

    return {
      name: { [this.language]: formGroup.controls.name.value },
      description: { [this.language]: formGroup.controls.description.value },
      status: formGroup.controls.status.value,
      steps: steps,
      institution_id: institutionId,
    };
  }
}
