import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule, Routes } from '@angular/router';
import { BookingSharedModule } from '@modules/booking/booking-shared.module';
import { BookVisitPageComponent } from '@modules/booking/pages/book-visit-page/book-visit-page.component';
import { ViewVisitTasksPageComponent } from '@modules/booking/pages/view-visit-tasks-page/view-visit-tasks-page.component';
import { StepUploadReferralLetterComponent } from '@modules/booking/components/step-upload-referral-letter/step-upload-referral-letter.component';
import { ContinueMobileBannerComponent } from '@modules/booking/components/continue-mobile-banner/continue-mobile-banner.component';
import { StepSelectLocationComponent } from '@modules/booking/components/step-select-location/step-select-location.component';
import { ContactForVisitPageComponent } from '@modules/booking/pages/contact-for-visit-page/contact-for-visit-page.component';
import { UploadDocumentPageComponent } from '@modules/booking/pages/upload-document-page/upload-document-page.component';
import { StepSelectSlotComponent } from '@modules/booking/components/step-select-slot/step-select-slot.component';
import { BookingConfirmationPageComponent } from '@modules/booking/pages/booking-confirmation-page/booking-confirmation-page.component';
import { MatDialogModule } from '@angular/material/dialog';
import { VisitServiceDependencyDialogComponent } from '@modules/booking/dialogs/visit-blocked-by-service-dialog/visit-service-dependency-dialog.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskPipe } from 'ngx-mask';
import { FormatPipeModule } from 'ngx-date-fns';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { FormModule } from '@modules/shared/form/form.module';
import { CalendarModule } from '@modules/shared/calendar/calendar.module';
import { CardModule } from '@modules/shared/cards/card.module';
import { HierarchicalPickerComponent } from '@modules/booking/components/hierarchical-picker/hierarchical-picker.component';
import { SelectPatientPageComponent } from '@modules/booking/pages/select-patient-page/select-patient-page.component';
import { SelectedServicesCardComponent } from '@modules/booking/components/selected-services-card/selected-services-card.component';
import { RecommendationsListComponent } from '@modules/booking/components/recommendations-list/recommendations-list.component';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';
import { createBookingResolver, updateBookingResolver } from '@modules/booking/resolvers/booking.resolver';
import { BookingFlow } from '@enums/booking-flow.enum';
import { InstitutionHeaderComponent } from '@modules/booking/components/institution-header/institution-header.component';
import { canDeactivateGuard } from '@guards/can-deactivate.guard';
import { BookingSuccessPageComponent } from '@modules/booking/pages/booking-success-page/booking-success-page.component';

const routes: Routes = [
  {
    path: BOOKING_NAV.createBooking.path,
    component: BookVisitPageComponent,
    resolve: { booking: createBookingResolver },
    data: { flow: BookingFlow.PUBLIC },
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.updateBooking.path,
    component: BookVisitPageComponent,
    resolve: { booking: updateBookingResolver },
    data: { flow: BookingFlow.PUBLIC },
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.createBookingViaInstitutionUniqueLink.path,
    component: BookVisitPageComponent,
    resolve: { booking: createBookingResolver },
    data: { flow: BookingFlow.UNIQUE_LINK },
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.updateBookingViaInstitutionUniqueLink.path,
    component: BookVisitPageComponent,
    resolve: { booking: updateBookingResolver },
    data: { flow: BookingFlow.UNIQUE_LINK },
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.bookingContactForHelp.path,
    component: ContactForVisitPageComponent,
  },
  {
    path: BOOKING_NAV.bookingSelectPatient.path,
    resolve: { booking: updateBookingResolver },
    component: SelectPatientPageComponent,
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.bookingConfirmation.path,
    resolve: { booking: updateBookingResolver },
    component: BookingConfirmationPageComponent,
    canActivate: [authenticationGuard()],
    canDeactivate: [canDeactivateGuard],
  },
  {
    path: BOOKING_NAV.bookingSuccess.path,
    resolve: { booking: updateBookingResolver },
    component: BookingSuccessPageComponent,
    canActivate: [authenticationGuard()],
  },
  {
    path: BOOKING_NAV.visitViewTasks.path,
    component: ViewVisitTasksPageComponent,
    canActivate: [authenticationGuard()],
  },
  {
    path: BOOKING_NAV.visitManageDocumentTasks.path,
    component: UploadDocumentPageComponent,
    canActivate: [authenticationGuard()],
  },
];

@NgModule({
  declarations: [
    BookVisitPageComponent,
    ViewVisitTasksPageComponent,
    ContactForVisitPageComponent,
    UploadDocumentPageComponent,
    BookingConfirmationPageComponent,
    SelectPatientPageComponent,
    StepUploadReferralLetterComponent,
    StepSelectLocationComponent,
    StepSelectSlotComponent,
    ContinueMobileBannerComponent,
    VisitServiceDependencyDialogComponent,
    HierarchicalPickerComponent,
    SelectedServicesCardComponent,
    RecommendationsListComponent,
    InstitutionHeaderComponent,
    BookingSuccessPageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    BookingSharedModule,
    AccountSharedModule,
    FormModule,
    CalendarModule,
    CardModule,
    GoogleMapsModule,
    MatDialogModule,
    MatTooltipModule,
    NgxMaskPipe,
    FormatPipeModule,
    AccountSharedModule,
  ],
})
export class BookingModule { }
