import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { generateTimeslots } from '@utils/helpers/date.util';
import { Timeslot } from '@modules/shared/core/components/timeslot-picker/timeslot.model';

@Component({
  selector: 'vh-timeslot-picker',
  templateUrl: './timeslot-picker.component.html',
  styleUrls: ['./timeslot-picker.component.scss'],
})
export class TimeslotPickerComponent implements OnInit {
  @Input() startHour: number;
  @Input() endHour: number;
  @Input() minuteInterval: number = 60;
  @Input() selectedSlot: Timeslot;
  @Input() autoGenerate: boolean = true;

  @Output() timeslotPick: EventEmitter<Timeslot> = new EventEmitter<Timeslot>();

  slots: Timeslot[] = [];

  ngOnInit(): void {
    this.autoGenerateSlots();
  }

  setSelectedSlot(slot?: Timeslot): void {
    if (this.selectedSlot?.isEqual(slot)) {
      this.selectedSlot = null;

      return;
    }

    this.selectedSlot = slot;
  }

  onTimeSlotClicked(slot?: Timeslot): void {
    this.setSelectedSlot(slot);
    this.timeslotPick.emit(this.selectedSlot);
  }

  isSameSlot(slot: Timeslot): boolean {
    return slot?.from.getUTCHours() === this.selectedSlot?.from.getUTCHours();
  }

  private autoGenerateSlots(): void {
    if (!this.autoGenerate) {
      return;
    }

    if (!this.startHour || !this.endHour) {
      throw new Error(`${TimeslotPickerComponent.name} requires a start and end hour if autogenerate is set to true.`);
    }

    if (this.startHour > this.endHour || this.startHour === this.endHour) {
      throw new Error(`${TimeslotPickerComponent.name} start hour cannot be greater or equal to end hour.`);
    }

    if (this.startHour < 0 || this.startHour > 23) {
      throw new Error(`${TimeslotPickerComponent.name} start hour must be between 0 and 23 hours`);
    }

    if (this.endHour < 1 || this.endHour > 24) {
      throw new Error(`${TimeslotPickerComponent.name} end hour must be between 1 and 24 hours`);
    }

    this.slots = generateTimeslots(this.startHour, this.endHour, this.minuteInterval);
  }
}
