<h1 [vhMarginTop]="40">{{ 'pages.createAppointment.step3.title' | translate }}</h1>
<p [vhMarginTop]="8">{{ 'pages.createAppointment.step3.text' | translate }}</p>
<div *ngIf="googleMapsIsInitialized$ | async">
  <div class="flex-row-l-c gap-h-8" [vhMarginTop]="24">
    <vh-address-suggestions-input
      [allowSuggestionsOnly]="true"
      [suggestionOnClick]="true"
      [displayContainer]="map"
      (googleSuggestionsErrorOccurred)="onGoogleSuggestionsErrorOccurred($event)"
      (suggestionClicked)="onAddressSuggestionClicked($event)">
    </vh-address-suggestions-input>
    <vh-button
      [label]="
        (!breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium] ? 'pages.createAppointment.step3.myLocationButtonLabel' : null)
          | translate
      "
      [style]="ButtonStyle.QUATERNARY"
      [iconLeftPath]="isLoadingUserLocation ? 'assets/icons/hourglass.svg' : 'assets/icons/crosshairs.svg'"
      [iconLeftHeight]="20"
      [isDisabled]="isLoadingUserLocation"
      (buttonClick)="showUserLocation(true)"></vh-button>
  </div>
  <vh-message
    *ngIf="getPlaceSuggestionOptionsErrorTranslationKey"
    [vhMarginTop]="4"
    [vhTextColor]="'var(--color-alert)'"
    [text]="getPlaceSuggestionOptionsErrorTranslationKey | translate"></vh-message>
  <vh-message
    *ngIf="geolocationErrorTranslationKey"
    [vhMarginTop]="8"
    [vhTextColor]="'var(--color-alert)'"
    [text]="geolocationErrorTranslationKey | translate"></vh-message>
  <div class="map" [vhMarginTop]="24">
    <google-map
      #map
      [width]="'100%'"
      [height]="'100%'"
      [center]="mapCenter"
      [zoom]="mapZoom"
      (tilesloaded)="onMapTilesLoaded()"></google-map>
  </div>
</div>
