import { Component, Input, TemplateRef } from '@angular/core';
import { DateCardLayout } from '@modules/shared/cards/enums/date-card-layout.enum';
import { CardGroupNoResultsMessage } from '@interfaces/card-group-no-results-message.interface';
import { BaseVisitProperties } from '@modules/shared/cards/interfaces/base-visit-properties.interface';
import { VisitCardLayout } from '@modules/shared/cards/enums/visit-card-layout.enum';

@Component({
  selector: 'vh-visit-card-group',
  templateUrl: './visit-card-group.component.html',
  styleUrls: ['./visit-card-group.component.scss'],
})
export class VisitCardGroupComponent {
  @Input() noResultsMessage: CardGroupNoResultsMessage;
  @Input() disablePast: boolean;
  @Input() visits: BaseVisitProperties[] = [];
  @Input() visitCardLayout: VisitCardLayout;
  @Input() dateCardLayout: DateCardLayout;
  @Input() dateCardBackgroundColor: string;
  @Input() actionsTemplate: TemplateRef<BaseVisitProperties>;
  @Input() isLoading: boolean = false;
  @Input() onCardClickFunction: (visit: BaseVisitProperties) => void;
  @Input() getDateCardBackgroundColorFunction: (date: Date) => string;
  @Input() isHighlightedFunction: (visit: BaseVisitProperties) => boolean;
}
