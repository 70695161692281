import { Injectable } from '@angular/core';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { VirtualSlot } from '@models/virtual-slot.model';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Visit } from '@models/visit.model';
import { AbstractTrackingService } from '@services/tracking/abstract-tracking.service';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';

@Injectable({
  providedIn: 'root',
})
export class SlotTrackingEventService extends AbstractTrackingService<VirtualSlot | Visit> {
  protected map(trackingEventType: TrackingEventType, slot: VirtualSlot | Visit): Record<string, string> {
    return {
      type: trackingEventType,
      slot_start: getTimeStringWithTimeZone(slot.start),
      slot_end: getTimeStringWithTimeZone(slot.end),
      service_id: slot.service?.id,
      room_id: slot instanceof VirtualSlot || slot instanceof Visit ? slot.room?.id : null,
    };
  }

  protected get endpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.trackSlotEvent.route;
  }

  protected get bulkEndpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.bulkTrackSlotEvents.route;
  }

  protected getUniqueIdentifier(slot: VirtualSlot | Visit): string {
    return `${format(slot.start, DATE_FORMATS.serverDateTime)}_${format(slot.end, DATE_FORMATS.serverDateTime)}_${slot.service?.id}`;
  }
}
