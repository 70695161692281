<vh-dialog-frame [title]="visit?.title" (closeClicked)="close()">
  <mat-dialog-content class="visit-properties-grid">
    <!-- Visit title -->
    <div
      class="flex-col card"
      vhSkeleton
      [skeletonIsVisible]="isLoading"
      [skeletonIsEmpty]="!isLoading"
      skeletonHeight="200px"
      skeletonWidth="30vw">
      <ng-container *ngIf="!isLoading">
        <div class="flex-row-l-c" *ngIf="!isLoading">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/user-square.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleVisitData' | translate"
            iconLeftWidth="20"></vh-message>
          <vh-icon
            *ngIf="visitInfoViewMode === 'read' && !isReadOnly"
            class="clickable align-end"
            src="assets/icons/pen.svg"
            [fill]="COLORS.tertiary"
            [hoverFill]="COLORS.secondary"
            (click)="onEditVisitInfoClicked()"></vh-icon>
          <div *ngIf="visitInfoViewMode === 'edit'" class="flex-row-l-c gap-h-8 align-end">
            <vh-icon
              [ngClass]="{ clickable: !isUpdatingVisitInfo, 'not-clickable': isUpdatingVisitInfo }"
              src="assets/icons/save.svg"
              [width]="20"
              [height]="20"
              [fill]="COLORS.tertiary"
              [hoverFill]="COLORS.primary"
              (click)="onVisitInfoSaveClicked()"></vh-icon>
            <vh-icon
              [ngClass]="{ clickable: !isUpdatingVisitInfo, 'not-clickable': isUpdatingVisitInfo }"
              src="assets/icons/cancel.svg"
              [width]="16"
              [height]="16"
              [fill]="COLORS.tertiary"
              [hoverFill]="COLORS.error"
              (click)="onCancelEditVisitInfoClicked()"></vh-icon>
          </div>
        </div>
        <div [vhMarginTop]="8" class="visit-properties-list" [formGroup]="visitInfoFormGroup">
          <p>{{ 'admin.pages.dashboard.visitDialog.customTitle' | translate }}</p>
          <p *ngIf="visitInfoViewMode === 'read'">{{ visit.customTitle }}</p>
          <vh-text-input
            *ngIf="visitInfoViewMode === 'edit'"
            [formControlName]="VISIT_FORM_KEYS.get('customTitle')"
            [placeholder]="'admin.pages.dashboard.visitDialog.customTitle' | translate"
            [style]="TextInputStyle.MATERIAL">
          </vh-text-input>
        </div>
      </ng-container>
    </div>
    
    <!-- Visit data -->
    <div class="flex-col card" vhSkeleton [skeletonIsVisible]="isLoading" [skeletonIsEmpty]="!isLoading" skeletonHeight="200px">
      <ng-container *ngIf="!isLoading">
        <!-- Time and location -->
        <div class="flex-row-l-c">
          <vh-message
            class="not-clickable font-medium-16-19"
            iconLeftPath="assets/icons/calendar-alt.svg"
            [text]="'admin.pages.dashboard.visitDialog.titleTimeLocation' | translate"
            [iconLeftFill]="COLORS.secondary"
            iconLeftWidth="16"
            iconLeftHeight="16"></vh-message>
          <vh-icon
            *ngIf="visitTimeViewMode === 'read' && !isReadOnly"
            class="clickable align-end"
            src="assets/icons/pen.svg"
            [fill]="COLORS.tertiary"
            [hoverFill]="COLORS.secondary"
            (click)="onEditVisitClicked()"></vh-icon>
          <vh-icon
            *ngIf="visitTimeViewMode === 'edit' && !isReadOnly"
            class="clickable align-end"
            src="assets/icons/times-circle.svg"
            [fill]="COLORS.tertiary"
            [hoverFill]="COLORS.error"
            (click)="onCancelEditVisitClicked()"></vh-icon>
        </div>
        <div [vhMarginTop]="8" class="visit-properties-list" [formGroup]="visitTimeFormGroup">
          <p>{{ 'admin.pages.dashboard.visitDialog.labelLocation' | translate }}</p>
          <div>
            <p>{{ visit.institution?.parent ? visit.institution?.parent?.name : visit.institution?.name }}</p>
            <p
              *ngIf="visit.institution?.parent && visit.institution?.parent?.name.toLowerCase() !== visit.institution?.name.toLowerCase()"
              class="detail-text">
              {{ visit.institution.name }}
            </p>
          </div>
          <ng-container *vhRequiresRoles="getInstitutionRoles()">
            <p>{{ 'admin.pages.dashboard.visitDialog.labelRoom' | translate }}</p>
            <p>{{ visit.room?.name }}</p>
          </ng-container>
          <p>{{ 'admin.pages.dashboard.visitDialog.labelDate' | translate }}</p>
          <p *ngIf="visitTimeViewMode === 'read'">{{ visit.start | dfnsFormat : 'dd/MM/yyyy' }}</p>
          <vh-text-input
            *ngIf="visitTimeViewMode === 'edit'"
            type="date"
            [formControlName]="VISIT_FORM_KEYS.get('startDate')"
            [style]="TextInputStyle.MATERIAL"
            (inputValueChange)="onVisitDateTimeChanged()">
          </vh-text-input>
          <p>{{ 'admin.pages.dashboard.visitDialog.labelStartTime' | translate }}</p>
          <p *ngIf="visitTimeViewMode === 'read'">{{ visit.start | dfnsFormat : 'HH:mm' }}</p>
          <vh-text-input
            *ngIf="visitTimeViewMode === 'edit'"
            type="time"
            [formControlName]="VISIT_FORM_KEYS.get('startTime')"
            [style]="TextInputStyle.MATERIAL"
            (inputValueChange)="onVisitDateTimeChanged('start')">
          </vh-text-input>
          <p>{{ 'admin.pages.dashboard.visitDialog.labelEndTime' | translate }}</p>
          <p *ngIf="visitTimeViewMode === 'read'">{{ visit.end | dfnsFormat : 'HH:mm' }}</p>
          <vh-text-input
            *ngIf="visitTimeViewMode === 'edit'"
            type="time"
            [formControlName]="VISIT_FORM_KEYS.get('endTime')"
            [style]="TextInputStyle.MATERIAL"
            (inputValueChange)="onVisitDateTimeChanged('end')"
            vhPopoverHost="reschedule"
            [popoverTemplate]="reschedulePopover"
            popoverEnforcedPosition="bottom"></vh-text-input>
            <p>{{ 'admin.pages.dashboard.visitDialog.cancelOverlappingVisits.cancelOverlappingVisits' | translate }}</p>
            <div class="flex-row-l-c gap-h-8">
              <vh-icon
                [matTooltip]="
                  (visit.cancelOverlappingVisits
                    ? 'admin.pages.dashboard.visitDialog.cancelOverlappingVisits.yes'
                    : 'admin.pages.dashboard.visitDialog.cancelOverlappingVisits.no'
                  ) | translate
                "
                [src]="visit.cancelOverlappingVisits ? 'assets/icons/check-circle.svg' : 'assets/icons/times-circle.svg'"
                [fill]="visit.cancelOverlappingVisits ? COLORS.primary : COLORS.error"
                [width]="14"
                [height]="14">
              </vh-icon>
            </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <!-- Cancel button -->
  <mat-dialog-actions *ngIf="!isLoading" [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button 
      *ngIf="!isLoading"
      matTooltipPosition="above"
      iconLeftPath="assets/icons/calendar-slash.svg"
      [label]="'admin.pages.dashboard.visitDialog.actionCancel' | translate"
      [isDisabled]="cancellationButtonTooltip !== null"
      [style]="ButtonStyle.DANGER"
      [matTooltip]="(cancellationButtonTooltip !== null ? cancellationButtonTooltip : '')"
      (buttonClick)="onCancelVisitClicked()">
    </vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>
<ng-template #reschedulePopover>
  <vh-other-visit-rescheduling-impact-result
    class="col-span-full"
    *ngIf="rescheduleVisitDuration"
    [visit]="visit"
    [newStartDateTime]="rescheduleVisitDuration?.start"
    [newEndDateTime]="rescheduleVisitDuration?.end"
    (cancelClicked)="onCancelEditVisitClicked()"
    (visitRescheduleSuccess)="onVisitDateTimeSaved($event)">
  </vh-other-visit-rescheduling-impact-result>
</ng-template>