import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NAVIGATION } from '@constants/navigation.constant';
import { RESET_PASSWORD_FORM_KEYS } from '@constants/form-keys/reset-password-form-keys.constant';
import { IObject } from '@app-types/iobject.type';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage } from '@utils/helpers/form.util';
import { HttpErrorResponse } from '@angular/common/http';
import { PasswordService } from '@services/password.service';
import { mustMatch } from '@utils/validators/must-match.validator';
import { passwordValidator } from '@utils/validators/password.validator';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
@UntilDestroy()
export class ResetPasswordPageComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly RESET_PASSWORD_FORM_KEYS: typeof RESET_PASSWORD_FORM_KEYS = RESET_PASSWORD_FORM_KEYS;

  private readonly token: string;
  isEnforced: boolean;

  formGroup: UntypedFormGroup;

  newPassword: string;
  repeatedPassword: string;
  submitNewPasswordButtonIsDisabled: boolean;
  submitNewPasswordErrorMessageTranslationKey: string;

  isSubmitted: boolean;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly passwordService: PasswordService
  ) {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.isEnforced = this.activatedRoute.snapshot.queryParamMap.get('is_enforced') === 'true';
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        [RESET_PASSWORD_FORM_KEYS.get('newPassword')]: [
          null,
          [
            Validators.required,
            passwordValidator(),
          ],
        ],
        [RESET_PASSWORD_FORM_KEYS.get('repeatedPassword')]: [
          null,
          [Validators.required, mustMatch(RESET_PASSWORD_FORM_KEYS.get('newPassword'), RESET_PASSWORD_FORM_KEYS.get('repeatedPassword'))],
        ],
      }
    );

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((values: IObject): void => {
      this.newPassword = values[RESET_PASSWORD_FORM_KEYS.get('newPassword')] as string;
      this.repeatedPassword = values[RESET_PASSWORD_FORM_KEYS.get('repeatedPassword')] as string;
    });
  }

  submitNewPassword = (): void => {
    this.submitNewPasswordErrorMessageTranslationKey = extractErrorMessageFromFormValidation(
      RESET_PASSWORD_FORM_KEYS,
      this.formGroup,
      this.getSubmitNewPasswordErrorMessage
    );

    if (this.submitNewPasswordErrorMessageTranslationKey) {
      return;
    }

    if (this.newPassword !== this.repeatedPassword) {
      this.submitNewPasswordErrorMessageTranslationKey = 'pages.resetPassword.passwordsDoNotMatchError';

      return;
    }

    this.submitNewPasswordButtonIsDisabled = true;
    this.submitNewPasswordErrorMessageTranslationKey = null;

    this.passwordService.resetPassword$(this.activatedRoute.snapshot.queryParams.username, this.newPassword, this.repeatedPassword, this.token)
      .subscribe({
        next: (): void => {
          this.isSubmitted = true;
        },
        error: (response: HttpErrorResponse): void => {
          this.submitNewPasswordButtonIsDisabled = false;
          this.submitNewPasswordErrorMessageTranslationKey = response?.error?.message ?? 'common.unknownError';
        },
      });
  };

  private getSubmitNewPasswordErrorMessage = (errorIdentifier: string, formKey: string): string => {
    if (errorIdentifier === 'minlength') {
      return 'pages.resetPassword.passwordTooShortError';
    }

    return getUnknownErrorMessage(errorIdentifier, formKey);
  };

  protected readonly COLORS = COLORS;
}
