export abstract class AbstractCacheService {
  abstract get<T>(key: string): T | null;

  abstract set<T>(key: string, value: T, expiry: number): void;

  abstract remove(key: string): void;

  abstract clear(): void;

  abstract has(key: string): boolean;

  protected isInternalStorageItem(value: any): boolean {
    return Object.hasOwnProperty.call(value, 'value') &&
      Object.hasOwnProperty.call(value, 'expiry') &&
      Object.hasOwnProperty.call(value, 'storedAt');
  }

  protected hasExpired(item: InternalStorageItem): boolean {
    return item.expiry > 0 && item.storedAt + item.expiry < Date.now();
  }
}

export interface InternalStorageItem {
  value: any;
  expiry: number;
  storedAt: number;
}
