import { Component } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'vh-wallet-history-page',
  templateUrl: './wallet-history-page.component.html',
  styleUrls: ['./wallet-history-page.component.scss'],
})
export class WalletHistoryPageComponent {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  currentUser: User;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUser;
  }
}
