export const generateRandomString = (): string => {
  return (Math.random() + 1).toString(36).substring(7);
};

/**
 * Truncate a string in the middle
 * e.g. "A very long string" -> "A very...ring"
 */
export const truncateMiddle = (subject: string, maxLength: number, backCharsLength: number, separator: string = '...'): string => {
  if (subject?.length <= maxLength) {
    return subject;
  }

  const separatorLength = separator.length;
  const charsToShow = maxLength - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = backCharsLength && backCharsLength > subject.length ? backCharsLength : Math.floor(charsToShow / 2);

  return subject.substring(0, frontChars) +
    separator +
    subject.substring(subject.length - backChars, subject.length);
};

/**
 * Helper function to convert camelCase to snake_case
 */
export const camelToSnake = (key: string): string => {
  return key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};
