import { parseISO } from 'date-fns';

export class Module {
  constructor(private _id: string, private _key: string, private _name: string, private _description: string, private _accessUntil: Date) {}

  get id(): string {
    return this._id;
  }

  get key(): string {
    return this._key;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get accessUntil(): Date {
    return this._accessUntil;
  }

  static fromJson(json: ModuleJson): Module {
    return new Module(
      json.id,
      json.key,
      json.name,
      json.description,
      json.access_until ? parseISO(json.access_until) : null
    );
  }

  toJson(): ModuleJson {
    return {
      id: this.id,
      key: this.key,
      name: this.name,
      description: this.description,
      access_until: this.accessUntil?.toISOString(),
    };
  }
}

export interface ModuleJson {
  id: string;
  key: string;
  name: string;
  description: string;
  access_until?: string;
}
