import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { InstitutionSettings } from '@models/institution-settings.model';
import { INSTITUTION_SETTINGS_FORM_KEYS } from '@constants/form-keys/institution-settings-form-keys.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class InstitutionSettingsFormService extends AbstractFormService {
  createGeneralSettingsFormGroup(settings: InstitutionSettings): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('default_language')]: [
        settings.defaultLanguage,
        [],
      ],
    });
  }

  createConversationSettingsFormGroup(settings: InstitutionSettings): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('preferred_communication_method')]: [
        settings.preferredCommunicationMethod,
        [],
      ],
    });
  }

  createCommunicationSettingsFormGroup(settings: InstitutionSettings, language: string | null = null): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('support_email')]: [
        settings.supportEmail,
        [Validators.email],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('support_email_as_reply_to')]: [
        settings.useSupportEmailAsReplyTo,
        [],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('support_phone')]: [
        settings.supportPhone,
        null,
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('visit_guidelines')]: [
        settings.visitGuidelines.get(language ?? settings.defaultLanguage),
        [Validators.maxLength(65535)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('hide_service_details_in_visit_mails')]: [
        settings.hideServiceDetailsInVisitMails,
        null,
      ],
    });
  }

  createReservationSettingsFormGroup(settings: InstitutionSettings): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('patient_can_cancel_visit')]: [
        settings.patientCanCancelVisit,
        [],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('patient_can_cancel_visit_hours_at_forehand')]: [
        settings.patientCanCancelVisitHoursAtForehand,
        [Validators.min(1)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('referring_physician_can_cancel_visit')]: [
        settings.referringPhysicianCanCancelVisit,
        [],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('referring_physician_can_cancel_visit_hours_at_forehand')]: [
        settings.referringPhysicianCanCancelVisitHoursAtForehand,
        [Validators.min(1)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('allow_double_booking_admin')]: [
        settings.allowDoubleBookingAdmin,
        null,
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_simultaneous_bookings')]: [
        settings.bookingRestrictionMaxSimultaneousBookings ?? 1,
        null,
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_week_starts_on')]: [
        settings.bookingRestrictionWeekStartsOn,
        [Validators.min(0)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_days_before_visit')]: [
        settings.bookingRestrictionMaxDaysBeforeVisit,
        [Validators.min(1)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_weeks_before_visit')]: [
        settings.bookingRestrictionMaxWeeksBeforeVisit,
        [Validators.min(1)],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')]: [
        'none',
        [Validators.min(1)],
      ],
    });

    if (settings.bookingRestrictionMaxDaysBeforeVisit) {
      formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')).setValue('days');
    } else if (settings.bookingRestrictionMaxWeeksBeforeVisit) {
      formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')).setValue('weeks');
    }

    formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction'))
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        if (value === 'days') {
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_days_before_visit')).setValue(1);
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_weeks_before_visit')).setValue(null);
        } else if (value === 'weeks') {
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_days_before_visit')).setValue(null);
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_weeks_before_visit')).setValue(1);
        } else {
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_days_before_visit')).setValue(null);
          formGroup.get(INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_weeks_before_visit')).setValue(null);
        }
      });

    return formGroup;
  }

  createSuperAdminSettingsFormGroup(settings: InstitutionSettings): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('event_bus_enabled')]: [
        settings.isEventBusEnabled,
        [],
      ],
    });
  }

  createSecuritySettingsFormGroup(settings: InstitutionSettings): UntypedFormGroup {
    return this.formBuilder.group({
      [INSTITUTION_SETTINGS_FORM_KEYS.get('sec_2fa')]: [
        settings.sec2Fa,
        [],
      ],
      [INSTITUTION_SETTINGS_FORM_KEYS.get('sec_min_password_length')]: [
        settings.secMinPasswordLength,
        [Validators.min(environment.app.minPasswordLength)],
      ],
    });
  }
}
