import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { CoreModule } from '@modules/shared/core/core.module';
import { ContactPageComponent } from '@modules/public/contact/pages/main/contact-page.component';
import { FormModule } from '@modules/shared/form/form.module';

const routes: Routes = [
  {
    path: NAVIGATION.contact.path,
    component: ContactPageComponent,
  },
];

@NgModule({
  declarations: [ContactPageComponent],
  imports: [
    // Angular modules
    RouterModule.forChild(routes),
    CoreModule,
    FormModule,
  ],
})
export class ContactModule {}
