import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User, UserJson } from '@models/user.model';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { IObject } from '@app-types/iobject.type';
import { AuthenticationService } from '@services/authentication.service';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { stripFalsyPropertiesFromObject } from '@utils/helpers/form.util';
import { Doctor, DoctorJson } from '@models/doctor.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  findUser$ = (filter: { socialSecurityNumber?: string; email?: string; }): Observable<string | null> => {
    const params = { params: {} };

    if (filter.socialSecurityNumber) {
      params.params[ENDPOINTS.findUser.queryParams.socialSecurityNumber] = filter.socialSecurityNumber;
    }

    if (filter.email) {
      params.params[ENDPOINTS.findUser.queryParams.email] = filter.email;
    }

    return this.http
      .get(environment.apiBaseUrl + ENDPOINTS.findUser.route, params)
      .pipe(map((json: { id: string | null; }): string | null => json.id));
  };

  updateUser$ = (id: string, user: IObject): Observable<User> => {
    const endpoint: string = ENDPOINTS.updateUser.route.replace(`{${ENDPOINTS.updateUser.pathParams.userId}}`, id);

    const userDTO: IObject = stripFalsyPropertiesFromObject(user);

    // Institution ID is required in the payload when updating a user
    if (user.roles) {
      userDTO.institution_id = this.authenticationService.institution?.id ?? null;
      userDTO.roles = user.roles;
    }

    if (userDTO?.birthdate instanceof Date) {
      userDTO.birthdate = format(userDTO.birthdate, DATE_FORMATS.serverDate);
    }

    return this.http
      .put(environment.apiBaseUrl + endpoint, userDTO)
      .pipe(map((json: UserJson): User => User.fromJson(json)))
      .pipe(
        tap((updatedUser: User) => {
          if (updatedUser.id === this.authenticationService.currentUser?.id) {
            this.authenticationService.currentUser = updatedUser;
            this.authenticationService.persistLoggedInUser();
          }
        })
      );
  };

  deleteUser$ = (user: User): Observable<void> => {
    const endpoint: string = ENDPOINTS.deleteUser.route.replace(`{${ENDPOINTS.updateUser.pathParams.userId}}`, user.id);

    return this.http.delete(environment.apiBaseUrl + endpoint).pipe(switchToEmptyObservable());
  };

  createUser$ = (user: IObject): Observable<User> => {
    user = stripFalsyPropertiesFromObject(user);

    user.is_admin = true;

    const userDTO: IObject = {
      ...user,
      institution_id: this.authenticationService.institution.id,
    };

    if (user.roles) {
      userDTO.roles = user.roles;
    }

    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.createUser.route, userDTO)
      .pipe(map((json: UserJson): User => User.fromJson(json)));
  };

  sendVerificationEmail$(): Observable<void> {
    return this.http.get(environment.apiBaseUrl + ENDPOINTS.resendVerificationEmail.route).pipe(switchToEmptyObservable());
  }

  getDoctorIfLinked$(user: User): Observable<Doctor> {
    return this.http
      .get(
        environment.apiBaseUrl + ENDPOINTS.getDoctorIfLinked.route.replace(`{${ENDPOINTS.getDoctorIfLinked.pathParams.userId}}`, user.id)
      )
      .pipe(map((json: DoctorJson): Doctor => Doctor.fromJson(json)));
  }
}
