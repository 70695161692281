import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { ContactService } from '@services/contact.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { ContactReason, fromString } from '@enums/contact-reason.enum';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  COLORS: typeof COLORS = COLORS;

  formGroup: FormGroup;
  questionOptions: Option[];
  isSubmitting: boolean;
  finishedSubmittingSuccessfully: boolean;
  errorTranslationKey: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly location: Location,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translation: TranslateService,
    private readonly contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    combineLatest([
      this.translation.get('pages.contact.options.flyers'),
      this.translation.get('pages.contact.options.demo'),
      this.translation.get('pages.contact.options.question'),
    ]).subscribe(this.onOptionTranslationsLoaded);
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      last_name: [
        null,
        Validators.required,
      ],
      first_name: [
        null,
        Validators.required,
      ],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
        ],
      ],
      institution: [null],
      type: [
        null,
        Validators.required,
      ],
      message: [
        null,
        Validators.required,
      ],
    });
  }

  submit = (): void => {
    this.isSubmitting = true;

    // TODO: work-around for the value of the "selectbox"
    const body = this.formGroup.value;
    body.type = this.formGroup.value.type?.value ?? ContactReason.QUESTION;

    this.contactService.submit(body).subscribe({
      next: (): void => {
        this.errorTranslationKey = null;
        this.isSubmitting = false;
        this.finishedSubmittingSuccessfully = true;
      },
      error: (): void => {
        this.errorTranslationKey = 'common.unknownError';
      },
    });
  };

  goBack = (): void => {
    this.location.back();
  };

  onOptionTranslationsLoaded = ([flyers, demo, question]: [string, string, string]): void => {
    this.questionOptions = [
      {
        label: flyers,
        value: 0,
      },
      {
        label: demo,
        value: 1,
      },
      {
        label: question,
        value: 2,
      },
    ];

    this.activatedRoute.queryParams.subscribe(this.loadOptionFromRouteParameter);
  };

  loadOptionFromRouteParameter = (params: Params): void => {
    if (params?.reason === undefined) {
      return;
    }

    const reason = fromString(params.reason);
    const options = this.questionOptions
      .filter((option: Option): boolean => option.value.toString() === reason.toString());

    if (options.length <= 0) {
      return;
    }

    this.formGroup.controls.type.setValue(options[0]);
  };
}
