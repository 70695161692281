import { CreditCardNetwork } from '@enums/credit-card-network.enum';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';

export class CreditCard {
  constructor(
    private readonly _expiryDate: Date,
    private readonly _network: CreditCardNetwork = null,
    private readonly _number: string = null,
    private readonly _holder: string = null
  ) {}

  get network(): CreditCardNetwork | null {
    return this._network;
  }

  get expiryDate(): Date {
    return this._expiryDate;
  }

  get number(): string | null {
    return this._number;
  }

  get holder(): string | null {
    return this._holder;
  }

  get displayName(): string {
    let displayName = '';

    displayName += this._holder ? this._holder : '';
    displayName += this._number ? ` (${this._number})` : 'XXXX XXXX XXXX XXXX';
    displayName += this._network ? ` - ${this._network}` : '';
    displayName += this._expiryDate ? ` - ${format(this.expiryDate, DATE_FORMATS.clientMonthYear)}` : '';

    return displayName;
  }

  get networkIconSrc(): string | null {
    switch (this._network) {
      case CreditCardNetwork.VISA:
        return 'assets/images/payment/visa.svg';
      case CreditCardNetwork.MASTERCARD:
        return 'assets/images/payment/mastercard.svg';
      default:
        return null;
    }
  }

  static fromJson(json: CreditCardJson): CreditCard {
    return new CreditCard(new Date(json.expiry_date), CreditCardNetwork[json.network], json.number, json.holder);
  }
}

export interface CreditCardJson {
  network: string | null;
  expiry_date: string;
  number: string | null;
  holder: string | null;
}
