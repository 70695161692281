<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
    <div class="gap-v-16 container flex-col">
        <div class="flex-row gap-h-16 search-controls">
            <vh-multi-select-input
              [selectedOptions]="selectedFilterOptions"
              [options]="filterOptions"
              [placeholder]="filterPlaceholder"
              (selectedOptionsChange)="onSelectedFilterOptionsChanged($event)" />
            <vh-search-input
              [placeholder]="searchPlaceholder"
              [value]="searchValue"
              (inputValueChange)="onSearchQueryEntered($event)" />
            <vh-button
              class="add-card-button"
              iconLeftPath="assets/icons/plus.svg"
              [iconLeftFill]="COLORS.white"
              [label]="addLabel"
              (buttonClick)="onAddCardClicked()" />
        </div>
        <ng-container *ngIf="!isLoading">
            <ng-container *ngFor="let card of displayedCards" [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{ card: card }"></ng-container>
            <ng-container *ngIf="isLoading">
                <ng-container *ngFor="let card of [].constructor(3)" [ngTemplateOutlet]="cardSkeletonTemplate"></ng-container>
            </ng-container>
        </ng-container>
        <vh-message
          *ngIf="!isLoading && hasFilterActive() && displayedCards.length === 0"
          class="not-clickable font-medium-16-19 has-text-tertiary align-center"
          [textAlignment]="'center'"
          [text]="'common.noResults' | translate" />
        <vh-message
          *ngIf="!isLoading && !hasFilterActive() && displayedCards.length === 0"
          class="not-clickable font-medium-16-19 has-text-tertiary align-center"
          [textAlignment]="'center'"
          [text]="'common.components.noCardsYet' | translate :{ cardType: cardType.value}" />
    </div>
</vh-content-layout>

<ng-template #cardTemplate let-card="card">
  <vh-settings-card
      [card]="card"
      (cardEditClicked)="onEditCardClicked($event)"
      (cardRemoveClicked)="onRemoveCardClicked($event)"
      (cardClicked)="onCardClicked($event)"
      (cardDuplicateClicked)="onDuplicateCardClicked($event)">
    <p class="description font-regular-16-19" [ngClass]="{ 'is-disabled': card.isReadOnly }">{{ card.description }}</p>
    <div *ngIf="isCareProgram()" class="pills-group" [vhMarginTop]="8">
        <vh-pill *ngFor="let doctor of card.doctors" [label]="doctor.fullName"></vh-pill>
    </div>
  </vh-settings-card>
</ng-template>

<ng-template #cardSkeletonTemplate>
    <div class="card card-skeleton flex-col gap-v-16">
        <div class="skeleton-title" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
        <div class="skeleton-subtitle" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
    </div>
</ng-template>
