<div class="flex-row">
  <h1 *ngIf="!isCollapsed">{{ 'admin.pages.dashboard.filterTitle' | translate }}</h1>
  <vh-icon
    class="clickable"
    [ngClass]="{
      'icon-open': !isCollapsed,
      'icon-collapsed': isCollapsed
    }"
    src="assets/icons/bars.svg"
    width="24"
    height="24"
    [fill]="COLORS.secondary"
    [hoverFill]="COLORS.primary"
    (click)="collapseIconClick.emit()">
  </vh-icon>
</div>

<ng-container *ngIf="!isCollapsed">
  <div class="flex-row">
    <h2 [vhMarginTop]="24">{{ 'admin.pages.dashboard.patientsTitle' | translate }}</h2>
    <vh-icon
      *ngIf="filter.hasPatientFilterActive()"
      class="clickable icon-open"
      src="assets/icons/filter-slash.svg"
      width="20"
      height="20"
      [fill]="COLORS.tertiary"
      [hoverFill]="COLORS.primary"
      (click)="onResetPatientsFilterClicked()"></vh-icon>
  </div>

  <form class="flex-col" [formGroup]="formGroup">
    <vh-text-input
      [label]="'admin.pages.dashboard.nameInputLabel' | translate"
      [formControlName]="'name'"
      [value]="filter.patientName"
      (keyup)="onFormControlKeyUp($event)">
    </vh-text-input>
    <vh-text-input
      type="date"
      [label]="'admin.pages.dashboard.birthDateInputLabel' | translate"
      [placeholder]="'admin.pages.slotsOverview.slotsPage.dateFormatPlaceholder' | translate"
      [formControlName]="'birthdate'"
      [value]="filter.patientBirthdate"
      [min]="DATE_BOUNDARIES.min.dateString"
      [max]="currentDate"
      (keyup)="onFormControlKeyUp($event)"
      (inputValueChange)="writeValuesToFilter()"
      (inputValueChangeOnBlur)="writeValuesToFilter()">
    </vh-text-input>
    <vh-text-input
      [label]="'admin.pages.dashboard.socialSecurityNumberInputLabel' | translate"
      [placeholder]="'pages.register.socialSecurityNumberInputPlaceholder' | translate"
      [formControlName]="'social_security_number'"
      [value]="filter.patientSocialSecurityNumber"
      (keyup)="onFormControlKeyUp($event)">
    </vh-text-input>
    <vh-button
      [vhMarginTop]="8"
      [label]="'admin.pages.dashboard.searchButtonLabel' | translate"
      alignment="center"
      [isDisabled]="isSearchButtonDisabled()"
      [iconLeftPath]="isLoading ? 'assets/icons/hourglass.svg' : 'assets/icons/search.svg'"
      [iconLeftFill]="COLORS.white"
      (buttonClick)="searchClick.emit()">
    </vh-button>
  </form>

  <ng-container *vhRequiresRoles="institutionRoles" [ngTemplateOutlet]="roomsFilterTemplate"></ng-container>

  <vh-button
    *ngIf="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]"
    [vhMarginTop]="16"
    [label]="'common.close' | translate"
    alignment="center">
  </vh-button>
</ng-container>

<ng-template #roomsFilterTemplate>
  <h3 [vhMarginTop]="32">{{ 'admin.pages.dashboard.roomsSubTitle' | translate }}</h3>
  <ng-container *ngIf="filter.allRooms.length > 0">
    <vh-checkbox
      *ngFor="let room of filter.allRooms"
      [label]="room.name"
      [value]="room.id"
      [fillColor]="room.color"
      [fillColorUnchecked]="COLORS.white"
      [borderColor]="room.color"
      (valueChanged)="roomFilterClick.emit(room)">
    </vh-checkbox>
  </ng-container>
  <ng-container *ngIf="filter.allRooms.length === 0">
    <p [vhMarginTop]="16" class="has-text-error">{{ 'admin.pages.dashboard.noRoomsText' | translate }}</p>
  </ng-container>
</ng-template>
