import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { User } from '@models/user.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { format } from 'date-fns';
import { passwordValidator } from '@utils/validators/password.validator';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { Doctor } from '@models/doctor.model';
import { CREATE_INSTITUTION_FORM_KEYS } from '@constants/form-keys/create-institution-form-keys.constant';

@Injectable({
  providedIn: 'root',
})
export class NewOnboardingFormService extends AbstractFormService {

  /**
   * Create a basic form group with only the required fields (first name, last name, username and password) for the registration form.
   */
  createFormGroupForOnboardingAboutYourself(doctor: Doctor = null): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('language')]: [
        null,
        null,
      ],
      [DOCTOR_FORM_KEYS.get('specialty_id')]: [
        doctor?.specialty ? doctor.specialty.id : null,
        [Validators.required],
      ],
    });
  }

  createFormGroupForOnboardingAPrivacy(): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('accepted_terms')]: [
        null,
        [Validators.required],
      ],
    });
  }

  createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [CREATE_INSTITUTION_FORM_KEYS.get('name')]: [
        null,
        [Validators.required],
      ],
    });
  }

  createFormGroupForOnboardingAccount(): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('email')]: [
        null,
        [Validators.required, Validators.email],
      ],
      [REGISTER_FORM_KEYS.get('username')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('password')]: [
        null,
        [
          Validators.required,
          passwordValidator(),
        ],
      ],
    });
  }

  enableEmailOrPhoneValidation(formGroup: UntypedFormGroup): void {
    const emailControl = formGroup.get(REGISTER_FORM_KEYS.get('email'));
    const phoneControl = formGroup.get(REGISTER_FORM_KEYS.get('phone'));

    const emailOrPhoneValidator: ValidatorFn = () =>
      !emailControl?.value && !phoneControl?.value ? { emailOrPhoneRequired: true } : null;

    [emailControl, phoneControl].forEach(control => {
      control?.addValidators(emailOrPhoneValidator);
    });
  }

  setUserToForm(formGroup: UntypedFormGroup, existingPatient: User): void {
    formGroup.patchValue({
      [REGISTER_FORM_KEYS.get('firstName')]: existingPatient.firstName,
      [REGISTER_FORM_KEYS.get('lastName')]: existingPatient.lastName,
      [REGISTER_FORM_KEYS.get('birthdate')]: format(existingPatient.birthdate, DATE_FORMATS.serverDate),
      [REGISTER_FORM_KEYS.get('username')]: existingPatient.username,
      [REGISTER_FORM_KEYS.get('email')]: existingPatient.email,
      [REGISTER_FORM_KEYS.get('gender')]: existingPatient.gender,
      [REGISTER_FORM_KEYS.get('phone')]: existingPatient.phone,
      [REGISTER_FORM_KEYS.get('canPhoneBeUsedToContact')]: existingPatient.canPhoneBeUsedToContact,
    });
  }
}
