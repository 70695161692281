import { style, animate, trigger, transition } from '@angular/animations';

export const popOutTransition = transition(
  ':leave',
  [
    style({ scale: '100%' }),
    animate(
      '0.1s ease-out',
      style({ scale: '0%' })
    ),
  ]
);

export const popOutAnimation = trigger(
  'popOutAnimation',
  [popOutTransition]
);
