<ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeletonTemplate ? skeletonTemplate : defaultSkeletonTemplate"></ng-container>
<ng-container *ngIf="!isLoading" [ngTemplateOutlet]="contentTemplate"></ng-container>

<ng-template #contentTemplate>
  <div class="card" [ngClass]="{ 'is-highlighted' : isHighlighted }">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #defaultSkeletonTemplate>
  <vh-generic-card-skeleton></vh-generic-card-skeleton>
</ng-template>
