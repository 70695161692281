import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';

export class TranslatedProperty {
  constructor(private _value: string | Record<string, string> | null, private readonly _defaultLanguage: string = null) {
    this._defaultLanguage = this.getDefaultLanguage();
  }

  all(): Record<string, string> | string | null {
    return this._value;
  }

  get(language: string = null): string | null {
    if (typeof this._value === 'string' || this._value === null) {
      return this._value as string | null;
    }

    language = language ?? this._defaultLanguage;

    if (!Object.keys(this._value).includes(language)) {
      return null;
    }

    return this._value[language];
  }

  toString(): string {
    return this.get();
  }

  private getDefaultLanguage(): string | null {
    let institutionPreferredLanguage: string = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedInstitution);

    if (institutionPreferredLanguage) {
      institutionPreferredLanguage = JSON.parse(institutionPreferredLanguage)?.settings?.defaultLanguage;
    }

    // If the language is a locale, but the value only contains the language as key, we take the base language instead
    if (institutionPreferredLanguage?.length === 5 && typeof this._value !== 'string' && this._value && !Object.keys(this._value).includes(institutionPreferredLanguage)) {
      institutionPreferredLanguage = institutionPreferredLanguage.substring(0, 2);
    }

    return institutionPreferredLanguage ?? localStorage.getItem(LOCAL_STORAGE_KEYS.preferredLanguage) ?? null;
  }

  private getFirst(): string | null {
    if (typeof this._value === 'string') {
      return this._value;
    }

    return Object.values(this._value)[0] ?? null;
  }
}
