import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { CoreModule } from '@modules/shared/core/core.module';
import { SurveyPageComponent } from '@modules/survey/pages/main/survey-page.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { SurveySubmitSuccessPageComponent } from '@modules/survey/pages/survey-submit-success-page/survey-submit-success-page.component';
import { FormModule } from '@modules/shared/form/form.module';
import { CardModule } from '@modules/shared/cards/card.module';

const routes: Routes = [
  {
    path: NAVIGATION.survey.path,
    component: SurveyPageComponent,
    canActivate: [authenticationGuard()],
  },
  {
    path: NAVIGATION.surveySubmitSuccess.path,
    component: SurveySubmitSuccessPageComponent,
    canActivate: [authenticationGuard()],
  },
];

@NgModule({
  declarations: [
    SurveyPageComponent,
    SurveySubmitSuccessPageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreModule,
    FormModule,
    CardModule,
  ],
})
export class SurveyModule {}
