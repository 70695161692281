<vh-primary-page [navigationItems]="navigationItems" [hasHorizontalContentSpacing]="false"
  [hasVerticalContentSpacing]="false" [profileTemplate]="profileTemplate">
  <vh-side-nav [navigationItems]="settingsNavigationItems">
    <div class="settings-page-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </vh-side-nav>
</vh-primary-page>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
