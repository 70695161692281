import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new BrowserTracing({
      tracingOrigins: environment.sentry.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.name,
  enabled: environment.sentry.enableErrorTracking,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
