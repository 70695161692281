<div class="flex-col full-width">
  <vh-button-group
    class="align-left"
    [singleItemSelectable]="true"
    [items]="toggleModeButtonOptions"
    [style]="ButtonGroupStyle.CALENDAR"
    (itemClick)="toggleMode($event)">
  </vh-button-group>

  <vh-horizontal-divider
    [verticalMargin]="16"
    [color]="'var(--color-primary)'">
  </vh-horizontal-divider>

  <div *ngIf="visitType === 'MEDICAL'" [formGroup]="eventFormGroup" class="grid-container">
    <!-- Date -->
    <div class="flex-row-l-c gap-h-16">
      <vh-icon
        src="assets/icons/calendar.svg"
        [width]="16"
        [height]="16">
      </vh-icon>

      <vh-text-input
        type="date"
        [formControlName]="VISIT_FORM_KEYS.get('startDate')"
        [style]="TextInputStyle.MATERIAL"
        [max]="DATE_BOUNDARIES.max.dateString"
        (inputValueChange)="onVisitDateTimeChanged()">
      </vh-text-input>
    </div>

    <!-- Time -->
    <div class="flex-row-l-c gap-h-16">
      <vh-icon
        src="assets/icons/clock-three.svg"
        [width]="16"
        [height]="16"
        [fill]="COLORS.secondary">
      </vh-icon>

      <div class="flex-row-l-c gap-h-16">
        <vh-text-input
          type="time"
          [formControlName]="VISIT_FORM_KEYS.get('startTime')"
          [style]="TextInputStyle.MATERIAL"
          (inputValueChange)="onVisitDateTimeChanged()">
        </vh-text-input>

        <p>-</p>

        <p class="font-regular-18-22">{{ endTime }}</p>
      </div>
    </div>

      <!-- Patient -->
      <div [vhMarginTop]="16">
        <!-- Search patient -->
        <label class="font-regular-18-22">{{ 'admin.pages.dashboard.createEventPopup.patient' | translate }}</label>

        <div class="flex-row-r-s gap-h-16 full-width">
          <div class="full-width">
            <vh-suggestions-text-input
            [vhMarginTop]="4"
            [loading]="isLoadingPatients"
            [placeholder]="createdUserLabel ?? 'admin.pages.dashboard.createEventPopup.patient' | translate"
            [getOptionsFunction$]="getPatientSuggestions$"
            [maxOptionsHeight]="'200px'"
            (inputValueChange)="onPatientClicked($event)">
          </vh-suggestions-text-input>
          </div>

          <button
            class="add-button clickable align-end"

            popoverPositioningStrategy="left_or_right"
            popoverConnectToFirstHtmlElementWithTagName="vh-create-event-popup"
            [vhPopoverHost]="POPOVER_ID"
            [popoverTemplate]="popupTemplate"
            [popoverHasBackdrop]="true"
            [popoverPreferredPosition]="popoverPreferredPositioning"

            [matTooltip]="'admin.pages.dashboard.createEventPopup.createPatient' | translate"
            (click)="openPopover(this.POPOVER_ID)">
            +
          </button>
        </div>

        <!-- Patient Register Popover -->
        <ng-template #popupTemplate>
          <div class="card gap-v-16 register-popup">

            <div class="flex-row-s-c">
              <h2>{{ 'conversations.create.createPatient.title' | translate }}</h2>

              <vh-button
                (click)="closePopover(this.POPOVER_ID)"
                [style]="ButtonStyle.ICON_ONLY"
                [iconRightPath]="'assets/icons/cross.svg'"
                [iconRightFill]="COLORS.white"
                [iconRightStroke]="COLORS.white">
              </vh-button>
            </div>

            <vh-register
              #registerComponent
              [headerIsVisible]="false"
              [loginButtonIsVisible]="false"
              [hasPasswordInput]="false"
              [hasPrivacyPolicyInput]="false"
              [registerButtonIsVisible]="false"
              [registerSelf]="false"
              [emailOrPhoneRequired]="true"
              [afterSuccessfulRegisterFunction]="newPatientCreated">
            </vh-register>

            <vh-button
              [label]="'admin.pages.dashboard.createEventPopup.savePatient' | translate"
              [vhMarginTop]="32"
              (buttonClick)="createNewPatient()"
              [centered]="true"
              [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
              [iconRightWidth]="20"
              [iconRightHeight]="20"
              [iconRightStroke]="COLORS.white">
            </vh-button>
          </div>
        </ng-template>
    </div>

    <!-- Service -->
    <vh-select-input
      [vhMarginTop]="16"
      [formControlName]="VISIT_FORM_KEYS.get('service')"
      [label]="'admin.pages.dashboard.createEventPopup.service' | translate"
      [placeholder]="'admin.pages.dashboard.createEventPopup.service' | translate"
      [options]="serviceOptions"
      [maxHeight]="'130px'">
    </vh-select-input>

    <!-- Room -->
    <vh-select-input
      [vhMarginTop]="16"
      [isDisabled]="!this.eventFormGroup.get(VISIT_FORM_KEYS.get('service'))?.value"
      [formControlName]="VISIT_FORM_KEYS.get('room')"
      [label]="'admin.pages.dashboard.createEventPopup.room' | translate"
      [placeholder]="'admin.pages.dashboard.createEventPopup.room' | translate"
      [options]="roomOptions"
      [maxHeight]="'52px'">
    </vh-select-input>

    <!-- Impact found warning -->
    <vh-message
      *ngIf="visitSchedulingImpact?.hasImpact()"
      [vhMarginTop]="16"
      class="not-clickable"
      [ngClass]="'has-text-alert'"
      [iconLeftPath]="'assets/icons/exclamation-triangle.svg'"
      [iconLeftFill]="COLORS.alert">
      {{ visitSchedulingImpactMessage | translate }}
    </vh-message>

    <!-- No recurrence found warning -->
    <vh-message
      *ngIf="!isRecurrenceFound"
      [vhMarginTop]="16"
      class="not-clickable"
      [ngClass]="{'has-text-alert': visitSchedulingImpact?.hasImpact() === true}"
      [iconLeftPath]="'assets/icons/exclamation-triangle.svg'"
      [iconLeftFill]="COLORS.alert">
      {{ 'admin.pages.dashboard.createEventPopup.noRecurrenceFound' | translate }}
    </vh-message>

    <!-- Buttons -->
    <div [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
      <!-- Save button -->
      <vh-button
        [isDisabled]="!isRecurrenceFound || eventFormGroup.invalid"
        [label]="'common.save' | translate"
        [iconLeftPath]="'assets/icons/arrow-right.svg'"
        [iconLeftFill]="COLORS.white"
        (buttonClick)="save()">
      </vh-button>

      <!-- Cancel button -->
      <vh-button
        [label]="'common.cancel' | translate"
        [style]="ButtonStyle.TERTIARY"
        (buttonClick)="cancel()">
      </vh-button>
    </div>
  </div>

  <div *ngIf="visitType === 'OTHER'" [formGroup]="otherVisitFormGroup" class="grid-container">
    <!-- Date -->
    <div class="flex-row-l-c gap-h-16">
      <vh-icon
        src="assets/icons/calendar.svg"
        [width]="16"
        [height]="16">
      </vh-icon>

      <vh-text-input
        type="date"
        [formControlName]="VISIT_FORM_KEYS.get('startDate')"
        [style]="TextInputStyle.MATERIAL"
        (inputValueChange)="onVisitDateTimeChanged()">
      </vh-text-input>
    </div>

    <!-- Time -->
    <div class="flex-row-l-c gap-h-16">
      <vh-icon
        src="assets/icons/clock-three.svg"
        [width]="16"
        [height]="16"
        [fill]="COLORS.secondary">
      </vh-icon>

      <div class="flex-row-l-c gap-h-16">
        <vh-text-input
          type="time"
          [formControlName]="VISIT_FORM_KEYS.get('startTime')"
          [style]="TextInputStyle.MATERIAL"
          (inputValueChange)="onVisitDateTimeChanged()">
        </vh-text-input>

        <p>-</p>

        <vh-text-input
          type="time"
          [formControlName]="VISIT_FORM_KEYS.get('endTime')"
          [style]="TextInputStyle.MATERIAL"
          (inputValueChange)="onVisitDateTimeChanged()">
        </vh-text-input>
      </div>
    </div>

    <!-- Title -->
    <vh-text-input
    [vhMarginTop]="16"
    [formControlName]="VISIT_FORM_KEYS.get('customTitle')"
    [label]="'admin.pages.dashboard.createEventPopup.title' | translate"
    [placeholder]="'admin.pages.dashboard.createEventPopup.title' | translate"></vh-text-input>

    <!-- Room -->
    <vh-select-input
      [vhMarginTop]="16"
      [formControlName]="VISIT_FORM_KEYS.get('room')"
      [label]="'admin.pages.dashboard.createEventPopup.room' | translate"
      [placeholder]="'admin.pages.dashboard.createEventPopup.room' | translate"
      [options]="roomOptions"
      [maxHeight]="'80px'">
    </vh-select-input>

    <!-- Cancel other visits -->
    <vh-checkbox
      [isDisabled]="!this.otherVisitFormGroup.get(VISIT_FORM_KEYS.get('room'))?.value"
      [vhMarginTop]="16"
      [label]="'admin.pages.dashboard.createEventPopup.cancelOverlappingVisits' | translate"
      [formControlName]="VISIT_FORM_KEYS.get('cancelOverlappingVisits')"

      [vhPopoverHost]="CANCEL_VISITS_POPOVER_ID"
      [popoverTemplate]="cancelVisitsPopupTemplate"
      [popoverHasBackdrop]="true"
      popoverPositioningStrategy="left_or_right"
      popoverConnectToFirstHtmlElementWithTagName="vh-create-event-popup"
      [popoverPreferredPosition]="popoverPreferredPositioning">
    </vh-checkbox>

    <!-- Cancel other visits Popover -->
    <ng-template #cancelVisitsPopupTemplate>
      <div class="flex-col card">
        <h2>{{ 'admin.pages.dashboard.createEventPopup.visitHasImpact' | translate }}</h2>

        <table class="patients-list" [vhMarginTop]="8">
          <thead>
            <tr>
              <th>{{ 'common.fields.start' | translate }}</th>
              <th>{{ 'common.fields.end' | translate }}</th>
              <th>{{ 'common.fields.service' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let visit of overlappingVisits" class="patient-row">
              <td>
                <p>{{ visit.start | dfnsFormat : DateFormat.clientDate }}</p>
                <p>{{ visit.start | dfnsFormat : DateFormat.clientTimeShort }}</p>
              </td>
              <td>
                <p>{{ visit.end | dfnsFormat : DateFormat.clientDate }}</p>
                <p>{{ visit.end | dfnsFormat : DateFormat.clientTimeShort }}</p>
              </td>
              <td>{{ visit.service?.name }}</td>
            </tr>
          </tbody>
        </table>

        <vh-cancel-visit-form [vhMarginTop]="16" [formGroup]="cancelVisitsFormGroup"></vh-cancel-visit-form>

        <!-- Buttons -->
        <div [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
          <!-- Save button -->
          <vh-button
            [label]="'common.ok' | translate"
            [alignment]="'center'"
            [centered]="true"
            [iconLeftPath]="'assets/icons/arrow-right.svg'"
            [iconLeftFill]="COLORS.white"
            (buttonClick)="onApproveCancelOverlappingVisitsClicked()">
          </vh-button>

          <!-- Cancel button -->
          <vh-button
            [label]="'common.cancel' | translate"
            [style]="'ButtonStyle.TERTIARY'"
            (buttonClick)="onDisapproveCancelOverlappingVisitsClicked()">
          </vh-button>
        </div>

      </div>
    </ng-template>

    <!-- No recurrence found warning -->
    <vh-message
      *ngIf="!isRecurrenceFound"
      [vhMarginTop]="16"
      class="not-clickable"
      [ngClass]="{'has-text-alert': visitSchedulingImpact?.hasImpact() === true}"
      [iconLeftPath]="'assets/icons/exclamation-triangle.svg'"
      [iconLeftFill]="COLORS.alert">
      {{ 'admin.pages.dashboard.createEventPopup.noRecurrenceFound' | translate }}
    </vh-message>

      <!-- Buttons -->
      <div [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
        <!-- Save button -->
        <vh-button
          [isDisabled]="!isRecurrenceFound || otherVisitFormGroup.invalid"
          [label]="'common.save' | translate"
          [alignment]="'center'"
          [centered]="true"
          [iconLeftPath]="'assets/icons/arrow-right.svg'"
          [iconLeftFill]="COLORS.white"
          (buttonClick)="onApproveTimeChangeClicked()">
        </vh-button>

        <!-- Cancel button -->
        <vh-button
          [label]="'common.cancel' | translate"
          [style]="ButtonStyle.TERTIARY"
          (buttonClick)="cancel()">
        </vh-button>
      </div>
  </div>
</div>
