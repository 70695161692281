import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[vhEscapePressed]',
})
export class EscapePressedDirective {
  @Output() escapePressed: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.escapePressed.emit();
    }
  }
}
