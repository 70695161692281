export const ENDPOINTS = {
  login: {
    route: '/login',
  },
  logout: {
    route: '/logout',
  },
  refreshToken: {
    route: '/refresh-token',
  },
  register: {
    route: '/register',
  },
  onboard: {
    route: '/onboard',
  },
  usersMe: {
    route: '/users/me',
  },
  validateUsername: {
    route: '/username/validate',
  },
  getUsernameSuggestion: {
    route: '/username/suggestion',
  },
  forgotUsername: {
    route: '/username/forgot',
  },
  forgotPassword: {
    route: '/password/forgot',
  },
  resetPassword: {
    route: '/password/reset',
  },
  updatePassword: {
    route: '/password/update',
  },
  resendVerificationEmail: {
    route: '/email/resend',
  },
  getServices: {
    route: '/services',
    queryParams: {
      serviceTypeKeys: 'service_type_keys',
      serviceTypeIds: 'service_type_ids',
      hasChildren: 'has_children',
      institution: 'institution',
    },
  },
  getVisits: {
    route: '/visits',
    queryParams: {
      startDate: 'start_date',
      endDate: 'end_date',
      sorting: 'sorting',
    },
  },
  getVisitsForInstitution: {
    route: '/institutions/{institutionId}/visits',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      startDate: 'from_date',
      endDate: 'to_date',
      institution: 'institution',
      rooms: 'rooms',
      patientName: 'patient_name',
      patientBirthdate: 'patient_birthdate',
      patientSocialSecurityNumber: 'patient_social_security_number',
      cancelled: 'cancelled',
      cancellationActionId: 'cancellation_action_id',
      sort: 'sort',
      page: 'page',
      page_size: 'page_size',
    },
  },
  getVisitsForInstitutionRequiringAction: {
    route: '/institutions/{institutionId}/visits-requiring-action/{actionId}',
    pathParams: {
      institutionId: 'institutionId',
      actionId: 'actionId',
    },
  },
  getVisitSchedulingImpact: {
    route: '/visits/schedule-impact',
    queryParams: {
      start: 'start',
      end: 'end',
      service_uuid: 'service_uuid',
      institution_uuid: 'institution_uuid',
    },
  },
  getVisitReschedulingImpact: {
    route: '/visits/{visitId}/reschedule-impact',
    pathParams: {
      visitId: 'visitId',
    },
    queryParams: {
      start: 'start',
      end: 'end',
    },
  },
  getDailyVisitsCount: {
    route: '/institutions/{institutionId}/visits/count/daily',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      startDate: 'from_date',
      endDate: 'to_date',
    },
  },
  resendVisitConfirmationEmail: {
    route: '/visits/{visitId}/resend-email',
    pathParams: {
      visitId: 'visitId',
    },
  },
  getAvailableSlotsInstitution: {
    route: '/institutions/{institutionId}/services/{serviceId}/slots',
    pathParams: {
      serviceId: 'serviceId',
      institutionId: 'institutionId',
    },
    queryParams: {
      pageSize: 'page_size',
      from: 'from',
      to: 'to',
    },
  },
  getAvailableSlots: {
    route: '/slots/search',
    queryParams: {
      serviceIds: 'service_ids',
      searchType: 'search_type',
      latitude: 'latitude',
      longitude: 'longitude',
      radius: 'radius',
      pageSize: 'page_size',
      from: 'from',
      to: 'to',
      timeslot: 'timeslot',
      selectedInstitution: 'institution_id',
      canServicesBeBookedByPatient: 'can_services_be_booked_by_patient',
      bookingFlow: 'booking_flow',
      timezone: 'timezone',
    },
  },
  submitContactRequest: {
    route: '/contact-me',
  },
  submitAppointmentContactRequest: {
    route: '/contact-for-appointment',
    queryParams: {
      latitude: 'latitude',
      longitude: 'longitude',
      radius: 'radius',
      serviceId: 'service_id',
    },
  },
  bookVisit: {
    route: '/visits/{visitId}/recurrences/{recurrenceId}',
    pathParams: {
      visitId: 'visitId',
      recurrenceId: 'recurrenceId',
    },
  },
  bookVisitForOtherUser: {
    route: '/users/{userId}/recurrences/{recurrenceId}',
    pathParams: {
      userId: 'userId',
      recurrenceId: 'recurrenceId',
    },
  },
  getVisitStatuses: {
    route: '/institutions/{institutionId}/visit-statuses',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  createVisit: {
    route: '/visits',
  },
  updateVisit: {
    route: '/visits/{visitId}',
    pathParams: {
      visitId: 'visitId',
    },
  },
  cancelVisit: {
    route: '/visits/{visitId}/cancel',
    pathParams: {
      visitId: 'visitId',
    },
  },
  bulkCancelVisits: {
    route: '/visits/bulk-cancel',
  },
  getVisitById: {
    route: '/visits/{visitId}',
    pathParams: {
      visitId: 'visitId',
    },
  },
  addVisitStatus: {
    route: '/visits/{visitId}/status',
    pathParams: {
      visitId: 'visitId',
    },
  },
  completeDocumentAppointmentTask: {
    route: '/visits/{visitId}/tasks/{taskId}/documents',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
    },
  },
  updateDocumentAppointmentTask: {
    route: '/visits/{visitId}/tasks/{taskId}/documents/{documentId}',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
      documentId: 'documentId',
    },
  },
  getDocumentAppointmentTask: {
    route: '/visits/{visitId}/tasks/{taskId}/documents/{documentId}',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
      documentId: 'documentId',
    },
  },
  deleteDocumentAppointmentTask: {
    route: '/visits/{visitId}/tasks/{taskId}/documents/{documentId}',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
      documentId: 'documentId',
    },
  },
  getRooms: {
    route: '/rooms',
  },
  getRoom: {
    route: '/rooms/{roomId}',
    pathParams: {
      institutionId: 'roomId',
    },
  },
  getRoomsOfInstitution: {
    route: '/institutions/{institutionId}/rooms',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      service: 'service',
    },
  },
  getPlanningOfRoom: {
    route: '/rooms/{roomId}/planning',
    pathParams: {
      roomId: 'roomId',
    },
  },
  getPlanningExceptionsOfRoom: {
    route: '/rooms/{roomId}/planning-exceptions',
    pathParams: {
      roomId: 'roomId',
    },
  },
  getPlanning: {
    route: '/institutions/{institutionId}/planning',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getDaysHavingPlannedSlots: {
    route: '/institutions/{institutionId}/planning/daily',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getServiceGroupsForRoom: {
    route: '/rooms/{roomId}/service-groups',
    pathParams: {
      roomId: 'roomId',
    },
  },
  getServicesForInstitutionFlat: {
    route: '/institutions/{institutionId}/services',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getServicesForInstitution: {
    route: '/institutions/{institutionId}/services/tree',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      loadAllTranslations: 'load_all_translations',
      serviceTypeIds: 'service_type_ids',
      hasChildren: 'has_children',
    },
  },
  getServiceOfInstitutionById: {
    route: '/institutions/{institutionId}/services/{serviceId}',
    pathParams: {
      institutionId: 'institutionId',
      serviceId: 'serviceId',
    },
  },
  getRecurrence: {
    route: '/recurrences/{recurrenceId}',
    pathParams: {
      recurrenceId: 'recurrenceId',
    },
  },
  findRecurrence: {
    route: '/recurrences/find-recurrence',
    queryParams: {
      start: 'start',
      end: 'end',
      serviceId: 'service_id',
      roomId: 'room_id',
    },
  },
  getImpactedVisitsWhenDeleting: {
    route: '/recurrences/{recurrenceId}/deletion-impact',
    pathParams: {
      recurrenceId: 'recurrenceId',
    },
  },
  getOverlappingVisits: {
    route: '/rooms/{roomId}/overlapping-visits',
    pathParams: {
      roomId: 'roomId',
    },
    queryParams: {
      start: 'start',
      end: 'end',
      visitId: 'visitId',
    },
  },
  deleteRecurrence: {
    route: '/recurrences/{recurrenceId}',
    pathParams: {
      recurrenceId: 'recurrenceId',
    },
  },
  getServiceById: {
    route: '/services/{serviceId}',
    pathParams: {
      serviceId: 'serviceId',
    },
  },
  getServiceRelationsTree: {
    route: '/services/{serviceId}/relations/tree',
    pathParams: {
      serviceId: 'serviceId',
    },
  },
  getServiceTypes: {
    route: '/service-types',
  },
  getServiceTypesOfInstitution: {
    route: '/institutions/{institutionId}/service-types',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      hasChildren: 'has_children',
    },
  },
  attachServiceToInstitution: {
    route: '/institutions/{institutionId}/services/{serviceId}',
    pathParams: {
      serviceId: 'serviceId',
      institutionId: 'institutionId',
    },
  },
  detachServiceFromInstitution: {
    route: '/institutions/{institutionId}/services/{serviceId}',
    pathParams: {
      serviceId: 'serviceId',
      institutionId: 'institutionId',
    },
  },
  downloadInstitutionServicesCsv: {
    route: '/institutions/{institutionId}/services/export',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  uploadInstitutionServicesCsv: {
    route: '/institutions/{institutionId}/services/import',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  createRecurrence: {
    route: '/recurrences',
  },
  getInstitutionUsers: {
    route: '/institutions/{institutionId}/users',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getInstitutionUserById: {
    route: '/institutions/{institutionId}/users/{userId}',
    pathParams: {
      institutionId: 'institutionId',
      userId: 'userId',
    },
  },
  attachUserToInstitution: {
    route: '/institutions/{institutionId}/users/{userId}',
    pathParams: {
      institutionId: 'institutionId',
      userId: 'userId',
    },
  },
  detachUserFromInstitution: {
    route: '/institutions/{institutionId}/users/{userId}',
    pathParams: {
      institutionId: 'institutionId',
      userId: 'userId',
    },
  },
  createDoctor: {
    route: '/doctors',
  },
  updateDoctor: {
    route: '/doctors/{doctorUuid}',
    pathParams: {
      doctorId: 'doctorUuid',
    },
  },
  getDoctorByRizivNumber: {
    route: '/doctors/{rizivNumber}',
    pathParams: {
      rizivNumber: 'rizivNumber',
    },
  },
  getDoctorIfLinked: {
    route: '/users/{userId}/doctor',
    pathParams: {
      userId: 'userId',
    },
  },
  linkDoctorToUser: {
    route: '/doctors/{userId}/{rizivNumber}/link',
    pathParams: {
      userId: 'userId',
      rizivNumber: 'rizivNumber',
    },
  },
  unlinkDoctorFromUser: {
    route: '/doctors/{userId}/unlink',
    pathParams: {
      userId: 'userId',
    },
  },
  getDoctorsOfInstitution: {
    route: '/institutions/{institutionId}/doctors',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getInstitutions: {
    route: '/institutions',
  },
  getInstitutionById: {
    route: '/institutions/{institutionId}',
    pathParams: {
      // This param can either be a slug or an id
      institutionId: 'institutionId',
    },
  },
  getInstitutionInsightsByInstitutionId: {
    route: '/institutions/{institutionId}/insights',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  updateInstitutionSettings: {
    route: '/institutions/{institutionId}/settings',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getPublicInstitutionSettings: {
    route: '/institutions/{institutionId}/settings/public',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  sendVisitPreviewEmail: {
    route: '/institutions/{institutionId}/settings/send-preview-visit-email',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  exportInstitutionData: {
    route: '/institutions/{institutionId}/data-export',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      from: 'from',
      to: 'to',
      exportTasks: 'export_tasks',
    },
  },
  findUser: {
    route: '/users/find',
    queryParams: {
      email: 'email',
      socialSecurityNumber: 'social_security_number',
    },
  },
  createUser: {
    route: '/users',
  },
  updateUser: {
    route: '/users/{userId}',
    pathParams: {
      userId: 'userId',
    },
  },
  deleteUser: {
    route: '/users/{userId}',
    pathParams: {
      userId: 'userId',
    },
  },
  trackSlotEvent: {
    route: '/slots/track',
  },
  bulkTrackSlotEvents: {
    route: '/slots/track/bulk',
  },
  trackInstitutionEvent: {
    route: '/institutions/track',
  },
  bulkTrackInstitutionEvents: {
    route: '/institutions/track/bulk',
  },
  trackAnswerListEvent: {
    route: '/answer-lists/track',
  },
  bulkTrackAnswerListEvents: {
    route: '/answer-lists/track/bulk',
  },
  getServiceGroups: {
    route: '/institutions/{institutionId}/service-groups',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getServiceGroupById: {
    route: '/institutions/{institutionId}/service-groups/{serviceGroupId}',
    pathParams: {
      institutionId: 'institutionId',
      serviceGroupId: 'serviceGroupId',
    },
  },
  createServiceGroup: {
    route: '/institutions/{institutionId}/service-groups',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  updateServiceGroup: {
    route: '/institutions/{institutionId}/service-groups',
    pathParams: {
      institutionId: 'institutionId',
      serviceGroupId: 'serviceGroupId',
    },
  },
  deleteServiceGroup: {
    route: '/institutions/{institutionId}/service-groups/{serviceGroupId}',
    pathParams: {
      institutionId: 'institutionId',
      serviceGroupId: 'serviceGroupId',
    },
  },
  getCareProgramsOfInstitution: {
    route: '/institutions/{institutionId}/care-programs',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getCareProgramById: {
    route: '/institutions/{institutionId}/care-programs/{careProgramId}',
    pathParams: {
      institutionId: 'institutionId',
      careProgramId: 'careProgramId',
    },
  },
  createCareProgram: {
    route: '/institutions/{institutionId}/care-programs',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  updateCareProgram: {
    route: '/institutions/{institutionId}/care-programs/{careProgramId}',
    pathParams: {
      institutionId: 'institutionId',
      careProgramId: 'careProgramId',
    },
  },
  deleteCareProgram: {
    route: '/institutions/{institutionId}/care-programs/{careProgramId}',
    pathParams: {
      institutionId: 'institutionId',
      careProgramId: 'careProgramId',
    },
  },
  careProgramSetStatus: {
    route: '/institutions/{institutionId}/care-programs/{careProgramId}/status',
    pathParams: {
      institutionId: 'institutionId',
      careProgramId: 'careProgramId',
    },
  },
  duplicateCareProgram: {
    route: '/institutions/{institutionId}/care-programs/{careProgramId}/duplicate',
    pathParams: {
      institutionId: 'institutionId',
      careProgramId: 'careProgramId',
    },
  },
  getSurveys: {
    route: '/institutions/{institutionId}/surveys',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getSurveyById: {
    route: '/institutions/{institutionId}/surveys/{surveyId}',
    pathParams: {
      institutionId: 'institutionId',
      surveyId: 'surveyId',
    },
  },
  getSurveyDeletionImpact: {
    route: '/institutions/{institutionId}/surveys/{surveyId}/deletion-impact',
    pathParams: {
      institutionId: 'institutionId',
      surveyId: 'surveyId',
    },
  },
  updateSurvey: {
    route: '/institutions/{institutionId}/surveys/{surveyId}',
    pathParams: {
      institutionId: 'institutionId',
      surveyId: 'surveyId',
    },
  },
  createSurvey: {
    route: '/institutions/{institutionId}/surveys',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  deleteSurvey: {
    route: '/institutions/{institutionId}/surveys/{surveyId}',
    pathParams: {
      institutionId: 'institutionId',
      surveyId: 'surveyId',
    },
  },
  getSurveyTask: {
    route: '/visits/{visitId}/tasks/{taskId}/survey',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
    },
  },
  submitAnswerList: {
    route: '/visits/{visitId}/tasks/{taskId}/survey/{answersListId}',
    pathParams: {
      visitId: 'visitId',
      taskId: 'taskId',
      answersListId: 'answersListId',
    },
  },
  getAnswerLists: {
    route: '/institutions/{institutionId}/answer-lists',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getAnswerList: {
    route: '/institutions/{institutionId}/answer-lists/{answerListId}',
    pathParams: {
      institutionId: 'institutionId',
      answerListId: 'answerListId',
    },
  },
  evaluateAnswerList: {
    route: '/institutions/{institutionId}/answer-lists/{answerListId}',
    pathParams: {
      institutionId: 'institutionId',
      answerListId: 'answerListId',
    },
  },
  createPendingBooking: {
    route: '/pending-bookings',
  },
  getPendingBookingById: {
    route: '/pending-bookings/{pendingBookingId}',
    pathParams: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  updatePendingBooking: {
    route: '/pending-bookings/{pendingBookingId}',
    pathParams: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  bookPendingBooking: {
    route: '/pending-bookings/{pendingBookingId}/book',
    pathParams: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  getAvailableBuckets: {
    route: '/buckets',
  },
  getBucketById: {
    route: '/buckets/{bucketId}',
    pathParams: {
      bucketId: 'bucketId',
    },
  },
  buyBucket: {
    route: '/buckets/buy',
  },
  getOrders: {
    route: '/institutions/{institutionId}/orders',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getOrderById: {
    route: '/institutions/{institutionId}/orders/{orderId}',
    pathParams: {
      institutionId: 'institutionId',
      orderId: 'orderId',
    },
  },
  getChatInfo: {
    route: '/conversations/{conversationId}/chat/info',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  getTranscript: {
    route: '/conversations/{conversationId}/transcript',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  updateTranscript: {
    route: '/conversations/{conversationId}/transcript',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  completeConversation: {
    route: '/conversations/{conversationId}/complete',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  acceptPolicy: {
    route: '/conversations/{conversationId}/policy/accept',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  getWalletTransactionHistory: {
    route: '/institutions/{institutionId}/wallet/history',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getAllConversations: {
    route: '/institutions/{institutionId}/conversations',
    pathParams: {
      institutionId: 'institutionId',
    },
    queryParams: {
      startDate: 'from_date',
      endDate: 'to_date',
      institution: 'institution',
      patient: 'patient',
      doctor: 'doctor',
      includeDeleted: 'include_deleted',
      status: 'status',
      questionList: 'question_list',
      sort: 'sort',
      page: 'page',
      page_size: 'page_size',
    },
  },
  createConversation: {
    route: '/institutions/{institutionId}/conversations',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getConversationDetails: {
    route: '/conversations/{conversationId}',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  resendEmail: {
    route: '/conversations/{conversationId}/resend-email',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  deleteConversation: {
    route: '/conversations/{conversationId}',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  undoDeleteConversation: {
    route: '/conversations/{conversationId}/undo-delete',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  newConversationEvent: {
    route: '/conversations/{conversationId}/events',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  activateConversationWhatsApp: {
    route: '/conversations/{conversationId}/whatsapp/activate',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  deactivateConversationWhatsApp: {
    route: '/conversations/{conversationId}/whatsapp/deactivate',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  regenerateSummary: {
    route: '/conversations/{conversationId}/regenerate-summary',
    pathParams: {
      conversationId: 'conversationId',
    },
  },
  getWalletDemoTokensAmount: {
    route: '/wallet/demo-tokens-amount',
  },
  getWallet: {
    route: '/institutions/{institutionId}/wallet',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  updateWalletPreferences: {
    route: '/institutions/{institutionId}/wallet/auto-purchase',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  getPaymentMethods: {
    route: '/institutions/{institutionId}/wallet/payment-methods',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  addPaymentMethod: {
    route: '/institutions/{institutionId}/wallet/payment-methods',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  updatePaymentMethod: {
    route: '/institutions/{institutionId}/wallet/payment-methods/{paymentMethodId}',
    pathParams: {
      institutionId: 'institutionId',
      paymentMethodId: 'paymentMethodId',
    },
  },
  deletePaymentMethod: {
    route: '/institutions/{institutionId}/wallet/payment-methods/{paymentMethodId}',
    pathParams: {
      institutionId: 'institutionId',
      paymentMethodId: 'paymentMethodId',
    },
  },
  getPaymentStatus: {
    route: '/institutions/{institutionId}/wallet/payment-status/{paymentId}',
    pathParams: {
      institutionId: 'institutionId',
      paymentId: 'paymentId',
    },
  },
  getStandardConversationQuestions: {
    route: '/conversation-questions',
  },
  getConversationQuestionsForInstitution: {
    route: '/institutions/{institutionId}/conversation-questions',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  attachConversationQuestionsToInstitution: {
    route: '/institutions/{institutionId}/conversation-questions/attach',
    pathParams: {
      institutionId: 'institutionId',
    },
  },
  createInstitution: {
    route: '/institutions',
  },
  getSpecialties: {
    route: '/specialties',
  },
  sendMissingQuestionListLeadEmail: {
    route: '/sales/leads/question-list',
  },
  platformFeedback: {
    route: '/feedback/platform-feedback',
    queryParams: {
      feedback: 'feedback',
    },
  },
  rateSummary: {
    route: '/feedback/summary-feedback',
    queryParams: {
      feedback: 'feedback',
    },
  },
};
