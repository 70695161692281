import { TranslatedProperty } from '@models/translated-property.model';

export class AnswerOption {
  constructor(private _id: string, private _questionId: number, private _value?: TranslatedProperty) {}

  get id(): string {
    return this._id;
  }

  get questionId(): number {
    return this._questionId;
  }

  get value(): TranslatedProperty {
    return this._value;
  }

  static fromJson(json: AnswerOptionJson): AnswerOption {
    return json
      ? new AnswerOption(
        json.id,
        json.question_id,
        new TranslatedProperty(json.value)
      )
      : null;
  }

  toJson(): AnswerOptionJson {
    return {
      id: this._id,
      question_id: this._questionId,
      value: this._value.get(),
    };
  }
}

export interface AnswerOptionJson {
  id: string;
  question_id: number;
  value: string | Record<string, string>;
}
