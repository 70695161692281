import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vhSortRoles',
})
export class SortRolesPipe implements PipeTransform {
  transform(roles: Set<string>): string[] {
    return Array.from(roles).sort((roleA: string, roleB: string): number => roleA.localeCompare(roleB));
  }
}
