import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { getDateTimeFromTime } from '@utils/helpers/date.util';

function dateComparisonValidator(
  otherControlName: string,
  compareFn: (a: Date, b: Date) => boolean,
  errorKey: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control.parent;
    if (!formGroup) {
      return null;
    }

    const otherControl = formGroup.get(otherControlName);
    if (!otherControl) {
      return null;
    }

    const controlValue = control.value;
    const otherControlValue = otherControl.value;

    if (!controlValue || !otherControlValue) {
      return null;
    }

    const controlDate = getDateTimeFromTime(controlValue);
    const otherControlDate = getDateTimeFromTime(otherControlValue);

    if (isNaN(controlDate.getTime()) || isNaN(otherControlDate.getTime())) {
      return { invalidDate: true };
    }

    return compareFn(controlDate, otherControlDate) ? { [errorKey]: true } : null;
  };
}

export function timeNotAfter(otherControlName: string): ValidatorFn {
  return dateComparisonValidator(otherControlName, (a, b) => a > b, 'dateNotAfter');
}

export function timeNotBefore(otherControlName: string): ValidatorFn {
  return dateComparisonValidator(otherControlName, (a, b) => a < b, 'dateNotBefore');
}
