import { Injectable } from '@angular/core';
import { Service } from '@models/service.model';
import { ServiceFilter, ServiceService } from '@services/service.service';
import { forkJoin, map, Observable, of } from 'rxjs';
import { ServiceTypeService } from '@services/service-type.service';
import { PendingBookingService } from '@services/pending-booking.service';
import { ServiceType } from '@models/service-type.model';
import { BookingFlow } from '@enums/booking-flow.enum';
import { PendingBooking } from '@models/pending-booking.model';
import { BookingFlowFilter } from '@modules/booking/models/booking-flow-filter.model';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { Booking } from '@modules/booking/models/booking.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { BookingFlowInitiatorService } from '@modules/booking/services/booking-flow-initiator.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { arrayWrap } from '@utils/helpers/array.util';
import { InstitutionService } from '@services/institution.service';
import { Institution } from '@models/institution.model';
import { AuthenticationService } from '@services/authentication.service';

/**
 * This service is responsible for loading the data required for the booking flow.
 */
@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class BookingFlowResolverService {
  constructor(
    private readonly serviceService: ServiceService,
    private readonly serviceTypesService: ServiceTypeService,
    private readonly pendingBookingService: PendingBookingService,
    private readonly bookingState: BookingFlowStateService,
    private readonly bookingFlowDialogService: BookingFlowDialogService,
    private readonly bookingFlowInitiatorService: BookingFlowInitiatorService,
    private readonly institutionService: InstitutionService,
    private readonly authenticationService: AuthenticationService
  ) {}

  restore(flow: BookingFlow, pendingBooking: PendingBooking, institutionId: string = null): Observable<void> {
    return this.loadData(institutionId)
      .pipe(map(([serviceTypes, services, institution]: [ServiceType[], Service[], Institution]): void => {
        this.bookingState.pendingBookingId = pendingBooking.id;
        this.bookingState.flow = flow;
        this.bookingState.scopedInstitution = institution;
        this.bookingState.services = services;
        this.bookingState.serviceTypes = serviceTypes;
        this.bookingState.booking = new Booking(pendingBooking.visits);
        this.bookingState.filter = new BookingFlowFilter();

        if (this.bookingState.scopedInstitution && !this.bookingState.filter.selectedInstitutionId) {
          this.bookingState.filter.selectedInstitutionId = this.bookingState.scopedInstitution.id;
        }
      }));
  }

  // Fetch a pending booking and resolve all the internal IDs to their respective objects
  restoreFromPendingBookingId(pendingBookingId: string): Observable<void> {
    return this.pendingBookingService.getPendingBookingById$(pendingBookingId)
      .pipe(
        switchMap((pendingBooking: PendingBooking) => {
          const flow: BookingFlow = BookingFlow[pendingBooking.data.flow];

          return this.restore(flow, pendingBooking, pendingBooking.data.scopedInstitutionId ?? null);
        }),
        catchError(this.handleOnFailToRestore)
      );
  }

  getPendingBookingIdFromRoute(route: ActivatedRouteSnapshot): string {
    return route.params.pendingBookingId;
  }

  // TODO: filter services based on selected service type
  private loadData(institutionId: string = null): Observable<[ServiceType[], Service[], Institution]> {
    return forkJoin([
      this.loadServiceTypes(institutionId),
      this.loadServices(institutionId),
      this.loadInstitution(institutionId),
    ]);
  }

  private loadServiceTypes(institutionId: string = null): Observable<ServiceType[]> {
    return institutionId
      ? this.serviceTypesService.getServiceTypesOfInstitution$(institutionId)
      : this.serviceTypesService.getServiceTypes$();
  }

  private loadServices(institutionId: string = null, serviceType: ServiceType = null): Observable<Service[]> {
    const filter: ServiceFilter = {
      hasChildren: true,
    };

    if (institutionId) {
      filter.institution = institutionId;
    }

    if (serviceType) {
      filter.serviceTypeIds = arrayWrap(serviceType.id);
    }

    return this.serviceService.getServices$(filter, true);
  }

  private handleOnFailToRestore(error: HttpErrorResponse): Observable<any> {
    if (error.status === 404) {
      this.bookingFlowDialogService.showExpiredLinkDialog()
        .pipe(untilDestroyed(this))
        .subscribe((): void => {
          this.bookingFlowInitiatorService.restart();
        });
    }

    return of(null);
  }

  private loadInstitution(institutionId: string = null): Observable<Institution> {
    if (!institutionId) {
      return of(null);
    }

    if (institutionId === this.authenticationService.institution?.id) {
      return of(this.authenticationService.institution);
    }

    return this.institutionService.getInstitutionByIdOrSlug$(institutionId);
  }
}
