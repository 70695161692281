import { Component, OnInit, ViewChild } from '@angular/core';
import { StepSelectLocationComponent } from '@modules/booking/components/step-select-location/step-select-location.component';
import { MapViewport } from '@interfaces/map-viewport.interface';
import { BOOKING_STEPS as Steps } from '@modules/booking/constants/booking-steps.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinate } from '@models/coordinate.model';
import { Service } from '@models/service.model';
import { ServiceFilter, ServiceService } from '@services/service.service';
import { VisitServiceDependencyDialogResult } from '@modules/booking/dialogs/visit-blocked-by-service-dialog/visit-service-dependency-dialog.component';
import { VirtualSlot } from '@models/virtual-slot.model';
import { HierarchicalPickerState } from '@modules/booking/models/hierarchy-state.model';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';
import { Subscription } from 'rxjs';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { BaseBookingFlowPageComponent } from '@modules/booking/pages/base-booking-flow-page/base-booking-flow.page';
import { BookingFlow } from '@enums/booking-flow.enum';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { InstitutionTrackingEventService } from '@services/tracking/institution-tracking-event.service';
import { Timeslot } from '@modules/shared/core/components/timeslot-picker/timeslot.model';
import { AuthenticationService } from '@services/authentication.service';
import { Mode } from '@enums/mode.enum';

type PageState = 'loading' | 'select_service_type' | 'form';

@Component({
  selector: 'vh-book-visit-page',
  templateUrl: './book-visit-page.component.html',
  styleUrls: ['./book-visit-page.component.scss'],
})
@UntilDestroy()
export class BookVisitPageComponent extends BaseBookingFlowPageComponent implements OnInit {
  protected readonly Steps: typeof Steps = Steps;
  protected readonly BookingFlow: typeof BookingFlow = BookingFlow;

  currentPageState: PageState;
  wizardState: WizardState;
  servicesState: HierarchicalPickerState;
  serviceTypesState: HierarchicalPickerState;

  hasSelectServiceTypePage: boolean = true;

  serviceDependencyDialogSubscription: Subscription;

  @ViewChild('selectLocationStep') selectLocationStepComponent: StepSelectLocationComponent;

  constructor(
    private readonly serviceService: ServiceService,
    private readonly trackingService: InstitutionTrackingEventService,
    private readonly authenticationService: AuthenticationService,
    bookingState: BookingFlowStateService,
    bookingFlowNavigationService: BookingFlowNavigationService,
    bookingFlowDialogService: BookingFlowDialogService
  ) {
    super(bookingState, bookingFlowNavigationService, bookingFlowDialogService);
  }

  ngOnInit(): void {
    this.serviceTypesState = new HierarchicalPickerState(this.bookingState.serviceTypes);
    this.servicesState = new HierarchicalPickerState(this.bookingState.services);

    this.initWizard();

    this.currentPageState = 'select_service_type';

    if (this.bookingState.flow === BookingFlow.UNIQUE_LINK) {
      this.trackingService.track(TrackingEventType.INSTITUTION_LINK_OPENED, this.bookingState.scopedInstitution);
    }
  }

  onServiceTypeSelected(autoSelectServiceId: string = null): void {
    this.currentPageState = 'loading';

    const filterParams: ServiceFilter = {
      serviceTypeIds: [this.serviceTypesState.currentItem.id],
      hasChildren: true,
      institution: this.bookingState.scopedInstitution?.id,
    };

    this.serviceService.getServices$(filterParams, true)
      .pipe(untilDestroyed(this))
      .subscribe((services: Service[]) => {
        this.servicesState = new HierarchicalPickerState(services, autoSelectServiceId);
        this.currentPageState = 'form';
        this.wizardState.goToStart();
        this.updatePendingBooking();
      });
  }

  onServiceSelected(skipBlockedByCheck: boolean = false): void {
    if (!this.servicesState.hasReachedDeepestLevel()) {
      return;
    }

    this.serviceService.getServiceRelationsTree$(this.servicesState.currentItem.id)
      .pipe(untilDestroyed(this))
      .subscribe((service: Service) => {
        if (service.blockedBy.length > 0 && !skipBlockedByCheck) {
          const rootBlockedBy: Service = service.findRootBlockedBy(service);
          this.showServiceDependencyDialog(service, rootBlockedBy);

          return;
        }

        if (service.blocks.length && !skipBlockedByCheck) {
          const rootBlocks: Service = service.findRootBlocks(service);
          this.showServiceDependencyDialog(service, rootBlocks);

          return;
        }

        this.bookingState.booking.addVisitForServiceIfNotExists(service);

        this.wizardState.next();
        this.updatePendingBooking();
      });
  }

  onTimeslotClicked(timeslot: Timeslot): void {
    this.bookingState.filter.timeslot = timeslot;
    this.updatePendingBooking();
  }

  onLocationChange(coordinate: Coordinate): void {
    this.bookingState.filter.latitude = coordinate.latitude;
    this.bookingState.filter.longitude = coordinate.longitude;
    this.updatePendingBooking();
  }

  onClearFilterClicked(): void {
    this.bookingState.filter.reset();
    this.updatePendingBooking();
  }

  onSlotSelected(slot: VirtualSlot): void {
    if (!slot) {
      return;
    }

    this.bookingState.booking.selectSlot(slot);

    if (this.authenticationService.mode === Mode.PRIVATE) {
      this.bookingState.booking.selectPatient(this.authenticationService.currentUser);
    }

    this.bookingState.syncToServer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.bookingState.booking.patient === null) {
          this.navigationService.navigateToSelectPatientPage(this.bookingState.pendingBookingId);

          return;
        }

        this.navigationService.navigateToBookingConfirmationPage(this.bookingState.pendingBookingId);
      });
  }

  onMapViewPortUpdated(viewport: MapViewport): void {
    this.bookingState.filter.latitude = viewport.lat;
    this.bookingState.filter.longitude = viewport.lng;
    this.bookingState.filter.radius = viewport.radius;
    this.updatePendingBooking();
  }

  navigateToSelectedStep(selectedStep: number): void {
    const firstStepWasCompleted: boolean = !!this.servicesState.currentItem;

    if (firstStepWasCompleted) {
      this.wizardState.goToStep(selectedStep);
    }

    if (this.wizardState.currentStep === Steps.selectService) {
      this.wizardState.nextButtonIsHidden = !this.servicesState.currentItem;
      this.wizardState.nextButtonIsDisabled = !this.servicesState.currentItem;
    }

    this.checkSkippedSteps();
  }

  navigateToContactPage(): void {
    const center: google.maps.LatLng = this.selectLocationStepComponent?.mapComponent?.getCenter();

    // TODO: all parameters can be deduced from the booking flow state
    this.navigationService.navigateToContactPage({
      latitude: center?.lat() ?? this.bookingState.filter.latitude,
      longitude: center?.lng() ?? this.bookingState.filter.longitude,
      radius: center ? this.selectLocationStepComponent?.getMapRadius() : this.bookingState.filter.radius,
      service: this.servicesState.currentItem.id ?? this.bookingState.booking.leadingVisit?.service?.id,
      // TODO: Set this to the pending booking id
      visit: null,
    });
  }

  onRemoveServiceClicked(service: Service): void {
    this.bookingState.booking.removeVisitByService(service);
  }

  onGoBackClicked(): void {
    if (this.wizardState.canGoBack() && this.currentPageState === 'form') {
      this.wizardState.previous();

      return;
    }

    if (!this.wizardState.canGoBack() && this.currentPageState === 'form' && this.hasSelectServiceTypePage) {
      this.currentPageState = 'select_service_type';

      return;
    }

    this.exitBookingFlow();
  }

  private checkSkippedSteps(): void {
    // if (this.wizardState.currentStep >= Steps.uploadReferralLetter && this.multiPartBooking.leadingBooking?.getTaskByKey(AppointmentTaskKey.REFERRAL_DOCUMENT)?.documents?.length === 0) {
    //   this.wizardState.skippedSteps.add(Steps.uploadReferralLetter);
    //
    //   return;
    // }

    this.wizardState.skippedSteps.delete(Steps.uploadReferralLetter);
  }

  private showServiceDependencyDialog(targetService: Service, dependantService: Service): void {
    if (this.serviceDependencyDialogSubscription) {
      return;
    }

    this.serviceDependencyDialogSubscription = this.dialogService.showServiceDependencyDialog(targetService, dependantService)
      .pipe(untilDestroyed(this))
      .subscribe((result: VisitServiceDependencyDialogResult) => {
        this.serviceDependencyDialogSubscription = null;

        if (result.status === 'yes') {
          this.bookingState.booking.addVisitForServiceIfNotExists(targetService);
          this.bookingState.booking.addVisitForServiceIfNotExists(dependantService);

          this.wizardState.next();
          this.updatePendingBooking();

          return;
        }

        if (result.status === 'no') {
          this.bookingState.booking.addVisitForServiceIfNotExists(targetService);
          this.wizardState.next();
          this.updatePendingBooking();

          return;
        }
      });
  }

  private initWizard(): void {
    this.wizardState = new WizardState([
      'pages.createAppointment.step1.title',
      'pages.createAppointment.step2.title',
      'pages.createAppointment.step3.title',
      'pages.createAppointment.step4.title',
    ]);

    // The referral letter step is hidden for all users until it can be reworked and parameterized.
    this.wizardState.hiddenSteps.add(Steps.uploadReferralLetter);
    this.wizardState.optionalSteps.add(Steps.uploadReferralLetter);

    if (this.bookingState.flow !== BookingFlow.PUBLIC) {
      //this.wizardState.hiddenSteps.add(Steps.uploadReferralLetter);
      this.wizardState.hiddenSteps.add(Steps.selectLocation);
    }
  }
}
