import { CareProgram, CareProgramJson } from '@models/care-program.model';
import { Service, ServiceJson } from '@models/service.model';
import { TranslatedProperty } from '@models/translated-property.model';

export class CareProgramStep {
  constructor(
    private _id: string,
    private _name: TranslatedProperty,
    private _careProgram: CareProgram,
    private _leading: boolean,
    private _timeToLeading: number,
    private _timeRangeBefore: number,
    private _timeRangeAfter: number,
    private _services: Service[]
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): TranslatedProperty {
    return this._name;
  }

  get careProgram(): CareProgram {
    return this._careProgram;
  }

  get leading(): boolean {
    return this._leading;
  }

  get timeToLeading(): number {
    return this._timeToLeading;
  }

  get timeRangeBefore(): number {
    return this._timeRangeBefore;
  }

  get timeRangeAfter(): number {
    return this._timeRangeAfter;
  }

  get services(): Service[] {
    return this._services;
  }

  static fromJson(json: CareProgramStepJson): CareProgramStep {
    return json
      ? new CareProgramStep(
        json.id,
        new TranslatedProperty(json.name),
        CareProgram.fromJson(json.careProgram),
        json.leading,
        json.days_to_leading,
        json.margin_before,
        json.margin_after,
        json.services ? json.services.map(Service.fromJson) : []
      )
      : null;
  }
}

export interface CareProgramStepJson {
  id: string;
  name: string | Record<string, string>;
  careProgram: CareProgramJson;
  leading: boolean;
  days_to_leading: number;
  margin_before: number;
  margin_after: number;
  services?: ServiceJson[];
}
