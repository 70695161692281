<form class="flex-col" [formGroup]="formGroup" (ngSubmit)="login()">
  <vh-message
    *ngIf="registerButtonIsVisible"
    class="clickable align-end"
    [iconRightPath]="'assets/icons/chevron-right.svg'"
    [iconRightStroke]="COLORS.primary"
    textClass="has-text-primary font-light-14-17"
    [text]="'pages.login.registerButtonLabel' | translate"
    [routerLink]="NAVIGATION.register.route">
  </vh-message>
  <vh-text-input
    autocomplete="username"
    [formControlName]="LOGIN_FORM_KEYS.get('username')"
    [label]="'common.fields.username' | translate"
    [placeholder]="'common.fields.username' | translate">
  </vh-text-input>
  <vh-message
    class="has-text-primary font-light-14-17 align-end clickable"
    [vhMarginTop]="8"
    [text]="'pages.forgotPassword.forgotUsernameLabel' | translate"
    (messageClick)="onForgotUsernameClicked()">
  </vh-message>
  <vh-password-input
    autocomplete="current-password"
    [formControlName]="LOGIN_FORM_KEYS.get('password')"
    [label]="'pages.login.passwordInputLabel' | translate"
    [placeholder]="'pages.login.passwordInputLabel' | translate">
  </vh-password-input>
  <vh-message
    class="has-text-primary font-light-14-17 align-end clickable"
    [vhMarginTop]="8"
    [text]="'pages.login.forgotPasswordMessage' | translate"
    [textAlignment]="'right'"
    (messageClick)="navigateToForgotPasswordPage()">
  </vh-message>
  <vh-message
    *ngIf="errorMessageTranslationKey"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="errorMessageTranslationKey | translate">
  </vh-message>
  <vh-button
    class="align-center"
    type="submit"
    [vhMarginTop]="32"
    [label]="'pages.login.loginButtonLabel' | translate"
    [isDisabled]="loginButtonIsDisabled"
    [alignment]="'center'">
  </vh-button>
</form>
