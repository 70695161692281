<vh-skeleton-group
  *ngIf="isLoading"
  [amountSkeletons]="5"
  [skeletonIsEmpty]="false"
  [template]="skeletonAppointmentCardTemplate">
</vh-skeleton-group>

<div class="flex-col gap-v-16">
  <vh-visit-card
    vhSlotTracker
    *ngFor="let visit of visits"
    [trackingData]="visit"
    [ngClass]="{ clickable: onCardClickFunction }"
    [visit]="visit"
    [layout]="visitCardLayout"
    [dateCardLayout]="dateCardLayout"
    [dateCardBackgroundColor]="dateCardBackgroundColor"
    [getDateCardBackgroundColorFunction]="getDateCardBackgroundColorFunction"
    [isHighlightedFunction]="isHighlightedFunction"
    [actionsTemplate]="actionsTemplate"
    (click)="onCardClickFunction(visit)">
  </vh-visit-card>
</div>

<ng-container *ngIf="visits?.length === 0 && noResultsMessage">
  <vh-skeleton-group
    *ngIf="noResultsMessage.showSkeletons"
    [template]="skeletonAppointmentCardTemplate"
    [skeletonIsEmpty]="true"
    [emptySkeletonMessage]="noResultsMessage.text"
    [amountSkeletons]="1">
  </vh-skeleton-group>
  <div *ngIf="!noResultsMessage.showSkeletons && !disablePast">
    <h2 *ngIf="noResultsMessage.title">{{ noResultsMessage.title }}</h2>
    <p>{{ noResultsMessage.text }}</p>
  </div>
  <div *ngIf="disablePast">
    <h2 *ngIf="noResultsMessage.title">{{ noResultsMessage.title }}</h2>
    <p>{{ 'admin.pages.createAppointment.step4.a.noPastReservations' | translate }}</p>
  </div>
</ng-container>

<ng-template #skeletonAppointmentCardTemplate let-skeletonIsEmpty="skeletonIsEmpty" let-emptySkeletonMessage="emptySkeletonMessage">
  <vh-visit-card
    [skeletonIsEmpty]="skeletonIsEmpty"
    [emptySkeletonMessage]="emptySkeletonMessage"
    [layout]="visitCardLayout">
  </vh-visit-card>
</ng-template>
