import { Component, Input } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { Conversation } from '@models/conversation.model';

@Component({
  selector: 'vh-conversations-list',
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.scss'],
})
export class ConversationsListComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;

  @Input() conversations: Conversation[] = [];
  @Input() loading: boolean = false;

  @Input() clickConversation: (conversation: Conversation, event: Event) => void;
}
