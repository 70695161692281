export class Document {
  constructor(private _id: string, private _filename: string, private _description?: string) {}

  get id(): string {
    return this._id;
  }

  get filename(): string {
    return this._filename;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  static fromJson(json: DocumentJson): Document {
    return json ? new Document(json.id, json.filename, json?.description) : null;
  }
}

export interface DocumentJson {
  id: string;
  filename: string;
  description?: string;
}
