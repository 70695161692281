<div
  class="drag-and-drop-container"
  [ngClass]="isHovering ? 'active' : ''"
  vhDragAndDrop
  (fileDropped)="onFileDropped($event)"
  (fileDragLeave)="onFileDragLeave()"
  (fileDragOver)="onFileDragOver()">
  <input type="file" #fileInputRef id="fileDropRef" multiple (change)="onBrowseFileSelected($event)" [accept]="accept" />
  <div class="drag-and-drop-instructions-container">
    <img
      class="img-36"
      draggable="false"
      src="assets/icons/upload.svg"
      [alt]="'components.upload.browseAction' | translate"
      [ngClass]="isHovering ? 'up-and-down-animation' : ''" />
    <p [vhMarginTop]="12" class="highlight" (click)="fileInputRef.click()">{{ 'components.upload.browseActionShort' | translate }}</p>
    <p [vhMarginTop]="12">{{ 'components.upload.dragAction' | translate }}</p>
  </div>
</div>
<div [vhMarginTop]="8" *ngIf="errors?.length > 0">
  <div class="error-text" *ngFor="let errorKey of errors">{{ errorKey | translate }}</div>
</div>
<div [vhMarginTop]="16">
  <vh-uploaded-file
    *ngFor="let file of files; let i = index"
    [file]="file"
    [index]="i"
    (deleteFileClicked)="onDeleteFileClicked($event)"
    (descriptionChanged)="fileDescriptionChange.emit($event)"></vh-uploaded-file>
</div>
