import { Directive } from '@angular/core';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { CanComponentDeactivate } from '@interfaces/can-component-deactivate.interface';
import { CanDeactivateType } from '@app-types/can-deactivate.type';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { Subscription } from 'rxjs';
import { RouterStateSnapshot } from '@angular/router';

@Directive()
@UntilDestroy()
export class BaseBookingFlowPageComponent implements CanComponentDeactivate {
  protected userConfirmedExit: boolean = false;
  protected updatePendingBookingSubscription: Subscription | null = null;

  constructor(
    protected readonly bookingState: BookingFlowStateService,
    protected readonly navigationService: BookingFlowNavigationService,
    protected readonly dialogService: BookingFlowDialogService
  ) {}

  canDeactivate(nextState: RouterStateSnapshot): CanDeactivateType {
    return this.userConfirmedExit ||
      this.navigationService.isBookingFlowRoute(nextState.url) ||
      this.dialogService.showConfirmExitBookingFlowDialog();
  }

  exitBookingFlow = (shouldAskForConfirmation: boolean = true): void => {
    this.navigationService.exitBookingFlow(shouldAskForConfirmation)
      .pipe(untilDestroyed(this))
      .subscribe((confirm: boolean): void => {
        this.userConfirmedExit = confirm;
      });
  };

  protected updatePendingBooking(): void {
    this.updatePendingBookingSubscription?.unsubscribe();
    this.updatePendingBookingSubscription = this.bookingState.syncToServer$()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
