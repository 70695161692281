import { AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild } from '@angular/core';

@Component({
  selector: 'vh-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('container') containerElement: ElementRef;
  @ViewChild('content') contentElement: ElementRef;

  @Input() hasHorizontalSpacing: boolean;
  @Input() hasVerticalSpacing: boolean;
  @Input() maxWidth: number | string;
  @Input() isHorizontallyCenterAligned: boolean;
  @Input() isVerticallyCenterAligned: boolean;
  @Input() isDisabled: boolean;

  spacingClassName: string;
  contentMargin: string;

  private resizeObserver: ResizeObserver;

  @Output() layoutScroll: EventEmitter<HTMLElement>;
  @Output() layoutResize: EventEmitter<HTMLElement>;

  constructor(private ngZone: NgZone) {
    // Defaults
    this.isHorizontallyCenterAligned = true;

    this.layoutScroll = new EventEmitter<HTMLElement>();
    this.layoutResize = new EventEmitter<HTMLElement>();
  }

  ngOnInit(): void {
    if (this.hasHorizontalSpacing) {
      this.spacingClassName = 'horizontal-spacing';
    }

    if (this.hasVerticalSpacing) {
      this.spacingClassName = 'vertical-spacing';
    }

    if (this.hasHorizontalSpacing && this.hasVerticalSpacing) {
      this.spacingClassName = 'spacing';
    }

    if (this.maxWidth) {
      this.maxWidth = `var(--${this.maxWidth}px)`;
    }

    if (this.isHorizontallyCenterAligned) {
      this.contentMargin = '0 auto';
    }

    if (this.isVerticallyCenterAligned) {
      this.contentMargin = 'auto 0';
    }

    if (this.isHorizontallyCenterAligned && this.isVerticallyCenterAligned) {
      this.contentMargin = 'auto';
    }
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new window.ResizeObserver((): void => {
      this.ngZone.run((): void => {
        this.layoutResize.emit(this.containerElement.nativeElement);
      });
    });

    this.resizeObserver.observe(this.contentElement.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const maxWidthChange: SimpleChange = changes.maxWidth;

    if (maxWidthChange && !maxWidthChange.firstChange && maxWidthChange.currentValue !== maxWidthChange.previousValue) {
      this.maxWidth = maxWidthChange.currentValue ? `var(--${maxWidthChange.currentValue}px)` : null;
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.contentElement.nativeElement);
  }

  handleScroll = (): void => {
    this.layoutScroll.emit(this.containerElement.nativeElement);
  };
}
