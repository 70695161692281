<vh-base-card [isLoading]="isLoading">
  <div class="flex-row">
    <vh-date-card
      [skeletonIsEmpty]="skeletonIsEmpty"
      [date]="slots?.start"
      [layout]="dateCardLayout"
      [backgroundColor]="dateCardBackgroundColor"
      [getBackgroundColorFunction]="getDateCardBackgroundColorFunction">
    </vh-date-card>
    <vh-timeline [items]="timelineLabels"></vh-timeline>
  </div>
</vh-base-card>
