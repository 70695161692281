<vh-back-button (buttonClick)="backToSelectPatient()"></vh-back-button>

<vh-conversation-info
  [patient]="user"
  [email]="user.email ?? conversationService.email"
  [phone]="user.phone ?? conversationService.phone">
</vh-conversation-info>

<div class="form-fields flex-col gap-v-16">
  <vh-suggestions-text-input
    [loading]="isLoadingDoctors"
    [value]="conversationService.doctor?.id"
    [placeholder]="conversationService.doctor?.fullName ?? 'conversations.create.planning.physicianPlaceholder' | translate"
    [allowSuggestionsOnly]="true"
    [suggestionOnClick]="true"
    [getOptionsFunction$]="getDoctorSuggestions$"
    [label]="'conversations.create.planning.physician' | translate"
    (inputValueChange)="onDoctorChange($event)">
  </vh-suggestions-text-input>

  <vh-suggestions-text-input
    [loading]="isLoadingQuestionLists"
    [value]="conversationService.questionList?.id"
    [placeholder]="conversationService.questionList?.title ?? 'conversations.create.planning.questionListPlaceholder' | translate"
    [allowSuggestionsOnly]="true"
    [suggestionOnClick]="true"
    [getOptionsFunction$]="getQuestionListSuggestions$"
    [label]="'conversations.create.planning.questionList' | translate"
    (inputValueChange)="onQuestionListChange($event)">
  </vh-suggestions-text-input>

  <vh-suggestions-text-input
    [value]="conversationService.language ?? conversationService.user.language"
    [placeholder]="'conversations.create.planning.languagePlaceholder' | translate"
    [allowSuggestionsOnly]="true"
    [suggestionOnClick]="true"
    [getOptionsFunction$]="getLanguageSuggestions$"
    [label]="'common.fields.language' | translate"
    (inputValueChange)="onLanguageSelected($event)">
  </vh-suggestions-text-input>

  <div>
    <div class="flex-row-l-c gap-h-4">
      <label class="font-regular-18-22">{{'conversations.create.planning.dueDate' | translate}}</label>
      <vh-icon
        [src]="'assets/icons/question-circle.svg'"
        [matTooltip]="'conversations.create.planning.dueDateInfo' | translate"
        width="18"
        height="18"
        [fill]="COLORS.secondary">
      </vh-icon>
    </div>
    <vh-text-input
      type="datetime-local"
      [value]="selectedDueDate"
      [min]="currentDate"
      (inputValueChange)="selectedDueDate = $event">
    </vh-text-input>
  </div>
</div>

<vh-button
  [label]="'common.next' | translate"
  [isDisabled]="!selectedDoctor || !selectedQuestionList"
  [vhMarginTop]="32"
  (buttonClick)="goToNextStep()"
  [centered]="true"
  [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
  [iconRightWidth]="20"
  [iconRightHeight]="20"
  [iconRightStroke]="COLORS.white">
</vh-button>
