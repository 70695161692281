import { Component, Inject } from '@angular/core';
import { Room } from '@models/room.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COLORS } from '@constants/colors.constant';

export interface ExportPlanningDialogData {
  room: Room;
}

@Component({
  selector: 'vh-export-planning-dialog',
  templateUrl: './export-planning-dialog.component.html',
  styleUrls: ['export-planning-dialog.component.scss'],
})
export class ExportPlanningDialogComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  room: Room;

  constructor(
    protected readonly dialogRef: MatDialogRef<ExportPlanningDialogData>,
    @Inject(MAT_DIALOG_DATA) data: ExportPlanningDialogData
  ) {
    this.room = data.room;
  }
}
