import { Directive, ElementRef, NgZone } from '@angular/core';
import { Institution } from '@models/institution.model';
import { InstitutionTrackingEventService } from '@services/tracking/institution-tracking-event.service';
import { AbstractTrackerDirective } from '@modules/shared/core/directives/abstract-tracker.directive';

@Directive({
  selector: '[vhInstitutionTracker]',
})
export class InstitutionTrackerDirective extends AbstractTrackerDirective<Institution> {
  // eslint thinks this constructor is useless, but it's not. It injects the correct instance of the
  // trackingService which inherits from AbstractTrackingService<T>
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(el: ElementRef<HTMLElement>, ngZone: NgZone, trackingService: InstitutionTrackingEventService) {
    super(el, ngZone, trackingService);
  }
}
