import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { arrayWrap } from '@utils/helpers/array.util';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { AuthenticationService } from '@services/authentication.service';

export const inaccessibleOnEnvironmentGuard = (inaccessibleEnvironment: string | string[]): CanActivateFn => {
  inaccessibleEnvironment = arrayWrap(inaccessibleEnvironment);

  return (): boolean => {
    const router: Router = inject(Router);
    const authenticationService: AuthenticationService = inject(AuthenticationService);

    if (inaccessibleEnvironment.includes(environment.name)) {
      redirectToMostRelevantHome(authenticationService, router);

      return false;
    }

    return true;
  };
};
