import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Institution } from '@models/institution.model';
import { Service } from '@models/service.model';
import { COLORS } from '@constants/colors.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

@Component({
  selector: 'vh-institution-card',
  templateUrl: './institution-card.component.html',
  styleUrls: ['./institution-card.component.scss'],
})
export class InstitutionCardComponent extends BreakpointObserverComponent implements OnInit {
  COLORS: typeof COLORS = COLORS;

  @Input() skeletonIsEmpty: boolean;
  @Input() emptySkeletonMessage: string;
  @Input() institution: Institution;

  @Output() moreInfoClick: EventEmitter<Institution> = new EventEmitter<Institution>();

  modalityOptions: ButtonOption[];

  ngOnInit(): void {
    this.modalityOptions = this.convertModalitiesToButtonOptions();
  }

  private convertModalitiesToButtonOptions(): ButtonOption[] {
    return this.institution.modalities.map((m: Service) => {
      return {
        ...m.toButtonOption(true),
        isClickable: false,
        isActive: true,
      };
    });
  }
}
