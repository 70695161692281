import { Option } from '@interfaces/option.interface';
import { AssignableIcon } from '@enums/assignable-icon.enum';

export class VisitStatus {
  static readonly EXECUTED: string = 'executed';

  constructor(
    private readonly _id: string,
    private readonly _name: string,
    private readonly _key: string,
    private readonly _icon: AssignableIcon,
    private readonly _createdAt: Date
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get key(): string {
    return this._key;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get icon(): AssignableIcon {
    return this._icon;
  }

  static fromJson(json: VisitStatusJson): VisitStatus {
    return json
      ? new VisitStatus(
        json.id,
        json.name,
        json.key,
        json.icon_name ? AssignableIcon[json.icon_name.toUpperCase()] : null,
        new Date(json.created_at)
      )
      : null;
  }

  toOption(): Option {
    return {
      value: this.id,
      label: this.name,
      icon: this.icon,
    };
  }
}

export interface VisitStatusJson {
  id: string;
  name: string;
  key: string;
  icon_name?: string | null;
  created_at: string;
}
