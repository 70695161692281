<div class="flex-col-c-c gap-v-16">
  <vh-icon
    width="64"
    height="64"
    [src]="isSuccessful ? SUCCESS_ICON_PATH : FAILED_ICON_PATH"
    [stroke]="isSuccessful ? COLORS.success : COLORS.error">
  </vh-icon>
  <h1>{{title}}</h1>
  <p>{{message}}</p>
  <ng-content></ng-content>
</div>