import { Pipe, PipeTransform } from '@angular/core';
import { minutesToTimeString } from '@utils/helpers/date.util';

@Pipe({
  name: 'vhMinutesToTimeString',
})
export class MinutesToTimeStringPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value) || value <= 0) {
      return '';
    }

    return minutesToTimeString(value);
  }
}
