<div class="flex-col-r-s container">
  <vh-message
    *ngIf="status === 'waitingForConfirmation'"
    [vhTextColor]="'var(--color-tertiary)'"
    [iconLeftPath]="'assets/icons/message.svg'"
    [iconLeftWidth]="20"
    [text]="'pages.dashboard.waitingForConfirmationLabel' | translate"></vh-message>
  <vh-message
    *ngIf="status === 'everythingIsProvided'"
    [vhTextColor]="'var(--color-tertiary)'"
    [iconLeftPath]="'assets/icons/file-check-alt.svg'"
    [iconLeftWidth]="14"
    [text]="'pages.dashboard.everythingIsProvidedLabel' | translate"
    (messageClick)="navigateToAppointmentTasksPage()">
  </vh-message>
  <vh-message
    *ngIf="status === 'missingData'"
    [vhTextColor]="'var(--color-alert)'"
    [iconLeftPath]="'assets/icons/file-times-alt.svg'"
    [iconLeftFill]="COLORS.error"
    [iconLeftWidth]="14"
    [text]="'pages.dashboard.missingDataLabel' | translate : { amount: amountTasksNotCompleted, total: totalAmountTasks }"
    (messageClick)="navigateToAppointmentTasksPage()">
  </vh-message>
  <!--  <vh-message-->
  <!--    [vhTextColor]="'var(&#45;&#45;color-primary)'"-->
  <!--    [iconRightPath]="'assets/icons/arrow-right-primary.svg'"-->
  <!--    [iconRightWidth]="8"-->
  <!--    [text]="'pages.dashboard.moreInfoLabel' | translate"-->
  <!--    (onClick)="navigateToAppointmentDetailsPage()"-->
  <!--  ></vh-message>-->
</div>
