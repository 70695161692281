import { Injectable } from '@angular/core';
import { UserService } from '@services/user.service';
import { Observable, of } from 'rxjs';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { AuthenticationService } from '@services/authentication.service';
import { SESSION_KEYS } from '@constants/local-storage-keys.constant';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyService {
  constructor(
    private readonly userService: UserService,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  shouldShowPrivacyPolicy$(): Observable<boolean> {
    if (this.authenticationService.isLoggedIn()) {
      return of(!this.authenticationService.currentUser.hasAcceptedPrivacyPolicy);
    }

    return of(!this.hasAcceptedPrivacyPolicyDuringSession());
  }

  acceptPrivacyPolicy$(userid: string = null): Observable<void> {
    if (!userid || !this.authenticationService.isLoggedIn()) {
      this.setAcceptedPrivacyPolicyDuringSession();

      return of(null);
    }

    return this.userService.updateUser$(userid, { 'accepted_privacy_policy': true })
      .pipe(tap(() => {
        this.setAcceptedPrivacyPolicyDuringSession();
      }))
      .pipe(switchToEmptyObservable());
  }

  private hasAcceptedPrivacyPolicyDuringSession(): boolean {
    return sessionStorage.getItem(SESSION_KEYS.acceptedPrivacyPolicy) === 'true';
  }

  private setAcceptedPrivacyPolicyDuringSession(): void {
    sessionStorage.setItem(SESSION_KEYS.acceptedPrivacyPolicy, 'true');
  }
}
