import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Specialty, SpecialtyJson } from '@models/specialty.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class SpecialtyService {
  constructor(private readonly http: HttpClient) {
  }

  getSpecialties$(): Observable<Specialty[]> {
    const endpoint: string = ENDPOINTS.getSpecialties.route;

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: SpecialtyJson[]): Specialty[] => json.map(Specialty.fromJson)));
  }
}
