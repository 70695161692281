<div class="flex-row card content-block-padding">
  <div>
    <h2>{{transaction.titleKey | translate : { 'name': transaction.conversation?.patient?.fullName } }}</h2>
    <p class="has-text-tertiary" [vhMarginTop]="8">{{transaction.createdAt | dfnsFormat: DATE_FORMATS.clientDate}}</p>
  </div>
  <div class="align-end">
    <p
      class="font-regular-18-22"
      [ngClass]="{
        'has-text-primary' : transaction.isOutgoing,
        'has-text-success' : !transaction.isOutgoing
      }">
      {{transaction.displayAmount}}
    </p>
    <p class="has-text-tertiary"
       [vhMarginTop]="8">
      {{ 'wallet.history.newBalanceLabel' | translate : {'balance' : transaction.newBalance } }}
    </p>
  </div>
</div>
