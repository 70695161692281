/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const RESET_PASSWORD_FORM_KEYS: Map<string, string> = new Map();
RESET_PASSWORD_FORM_KEYS.set('newPassword', 'password');
// Client form key
RESET_PASSWORD_FORM_KEYS.set('repeatedPassword', 'repeatedPassword');
