export const REGEX = {
  clientDate: '^([0-2][0-9]|(3)[0-1])(\\/)(((0)[0-9])|((1)[0-2]))(\\/)\\d{4}$',
  phoneBelgium: '^\\+( ?\\d){10,11}$',
  socialSecurityNumber: '^[0-9]{2}[.\\- ]{0,1}[0-9]{2}[.\\- ]{0,1}[0-9]{2}[.\\- ]{0,1}[0-9]{3}[.\\- ]{0,1}[0-9]{2}$',
  uuidV4: '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}',
  username: '/^[a-zA-Z0-9\\-_\\.]+$/',
  rizivNumber: '^[0-9]{1}-[0-9]{5}-[0-9]{2}-[0-9]{3}$',
  password: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  eightCharacters: '^.{8,}$',
  oneUppercaseLetter: '^(?=.*?[A-Z])',
  oneLowercaseLetter: '^(?=.*?[a-z])',
  oneNumber: '^(?=.*?[0-9])',
};
