import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { DoctorOnboardingState } from '@enums/doctor-onboarding-state.enum';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { User } from '@models/user.model';

/**
 * This guard checks if the user is authenticated before allowing them to access a page.
 */
export const authenticationGuard = (): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);

    if (!authenticationService.isLoggedIn()) {
      if (router.url !== NAVIGATION.login.route) {
        void router.navigate([NAVIGATION.login.route]);
      }

      return false;
    }

    return checkOnboardingState(state, authenticationService, router);
  };
};

/**
 * This function checks the user's onboarding state and navigates to the appropriate route if needed.
 */
function checkOnboardingState(state: RouterStateSnapshot, authenticationService: AuthenticationService, router: Router): boolean {
  const currentUser: User = authenticationService.currentUser;
  const currentUrl: string = router.url;
  const targetUrl: string = state.url;

  if (currentUser.doctorOnboardingState === null || currentUser.doctorOnboardingState === DoctorOnboardingState.DONE) {
    return true;
  }

  switch (currentUser.doctorOnboardingState) {
    case DoctorOnboardingState.REGISTERED:
      if (targetUrl !== ONBOARDING_NAV.createInstitution.route && currentUrl !== ONBOARDING_NAV.createInstitution.route) {
        void router.navigate([ONBOARDING_NAV.createInstitution.route]);

        return false;
      }

      return true;
    case DoctorOnboardingState.INSTITUTION_CREATED:
      if (targetUrl !== ONBOARDING_NAV.finishingUp.route && currentUrl !== ONBOARDING_NAV.finishingUp.route) {
        void router.navigate([ONBOARDING_NAV.finishingUp.route]);

        return false;
      }

      return true;
    case DoctorOnboardingState.PAYMENT_OPTION_ADDED:
      if (targetUrl !== ONBOARDING_NAV.finishingUp.route && currentUrl !== ONBOARDING_NAV.finishingUp.route) {
        void router.navigate([ONBOARDING_NAV.finishingUp.route]);

        return false;
      }

      return true;
    default:
      return true;
  }
}

