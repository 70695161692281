import { AfterContentInit, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '@modules/shared/form/components/checkbox/checkbox.component';

@Component({
  selector: 'vh-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends CheckboxComponent implements AfterContentInit {
  performedInit: boolean = false;

  @Input() changeValueOnClick: boolean = true;

  ngAfterContentInit(): void {
    this.performedInit = true;
  }

  handleInputClick(): void {
    if (!this.changeValueOnClick) {
      return;
    }

    super.handleInputClick();
  }
}
