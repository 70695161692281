import { trigger } from '@angular/animations';
import { fadeOutTransition } from '@modules/shared/core/animations/fade/fade-out.animation';
import { fadeInTransition } from '@modules/shared/core/animations/fade/fade-in.animation';

export const fadeInOutAnimation = trigger(
  'fadeInOutAnimation',
  [
    fadeInTransition,
    fadeOutTransition,
  ]
);
