export interface Preferences {
  calendar: {
    zoom: number;
  };
}

export const defaultPreferences: Preferences = {
  calendar: {
    zoom: 5,
  },
};
