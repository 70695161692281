import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { Mode } from '@enums/mode.enum';
import { authenticationGuard } from '@guards/authentication.guard';
import { modeGuard } from '@guards/mode.guard';
import { PrivacyStatementModule } from '@modules/public/privacy-statement/privacy-statement.module';
import { FormModule } from '@modules/shared/form/form.module';
import { FormatPipeModule } from 'ngx-date-fns';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { MatDialogActions, MatDialogContent, MatDialogModule } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ConversationsOverviewPageComponent } from '@modules/conversation/pages/conversations-overview-page/conversations-overview-page.component';
import { ConversationPageComponent } from '@modules/conversation/pages/conversation-page/conversation-page.component';
import { BaseCreateConversationPageComponent } from '@modules/conversation/pages/base-create-conversation-page/base-create-conversation-page.component';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { CreateConversationSelectPatientPageComponent } from '@modules/conversation/pages/create-conversation-select-patient-page/create-conversation-select-patient-page.component';
import { CreateConversationMissingInfoPageComponent } from '@modules/conversation/pages/create-conversation-missing-info-page/create-conversation-missing-info-page.component';
import { CreateConversationNewPatientPageComponent } from '@modules/conversation/pages/create-conversation-new-patient-page/create-conversation-new-patient-page.component';
import { CreateConversationPlanningPageComponent } from '@modules/conversation/pages/create-conversation-planning-page/create-conversation-planning-page.component';
import { CreateConversationConfirmationPageComponent } from '@modules/conversation/pages/create-conversation-confirmation-page/create-conversation-confirmation-page.component';
import { ConversationDetailsPageComponent } from '@modules/conversation/pages/conversation-details-page/conversation-details-page.component';
import { ConversationCardComponent } from '@modules/conversation/components/conversation-card/conversation-card.component';
import { ConversationInfoComponent } from '@modules/conversation/components/conversation-info/conversation-info.component';
import { ConversationSummaryComponent } from '@modules/conversation/components/conversation-summary/conversation-summary.component';
import { ConversationsByDayComponent } from '@modules/conversation/components/conversations-by-day/conversations-by-day.component';
import { ConversationsListComponent } from '@modules/conversation/components/conversations-list/conversations-list.component';
import { ConversationStatusComponent } from '@modules/conversation/components/conversation-status/conversation-status.component';
import { TranscriptDialogComponent } from '@modules/conversation/dialogs/transcript-dialog/transcript-dialog.component';
import { ConversationSharedModule } from '@modules/conversation/conversation-shared.module';
import { CdkMenuModule } from '@angular/cdk/menu';
import { LogbookDialogComponent } from '@modules/conversation/dialogs/logbook-dialog/logbook-dialog.component';
import { ConversationActionMenuComponent } from '@modules/conversation/components/conversation-action-menu/conversation-action-menu.component';
import { SplitButtonModule } from 'primeng/splitbutton';

const routes: Routes = [
  {
    path: NAVIGATION.dashboard.path,
    component: ConversationsOverviewPageComponent,
    canActivate: [authenticationGuard(), modeGuard(Mode.PRIVATE)],
  },
  {
    path: CONVERSATION_NAV.conversation.path,
    component: ConversationPageComponent,
    // canActivate: [authenticationGuard(), modeGuard(Mode.PRIVATE)],
  },
  {
    path: CONVERSATION_NAV.create.path,
    component: BaseCreateConversationPageComponent,
    // canActivate: [authenticationGuard(), modeGuard(Mode.PRIVATE)],
    children: [
      {
        path: '',
        redirectTo: CONVERSATION_NAV.create.selectPatient.path,
        pathMatch: 'full',
      },
      {
        path: CONVERSATION_NAV.create.selectPatient.path,
        component: CreateConversationSelectPatientPageComponent,
      },
      {
        path: CONVERSATION_NAV.create.missingInfo.path,
        component: CreateConversationMissingInfoPageComponent,
      },
      {
        path: CONVERSATION_NAV.create.newPatient.path,
        component: CreateConversationNewPatientPageComponent,
      },
      {
        path: CONVERSATION_NAV.create.planning.path,
        component: CreateConversationPlanningPageComponent,
      },
      {
        path: CONVERSATION_NAV.create.confirm.path,
        component: CreateConversationConfirmationPageComponent,
      },
    ],
  },
  {
    path: CONVERSATION_NAV.details.path,
    component: ConversationDetailsPageComponent,
    // canActivate: [authenticationGuard(), modeGuard(Mode.PRIVATE)],
  },
];

@NgModule({
  declarations: [
    ConversationsOverviewPageComponent,
    ConversationPageComponent,
    ConversationDetailsPageComponent,
    ConversationCardComponent,
    ConversationInfoComponent,
    ConversationSummaryComponent,
    ConversationActionMenuComponent,
    ConversationsByDayComponent,
    ConversationsListComponent,
    ConversationStatusComponent,
    BaseCreateConversationPageComponent,
    CreateConversationSelectPatientPageComponent,
    CreateConversationMissingInfoPageComponent,
    CreateConversationNewPatientPageComponent,
    CreateConversationPlanningPageComponent,
    CreateConversationConfirmationPageComponent,
    TranscriptDialogComponent,
    LogbookDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    ConversationSharedModule,
    FormModule,
    PrivacyStatementModule,
    FormatPipeModule,
    InfiniteScrollDirective,
    AccountSharedModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogModule,
    AccountSharedModule,
    MatTooltip,
    CdkMenuModule,
    SplitButtonModule,
  ],
})
export class ConversationModule { }
