import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[vhFontClass]',
})
export class FontClassDirective implements OnInit {
  @Input() vhFontClass: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.element.nativeElement.classList.add(this.vhFontClass);
  }
}
