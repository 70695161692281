<vh-primary-page [navigationItems]="navigationItems" [hasVerticalContentSpacing]="true" [hasHorizontalContentSpacing]="true"
  [profileTemplate]="profileTemplate">
  <div class="grid-container">
    <div class="header">
      <div class="title-with-add flex-row gap-h-8">
        <h1>{{ 'conversations.title' | translate }}</h1>
        <button
          [disabled]="currentUserTokens < 1"
          class="add-button clickable"
          [class.disabled]="currentUserTokens < 1"
          [matTooltip]="currentUserTokens < 1 ? ('navigationBar.noTokens' | translate) : ('conversations.new' | translate)"
          (click)="addConversation()">
          +
      </button>
      </div>

      <div class="flex-row gap-h-8" [class.filter-header]="this.filter.hasActiveFilters()">
        <vh-search-input
          [value]="filter.patient"
          [placeholder]="'conversations.filters.placeholder' | translate"
          (inputValueChange)="onSearchChange($event)">
        </vh-search-input>

        <vh-button
          alignment="center"
          [iconLeftPath]="'assets/icons/filter.svg'"
          [iconLeftHeight]="16"
          [iconLeftWidth]="16"
          [iconLeftHoverFill]="filterBarIsOpened ? COLORS.white : COLORS.secondary"
          [iconLeftFill]="filterBarIsOpened ? COLORS.white : COLORS.secondary"
          [style]="filterBarIsOpened ? ButtonStyle.SQUARE : ButtonStyle.INACTIVE_SQUARE"
          (buttonClick)="filterBarIsOpened = !filterBarIsOpened">
        </vh-button>

        <div *ngIf="this.filter.hasActiveFilters()"></div>
      </div>
    </div>

    <vh-icon
      *ngIf="filterBarIsOpened"
      src="assets/icons/angle-down.svg"
      width="16"
      height="16"
      [fill]="COLORS.secondary">
    </vh-icon>

    <div class="font-medium-18-22 filters" *ngIf="filterBarIsOpened">
      <div class="flex-row-l-c gap-h-8">
        <h2>{{ 'conversations.filters.title' | translate }}</h2>
        <vh-icon
          class="clickable"
          src="assets/icons/rotate.svg"
          (click)="resetFilters()">
        </vh-icon>
      </div>

      <div class="flex-row gap-h-16 font-light-16-19" [vhMarginTop]="16">
        <div class="gap-v-4">
          <label>{{ 'conversations.filters.physician' | translate }}</label>
          <vh-select-input
            [hasSelectAll]='true'
            [defaultValue]="filter.doctorId || 'all'"
            [options]="doctors"
            (inputValueChange)="onDoctorSelected($event)">
          </vh-select-input>
        </div>

        <div class="gap-v-4">
          <label>{{ 'conversations.filters.includeDeleted' | translate }}</label>
          <vh-select-input
            [defaultValue]="filter.includeDeleted ? 'yes' : 'no'"
            [options]="includeDeleted"
            (inputValueChange)="onIncludeDeletedSelected($event)">
          </vh-select-input>
        </div>

        <div class="gap-v-4">
          <label>{{ 'conversations.filters.status' | translate }}</label>
          <vh-select-input
            [hasSelectAll]='true'
            [defaultValue]="filter.status || 'all'"
            [options]="statuses"
            (inputValueChange)="onStatusSelected($event)">
          </vh-select-input>
        </div>

        <div class="gap-v-4">
          <label>{{ 'conversations.filters.questionList' | translate }}</label>
          <vh-select-input
            [hasSelectAll]='true'
            [defaultValue]="filter.questionList || 'all'"
            [options]="questionLists"
            (inputValueChange)="onQuestionListSelected($event)">
          </vh-select-input>
        </div>
      </div>
    </div>

    <div class="flex-row-c-s" [vhMarginTop]="24">
      <div *ngIf="filter.displayMode === ConversationDisplay.BY_DAY" class="flex-row-c-c gap-h-16">
        <vh-icon
          class="clickable"
          src="assets/icons/arrow-left-secondary.svg"
          (click)="updateSelectedDate(-1)">
        </vh-icon>
        <vh-text-input
          type="date"
          [value]="selectedDate | date: DATE_FORMATS.serverDate"
          [isDisabled]
          (inputValueChange)="setSelectedDate($event)">
        </vh-text-input>
        <vh-icon
          class="clickable"
          src="assets/icons/arrow-right-secondary.svg"
          (click)="updateSelectedDate(1)">
        </vh-icon>
      </div>

      <vh-button-group
        class="align-end"
        [singleItemSelectable]="true"
        [items]="toggleModeButtonOptions"
        [style]="ButtonGroupStyle.CONNECTED"
        (itemClick)="toggleMode($event)">
      </vh-button-group>
    </div>

    <div class="scroll-container">
      <vh-conversations-by-day
        *ngIf="filter.displayMode === ConversationDisplay.BY_DAY"
        [conversations]="conversations"
        [loading]="loading"
        [clickConversation]="goToDetails.bind(this)"
        infiniteScroll
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        [infiniteScrollDistance]="1">
      </vh-conversations-by-day>

      <vh-conversations-list
        *ngIf="filter.displayMode === ConversationDisplay.AS_LIST"
        [conversations]="conversations"
        [loading]="loading"
        [clickConversation]="goToDetails.bind(this)"
        infiniteScroll
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        [infiniteScrollDistance]="1">
      </vh-conversations-list>
    </div>
  </div>
</vh-primary-page>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
