<vh-primary-page [navigationItems]="navigationItems" [profileTemplate]="profileTemplate">
  <vh-message
    class="font-regular-18-22"
    [ngClass]="{ clickable: !isLoading, 'not-clickable': isLoading }"
    [text]="getTitleKey() | translate"
    [iconLeftPath]="isLoading ? 'assets/icons/hourglass.svg' : 'assets/icons/arrow-left.svg'"
    [iconLeftFill]="COLORS.secondary"
    [iconLeftAlt]="getTitleKey() | translate"
    (messageClick)="goBack()">
  </vh-message>

  <ng-container *ngIf="!isLoading" [ngTemplateOutlet]="contentTemplate"></ng-container>
  <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeletonTemplate"></ng-container>
</vh-primary-page>

<ng-template #contentTemplate>
  <form [formGroup]="formGroup" (ngSubmit)="saveConfiguration()">
    <div class="flex-row-r-c">
      <vh-button
        *ngIf="formGroup.dirty"
        [style]="ButtonStyle.TERTIARY"
        [label]="'common.cancel' | translate"
        [alignment]="'right'"
        (buttonClick)="cancel()"
        [isDisabled]="!formGroup.dirty || !!createOrUpdateRoomSubscription">
      </vh-button>
      <vh-button
        *ngIf="formGroup.dirty"
        [label]="'common.save' | translate"
        [alignment]="'right'"
        [isDisabled]="!formGroup.dirty || formGroup.invalid || !!createOrUpdateRoomSubscription"
        (buttonClick)="saveConfiguration()">
      </vh-button>
    </div>
    <div class="flex-row-c-n clickable has-text-error font-regular-18-22" *ngIf="selectedRoom">
      <vh-message
        iconLeftPath="assets/icons/trash-alt.svg"
        [isDisabled]="!currentUser.hasRole(Role.ADMIN)"
        [matTooltip]="(!currentUser.hasRole(Role.ADMIN) ? 'admin.pages.slotsOverview.roomsPage.adminDeleteDisclaimer' : '') | translate"
        [iconLeftFill]="COLORS.error"
        [text]="'admin.pages.slotsOverview.roomsPage.deleteRoom' | translate"
        (messageClick)="deleteRoom()">
      </vh-message>
    </div>
    <div [vhMarginTop]="16" class="input-fields-container">
      <p>{{ 'admin.pages.slotsOverview.roomsPage.name' | translate }}</p>
      <vh-text-input class="input" [formControlName]="CREATE_ROOM_FORM_KEYS.get('name')" [placeholder]="'MRI_D1'"> </vh-text-input>
      <p>{{ 'admin.pages.slotsOverview.roomsPage.description' | translate }}</p>
      <vh-text-input class="input" [formControlName]="CREATE_ROOM_FORM_KEYS.get('description')"> </vh-text-input>
      <p>{{ 'admin.pages.slotsOverview.roomsPage.color' | translate }}</p>
      <vh-color-picker class="input" [formControlName]="CREATE_ROOM_FORM_KEYS.get('color')"> </vh-color-picker>
      <p>{{ 'admin.pages.slotsOverview.roomsPage.type' | translate }}</p>
      <div class="option-container">
        <vh-select-input
          class="input"
          [formControlName]="CREATE_ROOM_FORM_KEYS.get('modality')"
          [placeholder]="'admin.pages.slotsOverview.roomsPage.selectModality' | translate"
          [options]="allModalityOptions">
        </vh-select-input>
      </div>
    </div>
  </form>

  <h1 [vhMarginTop]="32">{{ 'admin.pages.slotsOverview.roomsPage.serviceGroups' | translate }}</h1>
  <div class="flex-col-l-c">
    <div class="flex-row gap-h-32 service-groups-container" [vhMarginTop]="8">
      <div class="flex-col gap-v-16">
        <vh-assign-service-group-to-room
          class="card"
          *ngFor="let controlGroup of serviceGroupFormArray?.controls; let i = index"
          [options]="filteredServiceGroupOptions"
          [formGroup]="controlGroup"
          (deleteItemClick)="removeServiceGroup(controlGroup)"
          (serviceGroupSelected)="updateServiceGroup($event)">
        </vh-assign-service-group-to-room>
      </div>
      <vh-human-body [serviceOrServices]="selectedRoom?.services" class="align-end flex-grow"></vh-human-body>
    </div>

    <vh-button
      *ngIf="serviceGroupFormArray && allServiceGroupOptions && allServiceGroupOptions.length !== serviceGroupFormArray.length"
      class="align-center"
      [vhMarginTop]="32"
      [style]="ButtonStyle.QUATERNARY"
      [label]="'admin.pages.slotsOverview.roomsPage.add' | translate"
      [iconLeftPath]="'assets/icons/plus.svg'"
      [alignment]="'center'"
      (buttonClick)="addServiceGroup()">
    </vh-button>
  </div>
</ng-template>

<ng-template #skeletonTemplate>
  <div class="input-fields-container skeleton-input-fields-container" [vhMarginTop]="64">
    <div *ngFor="let i of [].constructor(8)" class="field-skeleton" vhSkeleton [skeletonIsEmpty]="false" [skeletonIsVisible]="true"></div>
  </div>
  <div class="service-groups-container" [vhMarginTop]="64">
    <div
      *ngFor="let i of [].constructor(2)"
      class="service-group-skeleton"
      vhSkeleton
      [skeletonIsEmpty]="false"
      [skeletonIsVisible]="true"></div>
  </div>
</ng-template>

<ng-template #profileTemplate>
  <vh-navigation-profile></vh-navigation-profile>
</ng-template>
