export class Bucket {
  constructor(
    private _id: string,
    private _baseTokens: number,
    private _additionalTokens: number,
    private _price: number
  ) { }

  get id(): string {
    return this._id;
  }

  get baseTokens(): number {
    return this._baseTokens;
  }

  get additionalTokens(): number {
    return this._additionalTokens;
  }

  get totalTokens(): number {
    return this._baseTokens + this._additionalTokens;
  }

  get price(): number {
    return this._price;
  }

  static fromJson(json: BucketJson): Bucket {
    return json
      ? new Bucket(
        json.id,
        json.baseTokens,
        json.additionalTokens,
        json.price
      )
      : null;
  }
}

export interface BucketJson {
  id: string;
  baseTokens: number;
  additionalTokens: number;
  price: number;
}
