<div class="flex-col">
  <div class="flex-row gap-h-8" (click)="handleInputClick()">
    <label *ngIf="label" [innerHTML]="label"></label>
    <div class="switch">
      <input
        type="checkbox"
        [ngClass]="{
        clickable: !isDisabled,
        'switch-checked': !!value,
        'not-clickable': isDisabled
      }"
        [checked]="!!value"
        [disabled]="isDisabled" />
      <span class="slider round" [ngClass]="{ 'slider-animated': performedInit }"></span>
    </div>
  </div>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }"
    [innerHTML]="message">
  </p>
</div>


