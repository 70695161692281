/*
 * Values must match with the API
 */
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum RecurrenceFrequency {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const recurrenceFrequencyOptionsSingular$ = (translate: TranslateService): Observable<Option[]> => {
  return recurrenceFrequencyOptions$('admin.pages.slotsOverview.popup.frequencyOptions.singular.', translate);
};

export const recurrenceFrequencyOptionsPlural$ = (translate: TranslateService): Observable<Option[]> => {
  return recurrenceFrequencyOptions$('admin.pages.slotsOverview.popup.frequencyOptions.plural.', translate);
};

const recurrenceFrequencyOptions$ = (keyPrefix: string, translate: TranslateService): Observable<Option[]> => {
  const types = Object.keys(RecurrenceFrequency).filter((key: string) => key !== RecurrenceFrequency.NONE.toString());
  const observables = types.map((type: RecurrenceFrequency) => translate.get(keyPrefix + type));

  return combineLatest(observables)
    .pipe(map((labels: string[]): Option[] => {
      return labels.map((label: string, index: number) => {
        return {
          label: label,
          value: types[index],
        };
      });
    }));
};
