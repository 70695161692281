import { Component, Input } from '@angular/core';

@Component({
  selector: 'vh-skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['./skeleton-table.component.scss'],
})
export class SkeletonTableComponent {
  @Input() amountOfRows: number = 3;
  @Input() amountOfColumns: number = 3;
  @Input() rowHeight: number | string = '50px';
  @Input() showHeader: boolean = true;
}
