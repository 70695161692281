import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormModule } from '@modules/shared/form/form.module';
import { BucketsOverviewPageComponent } from '@modules/payment/pages/buckets-overview-page/buckets-overview-page.component';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';
import { CoreModule } from '@modules/shared/core/core.module';
import { PaymentSuccessPageComponent } from '@modules/payment/pages/payment-success-page/payment-success-page.component';
import { PaymentFailedPageComponent } from '@modules/payment/pages/payment-failed-page/payment-failed-page.component';
import { PaymentCancelledPageComponent } from '@modules/payment/pages/payment-cancelled-page/payment-cancelled-page.component';
import { orderResolver } from '@modules/payment/resolvers/order.resolver';
import { AutomaticPaymentSetupSuccessPageComponent } from '@modules/payment/pages/automatic-payment-setup-success-page/automatic-payment-setup-success-page.component';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { BasePaymentFlowPageComponent } from '@modules/payment/pages/base-payment-flow-page/base-payment-flow-page.component';
import { BucketCardComponent } from '@modules/payment/components/bucket-card/bucket-card.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { roleGuard } from '@guards/role.guard';
import { Role } from '@enums/role.enum';
import { PaymentRedirectPageComponent } from '@modules/payment/pages/payment-redirect-page/payment-redirect-page.component';

const routes: Routes = [
  {
    path: PAYMENT_NAV.root.path,
    component: BasePaymentFlowPageComponent,
    canActivate: [authenticationGuard(), roleGuard([Role.BILLING_ADMIN])],
    children: [
      {
        path: PAYMENT_NAV.bucketsOverview.path,
        component: BucketsOverviewPageComponent,
      },
      {
        path: PAYMENT_NAV.bucketsOverview.path,
        component: PaymentSuccessPageComponent,
      },
      {
        path: PAYMENT_NAV.redirectWhenPaymentFinished.path,
        component: PaymentSuccessPageComponent,
        resolve: { order: orderResolver },
      },
      {
        path: PAYMENT_NAV.redirectWhenAutomaticPaymentSetupCompleted.path,
        component: PaymentSuccessPageComponent,
      },
      {
        path: PAYMENT_NAV.paymentSuccess.path,
        component: PaymentSuccessPageComponent,
      },
      {
        path: PAYMENT_NAV.paymentFailed.path,
        component: PaymentFailedPageComponent,
      },
      {
        path: PAYMENT_NAV.paymentCancelled.path,
        component: PaymentCancelledPageComponent,
      },
    ],
  },
  {
    path: PAYMENT_NAV.paymentRedirect.path,
    component: PaymentRedirectPageComponent,
  },
];

@NgModule({
  declarations: [
    BasePaymentFlowPageComponent,
    BucketsOverviewPageComponent,
    PaymentSuccessPageComponent,
    PaymentCancelledPageComponent,
    PaymentFailedPageComponent,
    AutomaticPaymentSetupSuccessPageComponent,
    BucketCardComponent,
    PaymentRedirectPageComponent,
  ],
  imports: [
    CoreModule,
    RouterModule.forChild(routes),
    FormModule,
    AccountSharedModule,
  ],
})
export class PaymentModule { }
