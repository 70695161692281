import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { Room } from '@models/room.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { isPastDate } from '@utils/validators/date.validator';
import { debounce } from '@utils/decorators/debounce.decorator';
import { format } from 'date-fns';
import { getInstitutionRoles, Role } from '@enums/role.enum';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-filter-visits-sidebar',
  templateUrl: './filter-visits-sidebar.component.html',
  styleUrls: ['./filter-visits-sidebar.component.scss'],
})
export class FilterVisitsSidebarComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;
  protected readonly institutionRoles: Role[] = getInstitutionRoles();

  @Input() isCollapsed: boolean;
  @Input() filter: VisitFilter;
  @Input() isLoading: boolean;

  @Output() searchClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() roomFilterClick: EventEmitter<Room> = new EventEmitter<Room>();
  @Output() filterChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() collapseIconClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearFilterClick: EventEmitter<void> = new EventEmitter<void>();

  formGroup: FormGroup;
  currentDate: string;

  constructor(breakpointObserver: BreakpointObserver, private readonly formBuilder: FormBuilder) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    this.currentDate = format(new Date(), 'yyyy-MM-dd');
    this.createForm();
  }

  onFormControlKeyUp(keyboardEvent: KeyboardEvent): void {
    if (keyboardEvent?.key === 'Enter') {
      this.searchClick.emit();

      return;
    }

    this.writeValuesToFilter();
  }

  onResetPatientsFilterClicked(): void {
    this.filter.resetPatientFields();
    this.createForm();
    this.filterChange.emit();
    this.clearFilterClick.emit();
  }

  isSearchButtonDisabled(): boolean {
    return this.isLoading || !this.formGroup?.valid || !this.filter.hasPatientFilterActive();
  }

  writeValuesToFilter(): void {
    this.filter.patientName = this.formGroup.get('name').value;
    this.filter.patientBirthdate = this.formGroup.get('birthdate').value;
    this.filter.patientSocialSecurityNumber = this.formGroup.get('social_security_number').value;

    if (!this.filter.hasPatientFilterActive()) {
      this.onFilterChangedInstantly();

      return;
    }

    this.onFilterChangedWithDebounce();
  }

  private onFilterChangedInstantly(): void {
    this.filterChange.emit();
  }

  @debounce(500)
  private onFilterChangedWithDebounce(): void {
    this.filterChange.emit();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      ['name']: [
        null,
        [],
      ],
      ['birthdate']: [
        null,
        [isPastDate()],
      ],
      ['social_security_number']: [
        null,
        [],
      ],
    });
  }
}
