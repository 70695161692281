import { Component, Input, TemplateRef } from '@angular/core';
import { IObject } from '@app-types/iobject.type';

@Component({
  selector: 'vh-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input() logoIsHiddenOnMobile: boolean;
  @Input() navigationBarContentTemplate: TemplateRef<IObject> | null = null;
  @Input() overflowY: 'auto' | 'hidden' = 'auto';
  @Input() expanded: boolean = true;
}
