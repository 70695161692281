import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CONVERSATION_NAV } from '../constants/conversation-nav.constant';
import { NAVIGATION } from '@constants/navigation.constant';

/**
 * This services helps with navigating through the conversation flow.
 */
@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class ConversationNavigationService {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
  ) { }

  navigateToDetails(conversation: string, newTab: boolean = false): void {
    const route: string = CONVERSATION_NAV.details.route.replace(
      `:${CONVERSATION_NAV.details.params.conversation_uuid}`,
      conversation
    );

    if (newTab) {
      window.open(this.router.serializeUrl(this.router.createUrlTree([route])), '_blank');
    } else {
      void this.router.navigate([route]);
    }
  }

  navigateToConversation(conversation: string): void {
    const route: string = CONVERSATION_NAV.conversation.route.replace(`:${CONVERSATION_NAV.conversation.params.conversation_uuid}`, conversation);

    void this.router.navigate([route]);
  }

  navigateToCreation(): void {
    void this.router.navigate([CONVERSATION_NAV.create.route]);
  }

  navigateToConversationList(): void {
    void this.router.navigate([NAVIGATION.adminDashboardConversations.route]);
  }
}
