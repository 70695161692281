<vh-dialog-frame (closeClicked)="dialog.close(false)" [title]="'wallet.paymentMethods.addDialog.title' | translate">

  <p>{{ 'wallet.paymentMethods.addDialog.intro' | translate }}</p>

  <mat-dialog-content>
    <vh-credit-card-form
      *ngIf="!pollingActive"
      (mollieTokenGenerated)="onMollieTokenReady($event)"
      (mollieTokenErrorOccurred)="onMollieTokenError($event)"
      (validationStateChanged)="onValidationStateChanged($event)">
    </vh-credit-card-form>

    <div *ngIf="pollingActive" class="flex-col-c-c gap-v-24">
      <p>{{ 'common.processing' | translate }}</p>
      <vh-loader></vh-loader>
    </div>

    <vh-message
      *ngIf="error"
      class="not-clickable"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-alert)'"
      [text]="'onboarding.errors.mollieStatus.' + error | translate">
    </vh-message>
  </mat-dialog-content>
  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button [isDisabled]="pollingActive" [label]="'common.next' | translate"
      (buttonClick)="onConfirmClicked()"></vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>