import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { Room } from '@models/room.model';
import { Occurrence, OccurrenceJson } from '@models/occurrence.model';
import { RecurrenceException, RecurrenceExceptionJson } from '@models/recurrence-exception.model';
import { RoomPlanning, RoomPlanningJson } from '@models/room-planning.model';
import { DaysWithRoomPlanning, DaysWithRoomPlanningJson } from '@models/days-with-room-planning.model';
import { IObject } from '@app-types/iobject.type';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {}

  getPlanningOfRoom$ = (roomId: string, fromDate: string, toDate: string): Observable<Occurrence[]> => {
    const endpoint: string = ENDPOINTS.getPlanningOfRoom.route.replace(
      `{${ENDPOINTS.getPlanningOfRoom.pathParams.roomId}}`,
      roomId.toString()
    );

    const options = {
      params: {
        from: fromDate,
        to: toDate,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: OccurrenceJson[]): Occurrence[] => json.map(Occurrence.fromJson)));
  };

  getPlanningExceptionsOfRoom$(roomId: string, fromDate: string, toDate: string): Observable<RecurrenceException[]> {
    const endpoint: string = ENDPOINTS.getPlanningExceptionsOfRoom.route.replace(
      `{${ENDPOINTS.getPlanningExceptionsOfRoom.pathParams.roomId}}`,
      roomId.toString()
    );

    const options = {
      params: {
        from: fromDate,
        to: toDate,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: RecurrenceExceptionJson[]): RecurrenceException[] => json.map(RecurrenceException.fromJson)));
  }

  getPlanningOfMultipleRooms$ = (fromDate: Date, toDate: Date, rooms: Room[] = null): Observable<RoomPlanning[]> => {
    const endpoint: string = ENDPOINTS.getPlanning.route
      .replace(`{${ENDPOINTS.getPlanning.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const options: IObject = {
      params: {
        from: format(fromDate, DATE_FORMATS.serverDate),
        to: format(toDate, DATE_FORMATS.serverDate),
      },
    };

    if (rooms && rooms.length > 0) {
      options.params.rooms = rooms.map((r: Room) => r.id).join(',');
    }

    return this.http.get(environment.apiBaseUrl + endpoint, options).pipe(
      map((json: RoomPlanningJson[]): RoomPlanning[] => {
        return json.map((item: RoomPlanningJson): RoomPlanning => RoomPlanning.fromJson(item));
      })
    );
  };

  getDaysHavingPlannedSlots$ = (fromDate: Date, toDate: Date, rooms: Room[] = null): Observable<DaysWithRoomPlanning[]> => {
    const endpoint: string = ENDPOINTS.getDaysHavingPlannedSlots.route
      .replace(`{${ENDPOINTS.getDaysHavingPlannedSlots.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const options: IObject = {
      params: {
        from: format(fromDate, DATE_FORMATS.serverDate),
        to: format(toDate, DATE_FORMATS.serverDate),
      },
    };

    if (rooms && rooms.length > 0) {
      options.params.rooms = rooms.map((r: Room) => r.id).join(',');
    }

    return this.http.get(environment.apiBaseUrl + endpoint, options).pipe(
      map((json: DaysWithRoomPlanningJson[]): DaysWithRoomPlanning[] => {
        return json.map((item: DaysWithRoomPlanningJson): DaysWithRoomPlanning => DaysWithRoomPlanning.fromJson(item));
      })
    );
  };
}
