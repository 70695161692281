import { Component, Input } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { Conversation } from '@models/conversation.model';

@Component({
  selector: 'vh-conversation-card',
  templateUrl: './conversation-card.component.html',
  styleUrls: ['./conversation-card.component.scss'],
})
export class ConversationCardComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;

  @Input() conversation: Conversation;
}
