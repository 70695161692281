import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  constructor(private readonly http: HttpClient) {}

  sendMissingQuestionListLeadEmail(): Observable<void> {
    const endpoint: string = ENDPOINTS.sendMissingQuestionListLeadEmail.route;

    return this.http.post(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }
}
