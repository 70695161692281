import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { LoginComponent } from '@modules/account/components/login/login.component';
import { RegisterComponent } from '@modules/account/components/register/register.component';
import { FormModule } from '@modules/shared/form/form.module';
import { CenteredCardWithRouterPageComponent } from '@modules/account/components/centered-card-with-router-page/centered-card-with-router.page';
import { CenteredCardPageComponent } from '@modules/account/components/centered-card-page/centered-card-page.component';
import { TokenBalanceComponent } from '@modules/account/components/token-balance/token-balance.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { NavigationProfileComponent } from '@modules/account/components/navigation-profile/navigation-profile.component';
import { NavigationProfileDetailsComponent } from '@modules/account/components/navigation-profile-details/navigation-profile-details.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [
  LoginComponent,
  RegisterComponent,
  CenteredCardPageComponent,
  CenteredCardWithRouterPageComponent,
  TokenBalanceComponent,
  NavigationProfileComponent,
  NavigationProfileDetailsComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
    OverlayModule,
  ],
  exports: sharedIngredients,
})
export class AccountSharedModule { }
