<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()" [isDisabled]="!formGroup.dirty"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()" [isDisabled]="!formGroup.dirty"></vh-button>
    </div>

    <div class="settings-list" [formGroup]="formGroup">
      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.reservationsTitle' | translate"></vh-horizontal-divider>

     <label>{{ 'admin.pages.settings.generalSettings.patientCanCancelVisitLabel' | translate }}</label>
     <vh-switch
       #patientCanCancelVisitCheckbox
       [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('patient_can_cancel_visit')"
       [value]="institution.settings.patientCanCancelVisit">
     </vh-switch>

     <label>{{ 'admin.pages.settings.generalSettings.patientCanCancelVisitHoursAtForehand' | translate }}</label>
     <div class="flex-row-l-c gap-h-8">
       <vh-text-input
         class="text-input-s"
         type="number"
         [min]="1"
         [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('patient_can_cancel_visit_hours_at_forehand')"
         [value]="institution.settings.patientCanCancelVisitHoursAtForehand"
         [isDisabled]="!patientCanCancelVisitCheckbox.value">
       </vh-text-input>
       <p class="flex-grow">{{ 'admin.pages.settings.generalSettings.hoursBeforeLabel' | translate }}</p>
     </div>

      <label>{{ 'admin.pages.settings.generalSettings.referringPhysicianCanCancelVisitLabel' | translate }}</label>
      <vh-switch
        #referringPhysicianCanCancelVisitCheckbox
        [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('referring_physician_can_cancel_visit')"
        [value]="institution.settings.referringPhysicianCanCancelVisit">
      </vh-switch>

      <label>{{ 'admin.pages.settings.generalSettings.referringPhysicianCanCancelVisitHoursAtForehand' | translate }}</label>
      <div class="flex-row-l-c gap-h-8">
        <vh-text-input
          class="text-input-s"
          type="number"
          [min]="1"
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('referring_physician_can_cancel_visit_hours_at_forehand')"
          [value]="institution.settings.referringPhysicianCanCancelVisitHoursAtForehand"
          [isDisabled]="!referringPhysicianCanCancelVisitCheckbox.value">
        </vh-text-input>
        <p class="flex-grow">{{ 'admin.pages.settings.generalSettings.hoursBeforeLabel' | translate }}</p>
      </div>

      <label>{{ 'admin.pages.settings.generalSettings.allowDoubleBookingsLabel' | translate }}</label>
      <vh-switch
        [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('allow_double_booking_admin')"
        [value]="institution.settings.referringPhysicianCanCancelVisit"
        [message]="'admin.pages.settings.generalSettings.allowDoubleBookingsDescription' | translate">
      </vh-switch>

      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.roomsTitle' | translate"></vh-horizontal-divider>

      <label>{{ 'admin.pages.settings.generalSettings.limitSimultaneousBookingsLabel' | translate }}</label>
      <vh-switch
        #maxSimultaneousBookingsEnabledSwitch
        [value]="institution.settings.isMaxSimultaneousBookingsEnabled"
        (valueChanged)="fillMaxSimultaneousBookingsDefaultValueIfNeeded($event)">
      </vh-switch>

      <div></div>
      <div class="flex-row-l-c gap-h-8">
        <p>{{ 'admin.pages.settings.generalSettings.maximumSimultaneousBookingsInputPrefix' | translate }}</p>
        <vh-text-input
          type="number"
          [min]="1"
          [max]="amountOfRooms"
          [isDisabled]="!maxSimultaneousBookingsEnabledSwitch.value"
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_simultaneous_bookings')"
          [value]="institution.settings.bookingRestrictionMaxSimultaneousBookings">
        </vh-text-input>
        <p>{{ 'admin.pages.settings.generalSettings.maximumSimultaneousBookingsInputSuffix' | translate }}</p>
      </div>

      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.bookingLimitations.title' | translate"></vh-horizontal-divider>

      <label>{{ 'admin.pages.settings.generalSettings.bookingLimitations.limitationsLabel' | translate }}</label>
      <div class="flex-col">
        <div class="flex-row-l-c gap-h-8">
          <input
            #bookingRestrictionsNone
            class="checkbox-s"
            type="radio"
            name="booking_restriction"
            checked="checked"
            value="none"
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')" />
          <label>{{ 'admin.pages.settings.generalSettings.bookingLimitations.limitationOptionNone' | translate }}</label>
        </div>

        <div class="flex-row-l-c gap-h-8" [vhMarginTop]="8">
          <input
            #bookingRestrictionDaysRadio
            class="checkbox-s"
            name="booking_restriction"
            type="radio"
            value="days"
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')" />
          <vh-text-input
            type="number"
            [isDisabled]="bookingRestrictionWeeksRadio.checked || bookingRestrictionsNone.checked"
            [min]="1"
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_days_before_visit')">
          </vh-text-input>
          <p>{{ 'admin.pages.settings.generalSettings.bookingLimitations.limitationOptionDays' | translate }}</p>
        </div>

        <div class="flex-row-l-c gap-h-8" [vhMarginTop]="8">
          <input
            #bookingRestrictionWeeksRadio
            class="checkbox-s"
            name="booking_restriction"
            type="radio"
            value="weeks"
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction')" />
          <vh-text-input
            type="number"
            [isDisabled]="bookingRestrictionDaysRadio.checked || bookingRestrictionsNone.checked"
            [min]="1"
            [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_max_weeks_before_visit')">
          </vh-text-input>
          <p>{{ 'admin.pages.settings.generalSettings.bookingLimitations.limitationOptionWeeks' | translate }}</p>
        </div>
      </div>

      <label>{{ 'admin.pages.settings.generalSettings.bookingLimitations.weekStartsOnLabel' | translate }}</label>
      <div class="flex-col">
        <vh-select-input
          [options]="dayOptions"
          [isDisabled]="bookingRestrictionDaysRadio.checked || bookingRestrictionsNone.checked"
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('booking_restriction_week_starts_on')">
        </vh-select-input>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.bookingLimitations.weekStartDisclaimer' | translate }}
        </p>
      </div>

    </div>
  </div>
</vh-content-layout>
