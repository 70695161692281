<vh-centered-card-page>
  <vh-message
    *ngIf="!isSubmitted"
    textClass="has-text-primary font-light-14-17"
    [text]="'pages.forgotPassword.loginButtonLabel' | translate"
    [iconLeftPath]="'assets/icons/chevron-left.svg'"
    [iconLeftStroke]="COLORS.primary"
    [routerLink]="NAVIGATION.login.route">
  </vh-message>
  <ng-container *ngIf="!isSubmitted" [ngTemplateOutlet]="resetPasswordFormTemplate"></ng-container>
  <ng-container *ngIf="isSubmitted" [ngTemplateOutlet]="submitSuccessfulTemplate"></ng-container>
</vh-centered-card-page>

<ng-template #resetPasswordFormTemplate [formGroup]="formGroup">
  <h1 [vhMarginTop]="8">{{ (isEnforced ? 'pages.resetPassword.titleWhenEnforced' : 'pages.resetPassword.title') | translate }}</h1>
  <p [vhMarginTop]="16">{{ (isEnforced ? 'pages.resetPassword.textWhenEnforced' : 'pages.resetPassword.text') | translate }}</p>
  <vh-password-input
    autocomplete="new-password"
    [vhMarginTop]="16"
    [formControlName]="RESET_PASSWORD_FORM_KEYS.get('newPassword')"
    [label]="'common.fields.newPassword' | translate"
    [placeholder]="'common.fields.password' | translate">
  </vh-password-input>
  <vh-password-input
    autocomplete="new-password"
    [vhMarginTop]="16"
    [formControlName]="RESET_PASSWORD_FORM_KEYS.get('repeatedPassword')"
    [label]="'common.fields.repeatNewPassword' | translate"
    [placeholder]="'common.fields.password' | translate">
  </vh-password-input>
  <vh-message
    *ngIf="submitNewPasswordErrorMessageTranslationKey"
    class="not-clickable"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="submitNewPasswordErrorMessageTranslationKey | translate">
  </vh-message>
  <div class="flex-row-c-c"       [vhMarginTop]="16">
    <vh-button
      [label]="'pages.resetPassword.submitButtonLabel' | translate"
      [isDisabled]="!newPassword || !repeatedPassword || submitNewPasswordButtonIsDisabled"
      [iconRightPath]="'assets/icons/arrow-right-white.svg'"
      (buttonClick)="submitNewPassword()">
    </vh-button>
  </div>
</ng-template>

<ng-template #submitSuccessfulTemplate>
  <vh-post-action-status
    status="success"
    [isStroke]="true"
    [actionText]="'pages.resetPassword.submitted.loginButtonLabel' | translate"
    [message]="'pages.resetPassword.submitted.text' | translate"
    [title]="'pages.resetPassword.submitted.title' | translate"
    [routerLink]="NAVIGATION.login.route">
  </vh-post-action-status>
</ng-template>
