<vh-dialog-frame
  [title]="institution.parent ? institution.parent.name : institution.name"
  [marginBetweenTitleAndBody]="2"
  (closeClicked)="close()">
  <p *ngIf="institution.parent && institution.parent.name.toLowerCase() !== institution.name.toLowerCase()" class="detail-text">
    {{ institution.name }}
  </p>

  <div class="icon-with-text-grid gap-h-8" [vhMarginTop]="8">
    <vh-icon [vhMarginTop]="6" src="assets/icons/marker.svg"></vh-icon>
    <div>
      <p>{{ institution.address.fullStreet }}</p>
      <p [vhMarginTop]="4">{{ institution.address.postalCode }} {{ institution.address.city }}</p>
    </div>
  </div>

  <div *ngIf="institution.phones?.length > 0" [vhMarginTop]="8" class="icon-with-text-grid gap-h-8">
    <vh-icon [vhMarginTop]="6" height="16" width="16" src="assets/icons/phone.svg"></vh-icon>
    <div>
      <p *ngFor="let phone of institution.phones" [vhMarginTop]="4">
        <span
          ><a [href]="phone.valueHref">{{ phone.value }}</a></span
        >
        <span class="detail-text" *ngIf="phone.description"> ({{ phone.description }})</span>
      </p>
    </div>
  </div>

  <div [vhMarginTop]="24">
    <vh-button-group [items]="convertModalitiesToButtonOptions(institution)"></vh-button-group>
  </div>
</vh-dialog-frame>
