import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { Institution } from '@models/institution.model';
import { Service } from '@models/service.model';
import { COLORS } from '@constants/colors.constant';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

export interface InstitutionDetailsDialogData {
  institution: Institution;
}

@Component({
  selector: 'vh-institution-details-dialog',
  templateUrl: './institution-details-dialog.component.html',
  styleUrls: ['./institution-details-dialog.component.scss'],
})
export class InstitutionDetailsDialogComponent {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  institution: Institution;

  constructor(
    protected readonly dialogRef: MatDialogRef<InstitutionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: InstitutionDetailsDialogData
  ) {
    this.institution = data.institution;
  }

  close(): void {
    this.dialogRef.close();
  }

  convertModalitiesToButtonOptions(institution: Institution): ButtonOption[] {
    return institution.modalities.map((m: Service) => {
      return {
        ...m.toButtonOption(true),
        isClickable: false,
        isActive: true,
      };
    });
  }
}
