<vh-dialog-frame
  [title]="'conversations.details.transcriptTitle' | translate"
  (closeClicked)="dialog.close(false)">

  <mat-dialog-content>
    <vh-loader *ngIf="loading" class="loader"></vh-loader>

    <div *ngFor="let message of messages; let i = index"
      [ngClass]="message.role === 'user' ? 'text-user' : 'text-assistant'"
      [attr.key]="'m-' + i">

      <img
        *ngIf="message.content !== '' && message.role === 'assistant'"
        class="text-assistant" src="/assets/icons/assistant-text-icon.svg" alt="Assistant Icon" />

      <p *ngIf="message.role !== 'info' && message.content !== ''">
        {{ message.content }}
      </p>
    </div>
  </mat-dialog-content>
</vh-dialog-frame>