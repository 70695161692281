/*
 * Values must match with the API
 */
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, map } from 'rxjs';

export enum Role {
  ADMIN = 'super_admin',
  BILLING_ADMIN = 'billing_admin',
  CARE_GIVER = 'care_giver',
  INSTITUTION_ADMIN = 'institution_admin',
  INSTITUTION_MANAGER = 'institution_manager',
  INSTITUTION_EDITOR = 'institution_editor',
  INSTITUTION_VIEWER = 'institution_viewer',
  PATIENT = 'patient',
  REFERRING_PHYSICIAN_ADMIN = 'referring_physician_admin',
  REFERRING_PHYSICIAN_EDITOR = 'referring_physician_editor',
  SYSTEM = 'system',
}

export function getAdminRoles(): Role[] {
  return [Role.INSTITUTION_ADMIN, Role.ADMIN];
}

export function getInstitutionRoles(): Role[] {
  return [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER];
}

export function getInstitutionEditorRoles(): Role[] {
  return [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR];
}

export function getInstitutionManageRoles(): Role[] {
  return [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.ADMIN];
}

export function getReferringPhysicianRoles(): Role[] {
  return [Role.REFERRING_PHYSICIAN_ADMIN, Role.REFERRING_PHYSICIAN_EDITOR];
}

export function getEditorRoles(): Role[] {
  return [
    Role.INSTITUTION_ADMIN,
    Role.INSTITUTION_MANAGER,
    Role.INSTITUTION_EDITOR,
    Role.REFERRING_PHYSICIAN_ADMIN,
    Role.REFERRING_PHYSICIAN_EDITOR,
  ];
}

/**
 * Get all the roles for users that have access to the back office
 */
export function getBackOfficeRoles(): Role[] {
  return [
    Role.ADMIN,
    Role.INSTITUTION_ADMIN,
    Role.INSTITUTION_MANAGER,
    Role.INSTITUTION_EDITOR,
    Role.INSTITUTION_VIEWER,
    Role.REFERRING_PHYSICIAN_ADMIN,
    Role.REFERRING_PHYSICIAN_EDITOR,
    Role.SYSTEM,
  ];
}

export function getAssignableRoles(): Role[] {
  return [
    Role.BILLING_ADMIN,
    Role.INSTITUTION_ADMIN,
    Role.INSTITUTION_MANAGER,
    Role.INSTITUTION_EDITOR,
    Role.INSTITUTION_VIEWER,
    Role.REFERRING_PHYSICIAN_ADMIN,
    Role.REFERRING_PHYSICIAN_EDITOR,
  ];
}

/**
 * Get all the roles that are relevant for the institution users overview
 */
export function getRolesForInstitutionUsersOverview(): Role[] {
  return [
    Role.BILLING_ADMIN,
    Role.INSTITUTION_ADMIN,
    Role.INSTITUTION_MANAGER,
    Role.INSTITUTION_EDITOR,
    Role.INSTITUTION_VIEWER,
    Role.REFERRING_PHYSICIAN_ADMIN,
    Role.REFERRING_PHYSICIAN_EDITOR,
    Role.SYSTEM,
  ];
}

export function getRolesAsOptions(translate: TranslateService): Observable<Option[]> {
  const observableRoles = Object.values(Role).map((role: string): Observable<Option> => {
    return translate.get(`roles.${role}.displayName`).pipe(
      map(
        (displayName: string): Option => ({
          label: displayName,
          value: role,
        })
      )
    );
  });

  return combineLatest(observableRoles);
}

export function getRolesAsOptionsForInstitutionEmployees(translate: TranslateService): Observable<Option[]> {
  return getRolesAsOptions(translate).pipe(
    map((options: Option[]): Option[] =>
      options.filter((option: Option): boolean => {
        return getRolesForInstitutionUsersOverview().includes(option.value as Role);
      })
    )
  );
}
