import { Injectable } from '@angular/core';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { Institution } from '@models/institution.model';
import { AbstractTrackingService } from '@services/tracking/abstract-tracking.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';

@Injectable({
  providedIn: 'root',
})
export class InstitutionTrackingEventService extends AbstractTrackingService<Institution> {
  protected get endpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.trackInstitutionEvent.route;
  }

  protected map(trackingEventType: TrackingEventType, institution: Institution): Record<string, string> {
    return {
      type: trackingEventType,
      institution_id: institution.id,
    };
  }

  protected get bulkEndpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.bulkTrackInstitutionEvents.route;
  }

  protected getUniqueIdentifier(institution: Institution): string {
    return institution.id;
  }
}
