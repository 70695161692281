<vh-back-button (buttonClick)="goBack()"></vh-back-button>

<h2>{{ 'conversations.create.createPatient.title' | translate }}</h2>

<vh-register
  #registerComponent
  [headerIsVisible]="false"
  [loginButtonIsVisible]="false"
  [hasPasswordInput]="false"
  [hasPrivacyPolicyInput]="false"
  [registerButtonIsVisible]="false"
  [registerSelf]="false"
  [emailOrPhoneRequired]="true"
  [afterSuccessfulRegisterFunction]="goToNextStep"
  (existingPatientEvent)="usingExistentPatient = $event">
</vh-register>

<vh-button
  *ngIf="!usingExistentPatient"
  [label]="'common.next' | translate"
  [vhMarginTop]="32"
  (buttonClick)="register()"
  [centered]="true"
  [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
  [iconRightWidth]="20"
  [iconRightHeight]="20"
  [iconRightStroke]="COLORS.white">
</vh-button>
