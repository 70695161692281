<ng-container [ngTemplateOutlet]="noServiceGroupsConfiguredTemplate"></ng-container>

<vh-events-calendar
  [vhMarginTop]="shouldShowNoServiceGroupConfiguredError ? 8 : 0"
  [vhPopoverHost]="POPOVER_ID"
  [popoverTemplate]="popupTemplate"
  [popoverHasBackdrop]="true"
  popoverPositioningStrategy="left_or_right"
  popoverConnectToFirstHtmlElementWithTagName="vh-planning-page"
  [popoverPreferredPosition]="popoverPreferredPositioning"
  [weekStartsOn]="1"
  [selectedDate]="weekStartDate"
  [events]="events"
  [locale]="locale"
  [headerTemplate]="headerTemplate ?? null"
  [showEventCounterBadges]="false"
  [zoomLevel]="1"
  [calendarMode]="'week'"
  [isDisabled]="isFetchingData() || isDisabled || shouldShowNoServiceGroupConfiguredError"
  [isLoading]="isFetchingData()"
  (hourSegmentClick)="onHourSegmentClicked($event)"
  (eventClick)="onEventClicked($event)"
  (eventTimeChange)="onEventDragged($event)">
</vh-events-calendar>

<ng-template #popupTemplate>
  <div class="pop-up-container">
    <vh-recurrence-settings-popup
      vhOutsideClick
      [selectedOccurrence]="selectedOccurrence"
      [serviceGroupOptions]="serviceGroupOptions"
      [doctorOptions]="doctorOptions"
      [recurrenceOptionsSingular]="recurrenceOptionsSingular"
      [recurrenceOptionsPlural]="recurrenceOptionsPlural"
      [room]="room"
      (valueChange)="onOccurrenceChanged($event)"
      (saveClick)="onSaveClick($event)"
      (deleteClick)="onDeleteClicked($event)"
      (outsideClick)="closePopup(true)">
    </vh-recurrence-settings-popup>
  </div>
</ng-template>

<ng-template #noServiceGroupsConfiguredTemplate>
  <ng-container *ngIf="shouldShowNoServiceGroupConfiguredError">
    <vh-message
      *vhRequiresRoles="Role.ADMIN"
      class="has-text-error"
      [text]="'admin.pages.slotsOverview.popup.noServiceGroupsConfigured' | translate"
      (messageClick)="onNavigateToRoomSettingsClick()"
      iconRightPath="assets/icons/arrow-right.svg"
      [iconRightFill]="COLORS.error">
    </vh-message>

    <vh-message
      *ngIf="!user.hasRole(Role.ADMIN)"
      class="has-text-error not-clickable"
      [text]="'admin.pages.slotsOverview.popup.noServiceGroupsConfiguredForEndUser' | translate">
    </vh-message>
  </ng-container>
</ng-template>
