import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { BreakpointObserverComponent } from '../breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-navigation-return-link',
  templateUrl: './navigation-return-link.component.html',
  styleUrls: ['./navigation-return-link.component.scss'],
})
export class NavigationReturnLinkComponent extends BreakpointObserverComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() icon: string = 'assets/icons/cross.svg';
  @Input() color: string | typeof COLORS = COLORS.white;
  @Input() label: string | null = 'common.return';

  @Output() navigateBackClick: EventEmitter<void> = new EventEmitter<void>();
}
