import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from 'src/app/app.component';
import { NAVIGATION } from '@constants/navigation.constant';
import { roleGuard } from '@guards/role.guard';
import { HttpErrorInterceptor } from 'src/app/interceptors/http-error.interceptor';
import { AccountModule } from '@modules/account/account.module';
import { BookingModule } from '@modules/booking/booking.module';
import { PatientModule } from '@modules/patient/patient.module';
import { CoreModule } from '@modules/shared/core/core.module';
import { SurveyModule } from '@modules/survey/survey.module';
import { PublicModule } from '@modules/public/public.module';
import { DateFnsModule } from 'ngx-date-fns';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { inaccessibleOnEnvironmentGuard } from '@guards/inaccessible-on-environment.guard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { getInstitutionRoles, getReferringPhysicianRoles } from '@enums/role.enum';
import { authenticationGuard } from '@guards/authentication.guard';
import { modeGuard } from '@guards/mode.guard';
import { Mode } from '@enums/mode.enum';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular-ivy';
import { ConversationModule } from '@modules/conversation/conversation.module';
import { PaymentModule } from '@modules/payment/payment.module';
import { OnboardingModule } from '@modules/onboarding/onboarding.module';

const routes: Routes = [
  {
    path: NAVIGATION.root.path,
    // eslint-disable-next-line @typescript-eslint/typedef
    loadChildren: () => import('@modules/account/account.module').then(m => m.AccountModule),
  },
  {
    path: NAVIGATION.admin.path,
    // eslint-disable-next-line @typescript-eslint/typedef
    loadChildren: () => import('@modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [authenticationGuard(), roleGuard(getInstitutionRoles()), modeGuard(Mode.ADMIN)],
  },
  {
    path: NAVIGATION.playground.path,
    // eslint-disable-next-line @typescript-eslint/typedef
    loadChildren: () => import('@modules/playground/playground.module').then(m => m.PlaygroundModule),
    canActivate: [inaccessibleOnEnvironmentGuard('prod')],
  },
  {
    path: NAVIGATION.referringPhysician.path,
    // eslint-disable-next-line @typescript-eslint/typedef
    loadChildren: () => import('@modules/referring-physician/referring-physician.module').then(m => m.ReferringPhysicianModule),
    canActivate: [authenticationGuard(), roleGuard(getReferringPhysicianRoles()), modeGuard(Mode.ADMIN)],
  },
];

// The Angular ahead-of-time compiler requires an exported function for factories
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    HttpClientJsonpModule,

    // Material modules
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,

    // Translate module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Library modules
    DateFnsModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    // App modules
    CoreModule,
    PublicModule,
    AccountModule,
    PatientModule,
    BookingModule,
    ConversationModule,
    OnboardingModule,
    SurveyModule,
    PaymentModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: (): () => void => (): void => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideEnvironmentNgxMask(),
  ],
})
export class AppModule { }
