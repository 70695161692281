import { Directive, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '@enums/role.enum';
import { arrayWrap } from '@utils/helpers/array.util';
import { AuthenticationService } from '@services/authentication.service';

@Directive({
  selector: '[vhRequiresRoles]',
})
export class RequiresRolesDirective implements OnChanges {
  didUpdateView: boolean = false;

  private _roles: Role[];

  @Input() set vhRequiresRoles(roles: Role[] | Role) {
    this._roles = arrayWrap(roles);

    if (!this._roles.includes(Role.ADMIN)) {
      this._roles.push(Role.ADMIN);
    }
  }

  constructor(
    protected readonly element: ElementRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly authenticationService: AuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vhRequiresRoles) {
      this.updateView();
    }
  }

  private updateView(): void {
    if (this.didUpdateView) {
      return;
    }

    if (this.authenticationService.currentUser?.hasAnyRole(this._roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.didUpdateView = true;

      return;
    }

    this.viewContainer.clear();
  }
}
