import { Component } from '@angular/core';
import { BasePaymentStatusPageComponent } from '@modules/payment/pages/base-payment-status-page/base-payment-status-page.component';

@Component({
  selector: 'vh-payment-failed-page',
  templateUrl: './payment-failed-page.component.html',
  styleUrls: ['./payment-failed-page.component.scss'],
})
export class PaymentFailedPageComponent extends BasePaymentStatusPageComponent {
}
