<vh-dialog-frame
  (closeClicked)="dialog.close(false)">

  <mat-dialog-content>
    <img
      class="qrcode"
      draggable="false"
      *ngIf="qrCode"
      [src]="qrCode"
      [alt]="data.url" />
  </mat-dialog-content>
</vh-dialog-frame>