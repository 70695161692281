import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { RecommendationResultType } from '@enums/recommendation-result-type.enum';
import { Recommendations } from '@models/recommendations.model';

type CardTemplateType = 'slot' | 'multi-slot' | 'institution' | 'care-program';
type RecommendationListState = 'empty' | 'error' | 'loading' | 'recommendations';

@Component({
  selector: 'vh-recommendations-list',
  templateUrl: './recommendations-list.component.html',
  styleUrls: ['./recommendations-list.component.scss'],
})
export class RecommendationsListComponent<T> implements OnChanges, AfterViewInit {
  getAvailableAppointmentsErrorMessageTranslationKey: string | null = null;

  protected state: RecommendationListState = 'loading';
  protected cardTemplate: CardTemplateType = 'slot';

  @Input() isLoading: boolean = true;
  @Input() allowInstitutionRecommendations: boolean = false;
  @Input() recommendations: Recommendations<T> | null = null;
  @Input() listFooterTemplate: TemplateRef<HTMLElement> | null = null;

  @Output() recommendationClick: EventEmitter<T> = new EventEmitter<T>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.state = this.isLoading ? 'loading' : 'recommendations';
    }

    if (changes.recommendations) {
      this.cardTemplate = this.getCardTemplate();

      if ((!this.recommendations || this.recommendations.data.length === 0) && !this.isLoading) {
        this.state = 'empty';
      }
    }
  }

  ngAfterViewInit(): void {
    this.cardTemplate = this.getCardTemplate();
  }

  onRecommendationClicked = (clickedRecommendation: T): void => {
    this.recommendationClick.emit(clickedRecommendation);
  };

  getCardTemplate(): CardTemplateType | null {
    // TODO: handle no recommendations
    if (!this.recommendations || this.recommendations.data.length === 0) {
      return null;
    }

    switch (this.recommendations.type) {
      case RecommendationResultType.CARE_PROGRAMS:
        return 'care-program';
      case RecommendationResultType.INSTITUTIONS:
        return 'institution';
      case RecommendationResultType.SUBSEQUENT_SLOTS:
        return 'multi-slot';
      case RecommendationResultType.SLOTS:
        return 'slot';
    }
  }
}
