import { Injectable } from '@angular/core';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { Observable } from 'rxjs';
import { VisitServiceDependencyDialogComponent, VisitServiceDependencyDialogResult } from '@modules/booking/dialogs/visit-blocked-by-service-dialog/visit-service-dependency-dialog.component';
import { Service } from '@models/service.model';
import { MatDialog } from '@angular/material/dialog';

/**
 * This service can be used to show dialogs that are related to the booking flow.
 */
@Injectable({
  providedIn: 'root',
})
export class BookingFlowDialogService {
  constructor(
    private readonly alertDialogService: AlertDialogService,
    private readonly dialogService: MatDialog
  ) {
  }

  showConfirmExitBookingFlowDialog(): Observable<boolean> {
    return this.alertDialogService.open({
      titleTranslationKey: 'pages.createAppointment.exitConfirmationDialog.title',
      messageTranslationKey: 'pages.createAppointment.exitConfirmationDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    });
  }

  showExpiredLinkDialog(): Observable<boolean> {
    return this.alertDialogService.open({
      titleTranslationKey: 'pages.createAppointment.toasts.appointmentExpired.title',
      messageTranslationKey: 'pages.createAppointment.toasts.appointmentExpired.text',
      confirmTextTranslationKey: 'common.ok',
    }, {
      disableClose: true,
      panelClass: 'dialog-size-tiny',
    });
  }

  showServiceDependencyDialog(targetService: Service, dependantService: Service): Observable<VisitServiceDependencyDialogResult> {
    return this.dialogService.open(VisitServiceDependencyDialogComponent, {
      panelClass: 'dialog-size-medium',
      data: {
        selectedService: targetService,
        priorService: dependantService,
      },
    })
      .afterClosed();
  }

  showErrorDialog(errorMessageTranslationKey: string): Observable<boolean> {
    return this.alertDialogService.open({
      titleTranslationKey: 'common.errors.generic',
      messageTranslationKey: errorMessageTranslationKey,
      confirmTextTranslationKey: 'common.ok',
    }, {
      panelClass: 'dialog-size-tiny',
    });
  }
}
