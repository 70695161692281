<ng-container *ngIf="!currentPageState || currentPageState === 'loading'" [ngTemplateOutlet]="loadingStateTemplate"></ng-container>
<ng-container *ngIf="currentPageState === 'select_service_type'" [ngTemplateOutlet]="selectServiceTypeStep"></ng-container>
<ng-container *ngIf="currentPageState === 'form'" [ngTemplateOutlet]="stepsTemplate"></ng-container>

<ng-template #stepsTemplate>
  <vh-secondary-page [headerTemplate]="institutionScopeHeader" [title]="'admin.pages.dashboard.createAppointmentButtonLabel' | translate" (navigateBackClick)="exitBookingFlow()">
    <vh-wizard-layout
      [leftHeaderTemplate]="selectedServicesCardTemplate"
      [progressIndicatorMaxWidth]="250"
      [state]="wizardState"
      [contentHasHorizontalSpacing]="true"
      [contentHasVerticalSpacing]="true"
      (nextStepClicked)="wizardState.next()"
      (previousStepClicked)="onGoBackClicked()"
      (stepClicked)="navigateToSelectedStep($event)">
      <ng-container *ngIf="wizardState.currentStep === Steps.selectService" [ngTemplateOutlet]="step1Template"></ng-container>
      <ng-container *ngIf="wizardState.currentStep === Steps.uploadReferralLetter" [ngTemplateOutlet]="step2Template"></ng-container>
      <ng-container *ngIf="wizardState.currentStep === Steps.selectLocation" [ngTemplateOutlet]="step3Template"></ng-container>
      <ng-container *ngIf="wizardState.currentStep === Steps.selectSlot" [ngTemplateOutlet]="step4Template"></ng-container>
    </vh-wizard-layout>
  </vh-secondary-page>
</ng-template>

<ng-template #selectServiceTypeStep>
  <vh-secondary-page [headerTemplate]="institutionScopeHeader" [title]="'admin.pages.dashboard.createAppointmentButtonLabel' | translate" (navigateBackClick)="exitBookingFlow()">
    <vh-hierarchical-picker
      [title]="'pages.createAppointment.pickServiceType.title' | translate"
      [showSearch]="false"
      [state]="serviceTypesState"
      (itemClick)="onServiceTypeSelected()">
    </vh-hierarchical-picker>
  </vh-secondary-page>
</ng-template>

<ng-template #step1Template>
  <vh-hierarchical-picker
    [title]="'pages.createAppointment.step1.title' | translate"
    [subtitle]="'pages.createAppointment.step1.text' | translate"
    [searchPlaceholder]="'pages.createAppointment.step1.serviceSearchInputLabel' | translate"
    [state]="servicesState"
    (itemClick)="onServiceSelected()">
    <div class="human-body" *ngIf="servicesState?.parentOfCurrentItem">
      <vh-human-body [serviceOrServices]="bookingState.booking.services"></vh-human-body>
    </div>
  </vh-hierarchical-picker>
</ng-template>

<ng-template #step2Template>
  <vh-step-upload-referral-letter [visit]="bookingState.booking.leadingVisit"></vh-step-upload-referral-letter>
</ng-template>

<ng-template #step3Template>
  <vh-step-select-location
    #selectLocationStep
    (mapViewPortUpdated)="onMapViewPortUpdated($event)"
    (locationChange)="onLocationChange($event)">
  </vh-step-select-location>
</ng-template>

<ng-template #step4Template>
  <vh-step-select-slot
    [showContactCallToActionCard]="bookingState.flow === BookingFlow.PUBLIC || bookingState.flow === BookingFlow.UNIQUE_LINK"
    [showLocationPicker]="bookingState.flow === BookingFlow.PUBLIC"
    (clearFilterClick)="onClearFilterClicked()"
    (requestContactClick)="navigateToContactPage()"
    (timeslotFilterClick)="onTimeslotClicked($event)"
    (slotClick)="onSlotSelected($event)">
  </vh-step-select-slot>
</ng-template>

<ng-template #loadingStateTemplate>
  <vh-secondary-page [headerTemplate]="institutionScopeHeader" [title]="'admin.pages.dashboard.createAppointmentButtonLabel' | translate" (navigateBackClick)="exitBookingFlow()">
    <vh-loader></vh-loader>
  </vh-secondary-page>
</ng-template>

<ng-template #institutionScopeHeader>
  <vh-institution-header *ngIf="bookingState.scopedInstitution" [institution]="bookingState.scopedInstitution"></vh-institution-header>
</ng-template>

<ng-template #selectedServicesCardTemplate>
  <vh-selected-services-card
    *ngIf="bookingState.booking.services?.length > 0"
    [title]="'pages.createAppointment.step1.selectedServicesTitle' | translate"
    [services]="bookingState.booking.services"
    (serviceRemoved)="onRemoveServiceClicked($event)">
  </vh-selected-services-card>
</ng-template>
