import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimezoneService } from '@services/timezone.service';
import { ErrorMessageService } from '@services/error-message.service';
import { PopoverService } from '@services/ui/popover.service';
import { SelectInputComponent } from '@modules/shared/form/components/select-input/select-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vh-timezone-picker',
  templateUrl: '../select-input/select-input.component.html',
  styleUrls: [
    '../base-select-input/base-select-input.component.scss',
    '../select-input/select-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimezonePickerComponent),
      multi: true,
    },
  ],
})
export class TimezonePickerComponent extends SelectInputComponent implements OnInit {
  constructor(
    controlContainer: ControlContainer,
    translateService: TranslateService,
    popoverService: PopoverService,
    errorMessageService: ErrorMessageService,
    private readonly timezoneService: TimezoneService
  ) {
    super(controlContainer, translateService, popoverService, errorMessageService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.options = this.timezoneService.getTimeZonesAsOptions();
  }
}
