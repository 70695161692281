import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { PatientSharedModule } from '@modules/patient/patient-shared.module';
import { DashboardPageComponent } from '@modules/patient/pages/dashboard-page/dashboard-page.component';
import { authenticationGuard } from '@guards/authentication.guard';
import { modeGuard } from '@guards/mode.guard';
import { Mode } from '@enums/mode.enum';
import { CardModule } from '@modules/shared/cards/card.module';
import { CalendarModule } from '@modules/shared/calendar/calendar.module';
import { FormModule } from '@modules/shared/form/form.module';
import { AccountSharedModule } from '@modules/account/account-shared.module';

const routes: Routes = [
  {
    path: NAVIGATION.dashboard.path,
    component: DashboardPageComponent,
    canActivate: [authenticationGuard(), modeGuard(Mode.PRIVATE)],
  },
];

@NgModule({
  declarations: [DashboardPageComponent],
  imports: [
    RouterModule.forChild(routes),
    PatientSharedModule,
    CardModule,
    CalendarModule,
    FormModule,
    AccountSharedModule,
  ],
})
export class PatientModule { }
