import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { Recurrence, RecurrenceJson } from '@models/recurrence.model';
import { AuthenticationService } from '@services/authentication.service';
import { IObject } from '@app-types/iobject.type';
import { Visit, VisitJson } from '@models/visit.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class RecurrenceService {
  private readonly httpOptions: IObject = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  getRecurrence$ = (recurrenceId: string): Observable<Recurrence> => {
    const endpoint = ENDPOINTS.getRecurrence.route.replace(/{.*}/, recurrenceId);

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map((json: RecurrenceJson) => Recurrence.fromJson(json)));
  };

  findRecurrence$(start: Date, end: Date, serviceId: string | null, roomId: string): Observable<Recurrence> {
    const endpoint: string = ENDPOINTS.findRecurrence.route;

    const options: IObject = {
      params: {
        [ENDPOINTS.findRecurrence.queryParams.start]: start.toISOString(),
        [ENDPOINTS.findRecurrence.queryParams.end]: end.toISOString(),
        [ENDPOINTS.findRecurrence.queryParams.serviceId]: serviceId ?? '',
        [ENDPOINTS.findRecurrence.queryParams.roomId]: roomId,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: RecurrenceJson) => Recurrence.fromJson(json)));
  }

  createRecurrence$ = (recurrence: IObject): Observable<Recurrence> => {
    const endpoint: string = ENDPOINTS.createRecurrence.route;

    const recurrenceDTO = {
      ...recurrence,
      institution_id: this.authenticationService.institution.id,
    };

    return this.http
      .post(environment.apiBaseUrl + endpoint, recurrenceDTO, this.httpOptions)
      .pipe(map((json: RecurrenceJson): Recurrence => Recurrence.fromJson(json)));
  };

  updateRecurrence$ = (recurrenceId: string, recurrence: IObject): Observable<Recurrence> => {
    const endpoint: string = ENDPOINTS.getRecurrence.route.replace(/{.*}/, recurrenceId);

    return this.http
      .put(environment.apiBaseUrl + endpoint, recurrence, this.httpOptions)
      .pipe(map((json: RecurrenceJson): Recurrence => Recurrence.fromJson(json)));
  };

  getImpactedVisitsBeforeDeletion$ = (recurrenceId: string): Observable<Visit[]> => {
    const endpoint = ENDPOINTS.getImpactedVisitsWhenDeleting.route.replace(/{.*}/, recurrenceId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: VisitJson[]): Visit[] => json.map(Visit.fromJson)));
  };

  getOverlappingVisits$ = (roomId: string, start: Date, end: Date, visitId?: string): Observable<Visit[]> => {
    const endpoint = ENDPOINTS.getOverlappingVisits.route.replace(/{.*}/, roomId);

    const options: IObject = {
      params: {
        [ENDPOINTS.getOverlappingVisits.queryParams.start]: start.toISOString(),
        [ENDPOINTS.getOverlappingVisits.queryParams.end]: end.toISOString(),
        [ENDPOINTS.getOverlappingVisits.queryParams.visitId]: visitId,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: VisitJson[]): Visit[] => json.map(Visit.fromJson)));
  };

  deleteRecurrence$ = (recurrenceId: string, properties: IObject): Observable<void> => {
    const endpoint: string = ENDPOINTS.getRecurrence.route.replace(/{.*}/, recurrenceId);

    return this.http
      .post(environment.apiBaseUrl + endpoint, properties)
      .pipe(switchToEmptyObservable());
  };
}
