export class InstitutionInsight {
  constructor(
    private readonly _slotClickCount: number,
    private readonly _slotViewCount: number,
    private readonly _slotBookCount: number,
    private readonly _institutionClickCount: number,
    private readonly _institutionViewCount: number,
    private readonly _institutionUniqueLinkOpenCount: number,
    private readonly _visitsTotalGroupedByModality: Record<string, number>[]
  ) {}

  get slotClickCount(): number {
    return this._slotClickCount;
  }

  get slotViewCount(): number {
    return this._slotViewCount;
  }

  get slotBookCount(): number {
    return this._slotBookCount;
  }

  get institutionClickCount(): number {
    return this._institutionClickCount;
  }

  get institutionViewCount(): number {
    return this._institutionViewCount;
  }

  get institutionUniqueLinkOpenCount(): number {
    return this._institutionUniqueLinkOpenCount;
  }

  get visitsTotalGroupedByModality(): Record<string, number>[] {
    return this._visitsTotalGroupedByModality;
  }

  get hasVisitsTotalGroupedByModality(): boolean {
    return Object.keys(this._visitsTotalGroupedByModality).length > 0;
  }

  static fromJson(json: InstitutionInsightJson): InstitutionInsight {
    return new InstitutionInsight(
      json.slot_click_count,
      json.slot_view_count,
      json.slot_book_count,
      json.institution_click_count,
      json.institution_view_count,
      json.institution_unique_link_open_count,
      json.visits_total_grouped_by_modality
    );
  }
}

export interface InstitutionInsightJson {
  slot_click_count: number;
  slot_view_count: number;
  slot_book_count: number;
  institution_click_count: number;
  institution_view_count: number;
  institution_unique_link_open_count: number;
  visits_total_grouped_by_modality: Record<string, number>[];
}
