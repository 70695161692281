import { Component, OnInit } from '@angular/core';
import { Mode } from '@enums/mode.enum';
import { AuthenticationService } from '@services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BaseBookingFlowPageComponent } from '@modules/booking/pages/base-booking-flow-page/base-booking-flow.page';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { UserFormService } from '@services/form-services/user-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '@services/user.service';
import { forkJoin, Observable, of } from 'rxjs';
import { User } from '@models/user.model';
import { PROFILE_FORM_KEYS } from '@constants/form-keys/profile-form-keys.constant';
import { VISIT_FORM_KEYS } from '@constants/form-keys/visit-form-keys.constant';
import { VisitFormService } from '@services/form-services/visit-form.service';
import { getEditorRoles } from '@enums/role.enum';
import { Visit } from '@models/visit.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'vh-booking-confirmation-page',
  templateUrl: './booking-confirmation-page.component.html',
  styleUrls: ['./booking-confirmation-page.component.scss'],
})
@UntilDestroy()
export class BookingConfirmationPageComponent extends BaseBookingFlowPageComponent implements OnInit {
  protected readonly Mode: typeof Mode = Mode;
  protected readonly PROFILE_FORM_KEYS: typeof PROFILE_FORM_KEYS = PROFILE_FORM_KEYS;
  protected readonly VISIT_FORM_KEYS: typeof VISIT_FORM_KEYS = VISIT_FORM_KEYS;

  emailFormGroup: UntypedFormGroup | null = null;
  commentsFormGroup: UntypedFormGroup | null = null;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly userFormService: UserFormService,
    private readonly visitFormService: VisitFormService,
    private readonly userService: UserService,
    private readonly analyticsService: AnalyticsService,
    bookingState: BookingFlowStateService,
    bookingFlowNavigationService: BookingFlowNavigationService,
    bookingFlowDialogService: BookingFlowDialogService
  ) {
    super(bookingState, bookingFlowNavigationService, bookingFlowDialogService);
  }

  ngOnInit(): void {
    if (!this.bookingState.booking.patient.hasEmail) {
      this.emailFormGroup = this.userFormService.createEmailOnlyFormGroup();
    }

    if (this.authenticationService.currentUser.hasAnyRole(getEditorRoles())) {
      this.commentsFormGroup = this.visitFormService.createUpdateVisitCommentFormGroup(this.bookingState.booking.visits[0]);
    }
  }

  confirmBookingAndExit(): void {
    this.saveCommentsIfNeeded();

    forkJoin([
      this.saveUserEmailIfNeeded$(),
      this.bookingState.syncToServer$(),
      this.bookingState.confirm$(),
    ]).subscribe({
      next: () => {
        this.analyticsService.trackEvent('visit', 'create_visit_successful');
        this.navigationService.navigateToBookingSuccessPage(this.bookingState.pendingBookingId);
      },
      error: (err: Error): void => {
        this.handleBookingError(err);
      },
    });
  }

  handleBookingError(err: Error): void {
    const errorMessageTranslationKey: string = err instanceof HttpErrorResponse && err.status === 409
      ? 'pages.createAppointment.accountConfirmation.errors.alreadyBooked'
      : 'common.unknownError';

    this.dialogService.showErrorDialog(errorMessageTranslationKey)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private saveUserEmailIfNeeded$(): Observable<User> {
    if (!this.emailFormGroup?.valid) {
      return of(this.bookingState.booking.patient);
    }

    return this.userService.updateUser$(this.bookingState.booking.patient.id, { email: this.emailFormGroup.value.email });
  }

  private saveCommentsIfNeeded(): void {
    const internalComment = this.commentsFormGroup?.get(VISIT_FORM_KEYS.get('internalComment')).value?.trim();
    const externalComment = this.commentsFormGroup?.get(VISIT_FORM_KEYS.get('externalComment')).value?.trim();

    if (!internalComment && !externalComment) {
      return;
    }

    this.bookingState.booking.visits.forEach((visit: Visit): void => {
      visit.internalComment = internalComment ?? null;
      visit.externalComment = externalComment ?? null;
    });
  }
}

