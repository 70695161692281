<div>
  <label *ngIf="label">{{ label }}</label>
  <div class="flex-row-l-c gap-h-8">
    <vh-icon *ngIf="showIcon" src="assets/icons/world.svg"></vh-icon>
    <vh-select-input
      [ngClass]="{ 'short-names' : useShortLanguageNames }"
      [options]="visibleOptions"
      [value]="selectedOption?.value"
      (inputValueChange)="onLanguageSelected($event)">
    </vh-select-input>
  </div>
</div>

