<vh-tertiary-page-alt>
  <div class="flex-row gap-h-16 content columns">
    <div class="disclaimer" [ngStyle]="{ flex: 4 }">
      <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
        <p>{{ 'pages.profile.profileWarning' | translate }}</p>
      </vh-content-layout>
    </div>
    <div [ngStyle]="{ flex: 6 }">
      <vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
        <div class="profile-heading">
          <h1 class="subtitle">{{ user.firstName + ' ' + user.lastName }}</h1>
          <p class="subtitle">{{ 'pages.profile.subtitle' | translate }}</p>
        </div>
        <ng-container class="rightColumn" [ngTemplateOutlet]="mode === 'read' ? readTemplate : editTemplate"></ng-container>
      </vh-content-layout>
    </div>
  </div>
</vh-tertiary-page-alt>

<ng-template #readTemplate>
  <div class="content-container flex-col-l-c">
    <div class="properties-grid gap-h-16">
      <p class="has-text-primary">{{ 'common.fields.username' | translate }}</p>
      <p>{{ user.username }}</p>
      <p class="has-text-primary">{{ 'common.fields.password' | translate }}</p>
      <a [routerLink]="NAVIGATION.updatePassword.route">{{ 'pages.profile.actionChange' | translate }}</a>
      <p class="has-text-primary">{{ 'pages.register.lastNameInputLabel' | translate }}</p>
      <p>{{ user.lastName ? user.lastName : '/' }}</p>
      <p class="has-text-primary">{{ 'pages.register.firstNameInputLabel' | translate }}</p>
      <p>{{ user.firstName ? user.firstName : '/' }}</p>
      <p class="has-text-primary">{{ 'pages.register.birthdateInputLabel' | translate }}</p>
      <p>{{ user.birthdate ? (user.birthdate | dfnsFormat : DATE_FORMATS.clientDate) : '/' }}</p>
      <p class="has-text-primary">{{ 'pages.register.socialSecurityNumberInputLabel' | translate }}</p>
      <p>{{ user.socialSecurityNumber ? user.socialSecurityNumber : '/' }}</p>
      <p class="has-text-primary">{{ 'pages.register.genderInputLabel' | translate }}</p>
      <p>{{ translatedGender }}</p>
      <p class="has-text-primary">{{ 'pages.register.phoneInputLabel' | translate }}</p>
      <div class="flex-col-l-c multi-line-item">
        <p>{{ user.phone ? user.phone : '/' }}</p>
      </div>
      <p class="has-text-primary">{{ 'pages.register.emailInputLabel' | translate }}</p>
      <div class="flex-col-l-c multi-line-item">
        <p>{{ user.email ? user.email : '/' }}</p>
        <vh-message
          class="font-light-12-14 not-clickable"
          [ngClass]="{
            'has-text-primary': user.isEmailVerified,
            'has-text-error': !user.isEmailVerified
          }"
          [vhMarginTop]="6"
          [text]="
            (user.isEmailVerified ? 'pages.profile.verifyEmail.statusVerified' : 'pages.profile.verifyEmail.statusUnverified') | translate
          "
          [iconLeftPath]="user.isEmailVerified ? 'assets/icons/check-circle.svg' : 'assets/icons/times-circle.svg'"
          [iconLeftFill]="user.isEmailVerified ? COLORS.primary : COLORS.error"
          [iconLeftHeight]="12"
          [iconLeftWidth]="12">
        </vh-message>
        <vh-message
          *ngIf="!user.isEmailVerified"
          class="font-light-12-14 button-verify-email"
          [vhMarginTop]="6"
          [ngClass]="{
            'not-clickable': verificationEmailRequestWasSent,
            clickable: !verificationEmailRequestWasSent
          }"
          [isDisabled]="isSendingVerificationEmail"
          [iconLeftPath]="isSendingVerificationEmail ? 'assets/icons/hourglass.svg' : ''"
          [text]="
            (verificationEmailRequestWasSent ? 'pages.profile.verifyEmail.checkMailbox' : 'pages.profile.verifyEmail.actionVerifyNow')
              | translate
          "
          (messageClick)="onRequestSentVerificationEmailClicked()">
        </vh-message>
      </div>
      <p class="has-text-primary">{{ 'pages.profile.languageLabel' | translate }}</p>
      <div class="flex-row-l-c gap-h-8">
        <p>{{ user.language | uppercase }}</p>
      </div>
      <p class="has-text-primary">{{ 'pages.register.timezoneLabel' | translate }}</p>
      <div class="flex-row-l-c gap-h-8">
        <p>{{ user.timezone ?? '/' }}</p>
      </div>
      <p class="has-text-primary">{{ 'pages.profile.institutionsLabel' | translate }}</p>
      <div class="flex-col-l-c multi-line-item">
        <p *ngFor="let institute of user.institutions">
          {{ institute.name }}
        </p>
      </div>
    </div>

    <div [vhMarginTop]="32" class="actions-container flex-row-c-c gap-h-16">
      <vh-button
        [label]="'common.edit' | translate"
        [isDisabled]="deleteUserSubscription && !deleteUserSubscription?.closed"
        (buttonClick)="onEditProfileClicked()"></vh-button>
      <vh-button
        [label]="'pages.profile.removeAccount' | translate"
        [isDisabled]="deleteUserSubscription && !deleteUserSubscription?.closed"
        [style]="ButtonStyle.DANGER"
        (buttonClick)="openDeleteAccountConfirmationDialog()"></vh-button>
    </div>
  </div>
</ng-template>

<ng-template #editTemplate>
  <div class="content-container flex-col-l-c">
    <form class="properties-grid gap-h-16 gap-v-8" [formGroup]="formGroup">
      <p class="has-text-primary">{{ 'common.fields.username' | translate }}</p>
      <vh-text-input [value]="user.username" [isDisabled]="true"></vh-text-input>
      <p class="has-text-primary">{{ 'pages.register.lastNameInputLabel' | translate }}</p>
      <vh-text-input [formControlName]="PROFILE_FORM_KEYS.get('lastname')"></vh-text-input>
      <p class="has-text-primary">{{ 'pages.register.firstNameInputLabel' | translate }}</p>
      <vh-text-input [formControlName]="PROFILE_FORM_KEYS.get('firstname')"></vh-text-input>
      <p class="has-text-primary">{{ 'pages.register.birthdateInputLabel' | translate }}</p>
      <vh-text-input
        type="date"
        [formControlName]="PROFILE_FORM_KEYS.get('birthdate')"
        [placeholder]="'pages.register.birthdateInputLabel' | translate"
        [min]="DATE_BOUNDARIES.min.dateString"
        [max]="currentDate">
      </vh-text-input>
      <p class="has-text-primary">{{ 'pages.register.socialSecurityNumberInputLabel' | translate }}</p>
      <vh-text-input
        [formControlName]="PROFILE_FORM_KEYS.get('socialSecurityNumber')">
      </vh-text-input>
      <p class="has-text-primary">{{ 'pages.register.genderInputLabel' | translate }}</p>
      <vh-select-input [formControlName]="PROFILE_FORM_KEYS.get('gender')" [options]="genderOptions"></vh-select-input>
      <p class="has-text-primary">{{ 'pages.register.phoneInputLabel' | translate }}</p>
      <vh-phone-input
        [fieldControl]="formGroup.get(PROFILE_FORM_KEYS.get('phone'))"
        [initialValue]="formGroup.get(PROFILE_FORM_KEYS.get('phone'))?.value">
      </vh-phone-input>
      <p class="has-text-primary">{{ 'pages.register.emailInputLabel' | translate }}</p>
      <vh-text-input
        type="email"
        [formControlName]="PROFILE_FORM_KEYS.get('email')">
      </vh-text-input>
      <p class="has-text-primary">{{ 'pages.profile.languageLabel' | translate }}</p>
      <vh-language-picker
        [showIcon]="false"
        [formControlName]="PROFILE_FORM_KEYS.get('language')"
        [automaticallyStoreServerSideOnChange]="false">
      </vh-language-picker>
      <p class="has-text-primary">{{ 'pages.register.timezoneLabel' | translate }}</p>
      <vh-timezone-picker
        [formControlName]="PROFILE_FORM_KEYS.get('timezone')">
      </vh-timezone-picker>
    </form>

    <div [vhMarginTop]="32" class="actions-container flex-row-c-c gap-h-16">
      <vh-button
        [label]="'common.save' | translate"
        [isDisabled]="updateUserSubscription && !updateUserSubscription?.closed"
        (buttonClick)="onSaveProfileClicked()"
        vhRunFormValidation
        [runFormValidationOn]="formGroup">
      </vh-button>
      <vh-button
        [label]="'common.cancel' | translate"
        [isDisabled]="updateUserSubscription && !updateUserSubscription?.closed"
        [style]="ButtonStyle.TERTIARY"
        (buttonClick)="onCancelUpdateClicked()">
      </vh-button>
    </div>
  </div>
</ng-template>
