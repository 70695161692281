import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { ConversationNavigationService } from '@modules/conversation/services/conversation-flow-navigation.service';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { Subscription } from 'rxjs';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';
import { environment } from '@environments/environment';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';

@Component({
  selector: 'vh-base-create-conversation-page',
  templateUrl: './base-create-conversation-page.component.html',
  styleUrls: ['./base-create-conversation-page.component.scss'],
})
@UntilDestroy()
export class BaseCreateConversationPageComponent implements OnInit, OnDestroy {
  step: number = 1;
  wizardState: WizardState;
  currentUserTokens: number;
  lowTokensAmount: number = environment.app.lowTokensAmount;

  protected shouldShowLowTokensWarning: boolean = false;
  protected shouldShowNoTokensWarning: boolean = false;

  private stepSubscription: Subscription;

  constructor(
    protected readonly conversationNavigationService: ConversationNavigationService,
    private readonly router: Router,
    private readonly conversationService: CreateConversationService,
    private readonly walletService: WalletService
  ) { }

  ngOnInit(): void {
    this.conversationService.reset();

    this.getTokens();

    this.stepSubscription = this.conversationService.setStep$
      .pipe(untilDestroyed(this))
      .subscribe((step: number) => {
        this.wizardState.goToStep(step);
      });

    this.initWizard();

    void this.router.navigate([CONVERSATION_NAV.create.selectPatient.route]);
  }

  private initWizard(): void {
    this.wizardState = new WizardState([
      'conversations.create.menu.first',
      'conversations.create.menu.second',
      'conversations.create.menu.third',
    ]);

    this.wizardState.goToStart();
  }

  leave(): void {
    this.conversationService.reset();
    this.conversationNavigationService.navigateToConversationList();
  }

  onTokensClicked(): void {
    void this.router.navigate([NAVIGATION.wallet.route]);
  }

  ngOnDestroy(): void {
    if (this.stepSubscription) {
      this.stepSubscription.unsubscribe();
    }
  }

  private getTokens(): void {
    // We reload the user for good measure as it contains the wallet balance
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.currentUserTokens = wallet?.tokens;
        this.shouldShowLowTokensWarning = wallet.tokens < this.lowTokensAmount && !wallet.isAutoReplenishEnabled;
        this.shouldShowNoTokensWarning = wallet.tokens === 0 && !wallet.isAutoReplenishEnabled;
      });
  }
}
