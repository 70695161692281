import { TemplatePortal } from '@angular/cdk/portal';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { Subject, takeUntil } from 'rxjs';
import { AfterViewInit, Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { PopoverService } from '@services/ui/popover.service';

export type PopoverPositioningStrategy = 'top_or_bottom' | 'left_or_right';
export type PopoverPosition = 'top' | 'bottom' | 'left' | 'right';

/**
 * Code based upon https://stackoverflow.com/a/69698661
 */
@Directive({
  selector: '[vhPopoverHost]',
})
export class PopoverHostDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('vhPopoverHost') id: string;
  @Input() popoverTemplate: TemplateRef<unknown>;
  @Input() popoverHasBackdrop: boolean;
  @Input() popoverConnectToFirstHtmlElementWithTagName: string;
  @Input() popoverCloseOnClickOutside: boolean;
  @Input() popoverWidth: number | string;
  @Input() popoverHeight: number | string;
  @Input() popoverPanelClass: string | string[];
  @Input() popoverScrollStrategy: 'block' | 'reposition' | 'close' | 'noop' = 'close';

  /**
   * Sets the positioning strategy to open the popover either above or below the component or left or right of the component
   */
  @Input() popoverPositioningStrategy: PopoverPositioningStrategy = 'top_or_bottom';

  /**
   * Sets the preferred position of the popover. For example if the popoverPositioningStrategy is "top_or_bottom" and the popoverPreferredPosition is "bottom" then the component will
   * show the element below the component IF there is enough room
   */
  @Input() popoverPreferredPosition: PopoverPosition = 'bottom';

  /**
   * Overrides the popoverPositioningStrategy and popoverPreferredPosition properties. This will show the popover at the position given even if there is no available space for it.
   */
  @Input() popoverEnforcedPosition: PopoverPosition;

  private readonly destroy$: Subject<void> = new Subject<void>();
  private overlayRef: OverlayRef | undefined;

  constructor(
    private readonly popoverService: PopoverService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly overlay: Overlay
  ) {}

  ngOnInit(): void {
    if (!this.id || !this.popoverTemplate) {
      throw new Error('An id and template must be passed into the PopoverHostDirective');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popoverPositioningStrategy || changes.popoverPreferredPosition || changes.popoverEnforcedPosition) {
      this.overlayRef?.updatePositionStrategy(this.determinePositionStrategy());
    }

    if (changes.popoverWidth) {
      this.overlayRef?.updateSize({
        width: changes.popoverWidth?.currentValue ?? this.overlayRef.hostElement.offsetWidth,
      });
    }

    if (changes.popoverHeight) {
      this.overlayRef?.updateSize({
        height: changes.popoverHeight?.currentValue ?? this.overlayRef.hostElement.offsetHeight,
      });
    }
  }

  ngAfterViewInit(): void {
    this.createOverlayRef();

    // Then we create a portal to render a component
    const templatePortal = new TemplatePortal(this.popoverTemplate, this.viewContainerRef);

    this.popoverService.togglePopover$
      .pipe(takeUntil(this.destroy$))
      .subscribe((id: string) => {
        if (id !== this.id) {
          return;
        }

        if (this.overlayRef.hasAttached()) {
          this.overlayRef.detach();

          return;
        }

        this.overlayRef.attach(templatePortal);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.overlayRef?.dispose();
    this.overlayRef = null;
  }

  private createOverlayRef(): void {
    const config: OverlayConfig = {
      panelClass: this.buildPanelClasses(),
      hasBackdrop: this.popoverHasBackdrop,
      positionStrategy: this.determinePositionStrategy(),
      scrollStrategy: this.buildScrollStrategy(),
    };

    if (this.popoverHeight) {
      config.height = this.popoverHeight;
    }

    if (this.popoverWidth) {
      config.width = this.popoverWidth;
    }

    this.overlayRef = this.overlay.create(config);
    this.configureCloseOnClickOutsideBehaviour();
  }

  private configureCloseOnClickOutsideBehaviour(): void {
    if (!this.popoverCloseOnClickOutside) {
      return;
    }

    this.overlayRef.detachBackdrop();
    this.overlayRef.outsidePointerEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: MouseEvent) => {
        // Prevent handling clicking on the element that triggers opening the popup as a click outside
        if (this.viewContainerRef.element.nativeElement.contains(event.target as HTMLElement)) {
          return;
        }

        this.popoverService.close(this.id);
      });
  }

  private determinePositionStrategy(): PositionStrategy {
    const showOverElement: HTMLElement = this.getElementToConnectTo();
    const connectedPosition: ConnectedPosition[] = this.determineConnectedPosition();

    return this.overlay
      .position()
      .flexibleConnectedTo(showOverElement)
      .withPositions(connectedPosition);
  }

  private determineConnectedPosition(): ConnectedPosition[] {
    if (this.popoverEnforcedPosition) {
      switch (this.popoverEnforcedPosition) {
        case 'top':
          return [this.getTopPositionStrategy()];
        case 'bottom':
          return [this.getBottomPositionStrategy()];
        case 'left':
          return [this.getLeftPositionStrategy()];
        case 'right':
          return [this.getRightPositionStrategy()];
      }
    }

    return this.popoverPositioningStrategy === 'top_or_bottom'
      ? this.getTopOrBottomPositionStrategy()
      : this.getLeftOrRightPositionStrategy();
  }

  private getTopOrBottomPositionStrategy(): ConnectedPosition[] {
    const result: ConnectedPosition[] = [
      this.getBottomPositionStrategy(),
      this.getTopPositionStrategy(),
    ];

    if (this.popoverPreferredPosition === 'bottom') {
      result.reverse();
    }

    return result;
  }

  private getLeftOrRightPositionStrategy(): ConnectedPosition[] {
    const result: ConnectedPosition[] = [
      this.getLeftPositionStrategy(),
      this.getRightPositionStrategy(),
    ];

    if (this.popoverPreferredPosition === 'right') {
      result.reverse();
    }

    return result;
  }

  private getLeftPositionStrategy(): ConnectedPosition {
    return {
      originX: 'end',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
    };
  }

  private getRightPositionStrategy(): ConnectedPosition {
    return {
      originX: 'start',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
    };
  }

  private getBottomPositionStrategy(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    };
  }

  private getTopPositionStrategy(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    };
  }

  private buildPanelClasses(): string[] {
    const result: string[] = ['popover'];

    if (!this.popoverPanelClass) {
      return result;
    }

    if (Array.isArray(this.popoverPanelClass)) {
      return result.concat(this.popoverPanelClass);
    }

    return result.concat(this.popoverPanelClass.split(' '));
  }

  private buildScrollStrategy(): ScrollStrategy {
    switch (this.popoverScrollStrategy) {
      case 'block':
        return this.overlay.scrollStrategies.block();
      case 'reposition':
        return this.overlay.scrollStrategies.reposition();
      case 'close':
        return this.overlay.scrollStrategies.close();
      case 'noop':
        return this.overlay.scrollStrategies.noop();
    }
  }

  private getElementToConnectTo(): HTMLElement {
    const result = this.popoverConnectToFirstHtmlElementWithTagName
      ? document.getElementsByTagName(this.popoverConnectToFirstHtmlElementWithTagName)[0]
      : this.viewContainerRef.element.nativeElement;

    if (!result) {
      throw new Error(
        this.popoverConnectToFirstHtmlElementWithTagName
          ? `${this.popoverConnectToFirstHtmlElementWithTagName} does not exist or does not exist in the current view. Are you sure the element/component exists?`
          : 'No element found to connect the popover to'
      );
    }

    return result;
  }
}
