import { Directive, ElementRef, NgZone } from '@angular/core';
import { SlotTrackingEventService } from '@services/tracking/slot-tracking-event.service';
import { AbstractTrackerDirective } from '@modules/shared/core/directives/abstract-tracker.directive';
import { BaseVisitProperties } from '@modules/shared/cards/interfaces/base-visit-properties.interface';

@Directive({
  selector: '[vhSlotTracker]',
})
export class SlotTrackerDirective extends AbstractTrackerDirective<BaseVisitProperties> {
  // eslint thinks this constructor is useless, but it's not. It injects the correct instance of the
  // trackingService which inherits from AbstractTrackingService<T>
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(el: ElementRef<HTMLElement>, ngZone: NgZone, trackingService: SlotTrackingEventService) {
    super(el, ngZone, trackingService);
  }
}
