import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@services/authentication.service';
import { ConversationQuestionList, ConversationQuestionListJson } from '@models/conversation-question-list.model';
import { IObject } from '@app-types/iobject.type';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class ConversationQuestionListService {
  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) { }

  getStandardConversationQuestions$(specialtyId: string | null = null): Observable<ConversationQuestionList[]> {
    const endpoint: string = ENDPOINTS.getStandardConversationQuestions.route;
    const options: IObject = {
      params: {},
    };

    if (specialtyId) {
      options.params.specialty_id = specialtyId;
    }

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: ConversationQuestionListJson[]): ConversationQuestionList[] =>
        json.map((questionListJson: ConversationQuestionListJson): ConversationQuestionList => ConversationQuestionList.fromJson(questionListJson)))
      );
  }

  getConversationQuestionListsForCurrentInstitution$(): Observable<ConversationQuestionList[]> {
    return this.getConversationQuestionListsForInstitution$(this.authenticationService.institution.id);
  }

  getConversationQuestionListsForInstitution$(institutionId: string): Observable<ConversationQuestionList[]> {
    const endpoint: string = ENDPOINTS.getConversationQuestionsForInstitution.route
      .replace(/{.*}/, institutionId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: ConversationQuestionListJson[]): ConversationQuestionList[] =>
        json.map((questionListJson: ConversationQuestionListJson): ConversationQuestionList => ConversationQuestionList.fromJson(questionListJson)))
      );
  }

  attachConversationQuestionListsToInstitution$(questionListIds: string[], institutionId: string): Observable<void> {
    const endpoint: string = ENDPOINTS.attachConversationQuestionsToInstitution.route
      .replace(/{.*}/, institutionId);

    return this.http
      .post(environment.apiBaseUrl + endpoint, { conversation_question_ids: questionListIds })
      .pipe(switchToEmptyObservable());
  }
}
