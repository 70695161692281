<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-32" [vhMarginTop]="16">
  <p *ngIf="isResuming" class="has-text-primary">{{ 'onboarding.resuming.text' | translate }}</p>
  <p [innerHTML]="'onboarding.wallet.intro1' | translate: { 'demoAmount' : demoTokensAmount }"></p>

  <vh-token-balance *ngIf="!pollingActive" [balance]="demoTokensAmount"></vh-token-balance>

  <vh-credit-card-form
    *ngIf="!pollingActive"
    (mollieTokenGenerated)="onMollieTokenReady($event)"
    (mollieTokenErrorOccurred)="onMollieTokenError($event)"
    (validationStateChanged)="onValidationStateChanged($event)">
  </vh-credit-card-form>

  <div *ngIf="pollingActive" class="flex-col-c-c gap-v-24">
    <p>{{ 'common.processing' | translate }}</p>
    <vh-loader></vh-loader>
  </div>

  <vh-message
    *ngIf="error"
    class="not-clickable"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="'onboarding.errors.mollieStatus.' + error | translate">
  </vh-message>

  <vh-button
    class="align-center"
    [isDisabled]="!mollieToken || isSubmitting"
    [label]="'common.next' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>

</section>
