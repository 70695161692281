<router-outlet></router-outlet>

<div *ngIf="!cookiePopupIsClosed" class="cookie">
  <div class="flex-row gap-h-16">
    <img src="assets/images/cookie.svg" />
    <div>
      <h1>{{ 'cookie.title' | translate }}</h1>
      <p [vhMarginTop]="8">{{ 'cookie.text' | translate }}</p>
    </div>
  </div>

  <div [vhMarginTop]="24" class="flex-row-c-c gap-h-8">
    <vh-button [label]="'common.accept' | translate" (buttonClick)="onAcceptCookies()"></vh-button>
    <vh-button [label]="'common.decline' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="onDeclineCookies()"></vh-button>
  </div>
</div>
