import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ServiceType, ServiceTypeJson } from '@models/service-type.model';
import { Institution } from '@models/institution.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceTypeService {
  constructor(private readonly http: HttpClient) {}

  getServiceTypes$ = (): Observable<ServiceType[]> => {
    return this.http
      .get(environment.apiBaseUrl + ENDPOINTS.getServiceTypes.route)
      .pipe(map((json: ServiceTypeJson[]): ServiceType[] => json.map(ServiceType.fromJson)));
  };

  getServiceTypesOfInstitution$ = (institutionId: Institution | string): Observable<ServiceType[]> => {
    institutionId = institutionId instanceof Institution ? institutionId.id : institutionId;

    const url = environment.apiBaseUrl + ENDPOINTS.getServiceTypesOfInstitution.route
      .replace(`{${ENDPOINTS.getServiceTypesOfInstitution.pathParams.institutionId}}`, institutionId);

    const queryParams = {
      params: {
        [ENDPOINTS.getServiceTypesOfInstitution.queryParams.hasChildren]: true,
      },
    };

    return this.http
      .get(url, queryParams)
      .pipe(map((json: ServiceTypeJson[]): ServiceType[] => json.map(ServiceType.fromJson)));
  };
}
