import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { buffer, Observable, of, Subject } from 'rxjs';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

/**
 * This service can be used to track events throughout the application. To avoid multiple calls to the backend for each
 * item tracked it can automatically aggregate incoming tracking events and send them in a single call to the backend.
 * Due to this mechanism the code might seem more complex than needed but in a nutshell the track() function can be called
 * to track events and the processIncomingTrackingRequests() listens for the bundled events and sends them to the backend.
 *
 * Aggregation mechanism adapted from:
 * https://web.archive.org/web/20230124122742/https://dev.to/datadeer/debounced-aggregated-buffered-actions-with-rxjs-6-3koa
 */
@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export abstract class AbstractTrackingService<T> {

  /**
   * This variable keeps track of events which are of type VIEWED.
   * VIEWED events should only be triggered once per session.
   * @private
   */
  protected readonly _viewedTrackedEvents: Set<string>;

  protected readonly _addEventSubject: Subject<TrackingEventDefinition<T>>;
  protected readonly _debounceAddEvent$: Observable<TrackingEventDefinition<T>>;
  protected readonly _addEvent$: Observable<TrackingEventDefinition<T>[]>;
  protected readonly _debounceTimeMs: number = 500;

  protected constructor(private readonly http: HttpClient) {
    this._viewedTrackedEvents = new Set<string>();
    this._addEventSubject = new Subject();
    this._debounceAddEvent$ = this._addEventSubject.pipe(debounceTime(this._debounceTimeMs));
    this._addEvent$ = this._addEventSubject.pipe(buffer(this._debounceAddEvent$));
    this.processIncomingTrackingRequests();
  }

  protected abstract map(trackingEventType: TrackingEventType, item: T): Record<string, string>;

  protected abstract get endpoint(): string;

  protected abstract get bulkEndpoint(): string;

  protected abstract getUniqueIdentifier(item: T): string;

  private get viewedTrackedEvents(): Set<string> {
    return this._viewedTrackedEvents;
  }

  hasTrackedEventAsViewed(item: T): boolean {
    return this.viewedTrackedEvents.has(this.getUniqueIdentifier(item));
  }

  markEventAsViewed(item: T): void {
    this.viewedTrackedEvents.add(this.getUniqueIdentifier(item));
  }

  markEventsAsViewed(items: T[]): void {
    items.forEach(this.markEventAsViewed);
  }

  /**
   * This function will aggregate multiple calls to itself, so it can use the bulk insert call instead
   * of multiple calls.
   */
  track = (trackingEventType: TrackingEventType, items: T | T[]): void => {
    if (Array.isArray(items)) {
      items.forEach((item: T) => {
        this._addEventSubject.next({ event: item, type: trackingEventType });
      });
    }

    this._addEventSubject.next({ event: items as T, type: trackingEventType });
  };

  private trackSingleEvent = (event: TrackingEventDefinition<T>): Observable<void> => {
    const body = this.map(event.type, event.event);

    return this.http
      .post(this.endpoint, body)
      .pipe(switchToEmptyObservable());
  };

  private trackMultipleEvents = (events: TrackingEventDefinition<T>[]): Observable<void> => {
    if (events.length === 1) {
      return this.trackSingleEvent(events[0]);
    }

    const uniqueIdentifiers = events.map((item: TrackingEventDefinition<T>) => this.getUniqueIdentifier(item.event));
    const body = events
      // Filter possible duplicates
      .filter((item: TrackingEventDefinition<T>, index: number) => uniqueIdentifiers.indexOf(this.getUniqueIdentifier(item.event)) === index)
      .map((item: TrackingEventDefinition<T>) => this.map(item.type, item.event));

    if (body.length === 0) {
      return of(void 0);
    }

    return this.http
      .post(this.bulkEndpoint, body)
      .pipe(tap(() => this.trackMultipleEvents(events)))
      .pipe(switchToEmptyObservable());
  };

  private processIncomingTrackingRequests = (): void => {
    this._addEvent$
      .pipe(untilDestroyed(this))
      .subscribe((trackingEvents: TrackingEventDefinition<T>[]) => {
        this.trackMultipleEvents(trackingEvents).pipe(untilDestroyed(this)).subscribe();
      });
  };
}

interface TrackingEventDefinition<T> {
  event: T;
  type: TrackingEventType;
}
