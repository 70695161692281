<label *ngIf="label" [innerHTML]="label"></label>
<div
  class="flex-col input-wrapper"
  [vhPopoverHost]="popoverId"
  [popoverTemplate]="optionsTemplate"
  [popoverEnforcedPosition]="'bottom'"
  [popoverCloseOnClickOutside]="true"
  [popoverWidth]="inputElement.offsetWidth">
  <input
    #inputElement
    type="text"
    [ngClass]="{
      'no-border-radius-bottom': filteredOptions?.length,
      error: hasError
    }"
    [disabled]="isDisabled"
    [ngModel]="displayedValue"
    [placeholder]="placeholder ?? ''"
    (ngModelChange)="handleInputChange($event)"
    (click)="handleInputClick()" />
  <vh-icon src="assets/icons/search.svg" [fill]="COLORS.tertiary"></vh-icon>
</div>

<ng-template #optionsTemplate>
  <div class="options clickable" *ngIf="filteredOptions?.length" [ngStyle]="{ 'max-height': maxOptionsHeight ? maxOptionsHeight :optionsHeight }">
    <div *ngFor="let option of filteredOptions" [innerHTML]="option.label" (click)="handleOptionClick(option.value)"></div>
  </div>
</ng-template>
