import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'vh-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit {
  @Input() fieldControl: AbstractControl;
  @Input() initialValue: string;

  ngOnInit(): void {
    if (this.initialValue) {
      this.fieldControl.setValue(this.formatPhoneNumber(this.initialValue));
    }
  }

  private formatPhoneNumber(phone: string): string {
    if (!phone) {
      return phone;
    }

    return phone.startsWith('+')
      ? phone
      : `+32${phone.startsWith('0') ? phone.substring(1) : phone}`;
  }
}
