import { Injectable } from '@angular/core';
import { defer, fromEvent, Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { AlertDialogService } from '@services/ui/alert-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class LocationPermissionService {
  protected _lastKnownUserPosition?: GeolocationPosition;
  protected _lastKnownPermissionState?: PermissionState;
  protected _permissionStateSubscription: Subscription;

  get lastKnownUserPosition(): GeolocationPosition {
    return this._lastKnownUserPosition;
  }

  get lastKnownPermissionState(): PermissionState {
    return this._lastKnownPermissionState;
  }

  constructor(private readonly alertDialogService: AlertDialogService) {
    this._permissionStateSubscription = this.getUserLocationPermissionState$().subscribe();
  }

  /**
   * For more info regarding permissions, see: https://www.w3.org/TR/permissions/#dfn-denied
   */
  hasPermission(): boolean {
    return this.lastKnownPermissionState !== undefined &&
      this.lastKnownPermissionState === 'granted' ||
      this.lastKnownPermissionState === 'prompt' &&
      this.lastKnownUserPosition !== undefined;
  }

  shouldShowPermissionDeniedDialog(): boolean {
    return !this.hasPermission();
  }

  getUserLocationPermissionState$(): Observable<PermissionState> {
    return defer(() => navigator.permissions.query({ name: 'geolocation' }))
      .pipe(
        switchMap((permissionStatus: PermissionStatus) => {
          return fromEvent(permissionStatus, 'change').pipe(
            map(() => permissionStatus.state),
            startWith(permissionStatus.state)
          );
        })
      ).pipe(tap((state: PermissionState) => this._lastKnownPermissionState = state));
  }

  showLocationPermissionDeniedDialog = (): void => {
    this.alertDialogService.open({
      confirmTextTranslationKey: 'common.ok',
      titleTranslationKey: 'pages.institutionsOverview.errors.locationSharingDenied',
      messageTranslationKey: 'pages.institutionsOverview.noLocationPermissionGrantedDialog.message',
    });
  };
}
