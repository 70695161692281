import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { CoreModule } from '@modules/shared/core/core.module';
import { NgxMaskDirective } from 'ngx-mask';
import { ExpandableCardComponent } from '@modules/shared/cards/components/expandable-card/expandable-card.component';
import { DateCardComponent } from '@modules/shared/cards/components/date-card/date-card.component';
import { VisitCardComponent } from '@modules/shared/cards/components/visit-card/visit-card.component';
import { InstitutionCardComponent } from '@modules/shared/cards/components/institution-card/institution-card.component';
import { VisitCardGroupComponent } from '@modules/shared/cards/components/visit-card-group/visit-card-group.component';
import { FormModule } from '@modules/shared/form/form.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MultiVisitCardComponent } from '@modules/shared/cards/components/multi-visit-card/multi-visit-card.component';
import { GenericCardSkeletonComponent } from '@modules/shared/cards/components/generic-card-skeleton/generic-card-skeleton.component';
import { BaseCardComponent } from '@modules/shared/cards/components/base-card/base-card.component';
import { PatientSharedModule } from '@modules/patient/patient-shared.module';

const sharedIngredients = [
  ExpandableCardComponent,
  DateCardComponent,
  VisitCardComponent,
  InstitutionCardComponent,
  VisitCardGroupComponent,
  MultiVisitCardComponent,
  BaseCardComponent,
  GenericCardSkeletonComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
    MatDialogModule,
    QuillEditorComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    PatientSharedModule,
  ],
  exports: sharedIngredients,
})
export class CardModule {}
