<vh-page class="page">
  <div class="grid-container">
    <div class="header">
      <vh-navigation-return-link
        (navigateBackClick)="navigateBackClick.emit()">
      </vh-navigation-return-link>
      <h1>{{ title }}</h1>
      <span></span>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</vh-page>
