import { Component, OnInit } from '@angular/core';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

@Component({
  selector: 'vh-onboarding-success-page',
  templateUrl: './onboarding-success-page.component.html',
  styleUrls: ['./onboarding-success-page.component.scss'],
})
@UntilDestroy()
export class OnboardingSuccessPageComponent extends BaseOnboardingPageComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) {
    super();
  }

  onNextClicked(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }

  setStep(): void {
    this.state.goToStep(this.STEP_FINISHING_UP);
  }
}
