import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { NAVIGATION } from '@constants/navigation.constant';
import { getSettingsNavigationItems } from '@modules/settings/constants/settings-navigation-items.constant';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { ModuleService } from '@services/module.service';
import { ModuleKey } from '@enums/module-key.enum';

@Component({
  selector: 'vh-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent implements OnInit {
  navigationItems: NavigationItem[] = [];
  settingsNavigationItems: NavigationItem[] = [];

  constructor(
    private readonly router: Router,
    private readonly moduleService: ModuleService
  ) {
    this.navigationItems = getNavigationItems();
    this.settingsNavigationItems = getSettingsNavigationItems();
  }

  ngOnInit(): void {
    this.loadFirstPage();
  }

  private loadFirstPage(): void {
    // If any other page is opened, do nothing
    if (this.router.url !== NAVIGATION.adminDashboardSettings.route) {
      return;
    }

    // If the user has the booking module, we want to load the planning page for backwards compatibility with MEDPLaNNER.
    if (this.moduleService.hasAccessToModule(ModuleKey.BOOKING)) {
      void this.router.navigate([NAVIGATION.adminDashboardSettings.rooms.route]);

      return;
    }

    // If not, we want to load the first page of the settings navigation
    void this.router.navigate([this.settingsNavigationItems[0].subNavigationItems[0].route]);
  }
}
