import { OnInit, Directive } from '@angular/core';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { Institution } from '@models/institution.model';
import { Mode } from '@enums/mode.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';
import { environment } from '@environments/environment';

@UntilDestroy()
@Directive()
export abstract class BaseNavigationProfileComponent implements OnInit {
  protected readonly Mode: typeof Mode = Mode;

  protected wallet: Wallet | null = null;
  protected currentUser: User;
  protected currentInstitution: Institution;
  protected displayMode: Mode;
  protected lowTokensAmount: number = environment.app.lowTokensAmount;
  protected shouldShowLowTokensWarning: boolean = false;

  protected constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly walletService: WalletService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;

    if (this.authenticationService.institution) {
      this.currentInstitution = this.authenticationService.institution;
      this.displayMode = this.authenticationService.mode;
    }

    this.reloadWallet();
  }

  protected reloadWallet(): void {
    if (!this.currentUser.hasAnyInstitutionRole()) {
      return;
    }

    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.wallet = wallet;
        this.shouldShowLowTokensWarning = this.wallet.tokens < this.lowTokensAmount && !this.wallet.isAutoReplenishEnabled;
      });
  }
}
