import { Injectable } from '@angular/core';
import { defaultPreferences, Preferences } from '@interfaces/preferences.interface';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { dot } from '@utils/helpers/object.util';
import { Subject } from 'rxjs';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  preferencesChanged$: Subject<Preferences> = new Subject<Preferences>();

  constructor(private readonly localStorageService: LocalStorageCacheService) {
  }

  getPreferences(): Preferences {
    if (!this.localStorageService.get<Preferences>(LOCAL_STORAGE_KEYS.preferences)) {
      return defaultPreferences;
    }

    return this.mergeWithDefault(
      this.localStorageService.get<Preferences>(LOCAL_STORAGE_KEYS.preferences)
    );
  }

  storePreferences(preferences: Preferences): void {
    this.localStorageService.set(LOCAL_STORAGE_KEYS.preferences, preferences);

    this.preferencesChanged$.next(preferences);
  }

  storePreference(path: string, value: unknown): void {
    let preferences = this.getPreferences();
    preferences = dot(preferences, path, value) as Preferences;
    this.storePreferences(preferences);
    this.preferencesChanged$.next(preferences);
  }

  private mergeWithDefault(preferences: Preferences): Preferences {
    return {
      ...defaultPreferences,
      ...preferences,
    };
  }
}
