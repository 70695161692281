<vh-tertiary-page *ngIf="!contactRequestIsSubmitted">
  <ng-container [formGroup]="contactRequestFormGroup">
    <h1>{{ 'pages.createAppointment.contact.title' | translate }}</h1>
    <p [vhMarginTop]="8">{{ 'pages.createAppointment.contact.text' | translate }}</p>
    <div class="flex-col gap-v-16" [vhMarginTop]="16">
      <vh-text-input
        autocomplete="given-name"
        [formControlName]="CONTACT_FORM_KEYS.get('firstName')"
        [label]="'pages.createAppointment.contact.firstNameInputLabel' | translate"
        [placeholder]="'pages.createAppointment.contact.firstNameInputLabel' | translate"></vh-text-input>
      <vh-text-input
        autocomplete="family-name"
        [formControlName]="CONTACT_FORM_KEYS.get('lastName')"
        [label]="'pages.createAppointment.contact.lastNameInputLabel' | translate"
        [placeholder]="'pages.createAppointment.contact.lastNameInputLabel' | translate"></vh-text-input>
      <vh-phone-input
        [fieldControl]="contactRequestFormGroup.get(CONTACT_FORM_KEYS.get('phone'))"
        [initialValue]="contactRequestFormGroup.get(CONTACT_FORM_KEYS.get('phone'))?.value">
      </vh-phone-input>
      <vh-text-input
        autocomplete="email"
        type="email"
        [formControlName]="CONTACT_FORM_KEYS.get('email')"
        [label]="'pages.createAppointment.contact.emailInputLabel' | translate"
        [placeholder]="'pages.createAppointment.contact.emailInputLabel' | translate">
      </vh-text-input>
    </div>
    <vh-message
      *ngIf="contactRequestErrorMessageTranslationKey"
      [vhMarginTop]="16"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-alert)'"
      [text]="contactRequestErrorMessageTranslationKey | translate"></vh-message>
    <vh-button
      [vhMarginTop]="32"
      [label]="'pages.createAppointment.contact.submitButtonLabel' | translate"
      [iconRightPath]="'assets/icons/arrow-right-white.svg'"
      [alignment]="'center'"
      [isDisabled]="contactRequestButtonIsDisabled"
      (buttonClick)="submitContactRequest()"></vh-button>
  </ng-container>
</vh-tertiary-page>

<vh-tertiary-page *ngIf="contactRequestIsSubmitted" [returnLinkIsVisible]="false" [doctorIsVisible]="true">
  <h1>{{ 'pages.createAppointment.contact.submitted.title' | translate }}</h1>
  <p [vhMarginTop]="8">{{ 'pages.createAppointment.contact.submitted.text' | translate }}</p>
  <vh-button
    [vhMarginTop]="32"
    [label]="'pages.createAppointment.contact.submitted.finishButtonLabel' | translate"
    [iconRightPath]="'assets/icons/arrow-right-white.svg'"
    [alignment]="'center'"
    [routerLink]="NAVIGATION.dashboard.route"></vh-button>
</vh-tertiary-page>
