<div class="flex-col gap-v-8" [vhMarginTop]="16">
  <vh-conversation-card
    *ngFor="let conversation of conversations"
    class="clickable"
    [conversation]="conversation"
    (click)="clickConversation(conversation, $event)">
  </vh-conversation-card>
</div>

<vh-loader class="loader" *ngIf="conversations.length >= 0 && loading"></vh-loader>

<p [vhMarginTop]="24" *ngIf="conversations.length === 0 && !loading">
  {{ 'conversations.noConversations' | translate }}
</p>
