import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { InstitutionFormService } from '@services/form-services/institution-form.service';
import { Address } from '@models/address.model';
import { CREATE_INSTITUTION_FORM_KEYS } from '@constants/form-keys/create-institution-form-keys.constant';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NewOnboardingService } from '@services/new-onboarding.service';
import { HttpClient } from '@angular/common/http';
import * as countries from 'i18n-iso-countries';
import * as enLocale from 'i18n-iso-countries/langs/en.json';
import { Option } from '@interfaces/option.interface';

@Component({
  selector: 'vh-onboarding-institution-page',
  templateUrl: './onboarding-institution-page.component.html',
  styleUrls: ['./onboarding-institution-page.component.scss'],
})
@UntilDestroy()
export class OnboardingInstitutionPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly CREATE_INSTITUTION_FORM_KEYS: typeof CREATE_INSTITUTION_FORM_KEYS = CREATE_INSTITUTION_FORM_KEYS;

  formGroup: UntypedFormGroup;
  selectedAddress?: Address;
  countryOptions: Option[] = [];
  selectedCountry?: Option;
  isSubmitting: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly institutionFormService: InstitutionFormService,
    private readonly newOnboardingService: NewOnboardingService,
    private readonly http: HttpClient
  ) {
    super();
    countries.registerLocale(enLocale);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this.institutionFormService.createFormGroup();
    this.loadCountries();
    this.detectCountry();
  }

  private loadCountries(): void {
    const countryNames = countries.getNames('en', { select: 'official' });
    this.countryOptions = Object.keys(countryNames).map((code: string) => ({
      label: countryNames[code],
      value: countryNames[code],
    }));
  }

  private detectCountry(): void {
    this.http.get<any>('http://ip-api.com/json')
      .subscribe((response: any) => {
        if (response.countryCode) {
          const detectedCountry = this.countryOptions.find((option: Option) => option.value === response.countryCode);
          if (detectedCountry) {
            this.formGroup.patchValue({
              country: detectedCountry.value,
            });
          }
        }
      });
  }

  onNextClicked(): void {
    const onboardingPayload = {
      ...this.newOnboardingService.getOnboardingPayload(),
      institution_name: this.formGroup.value.name,
      institution_country: this.formGroup.value.country,
    };
    this.newOnboardingService.setOnboardingPayload(onboardingPayload);

    void this.router.navigate([ONBOARDING_NAV.createAccount.route]);
  }

  setStep(): void {
    this.state.goToStep(this.STEP_INSTITUTION);
  }

  onAddressClicked(place: google.maps.places.PlaceResult): void {
    this.selectedAddress = Address.fromPlacesApiResult(place);
  }

  onCountrySelected(country: Option): void {
    this.selectedCountry = country;
    const onboardingPayload = {
      ...this.newOnboardingService.getOnboardingPayload(),
      institution_country: country.value,
    };
    this.newOnboardingService.setOnboardingPayload(onboardingPayload);
  }
}
