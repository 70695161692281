import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonStyle } from '@enums/button-style.enum';
import { Service } from '@models/service.model';
import { Option } from '@interfaces/option.interface';
import { ServiceService } from '@services/service.service';
import { SERVICE_FORM_KEYS } from '@constants/form-keys/service-form-keys.constant';
import { COLORS } from '@constants/colors.constant';
import { ServiceFormService } from '@services/form-services/service-form.service';
import { IObject } from '@app-types/iobject.type';

@Component({
  selector: 'vh-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.scss'],
})
export class ServiceSettingsComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly SERVICE_FORM_KEYS: typeof SERVICE_FORM_KEYS = SERVICE_FORM_KEYS;
  protected readonly COLORS: typeof COLORS = COLORS;

  errorMessageTranslationKey: string;
  formGroup: UntypedFormGroup;
  mode: 'read' | 'edit' = 'read';

  @Input() serviceOptions: Option[];
  @Input() service: Service;
  @Input() canRemove: boolean;
  @Input() selectedLanguage: string;
  @Input() defaultLanguage: string;

  @Output() saveClick: EventEmitter<Service> = new EventEmitter<Service>();
  @Output() removeClick: EventEmitter<Service> = new EventEmitter<Service>();

  attachServiceSubscription: Subscription;

  constructor(private readonly serviceFormService: ServiceFormService, private readonly serviceService: ServiceService) {}

  get options(): Option[] {
    // Add the current selected service as an option otherwise you won't be able to (re)select it
    if (this.service?.id && !this.serviceOptions.some((option: Option) => option.value === this.service.id)) {
      this.serviceOptions.push({ value: this.service.id, label: this.service.name });
    }

    return this.serviceOptions
      .sort((a: Option, b: Option) => a.label?.localeCompare(b.label));
  }

  ngOnInit(): void {
    this.formGroup = this.serviceFormService.createFormGroup(this.selectedLanguage, this.service);
    this.mode = this.service?.id ? 'read' : 'edit';
  }

  attachService = (): void => {
    this.formGroup.markAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const serviceId = this.formGroup.get([SERVICE_FORM_KEYS.get('service')]).value;
    const data: IObject = this.serviceFormService.toRequestObject(this.formGroup, this.selectedLanguage);

    this.attachServiceSubscription?.unsubscribe();
    this.attachServiceSubscription = this.serviceService.attachServiceToCurrentInstitution$(serviceId, data)
      .subscribe({
        next: (): void => {
          this.attachServiceSubscription = null;
          this.formGroup.reset();
          this.mode = 'read';
          this.saveClick.emit(this.service);
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.attachServiceSubscription = null;
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
        },
      });
  };

  onEditServiceClicked = (): void => {
    this.mode = 'edit';
    this.formGroup = this.serviceFormService.createFormGroup(this.selectedLanguage, this.service);
  };

  onRemoveServiceClicked = (service: Service): void => {
    this.removeClick.emit(service);
  };

  onCancelClick = (): void => {
    // Cancel creation of an unsaved service
    if (!this.service.id) {
      this.removeClick.emit(null);

      return;
    }

    this.mode = 'read';
  };

  private getHttpErrorMessageTranslationKey = (error: HttpErrorResponse | unknown): string => {
    if (!(error instanceof HttpErrorResponse && error.status === 422)) {
      return 'common.unknownError';
    }
  };
}
