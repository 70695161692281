export const APP = {
  business: {
    email: 'help@vesalius.ai',
    address: 'Ottergemsesteenweg-Zuid 808b / 48, 9000 Gent',
  },
  googleApi: {
    maps: {
      key: 'AIzaSyCPLX506v03XLP5ff79YHHgXb6eAOrSW9A',
    },
  },
};
