import { IObject } from '@app-types/iobject.type';
import { camelToSnake } from '@utils/helpers/string.util';

/**
 * Set a value in an object using the dot notation. For example if you have an object like this:
 * {
 *   foo: {
 *     bar: 'baz',
 *   },
 * }
 *
 * then you can set the value of bar by calling dot(obj, 'foo.bar', 'new value');
 */
export function dot(obj: object, path: string, value: unknown): object {
  const keys = path.split('.');
  let currentObj = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!currentObj[key] || typeof currentObj[key] !== 'object') {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  const finalKey = keys[keys.length - 1];
  currentObj[finalKey] = value;

  return obj;
}

/**
 * Removes a prefix from a string. If the string doesn't start with the prefix, the string is returned as is.
 * @param key
 * @param prefix
 */
export function removePrefix(key: string, prefix: string = '_'): string {
  if (key.startsWith(prefix)) {
    return key.slice(prefix.length);
  }

  return key;
}

/**
 * Removes all prefixes from an object. This function is useful when you want to remove all the prefixes from an object.
 * For example, if you have an object like this:
 * {
 *   _foo: {
 *     _bar: 'baz',
 *   },
 * }
 *
 * then you can remove all the prefixes by calling removePrefixesFromObject(obj).
 */
export function removePrefixesFromObjectKeys(obj: IObject): IObject {
  if (Array.isArray(obj)) {
    return obj.map(removePrefixesFromObjectKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: IObject, key: string) => {
      const newKey: string = removePrefix(key);
      acc[newKey] = removePrefixesFromObjectKeys(obj[key]);

      return acc;
    }, {});
  }

  return obj;
}

export function convertObjectKeysFromCamelToSnakeCase(obj: IObject): IObject {
  if (Array.isArray(obj)) {
    return obj.map(convertObjectKeysFromCamelToSnakeCase);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc: IObject, key: string) => {
      const newKey: string = camelToSnake(key);
      acc[newKey] = convertObjectKeysFromCamelToSnakeCase(obj[key]);

      return acc;
    }, {});
  }

  return obj;
}
