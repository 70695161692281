import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { environment } from '@environments/environment';
import { LanguageService } from '@services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
})
@UntilDestroy()
export class CreditCardFormComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly MOLLIE_CONTAINER_ID: string = 'mollie-cc-container';

  @Output() validationStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mollieTokenErrorOccurred: EventEmitter<any> = new EventEmitter<any>();
  @Output() mollieTokenGenerated: EventEmitter<string> = new EventEmitter<string>();

  private currentLanguage: string;
  private mollie: any;
  private mollieCardComponent: any;
  private mutationObservers: MutationObserver[] = [];

  constructor(
    private readonly elRef: ElementRef,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.languageService.getPreferredLanguage();
    this.bootstrapMollie();
  }

  ngAfterViewInit(): void {
    this.mountMollieCardComponent();
    this.listenForLanguageChanges();
    this.listenForInputValidationChanges();
  }

  ngOnDestroy(): void {
    this.stopListeningForInputValidationChanges();
    this.unmountMollieCardComponent();
  }

  private bootstrapMollie(): void {
    const locale = this.languageService.convertLanguageToMollieLocale(this.languageService.getPreferredLanguage());
    this.mollie = Mollie(environment.mollie.profileId, { locale: locale, testmode: environment.mollie.testMode });
  }

  private mountMollieCardComponent(): void {
    const options = {
      styles: {
        base: {
          color: COLORS.secondary,
          backgroundColor: COLORS.white,
        },
      },
    };

    this.mollieCardComponent = this.mollie.createComponent('card', options);
    this.mollieCardComponent.mount(`#${this.MOLLIE_CONTAINER_ID}`);
  }

  private unmountMollieCardComponent(): void {
    this.mollieCardComponent?.unmount();
  }

  private listenForLanguageChanges(): void {
    this.languageService.languageSetSuccessfully$
      .pipe(untilDestroyed(this))
      .subscribe((isSuccess: boolean) => {
        const setLanguage = this.languageService.getPreferredLanguage();
        if (!isSuccess || this.currentLanguage === setLanguage) {
          return;
        }

        this.currentLanguage = setLanguage;

        this.unmountMollieCardComponent();
        this.bootstrapMollie();
        this.mountMollieCardComponent();
      });
  }

  private async generateMollieToken(): Promise<void> {
    const { token, error } = await this.mollie.createToken();

    if (error) {
      this.mollieTokenErrorOccurred.emit(error);

      return;
    }

    this.mollieTokenGenerated.emit(token);
  }

  private checkAllValid(): void {
    const allValid = Array.from(this.elRef.nativeElement.querySelectorAll('.mollie-component')).every(
      (control: HTMLElement) => control.classList.contains('is-valid')
    );

    this.validationStateChanged.emit(allValid);

    if (allValid) {
      void this.generateMollieToken();
    }
  }

  private listenForInputValidationChanges(): void {
    const formControls = this.elRef.nativeElement.querySelectorAll('.mollie-component');

    formControls.forEach((control: HTMLDivElement) => {
      const observer = new MutationObserver(() => {
        this.checkAllValid();
      });
      observer.observe(control, { attributes: true, attributeFilter: ['class'] });
      this.mutationObservers.push(observer);
    });
  }

  private stopListeningForInputValidationChanges(): void {
    this.mutationObservers.forEach((observer: MutationObserver) => {
      observer.disconnect();
    });
  }
}
