import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AlertDialogComponent } from '@modules/shared/core/dialogs/alert-dialog/alert-dialog.component';

export interface DialogData {
  titleTranslationKey?: string;
  messageTranslationKey: string;
  confirmTextTranslationKey?: string;
  cancelTextTranslationKey?: string;
  showCancelButton?: boolean;
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AlertDialogService {
  dialogRef: MatDialogRef<AlertDialogComponent>;

  constructor(private readonly dialog: MatDialog) {}

  open(options: DialogData, config: MatDialogConfig = {}): Observable<boolean> {
    this.dialogRef = this.dialog.open(AlertDialogComponent, {
      ...config,
      data: {
        showCancelButton: !!options.cancelTextTranslationKey,
        ...options,
      },
    });

    return this.dialogRef.afterClosed()
      .pipe(untilDestroyed(this))
      .pipe(take(1), map((res: boolean) => res));
  }
}
