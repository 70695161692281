import { RecommendationResultType } from '@enums/recommendation-result-type.enum';
import { Institution } from '@models/institution.model';
import { VirtualSlot } from '@models/virtual-slot.model';
import { SubsequentVirtualSlot } from '@models/subsequent-virtual-slot.model';

export class Recommendations<T = any> {
  constructor(
    protected _type: RecommendationResultType,
    protected _data: T[] = []
  ) {
  }

  get type(): RecommendationResultType {
    return this._type;
  }

  get data(): T[] {
    return this._data;
  }

  get institutions(): Institution[] | null {
    if (this.type === RecommendationResultType.INSTITUTIONS) {
      return this.data as Institution[];
    }

    if (this.type === RecommendationResultType.SLOTS) {
      return this.data.map((item: T) => (item as VirtualSlot).institution);
    }

    if (this.type === RecommendationResultType.SUBSEQUENT_SLOTS) {
      return this.data.flatMap((item: T) => (item as SubsequentVirtualSlot).virtualSlots.map((slot: VirtualSlot) => slot.institution));
    }

    return null;
  }
}
