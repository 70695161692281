import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Service } from '@models/service.model';
import { arrayWrap } from '@utils/helpers/array.util';

@Component({
  selector: 'vh-human-body',
  templateUrl: './human-body.component.html',
  styleUrls: ['./human-body.component.scss'],
})
export class HumanBodyComponent implements OnInit, OnChanges {
  @ViewChild('humanBodySvgContainer') humanBodySvgContainerElement: ElementRef;
  @Input() serviceOrServices: Service | Service[];

  ngOnInit(): void {
    if (this.serviceOrServices && arrayWrap(this.serviceOrServices).length > 0) {
      this.updateSelectedBodyParts(arrayWrap(this.serviceOrServices));
    }
  }

  updateSelectedBodyParts(services: Service[]): void {
    if (!this.humanBodySvgContainerElement) {
      return;
    }
    // Reset the highlighted body parts in the human body SVG
    this.humanBodySvgContainerElement.nativeElement.querySelectorAll('svg > g > path').forEach((pathElement: Element): void => {
      pathElement.classList.remove('highlighted');
    });

    services.forEach((service: Service) => {
      if (service.bodyPartNames?.length) {
        // Highlight the body parts in the human body SVG that the service targets
        const bodyPartNamesQuerySelector: string = service.bodyPartNames
          .map((bodyPartName: string): string => `#${bodyPartName}`)
          .join(',');

        this.humanBodySvgContainerElement.nativeElement
          .querySelectorAll(bodyPartNamesQuerySelector)
          .forEach((pathElement: Element): void => {
            pathElement.classList.add('highlighted');
          });
      }
    });
  }

  ngOnChanges(): void {
    if (this.serviceOrServices && arrayWrap(this.serviceOrServices).length >= 0) {
      this.updateSelectedBodyParts(arrayWrap(this.serviceOrServices));
    }
  }
}
