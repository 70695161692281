import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, ValidatorFn, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { LINK_RIZIV_FORM_KEYS } from '@constants/form-keys/link-riziv-form-keys.constant';
import { REGEX } from '@constants/regex.constant';

@Injectable({
  providedIn: 'root',
})
export class LinkRizivFormService extends AbstractFormService {
  createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LINK_RIZIV_FORM_KEYS.get('linkRizivNumber')]: [
        null,
        [Validators.required, Validators.pattern(new RegExp(REGEX.rizivNumber)), rizivValidator()],
      ],
    });
  }
}

export function rizivValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.length !== 14) {
      return null;
    }
    const riziv: string = control.value.replace(/-/g, '');
    const first6: number = parseInt(riziv.substring(0, 6));
    const controlNr: number = parseInt(riziv.substring(6, 8));
    const controlNrCheck: boolean = first6 % 97 === 97 - controlNr || first6 % 89 === 89 - controlNr || first6 % 83 === 83 - controlNr || first6 % 79 === 79 - controlNr;

    return controlNrCheck ? null : { rizivControlNr: { value: control.value } };
  };
}
