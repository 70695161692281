<div
  class="flex-row-l-c"
  [className]="containerClass"
  [ngClass]="{ 'is-disabled': isDisabled }"
  [ngStyle]="{ 'text-align': textAlignment }"
  (click)="onMessageClick()">
  <vh-icon
    class="spacing-right"
    *ngIf="iconLeftPath"
    [ngClass]="iconLeftClass"
    [src]="iconLeftPath"
    [ariaLabel]="iconLeftAlt ?? ''"
    [fill]="iconLeftFill"
    [hoverFill]="iconLeftHoverFill"
    [stroke]="iconLeftStroke"
    [width]="iconLeftWidth"
    [height]="iconLeftHeight"
    [matTooltip]="iconLeftTooltip"
    (click)="onLeftIconClick($event)">
  </vh-icon>
  <span *ngIf="text" [innerHtml]="text" [ngClass]="textClass"></span>
  <ng-content></ng-content>
  <vh-icon
    *ngIf="iconRightPath"
    class="spacing-left"
    matTooltipPosition="right"
    [ngClass]="iconRightClass"
    [src]="iconRightPath"
    [ariaLabel]="iconRightAlt ?? ''"
    [fill]="iconRightFill"
    [hoverFill]="iconRightHoverFill"
    [stroke]="iconRightStroke"
    [width]="iconRightWidth"
    [height]="iconRightHeight"
    [matTooltip]="iconRightTooltip"
    (click)="onRightIconClick($event)">
  </vh-icon>
</div>
