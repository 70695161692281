import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { Location } from '@angular/common';

@Component({
  selector: 'vh-tertiary-page-alt',
  templateUrl: './tertiary-page-alt.component.html',
  styleUrls: ['./tertiary-page-alt.component.scss'],
})
export class TertiaryPageAltComponent extends BreakpointObserverComponent {
  @Input() doctorIsVisible: boolean;
  @Input() contentMaxWidth: number;

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly location: Location
  ) {
    super(breakpointObserver);

    // Defaults
    this.contentMaxWidth = 600;
  }

  exit(): void {
    this.location.back();
  }
}
