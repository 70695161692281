import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CommunicationMethod } from '@enums/communication-methods';

@Injectable({
  providedIn: 'root',
})
export class CommunicationMethodService {

  constructor(private translateService: TranslateService) { }

  getAsOptions(): Option[] {
    return this.getAsFilteredOptions(Object.values(CommunicationMethod));
  }

  getAsFilteredOptions(communicationMethods: CommunicationMethod[]): Option[] {
    return communicationMethods.map((communicationMethod: CommunicationMethod) => {
      return {
        label: this.translateService.instant(`conversations.communicationMethods.${communicationMethod.toLowerCase()}`),
        value: communicationMethod,
      };
    });
  }
}
