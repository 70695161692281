<div class="popup-container" *ngIf="selectedFormGroup" [formGroup]="selectedFormGroup">
  <p>Status Wijzigen</p>
  <vh-select-input formControlName="status" [options]="statusOptions"></vh-select-input>

  <p>Argumentatie</p>
  <p>Interne argumentatie (optioneel)</p>
  <vh-textarea-input formControlName="internal_argumentation" [placeholder]="'Schrijf hier uw interne argumentatie'"></vh-textarea-input>

  <p>Argumentatie aan patiënt (optioneel)</p>
  <vh-textarea-input formControlName="argumentation" [placeholder]="'Schrijf hier uw argumentatie aan patiënt'"></vh-textarea-input>

  <div class="flex-row-c-c" [vhMarginTop]="18">
    <p style="margin-right: var(--16px)" (click)="closePopup()" class="font-regular-18-22 clickable">Annuleren</p>

    <vh-button (buttonClick)="saveStatus()" [label]="'Status wijzigen'" [alignment]="'center'"></vh-button>
  </div>
</div>
