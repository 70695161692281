import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FORGOT_PASSWORD_FORM_KEYS } from '@constants/form-keys/forgot-password-form-keys.constant';
import { NAVIGATION } from '@constants/navigation.constant';
import { IObject } from '@app-types/iobject.type';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage } from '@utils/helpers/form.util';
import { PasswordService } from '@services/password.service';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
@UntilDestroy()
export class ForgotPasswordPageComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly FORGOT_PASSWORD_FORM_KEYS: typeof FORGOT_PASSWORD_FORM_KEYS = FORGOT_PASSWORD_FORM_KEYS;

  formGroup: UntypedFormGroup;

  username: string;
  submitUsernameButtonIsDisabled: boolean;
  submitUsernameErrorMessageTranslationKey: string;

  isSubmitted: boolean;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly passwordService: PasswordService,
    private readonly router: Router
  ) {
    this.username = this.activatedRoute.snapshot.queryParamMap.get('username');
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      {
        [FORGOT_PASSWORD_FORM_KEYS.get('username')]: [
          null,
          [Validators.required],
        ],
      }
    );

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((values: IObject): void => {
      this.username = values[FORGOT_PASSWORD_FORM_KEYS.get('username')] as string;
    });
  }

  submitUsername = (): void => {
    this.submitUsernameErrorMessageTranslationKey = extractErrorMessageFromFormValidation(
      FORGOT_PASSWORD_FORM_KEYS,
      this.formGroup,
      this.getSubmitUsernameErrorMessage
    );

    if (this.submitUsernameErrorMessageTranslationKey) {
      return;
    }

    this.submitUsernameButtonIsDisabled = true;
    this.submitUsernameErrorMessageTranslationKey = null;

    this.passwordService.forgotPassword$(this.username)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.isSubmitted = true;
        },
        error: (): void => {
          this.submitUsernameButtonIsDisabled = false;
          this.submitUsernameErrorMessageTranslationKey = 'common.unknownError';
        },
      });
  };

  onUsernameEntered($event: KeyboardEvent): void {
    if ($event.key !== 'Enter') {
      return;
    }

    this.submitUsername();
  }

  onForgotUsernameClicked(): void {
    void this.router.navigate([this.NAVIGATION.forgotUsername.path]);
  }

  private getSubmitUsernameErrorMessage = (errorIdentifier: string, formKey: string): string => {
    if (errorIdentifier === 'username') {
      return 'pages.login.invalidUsernameError';
    }

    return getUnknownErrorMessage(errorIdentifier, formKey);
  };

  protected readonly COLORS = COLORS;
}
