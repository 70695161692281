<div class="flex-col-c-c">
  <ng-content></ng-content>
</div>

<div class="flex-col flex-grow">
  <div *ngIf="!isDisabled" class="flex-row-r-c">
    <vh-icon class="clickable" src="assets/icons/trash-alt.svg" [hoverFill]="COLORS.error" (click)="onDeleteQuestionClicked()"></vh-icon>
  </div>

  <div class="flex-row card-container gap-h-16" [formGroup]="formGroup">
    <div class="flex-col">
      <p>{{ 'admin.pages.settings.surveys.create.visibleFor' | translate }}</p>
      <div [vhMarginTop]="8" class="flex-row gap-h-16">
        <div class="flex-row gap-h-4">
          <vh-checkbox
            [id]="getElementId(SURVEY_FORM_KEYS.get('isVisibleForPatient'))"
            [formControlName]="SURVEY_FORM_KEYS.get('isVisibleForPatient')"
            [value]="formGroup.get(SURVEY_FORM_KEYS.get('isVisibleForPatient'))?.value"
            [isDisabled]="isDisabled">
          </vh-checkbox>
          <label [for]="getElementId(SURVEY_FORM_KEYS.get('isVisibleForPatient'))">{{
            'admin.pages.settings.surveys.create.patients' | translate
          }}</label>
        </div>
        <div class="flex-row gap-h-4">
          <vh-checkbox
            [id]="getElementId(SURVEY_FORM_KEYS.get('isVisibleForInstitution'))"
            [formControlName]="SURVEY_FORM_KEYS.get('isVisibleForInstitution')"
            [value]="formGroup.get(SURVEY_FORM_KEYS.get('isVisibleForInstitution'))?.value"
            [isDisabled]="isDisabled">
          </vh-checkbox>
          <label [for]="getElementId(SURVEY_FORM_KEYS.get('isVisibleForInstitution'))">{{
            'admin.pages.settings.surveys.create.physicians' | translate
          }}</label>
        </div>
      </div>
      <p [vhMarginTop]="32">{{ 'admin.pages.settings.surveys.create.responseType' | translate }}</p>
      <vh-select-input
        [vhMarginTop]="8"
        [options]="questionTypeOptions"
        [placeholder]="'admin.pages.settings.surveys.create.instruction' | translate"
        [formControlName]="SURVEY_FORM_KEYS.get('questionType')"
        [isDisabled]="isDisabled">
      </vh-select-input>
    </div>

    <div class="flex-col grid-card-wrapper">
      <div class="grid-card">
        <ng-container *ngIf="formGroup.value.type === 'INSTRUCTION'" [ngTemplateOutlet]="instructionTemplate"></ng-container>
        <ng-container *ngIf="formGroup.value.type !== 'INSTRUCTION'" [ngTemplateOutlet]="questionWrapperTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #questionWrapperTemplate [formGroup]="formGroup">
  <p>{{ 'admin.pages.settings.surveys.create.question' | translate }}</p>
  <vh-text-input
    [formControlName]="SURVEY_FORM_KEYS.get('question')"
    [placeholder]="'admin.pages.settings.surveys.create.questionPlaceholder' | translate"
    [message]="translationPreview">
  </vh-text-input>

  <ng-container [ngSwitch]="formGroup.value.type">
    <ng-container *ngSwitchCase="'TEXT'" [ngTemplateOutlet]="textQuestionTemplate"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultQuestionTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #textQuestionTemplate [formGroup]="formGroup">
  <p>{{ 'admin.pages.settings.surveys.create.answerType' | translate }}</p>

  <div class="flex-row gap-h-8 grid-column-two">
    <input type="radio" [id]="getElementId('single-line')" [formControlName]="SURVEY_FORM_KEYS.get('answerType')" value="SINGLE_LINE" />
    <label [for]="getElementId('single-line')">{{ 'admin.pages.settings.surveys.create.answertypeSingleLine' | translate }}</label>
  </div>
  <div class="flex-row gap-h-8 grid-column-two">
    <input type="radio" [id]="getElementId('multi-line')" [formControlName]="SURVEY_FORM_KEYS.get('answerType')" value="MULTI_LINE" />
    <label [for]="getElementId('multi-line')">{{ 'admin.pages.settings.surveys.create.answertypeMultiLine' | translate }}</label>
  </div>
</ng-template>

<ng-template #defaultQuestionTemplate [formGroup]="formGroup">
  <p>{{ 'admin.pages.settings.surveys.create.answers' | translate }}</p>
  <ng-container [formArrayName]="SURVEY_FORM_KEYS.get('answerOptions')">
    <ng-container *ngFor="let answerOption of answerOptions.controls; let answerOptIndex = index" [formGroupName]="answerOptIndex">
      <vh-text-input
        class="grid-column-two"
        [formControlName]="SURVEY_FORM_KEYS.get('answerOptionValue')"
        [iconRightPath]="!isDisabled ? 'assets/icons/cross.svg' : ''"
        [placeholder]="'admin.pages.settings.surveys.create.answerOptionPlaceholder' | translate"
        [iconRightClickable]="!isDisabled"
        [formArray]="answerOptions"
        [hoverFill]="COLORS.error"
        [index]="answerOptIndex"
        [message]="translationAnswerOptionsPreview[answerOptIndex]"
        (deleteFromFormFormArrayClick)="onDeleteAnswerOptionClicked($event)">
      </vh-text-input>
    </ng-container>
    <div class="flex-row-r-c grid-column-two" [vhMarginTop]="18">
      <vh-message
        *ngIf="!isDisabled"
        class="has-text-tertiary"
        [text]="'admin.pages.settings.surveys.create.addAnswer' | translate"
        (messageClick)="onAddAnswerOptionClicked(answerOptions)"
        iconRightPath="assets/icons/plus.svg">
      </vh-message>
    </div>
  </ng-container>
</ng-template>

<ng-template #instructionTemplate [formGroup]="formGroup">
  <p>{{ 'admin.pages.settings.surveys.create.instruction' | translate }}</p>
  <vh-textarea-input
    [formControlName]="SURVEY_FORM_KEYS.get('question')"
    [isDisabled]="isDisabled"
    [placeholder]="'admin.pages.settings.surveys.create.instructionPlaceholder' | translate"
    [value]="formGroup.get('question')?.value"
    [message]="translationPreview">
  </vh-textarea-input>
</ng-template>
