import { Room, RoomJson } from '@models/room.model';
import { startOfDay } from 'date-fns';

export class DaysWithRoomPlanning {
  constructor(
    private _room: Room,
    private _days: Date[]
  ) {
  }

  get room(): Room {
    return this._room;
  }

  get days(): Date[] {
    return this._days;
  }

  static fromJson(json: DaysWithRoomPlanningJson): DaysWithRoomPlanning {
    return new DaysWithRoomPlanning(
      Room.fromJson(json.room),
      json.days.map((day: string) => startOfDay(new Date(day)))
    );
  }
}

export interface DaysWithRoomPlanningJson {
  room: RoomJson;
  days: string[];
}
