import { HierarchicalPickerItem } from '@modules/booking/models/hierarchy-state.model';
import { Service } from '@models/service.model';

export class ServiceType implements HierarchicalPickerItem {
  constructor(
    private _id: string,
    private _name: string,
    private _description: string,
    private _services: Service[] = []
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get description(): string {
    return this._description;
  }

  get children(): HierarchicalPickerItem[] {
    return this._services;
  }

  static fromJson(json: ServiceTypeJson): ServiceType {
    return json
      ? new ServiceType(
        json.id,
        json.name,
        json.description
      )
      : null;
  }
}

export interface ServiceTypeJson {
  id: string;
  name: string;
  description: string;
}
