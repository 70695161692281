import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vh-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @HostBinding('style.display') private display: string;

  @Input() iconLeftPath: string;
  @Input() iconLeftWidth: number = 16;
  @Input() iconLeftHeight: number = 16;
  @Input() iconLeftAlt: string;
  @Input() iconLeftHoverFill: string;
  @Input() iconLeftFill: string;
  @Input() iconLeftClass: string;
  @Input() iconLeftTooltip: string;
  @Input() iconLeftStroke: string;

  @Input() iconRightPath: string;
  @Input() iconRightWidth: number = 16;
  @Input() iconRightHeight: number = 16;
  @Input() iconRightAlt: string;
  @Input() iconRightHoverFill: string;
  @Input() iconRightFill: string;
  @Input() iconRightClass: string;
  @Input() iconRightTooltip: string;
  @Input() iconRightStroke: string;

  @Input() isDisplayedInline: boolean;
  @Input() containerClass: string;
  @Input() textClass: string;
  @Input() text: string;
  @Input() textAlignment: 'left' | 'right' | 'center';
  @Input() isDisabled: boolean;

  @Output() messageClick: EventEmitter<void>;
  @Output() rightIconClick: EventEmitter<void>;
  @Output() leftIconClick: EventEmitter<void>;

  constructor() {
    this.messageClick = new EventEmitter<void>();
    this.rightIconClick = new EventEmitter<void>();
    this.leftIconClick = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.display = this.isDisplayedInline ? 'inline-block' : null;
  }

  onMessageClick(): void {
    if (this.isDisabled) {
      return;
    }

    this.messageClick.emit();
  }

  onLeftIconClick(event: Event): void {
    if (this.isDisabled) {
      return;
    }

    event.stopPropagation();

    if (this.leftIconClick.observed) {
      this.leftIconClick.emit();

      return;
    }

    this.messageClick.emit();
  }

  onRightIconClick(event: Event): void {
    if (this.isDisabled) {
      return;
    }

    event.stopPropagation();

    if (this.rightIconClick.observed) {
      this.rightIconClick.emit();

      return;
    }

    this.messageClick.emit();
  }
}
