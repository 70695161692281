import { SurveyService } from '@services/survey.service';
import { CareProgramService } from '@services/care-program.service';
import { Injectable } from '@angular/core';
import { Survey } from '@models/survey.model';
import { CareProgram } from '@models/care-program.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { CardType } from '@modules/settings/enums/card-type.enum';
import { ServiceGroup } from '@models/service-group.model';
import { Option } from '@interfaces/option.interface';
import { NAVIGATION } from '@constants/navigation.constant';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly surveyService: SurveyService,
    private readonly careProgramService: CareProgramService
  ) {
  }

  private _surveys: Survey[];
  private _carePrograms: CareProgram[];

  get currentSurveys(): SettingsCard[] {
    return this._surveys;
  }

  set currentSurveys(surveys: Survey[]) {
    this._surveys = surveys;
  }

  get currentCarePrograms(): SettingsCard[] {
    return this._carePrograms;
  }

  set currentCarePrograms(carePrograms: CareProgram[]) {
    this._carePrograms = carePrograms;
  }

  setCards(cardType: CardType, cards: SettingsCard[]): void {
    switch (cardType) {
      case CardType.SURVEY:
        this._surveys = cards as Survey[];
        break;
      case CardType.CARE_PROGRAM:
        this._carePrograms = cards as CareProgram[];
        break;
      default:
        throw new Error('Invalid settings-card type');
    }
  }

  getCards(cardType: CardType): SettingsCard[] {
    switch (cardType) {
      case CardType.SURVEY:
        return this._surveys;
      case CardType.CARE_PROGRAM:
        return this._carePrograms;
      default:
        throw new Error('Invalid settings-card type');
    }
  }

  loadCards$ = (cardType: CardType, forceLoad: boolean = true): Observable<SettingsCard[]> => {
    switch (cardType) {
      case CardType.SURVEY:
        return this.getSurveysByInstitution$(forceLoad);
      case CardType.CARE_PROGRAM:
        return this.getCareProgramsByInstitution$(forceLoad);
      default:
        throw new Error(`Invalid card type ${cardType}`);
    }
  };

  deleteCard$ = (card: SettingsCard): Observable<void> => {
    const cardType = this.getCardType(card);
    switch (cardType) {
      case CardType.SURVEY:
        return this.deleteSurvey$(card.id);
      case CardType.CARE_PROGRAM:
        return this.deleteCareProgram$(card.id);
      default:
        throw new Error(`Invalid card type ${cardType}`);
    }
  };

  deleteSurvey$ = (surveyId: string): Observable<void> => {
    return this.surveyService.deleteSurvey$(surveyId);
  };

  deleteCareProgram$ = (careProgramId: string): Observable<void> => {
    return this.careProgramService.deleteCareProgram$(careProgramId);
  };

  getImpactedItems$ = (card: SettingsCard): Observable<Option[]> => {
    const cardType = this.getCardType(card);
    switch (cardType) {
      case CardType.SURVEY:
        return this.getSurveyDeletionImpact$(card.id);
      case CardType.CARE_PROGRAM:
        return this.getCareProgramDeletionImpact$(card.id);
      default:
        throw new Error(`Invalid card type ${cardType}`);
    }
  };

  getSurveyDeletionImpact$(surveyId: string, institutionId: string = null): Observable<Option[]> {
    return this.surveyService.getSurveyDeletionImpact$(surveyId, institutionId)
      .pipe(map((serviceGroups: ServiceGroup[]): Option[] => serviceGroups.map((serviceGroup: ServiceGroup): Option => serviceGroup.toOption())));
  }

  getCareProgramDeletionImpact$(careProgramId: string): Observable<Option[]> {
    //TODO: Implement this method
    /**
     * Once CarePrograms Can be booked, this method should return the bookings that would be affected by the archiving of the care program.
     * The careProgram can still be archived, but the user should be warned about the impact of the action.
     *
     * for now, an empty array is returned
     */
    return of([]);
  }

  getSurveysByInstitution$ = (forceLoad: boolean = true): Observable<Survey[]> => {
    if (this._surveys?.length > 0 && !forceLoad) {
      return of(this._surveys);
    }

    return this.surveyService.getSurveysOfInstitution$();
  };

  getCareProgramsByInstitution$ = (forceLoad: boolean = true): Observable<CareProgram[]> => {
    if (this._carePrograms?.length > 0 && !forceLoad) {
      return of(this._carePrograms);
    }

    return this.careProgramService.getCareProgramsOfInstitution$(this.authenticationService.institution.id);
  };

  duplicateCard$ = (card: SettingsCard): Observable<SettingsCard> => {
    const cardType = this.getCardType(card);
    switch (cardType) {
      case CardType.SURVEY:
        return this.duplicateSurvey$(card as Survey);
      case CardType.CARE_PROGRAM:
        return this.duplicateCareProgram$(card as CareProgram);
      default:
        throw new Error(`Invalid card type ${cardType}`);
    }
  };

  duplicateSurvey$ = (survey: Survey): Observable<Survey> => {
    //todo: implement this
    throw new Error('Not implemented');
  };

  duplicateCareProgram$ = (careProgram: CareProgram): Observable<CareProgram> => {

    return this.careProgramService.duplicateCareProgram$(careProgram);
  };

  getImpactedItemRoute = (card: SettingsCard): string => {
    const cardType = this.getCardType(card);
    switch (cardType) {
      case CardType.SURVEY:
        return NAVIGATION.adminDashboardSettings.serviceGroups.route;
      case CardType.CARE_PROGRAM:
        //TODO: Implement this
        return 'NOT IMPLEMENTED';
      default:
        throw new Error(`Invalid card type ${cardType}`);
    }
  };

  getCardTypeTranslationKey(card: SettingsCard): string {
    const cardType = this.getCardType(card);

    return `cardType.${cardType}`;
  }

  getImpactTypeTranslationKey(card: SettingsCard): string {
    const cardType = this.getCardType(card);

    return `impactType.${cardType}`;
  }

  private getCardType(card: SettingsCard): CardType {
    return card instanceof Survey ? CardType.SURVEY : CardType.CARE_PROGRAM;
  }
}
