/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const INSTITUTION_USER_FORM_KEYS: Map<string, string> = new Map([
  ['firstName', 'first_name'],
  ['lastName', 'last_name'],
  ['username', 'username'],
  ['email', 'email'],
  ['roles', 'roles'],
  ['title', 'title'],
  ['organisationPosition', 'organisation_position'],
]);
