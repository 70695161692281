import { Injectable } from '@angular/core';
import { DailyOrWeeklyOpeningsService } from '@modules/admin/admin-dashboard/services/openings/daily-or-weekly-openings.service';
import { MonthlyOpeningsService } from '@modules/admin/admin-dashboard/services/openings/monthly-openings.service';
import { EventsCalendarMode } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';
import { Observable } from 'rxjs';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';

@Injectable({
  providedIn: 'root',
})
export class OpeningsService {
  constructor(
    private readonly openingsService: DailyOrWeeklyOpeningsService,
    private readonly openingsMonthlyService: MonthlyOpeningsService
  ) {}

  loadOpenings$(filter: VisitFilter, calendarMode: EventsCalendarMode): Observable<CalendarOpening[]> {
    switch (calendarMode) {
      case 'day':
      case 'week':
        return this.openingsService.loadOpenings$(filter);
      case 'month':
        return this.openingsMonthlyService.loadOpenings$(filter);
      default:
        throw new Error(`Unsupported calendar mode: ${calendarMode}`);
    }
  }
}
