<vh-base-card [isLoading]="skeletonIsEmpty" [isHighlighted]="isHighlighted">
  <div class="flex-row gap-h-16 container">
    <vh-date-card
      *ngIf="!(layout === AppointmentCardLayout.PRIMARY && breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium])"
      [skeletonIsEmpty]="skeletonIsEmpty"
      [date]="visit?.start"
      [layout]="dateCardLayout"
      [backgroundColor]="dateCardBackgroundColor"
      [getBackgroundColorFunction]="getDateCardBackgroundColorFunction">
    </vh-date-card>
    <div class="flex-row gap-h-16 content">
      <div class="data">
        <div *ngIf="layout === AppointmentCardLayout.PRIMARY" class="title">
          <h2>
            {{
              breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium]
                ? appointmentTitleMobile
                : (visit?.service?.displayName | translate)
            }}
          </h2>
        </div>
        <div *ngIf="layout === AppointmentCardLayout.SECONDARY" class="flex-row-l-c gap-h-16 title">
          <h2>{{ visit?.patient?.fullName }}</h2>
          <span [vhTextColor]="'var(--color-tertiary)'">{{ visit?.start | date : DATE_FORMATS.clientDate }}</span>
        </div>
        <div class="flex-col gap-v-4" [vhMarginTop]="8">
          <ng-container *ngIf="layout === AppointmentCardLayout.PRIMARY">
            <div class="flex-row-l-c">
              <vh-icon class="img-14" src="assets/icons/clock-three.svg" [fill]="COLORS.tertiary"></vh-icon>
              <span>{{ visit?.start | date : DATE_FORMATS.clientTimeShort }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="layout === AppointmentCardLayout.SECONDARY">
            <div class="flex-row-l-c">
              <vh-icon class="img-16" src="assets/icons/sign-alt.svg"></vh-icon>
              <span>{{ visit?.service.displayName }}</span>
            </div>
          </ng-container>
          <div class="flex-row-l-c">
            <vh-icon class="img-16" src="assets/icons/marker.svg" [fill]="COLORS.tertiary"></vh-icon>
            <span>{{ visit?.institution.name }} </span>
          </div>
        </div>
      </div>
     <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="visit"></ng-container>
    </div>
  </div>
</vh-base-card>
