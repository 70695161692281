export class Coordinate {
  constructor(private _latitude: number, private _longitude: number) {}

  get latitude(): number {
    return this._latitude;
  }

  set latitude(value: number) {
    this._latitude = value;
  }

  get longitude(): number {
    return this._longitude;
  }

  set longitude(value: number) {
    this._longitude = value;
  }

  get latLngLiteral(): google.maps.LatLngLiteral {
    return {
      lat: this._latitude,
      lng: this._longitude,
    };
  }

  static fromGeolocationPosition(position: GeolocationPosition): Coordinate {
    return new Coordinate(position.coords.latitude, position.coords.longitude);
  }

  static fromLatLng(position: google.maps.LatLng): Coordinate {
    return new Coordinate(position.lat(), position.lng());
  }
}
