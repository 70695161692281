export const DATE_BOUNDARIES = {
  min: {
    date: new Date(1900, 0, 1, 0, 0, 0),
    dateString: '1900-01-01',
    dateTimeString: '1900-01-01T00:00:00',
  },
  max: {
    date: new Date(2099, 11, 31, 23, 59, 59),
    dateString: '2099-12-31',
    dateTimeString: '2099-12-31T23:59:59',
  },
  endless: {
    date: new Date(9999, 11, 31, 23, 59, 59),
    dateString: '9999-12-31',
    dateTimeString: '9999-12-31T23:59:59',
  },
};
