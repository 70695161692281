import { Component, OnInit } from '@angular/core';
import { ConversationQuestionList } from '@models/conversation-question-list.model';
import { Doctor } from '@models/doctor.model';
import { Institution } from '@models/institution.model';
import { ConversationNavigationService } from '@modules/conversation/services/conversation-flow-navigation.service';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from '@services/authentication.service';
import { ConversationService } from '@services/conversation.service';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Option } from '@interfaces/option.interface';
import { CommunicationMethod } from '@enums/communication-methods';
import { COLORS } from '@constants/colors.constant';
import { Conversation } from '@models/conversation.model';
import { User } from '@models/user.model';
import { CommunicationMethodService } from '@services/communication-method.service';
import { InstitutionSettings } from '@models/institution-settings.model';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';

@Component({
  selector: 'vh-create-conversation-confirmation-page',
  templateUrl: './create-conversation-confirmation-page.component.html',
  styleUrl: './create-conversation-confirmation-page.component.scss',
})
@UntilDestroy()
export class CreateConversationConfirmationPageComponent implements OnInit {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly CommunicationMethod: typeof CommunicationMethod = CommunicationMethod;
  protected readonly COLORS: typeof COLORS = COLORS;

  communicationMethods: Option[] = [];

  user: User | null = null;
  email: string | null = null;
  phone: string | null = null;
  doctor: Doctor | null = null;
  questionList: ConversationQuestionList | null = null;
  dueDate: Date | null = null;
  institution: Institution | null = null;
  selectedCommunicationMethod: CommunicationMethod = null;
  language: string | null = null;
  summaryEmail: boolean = false;
  summaryEmailInput: string = '';
  conversationCost: number = 1;

  isCreatingConversation: boolean = false;

  constructor(
    readonly createConversationService: CreateConversationService,
    readonly authenticationService: AuthenticationService,
    private readonly conversationService: ConversationService,
    private readonly conversationNavigationService: ConversationNavigationService,
    private readonly communicationMethodService: CommunicationMethodService
  ) {
    this.createConversationService.setStep(3);

    this.user = this.createConversationService.user;
    this.email = this.createConversationService.email ?? this.user?.email;
    this.phone = this.createConversationService.phone ?? this.user?.phone;
    this.doctor = this.createConversationService.doctor;
    this.questionList = this.createConversationService.questionList;
    this.language = this.createConversationService.language;
    this.dueDate = this.createConversationService.dueDate;
    this.institution = this.authenticationService.institution;
    this.summaryEmailInput = this.authenticationService.currentUser.email;
  }

  ngOnInit(): void {
    this.createConversationService.setStep(3);

    const usableCommunicationMethods: CommunicationMethod[] = [
      CommunicationMethod.LINK,
      this.phone ? CommunicationMethod.WHATSAPP : null,
      this.email ? CommunicationMethod.MAIL : null,
    ].filter((value: CommunicationMethod) => value !== null);

    this.communicationMethods = this.communicationMethodService.getAsFilteredOptions(usableCommunicationMethods);

    const settings: InstitutionSettings = this.authenticationService.institution.settings;
    this.selectedCommunicationMethod = settings.preferredCommunicationMethod;
  }

  goToNextStep(): void {
    const payload = {
      patient_id: this.user.id,
      doctor_id: this.doctor?.id,
      question_list_id: this.questionList?.id,
      due_date: this.dueDate ? getTimeStringWithTimeZone(this.dueDate) : null,
      communication_method: this.selectedCommunicationMethod,
      summary_email: this.summaryEmail ? this.summaryEmailInput : null,
      language: this.language,
    };

    this.isCreatingConversation = true;

    this.conversationService
      .createConversation$(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (conversation: Conversation) => {
          this.isCreatingConversation = false;

          this.conversationNavigationService.navigateToDetails(conversation.id);
        },
        error: () => {
          this.isCreatingConversation = false;
        },
      });
  }

  onCommunicationMethod(communicationMethod: CommunicationMethod): void {
    this.selectedCommunicationMethod = communicationMethod;
  }

  toggleResultsEmail(value: boolean): void {
    this.summaryEmail = value;
  }

  onResultsEmailChange(value: string): void {
    this.summaryEmailInput = value;
    this.createConversationService.resultsEmail = value;
  }

  goBack(): void {
    history.back();
  }
}
