import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { Role } from '@enums/role.enum';
import { ModuleKey } from '@enums/module-key.enum';
import { filterNavigationItems } from '@utils/helpers/navigation.util';
import { WalletPolicyService } from '@services/policies/wallet-policy.service';

const settingsNavigationItems: NavigationItem[] = [
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.conversationsLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.conversationsGeneralLabel',
        route: NAVIGATION.adminDashboardSettings.conversationsGeneral.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
      },
      // {
      //   labelTranslationKey: 'admin.pages.settings.navigationBar.conversationsCommunicationLabel',
      //   route: NAVIGATION.adminDashboardSettings.conversationsCommunication.route,
      //   visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
      // },
      // {
      //   labelTranslationKey: 'admin.pages.settings.navigationBar.conversationsPrivacyLabel',
      //   route: NAVIGATION.adminDashboardSettings.conversationsPrivacy.route,
      //   visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
      // },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.servicesLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
    visibleForModules: [ModuleKey.BOOKING],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.manageServicesLabel',
        route: NAVIGATION.adminDashboardSettings.services.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
        visibleForModules: [ModuleKey.BOOKING],
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.serviceGroupsLabel',
        route: NAVIGATION.adminDashboardSettings.serviceGroups.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
        visibleForModules: [ModuleKey.BOOKING],
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.planningLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
    visibleForModules: [ModuleKey.BOOKING],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.manageSlotsLabel',
        route: NAVIGATION.adminDashboardSettings.rooms.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
        visibleForModules: [ModuleKey.BOOKING],
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.reservationSettingsLabel',
        route: NAVIGATION.adminDashboardSettings.reservationSettings.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
        visibleForModules: [ModuleKey.BOOKING],
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.accessManagementLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.usersLabel',
        route: NAVIGATION.adminDashboardSettings.users.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN],
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.careProgramsLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN],
    visibleForModules: [ModuleKey.CARE_PROGRAM],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.manageCareProgramsLabel',
        route: NAVIGATION.adminDashboardSettings.careProgram.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN],
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.surveysLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.INSTITUTION_ADMIN],
    visibleForModules: [ModuleKey.SURVEY],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.manageSurveysLabel',
        route: NAVIGATION.adminDashboardSettings.surveys.route,
        visibleForRoles: [Role.INSTITUTION_ADMIN],
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.billingLabel',
    route: '',
    selected: true,
    visibleForRoles: WalletPolicyService.EDITOR_ROLES,
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.overviewLabel',
        route: NAVIGATION.wallet.route,
        visibleForRoles: WalletPolicyService.EDITOR_ROLES,
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.paymentMethodsLabel',
        route: NAVIGATION.paymentMethods.route,
        visibleForRoles: WalletPolicyService.EDITOR_ROLES,
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.historyLabel',
        route: NAVIGATION.walletHistory.route,
        visibleForRoles: WalletPolicyService.EDITOR_ROLES,
      },
    ],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.generalSettingsLabel',
    route: '',
    selected: true,
    visibleForRoles: [Role.ADMIN, Role.INSTITUTION_ADMIN],
    subNavigationItems: [
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.generalSettingsLabel',
        route: NAVIGATION.adminDashboardSettings.generalSettings.route,
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.communicationLabel',
        route: NAVIGATION.adminDashboardSettings.communication.route,
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.securityLabel',
        route: NAVIGATION.adminDashboardSettings.securitySettings.route,
        visibleForRoles: [Role.ADMIN],
      },
      {
        labelTranslationKey: 'admin.pages.settings.navigationBar.superAdminSettingsLabel',
        route: NAVIGATION.adminDashboardSettings.superAdminSettings.route,
        visibleForRoles: [Role.ADMIN],
      },
    ],
  },
];

export const getSettingsNavigationItems = (): NavigationItem[] => {
  return filterNavigationItems(settingsNavigationItems);
};
