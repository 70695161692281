<div class="flex-col card content-block-padding clickable" [ngClass]="{ 'selected': selected }" (click)="onCardClicked()">
  <input type="radio" class="align-end" [checked]="selected" />
  <p class="font-medium-24-29">
    <b>
      <span>{{ bucket.baseTokens }}</span>
      <span *ngIf="bucket.additionalTokens > 0" class="has-text-primary"> + {{ bucket.additionalTokens }}</span>
    </b>
  </p>
  <p class="font-light-12-14" [vhMarginTop]="8">{{'payment.bucketsOverview.tokens' | translate}}</p>
  <p class="font-regular-16-19" [vhMarginTop]="16">
    <span>{{'payment.bucketsOverview.forAmount' | translate}} </span>
    <span>{{ bucket.price | currency:'EUR':'symbol':'1.0-0' }}</span>
  </p>
</div>
