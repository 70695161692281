import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { VisitService } from '@services/visits/visit.service';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { LanguageService } from '@services/language.service';
import { Visit } from '@models/visit.model';
import { RoomService } from '@services/room.service';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { Room } from '@models/room.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek, isEqual } from 'date-fns';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { COLORS } from '@constants/colors.constant';
import { PopoverService } from '@services/ui/popover.service';
import { PopoverPosition } from '@modules/shared/core/directives/popover-host.directive';
import { ToastService } from '@services/ui/toast.service';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { VisitStatus } from '@models/visit-status.model';
import { VisitStatusService } from '@services/visits/visit-status.service';
import { Option } from '@interfaces/option.interface';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { PreferencesService } from '@services/preferences.service';
import { defaultPreferences } from '@interfaces/preferences.interface';
import { BaseVisitManagementComponent } from '@modules/shared/visits/components/base-visit-management/base-visit-management.component';
import { ExportInstitutionDataDialogComponent } from '@modules/admin/admin-dashboard/dialogs/export-institution-data-dialog/export-institution-data-dialog.component';
import { CalendarEventMapperService } from '@services/calendar-event-mapper.service';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { EventsCalendarMode } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';
import { calendarZoomPresets } from '@modules/shared/calendar/components/events-calendar/calendar-zoom-presets';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { OpeningsService } from '@modules/admin/admin-dashboard/services/openings/openings.service';
import { VisitsOverviewVisitsService } from '@modules/admin/admin-dashboard/services/visits/visits-overview-visits.service';
import { BookingFlowInitiatorService } from '@modules/booking/services/booking-flow-initiator.service';
import { BookingFlow } from '@enums/booking-flow.enum';
import { RefreshInitiator, VisitsCalendarHeaderComponent } from '@modules/admin/admin-dashboard/components/visits-calendar-header/visits-calendar-header.component';
import { GenericDailyCount } from '@models/generic-daily-count.model';
import { VisitType } from '@enums/visit-type.enum';

@Component({
  selector: 'vh-visits-overview-page',
  templateUrl: './visits-overview-page.component.html',
  styleUrls: ['./visits-overview-page.component.scss', 'visits-overview-print.scss'],
})
@UntilDestroy()
export class VisitsOverviewPageComponent extends BaseVisitManagementComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly VisitType: typeof VisitType = VisitType;
  protected readonly POPOVER_ID: string = 'popup';
  private readonly NEW_EVENT_ID: string = 'new-event';

  @ViewChild(VisitsCalendarHeaderComponent) calendarHeader: VisitsCalendarHeaderComponent;

  navigationItems: NavigationItem[];

  locale: string;

  openings: CalendarOpening[] = [];

  isFilterBarCollapsed: boolean;
  lastCalendarEventTimesChangedEvent: CalendarEventTimesChangedEvent;

  zoomLevel: number = defaultPreferences.calendar.zoom;
  calenderModeOptions: ButtonOption[];
  calendarMode: EventsCalendarMode;

  mode: 'calendar' | 'search' = 'calendar';

  popoverPreferredPositioning: PopoverPosition;
  selectedVisit: Visit | null = null;

  isLoadingVisits: boolean;
  isLoadingReservationFlow: boolean = false;

  private fetchOpeningsSubscription: Subscription;

  constructor(
    breakpointObserver: BreakpointObserver,
    dialogService: MatDialog,
    activatedRoute: ActivatedRoute,
    router: Router,
    calendarEventMapperService: CalendarEventMapperService,
    languageService: LanguageService,
    private readonly openingsService: OpeningsService,
    private readonly visitHelperService: VisitsOverviewVisitsService,
    private readonly visitService: VisitService,
    private readonly roomService: RoomService,
    private readonly popoverService: PopoverService,
    private readonly toastService: ToastService,
    private readonly visitStatusService: VisitStatusService,
    private readonly alertDialogService: AlertDialogService,
    private readonly preferencesService: PreferencesService,
    private readonly bookingFlowInitiatorService: BookingFlowInitiatorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(breakpointObserver, dialogService, activatedRoute, router, calendarEventMapperService);

    this.navigationItems = getNavigationItems();
    this.locale = languageService.getPreferredLanguage();

    this.calenderModeOptions = [
      {
        isActive: true,
        isClickable: true,
        label: 'common.timeframes.singular.day',
      },
      {
        isActive: false,
        isClickable: true,
        label: 'common.timeframes.singular.week',
      },
      {
        isActive: false,
        isClickable: true,
        label: 'common.timeframes.singular.month',
      },
    ];
  }

  ngOnInit(): void {
    this.onRestoreState();
    this.fetchVisitStatuses();
    this.loadRooms();
  }

  openPopup(preferredPosition: PopoverPosition = 'right'): void {
    this.popoverPreferredPositioning = preferredPosition;
    this.popoverService.open(this.POPOVER_ID);
  }

  closePopup(): void {
    this.popoverService.close(this.POPOVER_ID);
  }

  onHourSegmentClicked(date: Date): void {
    if (isEqual(this.selectedVisit?.start, date)) {
      this.openPopup(this.determinePopupPositionByClickedDate(date));

      return;
    }

    this.closePopup();
    this.addNewEvent(date);
  }

  private addNewEvent(date: Date): void {

    this.selectedVisit = Visit.createDummy(date);

    this.events = [
      ...this.events,
      {
        ...this.calendarEventMapperService.visitToCalenderEvent(this.selectedVisit || null),
        id: this.NEW_EVENT_ID,
        cssClass: 'anim-pulsating',
        draggable: true,
        color: {
          primary: COLORS.primary,
          secondary: COLORS.white,
          secondaryText: COLORS.white,
        },
        start: this.selectedVisit.start,
        end: this.selectedVisit.end,
      },
    ];

    this.openPopup(this.determinePopupPositionByClickedDate(date));
    this.autoPauseAutoRefresh();
  }

  updatePlaceholderEventTime(updatedEvent: Visit): void {
    this.events = this.events.map((event: CalendarEvent) =>
      event.id === this.NEW_EVENT_ID
        ? { ...event, start: updatedEvent.start, end: updatedEvent.end }
        : event
    );
    this.changeDetectorRef.detectChanges();
  }

  private determinePopupPositionByClickedDate(date: Date): PopoverPosition {
    return date.getDay() > 3 || date.getDay() === 0 ? 'right' : 'left';
  }

  onRestoreState(): void {
    super.onRestoreState();

    const params: Params = this.activatedRoute.snapshot.queryParams;

    if (params.zoom) {
      this.zoomLevel = parseInt(params.zoom) ?? this.preferencesService.getPreferences().calendar.zoom;

      if (this.zoomLevel < 0 || this.zoomLevel > calendarZoomPresets.size) {
        this.zoomLevel = 0;
      }
    }

    this.isFilterBarCollapsed = params.collapsed?.toLocaleLowerCase() === 'true';
    this.onCalendarModeChangeClicked(params.mode as EventsCalendarMode ?? 'day');
  }

  onStoreState(): void {
    super.onStoreState({
      date: format(this.selectedDate, DATE_FORMATS.clientDate),
      mode: this.calendarMode,
      collapsed: this.isFilterBarCollapsed,
      zoom: this.zoomLevel,
    });
  }

  onCalendarModeChangeClicked(mode: EventsCalendarMode): void {
    this.calendarMode = mode;

    switch (mode) {
      case 'day':
        this.filter.from = startOfDay(this.selectedDate);
        this.filter.to = endOfDay(this.selectedDate);
        break;
      case 'week':
        this.filter.from = startOfWeek(this.selectedDate, { weekStartsOn: 1 });
        this.filter.to = endOfWeek(this.selectedDate, { weekStartsOn: 1 });
        break;
      default:
        this.filter.from = startOfMonth(this.selectedDate);
        this.filter.to = endOfMonth(this.selectedDate);
        break;
    }

    this.onStoreState();
  }

  onEventClicked(event: CalendarEvent): void {
    this.selectedVisit = this.visits.find((visit: Visit) => visit.id === event.id);

    if (this.selectedVisit.visitType === VisitType.MEDICAL) {
      this.openVisitDetailsDialog();
      this.onStoreState();
    } else {
      this.openOtherVisitDetailsDialog();
      this.onStoreState();
    }
  }

  onDateSelected(date: Date): void {
    this.selectedDate = date;
    this.onCalendarModeChangeClicked('day');
  }

  onRoomFilterClicked(room: Room): void {
    const previousCount: number = this.filter.selectedRooms.size;

    if (this.filter.selectedRooms.has(room.id)) {
      this.filter.selectedRooms.delete(room.id);
    } else {
      this.filter.selectedRooms.set(room.id, room);
    }

    this.loadOpenings();

    // If we deselected a room then we can simply filter the visits instead of fetching them from the server
    if (previousCount > this.filter.selectedRooms.size) {
      this.visits = this.visits.filter((visit: Visit) => this.filter.selectedRooms.has(visit.room.id));
      this.recreateEvents();

      return;
    }

    this.loadVisits();
  }

  onFilterChanged(): void {
    if (!this.filter.hasPatientFilterActive() && this.mode !== 'calendar') {
      this.changeViewMode('calendar');

      return;
    }

    if (this.filter.selectedRooms.size === 0) {
      this.loadVisits();

      return;
    }

    if (this.filter.hasPatientFilterActive()) {
      this.loadVisits();
      this.changeViewMode('search');
    }
  }

  onSelectedDateChange(date: Date): void {
    this.selectedDate = date;

    switch (this.calendarMode) {
      case 'day':
        this.filter.from = startOfDay(this.selectedDate);
        this.filter.to = endOfDay(this.selectedDate);
        break;
      case 'week':
        this.filter.from = startOfWeek(this.selectedDate);
        this.filter.to = endOfWeek(this.selectedDate);
        break;
      case 'month':
        this.filter.from = startOfMonth(this.selectedDate);
        this.filter.to = endOfMonth(this.selectedDate);
        break;
    }

    this.onStoreState();
    this.loadVisits(true);
    this.loadOpenings();
  }

  onCollapseFilterBarClicked(): void {
    this.isFilterBarCollapsed = !this.isFilterBarCollapsed;
    this.onStoreState();
  }

  onEventTimeChanged(changedEvent: CalendarEventTimesChangedEvent): void {
    const visitIndex = this.visits.findIndex((visit: Visit) => visit.id === changedEvent.event.id);
    this.selectedVisit = this.visits[visitIndex];

    this.recreateEvent(visitIndex);

    const eventIndex = this.events.findIndex((event: CalendarEvent) => event.id === changedEvent.event.id);
    this.events[eventIndex].start = changedEvent.newStart;
    this.events[eventIndex].end = changedEvent.newEnd;
    this.events[eventIndex].cssClass = 'anim-pulsating';

    this.lastCalendarEventTimesChangedEvent = changedEvent;

    this.autoPauseAutoRefresh();
  }

  onRescheduleVisitSuccess(newVisit: Visit): void {
    if (this.lastCalendarEventTimesChangedEvent && this.popoverService.isOpen(this.lastCalendarEventTimesChangedEvent.event.meta.popoverId)) {
      this.popoverService.close(this.lastCalendarEventTimesChangedEvent.event.meta.popoverId);
    }

    this.lastCalendarEventTimesChangedEvent = null;

    this.onVisitChanged(newVisit);
    this.autoResumeAutoRefresh();
    this.loadVisits(true);
  }

  onRescheduleVisitFailed(): void {
    this.onCancelRescheduleEventClicked();
    this.toastService.showError('admin.pages.dashboard.rescheduleConfirmation.errorRescheduleFailed', 'common.ok')
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onCancelRescheduleEventClicked(): void {
    const visitIndex = this.visits.findIndex((visit: Visit) => visit.id === this.lastCalendarEventTimesChangedEvent.event.id);
    this.visits[visitIndex].start = this.lastCalendarEventTimesChangedEvent.event.start;
    this.visits[visitIndex].end = this.lastCalendarEventTimesChangedEvent.event.end;

    this.recreateEvent(visitIndex);

    this.popoverService.close(this.lastCalendarEventTimesChangedEvent.event.meta.popoverId);
    this.lastCalendarEventTimesChangedEvent = null;
    this.selectedVisit = null;

    this.autoResumeAutoRefresh();
  }

  onSearchClicked(): void {
    this.changeViewMode('search');
  }

  onSearchResultClicked(event: CalendarEvent): void {
    this.selectedVisit = this.visits.find((visit: Visit) => visit.id === event.id);
    this.openVisitDetailsDialog();
  }

  onClearFilterClicked(): void {
    this.filter.resetPatientFields();
    this.loadVisits();
    this.changeViewMode('calendar');
  }

  onScrolledToEndOfSearchResults(): void {
    this.filter.currentPage++;
    this.loadVisits();
  }

  onZoomLevelChanged(zoomLevel: number): void {
    this.zoomLevel = zoomLevel;
    this.onStoreState();
    this.preferencesService.storePreference('calendar.zoom', zoomLevel);
  }

  onEventActionClicked(event: MouseEvent, visit: Visit, option: Option): void {
    event.stopPropagation();

    this.visitStatusService.addStatusToVisit$(visit.id, option.value as string)
      .pipe(untilDestroyed(this))
      .subscribe((visitStatuses: VisitStatus[]) => {
        const index: number = this.visits.findIndex((v: Visit) => v.id === visit.id);
        this.visits[index].statusHistory = visitStatuses;
        this.recreateEvent(index);
      });
  }

  onUpdatePaymentStatusClicked(event: MouseEvent, visit: Visit): void {
    event.stopPropagation();

    const isPaid: boolean = !visit.paymentConfirmedAt;

    if (isPaid) {
      const index: number = this.visits.findIndex((v: Visit) => v.id === visit.id);
      this.visits[index].paymentConfirmedAt = new Date();
      this.recreateEvent(index);
      this.onUpdatedPaymentStatusConfirmed(visit, isPaid);

      return;
    }

    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.dashboard.visitDialog.undoPaymentDialog.title',
      messageTranslationKey: 'admin.pages.dashboard.visitDialog.undoPaymentDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onUpdatedPaymentStatusConfirmed(visit, isPaid);
        }
      });
  }

  onRefreshRequested(initiator: RefreshInitiator): void {
    // If a manual refresh was requested then we need to reset the auto refresh timer so that it
    // doesn't potentially trigger multiple refreshes in a short amount of time
    if (initiator === 'manual') {
      this.calendarHeader.resetAutoRefresh();
    }

    this.loadVisits(true);
  }

  onExportClicked(): void {
    const dialogConfig: MatDialogConfig = {
      minWidth: '40%',
      data: {
        from: this.filter.from,
        to: this.filter.to,
      },
    };

    this.dialogService.open(ExportInstitutionDataDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onStartBookingFlowClicked(): void {
    this.isLoadingReservationFlow = true;
    this.bookingFlowInitiatorService.initialise$(BookingFlow.INSTITUTION_EMPLOYEE)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private loadVisits(forceReload: boolean = false): void {
    this.visits = [];
    this.events = [];

    if (this.filter.selectedRooms.size === 0) {
      return;
    }

    this.isLoadingVisits = true;
    this.loadVisitsSubscription?.unsubscribe();

    if (this.mode === 'search') {
      this.loadVisitsForSearch();

      return;
    }

    if (this.calendarMode === 'month') {
      this.loadVisitsForMonthlyView();

      return;
    }

    this.loadVisitsSubscription = this.visitHelperService.loadVisits$(this.filter, forceReload)
      .pipe(untilDestroyed(this))
      .subscribe((visits: Visit[]) => {
        this.isLoadingVisits = false;
        this.visits = visits;
        this.filter.currentPage = 1;
        this.onVisitsLoaded();
        this.loadVisitsSubscription = null;
      });
  }

  private loadVisitsForSearch(): void {
    this.loadVisitsSubscription = this.visitService.getVisitsForCurrentInstitutionWithFilter$(this.filter)
      .pipe(untilDestroyed(this))
      .subscribe((result: PaginatedModel<Visit>): void => {
        this.isLoadingVisits = false;

        this.filter.currentPage = result.meta.current_page;
        this.visits = this.filter.currentPage > 1
          ? this.visits.concat(result.data)
          : result.data;

        this.onVisitsLoaded();
        this.loadVisitsSubscription = null;
      });

    return;
  }

  private loadVisitsForMonthlyView(): void {
    this.loadVisitsSubscription = this.visitService.getDailyVisitCountsForCurrentInstitution$(this.filter.from, this.filter.to)
      .pipe(untilDestroyed(this))
      .subscribe((counts: GenericDailyCount[]) => {
        this.isLoadingVisits = false;
        this.visits = [];
        this.events = this.calendarEventMapperService.genericDailyCountsToCalendarEvents(counts);
      });
  }

  private changeViewMode(mode: typeof this.mode): void {
    this.mode = mode;

    this.filter.areDateFiltersEnabled = this.mode === 'calendar';
    this.filter.currentPage = this.mode !== 'calendar' ? this.filter.currentPage : 1;
    this.filter.pageSize = this.mode === 'calendar' ? VisitFilter.PAGE_SIZE_CALENDAR : VisitFilter.PAGE_SIZE_SEARCH;
  }

  private fetchVisitStatuses(): void {
    this.visitStatusService.getVisitStatuses$()
      .pipe(untilDestroyed(this))
      .subscribe((statuses: VisitStatus[]) => {
        this.visitStatusOptions = statuses.map((status: VisitStatus) => status.toOption());
      });
  }

  private onUpdatedPaymentStatusConfirmed(visit: Visit, isPaid: boolean): void {
    const index: number = this.visits.findIndex((v: Visit) => v.id === visit.id);

    this.visitService.updateVisit$(visit.id, { payment_was_confirmed: isPaid })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (updatedVisit: Visit): void => {
          this.visits[index] = updatedVisit;
          this.recreateEvent(index);
        },
        error: (): void => {
          this.visits[index].paymentConfirmedAt = null;
          this.recreateEvent(index);
        },
      });
  }

  private loadRooms(): void {
    this.roomService.getRoomsOfInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((rooms: Room[]) => {
        this.filter.allRooms = rooms;
        this.loadVisits();
        this.loadOpenings();
      });
  }

  private loadOpenings(): void {
    this.fetchOpeningsSubscription?.unsubscribe();
    this.fetchOpeningsSubscription = this.openingsService.loadOpenings$(this.filter, this.calendarMode)
      .pipe(untilDestroyed(this))
      .subscribe((openings: CalendarOpening[]): void => {
        this.openings = openings;
      });
  }

  protected getCurrentRoute(): string {
    return NAVIGATION.adminDashboardAppointments.route;
  }

  onClosePopup(loadVisits: boolean = true): void {
    this.closePopup();
    this.autoResumeAutoRefresh();
    this.loadVisits(loadVisits);
  }
}
