import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { IObject } from '@app-types/iobject.type';

export const extractErrorMessageFromFormValidation = (
  formKeys: Map<string, string>,
  formGroup: UntypedFormGroup,
  getErrorMessageFunction: (errorIdentifier: string, formKey?: string) => string
): string => {
  // Check if all required validators are passed
  let errorMessage: string = null;

  for (const formKey of formKeys.values()) {
    const formControl: AbstractControl = formGroup.controls[formKey];
    if (formControl?.errors?.required) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      errorMessage = errorMessage || getErrorMessageFunction('required', formKey);
    }
  }

  if (errorMessage) {
    return errorMessage;
  }

  // Check if all other validators are passed
  for (const formKey of formKeys.values()) {
    const formControl: AbstractControl = formGroup.controls[formKey];

    if (formControl?.errors) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const firstErrorIdentifier: string = Object.keys(formControl.errors)[0];

      return getErrorMessageFunction(firstErrorIdentifier, formKey);
    }
  }
};

export const getUnknownErrorMessage = (errorIdentifier: string, formKey?: string): string => {
  console.warn(`Missing error message for errorIdentifier = ${errorIdentifier}` + (formKey ? ` and formKey = ${formKey}` : ''));

  return 'common.unknownError';
};

export const stripFalsyPropertiesFromObject = (value: IObject): IObject => {
  Object.keys(value)
    .forEach((key: string) => {
      if (!value[key]) {
        delete value[key];
      }
    });

  return value;
};

/**
 * Trigger form validation for all form controls in a form group
 * @param formGroup
 */
export const triggerFormValidation = (formGroup: UntypedFormGroup): void => {
  if (!formGroup) {
    return;
  }

  Object.keys(formGroup.controls).forEach((field: string) => {
    const control = formGroup.get(field);
    if (control) {
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
};
