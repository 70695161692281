<table class='font-light-16-19'>
  <thead>
    <tr>
      <th>{{ 'conversations.list.name' | translate }}</th>
      <th>{{ 'conversations.list.socialSecurityNumber' | translate }}</th>
      <th>{{ 'conversations.list.physician' | translate }}</th>
      <th>{{ 'conversations.list.questionList' | translate }}</th>
      <th>{{ 'conversations.list.summary' | translate }}</th>
      <th>{{ 'conversations.list.creationDate' | translate }}</th>
      <th>{{ 'conversations.list.deadline' | translate }}</th>
      <th>{{ 'conversations.list.status' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody *ngIf="!loading || conversations.length > 0">
    <tr
      *ngFor="let conversation of conversations; let index = index"
      class="clickable"
      (click)="clickConversation(conversation, $event)"
      [ngClass]="{ 'removed-conversation-record': conversation.status === ConversationStatus.REMOVED }">
      <td class="font-medium-18-22">{{ conversation?.patient?.fullName }}</td>
      <td>{{ conversation?.patient?.socialSecurityNumber ?? '/' }}</td>
      <td>{{ conversation?.doctor?.fullName }}</td>
      <td>{{ conversation?.questionList }}</td>
      <td>{{ conversation?.cause ?? '/' }}</td>
      <td>{{ conversation?.createdAt | dfnsFormat: DATE_FORMATS.clientDate }}</td>
      <td>{{ conversation?.dueDate | dfnsFormat: DATE_FORMATS.clientDate }}</td>
      <td>
        <vh-conversation-status [status]="conversation.status" [position]="'right'"></vh-conversation-status>
      </td>
      <td class="open-menu">
        <vh-icon
          [cdkMenuTriggerFor]="cdkMenuTemplate"
          [cdkMenuTriggerData]="{ conversation: conversation }"
          class="menu-icon clickable"
          src="assets/icons/dots-vertical.svg"
          height="24">
        </vh-icon>
      </td>
    </tr>
  </tbody>
</table>

<p [vhMarginTop]="24" *ngIf="conversations.length === 0 && !loading">
  {{ 'conversations.noConversations' | translate }}
</p>

<vh-loader *ngIf="loading" class="loader" [isFlex]="false"></vh-loader>

<ng-template #cdkMenuTemplate let-conversation="conversation">
  <div cdkMenu class="menu-content">
    <vh-conversation-action-menu [conversation]="conversation"></vh-conversation-action-menu>
  </div>
</ng-template>
