import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * A directive which can be applied to a component to listen for scroll events when control is pressed.
 * These actions are commonly used to perform a zoom action.
 */
@Directive({
  selector: '[vhCtrlZoom]',
})
export class CtrlZoomDirective {
  @Output() zoomIn: EventEmitter<void> = new EventEmitter<void>();
  @Output() zoomOut: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (!this.isCtrlPressed(event)) {
      return;
    }
    event.preventDefault();

    if (this.isScrollingDown(event)) {
      this.zoomOut.emit();

      return;
    }

    this.zoomIn.emit();
  }

  private isCtrlPressed(event: WheelEvent): boolean {
    return event.ctrlKey || event.metaKey;
  }

  private isScrollingDown(event: WheelEvent): boolean {
    return event.deltaY > 0;
  }
}
