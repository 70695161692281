export class WizardState {
  private readonly START: number = 1;

  private _currentStep?: number;
  private _previousButtonIsHidden?: boolean;
  private _nextButtonIsHidden?: boolean;
  private _nextButtonIsDisabled?: boolean;
  private _nextButtonLabelTranslationKey?: string;
  private _skippedSteps?: Set<number> = new Set<number>();
  private _hiddenSteps?: Set<number> = new Set<number>();
  private _optionalSteps?: Set<number> = new Set<number>();

  constructor(private _labels: string[]) {}

  get totalSteps(): number {
    return this._labels.length;
  }

  get labels(): string[] {
    return this._labels;
  }

  get currentStep(): number {
    return this._currentStep;
  }

  private set currentStep(value: number) {
    this._currentStep = value;
  }

  get previousButtonIsHidden(): boolean {
    return this._previousButtonIsHidden;
  }

  set previousButtonIsHidden(value: boolean) {
    this._previousButtonIsHidden = value;
  }

  get nextButtonIsHidden(): boolean {
    return this._nextButtonIsHidden;
  }

  set nextButtonIsHidden(value: boolean) {
    this._nextButtonIsHidden = value;
  }

  get nextButtonIsDisabled(): boolean {
    return this._nextButtonIsDisabled;
  }

  set nextButtonIsDisabled(value: boolean) {
    this._nextButtonIsDisabled = value;
  }

  get nextButtonLabelTranslationKey(): string {
    return this._nextButtonLabelTranslationKey;
  }

  get skippedSteps(): Set<number> {
    return this._skippedSteps;
  }

  get hiddenSteps(): Set<number> {
    return this._hiddenSteps;
  }

  get optionalSteps(): Set<number> {
    return this._optionalSteps;
  }

  canGoBack(): boolean {
    return this.currentStep > this.START && this.currentStep <= this.totalSteps;
  }

  isAtStart(): boolean {
    return this.currentStep === this.START;
  }

  goToStart(): void {
    this.goToStep(this.START);
  }

  goToStep(step: number): void {
    if (this.hiddenSteps.has(step)) {
      this.goToStep(step + 1);

      return;
    }

    if (step > this.totalSteps || step < this.START) {
      return;
    }

    this._currentStep = step;

    this.updateState();
  }

  previous(): void {
    if (this.currentStep < this.START) {
      return;
    }

    if (this.hiddenSteps.has(this.currentStep - 1)) {
      this.currentStep--;
      this.previous();

      return;
    }

    this.currentStep--;
    this.updateState();
  }

  next(): void {
    if (this.currentStep === this.totalSteps) {
      return;
    }

    if (this.hiddenSteps.has(this.currentStep + 1)) {
      this.currentStep++;
      this.next();

      return;
    }

    this.currentStep++;
    this.updateState();
  }

  getVisibleStepNumber(stepIndex: number): number {
    // Get visible steps (all labels minus the hidden labels)
    const visibleStepIndices = this._labels
      .map((label: string, index: number) => index + 1)
      .filter((stepNumber: number) => !this._hiddenSteps.has(stepNumber));

    return visibleStepIndices.indexOf(stepIndex + 1) + 1;
  }

  private updateState(): void {
    this.nextButtonIsHidden = this.currentStep === this.totalSteps;
    this.nextButtonIsDisabled = this.currentStep === this.totalSteps;
    this.previousButtonIsHidden = this.currentStep === this.START;
  }
}
