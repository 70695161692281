<div 
  class="card-info clickable flex-row"
  (click)="selectUser(user)"
  [ngClass]="{ 'selected': isSelected, 'not-selectable': !isSelectable }">

  <!-- User info -->
  <div class="user-info">
    <h2 vhSkeleton
        [skeletonIsVisible]="showSkeleton"
        [skeletonIsEmpty]="showSkeleton"
        [skeletonWidth]="'90%'"
        [skeletonHeight]="28"
        class="font-medium-16-19">
      {{ user?.fullName }}
      <span class="font-light-12-14"> {{ user?.birthdate | dfnsFormat: DATE_FORMATS.clientDate }}</span>
      <span class="font-light-12-14"> {{ user?.socialSecurityNumber ?? '/' }}</span>
    </h2>

    <div class="user-contact-info gap-v-4 font-light-12-14">
      <vh-message
        [vhMarginTop]="6"
        class="font-light-12-14"
        [text]="user?.email ?? '-'"
        [iconLeftPath]="'assets/icons/mail.svg'"
        [iconLeftFill]="COLORS.secondary"
        [iconLeftHeight]="18"
        [iconLeftWidth]="18"
        vhSkeleton
        [skeletonIsVisible]="showSkeleton"
        [skeletonIsEmpty]="showSkeleton"
        [skeletonWidth]="'70%'"
        [skeletonHeight]="22">
      </vh-message>

      <vh-message
        [vhMarginTop]="6"
        class="font-light-12-14"
        [text]="user?.phone ?? '-'"
        [iconLeftPath]="'assets/icons/phone.svg'"
        [iconLeftFill]="COLORS.secondary"
        [iconLeftHeight]="18"
        [iconLeftWidth]="18"
        vhSkeleton
        [skeletonIsVisible]="showSkeleton"
        [skeletonIsEmpty]="showSkeleton"
        [skeletonWidth]="'70%'"
        [skeletonHeight]="22">
      </vh-message>
    </div>
  </div>
  
  <!-- Radio button -->
  <input *ngIf="isSelectable" type="radio" name="selectedUser" [checked]="isSelected">
</div>
