<ng-container [formGroup]="formGroup">
  <p *ngIf="errorMessage" class="has-text-error font-regular-14-17" [innerHTML]="errorMessage | translate"></p>
  <vh-textarea-input
    [vhMarginTop]="8"
    [label]="'admin.pages.dashboard.cancelVisitDialog.cancellationReasonLabel' | translate"
    [formControlName]="CANCEL_VISIT_FORM_KEYS.get('cancellationMessage')">
  </vh-textarea-input>
  <p class="font-light-12-14 align-end">{{ 'admin.pages.dashboard.cancelVisitDialog.cancellationReasonDisclaimer' | translate }}</p>

  <vh-select-input
    [vhMarginTop]="8"
    [label]="'admin.pages.dashboard.cancelVisitDialog.reasonLabel' | translate"
    [formControlName]="CANCEL_VISIT_FORM_KEYS.get('cancelReasonId')"
    [options]="cancelReasonOptions"
    [hasEmptyOption]="true">
  </vh-select-input>

  <vh-select-input
    [vhMarginTop]="8"
    [label]="'admin.pages.dashboard.cancelVisitDialog.actionLabel' | translate"
    [formControlName]="CANCEL_VISIT_FORM_KEYS.get('cancelActionId')"
    [options]="cancelActionOptions"
    [hasEmptyOption]="true">
  </vh-select-input>
</ng-container>
