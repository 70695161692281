<div class="results">
  <div class="flex-row-s-c">
    <!-- Title -->
    <h2>{{ 'conversations.details.results' | translate }}</h2>

    <!-- Action buttons -->
    <p-splitButton
      [label]="'conversations.details.copySummary' | translate"
      [model]="menuItems"
      (onClick)="regenerateSummary()"
      [buttonDisabled]="!conversation.summary">
    </p-splitButton>
  </div>

  <!-- Summary -->
  <div class="summary gap-v-16" [vhMarginTop]="16">
    <div class="gap-v-4" *ngFor="let group of conversation.summaryJson | keyvalue : returnZero">
      <h3>{{ group.key }}</h3>
      <div *ngFor="let entry of group.value | keyvalue : returnZero" class="flex-row gap-h-4">
        <span>{{ entry.key }}:</span>
        <span>{{ entry.value }}</span>
      </div>
    </div>
  </div>
  
  <p *ngIf="!conversation.summaryJson && conversation.summary">{{ conversation.summary }}</p>
  
  <!-- No summary -->
  <p *ngIf="!conversation.summary">{{ 'conversations.details.noResults' | translate }}</p>
</div>
