import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Doctor, DoctorJson } from '@models/doctor.model';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { User } from '@models/user.model';
import { IObject } from '@app-types/iobject.type';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  private readonly httpOptions: IObject = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };

  constructor(private readonly http: HttpClient) {}

  createDoctor$(data: IObject): Observable<Doctor> {
    const endpoint: string = ENDPOINTS.createDoctor.route;

    return this.http.post(environment.apiBaseUrl + endpoint, data, this.httpOptions)
      .pipe(map(Doctor.fromJson));
  }

  /**
   * @deprecated use createDoctor$ instead, the model changed a bit
   * @param rizivNumber
   * @param user
   */
  createDoctorFromRiziv$(rizivNumber: string, user: User): Observable<Doctor> {
    const doctorDTO: IObject = { first_name: user.firstName, last_name: user.lastName, riziv_number: rizivNumber };
    const endpoint: string = ENDPOINTS.createDoctor.route;

    return this.http.post(environment.apiBaseUrl + endpoint, doctorDTO, this.httpOptions).pipe(map(Doctor.fromJson));
  }

  updateDoctor$(doctorId: string, data: IObject): Observable<Doctor> {
    const endpoint: string = ENDPOINTS.updateDoctor.route.replace(
      `{${ENDPOINTS.updateDoctor.pathParams.doctorId}}`,
      doctorId
    );

    return this.http.put(environment.apiBaseUrl + endpoint, data, this.httpOptions)
      .pipe(map(Doctor.fromJson));
  }

  getDoctorByRizivNumber$(rizivNumber: string): Observable<Doctor> {
    const endpoint: string = ENDPOINTS.getDoctorByRizivNumber.route.replace(
      `{${ENDPOINTS.getDoctorByRizivNumber.pathParams.rizivNumber}}`,
      rizivNumber
    );

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map(Doctor.fromJson));
  }

  linkDoctorToUser$(rizivNr: string, user: User): Observable<User> {
    const endpoint: string = ENDPOINTS.linkDoctorToUser.route
      .replace(`{${ENDPOINTS.linkDoctorToUser.pathParams.userId}}`, user.id)
      .replace(`{${ENDPOINTS.linkDoctorToUser.pathParams.rizivNumber}}`, rizivNr);

    return this.http.put(environment.apiBaseUrl + endpoint, this.httpOptions).pipe(map(User.fromJson));
  }

  getDoctorsOfInstitution$(institutionId: string): Observable<Doctor[]> {
    const endpoint: string = ENDPOINTS.getDoctorsOfInstitution.route.replace(
      `{${ENDPOINTS.getDoctorsOfInstitution.pathParams.institutionId}}`,
      institutionId
    );

    return this.http.get(environment.apiBaseUrl + endpoint).pipe(map((json: DoctorJson[]): Doctor[] => json.map(Doctor.fromJson)));
  }

  unlinkDoctor$(user: User): Observable<void> {
    return this.http
      .delete(
        environment.apiBaseUrl +
          ENDPOINTS.unlinkDoctorFromUser.route.replace(`{${ENDPOINTS.unlinkDoctorFromUser.pathParams.userId}}`, user.id)
      )
      .pipe(switchToEmptyObservable());
  }
}
