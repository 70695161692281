import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UPDATE_PASSWORD_FORM_KEYS } from '@constants/form-keys/update-password-form-keys.constant';
import { mustMatch } from '@utils/validators/must-match.validator';
import { passwordValidator } from '@utils/validators/password.validator';

@Injectable({
  providedIn: 'root',
})
export class UpdatePasswordFormService extends AbstractFormService {
  createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [UPDATE_PASSWORD_FORM_KEYS.get('current_password')]: [null, [Validators.required]],
      [UPDATE_PASSWORD_FORM_KEYS.get('new_password')]: [null, [Validators.required, passwordValidator()]],
      [UPDATE_PASSWORD_FORM_KEYS.get('new_password_confirmation')]: [null, [Validators.required, mustMatch(UPDATE_PASSWORD_FORM_KEYS.get('new_password'), UPDATE_PASSWORD_FORM_KEYS.get('new_password_confirmation'))]],
    });
  }
}
