import { MinimalVirtualSlotJson, VirtualSlot } from '@models/virtual-slot.model';

export class SubsequentVirtualSlot {
  constructor(private _virtualSlots: VirtualSlot[]) {}

  // TODO: this assumes that all slots are for the same day
  get start(): Date {
    return this._virtualSlots[0].start;
  }

  get virtualSlots(): VirtualSlot[] {
    return this._virtualSlots;
  }

  static fromJson(json: MinimalVirtualSlotJson[]): SubsequentVirtualSlot {
    return new SubsequentVirtualSlot(
      json.map((slot: MinimalVirtualSlotJson) => VirtualSlot.fromMinimalJson(slot))
    );
  }
}
