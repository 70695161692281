<vh-dialog-frame
  [title]="
    (password === null
      ? 'admin.pages.dashboard.exportInstitutionData.titleDefault'
      : 'admin.pages.dashboard.exportInstitutionData.titleSuccess'
    ) | translate
  "
  (closeClicked)="close()">
  <ng-container *ngIf="password === null" [ngTemplateOutlet]="exportForm"></ng-container>
  <ng-container *ngIf="password !== null" [ngTemplateOutlet]="exportSuccess"></ng-container>
</vh-dialog-frame>

<ng-template #exportForm>
  <mat-dialog-content class="flex-row-l-c" [formGroup]="formGroup">
    <vh-message *ngIf="errorMessage" [text]="errorMessage | translate" class="not-clickable has-text-error"></vh-message>
    <!-- For now, this is checked by default as of now there is only one thing to export -->
    <vh-checkbox
      #referralLetters
      [label]="'admin.pages.dashboard.exportInstitutionData.referralLettersLabel' | translate"
      [isDisabled]="true"
      [value]="true">
    </vh-checkbox>

    <div class="flex-col-l-c gap-v-8 indent-left" vhMarginTop="8">
      <div class="flex-row-l-c gap-h-8">
        <label class="min-label-width">{{ 'admin.pages.dashboard.exportInstitutionData.fromLabel' | translate }}</label>
        <vh-text-input
          type="date"
          [formControlName]="EXPORT_INSTITUTION_DATA_FORM_KEYS.get('from')"
          [isDisabled]="!referralLetters.value"
          [min]="'2020-01-01'">
        </vh-text-input>
      </div>

      <div class="flex-row-l-c gap-h-8">
        <label class="min-label-width">{{ 'admin.pages.dashboard.exportInstitutionData.toLabel' | translate }}</label>
        <vh-text-input
          type="date"
          [formControlName]="EXPORT_INSTITUTION_DATA_FORM_KEYS.get('to')"
          [isDisabled]="!referralLetters.value"
          [min]="'2020-01-01'">
        </vh-text-input>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button [label]="'common.ok' | translate" (buttonClick)="onConfirmClicked()"> </vh-button>
    <vh-button [label]="'common.cancel' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="close()"> </vh-button>
  </mat-dialog-actions>
</ng-template>

<ng-template #exportSuccess>
  <mat-dialog-content>
    <div class="flex-col">
      <p
        [innerHTML]="
          'admin.pages.dashboard.exportInstitutionData.passwordDisclaimer.paragraph1' | translate : { email: currentUser.email }
        "></p>
      <p [innerHTML]="'admin.pages.dashboard.exportInstitutionData.passwordDisclaimer.paragraph2' | translate"></p>
      <p [innerHTML]="'admin.pages.dashboard.exportInstitutionData.passwordDisclaimer.paragraph3' | translate"></p>
      <label vhMarginTop="8">{{ 'common.fields.password' | translate }}</label>
      <vh-copy-to-clipboard
        [text]="password"
        [confirmationMessage]="'admin.pages.dashboard.exportInstitutionData.copyConfirmation' | translate">
      </vh-copy-to-clipboard>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button [label]="'common.ok' | translate" (buttonClick)="close()"> </vh-button>
  </mat-dialog-actions>
</ng-template>
