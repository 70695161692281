import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { NAVIGATION } from '@constants/navigation.constant';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { ButtonStyle } from '@enums/button-style.enum';
import { Option } from '@interfaces/option.interface';
import { AppService } from '@services/app.service';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.model';
import { GenderService } from '@services/gender.service';
import { MASKS } from '@constants/masks.constant';
import { COLORS } from '@constants/colors.constant';
import { RegisterFormService } from '@services/form-services/register-form.service';
import { LanguageService } from '@services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
import { IObject } from '@app-types/iobject.type';
import { format } from 'date-fns';
import { isTrue } from '@utils/helpers/rx-js.util';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage, triggerFormValidation } from '@utils/helpers/form.util';
import { debounce } from '@utils/decorators/debounce.decorator';
import { InstitutionService } from '@services/institution.service';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'vh-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
@UntilDestroy()
export class RegisterComponent implements OnInit {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly REGISTER_FORM_KEYS: typeof REGISTER_FORM_KEYS = REGISTER_FORM_KEYS;
  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;

  @Input() headerIsVisible: boolean = true;
  @Input() loginButtonIsVisible: boolean = false;
  @Input() hasPasswordInput: boolean = true;
  @Input() hasPrivacyPolicyInput: boolean = true;
  @Input() registerButtonIsVisible: boolean = true;
  @Input() registerSelf: boolean = true;
  @Input() autoLogin: boolean = false;
  @Input() emailOrPhoneRequired: boolean = false;
  @Input() afterSuccessfulRegisterFunction: (user: User) => void;

  @Output() existingPatientEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  privacyStatementUrl: string = environment.links.privacyStatement;

  existingPatientId: string | null = null;
  socialSecurityNumberMatchingExistingPatient: string | null = null;

  isInitialized: boolean;

  formGroup: UntypedFormGroup;
  genderOptions: Option[];
  languageOptions: Option[];
  privacyStatementIsRead: boolean;

  registerCallIsInProgress: boolean;
  errorMessageTranslationKey: string;

  currentDate: string;

  constructor(
    private readonly genderService: GenderService,
    private readonly appService: AppService,
    private readonly authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly institutionService: InstitutionService,
    private readonly registerFormService: RegisterFormService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.currentDate = format(new Date(), 'yyyy-MM-dd');
    this.appService.translationsAreInitialized$.pipe(filter(isTrue)).subscribe((): void => {
      this.formGroup = this.registerFormService.createFormGroup(this.hasPasswordInput, this.hasPrivacyPolicyInput, this.registerSelf);
      this.isInitialized = true;
    });

    this.genderService.getGenderOptions$().subscribe((options: Option[]) => this.genderOptions = options);
    this.languageService.getPlatformLanguagesAsOptions$().subscribe((options: Option[]) => this.languageOptions = options);
  }

  getFormData(): IObject | null {
    if (this.emailOrPhoneRequired) {
      this.registerFormService.enableEmailOrPhoneValidation(this.formGroup);
    }

    triggerFormValidation(this.formGroup);

    this.errorMessageTranslationKey = extractErrorMessageFromFormValidation(
      REGISTER_FORM_KEYS,
      this.formGroup,
      this.getFormErrorMessageTranslationKey
    );

    if (this.errorMessageTranslationKey) {
      return null;
    }

    return {
      ...this.formGroup.value,
      language: this.languageService.getPreferredLanguage(),
    };
  }

  register = (): void => {
    if (this.emailOrPhoneRequired) {
      this.registerFormService.enableEmailOrPhoneValidation(this.formGroup);
    }

    triggerFormValidation(this.formGroup);

    this.errorMessageTranslationKey = extractErrorMessageFromFormValidation(
      REGISTER_FORM_KEYS,
      this.formGroup,
      this.getFormErrorMessageTranslationKey
    );

    if (this.errorMessageTranslationKey) {
      return;
    }

    this.registerCallIsInProgress = true;
    this.errorMessageTranslationKey = null;
    const payload = {
      ...this.formGroup.value,
      language: this.formGroup.value.language || this.languageService.getPreferredLanguage(),
    };

    this.registerUserForCorrectContext$(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user: User): void => {
          this.analyticsService.trackEvent('User', 'register_successful');

          if (!this.autoLogin) {
            this.afterSuccessfulRegisterFunction(user);

            return;
          }

          this.login({ username: payload.username, password: payload.password });
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.registerCallIsInProgress = false;
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
        },
      });
  };

  login = (credentials: { username: string; password: string; }): void => {
    this.authenticationService.login$(credentials)
      .subscribe({
        next: () => {
          this.router.navigate([NAVIGATION.success.route]);
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.registerCallIsInProgress = false;
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
        },
      });
  };

  private getFormErrorMessageTranslationKey = (errorIdentifier: string, formKey: string): string => {
    if (errorIdentifier === 'required') {
      if (formKey === REGISTER_FORM_KEYS.get('accepted_privacy_policy')) {
        return 'pages.register.privacyStatementIsNotReadError';
      }

      return 'pages.register.missingFieldsError';
    }

    if (errorIdentifier === 'emailOrPhoneRequired') {
      return 'pages.register.emailOrPhoneRequired';
    }

    if (errorIdentifier === 'email') {
      return 'pages.register.invalidEmailError';
    }

    if (errorIdentifier === 'minlength') {
      if (formKey === REGISTER_FORM_KEYS.get('password')) {
        return 'pages.register.passwordTooShortError';
      }
    }

    if (errorIdentifier === 'pattern') {
      if (formKey === REGISTER_FORM_KEYS.get('socialSecurityNumber')) {
        return 'pages.register.invalidSocialSecurityNumberError';
      }

      if (formKey === REGISTER_FORM_KEYS.get('phone')) {
        return 'pages.register.invalidPhoneNumberError';
      }
    }

    return getUnknownErrorMessage(errorIdentifier, formKey);
  };

  private getHttpErrorMessageTranslationKey = (error: HttpErrorResponse | unknown): string => {
    if (!(error instanceof HttpErrorResponse)) {
      return 'common.unknownError';
    }

    if (error.error.data?.social_security_number) {
      return 'pages.register.socialSecurityNumberAlreadyTakenError';
    }

    if (error.error.data?.phone) {
      return 'pages.register.invalidPhoneNumberError';
    }

    if (error.status === 409 || error.error.data?.email) {
      return 'pages.register.emailAlreadyTakenError';
    }

    return 'common.unknownError';
  };

  @debounce(500)
  onSocialSecurityNumberChanged(socialSecurityNumber: string): void {
    if (this.registerSelf || !socialSecurityNumber) {
      return;
    }

    // If the whole form is disabled and the social security number is changed
    // We assume the user wants to register another user, and thus we reset the form to its initial state
    if (this.existingPatientId && this.socialSecurityNumberMatchingExistingPatient !== socialSecurityNumber) {
      this.formGroup.enable();
      this.existingPatientId = null;
      this.socialSecurityNumberMatchingExistingPatient = null;
      this.formGroup.reset();
      this.existingPatientEvent.emit(false);

      return;
    }

    this.userService.findUser$({ socialSecurityNumber: socialSecurityNumber })
      .pipe(untilDestroyed(this))
      .subscribe((userId: string | null): void => {
        this.existingPatientId = userId;

        if (this.existingPatientId) {
          this.existingPatientEvent.emit(true);
          this.socialSecurityNumberMatchingExistingPatient = socialSecurityNumber;
          // Disable the form, but enable the social security number input
          this.formGroup.disable();
          this.formGroup.get(REGISTER_FORM_KEYS.get('socialSecurityNumber')).enable();
        }
      });
  }

  protected continueWithExistingPatient(): void {
    this.registerCallIsInProgress = true;
    this.institutionService.attachUserToCurrentInstitutionAndRetrieveUser$(this.existingPatientId)
      .pipe(untilDestroyed(this))
      .subscribe((user: User): void => {
        this.registerCallIsInProgress = false;
        this.afterSuccessfulRegisterFunction(user);
      });
  }

  /**
   * Determines if a user registers itself as a new user or if an admin registers someone else as a new user
   */
  private registerUserForCorrectContext$(payload: IObject): Observable<User> {
    return this.registerSelf
      ? this.authenticationService.register$(payload, false)
      : this.userService.createUser$(payload);
  }
}
