import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@services/authentication.service';
import { ConversationChat } from '@models/conversation-chat.model';
import { Conversation, ConversationJson } from '@models/conversation.model';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { IObject } from '@app-types/iobject.type';
import { ConversationFilter } from '@modules/conversation/pages/conversations-overview-page/conversation-filter.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { ConversationEventType } from '@enums/conversation-event-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private readonly infoRole: string = 'info';
  private readonly attachmentText: string = '*attachment*';

  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) { }

  getConversationDetails$(uuid: string): Observable<Conversation> {
    const endpoint: string = ENDPOINTS.getConversationDetails.route
      .replace(/{.*}/, uuid);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(Conversation.fromJson));
  }

  deleteConversation$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.deleteConversation.route
      .replace(/{.*}/, uuid);

    return this.http
      .delete(environment.apiBaseUrl + endpoint)
      .pipe(switchToEmptyObservable());
  }

  undoDeleteConversation$(uuid: string): Observable<Conversation> {
    const endpoint: string = ENDPOINTS.undoDeleteConversation.route
      .replace(/{.*}/, uuid);

    return this.http
      .patch(environment.apiBaseUrl + endpoint, {})
      .pipe(map(Conversation.fromJson));
  }

  newConversationEvent$(uuid: string, type: ConversationEventType): Observable<void> {
    const endpoint: string = ENDPOINTS.newConversationEvent.route
      .replace(/{.*}/, uuid);

    const typeKey = Object.keys(ConversationEventType).find(
      key => ConversationEventType[key] === type
    );

    return this.http
      .post(environment.apiBaseUrl + endpoint, {
        type: typeKey,
      })
      .pipe(switchToEmptyObservable());
  }

  getAllConversations(filter: ConversationFilter): Observable<PaginatedModel<Conversation>> {
    const institutionId = filter.institution?.id || this.authenticationService.institution?.id;

    const endpoint: string = ENDPOINTS.getAllConversations.route
      .replace(/{.*}/, institutionId);

    const options: IObject = {
      params: filter?.toQueryStringParams(),
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: PaginatedModel<ConversationJson>): PaginatedModel<Conversation> => ({
        ...json,
        data: json.data.map(Conversation.fromJson),
      })));
  }

  createConversation$(data: IObject): Observable<Conversation> {
    const institutionId = this.authenticationService.institution.id;

    const endpoint: string = ENDPOINTS.createConversation.route
      .replace(/{.*}/, institutionId);

    return this.http
      .post(environment.apiBaseUrl + endpoint, data)
      .pipe(map(Conversation.fromJson));
  }

  getChatInfo$(uuid: string): Observable<ConversationChat> {
    const endpoint: string = ENDPOINTS.getChatInfo.route
      .replace(/{.*}/, uuid);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(ConversationChat.fromJson));
  }

  getRawTranscript$(uuid: string): Observable<any> {
    const endpoint: string = ENDPOINTS.getTranscript.route
      .replace(/{.*}/, uuid);

    return this.http
      .get(environment.apiBaseUrl + endpoint);
  }

  getHumanReadableTranscript$(uuid: string): Observable<any> {
    return this.getRawTranscript$(uuid).pipe(
      map((transcript: IObject[]) => {
        return transcript
          .filter((message: any) => message.role !== this.infoRole)
          .filter((message: any, index: number) => message.content !== '' && (index !== 0 || message.content !== this.attachmentText));
      })
    );
  }

  postTranscript$(uuid: string, messages: any): Observable<any> {
    const endpoint: string = ENDPOINTS.updateTranscript.route
      .replace(/{.*}/, uuid);

    return this.http
      .post(environment.apiBaseUrl + endpoint, messages);
  }

  completeConversation$(uuid: string): Observable<any> {
    const endpoint: string = ENDPOINTS.completeConversation.route
      .replace(/{.*}/, uuid);

    return this.http
      .patch(environment.apiBaseUrl + endpoint, {});
  }

  activateWhatsApp$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.activateConversationWhatsApp.route
      .replace(/{.*}/, uuid);

    return this.http
      .put(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }

  deactivateWhatsApp$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.deactivateConversationWhatsApp.route
      .replace(/{.*}/, uuid);

    return this.http
      .put(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }

  toggleWhatsApp$(uuid: string, isActive: boolean): Observable<void> {
    if (isActive) {
      return this.deactivateWhatsApp$(uuid);
    }

    return this.activateWhatsApp$(uuid);
  }

  regenerateSummary$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.regenerateSummary.route
      .replace(/{.*}/, uuid);

    return this.http
      .post(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }

  resendEmail$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.resendEmail.route
      .replace(/{.*}/, uuid);

    return this.http
      .post(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }
}
