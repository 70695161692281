<h2 *ngIf="title">{{ title }}</h2>
<div class="flex-row" [vhMarginTop]="title ? 4 : 0">
  <div 
    class="content" 
    [ngClass]="{ 'dashed' : showDashLine }" 
    [ngStyle]="{ 
      margin: verticalMargin + ' 0',
      borderColor: color 
    }">
  </div>
</div>
