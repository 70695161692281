import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { endOfDay, startOfDay } from 'date-fns';
import { tap } from 'rxjs/operators';
import { DaysWithRoomPlanning } from '@models/days-with-room-planning.model';
import { BaseOpeningsService } from '@modules/admin/admin-dashboard/services/openings/base-openings.service';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';

@Injectable({
  providedIn: 'root',
})
export class MonthlyOpeningsService extends BaseOpeningsService {
  loadOpenings$(filter: VisitFilter): Observable<CalendarOpening[]> {
    if (filter.selectedRooms.size === 0) {
      return of([]);
    }

    if (this.shouldHitCache(filter)) {
      return of(this.filterOpeningsByRoom(filter.selectedRooms));
    }

    return this.planningService.getDaysHavingPlannedSlots$(filter.from, filter.from, filter.allRooms)
      .pipe(map((planning: DaysWithRoomPlanning[]): CalendarOpening[] => this.mapDailyRoomPlanningToOpenings(planning)))
      .pipe(
        tap((openings: CalendarOpening[]): void => {
          this.openings = openings;
        })
      );
  }

  private mapDailyRoomPlanningToOpenings(planning: DaysWithRoomPlanning[]): CalendarOpening[] {
    return planning.flatMap((item: DaysWithRoomPlanning) =>
      item.days.map((day: Date): CalendarOpening => ({
        id: item.room.id,
        start: startOfDay(day),
        end: endOfDay(day),
        color: item.room.color,
      })));
  }
}
