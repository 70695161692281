<div class="flex-row-l-c gap-h-8">
  <button
    class="flex-col-c-c checkbox"
    [ngClass]="{
      clickable: !isDisabled,
      checked: value,
      'disabled-checked': value && isDisabled && allowDisabledStyle,
      'disabled-unchecked': !value && isDisabled && allowDisabledStyle,
      error: hasError,
      'not-clickable': isDisabled
    }"
    [ngStyle]="{
      'background-color': value ? fillColor : fillColorUnchecked,
      'border-color': borderColor
    }"
    [disabled]="isDisabled"
    (click)="handleInputClick()">
    <vh-icon *ngIf="value" src="assets/icons/check.svg" [fill]="COLORS.white"></vh-icon>
  </button>
  <label
    *ngIf="label"
    [ngClass]="{ 'not-clickable is-disabled': isDisabled, clickable: !isDisabled }"
    [innerHTML]="label"
    (click)="handleInputClick()"></label>
  <ng-content></ng-content>
</div>
<p *ngIf="errorMessage" [vhMarginTop]="4" class="font-light-14-17 has-text-error">{{ errorMessage }}</p>
