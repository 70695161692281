// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
// import { OnboardingCredentialsPageComponent } from '@modules/onboarding/pages/onboarding-credentials-page/onboarding-credentials-page.component';
// import { FormModule } from '@modules/shared/form/form.module';
// import { CoreModule } from '@modules/shared/core/core.module';
// import { OnboardingWalletPageComponent } from '@modules/onboarding/pages/onboarding-wallet-page/onboarding-wallet-page.component';
// import { OnboardingInstitutionPageComponent } from '@modules/onboarding/pages/onboarding-institution-page/onboarding-institution-page.component';
// import { OnboardingFinishingUpPageComponent } from '@modules/onboarding/pages/onboarding-finishing-up-page/onboarding-finishing-up-page.component';
// import { OnboardingSuccessPageComponent } from '@modules/onboarding/pages/onboarding-success-page/onboarding-success-page.component';
// import { ConversationSharedModule } from '@modules/conversation/conversation-shared.module';
// import { unauthenticatedGuard } from '@guards/unauthenticated.guard';
// import { authenticationGuard } from '@guards/authentication.guard';
// import { OnboardingContainerPageComponent } from '@modules/onboarding/components/onboarding-container-page/onboarding-container-page.component';
// import { AccountSharedModule } from '@modules/account/account-shared.module';
// import { OnboardingPaymentRedirectPageComponent } from '@modules/onboarding/pages/onboarding-payment-redirect-page/onboarding-payment-redirect-page.component';
// import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';

// const routes: Routes = [
//   {
//     path: ONBOARDING_NAV.root.path,
//     component: OnboardingContainerPageComponent,
//     children: [
//       {
//         path: '',
//         component: OnboardingCredentialsPageComponent,
//         pathMatch: 'full',
//         canActivate: [unauthenticatedGuard()],
//       },
//       {
//         path: ONBOARDING_NAV.credentials.path,
//         component: OnboardingCredentialsPageComponent,
//         canActivate: [unauthenticatedGuard()],
//       },
//       {
//         path: ONBOARDING_NAV.createInstitution.path,
//         component: OnboardingInstitutionPageComponent,
//         canActivate: [authenticationGuard()],
//       },
//       {
//         path: ONBOARDING_NAV.addWallet.path,
//         component: OnboardingWalletPageComponent,
//         canActivate: [authenticationGuard()],
//       },
//       {
//         path: ONBOARDING_NAV.finishingUp.path,
//         component: OnboardingFinishingUpPageComponent,
//         canActivate: [authenticationGuard()],
//       },
//       {
//         path: ONBOARDING_NAV.success.path,
//         component: OnboardingSuccessPageComponent,
//         canActivate: [authenticationGuard()],
//       },
//       {
//         path: PAYMENT_NAV.paymentRedirect.path,
//         component: OnboardingPaymentRedirectPageComponent,
//       },
//     ],
//   },
// ];

// @NgModule({
//   declarations: [
//     OnboardingCredentialsPageComponent,
//     OnboardingInstitutionPageComponent,
//     OnboardingWalletPageComponent,
//     OnboardingFinishingUpPageComponent,
//     OnboardingContainerPageComponent,
//     OnboardingSuccessPageComponent,
//     OnboardingPaymentRedirectPageComponent,
//   ],
//   imports: [
//     RouterModule.forChild(routes),
//     FormModule,
//     CoreModule,
//     ConversationSharedModule,
//     AccountSharedModule,
//   ],
// })

// export class OnboardingModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { OnboardingCredentialsPageComponent } from '@modules/onboarding/pages/new-onboarding-credentials-page/onboarding-credentials-page.component';
import { FormModule } from '@modules/shared/form/form.module';
import { CoreModule } from '@modules/shared/core/core.module';
import { OnboardingWalletPageComponent } from '@modules/onboarding/pages/onboarding-wallet-page/onboarding-wallet-page.component';
import { OnboardingInstitutionPageComponent } from '@modules/onboarding/pages/new-onboarding-institution-page/onboarding-institution-page.component';
import { OnboardingFinishingUpPageComponent } from '@modules/onboarding/pages/onboarding-finishing-up-page/onboarding-finishing-up-page.component';
import { OnboardingSuccessPageComponent } from '@modules/onboarding/pages/onboarding-success-page/onboarding-success-page.component';
import { ConversationSharedModule } from '@modules/conversation/conversation-shared.module';
import { unauthenticatedGuard } from '@guards/unauthenticated.guard';
import { authenticationGuard } from '@guards/authentication.guard';
import { OnboardingContainerPageComponent } from '@modules/onboarding/components/onboarding-container-page/onboarding-container-page.component';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { OnboardingPaymentRedirectPageComponent } from '@modules/onboarding/pages/onboarding-payment-redirect-page/onboarding-payment-redirect-page.component';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';
import { OnboardingPrivacyPageComponent } from '@modules/onboarding/pages/new-onboarding-privacy-page/onboarding-privacy-page.component';
import { OnboardingAccountPageComponent } from '@modules/onboarding/pages/new-onboarding-account-page/onboarding-account-page.component';

const routes: Routes = [
  {
    path: ONBOARDING_NAV.root.path,
    component: OnboardingContainerPageComponent,
    children: [
      {
        path: '',
        component: OnboardingCredentialsPageComponent,
        pathMatch: 'full',
        canActivate: [unauthenticatedGuard()],
      },
      {
        path: ONBOARDING_NAV.credentials.path,
        component: OnboardingCredentialsPageComponent,
        canActivate: [unauthenticatedGuard()],
      },
      {
        path: ONBOARDING_NAV.createInstitution.path,
        component: OnboardingInstitutionPageComponent,
        canActivate: [unauthenticatedGuard()],
      },
      {
        path: ONBOARDING_NAV.privacy.path,
        component: OnboardingPrivacyPageComponent,
        canActivate: [unauthenticatedGuard()],
      },
      {
        path: ONBOARDING_NAV.createAccount.path,
        component: OnboardingAccountPageComponent,
        canActivate: [unauthenticatedGuard()],
      },
      {
        path: ONBOARDING_NAV.addWallet.path,
        component: OnboardingWalletPageComponent,
        canActivate: [authenticationGuard()],
      },
      {
        path: ONBOARDING_NAV.finishingUp.path,
        component: OnboardingFinishingUpPageComponent,
        canActivate: [authenticationGuard()],
      },
      {
        path: ONBOARDING_NAV.success.path,
        component: OnboardingSuccessPageComponent,
        canActivate: [authenticationGuard()],
      },
      {
        path: PAYMENT_NAV.paymentRedirect.path,
        component: OnboardingPaymentRedirectPageComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    OnboardingCredentialsPageComponent,
    OnboardingInstitutionPageComponent,
    OnboardingWalletPageComponent,
    OnboardingFinishingUpPageComponent,
    OnboardingContainerPageComponent,
    OnboardingSuccessPageComponent,
    OnboardingPaymentRedirectPageComponent,
    OnboardingPrivacyPageComponent,
    OnboardingAccountPageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    FormModule,
    CoreModule,
    ConversationSharedModule,
    AccountSharedModule,
  ],
})
export class OnboardingModule { }
