import { Injectable } from '@angular/core';
import { IObject } from '@app-types/iobject.type';
import { Observable } from 'rxjs';
import { VisitReschedulingImpact } from '@models/visit-rescheduling-impact.model';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { VisitService } from '@services/visits/visit.service';
import { Visit } from '@models/visit.model';
import { getInstitutionManageRoles } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { Service } from '@models/service.model';

@Injectable({
  providedIn: 'root',
})
export class VisitReschedulingService {
  constructor(
    private readonly http: HttpClient,
    private readonly visitService: VisitService,
    private readonly authenticationService: AuthenticationService
  ) {}

  getVisitSchedulingImpact$ = (start: Date, end: Date, serviceUuid: Service, institutionUuid: string): Observable<VisitReschedulingImpact> => {
    const endpoint: string = ENDPOINTS.getVisitSchedulingImpact.route;

    const options: IObject = {
      params: {
        [ENDPOINTS.getVisitSchedulingImpact.queryParams.start]: start.toISOString(),
        [ENDPOINTS.getVisitSchedulingImpact.queryParams.end]: end.toISOString(),
        [ENDPOINTS.getVisitSchedulingImpact.queryParams.service_uuid]: serviceUuid,
        [ENDPOINTS.getVisitSchedulingImpact.queryParams.institution_uuid]: institutionUuid,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map(VisitReschedulingImpact.fromJson));
  };

  getVisitReschedulingImpact$ = (visitId: string, newFrom: Date, newTo: Date): Observable<VisitReschedulingImpact> => {
    const endpoint: string = ENDPOINTS.getVisitReschedulingImpact.route
      .replace(/{.*}/, visitId);

    const options: IObject = {
      params: {
        [ENDPOINTS.getVisitReschedulingImpact.queryParams.start]: newFrom.toISOString(),
        [ENDPOINTS.getVisitReschedulingImpact.queryParams.end]: newTo.toISOString(),
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map(VisitReschedulingImpact.fromJson));
  };

  rescheduleVisit$ = (visitId: string, newFrom: Date, newTo: Date, cancelOverlappingVisits: boolean = false): Observable<Visit> => {
    return this.visitService.updateVisit$(visitId, {
      start: newFrom,
      end: newTo,
      cancel_overlapping_visits: cancelOverlappingVisits,
    });
  };

  canReschedule(visitReschedulingImpact: VisitReschedulingImpact): boolean {
    return this.getReasonWhyReschedulingIsNotPossible(visitReschedulingImpact) === null;
  }

  getReasonWhyReschedulingIsNotPossible(visitReschedulingImpact: VisitReschedulingImpact): string | null {
    const institutionAllowsDoubleBooking: boolean = this.authenticationService.institution.settings.allowDoubleBookingAdmin;
    const visitAlreadyBookedAtSameTime: boolean = visitReschedulingImpact.visitAlreadyBookedAtSameTime;
    const userIsAdmin: boolean = this.authenticationService.currentUser.hasAnyRole(getInstitutionManageRoles());

    if (visitAlreadyBookedAtSameTime && !institutionAllowsDoubleBooking && userIsAdmin) {
      return 'admin.pages.dashboard.rescheduleConfirmation.doubleBookingDisabledByInstitutionWarning';
    }

    return null;
  }
}
