import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { REGEX } from '@constants/regex.constant';
import { environment } from '@environments/environment';

export function passwordValidator(minLength: number = environment.app.minPasswordLength): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    if (value.length < minLength) {
      return { minlength: { requiredLength: minLength, actualLength: value.length } };
    }

    if (!new RegExp(REGEX.password).test(value)) {
      return { pattern: { requiredPattern: REGEX.password } };
    }

    return null;
  };
}
