<vh-dialog-frame
  [title]="'wallet.automaticPaymentsDialog.title' | translate"
  (closeClicked)="dialog.close(false)">

  <mat-dialog-content>
    <p>{{ 'wallet.automaticPaymentsDialog.intro' | translate }}</p>

    <form [formGroup]="formGroup">
      <label class="block" [vhMarginTop]="16">{{ 'wallet.automaticPaymentsDialog.topUpAtLabel' | translate }}</label>
      <vh-text-input type="number" min="1" [formControlName]="WALLET_FORM_KEYS.get('min_tokens_threshold')"></vh-text-input>

      <div [vhMarginTop]="16" class="flex-col gap-v-8">
        <p>{{ 'wallet.automaticPaymentsDialog.selectBucketTitle' | translate }}</p>
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loadingBucketOptionsTemplate"></ng-container>
        <ng-container *ngIf="!isLoading">
          <div *ngFor="let bucket of buckets" class="flex-row-l-c gap-h-8" >
            <input [id]="bucket.id" type="radio" [value]="bucket.id" [formControlName]="WALLET_FORM_KEYS.get('bucket_id')" />
            <label [for]="bucket.id" class="clickable">
              {{ bucket.totalTokens }}
              {{ 'wallet.automaticPaymentsDialog.tokensLabel' | translate }}
              <span> - </span>
              {{ bucket.price | currency: 'EUR' }}
            </label>
          </div>
        </ng-container>
      </div>
    </form>

    <p [vhMarginTop]="16">{{'wallet.automaticPaymentsDialog.emailDisclaimer' | translate}}</p>
  </mat-dialog-content>

  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button [label]="'wallet.automaticPaymentsDialog.actionConfirm' | translate" [isDisabled]="formGroup.invalid" (buttonClick)="onConfirmClicked()"></vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>

<ng-template #loadingBucketOptionsTemplate>
  <div *ngFor="let i of [].constructor(5)" vhSkeleton skeletonHeight="24px" skeletonWidth="66%" [skeletonIsVisible]="true" [skeletonIsEmpty]="false"></div>
</ng-template>
