<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()" [isDisabled]="!formGroup?.dirty"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()" [isDisabled]="!formGroup?.dirty"></vh-button>
    </div>

    <div class="settings-list" [formGroup]="formGroup">
        <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.superAdminSettingsLabel' | translate"></vh-horizontal-divider>

        <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.eventBusEnabledLabel' | translate }}</label>
        <vh-switch [vhMarginTop]="8" [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('event_bus_enabled')"> </vh-switch>
    </div>
  </div>
</vh-content-layout>
