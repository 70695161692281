import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isPast, isValid, parse } from 'date-fns';

export function isPastDate(): ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const date = parse(control.value, 'yyyy-MM-dd', new Date());

    return isValid(date) && isPast(date)
      ? null
      : { isInPast: true };
  };
}
