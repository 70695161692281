<vh-dialog-frame [title]="'admin.pages.dashboard.cancelVisitDialog.message' | translate" (closeClicked)="close('cancel')">
  <div *ngIf="visit.visitType === 'MEDICAL'">
    <vh-cancel-visit-form [errorMessage]="cancelFailedErrorMessage" [formGroup]="formGroup"> </vh-cancel-visit-form>
  </div>
  
  <mat-dialog-actions [vhMarginTop]="32" class="flex-row-c-c gap-h-8">
    <vh-button
      [label]="'common.ok' | translate"
      [isDisabled]="!!this.cancelVisitSubscription"
      [iconLeftPath]="!!this.cancelVisitSubscription ? 'assets/icons/hourglass-white.svg' : ''"
      (buttonClick)="onConfirmClicked()">
    </vh-button>
    <vh-button [label]="'common.cancel' | translate" [style]="ButtonStyle.TERTIARY" (buttonClick)="close('cancel')"> </vh-button>
  </mat-dialog-actions>
</vh-dialog-frame>
