import { Component, Input, OnInit } from '@angular/core';
import { QrCodeService } from '@services/qr-code.service';

@Component({
  selector: 'vh-continue-mobile-banner',
  templateUrl: './continue-mobile-banner.component.html',
  styleUrls: ['./continue-mobile-banner.component.scss'],
})
export class ContinueMobileBannerComponent implements OnInit {
  @Input() url: string;
  @Input() generateFromCurrentUrl: boolean;

  qrCode: string;

  constructor(private qrCodeService: QrCodeService) {}

  ngOnInit(): void {
    this.loadQrCode();
  }

  loadQrCode(): void {
    if (!this.url && !this.generateFromCurrentUrl) {
      return;
    }

    this.qrCodeService.generate$(this.url ?? window.location.toString()).subscribe((data: string) => {
      this.qrCode = data;
    });
  }
}
