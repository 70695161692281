<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="centered-container align-center gap-v-32">
      <p>{{ 'payment.bucketsOverview.intro' | translate }}</p>
      <div class="buckets-grid align-center">
        <vh-bucket-card
          *ngFor="let bucket of buckets"
          [bucket]="bucket"
          [selected]="bucket.id === selectedBucket?.id"
          (itemSelected)="onBucketSelected(bucket)">
        </vh-bucket-card>
      </div>

      <!-- TODO: to be implemented to buy a custom amount, hidden for now -->
<!--      <vh-text-input-->
<!--        class="align-start"-->
<!--        type="number"-->
<!--        min="1"-->
<!--        (inputValueChange)="customAmount = $event"-->
<!--        [label]="'payment.bucketsOverview.customAmountLabel' | translate"-->
<!--        [placeholder]="'payment.bucketsOverview.customAmountPlaceholder' | translate">-->
<!--      </vh-text-input>-->

      <div class="payment-methods-input-container flex-col">
        <vh-select-input
          class="align-start"
          [options]="paymentMethodOptions"
          [label]="'common.fields.creditCard' | translate"
          [defaultValue]="defaultPaymentMethod?.id"
          (inputValueChange)="onPaymentMethodSelected($event)">
        </vh-select-input>
        <vh-message
          class="clickable has-text-primary font-regular-12-14 align-end"
          [text]="'payment.bucketsOverview.actionAddCreditCard' | translate"
          [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
          [iconRightStroke]="COLORS.primary"
          [vhMarginTop]="8"
          (messageClick)="onManagePaymentMethodsClicked()">
        </vh-message>
      </div>

      <vh-button
        class="align-center"
        iconRightPath="assets/icons/arrow-narrow-right.svg"
        [isDisabled]="!canMakePurchase"
        [vhMarginTop]="32"
        [iconRightStroke]="COLORS.white"
        [label]="'common.purchase' | translate"
        (buttonClick)="onBuyClicked()">
      </vh-button>
    </div>
  </div>

</vh-content-layout>
