export const NAVIGATION = {
  root: {
    path: '',
    route: '/',
  },
  institutionsOverview: {
    path: 'institutions',
    route: '/institutions',
  },
  login: {
    path: 'login',
    route: '/login',
  },
  register: {
    path: 'register',
    route: '/register',
  },
  success: {
    path: 'register/success',
    route: '/register/success',
  },
  expiredLink: {
    path: 'expired-link',
    route: '/expired-link',
  },
  profile: {
    path: 'profile',
    route: '/profile',
  },
  updatePassword: {
    path: 'update-password',
    route: '/update-password',
  },
  resetPassword: {
    path: 'reset-password',
    route: '/reset-password',
  },
  forgotPassword: {
    path: 'forgot-password',
    route: '/forgot-password',
  },
  forgotUsername: {
    path: 'forgot-username',
    route: '/forgot-username',
  },
  privacyStatement: {
    path: 'privacy-statement',
    route: '/privacy-statement',
  },
  playground: {
    path: 'playground',
    route: '/playground',
  },
  contact: {
    path: 'contact',
    route: '/contact',
  },
  admin: {
    path: 'admin',
    route: '/admin',
  },
  dashboard: {
    path: 'dashboard',
    route: '/dashboard',
  },
  adminDashboardConversations: {
    path: 'dashboard/conversations',
    route: '/admin/dashboard/conversations',
  },
  adminDashboardAppointments: {
    path: 'dashboard/visits',
    route: '/admin/dashboard/visits',
  },
  adminDashboardRoomsCreate: {
    path: 'dashboard/settings/rooms/create',
    route: '/admin/dashboard/settings/rooms/create',
  },
  adminDashboardRoomsSettings: {
    path: 'dashboard/settings/rooms/:roomId/settings',
    route: '/admin/dashboard/settings/rooms/:roomId/settings',
    params: {
      roomId: 'roomId',
    },
  },
  adminDashboardInsights: {
    path: 'dashboard/insights',
    route: '/admin/dashboard/insights',
  },
  adminDashboardWorkLists: {
    path: 'dashboard/work-lists',
    route: '/admin/dashboard/work-lists',
    rescheduling: {
      path: 'rescheduling',
      route: '/admin/dashboard/work-lists/rescheduling',
    },
    surveys: {
      path: 'surveys',
      route: '/admin/dashboard/work-lists/surveys',
    },
  },
  adminDashboardSettings: {
    path: 'dashboard/settings',
    route: '/admin/dashboard/settings',
    conversationsGeneral: {
      path: 'conversations/general',
      route: '/admin/dashboard/settings/conversations/general',
    },
    conversationsCommunication: {
      path: 'conversations/communication',
      route: '/admin/dashboard/settings/conversations/communication',
    },
    conversationsPrivacy: {
      path: 'conversations/privacy',
      route: '/admin/dashboard/settings/conversations/privacy',
    },
    users: {
      path: 'users',
      route: '/admin/dashboard/settings/users',
    },
    createUser: {
      path: 'users/create',
      route: '/admin/dashboard/settings/users/create',
    },
    updateUser: {
      path: 'users/:userId',
      route: '/admin/dashboard/settings/users/:userId',
      params: {
        userId: 'userId',
      },
    },
    rooms: {
      path: 'rooms',
      route: '/admin/dashboard/settings/rooms',
    },
    surveys: {
      path: 'surveys',
      route: '/admin/dashboard/settings/surveys',
    },
    createSurvey: {
      path: 'surveys/create',
      route: '/admin/dashboard/settings/surveys/create',
      params: {
        surveyId: 'surveyId',
      },
    },
    updateSurvey: {
      path: 'surveys/:surveyId',
      route: '/admin/dashboard/settings/surveys/:id',
      params: {
        surveyId: 'id',
      },
    },
    careProgram: {
      path: 'care-program',
      route: '/admin/dashboard/settings/care-program',
    },
    createCareProgram: {
      path: 'care-program/create',
      route: '/admin/dashboard/settings/care-program/create',
    },
    updateCareProgram: {
      path: 'care-program/:careProgramId',
      route: '/admin/dashboard/settings/care-program/:id',
      params: {
        careProgramId: 'id',
      },
    },
    slots: {
      path: 'rooms/:roomId/slots',
      route: '/admin/dashboard/settings/rooms/:roomId/slots',
      params: {
        roomId: 'roomId',
      },
    },
    services: {
      path: 'services',
      route: '/admin/dashboard/settings/services',
    },
    serviceGroups: {
      path: 'service-groups',
      route: '/admin/dashboard/settings/service-groups',
    },
    communication: {
      path: 'communication',
      route: '/admin/dashboard/settings/communication',
    },
    reservationSettings: {
      path: 'reservation-settings',
      route: '/admin/dashboard/settings/reservation-settings',
    },
    generalSettings: {
      path: 'general',
      route: '/admin/dashboard/settings/general',
    },
    securitySettings: {
      path: 'security',
      route: '/admin/dashboard/settings/security',
    },
    superAdminSettings: {
      path: 'super-admin',
      route: '/admin/dashboard/settings/super-admin',
    },
  },
  survey: {
    path: ':visitId/survey/:taskId',
    route: '/:visitId/survey/:taskId',
    params: {
      visitId: 'visitId',
      taskId: 'taskId',
    },
  },
  surveySubmitSuccess: {
    path: ':visitId/survey/:taskId/submit-success',
    route: '/:visitId/survey/:taskId/submit-success',
    params: {
      visitId: 'visitId',
      taskId: 'taskId',
    },
  },
  referringPhysician: {
    path: 'referring-physician',
    route: '/referring-physician',
  },
  referringPhysicianDashboardAppointments: {
    path: 'dashboard/visits',
    route: '/referring-physician/dashboard/visits',
  },
  wallet: {
    path: 'profile/wallet',
    route: '/admin/dashboard/settings/profile/wallet',
  },
  walletHistory: {
    path: 'profile/wallet-history',
    route: '/admin/dashboard/settings/profile/wallet-history',
  },
  paymentMethods: {
    path: 'profile/payment-methods',
    route: '/admin/dashboard/settings/profile/payment-methods',
  },
  verifyEmailSuccess: {
    path: 'verify-email-success',
    route: '/verify-email-success',
  },
};
