import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadedFile } from '@modules/shared/form/interfaces/uploaded-file.interface';

@Component({
  selector: 'vh-upload-input-form',
  templateUrl: './upload-input-form.component.html',
  styleUrls: ['./upload-input-form.component.scss'],
})
export class UploadInputFormComponent implements OnInit {
  @Input() maxFiles: number = Number.MAX_VALUE;
  @Input() allowedExtensions: string[] = [];
  @Input() errors: string[] = [];

  @Output() filePick: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() deleteClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() fileDescriptionChange: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();

  @ViewChild('fileInputRef') fileInputElement: ElementRef;

  @Input() files: UploadedFile[] = [];

  accept: string;
  isHovering: boolean;

  ngOnInit(): void {
    this.accept = this.allowedExtensions.join(',');
  }

  onFileDropped(files: FileList): void {
    this.isHovering = false;
    this.handleFileSelected(files);
  }

  onFileDragOver(): void {
    this.isHovering = true;
  }

  onFileDragLeave(): void {
    this.isHovering = false;
  }

  onBrowseFileSelected(event: Event): void {
    this.handleFileSelected((event.currentTarget as HTMLInputElement).files);
  }

  handleFileSelected(files: FileList): void {
    if (files.length === 0) {
      return;
    }

    this.errors = [];

    if (this.containsDisallowedFiles(files)) {
      const errorMessage: string = this.files.length === 1
        ? 'pages.createAppointment.step2.errors.disallowedFileType'
        : 'pages.createAppointment.step2.errors.disallowedFileTypeMultiple';
      this.errors.push(errorMessage);

      return;
    }

    for (let i = 0; i < files.length; i++) {
      this.files.push({
        file: files.item(i),
        filename: files.item(i).name,
      });
    }

    this.filePick.emit(files);
    this.fileInputElement.nativeElement.value = '';
  }

  onDeleteFileClicked(index: number): void {
    this.files.splice(index, 1);
    this.deleteClick.emit(index);
  }

  private containsDisallowedFiles(files: FileList): boolean {
    const extensions = this.allowedExtensions.map((extension: string) => extension.replace('.', ''));

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const extension = files[i].name.split('.').pop()?.trim();

      if (!extensions.includes(extension)) {
        return true;
      }
    }

    return false;
  }
}
