import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialogRef } from '@angular/material/dialog';
import * as sentry from '@sentry/browser';
import { WalletService } from '@services/wallet.service';
import { NAVIGATION } from '@constants/navigation.constant';
import { MolliePaymentStatus } from '@enums/mollie-payment-status.emun';
import { interval, startWith, switchMap, catchError, of, takeWhile } from 'rxjs';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';

@Component({
  selector: 'vh-add-credit-card-dialog',
  templateUrl: './add-credit-card-dialog.component.html',
  styleUrls: ['./add-credit-card-dialog.component.scss'],
})
@UntilDestroy()
export class AddCreditCardDialogComponent {
  mollieToken: string | null = null;
  pollingActive: boolean;
  pollingInterval: number = 5000;
  router: any;
  error: any;

  constructor(
    private walletService: WalletService,
    readonly dialog: MatDialogRef<AddCreditCardDialogComponent>) {
  }

  onConfirmClicked(): void {
    if (this.mollieToken === null) {
      this.dialog.close(true);

      return;
    }

    this.walletService.addPaymentMethodAndGetCheckoutUrl$(this.mollieToken, PAYMENT_NAV.paymentRedirect.route)
      .pipe(untilDestroyed(this))
      .subscribe(({ checkout_url, payment_id }) => {
        this.startPolling(payment_id);
        window.open(checkout_url, '_blank');
      });
  }

  onMollieTokenReady(token: string): void {
    this.mollieToken = token;
  }

  onValidationStateChanged(isValid: boolean): void {
    if (!isValid) {
      this.mollieToken = null;
    }
  }

  onMollieTokenError(error: unknown): void {
    sentry.captureException(error);
  }

  startPolling(paymentId: string): void {
    this.pollingActive = true;

    interval(this.pollingInterval) // Emit every 5 seconds
      .pipe(
        startWith(0),
        switchMap(() =>
          this.walletService.getPaymentStatus$(paymentId).pipe(
            catchError(err => {
              console.error('Error fetching payment status:', err);

              return of(null);
            })
          )
        ),
        takeWhile(status => this.pollingActive)
      )
      .subscribe({
        next: status => {
          if (status === MolliePaymentStatus.OPEN || status === MolliePaymentStatus.PENDING) {
            return;
          }

          this.pollingActive = false;

          if (status === MolliePaymentStatus.PAID) {
            this.dialog.close(true);
          }

          this.error = status;
        },
        error: err => {
          console.error('Error fetching payment status:', err);
          this.pollingActive = false;
          this.error = 'general';
        },
      });
  }
}
