<div class="content" *ngIf="currentUserTokens > 0">
  
  <vh-user-search
    (userSelected)="onPatientSelected($event)">
  </vh-user-search>

  <button class="clickable" (click)="addNewPatient()">
    <h2>{{ 'conversations.create.selectPatient.create' | translate }}</h2>
    <vh-icon src="assets/icons/plus.svg" [fill]="COLORS.secondary"></vh-icon>
  </button>
  
  <vh-button 
    [label]="'common.next' | translate" 
    [isDisabled]="!selectedUser"
    (buttonClick)="goToNextStep()"
    [centered]="true"
    [vhMarginTop]="32"
    [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
    [iconRightWidth]="20"
    [iconRightHeight]="20"
    [iconRightStroke]="COLORS.white">
  </vh-button>
</div>