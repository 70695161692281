<vh-centered-card-page>
  <vh-message
    *ngIf="!isSubmitted"
    textClass="has-text-primary font-light-14-17"
    [text]="'pages.forgotUsername.loginButtonLabel' | translate"
    [iconLeftPath]="'assets/icons/chevron-left.svg'"
    [iconLeftStroke]="COLORS.primary"
    [routerLink]="NAVIGATION.login.route">
  </vh-message>
    <div class="flex-col-c-c" [vhMarginTop]="16">
      <div class="flex-col">
        <ng-container *ngIf="!isSubmitted" [ngTemplateOutlet]="forgotUsernameFormTemplate"></ng-container>
        <ng-container *ngIf="isSubmitted" [ngTemplateOutlet]="submitSuccessfulTemplate"></ng-container>
      </div>
    </div>
</vh-centered-card-page>

<ng-template #forgotUsernameFormTemplate [formGroup]="formGroup">
  <h1>{{ 'pages.forgotUsername.title' | translate }}</h1>
  <p [vhMarginTop]="16" [vhFontClass]="'font-regular-18-22'">{{ 'pages.forgotUsername.text' | translate }}</p>
  <vh-text-input
    type="email"
    [vhMarginTop]="16"
    [formControlName]="FORGOT_USERNAME_FORM_KEYS.get('email')"
    [label]="'common.fields.email' | translate"
    [placeholder]="'common.fields.email' | translate">
  </vh-text-input>
  <vh-text-input
    type="date"
    [vhMarginTop]="16"
    [formControlName]="FORGOT_USERNAME_FORM_KEYS.get('birthdate')"
    [label]="'common.fields.birthdate' | translate"
    [min]="DATE_BOUNDARIES.min.dateString"
    [max]="currentDateString">
  </vh-text-input>
  <vh-message
    *ngIf="submitErrorMessageTranslationKey"
    [vhMarginTop]="16"
    [vhFontClass]="'font-regular-18-22'"
    [vhTextColor]="'var(--color-alert)'"
    [text]="submitErrorMessageTranslationKey | translate">
  </vh-message>
  <vh-button
    class="align-center"
    [vhMarginTop]="32"
    [label]="'common.send' | translate"
    [iconRightPath]="'assets/icons/arrow-right-white.svg'"
    [isDisabled]="submitButtonIsDisabled"
    (buttonClick)="submitUsername()">
  </vh-button>
</ng-template>

<ng-template #submitSuccessfulTemplate>
  <vh-post-action-status
    [isStroke]="true"
    [title]="('pages.forgotUsername.submitted.title' | translate)"
    [message]="'pages.forgotUsername.submitted.text' | translate"
    [actionText]="'pages.forgotUsername.submitted.loginButtonLabel' | translate"
    [routerLink]="NAVIGATION.login.route">
  </vh-post-action-status>
</ng-template>
