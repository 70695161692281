import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { IObject } from '@app-types/iobject.type';
import { environment } from '@environments/environment';
import { VirtualSlot } from '@models/virtual-slot.model';
import { AuthenticationService } from '@services/authentication.service';
import { Visit, VisitJson } from '@models/visit.model';
import { User } from '@models/user.model';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { fetchAllPaginatedData } from '@utils/helpers/http.util';
import { GenericDailyCount, GenericDailyCountJson } from '@models/generic-daily-count.model';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class VisitService {
  private _visits: Visit[];

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) { }

  get visits(): Visit[] {
    return this._visits;
  }

  set visits(value: Visit[]) {
    this._visits = value;
  }

  getVisits$ = (startDate: string | null, endDate: string | null, sorting: 'ASC' | 'DESC' = 'DESC'): Observable<Visit[]> => {
    const options: IObject = {
      params: {
        ...startDate
          ? {
            [ENDPOINTS.getVisits.queryParams.startDate]: startDate,
          }
          : {},
        ...endDate
          ? {
            [ENDPOINTS.getVisits.queryParams.endDate]: endDate,
          }
          : {},
        [ENDPOINTS.getVisits.queryParams.sorting]: sorting,
      },
    };

    return this.http
      .get(environment.apiBaseUrl + ENDPOINTS.getVisits.route, options)
      .pipe(map((json: PaginatedModel<VisitJson>): Visit[] => json.data.map(Visit.fromJson)));
  };

  bookVisit$ = (appointment: Visit, slot: VirtualSlot, user: User = null): Observable<Visit> => {
    const endpoint: string = ENDPOINTS.bookVisit.route
      .replace(
        `{${ENDPOINTS.bookVisit.pathParams.visitId}}`,
        appointment.id
      )
      .replace(
        `{${ENDPOINTS.bookVisit.pathParams.recurrenceId}}`,
        slot.recurrenceId
      );

    return this.http
      .post(environment.apiBaseUrl + endpoint, slot.toRequest(user))
      .pipe(map((json: VisitJson): Visit => Visit.fromJson(json)));
  };

  bookVisitForOtherUser$ = (slot: VirtualSlot, user: User): Observable<Visit> => {
    const endpoint: string = ENDPOINTS.bookVisitForOtherUser.route
      .replace(
        `{${ENDPOINTS.bookVisitForOtherUser.pathParams.userId}}`,
        user.id
      )
      .replace(
        `{${ENDPOINTS.bookVisitForOtherUser.pathParams.recurrenceId}}`,
        slot.recurrenceId
      );

    return this.http
      .post(environment.apiBaseUrl + endpoint, slot.toRequest(user))
      .pipe(map((json: VisitJson): Visit => Visit.fromJson(json)));
  };

  createDummyVisit$ = (): Observable<Visit> => {
    const endpoint: string = ENDPOINTS.createVisit.route;

    return this.http
      .post(environment.apiBaseUrl + endpoint, null)
      .pipe(map(Visit.fromJson));
  };

  updateVisit$ = (visitId: string, properties: IObject): Observable<Visit> => {
    const endpoint: string = ENDPOINTS.updateVisit.route
      .replace(/{.*}/, visitId);

    if (properties.start) {
      properties.start = getTimeStringWithTimeZone(properties.start);
    }

    if (properties.end) {
      properties.end = getTimeStringWithTimeZone(properties.end);
    }

    return this.http
      .post(environment.apiBaseUrl + endpoint, properties)
      .pipe(map(Visit.fromJson))
      .pipe(tap((updatedVisit: Visit) => {
        const index = this.visits?.findIndex((visit: Visit) => visit.id === visitId);

        if (!index) {
          return;
        }

        this.visits[index] = updatedVisit;
      }));
  };

  getVisitById$ = (visitId: string): Observable<Visit> => {
    const endpoint: string = ENDPOINTS.getVisitById.route
      .replace(/{.*}/, visitId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(Visit.fromJson));
  };

  getDailyVisitCountsForCurrentInstitution$ = (from: Date, to: Date): Observable<GenericDailyCount[]> => {
    const endpoint: string = ENDPOINTS.getDailyVisitsCount.route
      .replace(/{.*}/, this.authenticationService.institution.id);

    const options: IObject = {
      params: {
        [ENDPOINTS.getDailyVisitsCount.queryParams.startDate]: format(from, DATE_FORMATS.serverDate),
        [ENDPOINTS.getDailyVisitsCount.queryParams.endDate]: format(to, DATE_FORMATS.serverDate),
      },
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: GenericDailyCountJson[]): GenericDailyCount[] =>
        json.map((item: GenericDailyCountJson) => GenericDailyCount.fromJson(item)))
      );
  };

  getVisitsForCurrentInstitutionWithFilter$ = (filter?: VisitFilter): Observable<PaginatedModel<Visit>> => {
    const endpoint: string = ENDPOINTS.getVisitsForInstitution.route
      .replace(/{.*}/, this.authenticationService.institution.id);

    const options: IObject = {
      params: filter?.toQueryStringParams(),
    };

    return this.http
      .get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: PaginatedModel<VisitJson>): PaginatedModel<Visit> => ({
        ...json,
        data: json.data.map(Visit.fromJson),
      })));
  };

  getVisitsForCurrentInstitutionWithFilterRecursively$ = (filter?: VisitFilter): Observable<Visit[]> => {
    const endpoint: string = ENDPOINTS.getVisitsForInstitution.route
      .replace(/{.*}/, this.authenticationService.institution.id);

    const options: IObject = {
      params: filter?.toQueryStringParams(),
    };

    return fetchAllPaginatedData<VisitJson>(
      this.http,
      environment.apiBaseUrl + endpoint,
      options
    )
      .pipe(map((json: VisitJson[]): Visit[] => json.map(Visit.fromJson)));
  };

  resendVisitConfirmationEmail$(uuid: string): Observable<void> {
    const endpoint: string = ENDPOINTS.resendVisitConfirmationEmail.route
      .replace(/{.*}/, uuid);

    return this.http
      .post(environment.apiBaseUrl + endpoint, {})
      .pipe(switchToEmptyObservable());
  }
}
