import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { COLORS } from '@constants/colors.constant';
import { BreakpointObserver } from '@angular/cdk/layout';
import { InstitutionService } from '@services/institution.service';
import { InstitutionInsight } from '@models/institution-insight.model';
import { Chart, ChartType, ChartTypeRegistry, registerables } from 'chart.js';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-insights-page',
  templateUrl: './insights-page.component.html',
  styleUrls: ['./insights-page.component.scss'],
})
export class InsightsPageComponent extends BreakpointObserverComponent implements OnInit, AfterViewChecked {
  protected readonly COLORS: typeof COLORS = COLORS;
  navigationItems: NavigationItem[];

  insights: InstitutionInsight;
  chartRef: Chart<keyof ChartTypeRegistry, Record<string, number>[], string>;
  errorMessageKey: string;

  @ViewChild('modalitiesPieChart') modalitiesPieChart: ElementRef<HTMLCanvasElement>;

  constructor(breakpointObserver: BreakpointObserver, private readonly institutionService: InstitutionService) {
    super(breakpointObserver);
    this.navigationItems = getNavigationItems();
  }

  ngOnInit(): void {
    Chart.register(...registerables);
    this.loadInsights();
  }

  ngAfterViewChecked(): void {
    this.createModalitiesPieChart();
  }

  private loadInsights(): void {
    this.errorMessageKey = null;

    this.institutionService.getInsightsForInstitution$()
      .subscribe({
        next: (insights: InstitutionInsight) => {
          this.insights = insights;
          this.createModalitiesPieChart();
        },
        error: () => {
          this.errorMessageKey = 'admin.pages.insights.error';
        },
      });
  }

  private createModalitiesPieChart(): void {
    if (!this.modalitiesPieChart || this.chartRef) {
      return;
    }

    const config = {
      type: 'pie' as ChartType,
      legend: true,
      data: {
        labels: Object.keys(this.insights.visitsTotalGroupedByModality),
        datasets: [
          {
            data: Object.values(this.insights.visitsTotalGroupedByModality),
            hoverOffset: 4,
          },
        ],
      },
    };

    this.chartRef = new Chart(this.modalitiesPieChart.nativeElement, config);
  }
}
