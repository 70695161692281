import { Component, Input } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { Conversation } from '@models/conversation.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConversationService } from '@services/conversation.service';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { ToastService } from '@services/ui/toast.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.component';
import { FeedbackService } from '@services/feedback.service';
import { Feedback } from '@modules/shared/core/dialogs/feedback-dialog/feedback-dialog.interface';

@Component({
  selector: 'vh-conversation-summary',
  templateUrl: './conversation-summary.component.html',
  styleUrls: ['./conversation-summary.component.scss'],
})
@UntilDestroy()
export class ConversationSummaryComponent {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() conversation: Conversation;

  protected menuItems: MenuItem[];

  constructor(
    protected readonly conversationService: ConversationService,
    private readonly clipboard: Clipboard,
    private readonly toastService: ToastService,
    private readonly alertDialogService: AlertDialogService,
    private readonly translateService: TranslateService,
    private readonly feedbackService: FeedbackService,
    private readonly dialogService: MatDialog
  ) {
    this.menuItems = [
      {
        label: this.translateService.instant('conversations.details.reloadCause'),
        command: (): void => {
          this.regenerateSummary();
        },
      },
      {
        label: this.translateService.instant('conversations.details.rateSummary'),
        command: (): void => {
          this.rateSummary();
        },
      },
    ];
  }

  regenerateSummary(): void {
    this.conversationService.regenerateSummary$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe();

    this.alertDialogService.open({
      messageTranslationKey: 'conversations.details.reloadSummaryText',
      confirmTextTranslationKey: 'common.ok',
      showCancelButton: false,
    }).subscribe();
  }

  copySummary(): void {
    this.clipboard.copy(this.conversation.summary);

    this.toastService.showDefault('common.copied', 'common.ok', { duration: 2000 })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  rateSummary(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'conversations.details.feedbackDialog.title',
      showStarRating: true,
      showTextarea: true,
      textareaRequired: true,
      textareaPlaceholder: 'conversations.details.feedbackDialog.textAreaPlaceholder',
      submitButtonText: 'conversations.details.feedbackDialog.submitButtonText',
    };

    this.dialogService.open(FeedbackDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((feedback: Feedback) => {
        if (feedback) {
          this.onSubmitFeedbackClicked({
            ...feedback,
            conversation_uuid: this.conversation.id,
          });
        }
      });
  }

  onSubmitFeedbackClicked(feedback: Feedback): void {
    this.feedbackService.rateSummary(feedback)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastService.showInfo('navigationBar.contactDialog.success', 'common.ok', { duration: 5000 })
            .pipe(untilDestroyed(this))
            .subscribe();
        },
        error: () => {
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  getLatestReloadTooltip(): Observable<string> {
    if (this.conversation.summaryUpdatedAt === null) {
      return this.translateService.get('conversations.details.reloadSummary');
    }

    const formattedDate = format(this.conversation.summaryUpdatedAt, DATE_FORMATS.clientDateTimeNoYear);

    return this.translateService.get('conversations.details.reloadSummaryWithLatest', { latest: formattedDate });
  }

  // used to disable the sort function in the keyvalue pipe for the cause
  returnZero(): number {
    return 0;
  }
}
