export const PAYMENT_NAV = {
  root: {
    path: 'top-up',
    route: '/top-up',
  },
  bucketsOverview: {
    path: 'buckets',
    route: '/top-up/buckets',
  },
  paymentSuccess: {
    path: 'payment/success',
    route: '/top-up/payment/success',
  },
  paymentFailed: {
    path: 'payment/failed',
    route: '/top-up/payment/failed',
  },
  paymentCancelled: {
    path: 'payment/cancelled',
    route: '/top-up/payment/cancelled',
  },
  redirectWhenPaymentFinished: {
    path: 'payment/redirect/finished',
    route: '/top-up/payment/redirect/finished',
  },
  redirectWhenAutomaticPaymentSetupCompleted: {
    path: 'payment/redirect/automatic-payment-setup',
    route: '/top-up/payment/redirect/automatic-payment-setup',
  },
  paymentRedirect: {
    path: 'payment/redirect',
    route: '/payment/redirect',
  },
};
