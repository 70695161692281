import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-centered-card-page',
  templateUrl: './centered-card-page.component.html',
  styleUrls: ['./centered-card-page.component.scss'],
})
export class CenteredCardPageComponent extends BreakpointObserverComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() showBackButton: boolean = false;

  @Output() backButtonClick: EventEmitter<void> = new EventEmitter<void>();
}
