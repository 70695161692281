import { style, animate, transition, trigger } from '@angular/animations';

export const slideInTransition = transition(
  ':enter', [
  style({ height: 0, overflow: 'hidden', opacity: 0 }),
  animate('0.3s ease-in-out', style({ height: '*', opacity: 1 })),
]
);

export const slideOutTransition = transition(
  ':leave', [
  style({ height: '*', overflow: 'hidden', opacity: 1 }),
  animate('0.3s ease-in-out', style({ height: 0, opacity: 0 })),
]
);

export const slideInOutAnimation = trigger(
  'slideInOutAnimation', [slideInTransition, slideOutTransition]
);

export const slideUpAnimation = transition(':enter', [
  style({
    transform: 'translateY(100%)'
  }),
  animate('0.3s ease-in-out', style({
    transform: 'translateY(0)'
  }))
]);

// Slide Down Animation for Leave
export const slideDownAnimation = transition(':leave', [
  style({
    transform: 'translateY(0)'
  }),
  animate('0.3s ease-in-out', style({
    transform: 'translateY(100%)'
  }))
]);

// Combined Slide Up and Down Animation
export const slideUpDownAnimation = trigger('animation', [
  slideUpAnimation, // This applies the :enter transition
  slideDownAnimation // This applies the :leave transition
]);