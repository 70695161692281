import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { IObject } from '@app-types/iobject.type';
import { COLORS } from '@constants/colors.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { NavigationProfileComponent } from '@modules/account/components/navigation-profile/navigation-profile.component';

@Component({
  selector: 'vh-primary-page',
  templateUrl: './primary-page.component.html',
  styleUrls: ['./primary-page.component.scss'],
})
export class PrimaryPageComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  navigationItemsAreVisible: boolean;
  expanded: boolean;

  @Input() navigationItems: NavigationItem[];
  @Input() headerContentTemplate: TemplateRef<IObject>;
  @Input() hasHorizontalContentSpacing: boolean = true;
  @Input() hasVerticalContentSpacing: boolean = true;
  @Input() profileTemplate: TemplateRef<NavigationProfileComponent>;

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly localStorageService: LocalStorageCacheService
  ) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    this.expanded = this.localStorageService.get(LOCAL_STORAGE_KEYS.navigationMenuExpanded) || false;
    this.navigationItemsAreVisible = this.navigationItems && this.navigationItems.length > 0;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    this.localStorageService.set(LOCAL_STORAGE_KEYS.navigationMenuExpanded, this.expanded);
  }
}
