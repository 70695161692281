import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { OrderService } from '@services/order.service';
import { Order } from '@models/order.model';
import { OrderStatus } from '@enums/order-status.enum';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';

export const orderResolver: ResolveFn<void> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {
    const orderService: OrderService = inject(OrderService);
    const router: Router = inject(Router);
    const orderId = route.queryParams.order_id;

    orderService.getOrderById$(orderId)
      .subscribe({
        next: (order: Order) => {
          const navOptions = { queryParams: { order_id: orderId }, state: { order } };

          switch (order.status) {
            case OrderStatus.CANCELLED:
              void router.navigate([PAYMENT_NAV.paymentCancelled.route], navOptions);

              return;
            case OrderStatus.OPEN:
            case OrderStatus.PAID:
              void router.navigate([PAYMENT_NAV.paymentSuccess.route], navOptions);

              return;
            case OrderStatus.EXPIRED:
            case OrderStatus.FAILED:
            default:
              void router.navigate([PAYMENT_NAV.paymentFailed.route], navOptions);

              return;
          }
        },
        error: (error: any) => {
          void router.navigate([PAYMENT_NAV.paymentFailed.route], { queryParams: { order_id: orderId } });
        },
      });
  };
