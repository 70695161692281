<label *ngIf="label" class="font-regular-18-22" [innerHtml]="label"></label>
<div class="input-container flex-row-l-c gap-h-8" [vhMarginTop]="label ? 4 : 0">
  <vh-text-input
    #inputElement
    [isDisabled]="!hasPerformedInitialSuggestion"
    [formControl]="formControl"
    [placeholder]="(placeholder ? placeholder : 'common.fields.username') | translate"
    [style]="style"
    [iconRightPath]="showValidationIcon ? icon : ''"
    [iconRightFill]="iconColor"
    [iconRightClass]="isLoading ? 'anim-rotating' : ''"
    [value]="value"
    (keyup)="checkIfUnique()">
  </vh-text-input>
</div>
<p *ngIf="isValid === false" [vhMarginTop]="4" class="has-text-error font-light-12-14">{{ errorMessage | translate }}</p>
