import { style, animate, trigger, transition } from '@angular/animations';

export const fadeInTransition = transition(
  ':enter',
  [
    style({ opacity: 0 }),
    animate(
      '0.5s ease-out',
      style({ opacity: 1 })
    ),
  ]
);

export const fadeInAnimation = trigger(
  'fadeInAnimation',
  [fadeInTransition]
);
