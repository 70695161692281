<vh-dialog-frame
  [title]="'conversations.details.logbook' | translate"
  (closeClicked)="dialog.close(false)">

  <mat-dialog-content>

    <p *ngIf="conversation.events.length === 0">
      {{ 'conversations.details.noEvents' | translate }}
    </p>

    <ng-container *ngIf="conversation.events.length > 0">
      <div
        *ngFor="let event of conversation.events; let i = index"
        [attr.key]="'e-' + i"
        class="flex-row-c-s gap-h-16">

        <span>{{'conversations.eventTypes.' + event.type | translate}}</span>
        <span>{{event.createdAt | date: DATE_FORMATS.clientDateTimeNoYear}}</span>
      </div>
    </ng-container>
  </mat-dialog-content>
</vh-dialog-frame>