import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vh-horizontal-divider',
  templateUrl: './horizontal-divider.component.html',
  styleUrls: ['./horizontal-divider.component.scss'],
})
export class HorizontalDividerComponent implements OnInit {
  @Input() title: string | null = null;
  @Input() verticalMargin: number | string;
  @Input() showDashLine: boolean = false;
  @Input() color: string = 'var(--color-background-grey-dark)';

  ngOnInit(): void {
    this.verticalMargin = this.verticalMargin ? `var(--${this.verticalMargin}px)` : null;
  }
}
