<ul class="flex-row nav-steps" [ngStyle]="{ 'max-width': maxWidth }">
  <li
    class="font-light-12-14 clickable"
    *ngFor="let i of [].constructor(state.totalSteps); let index = index"
    [ngClass]="{
              active: index + 1 === state.currentStep,
              completed: index + 1 < state.currentStep && !state.skippedSteps.has(index + 1),
              skipped: index + 1 < state.currentStep && state.skippedSteps.has(index + 1),
              hidden: state.hiddenSteps.has(index + 1),
              optional: state.optionalSteps.has(index + 1)
            }"
    (click)="stepClicked.emit(index + 1)">

    <vh-icon
      *ngIf="index > 0"
      [width]="16"
      [height]="16"
      [src]="'/assets/icons/arrow-right-narrow.svg'" 
    />

    <div>
      <span class="font-regular-16-19">{{ state.getVisibleStepNumber(index) }}.</span>
      <span class="font-light-12-16">{{ state.labels[index] | translate }}</span>
    </div>
  </li>
</ul>