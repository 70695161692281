import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { CANCEL_VISIT_FORM_KEYS } from '@constants/form-keys/cancel-visit-form-keys.constant';

@Injectable({
  providedIn: 'root',
})
export class CancelVisitFormService extends AbstractFormService {
  createFormGroup = (): UntypedFormGroup => {
    return this.formBuilder.group({
      [CANCEL_VISIT_FORM_KEYS.get('cancellationMessage')]: [
        null,
        [Validators.required],
      ],
      [CANCEL_VISIT_FORM_KEYS.get('cancelReasonId')]: [
        null,
        null,
      ],
      [CANCEL_VISIT_FORM_KEYS.get('cancelActionId')]: [
        null,
        null,
      ],
      [CANCEL_VISIT_FORM_KEYS.get('visitIds')]: [
        null,
        [Validators.required],
      ],
    });
  };

}
