import { Component, HostBinding, Input } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Conversation } from '@models/conversation.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { ConversationService } from '@services/conversation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { ToastService } from '@services/ui/toast.service';
import { AlertDialogService } from '@services/ui/alert-dialog.service';

@Component({
  selector: 'vh-conversation-action-menu',
  templateUrl: './conversation-action-menu.component.html',
  styleUrls: ['./conversation-action-menu.component.scss'],
  animations: [fadeInOutAnimation],
})
@UntilDestroy()
export class ConversationActionMenuComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly ConversationStatus: typeof ConversationStatus = ConversationStatus;

  @HostBinding('@fadeInOutAnimation') animationState: boolean = true;

  @Input() conversation: Conversation;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly conversationService: ConversationService,
    private readonly toastService: ToastService,
    private readonly alertDialogService: AlertDialogService
  ) { }

  copyEmr(): void {
    if (this.conversation.summary) {
      this.clipboard.copy(this.conversation.summary);
      this.toastService.showInfo('common.copiedToClipboard', 'common.ok', { duration: 5000 }).pipe(untilDestroyed(this)).subscribe();
    }
  }

  clickDeleteConversation(): void {
    if (this.conversation.status === ConversationStatus.REMOVED) {
      this.toastService.showError('conversations.delete.alreadyDeleted', 'common.ok', { duration: 5000 }).pipe(untilDestroyed(this)).subscribe();

      return;
    }
    this.showDeleteConfirmationDialog();
  }

  private onDeleteConfirmed(): void {
    this.conversationService.deleteConversation$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.conversation.status = ConversationStatus.REMOVED;
        },
      });
  }

  private showDeleteConfirmationDialog(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'conversations.delete.title',
      messageTranslationKey: 'conversations.delete.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onDeleteConfirmed();
        }
      });
  }

  showUndoDeleteConfirmationDialog(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'conversations.undoDelete.title',
      messageTranslationKey: 'conversations.undoDelete.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onUndoSoftDeleteConfirmed();
        }
      });
  }

  private onUndoSoftDeleteConfirmed(): void {
    this.conversationService.undoDeleteConversation$(this.conversation.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: Conversation) => {
          this.conversation.status = response.status;
        },
      });
  }
}
