<vh-secondary-page [headerTemplate]="institutionScopeHeader" [title]="'admin.pages.dashboard.createAppointmentButtonLabel' | translate" (navigateBackClick)="exitBookingFlow()">
  <vh-content-layout [maxWidth]="600" [hasVerticalSpacing]="true">
    <h1>{{ 'pages.createAppointment.confirmation.title' | translate }}</h1>
    <p [vhMarginTop]="4">{{ 'pages.createAppointment.confirmation.introduction' | translate }}</p>

    <vh-dynamic-visit-card [vhMarginTop]="16" [visits]="bookingState.booking.visits"></vh-dynamic-visit-card>
    <vh-user-card [user]="bookingState.booking.patient"></vh-user-card>

    <ng-container *vhRequiresMode="Mode.PRIVATE" [ngTemplateOutlet]="patientSpecificContentTemplate"></ng-container>
    <ng-container *vhRequiresMode="Mode.ADMIN" [ngTemplateOutlet]="adminSpecificContentTemplate"></ng-container>

    <vh-button
      class="align-end"
      [vhMarginTop]="16"
      [label]="'pages.createAppointment.confirmation.confirmButtonLabel' | translate"
      [iconRightPath]="'assets/icons/arrow-right-white.svg'"
      (buttonClick)="confirmBookingAndExit()">
    </vh-button>
  </vh-content-layout>
</vh-secondary-page>

<ng-template #patientSpecificContentTemplate>
  <ng-container *ngIf="!bookingState.booking.patient.hasEmail" [formGroup]="emailFormGroup">
    <p [vhMarginTop]="16">
      {{ 'pages.createAppointment.confirmation.noEmail.text' | translate }}
    </p>
    <vh-text-input
      type="email"
      [formControlName]="PROFILE_FORM_KEYS.get('email')"
      [label]="'common.fields.email'">
    </vh-text-input>
  </ng-container>
</ng-template>

<ng-template #adminSpecificContentTemplate>
  <p *ngIf="!bookingState.booking.patient.hasEmail" [vhMarginTop]="16">
    {{ 'pages.createAppointment.confirmation.noEmail.adminText' | translate : { patient: bookingState.booking.patient.fullName } }}
  </p>

  <form [formGroup]="commentsFormGroup">
    <vh-textarea-input
      [formControlName]="VISIT_FORM_KEYS.get('internalComment')"
      [vhMarginTop]="16"
      [label]="'pages.createAppointment.confirmation.internalComment.label' | translate"
      [message]="'pages.createAppointment.confirmation.internalComment.disclaimer' | translate"
      [messageVerticalAlignment]="'end'">
    </vh-textarea-input>

    <vh-textarea-input
      *ngIf="bookingState.booking.patient.hasEmail"
      [formControlName]="VISIT_FORM_KEYS.get('externalComment')"
      [vhMarginTop]="16"
      [label]="'pages.createAppointment.confirmation.emailComment.label' | translate"
      [message]="'pages.createAppointment.confirmation.emailComment.disclaimer' | translate"
      [messageVerticalAlignment]="'end'">
    </vh-textarea-input>
  </form>
</ng-template>

<ng-template #institutionScopeHeader>
  <vh-institution-header *ngIf="bookingState.scopedInstitution" [institution]="bookingState.scopedInstitution"></vh-institution-header>
</ng-template>
