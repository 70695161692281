import { Component, Input } from '@angular/core';

@Component({
  selector: 'vh-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() width: string;
  @Input() isFlex: boolean = true;
}
