import { style, animate, trigger, transition } from '@angular/animations';

export const popInTransition = transition(
  ':enter',
  [
    style({ scale: '0%' }),
    animate(
      '0.1s ease-out',
      style({ scale: '100%' })
    ),
  ]
);

export const popInAnimation = trigger(
  'popInAnimation',
  [popInTransition]
);
