<div class="flex-row container">
  <vh-navigation-return-link *ngIf="returnLinkIsVisible" [color]="'var(--color-secondary)'"></vh-navigation-return-link>
  <img *ngIf="doctorIsVisible" src="assets/images/doctor.svg" [alt]="'common.doctorThumbsUpImage' | translate" />
  <div class="flex-row gap-h-16 background">
    <div class="void" [ngStyle]="{ flex: doctorIsVisible ? 3 : 4 }"></div>
    <div [ngStyle]="{ flex: doctorIsVisible ? 7 : 6 }"></div>
  </div>
  <div class="flex-row gap-h-16 content">
    <div class="void" [ngStyle]="{ flex: doctorIsVisible ? 3 : 4 }"></div>
    <div [ngStyle]="{ flex: doctorIsVisible ? 7 : 6 }">
      <vh-content-layout
        [hasHorizontalSpacing]="true"
        [hasVerticalSpacing]="true"
        [maxWidth]="breakpointObserverResult.breakpoints[BREAKPOINTS.maxWidthMedium] ? null : contentMaxWidth"
        [isVerticallyCenterAligned]="true"
        [isHorizontallyCenterAligned]="true">
        <ng-content></ng-content>
      </vh-content-layout>
    </div>
  </div>
</div>
