/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const SERVICE_FORM_KEYS: Map<string, string> = new Map([
  ['code', 'code'],
  ['duration', 'duration'],
  ['can_be_booked_by_patient', 'can_be_booked_by_patient'],
  ['is_public', 'is_public'],
  ['alternative_name', 'alternative_name'],
  ['service', 'service'],
  ['previous_service_id', 'previous_service_id'],
]);
