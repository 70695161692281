import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  private readonly openableExtensions: Map<string, string>;

  constructor() {
    this.openableExtensions = new Map<string, string>([
      ['.png', 'image/png'],
      ['.jpg', 'image/jpg'],
      ['.jpeg', 'image/jpg'],
      ['.pdf', 'application/pdf'],
    ]);
  }

  open(buffer: ArrayBuffer, filename: string): void {
    const extension = '.' + filename.split('.').pop()?.trim();

    if (!extension) {
      this.download(buffer, filename);

      return;
    }

    if (!this.openableExtensions.has(extension)) {
      this.download(buffer, filename);

      return;
    }

    const array = new Uint8Array(buffer);
    const file = new Blob([array], { type: this.openableExtensions.get(extension) });
    const fileURL = URL.createObjectURL(file);

    window.open(fileURL);
  }

  download(buffer: ArrayBuffer, filename: string): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([buffer]));
    link.download = filename;
    link.click();
    link.remove();
  }
}
