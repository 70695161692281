<vh-settings-card-overview-settings-page
  [filterOptions]="surveyStatusOptions"
  [searchPlaceholder]="'admin.pages.settings.surveys.searchByName' | translate"
  [filterPlaceholder]="'admin.pages.settings.surveys.filterByStatus' | translate"
  [addLabel]="'admin.pages.settings.surveys.addSurvey' | translate"
  [addRoute]="addRoute"
  [editRoute]="editRoute"
  [detailRoute]="editRoute"
  [cardType]="cardType">
</vh-settings-card-overview-settings-page>
