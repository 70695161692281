import { ButtonStyle } from '@enums/button-style.enum';
import { COLORS } from '@constants/colors.constant';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardService } from '@modules/shared/cards/services/card.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';

export interface DeleteCardDialogData {
  card: SettingsCard;
}

@Component({
  selector: 'vh-delete-card-dialog',
  templateUrl: './delete-card-dialog.component.html',
  styleUrls: ['./delete-card-dialog.component.scss'],
})
@UntilDestroy()
export class DeleteCardDialogComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  impactedItems: Option[];
  card: SettingsCard;
  cardType: string;
  impactType: string;

  constructor(private readonly cardService: CardService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    readonly dialog: MatDialogRef<DeleteCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DeleteCardDialogData) {
    this.card = data.card;
  }

  ngOnInit(): void {
    this.loadImpactedItems();
    this.cardType = this.translate.instant('admin.pages.cardOverview.deleteCardDialog.' + this.cardService.getCardTypeTranslationKey(this.card));
    this.impactType = this.translate.instant('admin.pages.cardOverview.deleteCardDialog.' + this.cardService.getImpactTypeTranslationKey(this.card));
  }

  onConfirmClicked(): void {
    this.dialog.close(true);
  }

  onImpactedItemClicked(impactedItem: Option): void {
    const itemRoute = this.cardService.getImpactedItemRoute(this.card);
    void this.router.navigate([itemRoute], { queryParams: { id: impactedItem.value } });
    this.dialog.close(false);
  }

  loadImpactedItems(): void {
    this.cardService.getImpactedItems$(this.card).pipe(untilDestroyed(this)).subscribe((impactedItems: Option[]) => {
      this.impactedItems = impactedItems;
    });
  }
}
