import { Injectable } from '@angular/core';
import { Occurrence } from '@models/occurrence.model';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ADD_RECURRENCE_FORM_KEYS } from '@constants/form-keys/add-recurrence-form-keys.constant';
import { addMinutes, differenceInMinutes, format, isSameDay } from 'date-fns';
import { RecurrenceFrequency } from '@enums/recurrence-frequency.enum';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { calculateEndDate, getDateTimeFromTime, getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { Recurrence } from '@models/recurrence.model';
import { IObject } from '@app-types/iobject.type';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { isRequired } from '@utils/validators/is-required.validator';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { timeNotAfter, timeNotBefore } from '@utils/validators/time.validator';

@Injectable({
  providedIn: 'root',
})
export class RecurrenceFormService extends AbstractFormService {
  createFormGroupFromOccurrence(occurrence: Occurrence, recurrence: Recurrence | null = null, fallbackServiceGroupId: string | null = null): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [ADD_RECURRENCE_FORM_KEYS.get('duration')]: [occurrence?.duration ?? 30, [Validators.required]],
      [ADD_RECURRENCE_FORM_KEYS.get('doctorRizivNumber')]: [recurrence?.doctor?.riziv ?? null],
      [ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')]: [
        recurrence.start ? format(occurrence.start, 'HH:mm') : null,
        [Validators.required, Validators.pattern('^(2[0-3]|[01][0-9]):([0-5][0-9])$'), timeNotAfter('occurrenceEnd')],
      ],
      [ADD_RECURRENCE_FORM_KEYS.get('occurrenceEnd')]: [
        recurrence.duration ? format(addMinutes(recurrence.start, recurrence.duration), 'HH:mm') : null,
        [Validators.required, Validators.pattern('^(2[0-3]|[01][0-9]):([0-5][0-9])$'), timeNotBefore('occurrenceStart')],
      ],
      [ADD_RECURRENCE_FORM_KEYS.get('serviceGroupId')]: [
        occurrence.serviceGroup?.id ? occurrence.serviceGroup.id : fallbackServiceGroupId,
        [Validators.required],
      ],
    });

    this.addRecurrenceFieldsToFormGroup(formGroup, recurrence);

    // This field is read-only
    formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('duration')).disable();

    return formGroup;
  }

  addRecurrenceFieldsToFormGroup(formGroup: UntypedFormGroup, recurrence: Recurrence | null): void {
    let recurrenceOption: string = 'never';
    let amount: number = null;
    let endsOnDate: string = null;

    if (!isSameDay(recurrence?.end, DATE_BOUNDARIES.endless.date)) {
      recurrenceOption = 'ends_on_date';
      endsOnDate = format(recurrence.end, DATE_FORMATS.serverDate);
    } else if (recurrence?.amount > 0) {
      recurrenceOption = 'after_x_occurrences';
      amount = recurrence.amount;
    }

    formGroup.addControl(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption'), new FormControl(recurrenceOption));
    formGroup.addControl(ADD_RECURRENCE_FORM_KEYS.get('frequency'), new FormControl(recurrence.frequency ?? RecurrenceFrequency.WEEKLY));
    formGroup.addControl(ADD_RECURRENCE_FORM_KEYS.get('interval'), new FormControl(recurrence.interval ?? 1));
    formGroup.addControl(ADD_RECURRENCE_FORM_KEYS.get('amount'), new FormControl(amount));
    formGroup.addControl(ADD_RECURRENCE_FORM_KEYS.get('end'), new FormControl(endsOnDate));

    formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('frequency')).setValidators([isRequired(recurrence.isRecurring)]);
    formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).setValidators([isRequired(recurrence.isRecurring)]);
  }

  formGroupToRequest(formGroup: UntypedFormGroup, startDate: Date, endDate: Date, roomId: string): IObject {
    const duration: number = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('duration')).value;
    const interval: number = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).value;
    const frequency: RecurrenceFrequency = RecurrenceFrequency[formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('frequency')).value];
    const recurrenceOption: string = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')).value;

    let amount: number = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('amount')).value;

    const recurrenceStart: Date | null = getDateTimeFromTime(formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')).value, startDate);
    let recurrenceEnd: Date | null = null;

    if (frequency !== RecurrenceFrequency.NONE) {
      switch (recurrenceOption) {
        case 'never':
          amount = 0;
          recurrenceEnd = DATE_BOUNDARIES.endless.date;
          break;
        case 'ends_on_date': {
          // First calculate number of days between start and end date
          const endMillis: number = new Date(endDate).valueOf();
          const startMillis: number = new Date(startDate).valueOf();
          const differenceMillis: number = endMillis - startMillis;
          // Convert milliseconds to days and add 1 to include the first day and calculate amount
          amount = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24)) + 1;
          recurrenceEnd = new Date(formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('end')).value);
          break;
        }
        case 'after_x_occurrences':
          recurrenceEnd = calculateEndDate(recurrenceStart, amount, interval, frequency);
          break;
      }
    } else {
      recurrenceEnd = addMinutes(recurrenceStart, duration);
    }

    return {
      frequency: frequency,
      service_group_id: formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('serviceGroupId')).value,
      amount: frequency !== RecurrenceFrequency.NONE ? amount : 1,
      duration_in_min: duration,
      interval: frequency !== RecurrenceFrequency.NONE ? interval : 0,
      start: getTimeStringWithTimeZone(recurrenceStart),
      end: getTimeStringWithTimeZone(recurrenceEnd),
      room_id: roomId,
      doctor_riziv_number: formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('doctorRizivNumber')).value,
    };
  }

  formGroupToOccurrence(formGroup: UntypedFormGroup, occurrence: Occurrence, recurrence: Recurrence): Occurrence {
    const startTime = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')).value;
    const endTime = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceEnd')).value;

    return new Occurrence(
      occurrence.recurrenceId,
      getDateTimeFromTime(startTime, recurrence.start),
      getDateTimeFromTime(endTime, addMinutes(recurrence.start, recurrence.duration)),
      occurrence.serviceGroup,
      occurrence.modality,
      occurrence.doctor
    );
  }

  changeRecurrenceFlag(formGroup: UntypedFormGroup, recurrence: Recurrence): void {
    recurrence.frequency = recurrence.isRecurring ? RecurrenceFrequency.NONE : RecurrenceFrequency.WEEKLY;
    formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('frequency')).setValue(recurrence.frequency);

    // Sometimes this variable gets wiped, this re-applies its default value
    if (!formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).value) {
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).setValue(1);
    }

    this.toggleRecurrenceFields(formGroup, recurrence.isRecurring);
  }

  toggleRecurrenceFields(formGroup: UntypedFormGroup, isRecurring: boolean): void {
    if (isRecurring) {
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')).enable();
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).enable();
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('frequency')).enable();
    } else {
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('recurrenceOption')).disable();
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('interval')).disable();
      formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('frequency')).disable();
    }
  }

  recalculateDuration(formGroup: UntypedFormGroup, newStart: Date, newEnd: Date): void {
    const startHoursAndMinutes = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceStart')).value.split(':');
    newStart.setHours(startHoursAndMinutes[0], startHoursAndMinutes[1]);

    const endHoursAndMinutes = formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('occurrenceEnd')).value.split(':');
    newEnd.setHours(endHoursAndMinutes[0], endHoursAndMinutes[1]);

    const duration: number = differenceInMinutes(newEnd, newStart);
    formGroup.get(ADD_RECURRENCE_FORM_KEYS.get('duration')).setValue(duration);
  }
}
