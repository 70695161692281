export class GenericDailyCount {
  constructor(
    private readonly _count: number,
    private readonly _date: Date
  ) {
  }

  get count(): number {
    return this._count;
  }

  get date(): Date {
    return this._date;
  }

  static fromJson(json: GenericDailyCountJson): GenericDailyCount {
    return new GenericDailyCount(
      json.count,
      new Date(json.date)
    );
  }
}

export interface GenericDailyCountJson {
  count: number;
  date: string;
}
