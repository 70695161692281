export const BOOKING_NAV = {
  // Public
  createBooking: {
    path: 'booking/start',
    route: '/booking/start',
  },
  updateBooking: {
    path: 'booking/:pendingBookingId',
    route: '/booking/:pendingBookingId',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  // Public, but only for a specific institution, used as unique link by the institutions
  createBookingViaInstitutionUniqueLink: {
    path: 'institutions/:institutionSlug/book',
    route: '/institutions/:institutionSlug/book',
    params: {
      institutionSlug: 'institutionSlug',
    },
  },
  updateBookingViaInstitutionUniqueLink: {
    path: 'institutions/:institutionSlug/book/:pendingBookingId',
    route: '/institutions/:institutionSlug/book/:pendingBookingId',
    params: {
      institutionSlug: 'institutionSlug',
      pendingBookingId: 'pendingBookingId',
    },
  },
  // Private, for institutions only
  createBookingAsInstitution: {
    path: 'booking/start',
    route: '/admin/booking/start',
  },
  // Private, for institutions only
  updateBookingAsInstitution: {
    path: 'booking/start/:pendingBookingId',
    route: '/admin/booking/start/:pendingBookingId',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  // Private, for referring physicians only
  createBookingAsReferringPhysician: {
    path: 'booking/start',
    route: '/referring-physician/booking/start',
  },
  updateBookingAsReferringPhysician: {
    path: 'booking/start/:pendingBookingId',
    route: '/referring-physician/booking/start/:pendingBookingId',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  // Further steps in the booking flow
  bookingSelectPatient: {
    path: 'booking/:pendingBookingId/patient',
    route: '/booking/:pendingBookingId/patient',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  bookingConfirmation: {
    path: 'booking/:pendingBookingId/confirm',
    route: '/booking/:pendingBookingId/confirm',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  bookingSuccess: {
    path: 'booking/:pendingBookingId/success',
    route: '/booking/:pendingBookingId/success',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  bookingContactForHelp: {
    path: 'booking/:pendingBookingId/contact',
    route: '/booking/:pendingBookingId/contact',
    params: {
      pendingBookingId: 'pendingBookingId',
    },
  },
  // TODO: these are not really unique to booking, but are used in the booking flow
  visitViewTasks: {
    path: 'visits/view/:visitId/tasks',
    route: '/visits/view/:visitId/tasks',
    params: {
      visitId: 'visitId',
    },
  },
  visitManageDocumentTasks: {
    path: 'visits/view/:appointmentId/tasks/:taskId',
    route: '/visits/view/:appointmentId/tasks/:taskId',
    params: {
      appointmentId: 'appointmentId',
      taskId: 'taskId',
    },
  },
};
