export class ConversationQuestionList {
  constructor(
    private _id: string,
    private _title: string,
    private _chatbotRole: string | null,
    private _questions: string | null
  ) {
  }

  get id(): string {
    return this._id;
  }

  get title(): string {
    return this._title;
  }

  get chatbotRole(): string {
    return this._chatbotRole;
  }

  get questions(): string {
    return this._questions;
  }

  static fromJson(json: ConversationQuestionListJson): ConversationQuestionList {
    return new ConversationQuestionList(
      json.id,
      json.title,
      json.chatbot_role,
      json.questions
    );
  }
}

export interface ConversationQuestionListJson {
  id: string;
  title: string;
  chatbot_role: string | null;
  questions: string | null;
}
