import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { delay, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * This directive helps a component to check for when it is visible to the user.
 *
 * Adapted from the following tutorial: https://web.archive.org/web/20220928071458/https://giancarlobuomprisco.com/angular/intersection-observer-with-angular
 */
@Directive({
  selector: '[vhTrackVisibility]',
})
@UntilDestroy()
export class VisibilityTrackerDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() debounceTimeMs: number = 0;
  @Input() threshold: number = 1;

  @Output() visible: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

  protected observer: IntersectionObserver | undefined;
  protected subject$: Subject<{ entry: IntersectionObserverEntry; observer: IntersectionObserver; }> = new Subject<{ entry: IntersectionObserverEntry; observer: IntersectionObserver; }>();

  protected constructor(
    @Host() protected readonly element: ElementRef,
    protected readonly ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(this.createObserver);
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(this.startObservingElements);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.next(null);
    this.subject$.complete();
  }

  protected isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise((resolve: (value: boolean) => void) => {
      const observer = new IntersectionObserver(([entry]: [IntersectionObserverEntry]) => {
        resolve(entry.intersectionRatio === 1);
        observer.disconnect();
      });

      observer.observe(element);
    });
  }

  protected createObserver = (): void => {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry): boolean => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (isIntersecting(entry)) {
          this.subject$.next({ entry, observer });
        }
      });
    }, options);
  };

  /**
   * For performance reasons this function is best ran via ngZone outside of Angular
   */
  protected startObservingElements = (): void => {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.element.nativeElement);

    this.subject$
      .pipe(untilDestroyed(this))
      .pipe(delay(this.debounceTimeMs), filter(Boolean))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async({ entry, observer }: { entry: IntersectionObserverEntry; observer: IntersectionObserver; }): Promise<void> => {
        const target = entry.target as HTMLElement;
        const isStillVisible = await this.isVisible(target);

        if (isStillVisible) {
          this.onVisible(target);
          observer.unobserve(target);
        }
      });
  };

  protected onVisible(target: HTMLElement): void {
    this.visible.emit(target);
  }
}
