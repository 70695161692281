export const scrollToElementById = (id: string, delayInMs: number = 0): void => {
  setTimeout(() => {
    document.getElementById(id).scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }, delayInMs);
};

/**
 * Scroll smoothly to an element in the DOM by its ID
 */
export const smoothScrollToElementById = (id: string, delayInMs: number = 0): void => {
  setTimeout(() => {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, delayInMs);
};

export const smoothScrollToFirstElementByClass = (className: string, delayInMs: number = 0): void => {
  setTimeout(() => {
    document.getElementsByClassName(className)[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, delayInMs);
};
