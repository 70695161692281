import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule } from 'angular-calendar';
import { GetWeekPipeModule } from 'ngx-date-fns';
import { CalendarComponent } from '@modules/shared/calendar/components/calendar/calendar.component';
import { EventsCalendarComponent } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';
import { CreateEventPopupComponent } from '@modules/shared/calendar/components/create-event-popup/create-event-popup.component';
import { BasicEventsCalendarControlsComponent } from '@modules/shared/calendar/components/basic-events-calendar-controls/basic-events-calendar-controls.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormModule } from '@modules/shared/form/form.module';
import { AccountSharedModule } from '@modules/account/account-shared.module';
import { FormatPipeModule } from 'ngx-date-fns';
import { SettingsSharedModule } from '@modules/settings/settings-shared.module';

const sharedIngredients: any[] = [
  CalendarComponent,
  EventsCalendarComponent,
  CreateEventPopupComponent,
  BasicEventsCalendarControlsComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
    CalendarCommonModule,
    CalendarDayModule,
    AccountSharedModule,
    SettingsSharedModule,
    CalendarWeekModule,
    CalendarMonthModule,
    GetWeekPipeModule,
    MatTooltipModule,
    FormatPipeModule,
  ],
  exports: sharedIngredients,
})
export class CalendarModule {}
