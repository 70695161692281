// export const ONBOARDING_NAV = {
//   root: {
//     path: 'onboarding',
//     route: '/onboarding',
//   },
//   credentials: {
//     path: 'credentials',
//     route: '/onboarding/credentials',
//   },
//   createInstitution: {
//     path: 'institution',
//     route: '/onboarding/institution',
//   },
//   addWallet: {
//     path: 'wallet',
//     route: '/onboarding/wallet',
//   },
//   finishingUp: {
//     path: 'finishing-up',
//     route: '/onboarding/finishing-up',
//   },
//   success: {
//     path: 'success',
//     route: '/onboarding/success',
//   },
// };

export const ONBOARDING_NAV = {
  root: {
    path: 'onboarding',
    route: '/onboarding',
  },
  credentials: {
    path: 'credentials',
    route: '/onboarding/credentials',
  },
  createInstitution: {
    path: 'institution',
    route: '/onboarding/institution',
  },
  addWallet: {
    path: 'wallet',
    route: '/onboarding/wallet',
  },
  finishingUp: {
    path: 'finishing-up',
    route: '/onboarding/finishing-up',
  },
  success: {
    path: 'success',
    route: '/onboarding/success',
  },
  privacy: {
    path: 'privacy',
    route: '/onboarding/privacy',
  },
  createAccount: {
    path: 'account',
    route: '/onboarding/account',
  },
};
