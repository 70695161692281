import { AfterContentChecked, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[vhSkewMargin]',
})
export class SkewMarginDirective implements AfterContentChecked {
  private readonly tangent: number;
  private readonly elementStyle: CSSStyleDeclaration;

  @Input() onlyMobile: boolean = false;

  constructor(private element: ElementRef) {
    const skewAngleInDegrees: number = 10;
    this.tangent = Math.tan(skewAngleInDegrees * Math.PI / 180);
    this.elementStyle = this.element.nativeElement.style;
  }

  ngAfterContentChecked(): void {
    if (this.onlyMobile && this.element.nativeElement.ownerDocument.defaultView.screen.width > 768) {
      this.elementStyle.marginLeft = '0px';
      this.elementStyle.marginRight = '0px';

      return;
    }

    const horizontalMargin: number = this.element.nativeElement.offsetHeight * this.tangent / 2;
    this.elementStyle.marginLeft = `${horizontalMargin}px`;
    this.elementStyle.marginRight = `${horizontalMargin}px`;
  }
}
