<div class="flex-col-c-c gap-v-4">
  <p>
    {{
      'admin.pages.dashboard.otherVisitRescheduleConfirmation.message'
        | translate
          : {
              previousDate: visit.start | dfnsFormat : 'dd/MM/yyyy HH:mm',
              previousEndDate: visit.end | dfnsFormat : 'HH:mm',
              newDate: newStartDateTime | dfnsFormat : 'dd/MM/yyyy HH:mm',
              newEndDate: newEndDateTime | dfnsFormat : 'HH:mm'
            }
    }}
  </p>
  <vh-message
    class="not-clickable"
    [ngClass]="{
      'has-text-secondary': visitReschedulingImpact === null,
      'has-text-alert': visitReschedulingImpact?.hasImpact() === true,
      'has-text-primary': visitReschedulingImpact?.hasImpact() === false
    }"
    [iconLeftClass]="visitReschedulingImpact === null ? 'anim-rotating' : ''"
    [iconLeftPath]="
      visitReschedulingImpact === null
        ? 'assets/icons/sync-secondary.svg'
        : visitReschedulingImpact?.hasImpact()
        ? 'assets/icons/exclamation-triangle.svg'
        : 'assets/icons/check-circle.svg'
    "
    [iconLeftFill]="
      visitReschedulingImpact === null ? COLORS.secondary : visitReschedulingImpact?.hasImpact() ? COLORS.alert : COLORS.primary
    "
    [text]="visitReschedulingImpactMessage | translate">
  </vh-message>
  <vh-message
    *ngIf="overRuleAttemptNonAdmin"
    class="not-clickable has-text-alert"
    [iconLeftPath]="'assets/icons/times-circle.svg'"
    [iconLeftFill]="COLORS.alert"
    [text]="overRuleAttemptMessage | translate">
  </vh-message>

  <vh-checkbox
    [vhMarginTop]="16"
    [label]="'admin.pages.dashboard.createEventPopup.cancelOverlappingVisits' | translate"
    [value]="cancelOverlappingVisits"
    (valueChanged)="onCancelOverlappingVisitsCheckedChange($event)"
    
    [vhPopoverHost]="CANCEL_VISITS_POPOVER_ID"
    [popoverTemplate]="cancelVisitsPopupTemplate"
    [popoverHasBackdrop]="true"
    popoverPositioningStrategy="left_or_right"
    popoverConnectToFirstHtmlElementWithTagName="vh-other-visit-rescheduling-impact-result"
    [popoverPreferredPosition]="'bottom'">
  </vh-checkbox>

  <div [vhMarginTop]="16" class="flex-row-c-c gap-h-8">
    <vh-button
      [label]="'common.ok' | translate"
      [isDisabled]="isConfirmDisabled()"
      [iconLeftFill]="COLORS.white"
      [matTooltip]="confirmDisabledMessage | translate"
      (buttonClick)="onApproveTimeChangeClicked()">
    </vh-button>
    <vh-button
      [label]="'common.cancel' | translate"
      [style]="ButtonStyle.TERTIARY"
      [isDisabled]="isSavingVisit"
      (buttonClick)="onCancelClicked()">
    </vh-button>
  </div>
</div>


  <!-- Cancel other visits Popover -->
<ng-template #cancelVisitsPopupTemplate>
  <div class="flex-col card">
    <h2>{{ 'admin.pages.dashboard.createEventPopup.visitHasImpact' | translate }}</h2>
    <table class="patients-list" [vhMarginTop]="8">
      <thead>
        <tr>
          <th>{{ 'common.fields.start' | translate }}</th>
          <th>{{ 'common.fields.end' | translate }}</th>
          <th>{{ 'common.fields.service' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let visit of overlappingVisits" class="patient-row">
          <td>
            <p>{{ visit.start | dfnsFormat : DateFormat.clientDate }}</p>
            <p>{{ visit.start | dfnsFormat : DateFormat.clientTimeShort }}</p>
          </td>
          <td>
            <p>{{ visit.end | dfnsFormat : DateFormat.clientDate }}</p>
            <p>{{ visit.end | dfnsFormat : DateFormat.clientTimeShort }}</p>
          </td>
          <td>{{ visit.service?.name }}</td>
        </tr>
      </tbody>
    </table>

    <vh-cancel-visit-form [vhMarginTop]="16" [formGroup]="cancelVisitsFormGroup"></vh-cancel-visit-form>

    <div class="flex-row-c-c gap-h-8" [vhMarginTop]="16">
      <vh-button 
        [label]="'common.ok' | translate" 
        [alignment]="'center'" 
        [centered]="true" 
        [iconLeftPath]="'assets/icons/arrow-right.svg'"
        [iconLeftFill]="COLORS.white"
        (buttonClick)="onApproveCancelOverlappingVisitsClicked()">
      </vh-button>
      <vh-button
        [label]="'common.cancel' | translate"
        [style]="ButtonStyle.TERTIARY"
        (buttonClick)="onCancelClicked()">
      </vh-button>
    </div>

    </div>
  </ng-template>
