/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const ADD_RECURRENCE_FORM_KEYS: Map<string, string> = new Map<string, string>([
  ['interval', 'interval'],
  ['frequency', 'frequency'],
  ['amount', 'amount'],
  ['start', 'end'],
  ['end', 'end'],
  ['duration', 'duration_in_min'],
  ['serviceGroupId', 'service_group_id'],
  ['doctorRizivNumber', 'doctor_riziv_number'],

  // Client-side only
  ['recurrenceOption', 'recurrenceOption'],
  ['occurrenceStart', 'occurrenceStart'],
  ['occurrenceEnd', 'occurrenceEnd'],
]);
