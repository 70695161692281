import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';

@Component({
  selector: 'vh-wizard-progress-indicator',
  templateUrl: './wizard-progress-indicator.component.html',
  styleUrls: ['./wizard-progress-indicator.component.scss'],
})
export class WizardProgressIndicatorComponent implements OnInit {
  @Input() maxWidth: number | string;
  @Input() state: WizardState;

  @Output() stepClicked: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    if (!this.state) {
      throw new Error('WizardProgressIndicatorComponent: state input is required');
    }

    this.maxWidth = this.maxWidth ? `var(--${this.maxWidth}px)` : null;
  }
}
