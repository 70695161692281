import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { ModuleKey } from '@enums/module-key.enum';
import { ModuleService } from '@services/module.service';
import { arrayWrap } from '@utils/helpers/array.util';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { Role } from '@enums/role.enum';

export const moduleGuard = (modules: ModuleKey | ModuleKey[]): CanActivateFn => {
  return (): boolean => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);
    const moduleService: ModuleService = inject(ModuleService);

    if (authenticationService.currentUser?.hasRole(Role.ADMIN)) {
      return true;
    }

    modules = arrayWrap(modules);

    const hasAccess: boolean = moduleService.hasAccessToModules(modules);

    if (!hasAccess) {
      redirectToMostRelevantHome(authenticationService, router);
    }

    return hasAccess;
  };
};
