import { Component } from '@angular/core';
import { rotateAnimation } from '@modules/shared/core/animations/rotate/rotate.animation';

@Component({
  selector: 'vh-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrl: './expandable-card.component.scss',
  animations: [rotateAnimation],
})
export class ExpandableCardComponent {

}
