import { Injectable } from '@angular/core';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { PlanningService } from '@services/planning.service';
import { Observable } from 'rxjs';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';
import { Room } from '@models/room.model';
import { isWithinInterval } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseOpeningsService {
  protected openings: CalendarOpening[] = [];

  protected constructor(protected readonly planningService: PlanningService) {}

  abstract loadOpenings$(filter: VisitFilter): Observable<CalendarOpening[]>;

  protected filterOpeningsByRoom(rooms: Map<string, Room>): CalendarOpening[] {
    return this.openings.filter((opening: CalendarOpening) => rooms.has(opening.id));
  }

  protected shouldHitCache(filter: VisitFilter): boolean {
    const cachedOpening: CalendarOpening = this.openings[0] ?? null;

    return cachedOpening && isWithinInterval(cachedOpening.start, { start: filter.from, end: filter.to });
  }
}
