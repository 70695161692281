import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { Institution } from '@models/institution.model';
import { addDays, isSameDay } from 'date-fns';
import { VirtualSlot } from '@models/virtual-slot.model';
import { LocationService } from '@services/location.service';
import { GoogleApiService } from '@services/google-api.service';
import { SlotService } from '@services/slot.service';
import { Subscription } from 'rxjs';
import { Service } from '@models/service.model';
import { SlotGroupType } from '@enums/slot-group-type.enum';
import { RecommendationsResult } from '@interfaces/search-slots-result.interface';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from '@constants/colors.constant';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CareProgram } from '@models/care-program.model';
import { debounce } from '@utils/decorators/debounce.decorator';
import { BaseMapComponent } from '@modules/shared/core/components/base-map/base-map.component';
import { Timeslot } from '@modules/shared/core/components/timeslot-picker/timeslot.model';
import { Recommendations } from '@models/recommendations.model';
import { RecommendationResultType } from '@enums/recommendation-result-type.enum';
import { SubsequentVirtualSlot } from '@models/subsequent-virtual-slot.model';
import { InstitutionDetailsDialogComponent } from '@modules/booking/dialogs/institution-details-dialog/institution-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { CalendarComponent } from '@modules/shared/calendar/components/calendar/calendar.component';
import { SlotSelectionPlanningService } from '@modules/booking/services/slot-selection-planning.service';
import { invertDates } from '@utils/helpers/date.util';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'vh-step-select-slot',
  templateUrl: './step-select-slot.component.html',
  styleUrls: ['./step-select-slot.component.scss'],
})
export class StepSelectSlotComponent extends BaseMapComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  isLoading: boolean = true;

  timeslotMinuteInterval: number = 120;
  timeslotStartHour: number = 6;
  timeslotEndHour: number = 22;

  unavailableDates: Date[] = [];
  getAvailableAppointmentsErrorMessageTranslationKey: string | null = null;

  institutionsToShowOnMap: Map<string, Institution> = new Map<string, Institution>();

  tabLabelTranslationKeys: string[];
  selectedTab: SlotGroupType = SlotGroupType.SOONEST;
  selectedInstitution: Institution;

  recommendations: Recommendations;

  private fetchingSlotsSubscription: Subscription | null = null;
  private fetchingPlanningSubscription: Subscription | null = null;

  @Input() showContactCallToActionCard: boolean = true;
  @Input() showLocationPicker: boolean = true;

  @Output() requestContactClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() timeslotFilterClick: EventEmitter<Timeslot> = new EventEmitter<Timeslot>();
  @Output() dateFilterClick: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() slotClick: EventEmitter<VirtualSlot> = new EventEmitter<VirtualSlot>();
  @Output() clearFilterClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('map') set map(mapComponent: GoogleMap) {
    if (mapComponent) {
      this.mapComponent = mapComponent;
    }
  }

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild('calendarComponent') calendarComponent: CalendarComponent;

  constructor(
    private readonly slotService: SlotService,
    private readonly dialogService: MatDialog,
    private readonly slotSelectionPlanningService: SlotSelectionPlanningService,
    private readonly authenticationService: AuthenticationService,
    protected readonly bookingState: BookingFlowStateService,
    breakpointObserver: BreakpointObserver,
    googleApiService: GoogleApiService,
    locationService: LocationService,
    translate: TranslateService
  ) {
    super(breakpointObserver, googleApiService, locationService, translate);
  }

  ngOnInit(): void {
    this.tabLabelTranslationKeys = [
      'pages.createAppointment.step4.tabs.label1',
      'pages.createAppointment.step4.tabs.label2',
      'pages.createAppointment.step4.tabs.label3',
      'pages.createAppointment.step4.tabs.label4',
    ];

    this.showUserLocation(false);
    this.refreshRecommendations();
    this.fetchPlanningData();
  }

  onCalendarDateClick = (date: Date): void => {
    if (isSameDay(date, this.bookingState.filter.from)) {
      return;
    }

    this.dateFilterClick.emit(date);
    this.slotSelectionPlanningService.selectedDate = date;
    this.bookingState.filter.from = this.slotSelectionPlanningService.selectedDate;
    this.bookingState.filter.to = addDays(this.slotSelectionPlanningService.selectedDate, 7);

    this.refreshRecommendations();
    // this.fetchPlanningData();
  };

  onTimeslotClicked(timeslot: Timeslot): void {
    this.refreshRecommendations();

    this.timeslotFilterClick.emit(timeslot);
  }

  onInstitutionMapMarkerClicked(institution: Institution): void {
    this.selectedInstitution = institution;
    this.dialogService.open(InstitutionDetailsDialogComponent, {
      data: {
        institution: institution,
      },
    });
  }

  onSlotClicked($event: VirtualSlot): void {
    this.slotClick.emit($event);
  }

  onSlotGroupClicked = ($event: number): void => {
    this.selectedTab = $event;
    this.refreshRecommendations();
  };

  drawInstitutionMarkersOnMap = (isGoogleMapsInitialized: boolean): void => {
    if (!isGoogleMapsInitialized) {
      return;
    }

    this.institutionsToShowOnMap.clear();

    // TODO: restore showing on map
    // const filteredInstitutions = this.recommendations?.institutions;
    //
    // filteredInstitutions.forEach((item: Institution) => {
    //   this.institutionsToShowOnMap.set(item.id, item);
    // });
    //
    // const coordinates: google.maps.LatLngLiteral[] = filteredInstitutions.map(
    //   (institution: Institution) => institution.address.coordinate.latLngLiteral
    // );
    //
    // this.addUserLocationMarker();
    // this.fitCoordinatesToBoundsIncludingUserPosition(coordinates);
  };

  resetAvailableAppointmentsFilterData(): void {
    this.clearFilterClick.emit();
    this.refreshRecommendations();
  }

  showUserLocation(wasTriggeredByUserInteraction: boolean): void {
    if (!this.showLocationPicker) {
      return;
    }

    super.showUserLocation(wasTriggeredByUserInteraction);
  }

  onMonthChanged(firstDayOfMonth: Date): void {
    this.slotSelectionPlanningService.firstDayOfSelectedMonth = firstDayOfMonth;
    this.fetchPlanningData();
  }

  fetchPlanningData(): void {
    // For now, we only support fetching planning date for the current institution, when logged in
    if (!this.authenticationService?.institution) {
      return;
    }

    this.fetchingPlanningSubscription?.unsubscribe();
    this.fetchingPlanningSubscription = this.slotSelectionPlanningService.fetchUnavailableDays$()
      .pipe(untilDestroyed(this))
      .subscribe((unavailableDates: Date[]) => {
        this.unavailableDates = unavailableDates;
        const availableDates = invertDates(this.slotSelectionPlanningService.optimisedFirstDay, this.slotSelectionPlanningService.lastDayOfSelectedMonth, unavailableDates);

        if (availableDates[0]) {
          this.slotSelectionPlanningService.selectedDate = availableDates[0];
          this.bookingState.filter.from = this.slotSelectionPlanningService.selectedDate;
          this.bookingState.filter.to = addDays(this.bookingState.filter.from, 7);
        }
      });
  }

  @debounce(500)
  private refreshRecommendations(): void {
    if (!this.bookingState.services || this.bookingState.services.length === 0) {
      return;
    }

    this.isLoading = true;
    this.fetchingSlotsSubscription?.unsubscribe();
    this.recommendations = null;
    this.getAvailableAppointmentsErrorMessageTranslationKey = null;

    const serviceIds: string[] = this.bookingState.booking.services.map((service: Service): string => service.id);

    this.fetchingSlotsSubscription?.unsubscribe();
    this.fetchingSlotsSubscription = this.slotService
      .getSlotRecommendations$(serviceIds, this.selectedTab, this.bookingState.flow, this.bookingState.filter)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: RecommendationsResult): void => {
          this.fetchingSlotsSubscription = null;

          switch (result.type) {
            case RecommendationResultType.SUBSEQUENT_SLOTS:
              this.recommendations = new Recommendations<SubsequentVirtualSlot>(
                RecommendationResultType.SUBSEQUENT_SLOTS,
                result.subsequentSlots
              );
              break;
            case RecommendationResultType.SLOTS:
              this.recommendations = new Recommendations<VirtualSlot>(RecommendationResultType.SLOTS, result.slots);
              break;
            case RecommendationResultType.INSTITUTIONS:
              this.recommendations = new Recommendations<Institution>(RecommendationResultType.INSTITUTIONS, result.institutions);
              break;
            case RecommendationResultType.CARE_PROGRAMS:
              this.recommendations = new Recommendations<CareProgram>(RecommendationResultType.CARE_PROGRAMS, result.carePrograms);
              break;
          }

          // Draw markers on the map for each institution and fit all markers in the viewport
          this.googleMapsIsInitializedSubscription?.unsubscribe();
          this.googleMapsIsInitializedSubscription = this.googleMapsIsInitialized$.subscribe(this.drawInstitutionMarkersOnMap);

          this.isLoading = false;
        },
        error: (): void => {
          this.fetchingSlotsSubscription = null;
          this.getAvailableAppointmentsErrorMessageTranslationKey = 'common.unknownError';
          this.isLoading = false;
        },
      });
  }
}
