import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { FormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { validateActiveTo } from '@utils/validators/survey-activation-date-validator';
import { SERVICE_GROUP_FORM_KEYS } from '@constants/form-keys/service-group-form-keys.constant';
import { Survey } from '@models/survey.model';
import { ServiceGroup } from '@models/service-group.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceGroupFormService extends AbstractFormService {
  createFormGroup(serviceGroup: ServiceGroup): UntypedFormGroup {
    const group: FormArray = new FormArray([]);

    serviceGroup.surveys?.forEach((survey: Survey) => {
      group.push(this.createSurveyFormGroup(survey));
    });

    return this.formBuilder.group({
      [SERVICE_GROUP_FORM_KEYS.get('name')]: [
        serviceGroup.name ?? null,
        [Validators.required],
      ],
      [SERVICE_GROUP_FORM_KEYS.get('color')]: [
        serviceGroup.color ?? null,
        [Validators.required],
      ],
      [SERVICE_GROUP_FORM_KEYS.get('services')]: [
        null,
        null,
      ],
      [SERVICE_GROUP_FORM_KEYS.get('surveys')]: group,
    });
  }

  createSurveyFormGroup(survey: Survey): UntypedFormGroup {
    return this.formBuilder.group({
      [SERVICE_GROUP_FORM_KEYS.get('surveyId')]: [survey?.id ?? null],
      [SERVICE_GROUP_FORM_KEYS.get('surveyName')]: [survey?.name ?? ''],
      [SERVICE_GROUP_FORM_KEYS.get('surveyIsActive')]: [
        survey?.isActive ?? false,
        [],
      ],
      [SERVICE_GROUP_FORM_KEYS.get('surveyActiveFrom')]: [
        survey?.activeFrom ? format(survey?.activeFrom, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [SERVICE_GROUP_FORM_KEYS.get('surveyActiveTo')]: [
        survey?.activeTo ? format(survey?.activeTo, DATE_FORMATS.serverDate) : null,
        [validateActiveTo(survey?.id)],
      ],
    });
  }
}
