import { Injectable } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { Option } from '@interfaces/option.interface';
import { AnswerList } from '@models/answer-list.model';
import { AnswerOption } from '@models/answer-option.model';
import { Answer } from '@models/answer.model';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { ANSWER_LIST_FORM_KEYS } from '@constants/form-keys/answer-list-form-keys.constant';

@Injectable({
  providedIn: 'root',
})
export class AnswerListFormService extends AbstractFormService {
  createFormGroup(answerList: AnswerList): UntypedFormGroup {
    const group: FormArray = new FormArray([]);
    answerList.answers.forEach((answer: Answer) => {
      group.push(this.createAnswer(answer));
    });

    return this.formBuilder.group({
      [ANSWER_LIST_FORM_KEYS.get('id')]: [
        answerList?.id,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('status')]: [
        answerList?.status,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('visitId')]: [
        answerList?.visitId,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('name')]: [
        answerList?.name,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('description')]: [
        answerList?.description,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('answers')]: group,
    });
  }

  createAnswer(answer?: Answer): UntypedFormGroup {
    return this.formBuilder.group({
      [ANSWER_LIST_FORM_KEYS.get('answerId')]: [
        answer?.id ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('answerListId')]: [
        answer?.answerListId ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('questionId')]: [
        answer?.question.id ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('listPositionNumber')]: [
        answer?.question.listPositionNumber ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('isVisibleForInstitution')]: [
        answer?.question.isVisibleForInstitution ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('isVisibleForPatient')]: [
        answer?.question.isVisibleForPatient ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('answerType')]: [
        answer?.question.answerType ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('parentId')]: [
        answer?.question.parentId ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('question')]: [
        answer?.question.question.get() ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('description')]: [
        answer?.question.description.get() ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('type')]: [
        answer?.question.type ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('answerOptions')]: [
        answer?.question.answerOptions ? this.createAnswerOptions(answer) : null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('valueBoolean')]: [
        answer?.valueBoolean ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('valueDate')]: [
        answer?.valueDate ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('valueText')]: [
        answer?.valueText ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('valuesAnswerOption')]: [
        answer?.valuesAnswerOption ?? null,
        [],
      ],
      [ANSWER_LIST_FORM_KEYS.get('selectedOption')]: [
        answer?.valuesAnswerOption.length && answer.question.type.toString() === 'RADIO' ? answer.valuesAnswerOption[0].id : null,
        [],
      ],
    });
  }

  private createAnswerOptions(answer: Answer): AnswerOption[] | Option[] {
    if (answer.question.type.toString() === 'RADIO') {
      const options: Option[] = [];

      answer.question.answerOptions.forEach((opt: AnswerOption) => {
        options.push({
          label: opt.value.get(),
          value: opt.id,
        });
      });

      return options;
    }

    return answer.question.answerOptions;
  }

  createFormArray(answerLists: AnswerList[]): UntypedFormGroup {
    const group = new FormArray([]);

    answerLists.forEach((answerList: AnswerList) => {
      group.push(this.createBriefAnswerList(answerList));
    });

    return this.formBuilder.group({
      ['answerLists']: group,
    });
  }

  createBriefAnswerList(answerList: AnswerList): UntypedFormGroup {
    return this.formBuilder.group({
      ['id']: [
        answerList.id,
        [],
      ],
      ['visit_id']: [
        answerList.visitId,
        [],
      ],
      ['first_name']: [
        answerList.patient.firstName,
        [],
      ],
      ['last_name']: [
        answerList.patient.lastName,
        [],
      ],
      ['patient_id']: [
        answerList.patient.id,
        [],
      ],
      ['survey_name']: [
        answerList.name,
        [],
      ],
      ['status']: [
        answerList.status,
        [],
      ],
      ['internal_argumentation']: [
        answerList.internalArgumentation ?? '',
        [],
      ],
      ['argumentation']: [
        answerList.argumentation ?? '',
        [],
      ],
      ['visit_name']: [
        answerList.visitName ?? '',
        [],
      ],
    });
  }

  createAnswerList(answerList: AnswerList): UntypedFormGroup {
    const group = new FormArray([]);

    answerList.answers.forEach((answer: Answer) => {
      group.push(this.createAnswer(answer));
    });

    return this.formBuilder.group({
      ['answers']: group,
    });
  }
}
