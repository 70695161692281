import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { ConversationStatus } from '@enums/conversation-status.enum';

@Component({
  selector: 'vh-conversation-status',
  templateUrl: './conversation-status.component.html',
  styleUrls: ['./conversation-status.component.scss'],
})
export class ConversationStatusComponent implements OnChanges {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() status: ConversationStatus;
  @Input() position: 'left' | 'right' | 'center' = 'left';
  @Input() size: 'normal' | 'large' = 'normal';

  refreshStatus = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']) {
      this.refreshStatus = true;
      setTimeout(() => {
        this.refreshStatus = false;
      }, 0); // Short delay to ensure the DOM is updated
    }
  }

  getColor(): string {
    switch (this.status) {
      case ConversationStatus.PENDING:
        return this.COLORS.statusPending;
      case ConversationStatus.REMOVED:
        return this.COLORS.statusRemoved;
      case ConversationStatus.ONGOING:
        return this.COLORS.statusOngoing;
      case ConversationStatus.FINISHED:
        return this.COLORS.statusFinished;
      case ConversationStatus.EXPIRED:
        return this.COLORS.statusExpired;
      default:
        return '';
    }
  }

  isFillableIcon(): boolean {
    switch (this.status) {
      case ConversationStatus.PENDING:
      case ConversationStatus.REMOVED:
      case ConversationStatus.FINISHED:
      case ConversationStatus.EXPIRED:
        return true;
      case ConversationStatus.ONGOING:
        return false;
    }
  }
}
