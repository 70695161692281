<div class="flex-col card gap-v-8 has-background-grey content-block-padding" [class.is-default-border]="paymentMethod.isDefault">
  <div class="flex-row">
    <svg-icon *ngIf="paymentMethod.creditCard.networkIconSrc" [src]="paymentMethod.creditCard.networkIconSrc"></svg-icon>
    <div class="flex-row align-end">
      <vh-message
        *ngIf="paymentMethod.isDefault"
        class="has-text-primary"
        [iconLeftPath]="'assets/icons/check-circle.svg'"
        [iconLeftFill]="COLORS.primary"
        [text]="'wallet.paymentMethods.defaultLabel' | translate ">
      </vh-message>
      <vh-icon
        class="menu-icon clickable "
        src="assets/icons/dots-vertical.svg"
        height="24"
        [hoverStroke]="COLORS.primary"
        [cdkMenuTriggerFor]="cdkMenuTemplate">
      </vh-icon>
    </div>
  </div>

  <div class="flex-grow"></div>
  <div class="flex-col gap-v-8">
    <p class="property-container has-background-white border font-medium-18-22">{{ paymentMethod.creditCard.number ?? 'XXXX XXXX XXXX XXXX' }}</p>
    <div class="flex-row gap-h-8">
      <p class="property-container flex-grow has-background-white border">{{ paymentMethod.creditCard.holder ?? 'XXXX XXXX' }}</p>
      <p class="property-container has-background-white border">{{ paymentMethod.creditCard.expiryDate | dfnsFormat: DATE_FORMATS.clientMonthYear }}</p>
    </div>
  </div>
</div>

<ng-template #cdkMenuTemplate>
  <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{paymentMethod: paymentMethod}"></ng-container>
</ng-template>
