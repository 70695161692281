import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Order, OrderJson } from '@models/order.model';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {}

  getOrders$(): Observable<Order[]> {
    const endpoint: string = ENDPOINTS.getOrders.route
      .replace(`{${ENDPOINTS.getOrders.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: OrderJson[]) => json.map(Order.fromJson)));
  }

  getOrderById$(orderId: string): Observable<Order> {
    const endpoint: string = ENDPOINTS.getOrderById.route
      .replace(`{${ENDPOINTS.getOrderById.pathParams.orderId}}`, orderId)
      .replace(`{${ENDPOINTS.getOrderById.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map(Order.fromJson));
  }
}
