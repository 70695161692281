import { NavigationExtras, Router } from '@angular/router';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { AuthenticationService } from '@services/authentication.service';
import { Injectable } from '@angular/core';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';
import { BookingFlow } from '@enums/booking-flow.enum';
import { PendingBooking } from '@models/pending-booking.model';
import { Institution } from '@models/institution.model';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface NavigateToContactPageParameters {
  latitude: number;
  longitude: number;
  radius: number;
  service: string;
  visit: string;
}

/**
 * This services helps with navigating through the booking flow.
 */
@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class BookingFlowNavigationService {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly bookingFlowDialogService: BookingFlowDialogService
  ) {}

  navigateToBookingFlow(flow: BookingFlow, pendingBooking: PendingBooking = null, institution: Institution = null): void {
    if (pendingBooking) {
      this.navigateToUpdateBookingFlow(flow, pendingBooking, institution);

      return;
    }

    switch (flow) {
      case BookingFlow.PUBLIC:
        this.navigateToCreatePublicBookingFlow();

        return;
      case BookingFlow.INSTITUTION_EMPLOYEE:
        this.navigateToCreateInstitutionEmployeeBookingFlow(institution ?? this.authenticationService.institution);

        return;
      case BookingFlow.REFERRING_PHYSICIAN:
        this.navigateToCreateReferringPhysicianBookingFlow(institution ?? this.authenticationService.institution);

        return;
      case BookingFlow.UNIQUE_LINK:
        this.navigateToCreateBookingFlowViaUniqueLink(institution);

        return;
    }
  }

  protected navigateToUpdateBookingFlow(flow: BookingFlow, pendingBooking: PendingBooking, institution: Institution): void {
    switch (flow) {
      case BookingFlow.PUBLIC:
        this.navigateToUpdatePublicBookingFlow(pendingBooking);

        return;
      case BookingFlow.INSTITUTION_EMPLOYEE:
        this.navigateToUpdateInstitutionEmployeeBookingFlow(pendingBooking, institution ?? this.authenticationService.institution);

        return;
      case BookingFlow.REFERRING_PHYSICIAN:
        this.navigateToUpdateReferringPhysicianBookingFlow(pendingBooking, institution ?? this.authenticationService.institution);

        return;
      case BookingFlow.UNIQUE_LINK:
        this.navigateToUpdateBookingFlowViaUniqueLink(pendingBooking, institution);

        return;
    }
  }

  protected navigateToCreatePublicBookingFlow(): void {
    const route: string = BOOKING_NAV.createBooking.route;

    void this.router.navigate([route]);
  }

  protected navigateToCreateInstitutionEmployeeBookingFlow(institution: Institution): void {
    const route: string = BOOKING_NAV.createBookingAsInstitution.route;

    void this.router.navigate([route], { state: { institution: institution } });
  }

  protected navigateToCreateReferringPhysicianBookingFlow(institution: Institution): void {
    const route: string = BOOKING_NAV.createBookingAsReferringPhysician.route;

    void this.router.navigate([route], { state: { institution: institution } });
  }

  protected navigateToCreateBookingFlowViaUniqueLink(institution: Institution): void {
    const route: string = BOOKING_NAV.createBookingViaInstitutionUniqueLink.route
      .replace(`:${BOOKING_NAV.createBookingViaInstitutionUniqueLink.params.institutionSlug}`, institution.id);

    void this.router.navigate([route], { state: { institution: institution } });
  }

  protected navigateToUpdatePublicBookingFlow(pendingBooking: PendingBooking): void {
    const route: string = BOOKING_NAV.updateBooking.route
      .replace(`:${BOOKING_NAV.updateBooking.params.pendingBookingId}`, pendingBooking.id);

    void this.router.navigate([route], { state: { pendingBooking: pendingBooking } });
  }

  protected navigateToUpdateInstitutionEmployeeBookingFlow(pendingBooking: PendingBooking, institution: Institution): void {
    const route: string = BOOKING_NAV.updateBookingAsInstitution.route
      .replace(`:${BOOKING_NAV.updateBookingAsInstitution.params.pendingBookingId}`, pendingBooking.id);

    void this.router.navigate([route], { state: { pendingBooking: pendingBooking, institution: institution } });
  }

  protected navigateToUpdateReferringPhysicianBookingFlow(pendingBooking: PendingBooking, institution: Institution): void {
    const route: string = BOOKING_NAV.updateBookingAsReferringPhysician.route
      .replace(`:${BOOKING_NAV.updateBookingAsReferringPhysician.params.pendingBookingId}`, pendingBooking.id);

    void this.router.navigate([route], { state: { pendingBooking: pendingBooking, institution: institution } });
  }

  protected navigateToUpdateBookingFlowViaUniqueLink(pendingBooking: PendingBooking, institution: Institution): void {
    const route: string = BOOKING_NAV.updateBookingViaInstitutionUniqueLink.route
      .replace(`:${BOOKING_NAV.updateBookingViaInstitutionUniqueLink.params.pendingBookingId}`, pendingBooking.id)
      .replace(`:${BOOKING_NAV.updateBookingViaInstitutionUniqueLink.params.institutionSlug}`, institution.id);

    void this.router.navigate([route], { state: { pendingBooking: pendingBooking, institution: institution } });
  }

  navigateToContactPage(parameters: NavigateToContactPageParameters): void {
    const options: NavigationExtras = { queryParams: parameters };
    const route: string = BOOKING_NAV.bookingContactForHelp.route
      .replace(`:${BOOKING_NAV.bookingContactForHelp.params.pendingBookingId}`, parameters.visit);

    void this.router.navigate([route], options);
  }

  navigateToSelectPatientPage(pendingBookingId: string): void {
    const route: string = BOOKING_NAV.bookingSelectPatient.route
      .replace(`:${BOOKING_NAV.bookingSelectPatient.params.pendingBookingId}`, pendingBookingId);

    void this.router.navigate([route]);
  }

  navigateToBookingConfirmationPage(pendingBookingId: string): void {
    const route: string = BOOKING_NAV.bookingConfirmation.route
      .replace(`:${BOOKING_NAV.bookingConfirmation.params.pendingBookingId}`, pendingBookingId);

    void this.router.navigate([route]);
  }

  navigateToBookingSuccessPage(pendingBookingId: string): void {
    const route: string = BOOKING_NAV.bookingSuccess.route.replace(`:${BOOKING_NAV.bookingSuccess.params.pendingBookingId}`, pendingBookingId);

    void this.router.navigate([route]);
  }

  exitBookingFlow(shouldAskForConfirmation: boolean = true): Observable<boolean> {
    if (!shouldAskForConfirmation) {
      redirectToMostRelevantHome(this.authenticationService, this.router);

      return of(true);
    }

    return this.bookingFlowDialogService.showConfirmExitBookingFlowDialog()
      .pipe(untilDestroyed(this))
      .pipe(tap((confirmed: boolean) => {
        if (confirmed) {
          redirectToMostRelevantHome(this.authenticationService, this.router);
        }
      }));
  }

  isBookingFlowRoute(route: string): boolean {
    return route.includes('/booking/');
  }
}
