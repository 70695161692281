import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';

@Component({
  selector: 'vh-onboarding-container-page',
  templateUrl: './onboarding-container-page.component.html',
  styleUrls: ['./onboarding-container-page.component.scss'],
})
@UntilDestroy()
export class OnboardingContainerPageComponent {
  protected readonly ONBOARDING_NAV: typeof ONBOARDING_NAV = ONBOARDING_NAV;
  

  constructor(
    readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) { }

  onBackButtonClicked(): void {
    if (!this.authenticationService.isLoggedIn()) {
      void this.router.navigate([NAVIGATION.login.path]);

      return;
    }

    this.authenticationService.logout$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        void this.router.navigate([NAVIGATION.login.path]);
      });
  }
}
