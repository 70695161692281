import { Component, OnInit } from '@angular/core';
import { ServiceGroup } from '@models/service-group.model';
import { ServiceGroupService } from '@services/service-group.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { ServiceService } from '@services/service.service';
import { Service } from '@models/service.model';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { COLORS } from '@constants/colors.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InstitutionService } from '@services/institution.service';
import { Survey } from '@models/survey.model';
import { ModuleService } from '@services/module.service';
import { ModuleKey } from '@enums/module-key.enum';
import { ActivatedRoute } from '@angular/router';
import { smoothScrollToElementById } from '@utils/helpers/scroll.util';
import { NAVIGATION } from '@constants/navigation.constant';
import { fadeInAnimation } from '@modules/shared/core/animations/fade/fade-in.animation';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

@Component({
  selector: 'vh-service-groups-settings-page',
  templateUrl: './service-group-settings-page.component.html',
  styleUrls: ['./service-group-settings-page.component.scss'],
  animations: [fadeInAnimation],
})
@UntilDestroy()
export class ServiceGroupSettingsPageComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  state: 'list' | 'empty' | 'loading' = 'loading';

  COLORS: typeof COLORS = COLORS;

  displayedServiceGroups: ServiceGroup[];
  surveys: Survey[] | null;

  selectedModality: Service;
  modalityOptions: ButtonOption[];
  searchValue: string;
  isGroupPendingCreation: boolean;

  fetchGroupsSubscription: Subscription;
  deleteGroupSubscription: Subscription;

  highlightedServiceGroup: ServiceGroup;

  constructor(
    private readonly moduleService: ModuleService,
    private readonly serviceGroupService: ServiceGroupService,
    private readonly serviceService: ServiceService,
    private readonly alertDialogService: AlertDialogService,
    private readonly institutionService: InstitutionService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.reloadAll();
  }

  onModalitySelected = (selectedIndex: number): void => {
    this.highlightedServiceGroup = null;

    const service = this.serviceService.servicesForCurrentInstitution[selectedIndex];

    if (!service || service.id === this.selectedModality?.id) {
      return;
    }

    this.selectedModality = service;
    this.isGroupPendingCreation = false;

    this.modalityOptions = this.serviceService.servicesForCurrentInstitution?.map((s: Service, index: number) => {
      const option = s.toButtonOption(true);
      option.isActive = selectedIndex === index;
      option.isClickable = !option.isActive;

      return option;
    });

    this.updateDisplayedGroups();
  };

  onSearchQueryEntered = (query: string): void => {
    this.highlightedServiceGroup = null;
    this.searchValue = query;
    this.updateDisplayedGroups();
  };

  onAddServiceGroupClicked = (): void => {
    this.highlightedServiceGroup = null;
    this.displayedServiceGroups.push(new ServiceGroup(null, null, COLORS.primary));
    this.isGroupPendingCreation = true;
  };

  onRemoveServiceGroupClicked = (group: ServiceGroup): void => {
    this.highlightedServiceGroup = null;

    // This occurs when creating a new group is canceled
    if (!group.id) {
      this.displayedServiceGroups.splice(this.displayedServiceGroups.length - 1, 1);
      this.isGroupPendingCreation = false;

      return;
    }

    // Ask confirmation before deleting a saved service group
    this.alertDialogService.open({
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
      titleTranslationKey: 'admin.pages.settings.serviceGroups.deleteDialog.title',
      messageTranslationKey: 'admin.pages.settings.serviceGroups.deleteDialog.message',
    })
      .pipe(untilDestroyed(this))
      .subscribe((isOkay: boolean) => {
        if (isOkay) {
          this.onRemoveServiceGroupConfirmed(group);
        }
      });
  };

  onEditServiceGroupClicked(): void {
    this.highlightedServiceGroup = null;
  }

  onRemoveServiceGroupConfirmed = (group: ServiceGroup): void => {
    this.deleteGroupSubscription?.unsubscribe();
    this.deleteGroupSubscription = this.serviceGroupService.deleteServiceGroup$(group.id)
      .subscribe(() => {
        this.deleteGroupSubscription = null;

        this.reloadGroups(true);
      });
  };

  onServiceGroupSaved(): void {
    this.isGroupPendingCreation = false;
    this.reloadGroups(true);
  }

  private reloadAll = (): void => {
    this.state = 'loading';
    this.highlightedServiceGroup = null;

    combineLatest([
      this.serviceService.getServicesForCurrentInstitution$(null, true),
      this.serviceGroupService.getServiceGroupsForCurrentInstitution$(),
      this.moduleService.hasAccessToModule(ModuleKey.SURVEY) ? this.institutionService.getSurveysByInstitution$(false) : of(null),
    ]).subscribe(() => {
      if (this.serviceService.servicesForCurrentInstitution.length === 0) {
        this.state = 'empty';

        return;
      }

      this.state = 'list';

      this.surveys = this.institutionService.currentSurveys;

      // If a service group ID is passed as qs param then we automatically highlight the service group
      if (this.activatedRoute.snapshot.queryParams.serviceGroupId) {
        const serviceGroup = this.serviceGroupService.serviceGroupsForCurrentInstitution
          .find((group: ServiceGroup) => group.id === this.activatedRoute.snapshot.queryParams.serviceGroupId);

        if (serviceGroup) {
          const serviceIndex = this.serviceService.servicesForCurrentInstitution.findIndex((s: Service) => s.id === serviceGroup.modality.id);
          this.onModalitySelected(serviceIndex ?? 0);
          this.highlightServiceGroup(serviceGroup);
        }

        return;
      }

      // Set the first service automatically as the selected service if none was selected
      this.onModalitySelected(0);
    });
  };

  private updateDisplayedGroups = (): void => {
    const pool = this.serviceGroupService.serviceGroupsForCurrentInstitution?.filter((group: ServiceGroup) => {
      return group.services.some((service: Service) => service.isChildOf(this.selectedModality));
    }) ?? [];

    if (this.searchValue) {
      this.displayedServiceGroups = pool?.filter((group: ServiceGroup): boolean => {
        return group.name.toLowerCase().includes(this.searchValue.toLowerCase());
      });

      return;
    }

    this.displayedServiceGroups = pool;

    // If there are no service groups for the selected service then we automatically show the form to create the first one
    if (this.displayedServiceGroups.length === 0) {
      this.onAddServiceGroupClicked();
    }
  };

  private reloadGroups = (forceLoad: boolean = false): void => {
    this.fetchGroupsSubscription?.unsubscribe();
    this.fetchGroupsSubscription = this.serviceGroupService.getServiceGroupsForCurrentInstitution$(forceLoad)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchGroupsSubscription = null;
        this.updateDisplayedGroups();
      });
  };

  private highlightServiceGroup(group: ServiceGroup): void {
    this.highlightedServiceGroup = group;
    smoothScrollToElementById(group.id);
  }
}
