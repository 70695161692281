<label *ngIf="label" [innerHTML]="label"></label>
<div class="flex-col">
  <div
    class="flex-col input-wrapper"
    [ngClass]="{
      default: style === TextInputStyle.DEFAULT,
      material: style === TextInputStyle.MATERIAL
    }">
    <input
      [type]="showPassword ? 'text' : 'password'"
      class="show-password-icon-spacing"
      [ngClass]="{
        'error': hasError,
      }"
      [autocomplete]="autocomplete ?? ''"
      [placeholder]="placeholder || ''"
      [disabled]="isDisabled || formControl?.disabled"
      [readonly]="isReadonly"
      [ngModel]="value"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="handleValueChangeWithCheck($event)"
      (blur)="handleValueChangeOnBlurWithCheck()" />
    <vh-icon
      class="inline-icon inline-icon-right show-password-icon clickable"
      [src]="showPassword ? 'assets/icons/eye-slash.svg' : 'assets/icons/eye.svg'"
      [stroke]="COLORS.secondary"
      (click)="showPassword = !showPassword">
    </vh-icon>
  </div>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }"
    [innerHTML]="message">
  </p>
  <p
    *ngIf="errorMessage"
    [vhMarginTop]="8"
    class="has-text-error font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }"
    [innerHTML]="errorMessage">
  </p>
</div>
