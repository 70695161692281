import { Component, forwardRef, OnInit } from '@angular/core';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vh-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: [
    '../base-input/base-input.component.scss',
    './color-picker.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent extends BaseInputComponent implements OnInit {
  writeValue(value: string): void {
    if (value === '') {
      value = null;
    }

    this.value = value ?? this.value ?? null;
  }
}
