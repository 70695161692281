import { Pipe, PipeTransform } from '@angular/core';
import { truncateMiddle } from '@utils/helpers/string.util';

/**
 * This pipe can be used to truncate a string in the middle with ellipsis (or any other character).
 *
 * For example: "This is a very long sentence which will be abbreviated."
 * will become: "This is a very...ted."
 *
 */
@Pipe({ name: 'vhTruncateMiddle' })
export class TruncateMiddlePipe implements PipeTransform {
  transform(value?: string, maxLength: number = 30, backCharsLength: number = 4, separator: string = '...'): string {
    if (!value) {
      return null;
    }

    return truncateMiddle(value, maxLength, backCharsLength, separator);
  }
}
