import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { VisitReschedulingImpactResultComponent } from '@modules/shared/visits/components/visit-rescheduling-impact-result/visit-rescheduling-impact-result.component';
import { OtherVisitReschedulingImpactResultComponent } from '@modules/shared/visits/components/other-visit-rescheduling-impact-result/other-visit-rescheduling-impact-result.component';
import { VisitStatusListComponent } from '@modules/shared/visits/components/visit-status-list/visit-status-list.component';
import { VisitDetailsDialogComponent } from '@modules/shared/visits/dialogs/visit-details-dialog/visit-details-dialog.component';
import { OtherVisitDetailsDialogComponent } from '@modules/shared/visits/dialogs/other-visit-details-dialog/other-visit-details-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormModule } from '@modules/shared/form/form.module';
import { SettingsSharedModule } from '@modules/settings/settings-shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormatDistanceToNowPipeModule, FormatPipeModule } from 'ngx-date-fns';
import { Type } from '@angular/core';

const sharedIngredients: Type<unknown>[] = [
  VisitReschedulingImpactResultComponent,
  OtherVisitReschedulingImpactResultComponent,
  VisitStatusListComponent,
  VisitDetailsDialogComponent,
  OtherVisitDetailsDialogComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
    SettingsSharedModule,
    MatDialogModule,
    MatTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormatPipeModule,
    FormatDistanceToNowPipeModule,
  ],
  exports: sharedIngredients,
})
export class VisitsModule { }
