import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { COLORS } from '@constants/colors.constant';
import { UploadedFile } from '@modules/shared/form/interfaces/uploaded-file.interface';

@Component({
  selector: 'vh-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: ['./uploaded-file.component.scss'],
})
@UntilDestroy()
export class UploadedFileComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() file: UploadedFile;
  @Input() index: number;

  @Output() deleteFileClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() descriptionChanged: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();

  onDescriptionChange$: Subject<string> = new Subject<string>();

  ngOnInit(): void {
    this.onDescriptionChange$
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(500))
      .subscribe(this.onDescriptionChanged);
  }

  onDeleteFileClicked(): void {
    if (this.file?.isUpdating) {
      return;
    }

    this.deleteFileClicked.emit(this.index);
  }

  onDescriptionChanged = (description: string): void => {
    this.file.description = description;
    this.descriptionChanged.emit(this.file);
  };
}
