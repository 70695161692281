import { Component, forwardRef, OnInit, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectInputComponent } from '@modules/shared/form/components/select-input/select-input.component';
import { PopoverService } from '@services/ui/popover.service';
import { ErrorMessageService } from '@services/error-message.service';
import { SpecialtyService } from '@services/specialty.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Specialty } from '@models/specialty.model';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vh-specialties-select-input',
  templateUrl: '../../../shared/form/components/select-input/select-input.component.html',
  styleUrls: [
    '../../../shared/form/components/base-select-input/base-select-input.component.scss',
    '../../../shared/form/components/select-input/select-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecialtiesSelectInputComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class SpecialtiesSelectInputComponent extends SelectInputComponent implements OnInit {

  constructor(
  @Optional() controlContainer: ControlContainer,
    @Optional() translateService: TranslateService,
    popoverService: PopoverService,
    errorMessageService: ErrorMessageService,
    private readonly specialtyService: SpecialtyService
  ) {
    super(controlContainer, translateService, popoverService, errorMessageService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loadSpecialties();
  }

  private loadSpecialties(): void {
    this.specialtyService.getSpecialties$()
      .pipe(untilDestroyed(this))
      .subscribe((specialties: Specialty[]) => {
        // TODO: handle children
        this.options = specialties.map((specialty: Specialty): Option => {
          return {
            label: specialty.name,
            value: specialty.id,
          };
        });
      });
  }
}
