import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Visit } from '@models/visit.model';
import { VISIT_FORM_KEYS } from '@constants/form-keys/visit-form-keys.constant';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Injectable({
  providedIn: 'root',
})
export class VisitFormService extends AbstractFormService {
  createUpdateVisitTimeFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('startDate')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('startTime')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('customTitle')]: [
        visit?.customTitle,
        [],
      ],
    });
  }

  createUpdateOtherVisitInfoFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('customTitle')]: [
        visit?.customTitle,
        [],
      ],
    });
  }

  createUpdateOtherVisitTimeFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('startDate')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('startTime')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('endTime')]: [
        visit?.end ? format(visit.end, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
    });
  }

  createUpdateVisitCommentFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('internalComment')]: [
        visit?.internalComment ?? null,
        [],
      ],
      [VISIT_FORM_KEYS.get('externalComment')]: [
        visit?.externalComment ?? null,
        [],
      ],
    });
  }

  createVisitFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('startDate')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('startTime')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('endTime')]: [
        visit?.end ? format(visit.end, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('patient')]: [
        visit?.patient,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('service')]: [
        visit?.service,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('room')]: [
        visit?.room,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('recurrence')]: [
        visit?.recurrenceId,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('visitType')]: [
        visit?.visitType,
        [Validators.required],
      ],
    });
  }

  createOtherVisitFormGroup(visit?: Visit): UntypedFormGroup {
    return this.formBuilder.group({
      [VISIT_FORM_KEYS.get('startDate')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.serverDate) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('startTime')]: [
        visit?.start ? format(visit.start, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('endTime')]: [
        visit?.end ? format(visit.end, DATE_FORMATS.clientTimeShort) : null,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('room')]: [
        visit?.room,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('recurrence')]: [
        visit?.recurrenceId,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('customTitle')]: [visit?.customTitle],
      [VISIT_FORM_KEYS.get('visitType')]: [
        visit?.visitType,
        [Validators.required],
      ],
      [VISIT_FORM_KEYS.get('cancelOverlappingVisits')]: [visit?.cancelOverlappingVisits],
    });
  }
}
