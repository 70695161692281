import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[vhDragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('class.file-over') fileOver: boolean;

  @Output() fileDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() fileDragOver: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileDragLeave: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    // This check makes sure a child of the dragged over element does not trigger dragover
    if ((event.currentTarget as HTMLElement).contains(event.relatedTarget as HTMLElement)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (!this.fileOver) {
      this.fileOver = true;
      this.fileDragOver.emit();
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    // This check makes sure a child of the dragged over element does not trigger dragleave
    if ((event.currentTarget as HTMLElement).contains(event.relatedTarget as HTMLElement)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.fileOver) {
      this.fileOver = false;
      this.fileDragLeave.emit();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;

    if (event.dataTransfer.files.length <= 0) {
      return;
    }

    this.fileDropped.emit(event.dataTransfer.files);
  }
}
