<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()" [isDisabled]="!formGroup.dirty"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()" [isDisabled]="!formGroup.dirty"></vh-button>
    </div>

    <div class="settings-list" [formGroup]="formGroup">
      <ng-container *vhRequiresModule="ModuleKey.BOOKING">
        <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.generalTitle' | translate"></vh-horizontal-divider>

        <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.reservationLinkLabel' | translate }}</label>
        <div class="flex-col">
          <vh-copy-to-clipboard
            [text]="institution.reservationLink"
            [confirmationMessage]="'admin.pages.slotsOverview.slotsPage.exportCalendarDialog.copyConfirmation' | translate">
          </vh-copy-to-clipboard>
          <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
            {{ 'admin.pages.settings.generalSettings.reservationLinkDescription' | translate }}
          </p>
          <vh-button
            [style]="ButtonStyle.SECONDARY"
            [vhMarginTop]="8"
            class="gap-h-8 align-end"
            [label]="'admin.pages.settings.generalSettings.downloadQRCode' | translate"
            (buttonClick)="downloadQRCode()"></vh-button>
        </div>
      </ng-container>

      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.languageTitle' | translate"></vh-horizontal-divider>

      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.supportedLanguagesLabel' | translate }}</label>
      <div class="flex-col">
        <div *ngFor="let language of allLanguageOptions" class="flex-row-l-c language-option gap-h-8">
          <vh-checkbox
            [updateValueOnClick]="false"
            [value]="institution.settings.supportedLanguages.has(language.value)"
            [isDisabled]="institution.settings.supportedLanguages.size === 1 && institution.settings.supportedLanguages.has(language.value)"
            (click)="onSupportedLanguageClicked(language)">
          </vh-checkbox>
          <span>{{ language.label }}</span>
        </div>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.supportedLanguagesDescription' | translate }}
        </p>
      </div>

      <label [vhMarginTop]="4">{{ 'admin.pages.settings.generalSettings.defaultLanguageLabel' | translate }}</label>
      <div class="flex-col">
        <vh-language-picker
          #languagePicker
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('default_language')"
          [value]="institution.settings.defaultLanguage"
          [changeAppLanguageOnValueChange]="false"
          [scopeToInstitutionSettings]="true">
        </vh-language-picker>
        <p [vhMarginTop]="8" class="has-text-tertiary font-light-12-14 align-end">
          {{ 'admin.pages.settings.generalSettings.defaultLanguageDescription' | translate }}
        </p>
      </div>
    </div>
  </div>
</vh-content-layout>
