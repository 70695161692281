import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { Router } from '@angular/router';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { NAVIGATION } from '@constants/navigation.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';
import { NewOnboardingFormService } from '@services/form-services/new-onboarding-form.service';
import { NewOnboardingService } from '@services/new-onboarding.service';
import { IObject } from '@app-types/iobject.type';
import { ToastService } from '@services/ui/toast.service';
import { AuthenticationService } from '@services/authentication.service';
import { Subscription } from 'rxjs';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'vh-onboarding-account-page',
  templateUrl: './onboarding-account-page.component.html',
  styleUrls: ['./onboarding-account-page.component.scss'],
})
@UntilDestroy()
export class OnboardingAccountPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly REGISTER_FORM_KEYS: typeof REGISTER_FORM_KEYS = REGISTER_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  termsAndConditionsLink: string = environment.links.termsAndConditions;
  formGroup: UntypedFormGroup;
  isSubmitting: boolean = false;
  onboardingPayload: IObject;

  fetchSubscription: Subscription | null = null;

  constructor(
    private readonly newOnboardingFormService: NewOnboardingFormService,
    private readonly router: Router,
    private readonly newOnboardingService: NewOnboardingService,
    private readonly toastService: ToastService,
    private readonly authenticationService: AuthenticationService,
    private readonly analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this.newOnboardingFormService.createFormGroupForOnboardingAccount();
    this.onboardingPayload = this.newOnboardingService.getOnboardingPayload();
  }

  setStep(): void {
    this.state.goToStep(this.STEP_PRIVACY);
  }

  onNextClicked(): void {
    this.isSubmitting = true;

    const onboardingPayload = {
      ...this.newOnboardingService.getOnboardingPayload(),
      username: this.formGroup.value.username,
      password: this.formGroup.value.password,
      email: this.formGroup.value.email,
    };
    this.newOnboardingService.setOnboardingPayload(onboardingPayload);
    this.onboardingPayload = onboardingPayload;

    this.newOnboardingService.onboard$(this.onboardingPayload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.analyticsService.trackEvent('User', 'register_successful');
          this.login();
        },
        error: (response: HttpErrorResponse | unknown): void => {
          console.error(response);
          this.toastService.showError('common.errors.generic')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }

  login(): void {
    this.authenticationService.login$({ username: this.formGroup.value.username, password: this.formGroup.value.password })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this.analyticsService.trackEvent('User', 'login_successful');
          this.newOnboardingService.clearData();
          void this.router.navigate([ONBOARDING_NAV.success.route]);
        },
        error: (response: HttpErrorResponse | unknown): void => {
          console.error(response);
          this.toastService.showError('common.errors.login')
            .pipe(untilDestroyed(this))
            .subscribe();
        },
      });
  }
}
