import { Injectable } from '@angular/core';
import { IObject } from '@app-types/iobject.type';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@services/authentication.service';
import { Observable, map } from 'rxjs';
import { AnswerList, AnswerListJson } from '@models/answer-list.model';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class AnswerListService {
  private readonly httpOptions: IObject = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };

  constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {}

  getAnswerLists$ = (): Observable<AnswerList[]> => {
    const endpoint: string = ENDPOINTS.getAnswerLists.route
      .replace(`{${ENDPOINTS.getAnswerLists.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .get(url)
      .pipe(map((json: AnswerListJson[]): AnswerList[] => json.map((answerListJson: AnswerListJson): AnswerList => AnswerList.fromJson(answerListJson))));
  };

  getAnswerList$ = (answerListId: string): Observable<AnswerList> => {
    const endpoint: string = ENDPOINTS.getAnswerList.route
      .replace(`{${ENDPOINTS.getAnswerList.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getAnswerList.pathParams.answerListId}}`, answerListId);

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .get(url)
      .pipe(map((json: AnswerListJson): AnswerList => AnswerList.fromJson(json)));
  };

  evaluateAnswerList$ = (answerList: IObject): Observable<void> => {
    const endpoint: string = ENDPOINTS.evaluateAnswerList.route
      .replace(`{${ENDPOINTS.evaluateAnswerList.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.evaluateAnswerList.pathParams.answerListId}}`, answerList.id);

    const url = environment.apiBaseUrl + endpoint;

    return this.http
      .put(url, answerList, this.httpOptions)
      .pipe(switchToEmptyObservable());
  };
}
