import { ConversationChat, ConversationChatJson } from '@models/conversation-chat.model';
import { WalletTransactionType } from '@enums/wallet-transaction-type.enum';

export class WalletTransaction {
  constructor(
    public _id: string,
    public _amount: number,
    public _newBalance: number,
    public _type: WalletTransactionType,
    public _conversation: ConversationChat,
    public _createdAt: Date
  ) {
  }

  get id(): string {
    return this._id;
  }

  get amount(): number {
    return this._amount;
  }

  get displayAmount(): string {
    return this.amount > 0 ? `+ ${Math.abs(this.amount)}` : `- ${Math.abs(this.amount)}`;
  }

  get newBalance(): number {
    return this._newBalance;
  }

  get type(): WalletTransactionType {
    return this._type;
  }

  get conversation(): ConversationChat {
    return this._conversation;
  }

  get isOutgoing(): boolean {
    return this.amount < 0;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get titleKey(): string {
    return 'wallet.history.transactionTypeTitle.' + this.type;
  }

  static fromJson(json: WalletTransactionJson): WalletTransaction {
    return new WalletTransaction(
      json.id,
      json.amount,
      json.new_balance,
      WalletTransactionType[json.type],
      json.conversation ? ConversationChat.fromJson(json.conversation) : null,
      new Date(json.created_at)
    );
  }
}

export interface WalletTransactionJson {
  id: string;
  amount: number;
  new_balance: number;
  type: string;
  conversation: ConversationChatJson | null;
  created_at: string;
}
