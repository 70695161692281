<div class="flex-row card content-block-padding"
  [ngClass]="{ 'removed-conversation-record': conversation.status === ConversationStatus.REMOVED }">
  <div class="flex-col-l-c">
    <h2 class="font-medium-18-22">{{ conversation.patient.fullName | translate }}</h2>
    <p class="font-light-12-14 has-text-tertiary">{{ conversation.patient.socialSecurityNumber ?? '/' }}</p>
  </div>
  <div class="flex-col">
    <p class="font-regular-16-19" [vhMarginTop]="2">{{ conversation.doctor.fullName }}</p>
    <p class="font-light-12-14 has-text-tertiary">{{ conversation.questionList }}</p>
  </div>
  <div class="flex-col">
    <p class="font-regular-16-19" [vhMarginTop]="2">{{ 'conversations.card.summary' | translate }}</p>
    <p class="font-light-12-14 has-text-tertiary">{{ conversation.cause ?? '/' }}</p>
  </div>
  <div class="flex-row gap-h-24">
    <div class="flex-col date-width">
      <p class="font-light-12-14 has-text-tertiary">{{ 'conversations.card.completionDate' | translate }}</p>
      <p class="font-regular-16-19" [vhMarginTop]="2">{{ conversation.finishedAt | dfnsFormat: DATE_FORMATS.clientDate }}</p>
    </div>
    <div class="flex-col">
      <p class="font-light-12-14 has-text-tertiary">{{ 'conversations.card.dueDate' | translate }}</p>
      <p class="font-regular-16-19" [vhMarginTop]="2">{{ conversation.dueDate | dfnsFormat: DATE_FORMATS.clientTimeShort }}
      </p>
    </div>
  </div>

  <vh-conversation-status [status]="conversation.status" [position]="'right'"></vh-conversation-status>

  <div class="open-menu">
    <vh-icon
      [cdkMenuTriggerFor]="cdkMenuTemplate"
      class="menu-icon clickable"
      src="assets/icons/dots-vertical.svg"
      height="24">
    </vh-icon>
  </div>
</div>

<ng-template #cdkMenuTemplate>
  <div cdkMenu class="menu-content">
    <vh-conversation-action-menu [conversation]="conversation"></vh-conversation-action-menu>
  </div>
</ng-template>
