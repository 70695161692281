import { OrderStatus } from '@enums/order-status.enum';

export class Order {
  constructor(
    private _id: string,
    private _status: OrderStatus,
    private _price: number,
    private _tokens: number,
    private _method: string,
    private _createdAt: Date
  ) {}

  get id(): string {
    return this._id;
  }

  get status(): OrderStatus {
    return this._status;
  }

  get price(): number {
    return this._price;
  }

  get tokens(): number {
    return this._tokens;
  }

  get method(): string {
    return this._method;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  static fromJson(json: OrderJson): Order {
    return new Order(
      json.id,
      OrderStatus[json.status.toUpperCase() as keyof typeof OrderStatus],
      json.price,
      json.tokens,
      json.method,
      new Date(json.created_at)
    );
  }
}

export interface OrderJson {
  id: string;
  status: string;
  price: number;
  tokens: number;
  method: string;
  created_at: string;
}
