import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vh-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent {
  @Input() tabLabelTranslationKeys: string[];
  @Input() selectedTab: number;

  @Output() selectedTabChange: EventEmitter<number>;

  constructor() {
    this.selectedTab = 1;
    this.selectedTabChange = new EventEmitter<number>();
  }
}
