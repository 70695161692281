<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()" [isDisabled]="!formGroup.dirty || !formGroup.valid"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()" [isDisabled]="!formGroup.dirty || !formGroup.valid"></vh-button>
    </div>

    <div class="settings-list" [formGroup]="formGroup">
      <vh-horizontal-divider [title]="'admin.pages.settings.generalSettings.authenticationTitle' | translate"></vh-horizontal-divider>

      <label>{{ 'admin.pages.settings.generalSettings.minimumPasswordLength' | translate }}</label>
        <vh-text-input
          class="text-input-s"
          type="number"
          [min]="MIN_PASSWORD_LENGTH"
          [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('sec_min_password_length')"
          [value]="institution.settings.secMinPasswordLength">
        </vh-text-input>


      <label>{{ 'admin.pages.settings.generalSettings.enforce2fa' | translate }}</label>
      <vh-switch
        [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('sec_2fa')"
        [value]="institution.settings.sec2Fa">
      </vh-switch>
    </div>

  </div>
</vh-content-layout>
