import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { Wallet } from '@models/wallet.model';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { WALLET_FORM_KEYS } from '@constants/form-keys/wallet-form-keys.constant';

@Injectable({
  providedIn: 'root',
})
export class WalletFormService extends AbstractFormService {
  private readonly DEFAULT_MIN_TOKENS_THRESHOLD_VALUE: number = 10;
  private readonly MIN_TOKENS_THRESHOLD: number = 1;

  createFormGroupFromWallet(wallet: Wallet = null): UntypedFormGroup {
    return this.formBuilder.group({
      [WALLET_FORM_KEYS.get('min_tokens_threshold')]: [
        wallet && wallet.minTokensThreshold > 0 ? wallet.minTokensThreshold : this.DEFAULT_MIN_TOKENS_THRESHOLD_VALUE,
        [
          Validators.required,
          Validators.min(this.MIN_TOKENS_THRESHOLD),
        ],
      ],
      [WALLET_FORM_KEYS.get('bucket_id')]: [
        wallet?.autoReplenishBucketId ?? null,
        [Validators.required],
      ],
    });
  }
}
