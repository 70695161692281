import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NAVIGATION } from '@constants/navigation.constant';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { CardType } from '@modules/settings/enums/card-type.enum';
import { getSettingsCardStatusOptions } from '@modules/settings/enums/settings-card-status.enum';

@Component({
  selector: 'vh-surveys-overview-page',
  templateUrl: './surveys-overview-page.component.html',
  styleUrls: ['./surveys-overview-page.component.scss'],
})
@UntilDestroy()
export class SurveysOverviewPageComponent implements OnInit {
  addRoute: string = NAVIGATION.adminDashboardSettings.createSurvey.route;
  editRoute: string = NAVIGATION.adminDashboardSettings.updateSurvey.route;
  cardType: CardType = CardType.SURVEY;
  surveyStatusOptions: Option[];

  constructor(
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.surveyStatusOptions = getSettingsCardStatusOptions().map((option: Option): Option => ({
      ...option,
      label: this.translate.instant(option.label),
    }));
  }

}
