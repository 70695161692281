import { Answer, AnswerJson } from '@models/answer.model';
import { User, UserJson } from '@models/user.model';

export class AnswerList {
  constructor(
    private _id: string,
    private _visitId: number,
    private _name: string,
    private _description: string,
    private _answers: Answer[],
    private _status: string,
    private _argumentation: string,
    private _internalArgumentation: string,
    private _userId?: string,
    private _completedAt?: string,
    private _patient?: User,
    private _visitName?: string
  ) {}

  get id(): string {
    return this._id;
  }

  get visitId(): number {
    return this._visitId;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get answers(): Answer[] {
    return this._answers;
  }

  set answers(answers: Answer[]) {
    this._answers = answers;
  }

  get status(): string {
    return this._status;
  }

  get argumentation(): string {
    return this._argumentation;
  }

  get internalArgumentation(): string {
    return this._internalArgumentation;
  }

  get completedAt(): string {
    return this._completedAt;
  }

  get userId(): string {
    return this._userId;
  }

  get patient(): User {
    return this._patient;
  }

  get visitName(): string {
    return this._visitName;
  }

  static fromJson(json: AnswerListJson): AnswerList {
    return json
      ? new AnswerList(
        json.id,
        json.visit_id,
        json.name,
        json.description,
        json?.answers ? json?.answers.map(Answer.fromJson) : [],
        json.status,
        json.argumentation,
        json.internal_argumentation,
        json.user_id,
        json.completed_at,
        json?.patient ? User.fromJson(json?.patient) : null,
        json.visit_name
      )
      : null;
  }
}

export interface AnswerListJson {
  id: string;
  visit_id: number;
  name: string;
  description: string;
  answers: AnswerJson[];
  status: string;
  argumentation: string;
  internal_argumentation: string;
  user_id?: string;
  completed_at?: string;
  patient?: UserJson;
  visit_name: string;
}
