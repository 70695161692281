import { Injectable } from '@angular/core';
import { ModuleKey } from '@enums/module-key.enum';
import { Module } from '@models/module.model';
import { AuthenticationService } from '@services/authentication.service';
import { Role } from '@enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  constructor(private readonly authenticationService: AuthenticationService) {}

  hasAccessToModule(key: ModuleKey): boolean {
    if (!this.authenticationService.isLoggedIn()) {
      return false;
    }

    if (this.authenticationService.currentUser.hasRole(Role.ADMIN)) {
      return true;
    }

    const institutionModules: Module[] = this.authenticationService.institution?.modules ?? [];
    const allowedModule: Module = institutionModules.find((m: Module): boolean => m.key === key.toString()) ?? null;

    return allowedModule?.accessUntil >= new Date();
  }

  hasAccessToModules(keys: ModuleKey[]): boolean {
    return keys.every((key: ModuleKey) => this.hasAccessToModule(key));
  }
}
