import { ServiceTask } from '@models/service-task.model';
import { TaskJson } from '@models/task.model';
import { Option } from '@interfaces/option.interface';
import { ServiceType, ServiceTypeJson } from '@models/service-type.model';
import { TranslatedProperty } from '@models/translated-property.model';
import { HierarchicalPickerItem } from '@modules/booking/models/hierarchy-state.model';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

export class Service implements HierarchicalPickerItem {
  constructor(
    private _id: string,
    private _name: string,
    private _shortName: string,
    private _description: string,
    private _bodyPartNames: string[],
    private _code: string,
    private _duration: number,
    private _canBeBookedByPatient: boolean,
    private _isPublic: boolean,
    private _alternativeName: TranslatedProperty,
    private _type: ServiceType,
    private _parent: Service,
    private _children: Service[] = [],
    private _tasks: ServiceTask[] = [],
    private _blockedBy: Service[] = [],
    private _blocks: Service[] = []
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  /**
   * Returns the alternative name of the service. It is a custom name set by the institution.
   */
  get alternativeName(): TranslatedProperty {
    return this._alternativeName;
  }

  /**
   * Returns the short name of the service, most likely an abbreviation.
   */
  get shortName(): string {
    return this._shortName;
  }

  /**
   * Returns the alternative name of the service, with the default name as fallback.
   */
  get displayName(): string {
    return this._alternativeName ? this._alternativeName.toString() : this._name;
  }

  /**
   * Returns the display name of the service, including the parent service's name if available
   */
  get displayFullName(): string {
    if (this._parent) {
      return `${this._parent.displayFullName} - ${this.displayName}`;
    }

    return this.displayName;
  }

  get description(): string {
    return this._description;
  }

  get bodyPartNames(): string[] {
    return this._bodyPartNames;
  }

  get type(): ServiceType {
    return this._type;
  }

  get children(): Service[] {
    return this._children;
  }

  get blockedBy(): Service[] {
    return this._blockedBy;
  }

  get blocks(): Service[] {
    return this._blocks;
  }

  get tasks(): ServiceTask[] {
    return this._tasks;
  }

  get parent(): Service {
    return this._parent;
  }

  set parent(service: Service) {
    this._parent = service;
  }

  get code(): string {
    return this._code;
  }

  get duration(): number {
    return this._duration;
  }

  set duration(value: number) {
    this._duration = value;
  }

  get canBeBookedByPatient(): boolean {
    return this._canBeBookedByPatient;
  }

  get isPublic(): boolean {
    return this._isPublic;
  }

  findRootBlockedBy(parent?: Service): Service {
    parent = parent ?? this;

    if (parent.blockedBy.length === 0) {
      return parent;
    }

    let deepestChild: Service | null = null;

    for (const child of parent.blockedBy) {
      const childService = this.findRootBlockedBy(child);
      if (!deepestChild) {
        deepestChild = childService;
      }
    }

    return deepestChild;
  }

  findRootBlocks(parent?: Service): Service {
    parent = parent ?? this;

    if (parent.blocks.length === 0) {
      return parent;
    }

    let deepestChild: Service | null = null;

    for (const child of parent.blocks) {
      const childService = this.findRootBlocks(child);
      if (!deepestChild) {
        deepestChild = childService;
      }
    }

    return deepestChild;
  }

  isChildOf(parent: Service): boolean {
    return parent.children?.some((service: Service) => this.id === service.id);
  }

  static fromJson(json: ServiceJson): Service {
    if (!json) {
      return null;
    }

    const service: Service = new Service(
      json.id,
      json.name,
      json.short_name ?? null,
      json.description,
      json.body_part_names,
      json.code,
      json.duration,
      json.can_be_booked_by_patient,
      json.is_public,
      json.alternative_name ? new TranslatedProperty(json.alternative_name) : null,
      json.type ? ServiceType.fromJson(json.type) : null,
      json?.parent ? Service.fromJson(json?.parent) : null,
      json?.children ? json?.children?.map(Service.fromJson) : [],
      json?.tasks ? json?.tasks?.map(ServiceTask.fromJson) : [],
      json?.blocked_by ? json?.blocked_by?.map(Service.fromJson) : [],
      json?.blocks ? json?.blocks?.map(Service.fromJson) : []
    );

    if (service.children) {
      service.children.forEach((childService: Service) => childService.parent = service);
    }

    return service;
  }

  static dummy(): Service {
    return new Service(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
      true,
      new TranslatedProperty(null),
      null,
      null
    );
  }

  toJson(): SimpleServiceJson {
    return {
      id: this._id,
      code: this.code,
    };
  }

  toOption(useShortName: boolean = false): Option {
    return {
      label: useShortName && this.shortName ? this.shortName : this.name,
      value: this.id,
    };
  }

  toButtonOption(useShortName: boolean = false): ButtonOption {
    return {
      label: useShortName && this.shortName ? this.shortName : this.name,
      isActive: false,
    };
  }
}

export interface ServiceJson extends SimpleServiceJson {
  name: string;
  short_name?: string;
  description: string;
  body_part_names: string[];
  parent?: ServiceJson;
  duration?: number;
  can_be_booked_by_patient?: boolean;
  is_public?: boolean;
  alternative_name?: string;
  type: ServiceTypeJson;
  children: ServiceJson[];
  tasks: TaskJson[];
  blocks?: ServiceJson[];
  blocked_by?: ServiceJson[];
  linked_to?: ServiceJson[];
}

export interface SimpleServiceJson {
  id: string;
  code?: string;
}

