import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { FilterVisitsSidebarComponent } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/filter-visits-sidebar.component';
import { FormModule } from '@modules/shared/form/form.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [FilterVisitsSidebarComponent];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
  ],
  exports: sharedIngredients,
})
export class AdminSharedModule { }
