import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DateCardLayout } from '@modules/shared/cards/enums/date-card-layout.enum';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';

@Component({
  selector: 'vh-date-card',
  templateUrl: './date-card.component.html',
  styleUrls: ['./date-card.component.scss'],
})
export class DateCardComponent extends BreakpointObserverComponent implements OnInit, OnChanges {
  protected readonly DateCardLayout: typeof DateCardLayout = DateCardLayout;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() skeletonIsEmpty: boolean;
  @Input() date: Date;
  @Input() layout: DateCardLayout;
  @Input() backgroundColor: string;
  @Input() getBackgroundColorFunction: (date: Date) => string;

  dayTextTranslationKey: string;
  monthTextTranslationKey: string;

  ngOnInit(): void {
    if (this.date) {
      // Defaults
      this.layout = this.layout || DateCardLayout.PRIMARY;
      this.backgroundColor = this.backgroundColor || 'var(--color-background-grey)';

      this.updateDisplayedDate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dateChange: SimpleChange = changes.date;

    if (dateChange && dateChange.currentValue !== dateChange.previousValue) {
      this.updateDisplayedDate();
    }
  }

  private updateDisplayedDate = (): void => {
    if (this.layout === DateCardLayout.PRIMARY) {
      this.dayTextTranslationKey = `common.days.${format(this.date, DATE_FORMATS.dayNumber)}`;
      this.monthTextTranslationKey = `common.months.${format(this.date, DATE_FORMATS.monthNumber)}`;
    }

    if (this.getBackgroundColorFunction) {
      this.backgroundColor = this.getBackgroundColorFunction(this.date);
    }
  };
}
