import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '@modules/shared/form/components/base-input/base-input.component';

@Component({
  selector: 'vh-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaInputComponent),
      multi: true,
    },
  ],
})
export class TextareaInputComponent extends BaseInputComponent {
  @Input() resizable: boolean = true;
  @Input() wrapText: boolean = false;
  @Input() iconTopRightSrc: string;
  @Input() iconTopRightWidth: number;
  @Input() iconTopRightHeight: number;
  @Input() iconTopRightFill: string;
  @Input() iconTopRightHoverFill: string;
}
