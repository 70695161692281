import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@models/user.model';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { COLORS } from '@constants/colors.constant';
import { environment } from '@environments/environment';
import { WalletService } from '@services/wallet.service';
import { Wallet } from '@models/wallet.model';

@Component({
  selector: 'vh-create-conversation-select-patient-page',
  templateUrl: './create-conversation-select-patient-page.component.html',
  styleUrls: ['./create-conversation-select-patient-page.component.scss'],
})
@UntilDestroy()
export class CreateConversationSelectPatientPageComponent implements OnInit {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly COLORS: typeof COLORS = COLORS;

  query: string = '';
  selectedUser: User | null = null;
  users: User[] = [];
  queryControl: FormControl = new FormControl('');
  lowTokensAmount: number = environment.app.lowTokensAmount;
  currentUserTokens: number;
  isLoading: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly conversationService: CreateConversationService,
    private readonly walletService: WalletService
  ) {
    this.conversationService.setStep(1);
  }

  ngOnInit(): void {
    this.reloadUser();
  }

  onPatientSelected(user: User): void {
    this.selectedUser = user;
    this.conversationService.user = user;
  }

  addNewPatient(): void {
    void this.router.navigate([CONVERSATION_NAV.create.newPatient.route]);
  }

  goToNextStep(): void {
    const missingInformation = this.selectedUser.email == null || this.selectedUser.phone == null;

    if (missingInformation) {
      void this.router.navigate([CONVERSATION_NAV.create.missingInfo.route]);

      return;
    }

    void this.router.navigate([CONVERSATION_NAV.create.planning.route]);
  }

  private reloadUser(): void {
    // We reload the user for good measure as it contains the wallet balance
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.currentUserTokens = wallet?.tokens;
      });
  }
}
