import { Injectable } from '@angular/core';
import { IObject } from '@app-types/iobject.type';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(private readonly http: HttpClient) {}

  forgotPassword$ = (username: string): Observable<void> => {
    const body: IObject = {
      username: username,
    };

    return this.http.post(environment.apiBaseUrl + ENDPOINTS.forgotPassword.route, body).pipe(switchToEmptyObservable());
  };

  resetPassword$ = (username: string, password: string, repeatNewPassword: string, token: string): Observable<void> => {
    const body: IObject = {
      username: username,
      password: password,
      password_confirmation: repeatNewPassword,
      token: token,
    };

    return this.http.post(environment.apiBaseUrl + ENDPOINTS.resetPassword.route, body).pipe(switchToEmptyObservable());
  };

  updatePassword$ = (currentPassword: string, newPassword: string, repeatNewPassword: string): Observable<void> => {
    const body: IObject = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: repeatNewPassword,
    };

    return this.http.post(environment.apiBaseUrl + ENDPOINTS.updatePassword.route, body).pipe(switchToEmptyObservable());
  };
}
