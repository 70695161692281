import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'vh-icon',
  templateUrl: './icon.component.html',
  styleUrls: [],
})
export class IconComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() fill: string;
  @Input() stroke: string;
  @Input() hoverFill: string;
  @Input() hoverStroke: string;
  @Input() ariaLabel: string;
  @Input() disabled: boolean;

  @Input() @HostBinding('style.line-height') lineHeight: number;
  @Input() @HostBinding('style.width') width: number;
  @Input() @HostBinding('style.height') height: number;

  @HostBinding('style.width') hostWidth: string;
  @HostBinding('style.height') hostHeight: string;

  isHoveringOver: boolean;
  svgStyle: Record<string, unknown> = {};

  ngOnInit(): void {
    this.svgStyle = {
      display: 'block',
    };
    this.applyDimensions();
    this.applyHoverStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.width?.isFirstChange() || !changes.height?.isFirstChange()) {
      this.applyDimensions();
    }

    if (!changes.fill?.isFirstChange() || !changes.hoverStroke?.isFirstChange()) {
      this.applyHoverStyle();
    }
  }

  applyDimensions(): void {
    if (this.width) {
      this.svgStyle = { ...this.svgStyle, width: `${this.width}px` };
      this.hostWidth = `${this.width}`;
    }

    if (this.height) {
      this.svgStyle = { ...this.svgStyle, height: `${this.height}px` };
      this.hostHeight = `${this.height}`;
    }
  }

  applyHoverStyle(): void {
    if (!this.fill && !this.hoverFill && !this.stroke && !this.hoverStroke) {
      return;
    }

    if (this.hoverFill && this.isHoveringOver) {
      this.svgStyle = { ...this.svgStyle, fill: this.hoverFill };

      return;
    }

    if (this.hoverStroke && this.isHoveringOver) {
      this.svgStyle = { ...this.svgStyle, stroke: this.hoverStroke };

      return;
    }

    this.svgStyle = { ...this.svgStyle, fill: this.fill, stroke: this.stroke };
  }
}
