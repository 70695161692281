import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseSettingsPageComponent } from '@modules/settings/pages/base-settings-page/base-settings-page.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'vh-security-settings-page',
  templateUrl: './security-settings-page.component.html',
  styleUrls: ['./security-settings-page.component.scss'],
})
@UntilDestroy()
export class SecuritySettingsPageComponent extends BaseSettingsPageComponent implements OnInit {
  protected readonly MIN_PASSWORD_LENGTH: number = environment.app.minPasswordLength;

  resetForm(): void {
    this.formGroup = this.institutionSettingsFormService.createSecuritySettingsFormGroup(this.institution.settings);
  }
}
