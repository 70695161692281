export const COLORS = {
  white: '#ffffff',
  primary: '#667bf8',
  secondary: '#333333',
  tertiary: '#a8a8a8',
  alert: '#f46161',
  success: '#a1e887',
  warning: '#ffa739',
  error: '#de3c4b',
  backgroundPrimary: '#f2f2f2',
  backgroundSecondary: '#fafafa',
  backgroundGrey: '#a8a8a829',
  backgroundDarkGrey: '#0000003d',
  backgroundGreen: '#667bf829',
  backgroundGreenLight: '#667bf80A',
  backgroundWarning: '#ff9c3921',
  border: '#f1f1f1',
  googleMapsUserLocation: '#5384ED',
  statusRemoved: '#de3c4b',
  statusPending: '#fe7f2d',
  statusOngoing: '#ffaf2d',
  statusFinished: '#80c665',
  statusExpired: '#f6a6ae',
};
