import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { REGEX } from '@constants/regex.constant';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-password-feedback',
  templateUrl: './password-feedback.component.html',
  styleUrls: ['./password-feedback.component.scss'],
})
export class PasswordFeedbackComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() form: FormGroup;
  @Input() passwordControlName: string;
  @Input() repeatPasswordControlName: string;

  rules: { name: string; regex: RegExp; }[] = [
    { name: 'pages.register.passwordFeedback.length', regex: new RegExp(REGEX.eightCharacters) },
    { name: 'pages.register.passwordFeedback.uppercase', regex: new RegExp(REGEX.oneUppercaseLetter) },
    { name: 'pages.register.passwordFeedback.lowercase', regex: new RegExp(REGEX.oneLowercaseLetter) },
    { name: 'pages.register.passwordFeedback.number', regex: new RegExp(REGEX.oneNumber) },
  ];

  checkRule(regex: RegExp): boolean {
    const password = this.form.get(this.passwordControlName)?.value || '';

    return regex.test(password);
  }

  passwordsMatch(): boolean {
    const password = this.form.get(this.passwordControlName)?.value || '';
    const repeatPassword = this.form.get(this.repeatPasswordControlName)?.value || '';

    return password !== '' && repeatPassword !== '' && password === repeatPassword;
  }

  getIconPath(value: boolean): string {
    return value ? 'assets/icons/check.svg' : 'assets/icons/cross.svg';
  }

  getIconColor(path: string, value: boolean, boundTo: 'stroke' | 'fill'): string | null {
    const shouldFill = this.shouldIconUseFill(path);

    if (shouldFill && boundTo === 'fill' || !shouldFill && boundTo === 'stroke') {
      return value ? COLORS.success : COLORS.error;
    }

    return null;
  }

  shouldIconUseFill(iconPath: string): boolean {
    return iconPath === 'assets/icons/check.svg';
  }
}
