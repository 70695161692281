/*
 * Values must match with the API
 */
export enum QuestionType {
  INSTRUCTION = 'INSTRUCTION',
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  DATE = 'DATE',
  RADIO = 'RADIO',
  DESCRIPTION = 'DESCRIPTION',
  CHECKBOX = 'CHECKBOX',
}

export function getQuestionTypeLabelKey(questionType: QuestionType): string {
  switch (questionType) {
    case QuestionType.DESCRIPTION:
      return 'admin.pages.settings.surveys.create.description';
    case QuestionType.INSTRUCTION:
      return 'admin.pages.settings.surveys.create.instruction';
    case QuestionType.BOOLEAN:
      return 'admin.pages.settings.surveys.create.yesNoQuestion';
    case QuestionType.TEXT:
      return 'admin.pages.settings.surveys.create.openQuestion';
    case QuestionType.DATE:
      return 'admin.pages.settings.surveys.create.date';
    case QuestionType.RADIO:
      return 'admin.pages.settings.surveys.create.singleChoice';
    case QuestionType.CHECKBOX:
      return 'admin.pages.settings.surveys.create.multipleChoice';
  }
}
