export enum ContactReason {
  FLYERS,
  DEMO,
  QUESTION,
}

export function toString(reason: ContactReason): string {
  return ContactReason[reason].toLowerCase();
}

export function fromString(reason: string): ContactReason {
  return ContactReason[reason.toUpperCase()];
}
