import { IObject } from '@app-types/iobject.type';
import { ConversationStatus } from '@enums/conversation-status.enum';
import { User, UserJson } from '@models/user.model';

export class ConversationChat {
  constructor(
    private _id: string,
    private _patient: User,
    private _role: string,
    private _pathology: string,
    private _questionList: string,
    private _language: string,
    private _chatbotRole: string,
    private _activeWhatsapp: boolean,
    private _createdAt: Date,
    private _transcript: IObject[],
    private _status: ConversationStatus,
    private _version: string,
    private _chatbotConclusion: string | null = null,
    private _dueDate: Date | null = null
  ) {}

  get id(): string {
    return this._id;
  }

  get patient(): User {
    return this._patient;
  }

  get role(): string {
    return this._role;
  }

  get pathology(): string {
    return this._pathology;
  }

  get questionList(): string {
    return this._questionList;
  }

  get version(): string {
    return this._version;
  }

  get language(): string {
    return this._language;
  }

  get chatbotRole(): string {
    return this._chatbotRole;
  }

  get chatbotConclusion(): string | null {
    return this._chatbotConclusion;
  }

  get activeWhatsapp(): boolean {
    return this._activeWhatsapp;
  }

  get dueDate(): Date | null {
    return this._dueDate;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get transcript(): IObject[] {
    return this._transcript;
  }

  get status(): ConversationStatus {
    return this._status;
  }

  get isInactive(): boolean {
    return this.status === ConversationStatus.FINISHED ||
      this.status === ConversationStatus.EXPIRED ||
      this.status === ConversationStatus.REMOVED;
  }

  static fromJson(json: ConversationChatJson): ConversationChat {
    return json
      ? new ConversationChat(
        json.id,
        User.fromJson(json.patient),
        json.role,
        json.pathology,
        json.questions,
        json.language,
        json.chatbot_role,
        json.active_whatsapp,
        new Date(json.created_at),
        json.transcript,
        json.status,
        json.version,
        json.chatbot_conclusion,
        json.due_date ? new Date(json.due_date) : null
      )
      : null;
  }
}

export interface ConversationChatJson {
  id: string;
  role: string;
  pathology: string;
  questions: string;
  language: string;
  chatbot_role: string;
  chatbot_conclusion: string | null;
  version: string;
  active_whatsapp: boolean;
  patient: UserJson;
  due_date: string | null;
  created_at: string;
  transcript: IObject[];
  status: ConversationStatus;
}
