import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { ButtonStyle } from '@enums/button-style.enum';
import { UntypedFormGroup } from '@angular/forms';
import { COLORS } from '@constants/colors.constant';
import { CREATE_ROOM_FORM_KEYS } from '@constants/form-keys/create-room-form-keys.constant';

@Component({
  selector: 'vh-assign-service-group-to-room',
  templateUrl: './assign-service-group-to-room.component.html',
  styleUrls: ['./assign-service-group-to-room.component.scss'],
})
export class AssignServiceGroupToRoomComponent {
  COLORS: typeof COLORS = COLORS;
  ButtonStyle: typeof ButtonStyle = ButtonStyle;
  CREATE_ROOM_FORM_KEYS: typeof CREATE_ROOM_FORM_KEYS = CREATE_ROOM_FORM_KEYS;

  @Input() options: Option[];
  @Input() formGroup: UntypedFormGroup;

  @Output() deleteItemClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() serviceGroupSelected: EventEmitter<string> = new EventEmitter<string>();
}
