import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  private _togglePopover$: Subject<string> = new Subject<string>();

  private _openPopovers: Set<string> = new Set<string>();

  get togglePopover$(): Observable<string> {
    return this._togglePopover$.asObservable();
  }

  get openPopovers(): Set<string> {
    return this._openPopovers;
  }

  open(id: string): void {
    if (this.isOpen(id)) {
      this.close(id);
    }

    this.toggle(id);
  }

  close(id: string): void {
    if (!this.isOpen(id)) {
      return;
    }

    this.toggle(id);
  }

  toggle(id: string): void {
    this._togglePopover$.next(id);

    if (this.openPopovers.has(id)) {
      this.openPopovers.delete(id);
    } else {
      this.openPopovers.add(id);
    }
  }

  isOpen(id: string): boolean {
    return this.openPopovers.has(id);
  }
}
