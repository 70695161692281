import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { Visit } from '@models/visit.model';
import { Task } from '@models/task.model';
import { Document, DocumentJson } from '@models/document.model';
import { map } from 'rxjs/operators';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';

@Injectable({
  providedIn: 'root',
})
export class DocumentTaskService {
  constructor(private readonly http: HttpClient) {}

  uploadDocumentTask$ = (visit: Visit, task: Task, files: FileList): Observable<Document[]> => {
    const endpoint: string = ENDPOINTS.completeDocumentAppointmentTask.route
      .replace(
        `{${ENDPOINTS.completeDocumentAppointmentTask.pathParams.visitId}}`,
        visit.id
      )
      .replace(
        `{${ENDPOINTS.completeDocumentAppointmentTask.pathParams.taskId}}`,
        task.id
      );

    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      formData.append('document[]', files[i]);
    }

    return this.http
      .post(environment.apiBaseUrl + endpoint, formData, { reportProgress: true })
      .pipe(map((json: DocumentJson[]): Document[] => json.map(Document.fromJson)));
  };

  updateDocumentTask$ = (visit: Visit, task: Task, document: Document, file: File = null, description: string = null): Observable<Document> => {
    const endpoint: string = ENDPOINTS.updateDocumentAppointmentTask.route
      .replace(
        `{${ENDPOINTS.updateDocumentAppointmentTask.pathParams.visitId}}`,
        visit.id
      )
      .replace(
        `{${ENDPOINTS.updateDocumentAppointmentTask.pathParams.taskId}}`,
        task.id
      )
      .replace(
        `{${ENDPOINTS.updateDocumentAppointmentTask.pathParams.documentId}}`,
        document.id
      );

    const formData = new FormData();

    if (file) {
      formData.append('document', file);
    }

    if (description) {
      formData.append('description', description);
    }

    return this.http
      .post(environment.apiBaseUrl + endpoint, formData)
      .pipe(map((json: DocumentJson): Document => Document.fromJson(json)));
  };

  getDocumentFile$ = (visit: Visit, task: Task, document: Document): Observable<ArrayBuffer> => {
    const endpoint: string = ENDPOINTS.getDocumentAppointmentTask.route
      .replace(
        `{${ENDPOINTS.getDocumentAppointmentTask.pathParams.visitId}}`,
        visit.id
      )
      .replace(
        `{${ENDPOINTS.getDocumentAppointmentTask.pathParams.taskId}}`,
        task.id
      )
      .replace(
        `{${ENDPOINTS.getDocumentAppointmentTask.pathParams.documentId}}`,
        document.id
      );

    return this.http
      .get(environment.apiBaseUrl + endpoint, { responseType: 'arraybuffer' });
  };

  deleteDocumentTask$ = (visit: Visit, task: Task, document: Document): Observable<void> => {
    const endpoint: string = ENDPOINTS.deleteDocumentAppointmentTask.route
      .replace(
        `{${ENDPOINTS.deleteDocumentAppointmentTask.pathParams.visitId}}`,
        visit.id
      )
      .replace(
        `{${ENDPOINTS.deleteDocumentAppointmentTask.pathParams.taskId}}`,
        task.id
      )
      .replace(
        `{${ENDPOINTS.deleteDocumentAppointmentTask.pathParams.documentId}}`,
        document.id
      );

    return this.http
      .delete(environment.apiBaseUrl + endpoint)
      .pipe(switchToEmptyObservable());
  };
}
