import { Option } from '@interfaces/option.interface';

export class VisitCancellationAction {
  constructor(private _id: string, private _name: string) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  static fromJson(json: VisitCancellationActionJson): VisitCancellationAction {
    return new VisitCancellationAction(json.id, json.name);
  }

  toOption(): Option {
    return {
      value: this.id,
      label: this.name,
    };
  }
}

export interface VisitCancellationActionJson {
  id: string;
  name: string;
}
