import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { Visit } from '@models/visit.model';
import { COLORS } from '@constants/colors.constant';
import { VisitService } from '@services/visits/visit.service';
import { addMinutes, format, parse } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MASKS } from '@constants/masks.constant';
import { PROFILE_FORM_KEYS } from '@constants/form-keys/profile-form-keys.constant';
import { UserFormService } from '@services/form-services/user-form.service';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '@services/user.service';
import { User } from '@models/user.model';
import { TextInputStyle } from '@enums/text-input-style.enum';
import { VisitFormService } from '@services/form-services/visit-form.service';
import { VISIT_FORM_KEYS } from '@constants/form-keys/visit-form-keys.constant';
import { ServiceService } from '@services/service.service';
import { Service } from '@models/service.model';
import { debounce } from '@utils/decorators/debounce.decorator';
import { PopoverService } from '@services/ui/popover.service';
import { VisitStatusService } from '@services/visits/visit-status.service';
import { Option } from '@interfaces/option.interface';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { CancelVisitDialogComponent, CancelVisitDialogResult } from '@modules/settings/components/cancel-visit-dialog/cancel-visit-dialog.component';
import { getEditorRoles, getInstitutionRoles, getAdminRoles, Role } from '@enums/role.enum';
import { VisitCancellationService } from '@services/visits/visit-cancellation.service';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { AuthenticationService } from '@services/authentication.service';
import { Mode } from '@enums/mode.enum';
import { fadeInOutAnimation } from '@modules/shared/core/animations/fade/fade-in-out.animation';
import { ToastService } from '@services/ui/toast.service';

export interface OptionalVisitDetailsDialogData {
  isReadOnly?: boolean;
}

export interface VisitDetailsDialogData extends OptionalVisitDetailsDialogData {
  visit: Visit;
  visitStatusOptions: Option[];
}

export interface VisitDetailsDialogResult {
  canceledVisit: Visit | null;
  visitChanged: Visit | null;
}

@Component({
  selector: 'vh-visit-details-dialog',
  templateUrl: './visit-details-dialog.component.html',
  styleUrls: ['./visit-details-dialog.component.scss'],
  animations: [fadeInOutAnimation],
})
@UntilDestroy()
export class VisitDetailsDialogComponent implements OnInit {
  protected readonly getInstitutionRoles: () => Role[] = getInstitutionRoles;
  protected readonly getEditorRoles: () => Role[] = getEditorRoles;
  protected readonly getAdminRoles: () => Role[] = getAdminRoles;

  protected readonly MASKS: typeof MASKS = MASKS;
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly PROFILE_FORM_KEYS: typeof PROFILE_FORM_KEYS = PROFILE_FORM_KEYS;
  protected readonly VISIT_FORM_KEYS: typeof VISIT_FORM_KEYS = VISIT_FORM_KEYS;
  protected readonly DATE_BOUNDARIES: typeof DATE_BOUNDARIES = DATE_BOUNDARIES;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly TextInputStyle: typeof TextInputStyle = TextInputStyle;

  currentDate: string;

  patientViewMode: 'read' | 'edit' = 'read';
  patientFormGroup: UntypedFormGroup;

  isUpdatingPatient: boolean;
  isLoading: boolean;
  isClosing: boolean = false;

  visitViewMode: 'read' | 'edit' = 'read';
  visitFormGroup: UntypedFormGroup;

  showSavedStatusSuccessfullyMessage: boolean;

  isSavingComment: boolean;
  showSavedCommentSuccessfullyMessage: boolean;

  showSavedPaymentSuccessfullyMessage: boolean;

  rescheduleVisitDuration: { start: Date; end: Date; };

  visit: Visit;
  isReadOnly: boolean;
  visitStatusOptions: Option[];

  cancellationButtonTooltip: string | null = null;

  didUpdateVisit: boolean = false;

  @Output() visitUpdated: EventEmitter<Visit> = new EventEmitter<Visit>();

  constructor(
    private readonly visitService: VisitService,
    private readonly serviceService: ServiceService,
    private readonly userFormService: UserFormService,
    private readonly userService: UserService,
    private readonly visitFormService: VisitFormService,
    private readonly popoverService: PopoverService,
    private readonly toastService: ToastService,
    private readonly visitStatusService: VisitStatusService,
    private readonly alertDialogService: AlertDialogService,
    private readonly visitCancellationService: VisitCancellationService,
    private readonly authenticationService: AuthenticationService,
    private readonly dialogService: MatDialog,
    protected readonly dialogRef: MatDialogRef<VisitDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: VisitDetailsDialogData
  ) {
    this.visit = data.visit;
    this.visitStatusOptions = data.visitStatusOptions;
    this.isReadOnly = data.isReadOnly;
  }

  ngOnInit(): void {
    this.currentDate = format(new Date(), DATE_FORMATS.serverDate);
    this.patientFormGroup = this.userFormService.createFormGroup(this.visit?.patient);
    this.visitFormGroup = this.visitFormService.createUpdateVisitTimeFormGroup(this.visit);

    // This is needed to close the dialog with a result when the user clicks outside of it
    this.dialogRef.beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.isClosing) {
          this.close();
        }
      });
    this.fetchServiceDuration();
    this.fetchVisitDetails(true);
  }

  close(didCancelVisit: boolean = false): void {
    this.isClosing = true;
    this.dialogRef.close({ canceledVisit: didCancelVisit ? this.visit : null, visitChanged: this.didUpdateVisit ? this.visit : null });
  }

  onCancelVisitClicked(): void {
    if (this.authenticationService.mode === Mode.PRIVATE) {
      this.onCancelVisitClickedAsPatient();

      return;
    }

    this.onCancelVisitClickedAsAdmin();
  }

  onEditPatientClicked(): void {
    this.patientViewMode = 'edit';
    this.patientFormGroup = this.userFormService.createFormGroup(this.visit.patient);
  }

  onCancelEditPatientClicked(): void {
    this.patientViewMode = 'read';
    this.patientFormGroup = this.userFormService.createFormGroup(this.visit.patient);
  }

  onSavePatientClicked(): void {
    this.isUpdatingPatient = true;
    this.userService.updateUser$(this.visit.patient.id, this.patientFormGroup.value)
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this.isUpdatingPatient = false;
        this.visit.patient = user;
        this.patientViewMode = 'read';
        this.didUpdateVisit = true;
      });
  }

  onResendEmailClicked(): void {
    this.visitService.resendVisitConfirmationEmail$(this.visit.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastService.showInfo('admin.pages.dashboard.visitDialog.emailIsSuccessfulResend', 'common.ok', { duration: 5000 }).subscribe();
        },
        error: () => {
          this.toastService.showError('common.errors.generic').subscribe();
        },
      });
  }

  onEditVisitClicked(): void {
    this.visitViewMode = 'edit';
    this.visitFormGroup = this.visitFormService.createUpdateVisitTimeFormGroup(this.visit);
  }

  onCancelEditVisitClicked(): void {
    this.visitViewMode = 'read';
    this.visitFormGroup = this.visitFormService.createUpdateVisitTimeFormGroup(this.visit);
    this.popoverService.close('reschedule');
  }

  onShowVisitStatusHistoryClicked(): void {
    this.popoverService.open('status_history');
  }

  @debounce(500)
  onCommentChanged(value: string): void {
    this.showSavedCommentSuccessfullyMessage = false;
    this.isSavingComment = true;

    this.visitService.updateVisit$(this.visit.id, { internal_comment: value })
      .pipe(untilDestroyed(this))
      .subscribe((visit: Visit) => {
        this.isSavingComment = false;
        this.showSavedCommentSuccessfullyMessage = true;
        this.visit = visit;
        this.didUpdateVisit = true;
        setTimeout(() => {
          this.showSavedCommentSuccessfullyMessage = false;
        }, 5000);
      });
  }

  onVisitDateTimeChanged(): void {
    this.rescheduleVisitDuration = this.calculateNewStartAndEndDateTimeOfVisit();
    this.popoverService.open('reschedule');
  }

  onVisitDateTimeSaved(visit: Visit): void {
    this.rescheduleVisitDuration = null;

    this.visit = visit;
    this.visitFormGroup = this.visitFormService.createUpdateVisitTimeFormGroup(this.visit);

    this.popoverService.close('reschedule');
    this.visitViewMode = 'read';

    this.didUpdateVisit = true;

    this.visitUpdated.emit(visit);
  }

  onVisitStatusSelected(statusId: string): void {
    this.visitStatusService.addStatusToVisit$(this.visit.id, statusId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.showSavedStatusSuccessfullyMessage = true;
        this.fetchVisitDetails(false);
        setTimeout(() => {
          this.showSavedStatusSuccessfullyMessage = false;
        }, 5000);
      });
  }

  onPaymentToggled(): void {
    const isPaid: boolean = !this.visit.paymentConfirmedAt;

    if (isPaid) {
      this.visit.paymentConfirmedAt = new Date();
      this.onUpdatedPaymentStatusConfirmed(isPaid);

      return;
    }

    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.dashboard.visitDialog.undoPaymentDialog.title',
      messageTranslationKey: 'admin.pages.dashboard.visitDialog.undoPaymentDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.onUpdatedPaymentStatusConfirmed(isPaid);
        }
      });
  }

  private onCancelVisitClickedAsAdmin(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      visit: this.visit,
      panelClass: 'dialog-size-medium',
    };

    this.dialogService.open(CancelVisitDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result: CancelVisitDialogResult) => {
        if (result.status === 'ok') {
          this.close(true);
        }
      });
  }

  private onCancelVisitClickedAsPatient(): void {
    this.alertDialogService.open({
      titleTranslationKey: 'admin.pages.dashboard.cancelVisitDialog.title',
      messageTranslationKey: 'admin.pages.dashboard.cancelVisitDialog.message',
      confirmTextTranslationKey: 'common.yes',
      cancelTextTranslationKey: 'common.no',
    })
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed: boolean) => {
        if (!isConfirmed) {
          return;
        }

        this.visitCancellationService.cancelVisit$(this.visit.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.close(true);
          });
      });
  }

  private onUpdatedPaymentStatusConfirmed(isPaid: boolean): void {
    this.visitService.updateVisit$(this.visit.id, { payment_was_confirmed: isPaid })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (visit: Visit): void => {
          this.visit = visit;
          this.showSavedPaymentSuccessfullyMessage = true;
          this.didUpdateVisit = true;
          setTimeout(() => {
            this.showSavedPaymentSuccessfullyMessage = false;
          }, 5000);
        },
        error: (): void => {
          this.visit.paymentConfirmedAt = null;
        },
      });
  }

  private fetchServiceDuration(): void {
    if (!this.visit?.service?.id) {
      return;
    }

    this.serviceService.getServiceByIdOfInstitution$(this.visit.institution?.id, this.visit.service.id)
      .pipe(untilDestroyed(this))
      .subscribe((service: Service | null) => {
        if (!service) {
          return;
        }

        this.visit.service.duration = service.duration;
      });
  }

  private fetchVisitDetails(shouldShowLoadingSkeleton: boolean = true): void {
    this.isLoading = shouldShowLoadingSkeleton;
    // Fetch details of the visit which are only available after fetching by id
    this.visitService.getVisitById$(this.visit.id)
      .pipe(untilDestroyed(this))
      .subscribe((visit: Visit) => {
        this.visit = visit;
        this.didUpdateVisit = true;
        this.visitCancellationService.getVisitNotCancellableReason(this.visit)
          .pipe(untilDestroyed(this))
          .subscribe((reason: string | null) => {
            this.cancellationButtonTooltip = reason;
            this.isLoading = false;
          });
      });
  }

  private calculateNewStartAndEndDateTimeOfVisit(): { start: Date; end: Date; } {
    const newStart: Date = parse(
      `${this.visitFormGroup.get(VISIT_FORM_KEYS.get('startDate')).value} ${this.visitFormGroup.get(VISIT_FORM_KEYS.get('startTime')).value}`,
      DATE_FORMATS.serverDateTimeShort,
      new Date()
    );
    const newEnd: Date = addMinutes(newStart, this.visit.service.duration);

    return { start: newStart, end: newEnd };
  }
}
