<vh-centered-card-page>
  <vh-message
    textClass="has-text-primary font-light-14-17"
    [text]="'pages.forgotUsername.loginButtonLabel' | translate"
    [iconLeftPath]="'assets/icons/chevron-left.svg'"
    [iconLeftStroke]="COLORS.primary"
    [routerLink]="NAVIGATION.login.route">
  </vh-message>
  <div [vhMarginTop]="32" class="content-container flex-col-l-c">
    <form class="properties-grid gap-h-16 gap-v-8" [formGroup]="formGroup">
      <p class="has-text-primary">{{ 'common.fields.password' | translate }}</p>
      <vh-password-input autocomplete="current-password" [formControlName]="UPDATE_PASSWORD_FORM_KEYS.get('current_password')"></vh-password-input>
      <p class="has-text-primary">{{ 'common.fields.newPassword' | translate }}</p>
      <vh-password-input autocomplete="new-password" [formControlName]="UPDATE_PASSWORD_FORM_KEYS.get('new_password')"></vh-password-input>
      <p class="has-text-primary">{{ 'common.fields.repeatNewPassword' | translate }}</p>
      <vh-password-input [formControlName]="UPDATE_PASSWORD_FORM_KEYS.get('new_password_confirmation')" (keydown.enter)="onSaveClicked()"></vh-password-input>
    </form>

    <div [vhMarginTop]="32" class="actions-container flex-row-c-c gap-h-16">
      <vh-button
        [label]="'common.save' | translate"
        [isDisabled]="updatePasswordSubscription && !updatePasswordSubscription?.closed"
        (buttonClick)="onSaveClicked()"
        vhRunFormValidation
        [runFormValidationOn]="formGroup">
      </vh-button>
      <vh-button
        [label]="'common.cancel' | translate"
        [isDisabled]="updatePasswordSubscription && !updatePasswordSubscription?.closed"
        [style]="ButtonStyle.TERTIARY"
        (buttonClick)="onCancelClicked()">
      </vh-button>
    </div>
  </div>

</vh-centered-card-page>
