import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { BookingFlow } from '@enums/booking-flow.enum';
import { PendingBooking } from '@models/pending-booking.model';
import { AuthenticationService } from '@services/authentication.service';
import { Institution } from '@models/institution.model';
import { BookingFlowResolverService } from '@modules/booking/services/booking-flow-resolver.service';
import { BookingFlowInitiatorService } from '@modules/booking/services/booking-flow-initiator.service';
import { convertObjectKeysFromCamelToSnakeCase, removePrefixesFromObjectKeys } from '@utils/helpers/object.util';

/**
 * This resolver is responsible for creating a new pending booking and navigating to the update flow of said booking.
 * @param route
 * @param state
 */
export const createBookingResolver: ResolveFn<Observable<void>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const initiator: BookingFlowInitiatorService = inject(BookingFlowInitiatorService);

    const flow: BookingFlow = route.data.flow as BookingFlow;

    if (flow === BookingFlow.PUBLIC) {
      return initiator.initialise$(flow);
    }

    if (flow === BookingFlow.UNIQUE_LINK) {
      const institutionSlug = route.params.institutionSlug ?? null;

      // If we navigated to the unique link of the institution, but it's the same as the current institution,
      // there is no need to re-fetch the institution thus we can start the flow with one less network call
      if (institutionSlug !== null && institutionSlug === authenticationService.institution?.slug) {
        return initiator.initialise$(flow, authenticationService.institution);
      }

      return initiator.initialise$(flow, institutionSlug);
    }

    return initiator.initialise$(flow, authenticationService.institution);
  };

export const updateBookingResolver: ResolveFn<Observable<void>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> => {
    const resolver: BookingFlowResolverService = inject(BookingFlowResolverService);
    const router: Router = inject(Router);

    // This if-statement will be hit if we navigate from within the application to the booking flow
    // Some parameters will be passed via navigation and doesn't require to be fetched again from the server
    if (router.getCurrentNavigation().extras.state) {
      const pendingBooking: PendingBooking | null = getPendingBookingFromNavigationState(router);
      const institution: Institution | null = getInstitutionFromNavigationState(router);
      const flow: BookingFlow = route.data.flow as BookingFlow;

      return resolver.restore(flow, pendingBooking, institution?.id ?? null);
    }

    // If we got here, this means we probably navigated from somewhere outside Vesalius (for example via the unique link or continuing the flow from desktop to mobile)
    // This means we need to fetch and restore all data
    return resolver.restoreFromPendingBookingId(resolver.getPendingBookingIdFromRoute(route));
  };

/**
 * Here we extract the pending booking from the navigation state.
 * There is one caveat, when reloading the page the state does keep the data, but the data is not a proper instance of the class anymore. This function handles this case as well.
 * @param router
 */
const getPendingBookingFromNavigationState: (router: Router) => PendingBooking = (router: Router): PendingBooking | null => {
  let pendingBooking = router.getCurrentNavigation().extras.state.pendingBooking;

  if (pendingBooking && !(pendingBooking instanceof PendingBooking)) {
    // When reloading the page the state does keep the data, but the data is not a proper instance of the class anymore.
    pendingBooking = convertObjectKeysFromCamelToSnakeCase(removePrefixesFromObjectKeys(pendingBooking));

    return PendingBooking.fromJson(pendingBooking);
  }

  return pendingBooking;
};

/**
 * Here we extract the institution from the navigation state.
 * There is one caveat, when reloading the page the state does keep the data, but the data is not a proper instance of the class anymore. This function handles this case as well.
 * @param router
 */
const getInstitutionFromNavigationState: (router: Router) => Institution = (router: Router): Institution | null => {
  let institution = router.getCurrentNavigation().extras.state.institution;

  if (institution && !(institution instanceof Institution)) {
    institution = removePrefixesFromObjectKeys(institution);

    return Institution.fromJson(institution);
  }

  return institution;
};
