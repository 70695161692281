import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CONTACT_FORM_KEYS } from '@constants/form-keys/contact-form-keys.constant';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { SlotService } from '@services/slot.service';
import { IObject } from '@app-types/iobject.type';
import { ActivatedRoute } from '@angular/router';
import { MASKS } from '@constants/masks.constant';
import { extractErrorMessageFromFormValidation, getUnknownErrorMessage } from '@utils/helpers/form.util';

@Component({
  selector: 'vh-contact-for-visit-page',
  templateUrl: './contact-for-visit-page.component.html',
  styleUrls: ['./contact-for-visit-page.component.scss'],
})
export class ContactForVisitPageComponent implements OnInit {
  protected readonly CONTACT_FORM_KEYS: typeof CONTACT_FORM_KEYS = CONTACT_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly MASKS: typeof MASKS = MASKS;

  contactRequestIsSubmitted: boolean;
  contactRequestFormGroup: UntypedFormGroup;
  contactRequestButtonIsDisabled: boolean;
  contactRequestErrorMessageTranslationKey: string;

  latitude: number;
  longitude: number;
  radius: number;
  serviceId: string;

  constructor(
    private readonly slotService: SlotService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadNavigationData();
  }

  submitContactRequest = (): void => {
    this.contactRequestButtonIsDisabled = true;
    this.contactRequestErrorMessageTranslationKey = null;

    this.contactRequestErrorMessageTranslationKey = extractErrorMessageFromFormValidation(
      CONTACT_FORM_KEYS,
      this.contactRequestFormGroup,
      this.getErrorMessage
    );

    if (this.contactRequestErrorMessageTranslationKey) {
      this.contactRequestButtonIsDisabled = false;

      return;
    }

    const formValues: IObject = {
      ...this.contactRequestFormGroup.value,
      [ENDPOINTS.submitAppointmentContactRequest.queryParams.serviceId]: this.serviceId,
      [ENDPOINTS.submitAppointmentContactRequest.queryParams.latitude]: this.latitude,
      [ENDPOINTS.submitAppointmentContactRequest.queryParams.longitude]: this.longitude,
      [ENDPOINTS.submitAppointmentContactRequest.queryParams.radius]: this.radius,
    };

    this.slotService.submitContactRequest$(formValues).subscribe({
      next: (): void => {
        this.contactRequestIsSubmitted = true;
      },
      error: (): void => {
        this.contactRequestButtonIsDisabled = false;
        this.contactRequestErrorMessageTranslationKey = 'common.unknownError';
      },
    });
  };

  private initForm = (): void => {
    this.contactRequestFormGroup = this.formBuilder.group({
      [CONTACT_FORM_KEYS.get('firstName')]: [
        this.authenticationService.currentUser?.firstName,
        [Validators.required],
      ],
      [CONTACT_FORM_KEYS.get('lastName')]: [
        this.authenticationService.currentUser?.lastName,
        [Validators.required],
      ],
      [CONTACT_FORM_KEYS.get('phone')]: [
        this.authenticationService.currentUser?.phone,
        [Validators.required],
      ],
      [CONTACT_FORM_KEYS.get('email')]: [
        this.authenticationService.currentUser?.email,
        [
          Validators.required,
          Validators.email,
        ],
      ],
    });
  };

  private loadNavigationData = (): void => {
    const params = this.activatedRoute.snapshot.queryParams;

    this.latitude = params.latitude ?? null;
    this.longitude = params.longitude ?? null;
    this.radius = params.radius ?? null;
    this.serviceId = params.service ?? null;
  };

  private getErrorMessage = (errorIdentifier: string, formKey: string): string => {
    if (errorIdentifier === 'required') {
      return 'pages.createAppointment.contact.missingFieldsError';
    }

    if (errorIdentifier === 'email') {
      return 'pages.createAppointment.contact.invalidEmailError';
    }

    if (errorIdentifier === 'pattern') {
      if (formKey === CONTACT_FORM_KEYS.get('phone')) {
        return 'pages.createAppointment.contact.invalidPhoneNumberError';
      }
    }

    return getUnknownErrorMessage(errorIdentifier, formKey);
  };
}

