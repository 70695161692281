<div class="flex-col-c-c gap-v-16">
  <vh-icon [src]="icon" [stroke]="isStroke ? color : ''" [fill]="!isStroke ? color : ''" [width]="40" [height]="40"></vh-icon>
  <h1>{{title}}</h1>
  <p>{{message}}</p>
  <vh-button
    *ngIf="actionText"
    [vhMarginTop]="16"
    [label]="actionText"
    (buttonClick)="actionClick.emit()">
  </vh-button>
</div>

