import { Institution, InstitutionJson } from '@models/institution.model';
import { Service, ServiceJson, SimpleServiceJson } from '@models/service.model';
import { Option } from '@interfaces/option.interface';
import { Survey, SurveyJson } from '@models/survey.model';

export class ServiceGroup {
  constructor(
    private _id: string,
    private _name?: string,
    private _color?: string,
    private _institution?: Institution,
    private _services: Service[] = [],
    private _modality?: Service,
    private _surveys: Survey[] = []
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get institution(): Institution {
    return this._institution;
  }

  get services(): Service[] {
    return this._services.sort((serviceA: Service, serviceB: Service) => serviceA.name.localeCompare(serviceB.name));
  }

  get modality(): Service {
    return this._modality;
  }

  get surveys(): Survey[] {
    return this._surveys?.sort((a: Survey, b: Survey) => {
      const date1 = new Date(a.activeFrom);
      const date2 = new Date(b.activeFrom);
      if (date1.getTime() > date2.getTime()) {
        return -1;
      }
      if (date1.getTime() < date2.getTime()) {
        return 1;
      }

      return 0;
    });
  }

  static fromJson(json: ServiceGroupJson): ServiceGroup {
    return json
      ? new ServiceGroup(
        json.id,
        json.name,
        json.color,
        Institution.fromJson(json.institution),
        json.services?.map(Service.fromJson) ?? [],
        Service.fromJson(json.modality),
        json.surveys?.map((surveyJson: SurveyJson) => Survey.fromJson(surveyJson)) ?? []
      )
      : null;
  }

  toJson(): ServiceGroupJson {
    return {
      id: this._id,
      name: this._name,
      color: this._color,
      institution: this.institution?.toJson(),
      services: this.services.map((s: Service) => s.toJson()),
      surveys: this.surveys ? this.surveys.map((s: Survey) => s.toJson()) : [],
    };
  }

  toOption(includeModality: boolean = false): Option {
    return {
      value: this.id,
      label: includeModality && this.modality ? `${this.modality.shortName} - ${this.name}` : this.name,
    };
  }
}

export interface ServiceGroupJson {
  id: string;
  name: string;
  color: string;
  institution: InstitutionJson;
  services: ServiceJson[] | SimpleServiceJson[];
  modality?: ServiceJson;
  surveys?: SurveyJson[];
}
