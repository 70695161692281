import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonStyle } from '@enums/button-style.enum';
import { Service } from '@models/service.model';

export interface VisitServiceDependencyDialogData {
  selectedService: Service;
  priorService: Service;
}

export interface VisitServiceDependencyDialogResult {
  status: VisitBlockedByDialogResultStatus;
}

export type VisitBlockedByDialogResultStatus = 'yes' | 'no' | 'cancel';

@Component({
  selector: 'vh-visit-service-dependency-dialog',
  templateUrl: './visit-service-dependency-dialog.component.html',
  styleUrls: ['./visit-service-dependency-dialog.component.scss'],
})
export class VisitServiceDependencyDialogComponent {
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;

  data: VisitServiceDependencyDialogData;

  constructor(
    protected readonly dialog: MatDialogRef<VisitServiceDependencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: VisitServiceDependencyDialogData
  ) {
    this.data = data;
  }

  close(result: VisitBlockedByDialogResultStatus): void {
    this.dialog.close({ status: result });
  }
}
