import { Injectable } from '@angular/core';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { AbstractTrackingService } from '@services/tracking/abstract-tracking.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { AnswerList } from '@models/answer-list.model';

@Injectable({
  providedIn: 'root',
})
export class AnswerListTrackingEventService extends AbstractTrackingService<AnswerList> {
  protected get endpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.trackAnswerListEvent.route;
  }

  protected map(trackingEventType: TrackingEventType, answerList: AnswerList): Record<string, string> {
    return {
      type: trackingEventType,
      answer_list_id: answerList.id,
    };
  }

  protected get bulkEndpoint(): string {
    return environment.apiBaseUrl + ENDPOINTS.bulkTrackAnswerListEvents.route;
  }

  protected getUniqueIdentifier(answerList: AnswerList): string {
    return answerList.id;
  }
}
