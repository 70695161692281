import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { add, differenceInCalendarMonths, format, getMonth, isEqual, isPast, isSameDay, isSameMonth, isToday, startOfMonth } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { CalendarDate } from '@interfaces/calendar-day.interface';
import { COLORS } from '@constants/colors.constant';
import { changeDateFormat } from '@utils/helpers/date.util';

@Component({
  selector: 'vh-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;
  protected readonly Colors: typeof COLORS = COLORS;

  @Input() disablePastDates: boolean = false;
  @Input() disabledDates: Date[] = [];
  @Input() highlightedDates: Date[] = [];
  @Input() selectedDate: Date = new Date();
  @Input() onDateClickFunction: (date: Date) => void;

  @Output() monthChange: EventEmitter<Date> = new EventEmitter<Date>();

  currentDateTime: Date;
  displayedMonthFirstDate: Date;
  displayedMonthTranslationKey: string;
  displayedCalendarDates: CalendarDate[] = [];
  isDisplayingCurrentMonth: boolean;

  constructor() {
    this.updateCurrentDateTime();
  }

  ngOnInit(): void {
    this.displayedMonthFirstDate = this.selectedDate;
    this.updateCalendar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateCurrentDateTime();

    const highlightedDatesChange: SimpleChange = changes.highlightedDates;

    if (
      highlightedDatesChange &&
      !highlightedDatesChange.firstChange &&
      highlightedDatesChange.currentValue !== highlightedDatesChange.previousValue
    ) {
      this.updateCalendar();
    }

    if (changes.disabledDates && !changes.disabledDates.firstChange) {
      this.updateCalendar();
    }

    if (changes.selectedDate && !isSameMonth(changes.selectedDate.currentValue, this.displayedMonthFirstDate)) {
      this.updateCalendar(differenceInCalendarMonths(this.selectedDate, this.displayedMonthFirstDate));
    }
  }

  updateCalendar = (monthsToAddOrSubtract: number = 0): void => {
    if (!this.displayedMonthFirstDate) {
      return;
    }

    if (monthsToAddOrSubtract !== 0) {
      this.displayedMonthFirstDate = add(startOfMonth(this.displayedMonthFirstDate), {
        months: monthsToAddOrSubtract,
      });
      this.monthChange.emit(this.displayedMonthFirstDate);
    }

    this.isDisplayingCurrentMonth = differenceInCalendarMonths(this.displayedMonthFirstDate, this.currentDateTime) === 0;
    this.displayedMonthTranslationKey = `common.months.${format(this.displayedMonthFirstDate, DATE_FORMATS.monthNumber)}`;

    let calendarDayDate: Date = startOfMonth(this.displayedMonthFirstDate);
    const firstDayNumber: number = parseInt(format(calendarDayDate, DATE_FORMATS.dayNumber), 10);
    calendarDayDate = add(calendarDayDate, {
      days: 1 - firstDayNumber,
    });

    this.displayedCalendarDates = [];

    for (let dayNumber: number = 1; dayNumber <= 42; dayNumber++) {
      const calendarDayMonthDiffersFromDisplayedDateMonth: boolean = getMonth(calendarDayDate) !== getMonth(this.displayedMonthFirstDate);

      if (dayNumber === 36 && calendarDayMonthDiffersFromDisplayedDateMonth) {
        break;
      }

      const shouldBeDisabled: boolean = this.isCalendarDayDateFaded(calendarDayDate);
      this.displayedCalendarDates.push({
        date: calendarDayDate,
        clientDay: format(calendarDayDate, DATE_FORMATS.clientDay),
        isFaded: shouldBeDisabled,
        isHighlighted: isEqual(
          changeDateFormat(calendarDayDate, DATE_FORMATS.serverDate),
          changeDateFormat(this.selectedDate, DATE_FORMATS.serverDate)
        ),
        isClickable: this.onDateClickFunction !== null && !shouldBeDisabled,
        isToday: isToday(calendarDayDate),
        isSelected: this.selectedDate && isSameDay(calendarDayDate, this.selectedDate),
      });

      calendarDayDate = add(calendarDayDate, {
        days: 1,
      });
    }
  };

  handleDateClick = (date: Date): void => {
    this.selectedDate = date;

    this.updateCalendar(differenceInCalendarMonths(this.selectedDate, this.displayedMonthFirstDate));
    this.onDateClickFunction?.(this.selectedDate);
  };

  updateCurrentDateTime = (): void => {
    this.currentDateTime = new Date();
  };

  private isCalendarDayDateFaded(calendarDayDate: Date): boolean {
    const calendarDayMonthDiffersFromDisplayedDateMonth: boolean = getMonth(calendarDayDate) !== getMonth(this.displayedMonthFirstDate);
    if (calendarDayMonthDiffersFromDisplayedDateMonth) {
      return true;
    }

    if (this.disablePastDates && !isToday(calendarDayDate) && isPast(calendarDayDate)) {
      return true;
    }

    if (this.disabledDates.length > 0) {
      return this.disabledDates.some((disabledDate: Date) => isSameDay(disabledDate, calendarDayDate));
    }

    return false;
  }
}
