import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseBookingFlowPageComponent } from '@modules/booking/pages/base-booking-flow-page/base-booking-flow.page';
import { Mode } from '@enums/mode.enum';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';
import { BookingFlowStateService } from '@modules/booking/services/booking-flow-state.service';
import { BookingFlowNavigationService } from '@modules/booking/services/booking-flow-navigation.service';
import { BookingFlowDialogService } from '@modules/booking/services/booking-flow-dialog.service';
import { AuthenticationService } from '@services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vh-booking-success-page',
  templateUrl: './booking-success-page.component.html',
  styleUrls: ['./booking-success-page.component.scss'],
})
@UntilDestroy()
export class BookingSuccessPageComponent extends BaseBookingFlowPageComponent {
  protected readonly Mode: typeof Mode = Mode;

  constructor(
    bookingState: BookingFlowStateService,
    navigationService: BookingFlowNavigationService,
    dialogService: BookingFlowDialogService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {
    super(bookingState, navigationService, dialogService);
  }

  exitAndClearState(): void {
    this.bookingState.reset();
    this.exitBookingFlow(false);
  }

  onExitClicked(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}

