import { Injectable } from '@angular/core';
import { defer, from, Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
import QRCode from 'qrcode';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  generate$(payload: string): Observable<unknown> {
    return from(defer(() => QRCode.toDataURL(payload)));
  }
}
