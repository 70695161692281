import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-dialog-frame',
  templateUrl: './dialog-frame.component.html',
  styleUrls: ['./dialog-frame.component.scss'],
})
export class DialogFrameComponent {
  COLORS: typeof COLORS = COLORS;

  @Input() title: string;
  @Input() marginBetweenTitleAndBody: number = 16;
  @Input() showCloseButton: boolean = true;

  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();
}
