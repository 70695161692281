import { Component, OnInit } from '@angular/core';
import { NAVIGATION } from '@constants/navigation.constant';
import { User } from '@models/user.model';
import { AuthenticationService } from '@services/authentication.service';
import { Wallet } from '@models/wallet.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WalletService } from '@services/wallet.service';
import { AlertDialogService } from '@services/ui/alert-dialog.service';
import { PAYMENT_NAV } from '@modules/payment/constants/payment-nav.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutomaticTopUpSettingsDialogComponent } from '@modules/account/dialogs/automatic-top-up-settings-dialog/automatic-top-up-settings-dialog.component';
import { Bucket } from '@models/bucket.model';
import { PaymentService } from '@services/payment.service';

@Component({
  selector: 'vh-wallet-overview-page',
  templateUrl: './wallet-overview-page.component.html',
  styleUrls: ['./wallet-overview-page.component.scss'],
})
@UntilDestroy()
export class WalletOverviewPageComponent implements OnInit {
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  currentUser: User;
  wallet: Wallet;
  automaticTopUpBucket: Bucket | null = null;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly walletService: WalletService,
    private readonly alertDialogService: AlertDialogService,
    private readonly paymentService: PaymentService
  ) {
    this.currentUser = this.authenticationService.currentUser;
  }

  ngOnInit(): void {
    this.loadWallet();
  }

  onTopUpClicked(): void {
    void this.router.navigate([PAYMENT_NAV.bucketsOverview.route]);
  }

  onConfigureAutomaticTopUpClicked(): void {
    if (this.wallet.isAutoReplenishEnabled) {
      this.alertDialogService.open({
        titleTranslationKey: 'wallet.confirmDisableAutomaticPaymentsDialog.title',
        messageTranslationKey: 'wallet.confirmDisableAutomaticPaymentsDialog.message',
        confirmTextTranslationKey: 'common.yes',
        cancelTextTranslationKey: 'common.no',
        showCancelButton: true,
      })
        .pipe(untilDestroyed(this))
        .subscribe((result: boolean): void => {
          if (result) {
            this.disableAutomaticTopUp();
          }
        });

      return;
    }

    this.openConfigureAutomaticTopUpDialog();
  }

  private disableAutomaticTopUp(): void {
    this.walletService.disableAutoReplenish$()
      .subscribe(() => {
        this.loadWallet();
      });
  }

  private openConfigureAutomaticTopUpDialog(): void {
    this.dialog
      .open(AutomaticTopUpSettingsDialogComponent, { panelClass: 'dialog-size-medium' })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.loadWallet();
      });
  }

  private loadWallet(): void {
    this.walletService.getWalletOfCurrentInstitution$()
      .pipe(untilDestroyed(this))
      .subscribe((wallet: Wallet) => {
        this.wallet = wallet;

        if (this.wallet.isAutoReplenishEnabled) {
          this.loadAutomaticTopUpBucket();
        }
      });
  }

  private loadAutomaticTopUpBucket(): void {
    this.paymentService.getBucketById$(this.wallet.autoReplenishBucketId)
      .pipe(untilDestroyed(this))
      .subscribe((bucket: Bucket | null) => {
        this.automaticTopUpBucket = bucket;
      });
  }
}
