<vh-page>
  <vh-content-layout [hasHorizontalSpacing]="true">
    <div class="flex-row container">
      <div class="flex-col-l-c form content-container">
        <h1>{{ 'pages.expiredLink.title' | translate }}</h1>
        <p [vhMarginTop]="48">{{ 'pages.expiredLink.text' | translate }}</p>
        <vh-button
          [vhMarginTop]="32"
          [label]="'pages.expiredLink.resetButton' | translate"
          [iconRightPath]="'assets/icons/arrow-right-white.svg'"
          [alignment]="'center'"
          [routerLink]="NAVIGATION.resetPassword.route">
        </vh-button>
      </div>
      <div class="flex-row illustration">
        <div class="flex-col-c-c background" vhSkewMargin>
          <img [vhMarginTop]="64" src="assets/images/cold-sick-man.svg" [alt]="'pages.expiredLink.altImage' | translate" />
        </div>
      </div>
    </div>
  </vh-content-layout>
</vh-page>
