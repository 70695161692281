import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NAV_DATA_IDS } from '@constants/navigation-data-identifiers.constant';
import { VisitTask } from '@models/visit-task.model';
import { AppService } from '@services/app.service';
import { Task } from '@models/task.model';
import { COLORS } from '@constants/colors.constant';
import { TaskStatus } from '@enums/task-status.enum';
import { Visit } from '@models/visit.model';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';

@Component({
  selector: 'vh-visit-card-actions',
  templateUrl: './appointment-card-actions.component.html',
  styleUrls: ['./appointment-card-actions.component.scss'],
})
export class AppointmentCardActionsComponent implements OnInit, OnChanges {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() visit: Visit;

  status: 'everythingIsProvided' | 'missingData' | 'waitingForConfirmation';
  amountTasksNotCompleted: number;
  totalAmountTasks: number;

  constructor(private readonly appService: AppService, private readonly router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visit) {
      this.calculateTaskStatuses();
    }
  }

  ngOnInit(): void {
    this.calculateTaskStatuses();
  }

  navigateToAppointmentTasksPage(): void {
    this.appService.setNavigationData(NAV_DATA_IDS.appointment, this.visit);
    const route: string = BOOKING_NAV.visitViewTasks.route
      .replace(`:${BOOKING_NAV.visitViewTasks.params.visitId}`, this.visit?.id);
    void this.router.navigate([route]);
  }

  private calculateTaskStatuses(): void {
    const tasksNotCompleted: Task[] = this.visit?.allTasks.filter((task: VisitTask): boolean => {
      return task.status !== TaskStatus.COMPLETED;
    });

    if (tasksNotCompleted?.length) {
      this.status = 'missingData';
      this.amountTasksNotCompleted = tasksNotCompleted.length;
      this.totalAmountTasks = this.visit.allTasks.length;

      return;
    }

    this.status = 'everythingIsProvided';
  }
}
