import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

/**
 * This guard prevents users from accessing certain pages if they are logged in.
 * It's the opposite of the authentication guard.
 *
 * This is mainly used for the login and register pages.
 */
export const unauthenticatedGuard = (): CanActivateFn => {
  return (): boolean => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);

    if (authenticationService.isLoggedIn()) {
      redirectToMostRelevantHome(authenticationService, router);

      return false;
    }

    return true;
  };
};
