// import { Directive, OnInit } from '@angular/core';
// import { COLORS } from '@constants/colors.constant';
// import { WizardState } from '@modules/shared/core/models/wizard-state.model';
// import { SESSION_KEYS } from '@constants/local-storage-keys.constant';

// @Directive()
// export abstract class BaseOnboardingPageComponent implements OnInit {
//   protected readonly COLORS: typeof COLORS = COLORS;

//   protected readonly STEP_CREDENTIALS: number = 1;
//   protected readonly STEP_INSTITUTION: number = 2;
//   protected readonly STEP_WALLET: number = -1;
//   protected readonly STEP_FINISHING_UP: number = 3;

//   protected state: WizardState;

//   protected isResuming: boolean = false;

//   abstract onNextClicked(): void;
//   abstract setStep(): void;

//   ngOnInit(): void {
//     this.state = new WizardState([
//       'onboarding.steps.credentials',
//       'onboarding.steps.institution',
//       // 'onboarding.steps.wallet',
//       'onboarding.steps.finishingUp',
//     ]);
//     this.setStep();
//     this.isResuming = sessionStorage.getItem(SESSION_KEYS.isPerformingDoctorOnboardingDuringFirstSession) !== 'true';
//   }
// }

import { Directive, OnInit } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { WizardState } from '@modules/shared/core/models/wizard-state.model';
import { SESSION_KEYS } from '@constants/local-storage-keys.constant';

@Directive()
export abstract class BaseOnboardingPageComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  protected readonly STEP_CREDENTIALS: number = 1;
  protected readonly STEP_PRIVACY: number = 2;
  protected readonly STEP_INSTITUTION: number = 3;
  protected readonly STEP_WALLET: number = -1;
  protected readonly STEP_FINISHING_UP: number = 4;

  protected state: WizardState;

  protected isResuming: boolean = false;

  abstract onNextClicked(): void;
  abstract setStep(): void;

  ngOnInit(): void {
    this.state = new WizardState([
      'onboarding.steps.credentials',
      'onboarding.steps.privacy',
      'onboarding.steps.institution',
      'onboarding.steps.account',
    ]);
    this.setStep();
    this.isResuming = sessionStorage.getItem(SESSION_KEYS.isPerformingDoctorOnboardingDuringFirstSession) !== 'true';
  }
}
