import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IObject } from '@app-types/iobject.type';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PendingBooking } from '@models/pending-booking.model';

@Injectable({
  providedIn: 'root',
})
export class PendingBookingService {

  constructor(
    private readonly http: HttpClient
  ) {}

  createPendingBooking$(data: IObject): Observable<PendingBooking> {
    if (!data.version) {
      data.version = PendingBooking.CURRENT_VERSION;
    }

    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.createPendingBooking.route, data)
      .pipe(map(PendingBooking.fromJson));
  }

  updatePendingBooking$(pendingBookingId: string, properties: IObject): Observable<PendingBooking> {
    const endpoint: string = ENDPOINTS.updatePendingBooking.route
      .replace(/{.*}/, pendingBookingId);

    return this.http
      .put(environment.apiBaseUrl + endpoint, properties)
      .pipe(map(PendingBooking.fromJson));
  }

  book$(pendingBookingId: string): Observable<PendingBooking> {
    const endpoint: string = ENDPOINTS.bookPendingBooking.route
      .replace(/{.*}/, pendingBookingId);

    return this.http
      .post(environment.apiBaseUrl + endpoint, {})
      .pipe(map(PendingBooking.fromJson));
  }

  getPendingBookingById$(pendingBookingId: string): Observable<PendingBooking> {
    const endpoint: string = ENDPOINTS.getPendingBookingById.route
      .replace(/{.*}/, pendingBookingId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(PendingBooking.fromJson));
  }
}
