/**
 * Converts a random string to a color in a consistent manner. So the same input string will always result in the same
 * output color.
 */
export const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let u = 0; u < 3; u++) {
    const value = hash >> u * 8 & 0xFF;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
};

export const makeColorFaded = (color: string, fadeAmount: number = 0.15): string => {
  // Convert HEX to RGB
  const hexToRGB = (hex: string): { r: number; g: number; b: number; } => {
    const bigint = parseInt(hex.replace('#', ''), 16);

    return {
      r: bigint >> 16 & 255,
      g: bigint >> 8 & 255,
      b: bigint & 255,
    };
  };

  // Convert RGB to RGBA
  const rgbToRgba = (r: number, g: number, b: number, alpha: number): string =>
    `rgba(${r}, ${g}, ${b}, ${alpha.toFixed(2)})`;

  // Blend the color with white
  const { r, g, b } = hexToRGB(color);
  const white = 255;

  const fadedRGB = {
    r: Math.round(r * (1 - fadeAmount) + white * fadeAmount),
    g: Math.round(g * (1 - fadeAmount) + white * fadeAmount),
    b: Math.round(b * (1 - fadeAmount) + white * fadeAmount),
  };

  // Return the color in RGBA format with transparency
  return rgbToRgba(fadedRGB.r, fadedRGB.g, fadedRGB.b, 1 - fadeAmount);
};
