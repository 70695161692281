import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bucket, BucketJson } from 'src/app/models/bucket.model';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { IObject } from '@app-types/iobject.type';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private readonly http: HttpClient
  ) { }

  buyBucket$(paymentMethodId: string, bucketId: string | null = null, customAmount: number | null = null): Observable<any> {
    const bucketDTO: IObject = {
      payment_method_id: paymentMethodId,
    };

    if (bucketId) {
      bucketDTO.bucket_id = bucketId;
    }

    if (customAmount) {
      bucketDTO.custom_amount = customAmount;
    }

    const endpoint: string = ENDPOINTS.buyBucket.route;

    return this.http.post(environment.apiBaseUrl + endpoint, bucketDTO, this.httpOptions);
  }

  getAvailableBuckets$(): Observable<Bucket[]> {
    const endpoint: string = ENDPOINTS.getAvailableBuckets.route;

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: BucketJson[]): Bucket[] => json.map(Bucket.fromJson)));
  }

  getBucketById$(bucketId: string): Observable<Bucket> {
    const endpoint: string = ENDPOINTS.getBucketById.route
      .replace(/{.*}/, bucketId);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(Bucket.fromJson));
  }
}
