import { BookingFlowFilter, BookingFlowFilterJson } from '@modules/booking/models/booking-flow-filter.model';
import { BookingFlow } from '@enums/booking-flow.enum';

export class PendingBookingData {
  private _filter: BookingFlowFilter;
  private _flow: BookingFlow;
  private _scopedInstitutionId?: string;

  get filter(): BookingFlowFilter {
    return this._filter;
  }

  set filter(value: BookingFlowFilter) {
    this._filter = value;
  }

  get flow(): BookingFlow {
    return this._flow;
  }

  set flow(value: BookingFlow) {
    this._flow = value;
  }

  get scopedInstitutionId(): string {
    return this._scopedInstitutionId;
  }

  set scopedInstitutionId(value: string) {
    this._scopedInstitutionId = value;
  }

  static fromJson(json: PendingBookingDataJson): PendingBookingData {
    const data: PendingBookingData = new PendingBookingData();
    data.filter = BookingFlowFilter.fromJson(json.filter);
    data.flow = BookingFlow[json.flow];
    data.scopedInstitutionId = json.scoped_institution_id;

    return data;
  }
}

export interface PendingBookingDataJson {
  filter: BookingFlowFilterJson;
  flow: string;
  scoped_institution_id?: string;
}
