import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-conversations-privacy-page.component',
  templateUrl: './conversations-privacy-page.component.html',
  styleUrls: ['./conversations-privacy-page.component.scss'],
})
@UntilDestroy()
export class ConversationsPrivacyPageComponent {
}
