import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[vhMarginTop]',
})
export class MarginTopDirective implements OnInit, OnChanges {
  @Input() vhMarginTop: number | string;

  constructor(private readonly element: ElementRef) {}

  ngOnInit(): void {
    this.setMarginTop();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vhMarginTop) {
      this.setMarginTop();
    }
  }

  private setMarginTop(): void {
    this.element.nativeElement.style.marginTop = this.vhMarginTop ? `var(--${this.vhMarginTop}px)` : null;
  }
}
