import { Injectable } from '@angular/core';
import { CalendarZoomPreset, calendarZoomPresets } from '@modules/shared/calendar/components/events-calendar/calendar-zoom-presets';

@Injectable({
  providedIn: 'root',
})
export class AngularCalendarZoomHelperService {
  private readonly minZoomLevel: number = 0;
  private readonly maxZoomLevel: number = calendarZoomPresets.size - 1;

  changeZoomLevel(currentZoomLevel: number, stepSize: number): number {
    const requestedZoomLevel: number = currentZoomLevel + stepSize;

    return this.normaliseZoomLevel(requestedZoomLevel);
  }

  /**
   * Normalises a requested zoom level to prevent it going out of bounds.
   * @param requestedZoomLevel
   */
  normaliseZoomLevel(requestedZoomLevel: number): number {
    if (requestedZoomLevel <= this.minZoomLevel) {
      return this.minZoomLevel;
    } else if (requestedZoomLevel >= this.maxZoomLevel) {
      return this.maxZoomLevel;
    }

    return requestedZoomLevel;
  }

  getZoomPresetForZoomlevel(requestedZoomLevel: number): CalendarZoomPreset {
    requestedZoomLevel = this.normaliseZoomLevel(requestedZoomLevel);

    return calendarZoomPresets.get(requestedZoomLevel);
  }
}
