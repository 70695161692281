/* eslint-disable-next-line @typescript-eslint/no-restricted-imports */
import packageJson from '../../package.json';

export const environment = {
  name: 'test',
  production: true,
  assetsFolder: 'beta',
  apiBaseUrl: 'https://acc.vesalius.ai/api/v1',
  baseUrl: 'https://acc.vesalius.ai/',
  version: packageJson.version,
  sentry: {
    enableErrorTracking: true,
    dsn: 'https://950fbf210d2e42dcba826b8ceb9a394f@o1412969.ingest.sentry.io/6753408',
    tracingOrigins: [
      'localhost',
      'acc.vesalius.ai',
    ],
  },
  links: {
    supportUrl: 'https://www.vesalius.ai/helpdesk/customer-care-1/knowledgebase',
    contactUrl: 'https://vesalius.ai/#Form',
    telescopeUrl: 'https://acc.vesalius.ai/stethoscope',
    privacyStatement: 'https://www.vesalius.ai/privacy-policy',
    termsAndConditions: 'https://www.vesalius.ai/terms-conditions',
  },
  app: {
    slotAmount: 12,
    minPasswordLength: 8,
    lowTokensAmount: 15,
  },
  chatbot: {
    url: 'https://t2fb6pmybgdwxhwpmbz3wsz37y0smicz.lambda-url.eu-west-1.on.aws/',
  },
  mollie: {
    profileId: 'pfl_RAUmems3Ju',
    testMode: true,
  },
};
