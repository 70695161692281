import { CalendarDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { PreferencesService } from '@services/preferences.service';
import { Preferences } from '@interfaces/preferences.interface';
import { calendarZoomPresets } from '@modules/shared/calendar/components/events-calendar/calendar-zoom-presets';
import { LanguageService } from '@services/language.service';

@Injectable()
export class EventsCalendarDateFormatterProvider extends CalendarDateFormatter {
  private preferences: Preferences;

  constructor(
    readonly dateAdapter: DateAdapter,
    private readonly preferencesService: PreferencesService,
    private readonly languageService: LanguageService
  ) {
    super(dateAdapter);
    this.preferences = this.preferencesService.getPreferences();
    this.listenForPreferenceChanges();
  }

  dayViewHour({ date, locale }: DateFormatterParams): string {
    if (!this.languageService.isLocaleRegistered(locale)) {
      this.languageService.registerAngularLocaleData(locale);
    }

    const zoom: number = this.sanitiseZoomLevel(this.preferences.calendar.zoom);

    if (date.getMinutes() % calendarZoomPresets.get(zoom).showTimeEveryXMinutes === 0) {
      return formatDate(date, 'HH:mm', locale);
    }

    return `${date.getMinutes()}`;
  }

  weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  listenForPreferenceChanges(): void {
    this.preferencesService.preferencesChanged$
      .subscribe((newPreferences: Preferences) => {
        this.preferences = newPreferences;
      });
  }

  sanitiseZoomLevel(zoom: number): number {
    if (zoom > calendarZoomPresets.size - 1) {
      return calendarZoomPresets.size - 1;
    } else if (zoom < 0) {
      return 0;
    }

    return zoom;
  }
}
