import { Component, Input } from '@angular/core';
import { WalletTransaction } from '@models/wallet-transaction.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Component({
  selector: 'vh-wallet-transactions-card',
  templateUrl: './wallet-transaction-card.component.html',
  styleUrls: ['./wallet-transaction-card.component.scss'],
})
export class WalletTransactionCardComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() transaction: WalletTransaction;
}
