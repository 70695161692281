import { AfterContentInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { ButtonStyle } from '@enums/button-style.enum';
import { ZOOM_LEVELS } from '@constants/zoom-levels.constant';
import { Coordinate } from '@models/coordinate.model';
import { BaseMapComponent } from '@modules/shared/core/components/base-map/base-map.component';

@Component({
  selector: 'vh-step-select-location',
  templateUrl: './step-select-location.component.html',
  styleUrls: ['./step-select-location.component.scss'],
})
export class StepSelectLocationComponent extends BaseMapComponent implements AfterContentInit {
  ButtonStyle: typeof ButtonStyle = ButtonStyle;

  getPlaceSuggestionOptionsErrorTranslationKey: string;

  isLoadingUserLocation: boolean;

  @Output() locationChange: EventEmitter<Coordinate> = new EventEmitter<Coordinate>();

  @ViewChild('map') set map(mapComponent: GoogleMap) {
    if (mapComponent) {
      this.mapComponent = mapComponent;
    }
  }

  ngAfterContentInit(): void {
    this.showBelgium();
    this.showUserLocation(false);
  }

  showUserLocation(wasTriggeredByUserInteraction: boolean): void {
    this.geolocationErrorTranslationKey = null;

    // Check if the location will be fetched or not, otherwise we're stuck in loading limbo
    if (!this.locationService.shouldShowPermissionDeniedDialog()) {
      this.isLoadingUserLocation = true;
    }

    super.showUserLocation(wasTriggeredByUserInteraction);
  }

  onGoogleSuggestionsErrorOccurred(errorKey: string): void {
    this.getPlaceSuggestionOptionsErrorTranslationKey = errorKey;
  }

  onAddressSuggestionClicked(placeResult: google.maps.places.PlaceResult): void {
    this.updateMapViewport(placeResult.geometry.location.lat(), placeResult.geometry.location.lng(), ZOOM_LEVELS.highlight);
    this.locationChange.emit(Coordinate.fromLatLng(placeResult.geometry.location));
  }

  protected onUserLocationFetched(position: GeolocationPosition, zoom: number = ZOOM_LEVELS.default): void {
    super.onUserLocationFetched(position, zoom);

    this.isLoadingUserLocation = false;
    this.addUserLocationMarker('marker');
    this.locationChange.emit(Coordinate.fromGeolocationPosition(position));
  }

  protected onErrorWhenGettingUserLocation(positionError: GeolocationPositionError): void {
    super.onErrorWhenGettingUserLocation(positionError);

    this.isLoadingUserLocation = false;
  }
}
