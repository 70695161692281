import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { REGEX } from '@constants/regex.constant';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { User } from '@models/user.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { format } from 'date-fns';
import { passwordValidator } from '@utils/validators/password.validator';

@Injectable({
  providedIn: 'root',
})
export class RegisterFormService extends AbstractFormService {

  /**
   * Create a basic form group with only the required fields (first name, last name, username and password) for the registration form.
   */
  createFormGroupForHealthCareProvider(): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('email')]: [
        null,
        [Validators.required, Validators.email],
      ],
      [REGISTER_FORM_KEYS.get('username')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('password')]: [
        null,
        [
          Validators.required,
          passwordValidator(),
        ],
      ],
      [REGISTER_FORM_KEYS.get('accepted_terms')]: [
        false,
        [Validators.requiredTrue],
      ],
    });
  }

  /**
   * Create a form group with all possible fields for the registration form.
   * @param hasPasswordInput
   * @param hasPrivacyStatementInput
   * @param isSelfRegistering
   */
  createFormGroup(hasPasswordInput: boolean, hasPrivacyStatementInput: boolean, isSelfRegistering: boolean): UntypedFormGroup {
    return this.formBuilder.group({
      [REGISTER_FORM_KEYS.get('firstName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('lastName')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('birthdate')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('username')]: [
        null,
        [Validators.required],
      ],
      [REGISTER_FORM_KEYS.get('gender')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('language')]: [
        null,
        isSelfRegistering ? [Validators.required] : [],
      ],
      [REGISTER_FORM_KEYS.get('socialSecurityNumber')]: [
        null,
        null,
      ],
      [REGISTER_FORM_KEYS.get('phone')]: [
        null,
        isSelfRegistering
          ? [Validators.required]
          : [],
      ],
      [REGISTER_FORM_KEYS.get('email')]: [
        null,
        isSelfRegistering
          ? [Validators.required, Validators.email]
          : [Validators.email],
      ],
      [REGISTER_FORM_KEYS.get('password')]: [
        null,
        [
          ...hasPasswordInput
            ? [
              Validators.required,
              passwordValidator(),
            ]
            : [],
        ],
      ],
      [REGISTER_FORM_KEYS.get('accepted_privacy_policy')]: [
        false,
        [...hasPrivacyStatementInput ? [Validators.requiredTrue] : []],
      ],
    });
  }

  enableSocialSecurityNumberValidation(formGroup: UntypedFormGroup): void {
    formGroup.get(REGISTER_FORM_KEYS.get('socialSecurityNumber')).addValidators([Validators.required, Validators.pattern(new RegExp(REGEX.socialSecurityNumber))]);
  }

  disableSocialSecurityNumberValidation(formGroup: UntypedFormGroup): void {
    formGroup.get(REGISTER_FORM_KEYS.get('socialSecurityNumber')).clearValidators();
  }

  enableEmailOrPhoneValidation(formGroup: UntypedFormGroup): void {
    const emailControl = formGroup.get(REGISTER_FORM_KEYS.get('email'));
    const phoneControl = formGroup.get(REGISTER_FORM_KEYS.get('phone'));

    const emailOrPhoneValidator: ValidatorFn = () =>
      !emailControl?.value && !phoneControl?.value ? { emailOrPhoneRequired: true } : null;

    [emailControl, phoneControl].forEach(control => {
      control?.addValidators(emailOrPhoneValidator);
    });
  }

  setUserToForm(formGroup: UntypedFormGroup, existingPatient: User): void {
    formGroup.patchValue({
      [REGISTER_FORM_KEYS.get('firstName')]: existingPatient.firstName,
      [REGISTER_FORM_KEYS.get('lastName')]: existingPatient.lastName,
      [REGISTER_FORM_KEYS.get('birthdate')]: format(existingPatient.birthdate, DATE_FORMATS.serverDate),
      [REGISTER_FORM_KEYS.get('username')]: existingPatient.username,
      [REGISTER_FORM_KEYS.get('email')]: existingPatient.email,
      [REGISTER_FORM_KEYS.get('gender')]: existingPatient.gender,
      [REGISTER_FORM_KEYS.get('phone')]: existingPatient.phone,
      [REGISTER_FORM_KEYS.get('canPhoneBeUsedToContact')]: existingPatient.canPhoneBeUsedToContact,
    });
  }
}
