import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonStyle, ButtonCornerStyle } from '@enums/button-style.enum';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

@Component({
  selector: 'vh-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent {
  ButtonCornerStyle: typeof ButtonCornerStyle = ButtonCornerStyle;

  @Input() items: ButtonOption[];
  @Input() isDisabled: boolean;
  @Input() activeStyle: ButtonStyle = ButtonStyle.PRIMARY;
  @Input() inActiveStyle: ButtonStyle = ButtonStyle.QUATERNARY;

  @Output() itemClick: EventEmitter<number> = new EventEmitter<number>();

  onItemClick = (index: number): void => {
    this.items.map((item: ButtonOption) => item.isActive = false);
    this.items[index].isActive = true;
    this.itemClick.emit(index);
  };
}
