import { QuestionType } from '@enums/question-type.enum';
import { AnswerType } from '@enums/answer-type.enum';
import { AnswerOption, AnswerOptionJson } from '@models/answer-option.model';
import { TranslatedProperty } from '@models/translated-property.model';

export class Question {
  constructor(
    private _id: string,
    private _question: TranslatedProperty,
    private _type: QuestionType,
    private _answerType: AnswerType,
    private _isVisibleForPatient: boolean,
    private _isVisibleForInstitution: boolean,
    private _listPositionNumber: number,
    private _answerOptions: AnswerOption[],
    private _parentId?: string,
    private _validationRuleId?: string,
    private _description?: TranslatedProperty
  ) {}

  get id(): string {
    return this._id;
  }

  get question(): TranslatedProperty {
    return this._question;
  }

  get description(): TranslatedProperty {
    return this._description;
  }

  get type(): QuestionType {
    return this._type;
  }

  get answerType(): AnswerType {
    return this._answerType;
  }

  get isVisibleForPatient(): boolean {
    return this._isVisibleForPatient;
  }

  get isVisibleForInstitution(): boolean {
    return this._isVisibleForInstitution;
  }

  get listPositionNumber(): number {
    return this._listPositionNumber;
  }

  get answerOptions(): AnswerOption[] {
    return this._answerOptions;
  }

  get parentId(): string {
    return this._parentId;
  }

  get validationRuleId(): string {
    return this._validationRuleId;
  }

  static fromJson(json: QuestionJson): Question {
    return json
      ? new Question(
        json.id,
        new TranslatedProperty(json.question),
        json.type ? (json.type as QuestionType) : null,
        json.answer_type ? (json.answer_type as AnswerType) : null,
        json.is_visible_for_patient,
        json.is_visible_for_institution,
        json.list_position_number,
        json.answer_options.map(AnswerOption.fromJson),
        json.parent_id,
        json.validation_rule_id,
        new TranslatedProperty(json.description)
      )
      : null;
  }

  toJson(): QuestionJson {
    return {
      id: this._id,
      question: this._question.get(),
      type: this._type,
      answer_type: this._answerType,
      is_visible_for_patient: this._isVisibleForPatient,
      is_visible_for_institution: this._isVisibleForInstitution,
      list_position_number: this._listPositionNumber,
      answer_options: this._answerOptions.map((opt: AnswerOption) => opt.toJson()) ?? [],
      parent_id: this._parentId,
      validation_rule_id: this._validationRuleId,
      description: this._description.get(),
    };
  }
}

export interface QuestionJson {
  id: string;
  parent_id?: string;
  validation_rule_id?: string;
  question: string | Record<string, string>;
  description?: string | Record<string, string>;
  type: string;
  answer_type: string;
  is_visible_for_patient: boolean;
  is_visible_for_institution: boolean;
  list_position_number: number;
  answer_options: AnswerOptionJson[];
}
