<vh-content-layout [hasVerticalSpacing]="true" [hasHorizontalSpacing]="true">
  <vh-loader *ngIf="!order"></vh-loader>
  <div *ngIf="order" class="flex-col">
    <vh-status-message
      [isSuccessful]="true"
      [title]="'payment.status.success.title' | translate"
      [message]="'payment.status.success.message' | translate: { 'amount' : order?.tokens, 'totalTokens' : wallet?.tokens }">

      <vh-token-balance *ngIf="isSuccessful"></vh-token-balance>
    </vh-status-message>

    <vh-button
      class="align-center"
      iconRightPath="assets/icons/arrow-narrow-right.svg"
      [vhMarginTop]="32"
      [iconRightStroke]="COLORS.white"
      [label]="'common.finish' | translate"
      (buttonClick)="onFinishClicked()">
    </vh-button>
  </div>
</vh-content-layout>