<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <h1>{{ 'wallet.overview.title' | translate }}</h1>

  <div class="flex-col gap-v-16" [vhMarginTop]="32">
    <div>
      <h2>{{ 'wallet.overview.tokensBalanceLabel' | translate }}</h2>
      <vh-token-balance [vhMarginTop]="8" containerClass="has-background-quaternary"></vh-token-balance>
    </div>

    <div class="flex-row content-block-padding top-up-container gap-h-8">
      <vh-switch [value]="wallet?.isAutoReplenishEnabled" [changeValueOnClick]="false" (click)="onConfigureAutomaticTopUpClicked()"></vh-switch>
      <div class="flex-col gap-v-8">
        <p class="font-regular-18-22">{{ (wallet?.isAutoReplenishEnabled ? 'wallet.overview.automaticTopUpStateOn' : 'wallet.overview.automaticTopUpStateOff') | translate }}</p>
        <p class="font-regular-12-14">
          <span *ngIf="!wallet?.isAutoReplenishEnabled">{{ 'wallet.overview.automaticTopUpStateOffDisclaimer' | translate }}</span>
          <span *ngIf="wallet?.isAutoReplenishEnabled" [innerHTML]="'wallet.overview.automaticTopUpStateOnDisclaimer' | translate: { 'amount': automaticTopUpBucket?.totalTokens, 'threshold': wallet?.minTokensThreshold }"></span>
        </p>
      </div>
    </div>

    <vh-button [label]="'wallet.overview.actionAdd' | translate" (buttonClick)="onTopUpClicked()"></vh-button>
  </div>
</vh-content-layout>
