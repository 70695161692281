import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { isAfter, isBefore, parse } from 'date-fns';

export function validateActiveTo(id?: string): ValidationErrors | null {
  return (formControl: AbstractControl): ValidationErrors | null => {
    let valid = true;
    // active_to can never be more recent than active_from from the previous form group.
    let dateCeil = null;

    if (formControl.value === null || formControl.value === '') {
      return null;
    }

    if (id) {
      let afterFloor: boolean = false;
      let beforeCeil: boolean = false;
      const parent = formControl.parent?.parent ? (formControl.parent.parent as FormArray) : null;
      const formArray = parent ? parent.controls : null;

      if (formArray) {
        const form = formArray.find((f: AbstractControl) => f.value.id === id);
        const index = formArray.indexOf(form);

        const dateTo = formControl.value ? parse(formControl.value, 'yyyy-MM-dd', new Date()) : null;
        const dateFrom = formArray[index].value.active_from ? parse(formArray[index].value.active_from, 'yyyy-MM-dd', new Date()) : null;

        if (dateTo === null || dateFrom === null) {
          return null;
        }

        // Check if active_to is older than the previous row's active_from.
        //If index equals first row, there is nothing to compare with, else compare active_to with active_from of previous row
        if (index == 0) {
          beforeCeil = true;
        } else {
          dateCeil = parse(formArray[index - 1].value.active_from, 'yyyy-MM-dd', new Date());
          beforeCeil = isBefore(dateTo, dateCeil);
        }

        // Check if active_to is more recent than active_from of the same row
        afterFloor = isAfter(dateTo, dateFrom);
      }

      // If at least one condition equals false, active_to is not valid
      if (!beforeCeil || !afterFloor) {
        valid = false;
      }

      return valid
        ? null
        : { isAfter: true,
          isBefore: true };
    }
  };
}
