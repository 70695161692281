/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const SURVEY_FORM_KEYS: Map<string, string> = new Map<string, string>([
  ['name', 'name'],
  ['description', 'description'],
  ['status', 'status'],
  ['questions', 'questions'],
  ['questionId', 'question_id'],
  ['parentId', 'parent_id'],
  ['validationRuleId', 'validation_rule_id'],
  ['isVisibleForInstitution', 'is_visible_for_institution'],
  ['isVisibleForPatient', 'is_visible_for_patient'],
  ['questionType', 'type'],
  ['answerType', 'answer_type'],
  ['question', 'question'],
  ['description', 'description'],
  ['answerOptions', 'answer_options'],
  ['answerOptionId', 'id'],
  ['answerOptionValue', 'value'],
  ['listPositionNumber', 'list_position_number'],
]);
