import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { format } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DateCardLayout } from '@modules/shared/cards/enums/date-card-layout.enum';
import { AppService } from '@services/app.service';
import { IObject } from '@app-types/iobject.type';
import { COLORS } from '@constants/colors.constant';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { BaseVisitProperties } from '@modules/shared/cards/interfaces/base-visit-properties.interface';
import { VisitCardLayout } from '@modules/shared/cards/enums/visit-card-layout.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-visit-card',
  templateUrl: './visit-card.component.html',
  styleUrls: ['./visit-card.component.scss'],
})
@UntilDestroy()
export class VisitCardComponent extends BreakpointObserverComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly AppointmentCardLayout: typeof VisitCardLayout = VisitCardLayout;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() skeletonIsEmpty: boolean;
  @Input() emptySkeletonMessage: string;
  @Input() visit: BaseVisitProperties;
  @Input() layout: VisitCardLayout;
  @Input() dateCardLayout: DateCardLayout;
  @Input() dateCardBackgroundColor: string;
  @Input() getDateCardBackgroundColorFunction: (date: Date) => string;
  @Input() isHighlightedFunction: (visit: BaseVisitProperties) => boolean;

  /**
   * We specifically use undefined instead of null so we can use null to set it to an empty template instead of the default one
   */
  @Input() actionsTemplate: TemplateRef<BaseVisitProperties> | undefined;

  appointmentTitleMobile: string;
  isHighlighted: boolean;

  constructor(breakpointObserver: BreakpointObserver, private appService: AppService) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    // Defaults
    this.layout = this.layout || VisitCardLayout.PRIMARY;

    if (this.visit?.start) {
      const translationKeys: string[] = [
        `common.days.${format(this.visit.start, DATE_FORMATS.dayNumber)}`,
        `common.months.${format(this.visit.start, DATE_FORMATS.monthNumber)}`,
      ];

      this.appService.getTranslationsByKeys$(translationKeys)
        .pipe(untilDestroyed(this))
        .subscribe((translations: IObject): void => {
        /* eslint-disable @typescript-eslint/restrict-plus-operands */
          this.appointmentTitleMobile =
          translations[translationKeys[0]] +
          ` ${format(this.visit.start, DATE_FORMATS.clientDay)} ` +
          translations[translationKeys[1]];
        /* eslint-enable @typescript-eslint/restrict-plus-operands */
        });
    }
  }
}
