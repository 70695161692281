import { Injectable } from '@angular/core';
import { BookingFlow } from '@enums/booking-flow.enum';
import { Institution } from '@models/institution.model';
import { ServiceType } from '@models/service-type.model';
import { Service } from '@models/service.model';
import { Booking } from '@modules/booking/models/booking.model';
import { BookingFlowFilter } from '@modules/booking/models/booking-flow-filter.model';
import { PendingBooking } from '@models/pending-booking.model';
import { PendingBookingService } from '@services/pending-booking.service';
import { Observable } from 'rxjs';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { IObject } from '@app-types/iobject.type';
import { tap } from 'rxjs/operators';

/**
 * Service to store the state of the booking flow, thus allowing to share data between components.
 */
@Injectable({
  providedIn: 'root',
})
export class BookingFlowStateService {
  private _pendingBookingId: string;
  private _flow: BookingFlow;
  private _scopedInstitution: Institution;
  private _services: Service[];
  private _serviceTypes: ServiceType[];
  private _booking: Booking;
  private _filter: BookingFlowFilter;

  constructor(private readonly pendingBookingService: PendingBookingService) {}

  get pendingBookingId(): string {
    return this._pendingBookingId;
  }

  set pendingBookingId(value: string) {
    this._pendingBookingId = value;
  }

  get flow(): BookingFlow {
    return this._flow;
  }

  set flow(value: BookingFlow) {
    this._flow = value;
  }

  get scopedInstitution(): Institution {
    return this._scopedInstitution;
  }

  set scopedInstitution(value: Institution) {
    this._scopedInstitution = value;
  }

  get services(): Service[] {
    return this._services;
  }

  set services(value: Service[]) {
    this._services = value;
  }

  get serviceTypes(): ServiceType[] {
    return this._serviceTypes;
  }

  set serviceTypes(value: ServiceType[]) {
    this._serviceTypes = value;
  }

  get booking(): Booking {
    return this._booking;
  }

  set booking(value: Booking) {
    this._booking = value;
  }

  get filter(): BookingFlowFilter {
    return this._filter;
  }

  set filter(value: BookingFlowFilter) {
    this._filter = value;
  }

  reset(flow: BookingFlow | null = null, institution: Institution | null = null): void {
    this.flow = flow ?? this._flow;
    this.scopedInstitution = institution;
    this.services = null;
    this.serviceTypes = null;
    this.booking = new Booking();
    this.filter = new BookingFlowFilter();
  }

  isEmpty(): boolean {
    return !this._pendingBookingId;
  }

  syncToServer$(): Observable<void> {
    const payload: IObject = this.toJson();

    return this.pendingBookingService.updatePendingBooking$(this._pendingBookingId, payload)
      .pipe(tap((pendingBooking: PendingBooking) => {
        this.writePendingBookingToState(pendingBooking);
      }))
      .pipe(switchToEmptyObservable());
  }

  confirm$(): Observable<void> {
    return this.pendingBookingService.book$(this._pendingBookingId)
      .pipe(tap((pendingBooking: PendingBooking) => {
        this.writePendingBookingToState(pendingBooking);
      }))
      .pipe(switchToEmptyObservable());
  }

  private writePendingBookingToState(pendingBooking: PendingBooking): void {
    this.pendingBookingId = pendingBooking.id;
    this.booking.visits = pendingBooking.visits;
  }

  toJson(): IObject {
    const json: IObject = {
      visits: this._booking.toJson(),
      data: {
        flow: this._flow,
        filter: this._filter.toJson(),
      },
    };

    if (this._scopedInstitution) {
      json.data.scoped_institution_id = this._scopedInstitution.id;
    }

    return json;
  }
}
