import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { isEqual, isSameDay, isWithinInterval } from 'date-fns';
import { EventsCalendarDateFormatterProvider } from '@modules/settings/components/planning-calendar/events-calendar-date-formatter-provider.service';
import { COLORS } from '@constants/colors.constant';
import { PopoverService } from '@services/ui/popover.service';
import { BehaviorSubject } from 'rxjs';
import { defaultPreferences } from '@interfaces/preferences.interface';
import { AngularCalendarScrollHelperService } from '@modules/shared/calendar/services/angular-calendar-scroll-helper.service';
import { AngularCalendarZoomHelperService } from '@modules/shared/calendar/services/angular-calendar-zoom-helper.service';
import { CalendarOpening } from '@modules/shared/calendar/components/events-calendar/calendar-opening.interface';
import { CalendarZoomPreset } from '@modules/shared/calendar/components/events-calendar/calendar-zoom-presets';
import { UntilDestroy } from '@ngneat/until-destroy';

export type EventsCalendarMode = 'day' | 'week' | 'month';

@Component({
  selector: 'vh-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: EventsCalendarDateFormatterProvider,
    },
  ],
})
@UntilDestroy()
export class EventsCalendarComponent implements OnInit, OnChanges, AfterViewChecked {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Input() calendarMode: EventsCalendarMode;
  @Input() selectedDate: Date = new Date();
  @Input() events: CalendarEvent[] = [];
  @Input() openings: CalendarOpening[] = [];
  @Input() locale: string;
  @Input() weekStartsOn: number = 1;
  @Input() showEventCounterBadges: boolean = true;
  @Input() zoomLevel: number = defaultPreferences.calendar.zoom;
  @Input() headerTemplate: TemplateRef<unknown>;
  @Input() eventTimeChangeConfirmationPopoverTemplate: TemplateRef<unknown>;
  @Input() eventActionsTemplate: TemplateRef<unknown>;
  @Input() eventTooltipTemplate: TemplateRef<unknown>;

  @Output() eventClick: EventEmitter<CalendarEvent> = new EventEmitter<CalendarEvent>();
  @Output() hourSegmentClick: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() monthViewDayClick: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() weekViewDayClick: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() eventTimeChange: EventEmitter<CalendarEventTimesChangedEvent> = new EventEmitter<CalendarEventTimesChangedEvent>();
  @Output() zoomLevelChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * A flag to indicate if the view has automatically scrolled to the first relevant element in the calendar
   */
  private didAutoScrollToFirstEvent: boolean = false;

  /**
   * A flag to indicate if the events have finished rendering
   */
  private didFinishRendering: boolean = false;

  currentCalendarZoomPreset: CalendarZoomPreset;
  readonly zoomStepSize: number = 1;

  /**
   * This variable keeps track of when the eventTimesChanged event was triggered
   * We keep track of this event because on Firefox a drag event is also a click event which results in a double event
   * when we only want one or the other. After a small delay we set the state back to false but meanwhile the click event
   * can use this variable to check if an eventTimesChanged event was recently triggered
   * @link https://stackoverflow.com/questions/65849546/preventing-eventclick-trigger-when-drag-and-dropping-events-using-angular-calend
   * @private
   */
  private eventTimesChangedWasTriggered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly scrollHelper: AngularCalendarScrollHelperService,
    private readonly zoomHelper: AngularCalendarZoomHelperService,
    private readonly popoverService: PopoverService
  ) {
    this.scrollHelper.calendarElementRef = elementRef;
    this.currentCalendarZoomPreset = this.zoomHelper.getZoomPresetForZoomlevel(this.zoomLevel);
  }

  ngOnInit(): void {
    this.normaliseZoomLevel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events && !changes.events.firstChange && changes.events.currentValue.length !== changes.events.previousValue.length) {
      this.didFinishRendering = false;
    }

    if (changes.selectedDate && !changes.selectedDate.isFirstChange()) {
      this.scrollHelper.bestEffortScrollToRelevantViewportInCalendar();
    }

    if (changes.calendarMode && !changes.calendarMode.isFirstChange()) {
      this.scrollHelper.bestEffortScrollToRelevantViewportInCalendar();
    }

    if (changes.zoomLevel && !changes.zoomLevel.firstChange) {
      this.normaliseZoomLevel();
    }
  }

  ngAfterViewChecked(): void {
    if (this.events?.length === this.scrollHelper.getAllCalendarEventElements().length && !this.didFinishRendering) {
      this.onFinishedRenderingEvents();
    }
  }

  private onFinishedRenderingEvents(): void {
    this.didFinishRendering = true;

    if (!this.didAutoScrollToFirstEvent && this.events.length > 0) {
      this.scrollHelper.scrollToFirstEventInCalendar();
      this.didAutoScrollToFirstEvent = true;
    }
  }

  onEventClicked($event: { event: CalendarEvent; sourceEvent: MouseEvent | KeyboardEvent; }): void {
    if (this.eventTimesChangedWasTriggered$.value) {
      return;
    }

    this.eventClick.emit($event.event);
  }

  onHourSegmentClicked(date: Date): void {
    this.hourSegmentClick.emit(date);
  }

  onMonthViewDayClicked(date: Date): void {
    this.monthViewDayClick.emit(date);
  }

  onDayHeaderClicked(date: Date): void {
    this.weekViewDayClick.emit(date);
  }

  onEventTimesChanged(changedEvent: CalendarEventTimesChangedEvent): void {
    this.eventTimesChangedWasTriggered$.next(true);
    setTimeout(() => {
      this.eventTimesChangedWasTriggered$.next(false);
    }, 100);

    if (
      !this.canReschedule(changedEvent) ||
      isEqual(changedEvent.newStart, changedEvent.event.start) && isEqual(changedEvent.newEnd, changedEvent.event.end)
    ) {
      return;
    }

    this.eventTimeChange.emit(changedEvent);

    if (this.eventTimeChangeConfirmationPopoverTemplate && changedEvent.event.meta?.popoverId) {
      this.popoverService.open(changedEvent.event.meta.popoverId);
    }
  }

  getEventsCountForDay(day: Date): number {
    return this.events.filter((event: CalendarEvent) => isSameDay(event.start, day))?.length ?? 0;
  }

  //TODO: I assume this isn't meant to always return true?
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  canReschedule(changedEvent: CalendarEventTimesChangedEvent): boolean {
    return true;
    // return !isPast(changedEvent.newStart);
  }

  updateZoomLevel(stepSize: number): void {
    if (this.calendarMode === 'month') {
      return;
    }

    this.zoomLevel = this.zoomHelper.changeZoomLevel(this.zoomLevel, stepSize);
    this.currentCalendarZoomPreset = this.zoomHelper.getZoomPresetForZoomlevel(this.zoomLevel);

    this.zoomLevelChange.emit(this.zoomLevel);
  }

  isDateTimeWithinOpeningInterval(opening: CalendarOpening, dateTime: Date): boolean {
    return isWithinInterval(dateTime, { start: opening.start, end: opening.end });
  }

  getOpeningsForDay(day: Date): CalendarOpening[] {
    return this.openings?.filter((opening: CalendarOpening) => isSameDay(opening.start, day)) ?? [];
  }

  private normaliseZoomLevel(): void {
    this.zoomLevel = this.zoomHelper.normaliseZoomLevel(this.zoomLevel);
    this.currentCalendarZoomPreset = this.zoomHelper.getZoomPresetForZoomlevel(this.zoomLevel);
  }
}
