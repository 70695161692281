<label *ngIf="label" [innerHTML]="label"></label>
<div class="flex-col">
  <textarea
    [ngClass]="{
      error: hasError,
      'not-resizable': !resizable,
      'is-disabled': isDisabled,
      'wrap-text': wrapText,
    }"
    [placeholder]="placeholder || ''"
    [ngModel]="value"
    [disabled]="isDisabled"
    [readOnly]="isReadonly"
    (ngModelChange)="handleValueChangeWithCheck($event)"
    (blur)="handleValueChangeOnBlurWithCheck()">
  </textarea>
  <vh-icon
    *ngIf="iconTopRightSrc"
    [src]="iconTopRightSrc"
    [width]="iconTopRightWidth"
    [height]="iconTopRightHeight"
    [fill]="iconTopRightFill"
    [hoverFill]="iconTopRightHoverFill">
  </vh-icon>
  <p
    *ngIf="message"
    [vhMarginTop]="8"
    class="has-text-tertiary font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ message }}
  </p>
  <p
    *ngIf="errorMessage"
    [vhMarginTop]="8"
    class="has-text-error font-light-12-14"
    [ngClass]="{ 'align-end': messageVerticalAlignment === 'end', 'align-center': messageVerticalAlignment === 'center' }">
    {{ errorMessage }}
  </p>
</div>
