import { Question, QuestionJson } from '@models/question.model';
import { Option } from '@interfaces/option.interface';
import { parseISO } from 'date-fns';
import { TranslatedProperty } from '@models/translated-property.model';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { SettingsCardStatus, settingsCardStatusTranslationKey } from '@modules/settings/enums/settings-card-status.enum';
import { SettingsCard } from '@modules/settings/interfaces/settings-card.interface';

export class Survey implements SettingsCard {
  constructor(
    private _id: string,
    private _name: TranslatedProperty,
    private _description: TranslatedProperty,
    private _status: SettingsCardStatus,
    private _questions: Question[],
    private _isActive?: boolean,
    private _activeFrom?: Date,
    private _activeTo?: Date
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): TranslatedProperty {
    return this._name;
  }

  get description(): TranslatedProperty {
    return this._description;
  }

  get status(): SettingsCardStatus {
    return this._status;
  }

  get statusTranslationKey(): string {
    return settingsCardStatusTranslationKey(this._status);
  }

  get questions(): Question[] {
    return this._questions;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get activeFrom(): Date {
    return this._activeFrom;
  }

  set activeFrom(value: Date) {
    this._activeFrom = value;
  }

  get activeTo(): Date {
    return this._activeTo;
  }

  set activeTo(value: Date) {
    this._activeTo = value;
  }

  get isReadOnly(): boolean {
    return this.status === SettingsCardStatus.ARCHIVED;
  }

  get isEditable(): boolean {
    return this.status !== SettingsCardStatus.ACTIVE;
  }

  readonly isDuplicable: boolean = false;

  static fromJson(json: SurveyJson): Survey {
    return json
      ? new Survey(
        json.id,
        new TranslatedProperty(json.name),
        new TranslatedProperty(json.description),
        json.status ? SettingsCardStatus[json.status] : SettingsCardStatus.DRAFT,
        json.questions?.map(Question.fromJson) ?? null,
        json.is_active,
        json.active_from ? parseISO(json.active_from) : null,
        json.active_to ? parseISO(json.active_to) : null
      )
      : null;
  }

  toJson(): SurveyJson {
    return {
      id: this._id,
      name: this._name.get(),
      description: this._description.get(),
      status: this._status,
      questions: this._questions.map((q: Question) => q.toJson()),
      is_active: this._isActive,
      active_from: getTimeStringWithTimeZone(this._activeFrom),
      active_to: getTimeStringWithTimeZone(this._activeTo),
    };
  }

  toOption(): Option {
    return {
      label: this._name.get(),
      value: this.id,
    };
  }
}

export interface SurveyJson {
  id: string;
  name: string | Record<string, string>;
  description: string | Record<string, string>;
  status: string;
  questions: QuestionJson[];
  is_active: boolean;
  active_from: string;
  active_to: string;
}
