import { NgModule } from '@angular/core';
import { CoreModule } from '@modules/shared/core/core.module';
import { CancelVisitFormComponent } from '@modules/settings/components/cancel-visit-form/cancel-visit-form.component';
import { FormModule } from '@modules/shared/form/form.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [CancelVisitFormComponent];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormModule,
  ],
  exports: sharedIngredients,
})
export class SettingsSharedModule { }
