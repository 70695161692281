import { Service, ServiceJson } from '@models/service.model';
import { Task, TaskJson } from '@models/task.model';
import { User, UserJson } from '@models/user.model';
import { Institution, InstitutionJson } from '@models/institution.model';
import { Room, RoomJson } from '@models/room.model';
import { addMinutes, differenceInMinutes, format, isAfter } from 'date-fns';
import { VisitCancellationAction, VisitCancellationActionJson } from '@models/visit-cancellation-action.model';
import { VisitStatus, VisitStatusJson } from '@models/visit-status.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { BaseVisitProperties } from '@modules/shared/cards/interfaces/base-visit-properties.interface';
import { VirtualSlot } from '@models/virtual-slot.model';
import { IObject } from '@app-types/iobject.type';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { VisitType } from '@enums/visit-type.enum';

export class Visit implements BaseVisitProperties {
  constructor(
    protected _id: string | null = null,
    protected _service?: Service,
    protected _customTitle?: string,
    protected _recurrenceId?: string,
    protected _visitType?: VisitType,
    protected _start?: Date,
    protected _end?: Date,
    protected _statusHistory?: VisitStatus[],
    protected _patient?: User | null,
    protected _institution?: Institution,
    protected _room?: Room,
    protected _tasks?: Task[],
    protected _cancellationAction?: VisitCancellationAction,
    protected _internalComment?: string,
    protected _externalComment?: string,
    protected _paymentConfirmedAt?: Date,
    protected _referringPhysicianName?: string,
    protected _creatorName?: string,
    protected _isLeading: boolean = true,
    protected _cancelOverlappingVisits: boolean = false
  ) {}

  get id(): string {
    return this._id ?? null;
  }

  get start(): Date {
    return this._start;
  }

  set start(value: Date) {
    this._start = value;
  }

  get end(): Date {
    return this._end;
  }

  set end(value: Date) {
    this._end = value;
  }

  get service(): Service {
    return this._service;
  }

  set service(value: Service) {
    this._service = value;
  }

  get customTitle(): string {
    return this._customTitle;
  }

  set customTitle(value: string) {
    this._customTitle = value;
  }

  get recurrenceId(): string {
    return this._recurrenceId;
  }

  set recurrenceId(value: string) {
    this._recurrenceId = value;
  }

  get visitType(): VisitType {
    return this._visitType;
  }

  set visitType(value: VisitType) {
    this._visitType = value;
  }

  get institution(): Institution {
    return this._institution;
  }

  get patient(): User | null {
    return this._patient;
  }

  set patient(value: User | null) {
    this._patient = value;
  }

  get room(): Room {
    return this._room;
  }

  get tasks(): Task[] {
    return this._tasks;
  }

  get multilineTitle(): string {
    let value: string = `${format(this.start, DATE_FORMATS.clientTimeShort)} (${differenceInMinutes(this.end, this.start)} min)`;

    if (this.patient?.fullName) {
      value += `<br />${this.patient.fullName}`;
    }

    if (this.patient?.birthdate) {
      value += ` °${format(this.patient.birthdate, DATE_FORMATS.clientDate)}`;
    }

    if (this.service) {
      value += `<br />${this.service.name}`;
    }

    if (this.internalComment) {
      value += '*';
    }

    return value;
  }

  get title(): string {
    return this.customTitle?.trim() ? this.customTitle : this.multilineTitle.replace(/<br \/>/g, ' - ');
  }

  get isPast(): boolean {
    return isAfter(new Date(), this.end);
  }

  get cancellationAction(): VisitCancellationAction {
    return this._cancellationAction;
  }

  get internalComment(): string {
    return this._internalComment;
  }

  set internalComment(value: string) {
    this._internalComment = value;
  }

  get externalComment(): string {
    return this._externalComment;
  }

  set externalComment(value: string) {
    this._externalComment = value;
  }

  get wasPaid(): boolean {
    return !!this._paymentConfirmedAt;
  }

  get paymentConfirmedAt(): Date {
    return this._paymentConfirmedAt;
  }

  set paymentConfirmedAt(value: Date | null) {
    this._paymentConfirmedAt = value;
  }

  get allTasks(): Task[] {
    return [
      ...this._service?.tasks ?? [],
      ...this._tasks ?? [],
    ];
  }

  get statusHistory(): VisitStatus[] {
    return this._statusHistory;
  }

  set statusHistory(value: VisitStatus[]) {
    this._statusHistory = value;
  }

  get status(): VisitStatus | null {
    return this._statusHistory?.length > 0 ? this._statusHistory[0] : null;
  }

  get wasExecuted(): boolean {
    return this.status?.key === VisitStatus.EXECUTED;
  }

  get referringPhysicianName(): string {
    return this._referringPhysicianName;
  }

  set referringPhysicianName(value: string) {
    this._referringPhysicianName = value;
  }

  get creatorName(): string {
    return this._creatorName;
  }

  set creatorName(value: string) {
    this._creatorName = value;
  }

  get isLeading(): boolean {
    return this._isLeading;
  }

  set isLeading(value: boolean) {
    this._isLeading = value;
  }

  get cancelOverlappingVisits(): boolean {
    return this._cancelOverlappingVisits;
  }

  set cancelOverlappingVisits(value: boolean) {
    this._cancelOverlappingVisits = value;
  }

  get virtualSlot(): VirtualSlot {
    return new VirtualSlot(this._institution, this._service ?? null, this._start, this._end, null, this, this._room);
  }

  getTaskByKey(key: string): Task {
    return this.allTasks.find((task: Task) => task.key === key);
  }

  getStatusByKey(key: string): VisitStatus {
    return this.statusHistory.find((status: VisitStatus) => status.key === key);
  }

  static fromJson(json: VisitJson): Visit {
    return new Visit(
      json?.id,
      json.service ? Service.fromJson(json.service) : null,
      json.custom_title,
      json.recurrence_id ?? null,
      json.visit_type,
      json.start ? new Date(json.start) : null,
      json.end ? new Date(json.end) : null,
      json.status_history ? json.status_history.map(VisitStatus.fromJson) : [],
      json.user ? User.fromJson(json.user) : null,
      Institution.fromJson(json.institution),
      Room.fromJson(json.room),
      json.tasks ? json.tasks.map((task: Task) => Task.fromJson(task)) : [],
      json.cancellation_action ? VisitCancellationAction.fromJson(json.cancellation_action) : null,
      json.internal_comment,
      json.external_comment,
      json.payment_confirmed_at ? new Date(json.payment_confirmed_at) : null,
      json.referring_physician_name ?? null,
      json.creator_name ?? null,
      json.is_leading,
      json.cancel_overlapping_visits
    );
  }

  toJson(): IObject {
    return {
      id: this.id,
      is_leading: this.isLeading,
      cancel_overlapping_visits: this.cancelOverlappingVisits,
      service_id: this.service?.id,
      custom_title: this.customTitle,
      recurrence_id: this.recurrenceId ?? null,
      visit_type: this.visitType,
      start: this.start ? getTimeStringWithTimeZone(this.start) : null,
      end: this.end ? getTimeStringWithTimeZone(this.end) : null,
      external_comment: this.externalComment,
      internal_comment: this.internalComment,
      user_id: this.patient?.id ?? null,
    };
  }

  static createDummy(startTime?: Date): Visit {
    startTime = startTime ?? new Date();
    const endTime = addMinutes(startTime, 15);

    return new Visit(
      null,
      null,
      null,
      null,
      null,
      startTime,
      endTime,
      [],
      null,
      null,
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}

export interface VisitJson {
  id: string;
  user_id?: string | null;
  service?: ServiceJson | null;
  custom_title?: string | null;
  visit_type?: VisitType | null;
  start?: string | null;
  end?: string | null;
  status_history?: VisitStatusJson[] | null;
  tasks?: TaskJson[];
  user?: UserJson | null;
  institution?: InstitutionJson | null;
  room?: RoomJson | null;
  cancellation_action?: VisitCancellationActionJson | null;
  internal_comment?: string | null;
  external_comment?: string | null;
  payment_confirmed_at?: Date | null;
  referring_physician_name?: string | null;
  creator_name?: string;
  is_leading: boolean;
  cancel_overlapping_visits: boolean;
  recurrence_id?: string | null;
}
