export const DATE_FORMATS = {
  serverDate: 'yyyy-MM-dd',
  serverDateTime: 'yyyy-MM-dd HH:mm:ss',
  serverDateTimeShort: 'yyyy-MM-dd HH:mm',
  clientMonthYear: 'MM/yy',
  clientDate: 'dd/MM/yyyy',
  clientDay: 'd',
  clientYear: 'yyyy',
  clientTime: 'HH:mm:ss',
  clientTimeShort: 'HH:mm',
  clientDateTimeShort: 'dd-MM-yyyy HH:mm',
  clientDateTimeComma: 'dd/MM/yyyy, HH:mm',
  clientDateTimeNoYear: 'dd/MM HH:mm',
  dayNumber: 'i',
  monthNumber: 'M',
};
