import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { Option } from '@interfaces/option.interface';
import { BaseSelectInputComponent } from '@modules/shared/form/components/base-select-input/base-select-input.component';
import { COLORS } from '@constants/colors.constant';

@Component({
  selector: 'vh-suggestions-text-input',
  templateUrl: './suggestions-text-input.component.html',
  styleUrls: [
    './suggestions-text-input.component.scss',
    '../base-select-input/base-select-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SuggestionsTextInputComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class SuggestionsTextInputComponent extends BaseSelectInputComponent implements OnInit, OnChanges {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() allowSuggestionsOnly: boolean;
  @Input() suggestionOnClick: boolean;
  @Input() getOptionsFunction$: (searchValue: string | null) => Observable<Option[]>;
  @Input() loading: boolean;

  private onInputValueChange$: Subject<string> = new Subject<string>();
  private valueChangeSubscription: Subscription;

  filteredOptions: Option[];

  ngOnInit(): void {
    super.ngOnInit();

    if (this.getOptionsFunction$ && !this.loading) {
      this.initListener();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.getOptionsFunction$ || changes.loading) {
      this.initListener();
      this.fetchOptions();
    }
  }

  private fetchOptions(): void {
    this.getOptionsFunction$('')
      .pipe(untilDestroyed(this), catchError(() => EMPTY))
      .subscribe((options: Option[]) => {
        this.options = this.filteredOptions = options;
        this.handleValueChangeWithCheck(this.value);
        this.updateDisplayedValue();
      });
  }

  handleInputClick(): void {
    if (this.suggestionOnClick) {
      this.displayedValue = '';

      this.getOptionsFunction$(this.displayedValue)
        .pipe(untilDestroyed(this), catchError(() => this.resetOptions()))
        .subscribe((options: Option[]) => {
          this.options = this.filteredOptions = options;
          this.popoverService.open(this.popoverId);
          this.hasError = false;
        });
    }
  }

  handleInputChange = (value: string): void => {
    if (this.allowSuggestionsOnly && this.getOptionsFunction$) {
      this.handleValueChangeWithCheck(null);
    } else if (this.allowSuggestionsOnly) {
      const option = this.options.find((o: Option) => o.value === value);
      this.handleValueChangeWithCheck(option ? value : null);
    } else {
      this.handleValueChangeWithCheck(value);
    }

    if (this.getOptionsFunction$) {
      this.onInputValueChange$.next(value);
    } else {
      this.filteredOptions = this.options?.filter((option: Option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      this.popoverService.toggle(this.popoverId);
    }

    this.displayedValue = value;
  };

  protected initListener(): void {
    this.valueChangeSubscription?.unsubscribe();
    this.valueChangeSubscription = this.onInputValueChange$
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        switchMap((value: string) =>
          this.getOptionsFunction$(value).pipe(catchError(() => this.resetOptions()))
        )
      )
      .subscribe((options: Option[]) => {
        this.options = this.filteredOptions = options;
        this.popoverService.open(this.popoverId);
        this.hasError = false;
      });
  }

  private resetOptions(): Observable<never> {
    this.options = this.filteredOptions = null;
    this.popoverService.close(this.popoverId);

    if (this.allowSuggestionsOnly) {
      this.hasError = true;
    }

    return EMPTY;
  }
}
