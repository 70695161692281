<h1 *ngIf="title">{{ title }}</h1>
<p *ngIf="subtitle">{{ subtitle }}</p>
<ng-container *ngIf="state">
  <vh-search-input
    *ngIf="showSearch"
    [vhMarginTop]="24"
    [placeholder]="searchPlaceholder"
    [value]="searchValue"
    [(ngModel)]="searchValue"
    (inputValueChange)="state.filter($event)">
  </vh-search-input>
  <div [vhMarginTop]="24">
    <vh-message
      *ngIf="state.parentOfCurrentItem"
      iconLeftPath="assets/icons/arrow-left.svg"
      class="clickable"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-primary)'"
      [iconLeftFill]="COLORS.primary"
      [iconLeftHoverFill]="COLORS.primary"
      [text]="state.parentOfCurrentItem.name"
      (messageClick)="onGoLevelBackClicked()">
    </vh-message>
    <div [vhMarginTop]="8" [ngClass]="state.currentItem ? 'flex-row gap-h-32' : null">
      <div class="items-container">
        <vh-skeleton-group *ngIf="!state.displayedItems" [template]="itemTemplate"></vh-skeleton-group>
        <div class="flex-col gap-v-16 items-list">
          <ng-container
            *ngFor="let item of state.displayedItems"
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ item: item }">
          </ng-container>
        </div>
        <vh-message
          *ngIf="state.displayedItems?.length === 0"
          [text]="'common.noResults' | translate">
        </vh-message>
        <vh-message
          class="not-clickable"
          [vhMarginTop]="16"
          [vhTextColor]="'var(--color-tertiary)'"
          [text]="'pages.createAppointment.step1.contactDoctorWhenInDoubtText' | translate">
        </vh-message>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>

<ng-template #itemTemplate let-item="item">
  <div
    class="flex-row gap-h-16 border clickable item-card has-background-grey"
    [ngClass]="{
      selected: state.isSelected(item)
    }"
    (click)="onItemClicked(item)">
    <div class="info">
      <h2 vhSkeleton [skeletonIsVisible]="!state.displayedItems" [skeletonWidth]="'50%'" [skeletonHeight]="22">
        {{ item?.alternativeName ?? item?.name }}
      </h2>
      <p *ngIf="item?.description" vhSkeleton [skeletonIsVisible]="!state.displayedItems" [skeletonHeight]="22">{{
        item.description }}</p>
    </div>
    <vh-icon *ngIf="item?.children?.length" src="assets/icons/arrow-right.svg" [fill]="COLORS.secondary"></vh-icon>
    <vh-checkbox *ngIf="state.isSelected(item)" [value]="true" [isDisabled]="true"
      [allowDisabledStyle]="false"></vh-checkbox>
  </div>
</ng-template>