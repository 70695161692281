<div class="container gap-v-16" vhAnswerListTracker [trackingData]="answerList" [disableAutomaticClickTracking]="true">
  <div class="gap-h-16">
    <h1>
      <img
        class="clickable arrow"
        (click)="navigateBack()"
        src="assets/icons/arrow-left-secondary.svg"
        alt="{{ 'common.navigateBack' | translate }}" />{{ formGroup.get('survey_name').value }}
    </h1>

    <div class="flex-row-s-c gap-h-8 card clickable patient-card" [formGroup]="formGroup" [vhMarginTop]="32">
      <div class="patient-group">
        <h2>{{ formGroup.get('first_name').value }} {{ formGroup.get('last_name').value }}</h2>
        <p>{{ formGroup.get('visit_name').value }}</p>
      </div>

      <div
        popoverConnectToFirstHtmlElementWithTagName="vh-work-lists-page"
        [vhPopoverHost]="POPUP_ID"
        [popoverTemplate]="popupTemplate"
        [popoverHasBackdrop]="true"
        [popoverPreferredPosition]="popoverPreferredPositioning"
        (click)="onSelectInputClicked(formGroup)">
        <vh-select-input formControlName="status" [options]="statusOptions" [isDisabled]="true"></vh-select-input>
      </div>
    </div>

    <div [vhMarginTop]="32">
      <h2>Vragen</h2>
      <div *ngIf="answerListFormGroup" [formGroup]="answerListFormGroup">
        <ng-container formArrayName="answers">
          <div
            class="gap-v-8 answer-list"
            [formGroupName]="index"
            *ngFor="let answer of answerListFormGroup.controls.answers.controls; let index = index"
            [vhMarginTop]="32">
            <!--<strong><p>{{ answer.get('question').value | translate  }}</p>
            </strong>-->
            <p class="question">{{ answer.get('question').value | translate }}</p>

            <ng-container [ngSwitch]="answer.value.type">
              <ng-container *ngSwitchCase="'TEXT'">
                <p>{{ answer.get('value_text').value }}</p>
              </ng-container>

              <ng-container *ngSwitchCase="'CHECKBOX'">
                <ul class="gap-v-4 answer-options-list">
                  <ng-container *ngFor="let answerOption of answer.value.values_answer_option">
                    <li>{{ answerOption.value }}</li>
                  </ng-container>
                </ul>
              </ng-container>

              <ng-container *ngSwitchCase="'RADIO'">
                <p>{{ answer.get('values_answer_option').value[0].value }}</p>
              </ng-container>

              <ng-container *ngSwitchCase="'BOOLEAN'">
                <p *ngIf="answer.value.value_boolean === true">Ja</p>
                <p *ngIf="answer.value.value_boolean === false">Nee</p>
                <p *ngIf="answer.value.value_boolean === null">Geen antwoord</p>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #popupTemplate>
    <div class="pop-up-container">
      <vh-answer-list-argumentation-popup
        vhOutsideClick
        [optionClick]="selectOption"
        [formGroup]="selectedFormGroup"
        [answerList]="answerList"
        [statusOptions]="options">
      </vh-answer-list-argumentation-popup>
    </div>
  </ng-template>
</div>
