import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { CoreModule } from '@modules/shared/core/core.module';
import { PrivacyStatementPageComponent } from '@modules/public/privacy-statement/pages/main/privacy-statement-page.component';

const routes: Routes = [
  {
    path: NAVIGATION.privacyStatement.path,
    component: PrivacyStatementPageComponent,
  },
];

@NgModule({
  declarations: [PrivacyStatementPageComponent],
  imports: [
    // Angular modules
    RouterModule.forChild(routes),
    CoreModule,
  ],
  exports: [PrivacyStatementPageComponent],
})
export class PrivacyStatementModule { }
