/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const ANSWER_LIST_FORM_KEYS: Map<string, string> = new Map<string, string>([
  ['id', 'id'],
  ['status', 'status'],
  ['visitId', 'visit_id'],
  ['name', 'name'],
  ['description', 'description'],
  ['answers', 'answers'],
  ['answerId', 'id'],
  ['answerListId', 'answer_list_id'],
  ['questionId', 'question_id'],
  ['listPositionNumber', 'list_position_number'],
  ['isVisibleForInstitution', 'is_visible_for_institution'],
  ['isVisibleForPatient', 'is_visible_for_patient'],
  ['answerType', 'answer_type'],
  ['parentId', 'parent_id'],
  ['question', 'question'],
  ['type', 'type'],
  ['answerOptions', 'answer_options'],
  ['valueBoolean', 'value_boolean'],
  ['valueDate', 'value_date'],
  ['valueText', 'value_text'],
  ['valuesAnswerOption', 'values_answer_option'],
  ['selectedOption', 'selected_option'],
]);
