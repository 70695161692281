import { Component, OnInit } from '@angular/core';
import { WalletTransaction } from '@models/wallet-transaction.model';
import { WalletService } from '@services/wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';
import { ButtonGroupStyle } from '@enums/button-style.enum';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { Subscription } from 'rxjs';

type ViewingOption = 'ALL' | 'INCOMING' | 'OUTGOING';

@Component({
  selector: 'vh-wallet-history',
  templateUrl: './wallet-history.component.html',
  styleUrls: ['./wallet-history.component.scss'],
})
@UntilDestroy()
export class WalletHistoryComponent implements OnInit {
  protected readonly ButtonGroupStyle: typeof ButtonGroupStyle = ButtonGroupStyle;

  isLoading: boolean = true;
  fetchingWalletTransactionsSubscription: Subscription | null = null;
  currentPage: number = 1;
  walletTransactions: WalletTransaction[] = [];

  selectedViewingOption: ViewingOption = 'ALL';
  viewingOptions: ButtonOption[] = [
    {
      id: 'ALL',
      label: 'wallet.history.viewingModes.all',
      isActive: true,
      isClickable: true,
    },
    {
      id: 'INCOMING',
      label: 'wallet.history.viewingModes.incoming',
      isActive: false,
      isClickable: true,
    },
    {
      id: 'OUTGOING',
      label: 'wallet.history.viewingModes.outgoing',
      isActive: false,
      isClickable: true,
    },
  ];

  constructor(private readonly walletService: WalletService) {}

  ngOnInit(): void {
    this.fetchWalletTransactionHistory();
  }

  onViewingOptionClicked(clickedIndex: number): void {
    const selectedViewingOption = this.viewingOptions[clickedIndex].id as ViewingOption;

    if (this.selectedViewingOption === selectedViewingOption) {
      return;
    }

    this.selectedViewingOption = selectedViewingOption;
    this.resetPagination();
    this.fetchWalletTransactionHistory();
  }

  onScrollEnd(): void {
    this.currentPage += 1;
    this.fetchWalletTransactionHistory();
  }

  private fetchWalletTransactionHistory(): void {
    this.fetchingWalletTransactionsSubscription?.unsubscribe();
    this.fetchingWalletTransactionsSubscription = this.walletService
      .getWalletTransactionHistory$(this.selectedViewingOption, this.currentPage)
      .pipe(untilDestroyed(this))
      .subscribe((transactions: PaginatedModel<WalletTransaction>) => {
        this.walletTransactions = [...this.walletTransactions, ...transactions.data];
        this.isLoading = false;
      });
  }

  private resetPagination(): void {
    this.walletTransactions = [];
    this.currentPage = 1;
    this.isLoading = true;
  }
}
