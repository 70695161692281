import { Component,
  ElementRef,
  forwardRef,
  HostListener,
  OnChanges,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { COLORS } from '@constants/colors.constant';
import { Option } from '@interfaces/option.interface';
import { BaseSelectInputComponent } from '@modules/shared/form/components/base-select-input/base-select-input.component';

@Component({
  selector: 'vh-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: [
    './select-input.component.scss',
    '../base-select-input/base-select-input.component.scss',
    '../base-input/base-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent extends BaseSelectInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  protected readonly COLORS: typeof COLORS = COLORS;

  @Input() defaultValue: string;
  @Input() maxHeight: string;
  @ViewChild('container') rootContainer: ElementRef<HTMLDivElement> | null = null;

  protected highlightedIndex: number = 0;
  protected popoverWidth: number = 0;
  private rootContainerResizeObserver: ResizeObserver | null = null;

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.defaultValue;
    this.updateDisplayedValue();
  }

  ngAfterViewInit(): void {
    this.observeRootContainerResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.reset();
    }

    if (changes.value && changes.value?.currentValue !== changes.value?.previousValue) {
      this.writeValue(changes.value.currentValue);
    }

    if (changes.defaultValue !== undefined && changes.defaultValue?.currentValue !== changes.defaultValue?.previousValue) {
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    this.rootContainerResizeObserver?.disconnect();
  }

  writeValue(value: string): void {
    super.writeValue(value);

    // If value is empty, set empty value, else set value
    if (value === '') {
      this.value = value;
    } else {
      this.value = this.value || value || null;
    }

    this.updateDisplayedValue();
  }

  toggleOptions(): void {
    this.popoverService.toggle(this.popoverId);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (this.popoverService.isOpen(this.popoverId)) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.options.length - 1) {
            this.highlightedIndex++;
          }
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          break;
        case 'Enter':
          this.handleOptionClick(this.options[this.highlightedIndex].value);
          break;
        default: {
          const index: number = this.options.findIndex((option: Option): boolean => {
            return option.label.toLowerCase().startsWith(event.key);
          });
          if (index > -1) {
            this.highlightedIndex = index;
            document.getElementById(index.toString()).scrollIntoView();
          }
        }
      }
    }
  }

  updateDisplayedValue(): void {
    super.updateDisplayedValue();

    if (this.options) {
      this.highlightedIndex = this.options.findIndex((v: Option) => v.value === this.value);
    }
  }

  /**
   * Observes the root container for resize events, this way we can bind the width of the container correctly to the width of the popover
   * (the popover containing the options of the select input)
   */
  protected observeRootContainerResize(): void {
    if (this.rootContainerResizeObserver) {
      return;
    }

    this.rootContainerResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.popoverWidth = entries[0].contentRect.width;
    });

    this.rootContainerResizeObserver.observe(this.rootContainer.nativeElement);
  }

  protected reset(): void {
    this.highlightedIndex = 0;
    this.updateDisplayedValue();
  }
}
