import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObject } from '@app-types/iobject.type';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';
import { User } from '@models/user.model';

@Injectable({
  providedIn: 'root',
})
export class NewOnboardingService {
  constructor(
    private readonly http: HttpClient
  ) {}

  private onboardingPayloadKey: string = LOCAL_STORAGE_KEYS.onboardingPayload;

  setOnboardingPayload(payload: IObject): void {
    localStorage.setItem(this.onboardingPayloadKey, JSON.stringify(payload));
  }

  getOnboardingPayload(): IObject {
    const payload = localStorage.getItem(this.onboardingPayloadKey);

    return payload ? JSON.parse(payload) : null;
  }

  clearData(): void {
    localStorage.removeItem(this.onboardingPayloadKey);
  }

  onboard$ = (data: IObject): Observable<User> => {
    return this.http
      .post(environment.apiBaseUrl + ENDPOINTS.onboard.route, data)
      .pipe(map(User.fromJson));
  };
}
