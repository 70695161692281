<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.institution.title' | translate }}</h1>
</section>

<form class="flex-col gap-v-16" [vhMarginTop]="16" [formGroup]="formGroup">
  <vh-text-input
    [formControlName]="CREATE_INSTITUTION_FORM_KEYS.get('name')"
    [label]="'onboarding.institution.organisationName' | translate">
  </vh-text-input>
  <vh-select-input
    [options]="countryOptions"
    [label]="'new.onboarding.institution.country' | translate"
    [placeholder]="'new.onboarding.institution.countryPlaceholder' | translate"
    [formControlName]="CREATE_INSTITUTION_FORM_KEYS.get('country')">
  </vh-select-input>
  <vh-button
    *ngIf="!isSubmitting"
    class="align-center"
    iconRightPath="assets/icons/arrow-narrow-right.svg"
    [iconRightStroke]="COLORS.white"
    [isDisabled]="formGroup.invalid"
    [label]="'common.next' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>

  <vh-loader *ngIf="isSubmitting"></vh-loader>
</form>