import { RecurrenceFrequency } from '@enums/recurrence-frequency.enum';
import { ServiceGroup, ServiceGroupJson } from '@models/service-group.model';
import { Service, ServiceJson } from '@models/service.model';
import { Doctor, DoctorJson } from '@models/doctor.model';

export class Recurrence {
  constructor(
    private _id: string | null,
    private _start: Date,
    private _end: Date,
    private _duration: number,
    private _amount: number | null,
    private _frequency: RecurrenceFrequency,
    private _interval: number,
    private _serviceGroup: ServiceGroup | null = null,
    private _modality: Service | null = null,
    private _doctor: Doctor | null = null
  ) {}

  get id(): string | null {
    return this._id;
  }

  get start(): Date {
    return this._start;
  }

  set start(value: Date) {
    this._start = value;
  }

  get end(): Date {
    return this._end;
  }

  set end(value: Date) {
    this._end = value;
  }

  get duration(): number {
    return this._duration;
  }

  get amount(): number | null {
    return this._amount;
  }

  get frequency(): RecurrenceFrequency {
    return this._frequency;
  }

  set frequency(value: RecurrenceFrequency) {
    this._frequency = value;
  }

  get interval(): number {
    return this._interval;
  }

  get serviceGroup(): ServiceGroup | null {
    return this._serviceGroup;
  }

  get doctor(): Doctor | null {
    return this._doctor;
  }

  get modality(): Service | null {
    return this._modality;
  }

  get isRecurring(): boolean {
    return this._frequency !== RecurrenceFrequency.NONE;
  }

  static fromJson(json: RecurrenceJson): Recurrence {
    return json
      ? new Recurrence(
        json.id,
        new Date(json.start),
        new Date(json.end),
        json.duration_in_min,
        json.amount,
        RecurrenceFrequency[json.frequency],
        json.interval,
        json.service_group ? ServiceGroup.fromJson(json.service_group) : null,
        json.modality ? Service.fromJson(json.modality) : null,
        json.doctor ? Doctor.fromJson(json.doctor) : null
      )
      : null;
  }
}

export interface RecurrenceJson {
  id: string;
  start: string;
  end: string;
  duration_in_min: number;
  frequency: string;
  interval: number;
  amount: number;
  service_group?: ServiceGroupJson | null;
  modality?: ServiceJson | null;
  doctor?: DoctorJson | null;
}
