import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Example adapted from https://web.archive.org/web/20240702082405/https://css-tricks.com/how-to-create-an-animated-countdown-timer-with-html-css-and-javascript/
 */
@Component({
  selector: 'vh-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  private FULL_DASH_ARRAY: number = 283;

  @Input() timeLimit: number = 20;

  @Output() timeUp: EventEmitter<void> = new EventEmitter<void>();

  remainingPathColor: string = 'default';
  strokeDasharray: string = `${this.FULL_DASH_ARRAY} ${this.FULL_DASH_ARRAY}`;
  timeLeft: number;

  private timePassed: number = 0;
  private timerInterval: any = null;
  private warningThreshold: number = 10;
  private alertThreshold: number = 5;

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }

  formatTime(time: number): string {
    const minutes: number = Math.floor(time / 60);
    let seconds: string | number = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  startTimer(): void {
    this.timeLeft = this.timeLimit;
    this.warningThreshold = Math.floor(this.timeLimit / 2);
    this.alertThreshold = Math.floor(this.timeLimit / 4);

    this.timerInterval = setInterval(() => {
      this.timePassed += 1;
      this.timeLeft = this.timeLimit - this.timePassed;
      this.strokeDasharray = `${this.calculateTimeFraction() * this.FULL_DASH_ARRAY} ${this.FULL_DASH_ARRAY}`;
      this.setRemainingPathColor(this.timeLeft);

      if (this.timeLeft === 0) {
        this.onTimesUp();
      }
    }, 1000);
  }

  clearTimer(): void {
    clearInterval(this.timerInterval);
  }

  onTimesUp(): void {
    this.timeUp.emit();
    this.clearTimer();
  }

  setRemainingPathColor(timeLeft: number): void {
    if (timeLeft <= this.alertThreshold) {
      this.remainingPathColor = 'time-is-up';

      return;
    } else if (timeLeft <= this.warningThreshold) {
      this.remainingPathColor = 'warning';

      return;
    }

    this.remainingPathColor = 'default';
  }

  private calculateTimeFraction(): number {
    const rawTimeFraction: number = this.timeLeft / this.timeLimit;

    return rawTimeFraction - 1 / this.timeLimit * (1 - rawTimeFraction);
  }
}
