import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { map } from 'rxjs/operators';
import { IObject } from '@app-types/iobject.type';

interface UsernameSuggestionResponse {
  username: string;
}

interface UsernameValidationResponse {
  unique: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UsernameService {
  constructor(private readonly http: HttpClient) {}

  isUsernameUnique$ = (username: string): Observable<boolean> => {
    const body: IObject = { username: username };

    return this.http.post(environment.apiBaseUrl + ENDPOINTS.validateUsername.route, body).pipe(
      map((json: UsernameValidationResponse): boolean => {
        return json.unique;
      })
    );
  };

  getSuggestion$ = (firstname: string, lastname: string, email: string, birthdate: string = null): Observable<string> => {
    const body: IObject = {
      params: {
        first_name: firstname,
        last_name: lastname,
        email: email,
      },
    };

    if (birthdate) {
      body.params.birthdate = birthdate;
    }

    return this.http.get(environment.apiBaseUrl + ENDPOINTS.getUsernameSuggestion.route, body).pipe(
      map((json: UsernameSuggestionResponse): string => {
        return json.username;
      })
    );
  };
}
