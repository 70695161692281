import { Component, Input } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DateCardLayout } from '@modules/shared/cards/enums/date-card-layout.enum';
import { BreakpointObserverComponent } from '@modules/shared/core/components/breakpoint-observer/breakpoint-observer.component';
import { VirtualSlot } from '@models/virtual-slot.model';
import { format } from 'date-fns';
import { SubsequentVirtualSlot } from '@models/subsequent-virtual-slot.model';

@Component({
  selector: 'vh-multi-visit-card',
  templateUrl: './multi-visit-card.component.html',
  styleUrls: ['./multi-visit-card.component.scss'],
})
export class MultiVisitCardComponent extends BreakpointObserverComponent {
  @Input() skeletonIsEmpty: boolean = true;
  @Input() slots: SubsequentVirtualSlot;
  @Input() dateCardLayout: DateCardLayout;
  @Input() dateCardBackgroundColor: string;
  @Input() getDateCardBackgroundColorFunction: (date: Date) => string;
  @Input() isLoading: boolean = false;

  get timelineLabels(): string[] {
    return this.slots?.virtualSlots?.map((slot: VirtualSlot) => `${slot.service?.displayFullName} - ${format(slot.start, DATE_FORMATS.clientTimeShort)}`) ?? [];
  }
}
