import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IObject } from '@app-types/iobject.type';

export function fetchAllPaginatedData<T>(
  http: HttpClient,
  url: string,
  params: IObject = {},
  currentPage: number = 1,
  allData: T[] = []
): Observable<T[]> {
  params = {
    params: {
      ...params.params,
      page: currentPage,
    },
  };

  return http.get<PaginatedModel<T>>(url, params).pipe(
    switchMap((response: PaginatedModel<T>) => {
      allData.push(...response.data);

      if (!response.links.next) {
        return of(allData);
      }

      const nextPage: number = ++currentPage;

      return fetchAllPaginatedData(http, url, params, nextPage, allData);
    })
  );
}
