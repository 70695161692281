import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { APP } from '@constants/app.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from '@services/language.service';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class GoogleApiService {
  private autocompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;

  googleMapsAndPlacesApiInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly ngZone: NgZone,
    private readonly languageService: LanguageService
  ) {
    this.bootstrap();
  }

  private bootstrap(): void {
    const language: string = this.languageService.getPreferredLanguage();

    // Add the Google Maps API library to the head
    const googleMapsApiUrl: string =
      `https://maps.googleapis.com/maps/api/js?key=${APP.googleApi.maps.key}` +
      // Include the additional libraries Geometry and Places
      '&libraries=geometry,places' +
      // Specify language
      `&language=${language}` +
      // See here why we set it this way: https://stackoverflow.com/a/75212692
      '&callback=Function.prototype';

    this.addScriptToHead(googleMapsApiUrl)
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this.googleMapsAndPlacesApiInitialized$.next(true);
      });
  }

  private addScriptToHead = (sourceUrl: string): Observable<boolean> => {
    return new Observable((subscriber: Subscriber<boolean>): void => {
      const script: HTMLScriptElement = document.createElement('script');
      script.src = sourceUrl;
      script.onload = (): void => {
        subscriber.next(true);
        subscriber.complete();
      };

      document.head.appendChild(script);
    });
  };

  initializeAllServices = (map: google.maps.Map | HTMLDivElement, options?: google.maps.MapOptions): void => {
    this.initAutocompleteService();
    this.initPlacesService(map, options);
  };

  initPlacesService = (map: google.maps.Map | HTMLDivElement, options?: google.maps.MapOptions): void => {
    this.placesService = new google.maps.places.PlacesService(map);

    if (map instanceof google.maps.Map) {
      map.setOptions({
        disableDefaultUI: true,
        gestureHandling: 'none',
        // center: { lat: COORDINATES.belgium.latitude, lng: COORDINATES.belgium.longitude },
        // zoom: ZOOM_LEVELS.country,
        ...options,
      });
    }
  };

  initAutocompleteService = (): void => {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  };

  getPlacePredictions$ = (searchValue: string): Observable<google.maps.places.AutocompletePrediction[]> => {
    if (!searchValue) {
      return of([]);
    }

    return new Observable((subscriber: Subscriber<google.maps.places.AutocompletePrediction[]>): void => {
      if (!this.autocompleteService) {
        throw new Error('Autocomplete service is not initialized');
      }

      const placeAutocompletionRequest: google.maps.places.AutocompletionRequest = {
        input: searchValue,
      };

      void this.autocompleteService.getPlacePredictions(
        placeAutocompletionRequest,
        (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus): void => {
          // This callback function is executed outside the Angular Zone,
          // so changes in templates that are effected by the observable `getPlacePredictions$` are not detected correctly by Angular change detection
          // To solve this, we wrap the callback function code in the `run` function provided by NgZone,
          // so it's executed within the Angular Zone and Angular change detection is triggered correctly
          // More info: https://angular.io/guide/zone
          this.ngZone.run((): void => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              subscriber.next(predictions);
              subscriber.complete();

              return;
            }

            subscriber.error(status);
          });
        }
      );
    });
  };

  getPlaceDetails$ = (placeId: string): Observable<google.maps.places.PlaceResult> => {
    return new Observable((subscriber: Subscriber<google.maps.places.PlaceResult>): void => {
      if (!this.placesService) {
        throw new Error('Places service is not initialized');
      }

      const placeDetailsRequest: google.maps.places.PlaceDetailsRequest = {
        placeId,
      };

      this.placesService.getDetails(
        placeDetailsRequest,
        (details: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus): void => {
          this.ngZone.run((): void => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              subscriber.next(details);
              subscriber.complete();

              return;
            }

            subscriber.error(status);
          });
        }
      );
    });
  };
}
