import { Component, Input, TemplateRef } from '@angular/core';
import { COLORS } from '@constants/colors.constant';
import { PaymentMethod } from '@models/payment-method.model';
import { DATE_FORMATS } from '@constants/date-formats.constant';

@Component({
  selector: 'vh-payment-method-card',
  templateUrl: './payment-method-card.component.html',
  styleUrls: ['./payment-method-card.component.scss'],
})
export class PaymentMethodCardComponent {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() paymentMethod: PaymentMethod;
  @Input() menuTemplate: TemplateRef<{ paymentMethod: PaymentMethod; }>;
}
