import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { INSTITUTION_USER_FORM_KEYS } from '@constants/form-keys/institution-user-form-keys.constant';
import { InstitutionService } from '@services/institution.service';
import { AuthenticationService } from '@services/authentication.service';
import { FormGroup } from '@angular/forms';
import { ButtonStyle } from '@enums/button-style.enum';
import { UserService } from '@services/user.service';
import { User } from '@models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from '@services/language.service';
import { InstitutionUserFormService } from '@services/form-services/institution-user-form.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Institution } from '@models/institution.model';
import { TranslateService } from '@ngx-translate/core';
import { getAssignableRoles, Role } from '@enums/role.enum';
import { COLORS } from '@constants/colors.constant';
import { UserPolicyService } from '@services/policies/user-policy.service';
import { of, switchMap } from 'rxjs';
import { DialogFrameComponent } from '@modules/shared/core/components/dialog-frame/dialog-frame.component';
import { OrganisationPosition } from '@enums/organisation-position.enum';

@Component({
  selector: 'vh-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
@UntilDestroy()
export class UserSettingsComponent implements OnInit, OnChanges {
  protected readonly Role: typeof Role = Role;
  protected readonly ButtonStyle: typeof ButtonStyle = ButtonStyle;
  protected readonly INSTITUTION_USER_FORM_KEYS: typeof INSTITUTION_USER_FORM_KEYS = INSTITUTION_USER_FORM_KEYS;
  protected readonly COLORS: typeof COLORS = COLORS;

  errorMessageTranslationKey: string;
  isExistingUser: boolean;
  formGroup: FormGroup;
  sendingUpdateRequest: boolean = false;
  showCheckingIfUserExistsLoadingIndicator: boolean;
  userAlreadyExistsDialogRef: MatDialogRef<unknown>;
  showCreateUserFormControls: boolean = false;
  assignableRoles: Role[];
  assignablePositions: OrganisationPosition[];
  userRoles: Set<Role> = new Set<Role>();
  isUpdateMode: boolean;
  isLoading: boolean;

  @Input() selectedUser: User;
  @Input() navigateTo: (page: string) => void;
  @Input() addUser: (user: User) => void;

  @ViewChild('userExistsDialogTemplate') userAlreadyExistsDialogTemplate: TemplateRef<DialogFrameComponent>;

  constructor(
    private readonly institutionService: InstitutionService,
    private readonly authenticationService: AuthenticationService,
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
    private readonly languageService: LanguageService,
    private readonly institutionUserFormService: InstitutionUserFormService,
    private readonly translate: TranslateService,
    private readonly userPolicy: UserPolicyService
  ) { }

  ngOnInit(): void {
    this.assignableRoles = getAssignableRoles();
    this.assignablePositions = Object.values(OrganisationPosition);
    this.loadSelectedUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedUser?.isFirstChange()) {
      this.loadSelectedUser();
    }
  }

  onEmailEntered(): void {
    if (this.formGroup.get(INSTITUTION_USER_FORM_KEYS.get('email')).valid) {
      this.checkIfUserExists();
    }
  }

  onEmailKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.formGroup.get(INSTITUTION_USER_FORM_KEYS.get('email')).valid) {
      this.checkIfUserExists();
    }
  }

  saveUser(): void {
    this.sendingUpdateRequest = true;

    if (this.selectedUser) {
      this.updateUser(this.selectedUser);

      return;
    }

    this.register();
  }

  register(): void {
    this.formGroup.markAsTouched();
    if (this.formGroup.invalid) {
      return;
    }

    const payload = {
      ...this.formGroup.value,
      language: this.languageService.getPreferredLanguage(),
      institution_id: this.authenticationService.institution.id,
      roles: Array.from(this.userRoles),
    };

    this.userService
      .createUser$(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (admin: User): void => {
          this.reset();
          this.addUser(admin);
          this.sendingUpdateRequest = false;
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
          this.sendingUpdateRequest = false;
        },
      });
  }

  editExistingUser(user: User): void {
    this.userAlreadyExistsDialogRef?.close();
    this.isExistingUser = true;
    for (const [userProp, formControl] of this.INSTITUTION_USER_FORM_KEYS) {
      if (!formControl.includes('role')) {
        this.formGroup.get(formControl).setValue(user[userProp]);
        this.formGroup.get(formControl).disable();
      }
    }
    this.showCreateUserFormControls = true;
  }

  cancelExistingUser(user: User): void {
    this.userAlreadyExistsDialogRef?.close();
    this.institutionService.detachUserFromCurrentInstitution$(user.id);
  }

  onRoleCheckedChanged(isChecked: boolean, role: Role): void {
    if (isChecked) {
      this.userRoles.add(role);

      if (role === Role.INSTITUTION_ADMIN) {
        this.userRoles.add(Role.INSTITUTION_MANAGER);
        this.userRoles.add(Role.INSTITUTION_EDITOR);
        this.userRoles.add(Role.INSTITUTION_VIEWER);
      }

      if (role === Role.INSTITUTION_MANAGER) {
        this.userRoles.add(Role.INSTITUTION_EDITOR);
        this.userRoles.add(Role.INSTITUTION_VIEWER);
      }

      if (role === Role.INSTITUTION_EDITOR) {
        this.userRoles.add(Role.INSTITUTION_VIEWER);
      }

      if (role === Role.REFERRING_PHYSICIAN_ADMIN) {
        this.userRoles.add(Role.REFERRING_PHYSICIAN_EDITOR);
      }

      return;
    }

    this.userRoles.delete(role);

    if (role === Role.INSTITUTION_MANAGER) {
      this.userRoles.delete(Role.INSTITUTION_ADMIN);
    }

    if (role === Role.INSTITUTION_EDITOR) {
      this.userRoles.delete(Role.INSTITUTION_ADMIN);
      this.userRoles.delete(Role.INSTITUTION_MANAGER);
    }

    if (role === Role.REFERRING_PHYSICIAN_EDITOR) {
      this.userRoles.delete(Role.REFERRING_PHYSICIAN_ADMIN);
    }
  }

  navigateBack(): void {
    this.sendingUpdateRequest = false;
    this.reset();
    this.navigateTo('usersOverview');
  }

  openUserAlreadyExistsDialog(): void {
    if (this.userAlreadyExistsDialogRef) {
      return;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      panelClass: 'dialog-size-medium',
    };

    this.userAlreadyExistsDialogRef = this.dialog.open(this.userAlreadyExistsDialogTemplate, dialogConfig);

    this.userAlreadyExistsDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.userAlreadyExistsDialogRef = null;
      });
  }

  protected canEditRole(role: Role): boolean {
    if (role === Role.INSTITUTION_VIEWER) {
      return false;
    }

    if (!this.authenticationService.currentUser.hasRole(Role.BILLING_ADMIN) && role === Role.BILLING_ADMIN) {
      return false;
    }

    return this.selectedUser ? this.userPolicy.canEditUserDetails(this.selectedUser) : true;
  }

  private updateUser(user: User): void {
    const payload = {
      ...this.formGroup.value,
      institution_id: this.authenticationService.institution.id,
      roles: Array.from(this.userRoles),
    };

    this.userService
      .updateUser$(user.id, payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (admin: User): void => {
          this.userAlreadyExistsDialogRef?.close();
          this.reset();
          this.addUser(admin);
          this.sendingUpdateRequest = false;
        },
        error: (error: HttpErrorResponse | unknown): void => {
          this.errorMessageTranslationKey = this.getHttpErrorMessageTranslationKey(error);
          this.sendingUpdateRequest = false;
        },
      });
  }

  private checkIfUserExists(): void {
    this.formGroup.markAsTouched();

    this.selectedUser = null;
    this.errorMessageTranslationKey = null;
    this.showCheckingIfUserExistsLoadingIndicator = true;

    this.userService.findUser$({ email: this.formGroup.value.email })
      .pipe(untilDestroyed(this),
        switchMap((userId: string | null) => {
          return userId
            ? this.institutionService.attachUserToCurrentInstitutionAndRetrieveUser$(userId)
            : of(null);
        }))
      .subscribe({
        next: (user: User): void => {
          this.showCheckingIfUserExistsLoadingIndicator = false;
          this.selectedUser = user;
          if (!this.selectedUser) {
            this.showCreateUserFormControls = true;

            return;
          }

          if (this.selectedUser?.institutions.some((institution: Institution) => institution.id === this.authenticationService.institution.id) &&
            [...this.selectedUser.roles].some((role: Role) => getAssignableRoles().includes(role))
          ) {
            this.errorMessageTranslationKey = this.translate.instant(
              'pages.register.userAlreadyLinkedToInstitutionError',
              {
                email: this.formGroup.value.email,
                institution: this.authenticationService.institution.name,
              }
            );

            return;
          }

          this.openUserAlreadyExistsDialog();
        },
        error: () => {
          this.showCheckingIfUserExistsLoadingIndicator = false;
        },
      });
  }

  private loadSelectedUser(): void {
    this.isUpdateMode = !!this.selectedUser;

    if (!this.formGroup) {
      this.formGroup = this.institutionUserFormService.createFormGroup(!this.isUpdateMode);
    }

    if (this.isUpdateMode) {
      const canBeEdited: boolean = this.userPolicy.canEditUserDetails(this.selectedUser);
      this.institutionUserFormService.setUserToForm(this.formGroup, this.selectedUser, canBeEdited);
      this.userRoles = new Set<Role>(this.selectedUser.roles);
    }
  }

  private reset(): void {
    this.formGroup.reset();
    this.userRoles = new Set<Role>();
    this.selectedUser = null;
  }

  private getHttpErrorMessageTranslationKey(error: HttpErrorResponse | unknown): string {
    if (!(error instanceof HttpErrorResponse && error.status === 422)) {
      return 'common.unknownError';
    }

    if (error.error.errors?.email) {
      return 'pages.register.emailAlreadyTakenError';
    }
  }
}
