<vh-content-layout [hasVerticalSpacing]="true" [hasHorizontalSpacing]="true">
  <div class="flex-col">
    <vh-status-message
      [isSuccessful]="false"
      [title]="'payment.status.failed.title' | translate"
      [message]="'payment.status.failed.message' | translate">
    </vh-status-message>

    <vh-button
      class="align-center"
      iconRightPath="assets/icons/arrow-narrow-right.svg"
      [vhMarginTop]="32"
      [iconRightStroke]="COLORS.white"
      [label]="'common.finish' | translate"
      (buttonClick)="onFinishClicked()">
    </vh-button>
  </div>
</vh-content-layout>
