import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BREAKPOINTS } from '@constants/breakpoints.constant';
import { BreakpointObserverResult } from '@interfaces/breakpoint-observer-result.interface';

@Directive()
@UntilDestroy()
export abstract class BreakpointObserverComponent {
  BREAKPOINTS: typeof BREAKPOINTS = BREAKPOINTS;

  breakpointObserverResult: BreakpointObserverResult;

  constructor(protected readonly breakpointObserver: BreakpointObserver) {
    const breakpoints: string[] = [
      BREAKPOINTS.maxWidthSmall,
      BREAKPOINTS.maxWidthMedium,
      BREAKPOINTS.maxWidthLarge,
    ];

    this.breakpointObserver
      .observe(breakpoints)
      .pipe(untilDestroyed(this))
      .subscribe((breakpointObserverResult: BreakpointObserverResult): void => {
        this.breakpointObserverResult = breakpointObserverResult;
      });
  }
}
