import { format, isBefore, isEqual, isValid, parse } from 'date-fns';
import { DATE_FORMATS } from '@constants/date-formats.constant';

export class Timeslot {
  private _from: Date;
  private _to: Date;

  constructor(from: Date, to: Date) {
    this._from = from;
    this._to = to;
  }

  get from(): Date {
    return this._from;
  }

  set from(value: Date) {
    this._from = value;
  }

  get to(): Date {
    return this._to;
  }

  set to(value: Date) {
    this._to = value;
  }

  static fromString(value: string): Timeslot {
    const times: string[] = value?.split('-');

    if (!times || times.length !== 2) {
      return null;
    }

    const referenceDate: Date = new Date();
    const parsedFrom = parse(times[0], DATE_FORMATS.clientTime, referenceDate);
    const parsedTo = parse(times[1], DATE_FORMATS.clientTime, referenceDate);

    if (!isValid(parsedFrom) || !isValid(parsedTo) || isBefore(parsedTo, parsedFrom) || isEqual(parsedFrom, parsedTo)) {
      return null;
    }

    return new Timeslot(parsedFrom, parsedTo);
  }

  toString(): string {
    return `${format(this.from, DATE_FORMATS.clientTime)}-${format(this.to, DATE_FORMATS.clientTime)}`;
  }

  isEqual(other: Timeslot): boolean {
    return this.toString() === other.toString();
  }
}
