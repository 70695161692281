<div class="component-root-container">
  <ng-container [ngTemplateOutlet]="roomsNavigationHeaderTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="bodyContentTemplate"></ng-container>
</div>

<ng-template #bodyContentTemplate>
  <vh-planning-calendar
    [vhMarginTop]="16"
    class="component-body"
    [room]="selectedRoom"
    [weekStartDate]="weekStartDate"
    [isDisabled]="rooms.length === 0"
    [headerTemplate]="calendarHeaderTemplate"
    (navigateToRoomSettingsClick)="navigateToSelectedRoomSettings(selectedRoom)">
  </vh-planning-calendar>
</ng-template>

<ng-template #calendarHeaderTemplate>
  <div class="flex-row-s-c calendar-title" [ngClass]="{ 'not-clickable not-selectable is-disabled': rooms.length === 0 }">
    <h1 class="title">{{ (selectedRoom ? 'admin.pages.slotsOverview.slotsPage.available' : '') | translate }} {{ selectedRoom?.name }}</h1>
    <div class="export-search gap-h-8">
      <p class="font-regular-18-22 export-container clickable" (click)="exportCalenderClick()">
        {{ 'admin.pages.slotsOverview.slotsPage.export' | translate }}
      </p>
      <vh-basic-events-calendar-controls
        [calendarMode]="'week'"
        [supportedModes]="supportedModes"
        (selectedDateChange)="searchWeek($event)">
      </vh-basic-events-calendar-controls>
    </div>
  </div>
  <div class="flex-row-s-c error">
    <vh-message
      *ngIf="errorMessageTranslationKey"
      [vhMarginTop]="16"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-alert)'"
      [text]="errorMessageTranslationKey | translate"></vh-message>
  </div>
</ng-template>

<ng-template #roomsNavigationHeaderTemplate>
  <div class="page-header flex-row rooms-nav">
    <ul class="flex-row-l-c rooms-list gap-h-8">
      <ng-container *ngIf="rooms?.length > 0">
        <li *ngFor="let room of rooms" (click)="selectRoom(room)" [ngClass]="selectedRoom?.id !== room.id ? 'clickable' : ''">
          <div class="flex-row-l-c gap-h-8">
            <div class="color-preview" [style.background-color]="room.color"></div>
            <p
              [ngClass]="{ 'underline-fat': room.id === selectedRoom.id }"
              [style.margin-right]="room.id === selectedRoom.id ? '0px' : '20px'">
              {{ room.name }}
            </p>
            <vh-icon
              *ngIf="room.id === selectedRoom?.id"
              class="clickable"
              src="assets/icons/pen.svg"
              (click)="navigateToSelectedRoomSettings(room)">
            </vh-icon>
          </div>
        </li>
      </ng-container>
      <vh-message
        iconRightPath="assets/icons/plus.svg"
        class="has-text-tertiary"
        [isDisabled]="!currentUser.hasRole(Role.ADMIN)"
        [matTooltip]="(!currentUser.hasRole(Role.ADMIN) ? 'admin.pages.slotsOverview.roomsPage.adminCreateDisclaimer' : '') | translate"
        [vhFontClass]="'font-regular-18-22'"
        [text]="'admin.pages.slotsOverview.slotsPage.newRoom' | translate"
        [iconRightFill]="COLORS.tertiary"
        (messageClick)="onCreateRoomClicked()">
      </vh-message>
    </ul>
    <div class="flex-row-c-n"></div>
  </div>
</ng-template>
