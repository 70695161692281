import { Component, Input } from '@angular/core';
import { Institution } from '@models/institution.model';

@Component({
  selector: 'vh-institution-header',
  templateUrl: './institution-header.component.html',
  styleUrls: ['./institution-header.component.scss'],
})
export class InstitutionHeaderComponent {
  @Input() institution: Institution;
}
