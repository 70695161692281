import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[vhSkeleton]',
})
export class SkeletonDirective implements OnInit, OnChanges {
  @Input() skeletonIsVisible: boolean;
  @Input() skeletonIsEmpty: boolean;
  @Input() skeletonWidth: number | string;
  // In case of text, use its line-height as height for the skeleton
  @Input() skeletonHeight: number | string;

  constructor(private readonly element: ElementRef) {}

  ngOnInit(): void {
    this.applySkeletonStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.skeletonIsVisible || changes.skeletonIsEmpty) {
      this.applySkeletonStyles();
    }
  }

  private applySkeletonStyles(): void {
    const elementStyle: CSSStyleDeclaration = this.element.nativeElement.style;

    if (this.skeletonIsVisible) {
      this.element.nativeElement.classList.add('skeleton');

      if (!this.skeletonIsEmpty) {
        this.element.nativeElement.classList.add('skeleton-animation');
      } else {
        this.element.nativeElement.classList.remove('skeleton-animation');
      }

      elementStyle.width = typeof this.skeletonWidth === 'number' ? `var(--${this.skeletonWidth}px)` : this.skeletonWidth;
      elementStyle.height = typeof this.skeletonHeight === 'number' ? `var(--${this.skeletonHeight}px)` : this.skeletonHeight;
    } else {
      this.element.nativeElement.classList.remove('skeleton');
      this.element.nativeElement.classList.remove('skeleton-animation');
      elementStyle.removeProperty('width');
      elementStyle.removeProperty('height');
    }
  }
}
