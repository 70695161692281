import { Document } from '@models/document.model';
import { TaskStatus } from '@enums/task-status.enum';

export class Task {
  constructor(
    private _id: string,
    private _name: string,
    private _description: string,
    private _optional: boolean,
    private _status: TaskStatus,
    private _key?: string,
    private _documents?: Document[]
  ) { }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get key(): string {
    return this._key;
  }

  get documents(): Document[] {
    return this._documents;
  }

  set documents(documents: Document[]) {
    this._documents = documents;
  }

  get status(): TaskStatus {
    return this._status;
  }

  get optional(): boolean {
    return this._optional;
  }

  static fromJson(json: TaskJson): Task {
    return json ? new Task(json.id, json.name, json.description, json.optional, json.status, json.key, json.documents) : null;
  }
}

export interface TaskJson {
  id: string;
  name: string;
  description: string;
  optional: boolean;
  status: TaskStatus;
  key?: string;
  documents?: Document[];
}
