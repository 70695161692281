import { Injectable } from '@angular/core';
import { Option } from '@interfaces/option.interface';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(private readonly translateService: TranslateService) {}

  getGenderOptions$(): Observable<Option[]> {
    return combineLatest([
      this.translateService.get('pages.register.genderInputOptions.male'),
      this.translateService.get('pages.register.genderInputOptions.female'),
      this.translateService.get('pages.register.genderInputOptions.other'),
    ]).pipe(map(([male, female, other]: [string, string, string]) => {
      return [
        {
          label: male,
          value: 'MALE',
        },
        {
          label: female,
          value: 'FEMALE',
        },
        {
          label: other,
          value: 'OTHER',
        },
      ];
    }));
  }
}
