import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { IObject } from '@app-types/iobject.type';
import { environment } from '@environments/environment';
import { MinimalVirtualSlotJson, SearchSlotResponseJson, VirtualSlot } from '@models/virtual-slot.model';
import { SlotGroupType } from '@enums/slot-group-type.enum';
import { RecommendationsResult } from '@interfaces/search-slots-result.interface';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { RecommendationResultType } from '@enums/recommendation-result-type.enum';
import { Institution, InstitutionJson } from '@models/institution.model';
import { SubsequentVirtualSlot } from '@models/subsequent-virtual-slot.model';
import { BookingFlowFilter } from '@modules/booking/models/booking-flow-filter.model';
import { BookingFlow } from '@enums/booking-flow.enum';

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  constructor(private readonly http: HttpClient) {}

  getSlotRecommendations$ = (
    serviceIds: string[],
    type: SlotGroupType,
    bookingFlow: BookingFlow,
    filter?: BookingFlowFilter,
    amount: number = environment.app.slotAmount
  ): Observable<RecommendationsResult> => {
    const options: IObject = {
      params: {
        [ENDPOINTS.getAvailableSlots.queryParams.serviceIds]: serviceIds.join(','),
        [ENDPOINTS.getAvailableSlots.queryParams.searchType]: SlotGroupType[type],
      },
    };

    if (amount) {
      options.params[ENDPOINTS.getAvailableSlots.queryParams.pageSize] = amount;
    }

    options.params[ENDPOINTS.getAvailableSlots.queryParams.bookingFlow] = bookingFlow;

    if (filter) {
      options.params = {
        ...options.params,
        ...filter.toQueryParams().params,
      };
    }

    return this.http
      .get(environment.apiBaseUrl + ENDPOINTS.getAvailableSlots.route, options)
      .pipe(map((json: SearchSlotResponseJson): RecommendationsResult => {
        const result: RecommendationsResult = {
          slots: null,
          institutions: null,
          carePrograms: null,
          subsequentSlots: null,
          type: json.type,
        };

        switch (json.type) {
          case RecommendationResultType.SLOTS:
            result.slots = json.data.length > 0 ? (json.data as MinimalVirtualSlotJson[]).map(VirtualSlot.fromMinimalJson) : [];
            break;
          case RecommendationResultType.INSTITUTIONS:
            result.institutions = json.data.length > 0 ? (json.data as InstitutionJson[]).map(Institution.fromJson) : [];
            break;
          case RecommendationResultType.SUBSEQUENT_SLOTS:
            result.subsequentSlots = json.data.length > 0 ? (json.data as MinimalVirtualSlotJson[][]).map(SubsequentVirtualSlot.fromJson) : [];
            break;
          case RecommendationResultType.CARE_PROGRAMS:
            throw new Error('Not implemented');
            break;
        }

        return result;
      }));
  };

  submitContactRequest$ = (form: IObject): Observable<void> => {
    return this.http.post(environment.apiBaseUrl + ENDPOINTS.submitAppointmentContactRequest.route, form).pipe(switchToEmptyObservable());
  };
}
