import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { REGISTER_FORM_KEYS } from '@constants/form-keys/register-form-keys.constant';
import { Router } from '@angular/router';
import { ONBOARDING_NAV } from '@modules/onboarding/constants/onboarding-nav.constant';
import { BaseOnboardingPageComponent } from '@modules/onboarding/pages/base-onboarding-page/base-onboarding-page.component';
import { NAVIGATION } from '@constants/navigation.constant';
import { LanguageService } from '@services/language.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';
import { SESSION_KEYS } from '@constants/local-storage-keys.constant';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { NewOnboardingFormService } from '@services/form-services/new-onboarding-form.service';
import { Option } from '@interfaces/option.interface';
import { NewOnboardingService } from '@services/new-onboarding.service';

@Component({
  selector: 'vh-onboarding-credentials-page',
  templateUrl: './onboarding-credentials-page.component.html',
  styleUrls: ['./onboarding-credentials-page.component.scss'],
})
@UntilDestroy()
export class OnboardingCredentialsPageComponent extends BaseOnboardingPageComponent implements OnInit {
  protected readonly REGISTER_FORM_KEYS: typeof REGISTER_FORM_KEYS = REGISTER_FORM_KEYS;
  protected readonly DOCTOR_FORM_KEYS: typeof DOCTOR_FORM_KEYS = DOCTOR_FORM_KEYS;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;

  termsAndConditionsLink: string = environment.links.termsAndConditions;
  formGroup: UntypedFormGroup;
  isSubmitting: boolean = false;
  languageOptions: Option[];

  constructor(
    private readonly newOnboardingFormService: NewOnboardingFormService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly newOnboardingService: NewOnboardingService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formGroup = this.newOnboardingFormService.createFormGroupForOnboardingAboutYourself();

    this.languageService.getPlatformLanguagesAsOptions$().subscribe((options: Option[]) => this.languageOptions = options);
  }

  setStep(): void {
    this.state.goToStep(this.STEP_CREDENTIALS);
  }

  onNextClicked(): void {
    const registerFormValues = this.formGroup.value;
    const preferredLanguage = this.languageService.getPreferredLanguage();
    const onboardingPayload = {
      ...registerFormValues,
      language: preferredLanguage,
      specialty_id: registerFormValues.specialty_id,
    };

    this.isSubmitting = true;

    this.newOnboardingService.setOnboardingPayload(onboardingPayload);

    sessionStorage.setItem(SESSION_KEYS.isPerformingDoctorOnboardingDuringFirstSession, 'true');
    void this.router.navigate([ONBOARDING_NAV.privacy.route]);

    this.isSubmitting = false;
  }
}
