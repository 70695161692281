/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const SERVICE_GROUP_FORM_KEYS: Map<string, string> = new Map([
  ['name', 'name'],
  ['color', 'color'],
  ['services', 'services'],
  ['surveys', 'surveys'],
  ['surveyId', 'id'],
  ['surveyName', 'name'],
  ['surveyIsActive', 'is_active'],
  ['surveyActiveFrom', 'active_from'],
  ['surveyActiveTo', 'active_to'],
]);
