import { TranslatedProperty } from '@models/translated-property.model';
export class HierarchicalPickerState {
  private _availableItems: HierarchicalPickerItem[] = [];
  private _displayedItems: HierarchicalPickerItem[] = [];

  private _selectedItems: Map<number, HierarchicalPickerItem>;
  private _selectionTree: Map<number, HierarchicalPickerItem[]>;

  constructor(private readonly _allItems: HierarchicalPickerItem[], deepestChildId: string = null) {
    this.deepSelect(deepestChildId);
  }

  private get allItems(): HierarchicalPickerItem[] {
    return this._allItems;
  }

  private get availableItems(): HierarchicalPickerItem[] {
    return this._availableItems;
  }

  private get selectionTree(): Map<number, HierarchicalPickerItem[]> {
    return this._selectionTree;
  }

  get displayedItems(): HierarchicalPickerItem[] {
    return this._displayedItems;
  }

  private get selectedItems(): Map<number, HierarchicalPickerItem> {
    return this._selectedItems;
  }

  get parentOfCurrentItem(): HierarchicalPickerItem | null {
    return this.hasReachedDeepestLevel()
      ? this.selectedItems.get(this.currentLevel - 1)
      : this.selectedItems.get(this.currentLevel);
  }

  get currentItem(): HierarchicalPickerItem | null {
    return this.selectedItems.get(this.currentLevel) ?? null;
  }

  get currentLevel(): number {
    return this._selectedItems.size - 1;
  }

  deepSelect(deepestChildId: string): void {
    this.reset();
    this.rebuildSelectionTree(this._allItems, deepestChildId);
  }

  private rebuildSelectionTree(
    items: HierarchicalPickerItem[],
    deepestChildId: string,
    level: number = -1
  ): HierarchicalPickerItem {
    for (const item of items) {

      if (item.id === deepestChildId) {
        this.select(item, true);

        return item;
      }

      // If not found, we'll check the children and check recursively
      if (item.children) {
        const foundChild = this.rebuildSelectionTree(item.children, deepestChildId, level + 1);
        if (foundChild) {
          this.select(item, true);

          return foundChild;
        }
      }
    }

    return null;
  }

  hasReachedDeepestLevel(): boolean {
    return this.currentItem?.children.length === 0;
  }

  isSibling(item: HierarchicalPickerItem): boolean {
    return Array.from(this.selectionTree.get(this.currentLevel) ?? [])?.some((availableItem: HierarchicalPickerItem): boolean => item?.id === availableItem.id);
  }

  isSelected(item?: HierarchicalPickerItem): boolean {
    return item && Array.from(this.selectedItems.values()).some((selectedItem: HierarchicalPickerItem): boolean => item.id === selectedItem.id);
  }

  // TODO: the ignore sibling check is a workaround for a bug when rebuilding the selection tree. The actual bug should be properly fixed but the root cause is unclear.
  select(item: HierarchicalPickerItem, ignoreSiblingCheck: boolean = false): void {
    if (this.isSibling(item) && !ignoreSiblingCheck) {
      this.pop();
    }

    this.selectionTree.set(this.currentLevel + 1, this.availableItems);
    this.selectedItems.set(this.currentLevel + 1, item);

    if (!this.hasReachedDeepestLevel()) {
      this._availableItems = item.children?.length > 0 ? item.children : this._availableItems;
      this._displayedItems = this._availableItems;
    }
  }

  filter(query: string): void {
    if (!query) {
      this._displayedItems = this.availableItems;

      return;
    }

    this._displayedItems = this.availableItems
      .filter((item: HierarchicalPickerItem) => item.name.toLowerCase().includes(query.toLowerCase()));
  }

  pop(): void {
    const previousSelection: HierarchicalPickerItem[] = this.selectionTree.get(this.currentLevel);
    this.selectedItems.delete(this.currentLevel);
    this.selectionTree.delete(this.currentLevel);

    this._availableItems = previousSelection ? previousSelection : this.allItems;
    this._displayedItems = this._availableItems;
  }

  reset(): void {
    this._availableItems = this.allItems;
    this._displayedItems = this.allItems;
    this._selectedItems = new Map<number, HierarchicalPickerItem>();
    this._selectionTree = new Map<number, HierarchicalPickerItem[]>();
  }
}

export interface HierarchicalPickerItem {
  id: string;
  name: string;
  alternativeName?: TranslatedProperty;
  description?: string;
  children: HierarchicalPickerItem[] | null;
}
