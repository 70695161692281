import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';
import { VisitsOverviewPageComponent } from '@modules/admin/admin-dashboard/pages/visits-overview-page/visits-overview-page.component';
import { ConversationsOverviewPageComponent } from '@modules/conversation/pages/conversations-overview-page/conversations-overview-page.component';
import { DifferenceInMinutesPipeModule, FormatPipeModule, GetWeekPipeModule } from 'ngx-date-fns';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgxMaskPipe } from 'ngx-mask';
import { InsightsPageComponent } from '@modules/admin/admin-dashboard/pages/insights-page/insights-page.component';
import { roleGuard } from '@guards/role.guard';
import { Role } from '@enums/role.enum';
import { WorkListsPageComponent } from '@modules/admin/admin-dashboard/pages/work-lists-page/work-lists-page.component';
import { ReschedulingWorkListPageComponent } from '@modules/admin/admin-dashboard/pages/rescheduling-work-list-page/rescheduling-work-list-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScreeningFormsToReviewPageComponent } from '@modules/admin/admin-dashboard/pages/screening-forms-to-review-page/screening-forms-to-review-page.component';
import { moduleGuard } from '@guards/module.guard';
import { ModuleKey } from '@enums/module-key.enum';
import { VisitsOverviewPrintoutComponent } from '@modules/admin/admin-dashboard/components/visits-overview-printout/visits-overview-printout.component';
import { ExportInstitutionDataDialogComponent } from '@modules/admin/admin-dashboard/dialogs/export-institution-data-dialog/export-institution-data-dialog.component';
import { VisitsCalendarHeaderComponent } from '@modules/admin/admin-dashboard/components/visits-calendar-header/visits-calendar-header.component';
import { AnswerListArgumentationPopupComponent } from '@modules/admin/admin-dashboard/components/answer-list-argumentation-popup/answer-list-argumentation-popup.component';
import { AnswerListResultsComponent } from '@modules/admin/admin-dashboard/components/answer-list-results/answer-list-results.component';
import { MatDialogModule } from '@angular/material/dialog';
import { authenticationGuard } from '@guards/authentication.guard';
import { modeGuard } from '@guards/mode.guard';
import { Mode } from '@enums/mode.enum';
import { getInstitutionManageRoles } from '@enums/role.enum';
import { SettingsModule } from '@modules/settings/settings.module';
import { AutoRefreshDirective } from '@modules/shared/core/directives/auto-refresh.directive';
import { CalendarModule } from '@modules/shared/calendar/calendar.module';
import { FormModule } from '@modules/shared/form/form.module';
import { VisitsModule } from '@modules/shared/visits/visits.module';
import { AdminSharedModule } from '@modules/admin/admin-shared.module';
import { CoreModule } from '@modules/shared/core/core.module';
import { PatientSharedModule } from '@modules/patient/patient-shared.module';
import { AccountSharedModule } from '@modules/account/account-shared.module';

const routes: Routes = [
  {
    path: NAVIGATION.dashboard.path,
    pathMatch: 'full',
    redirectTo: NAVIGATION.adminDashboardConversations.path,
  },
  {
    path: NAVIGATION.adminDashboardConversations.path,
    component: ConversationsOverviewPageComponent,
    canActivate: [authenticationGuard()],
    canMatch: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER])],
  },
  {
    path: NAVIGATION.adminDashboardAppointments.path,
    component: VisitsOverviewPageComponent,
    canActivate: [authenticationGuard()],
    canMatch: [roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER]), moduleGuard(ModuleKey.BOOKING)],
  },
  {
    path: NAVIGATION.adminDashboardInsights.path,
    component: InsightsPageComponent,
    // The insights module is temporarily only for the booking module as it is the only one that has insights
    canActivate: [authenticationGuard(), roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER]), moduleGuard(ModuleKey.BOOKING)],
  },
  {
    path: NAVIGATION.adminDashboardWorkLists.path,
    component: WorkListsPageComponent,
    canActivate: [authenticationGuard(), roleGuard([Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER]), moduleGuard(ModuleKey.BOOKING)],
    children: [
      {
        path: NAVIGATION.adminDashboardWorkLists.rescheduling.path,
        component: ReschedulingWorkListPageComponent,
        canActivate: [authenticationGuard(), moduleGuard(ModuleKey.BOOKING)],
      },
      {
        path: NAVIGATION.adminDashboardWorkLists.surveys.path,
        component: ScreeningFormsToReviewPageComponent,
        canActivate: [authenticationGuard(), moduleGuard(ModuleKey.SURVEY)],
      },
    ],
  },
  {
    path: NAVIGATION.adminDashboardSettings.path,
    // eslint-disable-next-line @typescript-eslint/typedef
    loadChildren: () => import('@modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [authenticationGuard(), roleGuard(getInstitutionManageRoles()), modeGuard(Mode.ADMIN)],
  },
];

@NgModule({
  declarations: [
    // Page components
    VisitsOverviewPageComponent,
    InsightsPageComponent,
    WorkListsPageComponent,
    ExportInstitutionDataDialogComponent,
    ReschedulingWorkListPageComponent,
    ScreeningFormsToReviewPageComponent,
    VisitsOverviewPrintoutComponent,
    VisitsCalendarHeaderComponent,
    AnswerListArgumentationPopupComponent,
    AnswerListResultsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreModule,
    AdminSharedModule,
    FormatPipeModule,
    MatTooltipModule,
    MatDialogModule,
    InfiniteScrollDirective,
    NgxMaskPipe,
    DragDropModule,
    DifferenceInMinutesPipeModule,
    AutoRefreshDirective,
    GetWeekPipeModule,
    SettingsModule,
    CalendarModule,
    FormModule,
    VisitsModule,
    PatientSharedModule,
    AccountSharedModule,
  ],
})
export class AdminDashboardModule { }
