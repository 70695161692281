import { parseISO } from 'date-fns';
import { Service, ServiceJson } from '@models/service.model';
import { Task } from '@models/task.model';
import { Room, RoomJson } from '@models/room.model';
import { Institution, InstitutionJson } from '@models/institution.model';
import { Visit, VisitJson } from '@models/visit.model';
import { RequestVisit } from '@interfaces/request-visit.interface';
import { User } from '@models/user.model';
import { RecommendationResultType } from '@enums/recommendation-result-type.enum';
import { CareProgramJson } from '@models/care-program.model';
import { getTimeStringWithTimeZone } from '@utils/helpers/date.util';
import { BaseVisitProperties } from '@modules/shared/cards/interfaces/base-visit-properties.interface';

export class VirtualSlot implements BaseVisitProperties {
  constructor(
    protected _institution: Institution,
    protected _service: Service,
    protected _startDate: Date,
    protected _endDate: Date,
    protected _recurrenceId: string,
    protected _visit: Visit,
    protected _room: Room
  ) {
  }

  get service(): Service {
    return this._service;
  }

  get institution(): Institution {
    return this._institution;
  }

  get start(): Date {
    return this._startDate;
  }

  get end(): Date {
    return this._endDate;
  }

  // get status(): SlotStatus {
  //   return this._status;
  // }

  get allTasks(): Task[] {
    return [
      ...this._visit?.tasks ?? [],
      ...this._service?.tasks ?? [],
    ];
  }

  get recurrenceId(): string {
    return this._recurrenceId;
  }

  get room(): Room {
    return this._room;
  }

  get visit(): Visit {
    return this._visit;
  }

  static fromJson(json: SlotJson): VirtualSlot {
    return new VirtualSlot(
      Institution.fromJson(json.institution),
      Service.fromJson(json.service),
      json.start_date ? parseISO(json.start_date) : null,
      json.end_date ? parseISO(json.end_date) : null,
      json.recurrence_id,
      json?.visit ? Visit.fromJson(json?.visit) : null,
      Room.fromJson(json.room)
    );
  }

  static fromMinimalJson(json: MinimalVirtualSlotJson): VirtualSlot {
    return new VirtualSlot(
      Institution.fromJson(json.institution),
      Service.fromJson(json.service),
      json.start_date ? parseISO(json.start_date) : null,
      json.end_date ? parseISO(json.end_date) : null,
      json.recurrence_id,
      null,
      Room.fromId(json.room_id)
    );
  }

  toRequest(user?: User): RequestVisit {
    const data = {
      from: getTimeStringWithTimeZone(this._startDate),
      to: getTimeStringWithTimeZone(this._endDate),
      service_id: this._service.id,
    };

    return user
      ? {
        ...data,
        user_id: user.id,
      }
      : data;
  }
}

export interface SlotJson {
  id: string;
  service: ServiceJson;
  start_date: string;
  end_date: string;
  status: string;
  institution?: InstitutionJson;
  room: RoomJson;
  service_id: string;
  recurrence_id: string;
  visit: VisitJson;
}

export interface MinimalVirtualSlotJson {
  start_date: string;
  end_date: string;
  institution: InstitutionJson;
  institution_id: string;
  recurrence_id: string;
  room_id: string;
  service: ServiceJson;
  service_id: string;
  visit_id: string;
}

export interface SearchSlotResponseJson {
  data: MinimalVirtualSlotJson[] | MinimalVirtualSlotJson[][] | InstitutionJson[] | CareProgramJson[];
  type: RecommendationResultType;
}
