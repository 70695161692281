<div class="container">
  <div class="flex-row-s-c header">
    <h2>{{ displayedMonthTranslationKey | translate }} {{ displayedMonthFirstDate | date : DATE_FORMATS.clientYear }}</h2>
    <div class="flex-row-c-c gap-h-16">
      <vh-icon
        *ngIf="!disablePastDates || !isDisplayingCurrentMonth"
        class="clickable"
        src="assets/icons/arrow-left-secondary.svg"
        (click)="updateCalendar(-1)"></vh-icon>
      <vh-icon
        class="clickable"
        src="assets/icons/circle.svg"
        [fill]="Colors.secondary"
        (click)="updateCurrentDateTime(); handleDateClick(currentDateTime)">
      </vh-icon>
      <vh-icon class="clickable" src="assets/icons/arrow-right-secondary.svg" (click)="updateCalendar(1)"></vh-icon>
    </div>
  </div>
  <div class="content">
    <div class="flex-col-c-c font-medium-16-19 spacing-bottom" *ngFor="let i of [].constructor(7); let index = index">
      {{ 'common.daysShort.' + (index + 1) | translate }}
    </div>
    <div class="flex-col-c-c day" *ngFor="let calendarDate of displayedCalendarDates">
      <div *ngIf="calendarDate.isSelected" class="selected"></div>
      <span
        [ngClass]="{
          faded: calendarDate.isFaded,
          clickable: calendarDate.isClickable,
          today: calendarDate.isToday
        }"
        (click)="calendarDate.isClickable && handleDateClick(calendarDate.date)"
        >{{ calendarDate.clientDay }}</span
      >
    </div>
  </div>
</div>
