import { parseISO } from 'date-fns';
import { CalendarEvent } from 'angular-calendar';
export class RecurrenceException {
  constructor(private _start: Date, private _end: Date, private _recurrenceId: string) {}

  get start(): Date {
    return this._start;
  }

  get end(): Date {
    return this._end;
  }

  get recurrenceId(): string {
    return this._recurrenceId;
  }

  static fromJson(json: RecurrenceExceptionJson): RecurrenceException {
    return json
      ? new RecurrenceException(
        parseISO(json.start),
        parseISO(json.end),
        json.recurrenceId
      )
      : null;
  }

  equalsEvent(event: CalendarEvent): boolean {
    return event.id === this.recurrenceId &&
        event.start === this.start &&
        event.end === this.end;
  }
}

export interface RecurrenceExceptionJson {
  recurrenceId: string;
  start: string;
  end: string;
}
