import { addMinutes, differenceInMinutes, isEqual, parseISO } from 'date-fns';
import { ServiceGroup, ServiceGroupJson } from '@models/service-group.model';
import { Service, ServiceJson } from '@models/service.model';
import { CalendarEvent } from 'angular-calendar';
import { Doctor, DoctorJson } from '@models/doctor.model';

export class Occurrence {
  constructor(
    private _recurrenceId: string,
    private _start: Date,
    private _end: Date,
    private _serviceGroup: ServiceGroup | null = null,
    private _modality: Service | null = null,
    private _doctor: Doctor | null = null
  ) {}

  get recurrenceId(): string {
    return this._recurrenceId;
  }

  get start(): Date {
    return this._start;
  }

  set start(value: Date) {
    this._start = value;
  }

  get end(): Date {
    return this._end;
  }

  set end(value: Date) {
    this._end = value;
  }

  get displayName(): string {
    return this.modality?.shortName ? `${this.modality.shortName} - ${this.name}` : this.name;
  }

  get name(): string {
    return this._serviceGroup?.name;
  }

  get color(): string {
    return this._serviceGroup?.color;
  }

  set color(value: string) {
    this._serviceGroup.color = value;
  }

  get duration(): number {
    return differenceInMinutes(this._end, this._start);
  }

  get serviceGroup(): ServiceGroup {
    return this._serviceGroup;
  }

  set serviceGroup(value: ServiceGroup) {
    this._serviceGroup = value;
  }

  get modality(): Service {
    return this._modality;
  }

  get doctor(): Doctor {
    return this._doctor;
  }

  set doctor(doctor: Doctor) {
    this._doctor = doctor;
  }

  equalsEvent(event: CalendarEvent): boolean {
    return event.id === this.recurrenceId &&
        isEqual(event.start, this._start) &&
        isEqual(event.end, this._end);
  }

  static createDummy(startTime?: Date, serviceGroup: ServiceGroup = null): Occurrence {
    startTime = startTime ?? new Date();
    const endTime = addMinutes(startTime, 60);

    return new Occurrence(
      null,
      startTime,
      endTime,
      serviceGroup,
      null,
      null
    );
  }

  static fromJson(json: OccurrenceJson): Occurrence {
    return json
      ? new Occurrence(
        json.recurrence_id,
        json.start ? parseISO(json.start) : null,
        json.end ? parseISO(json.end) : null,
        json.service_group ? ServiceGroup.fromJson(json.service_group) : null,
        json.modality ? Service.fromJson(json.modality) : null,
        json.doctor ? Doctor.fromJson(json.doctor) : null
      )
      : null;
  }
}

export interface OccurrenceJson {
  recurrence_id: string;
  start: string;
  end: string;
  service_group: ServiceGroupJson | null;
  modality?: ServiceJson | null;
  doctor?: DoctorJson | null;
}
