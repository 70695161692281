<section class="flex-col-c-c gap-v-16" [vhMarginTop]="16">
  <vh-status-message
    [isSuccessful]="true"
    [title]="'onboarding.success.title' | translate"
    [message]="'onboarding.success.message' | translate">
  </vh-status-message>

  <vh-button
    class="align-center"
    [label]="'onboarding.success.startNow' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>
</section>