import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { WalletTransaction, WalletTransactionJson } from '@models/wallet-transaction.model';
import { Wallet } from '@models/wallet.model';
import { IObject } from '@app-types/iobject.type';
import { PaginatedModel } from '@interfaces/paginated-model.interface';
import { PaymentMethod, PaymentMethodJson } from '@models/payment-method.model';
import { switchToEmptyObservable } from '@utils/helpers/rx-js.util';
import { AuthenticationService } from '@services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  constructor(
    private readonly http: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) { }

  getDemoTokensAmount$(): Observable<number> {
    const endpoint: string = ENDPOINTS.getWalletDemoTokensAmount.route;

    return this.http.get<{ amount: number; }>(environment.apiBaseUrl + endpoint)
      .pipe(map((json: { amount: number; }) => json.amount));
  }

  getWalletTransactionHistory$(direction: 'ALL' | 'INCOMING' | 'OUTGOING', page: number): Observable<PaginatedModel<WalletTransaction>> {
    const endpoint: string = ENDPOINTS.getWalletTransactionHistory.route
      .replace(`{${ENDPOINTS.getWallet.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const options: IObject = {
      params: {
        page: page.toString(),
      },
    };

    if (direction !== 'ALL') {
      options.params.direction = direction;
    }

    return this.http.get(environment.apiBaseUrl + endpoint, options)
      .pipe(map((json: PaginatedModel<WalletTransactionJson>) => {
        return {
          ...json,
          data: json.data.map(WalletTransaction.fromJson),
        };
      }));
  }

  getWalletOfCurrentInstitution$(): Observable<Wallet> {
    const endpoint: string = ENDPOINTS.getWallet.route
      .replace(`{${ENDPOINTS.getWallet.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http
      .get(environment.apiBaseUrl + endpoint)
      .pipe(map(Wallet.fromJson));
  }

  updateWalletPreferences$(value: IObject): Observable<Wallet> {
    const endpoint: string = ENDPOINTS.updateWalletPreferences.route
      .replace(`{${ENDPOINTS.updateWalletPreferences.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.put(environment.apiBaseUrl + endpoint, value)
      .pipe(map(Wallet.fromJson));
  }

  disableAutoReplenish$(): Observable<Wallet> {
    return this.updateWalletPreferences$({ bucket_id: null });
  }

  getConfiguredPaymentMethods$(): Observable<PaymentMethod[]> {
    const endpoint: string = ENDPOINTS.getPaymentMethods.route
      .replace(`{${ENDPOINTS.getPaymentMethods.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.get(environment.apiBaseUrl + endpoint)
      .pipe(map((json: PaymentMethodJson[]) => json.map(PaymentMethod.fromJson)));
  }

  addPaymentMethodAndGetCheckoutUrl$(token: string, returnPath: string): Observable<{ checkout_url: string; payment_id: string; }> {
    const endpoint: string = ENDPOINTS.addPaymentMethod.route
      .replace(`{${ENDPOINTS.addPaymentMethod.pathParams.institutionId}}`, this.authenticationService.institution.id);

    const payload: IObject = {
      token: token,
      method: 'creditcard',
      return_path: returnPath,
    };

    return this.http.post<{ checkout_url: string; payment_id: string; }>(environment.apiBaseUrl + endpoint, payload)
      .pipe(map((json: { checkout_url: string; payment_id: string; }) => json));
  }

  updatePaymentMethod$(paymentMethodId: string, properties: IObject): Observable<PaymentMethod> {
    const endpoint: string = ENDPOINTS.updatePaymentMethod.route
      .replace(`{${ENDPOINTS.updatePaymentMethod.pathParams.paymentMethodId}}`, paymentMethodId)
      .replace(`{${ENDPOINTS.updatePaymentMethod.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.put(environment.apiBaseUrl + endpoint, properties)
      .pipe(map(PaymentMethod.fromJson));
  }

  deletePaymentMethod$(paymentMethodId: string): Observable<void> {
    const endpoint: string = ENDPOINTS.deletePaymentMethod.route
      .replace(`{${ENDPOINTS.deletePaymentMethod.pathParams.paymentMethodId}}`, paymentMethodId)
      .replace(`{${ENDPOINTS.deletePaymentMethod.pathParams.institutionId}}`, this.authenticationService.institution.id);

    return this.http.delete(environment.apiBaseUrl + endpoint)
      .pipe(switchToEmptyObservable());
  }

  getPaymentStatus$(paymentId: string): Observable<string> {
    const endpoint: string = ENDPOINTS.getPaymentStatus.route
      .replace(`{${ENDPOINTS.getPaymentStatus.pathParams.institutionId}}`, this.authenticationService.institution.id)
      .replace(`{${ENDPOINTS.getPaymentStatus.pathParams.paymentId}}`, paymentId
      );

    return this.http.get<{ status: string; }>(environment.apiBaseUrl + endpoint)
      .pipe(
        map((response: { status: string; }) => response.status)
      );
  }
}
