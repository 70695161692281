<!-- TODO: perhaps make an option to switch between primary & secondary style -->
<div (mouseover)="onParentHover()" (mouseout)="onParentHoverOut()" class="pill-container" [ngStyle]="{ 'text-align': alignment }" [ngClass]="{ hoverable: isHoverable, clickable: pillClick?.observed,primary: isPrimary, secondary:!isPrimary }" (click)="onPillClicked($event)">
  <vh-icon
    class="spacing-right"
    *ngIf="iconLeftPath"
    [src]="iconLeftPath"
    [width]="iconLeftWidth"
    [height]="iconLeftHeight"
    [fill]="iconFillLeft"
    [hoverFill]="iconLeftHoverFill"
    [ngClass]="{ clickable: leftIconClick?.observed }"
    (click)="onLeftIconClick($event)">
  </vh-icon>
  <span [innerHTML]="label" [ngStyle]="{ 'line-height': labelLineHeight }"></span>
  <vh-icon
    class="spacing-left"
    *ngIf="iconRightPath"
    [src]="iconRightPath"
    [width]="iconRightWidth"
    [height]="iconRightHeight"
    [fill]="iconFillRight"
    [hoverFill]="iconRightHoverFill"
    [ngClass]="{ clickable: rightIconClick?.observed }"
    (click)="onRightIconClick($event)">
  </vh-icon>
</div>
