import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[vhTextColor]',
})
export class TextColorDirective implements OnInit {
  @Input() vhTextColor: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.element.nativeElement.style.color = this.vhTextColor;
  }
}
