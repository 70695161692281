<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.credentials.title' | translate }}</h1>
</section>

<form class="flex-col gap-v-16" [vhMarginTop]="16" [formGroup]="formGroup">
  <vh-text-input
    [label]="'common.fields.firstname' | translate"
    [formControlName]="REGISTER_FORM_KEYS.get('firstName')">
  </vh-text-input>
  <vh-text-input
    [label]="'common.fields.lastname' | translate"
    [formControlName]="REGISTER_FORM_KEYS.get('lastName')">
  </vh-text-input>

  <vh-language-picker [label]="'new.onboarding.credentials.languageLabel' | translate" [showIcon]="false"></vh-language-picker>

  <vh-specialties-select-input
    [formControlName]="DOCTOR_FORM_KEYS.get('specialty_id')"
    [label]="'onboarding.finishingUp.specialtyLabel' | translate">
  </vh-specialties-select-input>
  
  <vh-button
    class="align-center"
    iconRightPath="assets/icons/arrow-narrow-right.svg"
    [iconRightStroke]="COLORS.white"
    [isDisabled]="!formGroup.valid || isSubmitting"
    [label]="'common.next' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>
</form>
