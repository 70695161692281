import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IObject } from '@app-types/iobject.type';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  translationsAreInitialized$: BehaviorSubject<boolean>;
  navigationData: Record<string, unknown>;

  constructor(private readonly translateService: TranslateService) {
    this.translationsAreInitialized$ = new BehaviorSubject<boolean>(false);
    this.navigationData = {};
  }

  /**
   * @deprecated
   */
  setNavigationData = (identifier: string, value: unknown): void => {
    this.navigationData[identifier] = value;
  };

  /**
   * @deprecated
   */
  getNavigationData = (identifier: string): unknown => {
    const value: unknown = this.navigationData[identifier];
    delete this.navigationData[identifier];

    return value;
  };

  getTranslationsByKeys$ = (translationKeys: string[]): Observable<IObject> => {
    const translations$: Observable<string>[] = translationKeys.map((translationKey: string): Observable<string> => {
      return this.translateService.get(translationKey);
    });

    return forkJoin(translations$).pipe(
      map((translations: string[]): IObject => {
        return translationKeys.reduce((translationsObject: IObject, translationKey: string, index: number): IObject => {
          return {
            ...translationsObject,
            [translationKey]: translations[index],
          };
        }, {});
      })
    );
  };
}
