import { Injectable } from '@angular/core';
import { AppService } from '@services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { Option } from '@interfaces/option.interface';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { UserService } from '@services/user.service';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { de, enUS, fr, nl } from 'date-fns/locale';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _registeredLocales: Set<string> = new Set<string>();

  languageSetSuccessfully$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly platformSupportedLanguages: string[] = [
    // 'de',
    'en',
    'fr',
    'nl',
  ];

  readonly conversationSupportedLanguages: string[] = [
    'ar',
    'en',
    'fr',
    'nl',
    'tr',
  ];

  constructor(
    private readonly translateService: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly appService: AppService,
    private readonly userService: UserService,
    private readonly dateFnsConfigurationService: DateFnsConfigurationService,
    private readonly localStorageCacheService: LocalStorageCacheService
  ) {
  }

  bootstrapTranslations(): void {
    this.setLanguage(this.getPreferredLanguage(), false);
  }

  setLanguage(language: string, shouldStoreServerSide: boolean = true): void {
    language = language.toLowerCase();
    this.localStorageCacheService.set(LOCAL_STORAGE_KEYS.preferredLanguage, language);

    this.registerAngularLocaleData(language);

    if (this.authenticationService.isLoggedIn() && shouldStoreServerSide) {
      this.authenticationService.currentUser.language = language;
      this.userService.updateUser$(this.authenticationService.currentUser.id, { language: language })
        .subscribe((): void => {
          this.languageSetSuccessfully$.next(true);
        });
    } else {
      this.languageSetSuccessfully$.next(true);
    }

    this.translateService.use(language)
      .subscribe((): void => {
        this.appService.translationsAreInitialized$.next(true);
        this.dateFnsConfigurationService.setLocale(this.getDateFnsLocale(language));
      });
  }

  getPreferredLanguage(): string {
    const userLanguage = this.authenticationService.currentUser?.language?.toLowerCase() ??
      this.localStorageCacheService.get<string | null>(LOCAL_STORAGE_KEYS.preferredLanguage)?.toLowerCase();

    if (userLanguage && this.platformSupportedLanguages.includes(userLanguage)) {
      return userLanguage;
    }

    return this.getDefaultLanguage();
  }

  getDefaultLanguage(): string {
    const browserLanguage: string = navigator.language?.substring(0, 2);

    if (this.platformSupportedLanguages.includes(browserLanguage)) {
      return browserLanguage;
    }

    return 'nl';
  }

  getPlatformLanguagesAsOptions$(orderBy: 'label' | 'value' = 'label', useShortLanguageNames: boolean = false): Observable<Option[]> {
    return this.getLanguagesAsOptions$(this.platformSupportedLanguages, orderBy, useShortLanguageNames);
  }

  getConversationLanguagesAsOptions$(orderBy: 'label' | 'value' = 'label', useShortLanguageNames: boolean = false): Observable<Option[]> {
    return this.getLanguagesAsOptions$(this.conversationSupportedLanguages, orderBy, useShortLanguageNames, 'common.translatedLanguages.');
  }

  registerAngularLocaleData(language: string): void {
    language = language.toLowerCase();

    if (this.isLocaleRegistered(language)) {
      return;
    }

    switch (language) {
      case 'fr':
        registerLocaleData(localeFr);
        break;
      case 'nl':
        registerLocaleData(localeNl);
        break;
      case 'de':
        registerLocaleData(localeDe);
        break;
      default:
        registerLocaleData(localeEn);
        break;
    }

    this._registeredLocales.add(language);
  }

  isLocaleRegistered(language: string): boolean {
    return this._registeredLocales.has(language);
  }

  getDateFnsLocale(language: string): Locale {
    switch (language) {
      case 'fr':
        return fr;
      case 'nl':
        return nl;
      case 'de':
        return de;
      default:
        return enUS;
    }
  }

  convertLanguageToMollieLocale(language: string): string {
    switch (language) {
      case 'fr':
        return 'fr_FR';
      case 'nl':
        return 'nl_NL';
      case 'de':
        return 'de_DE';
      default:
        return 'en_US';
    }
  }

  getTranslatedLanguageName$(language: string): Observable<string> {
    return this.translateService.get(`common.translatedLanguages.${language}`);
  }

  getInstantTranslatedLanguageName(language: string): string {
    return this.translateService.instant(`common.translatedLanguages.${language}`);
  }

  getInstantLanguageName(language: string): string {
    return this.translateService.instant(`common.languages.${language}`);
  }

  getLanguageName$(language: string): Observable<string> {
    return this.translateService.get(`common.languages.${language}`);
  }

  protected getLanguagesAsOptions$(
    languages: string[], orderBy: 'label' | 'value' = 'label',
    useShortLanguageNames: boolean = false,
    keyPrefix: string = 'common.languages.'): Observable<Option[]> {
    if (useShortLanguageNames) {
      return of(languages.map((lang: string): Option => ({
        value: lang,
        label: lang,
      })));
    }

    const observables: Observable<string>[] = languages.map((lang: string) => this.translateService.get(`${keyPrefix}${lang}`));

    return forkJoin(observables)
      .pipe(map((translations: string[]) => {
        return translations
          .map((translation: string, index: number): Option => ({
            value: languages[index],
            label: translation,
          }))
          .sort((optionA: Option, optionB: Option) =>
            (orderBy === 'label' ? optionA.label : optionA.value as string)
              .localeCompare(orderBy === 'label' ? optionB.label : optionB.value as string)
          );
      }));
  }
}
