<vh-tertiary-page [returnLinkIsVisible]="false" [doctorIsVisible]="true">
  <h1>{{ 'pages.survey.submitted.title' | translate }}</h1>
  <p [vhMarginTop]="8">{{ 'pages.survey.submitted.text' | translate }}</p>
  <vh-button
    [vhMarginTop]="32"
    [label]="'common.close' | translate"
    [iconRightPath]="'assets/icons/arrow-right-white.svg'"
    [alignment]="'center'"
    (buttonClick)="navigateBackToHome()"></vh-button>
</vh-tertiary-page>
