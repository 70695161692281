<div class="banner-container">
  <div class="text-container">
    <h2>{{ 'pages.createAppointment.step2.mobileInvitation.title' | translate }}</h2>
    <p [vhMarginTop]="8">{{ 'pages.createAppointment.step2.mobileInvitation.description' | translate }}</p>
  </div>
  <img
    class="qrcode"
    draggable="false"
    *ngIf="qrCode"
    [src]="qrCode"
    [alt]="'pages.createAppointment.step2.mobileInvitation.qrCodeAltText' | translate" />
</div>
