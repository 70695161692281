import { Component, Input } from '@angular/core';

@Component({
  selector: 'vh-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: [],
})
export class TimelineComponent {
  @Input() items: string[] = [];
}
