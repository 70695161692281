import { Injectable } from '@angular/core';
import { VisitService } from '@services/visits/visit.service';
import { Visit } from '@models/visit.model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Room } from '@models/room.model';
import { VisitFilter } from '@modules/admin/admin-dashboard/components/filter-visits-sidebar/visit-filter.model';

@Injectable({
  providedIn: 'root',
})
export class VisitsOverviewVisitsService {

  protected visits: Visit[] = [];
  protected previousFilter: VisitFilter;

  constructor(private readonly visitService: VisitService) {
  }

  loadVisits$(filter: VisitFilter, forceReload: boolean = false): Observable<Visit[]> {
    if (filter.selectedRooms.size === 0) {
      return;
    }

    // If only the rooms filter changed, we can filter the already fetched data instead
    if (!forceReload && this.shouldFetchFromCache(filter)) {
      return of(this.filterVisitsByRoom(this.visits, filter.selectedRooms));
    }

    this.previousFilter = filter;

    return this.visitService.getVisitsForCurrentInstitutionWithFilterRecursively$(filter)
      .pipe(
        tap((visits: Visit[]): void => {
          this.visits = visits;
        })
      );
  }

  private filterVisitsByRoom(visits: Visit[], rooms: Map<string, Room>): Visit[] {
    return visits.filter((visit: Visit) => rooms.has(visit.room.id));
  }

  private shouldFetchFromCache(filter: VisitFilter): boolean {
    const cachedVisit: Visit = this.visits[0] ?? null;

    return !!cachedVisit || this.previousFilter && this.previousFilter.selectedRooms.size !== filter.selectedRooms.size;
  }
}
