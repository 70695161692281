import { TranslatedProperty } from '@models/translated-property.model';
import { PublicInstitutionSettings, PublicInstitutionSettingsJson } from '@models/public-institution-settings.model';
import { CommunicationMethod } from '@enums/communication-methods';

export class InstitutionSettings extends PublicInstitutionSettings {

  constructor(
    private _id: string,
    private _isVisitReminderActive: boolean,
    private _visitReminderChannels: string[],
    private _visitReminderHoursBeforeSending: number,
    private _shouldVisitBeConfirmedByInstitution: boolean,
    private _shouldVisitBeReconfirmedByPatient: boolean,
    private _patientCanCancelVisit: boolean,
    private _patientCanCancelVisitHoursAtForehand: number,
    private _referringPhysicianCanCancelVisit: boolean,
    private _referringPhysicianCanCancelVisitHoursAtForehand: number,
    private _bookingRestrictionWeekStartsOn: number,
    private _bookingRestrictionMaxDaysBeforeVisit: number,
    private _bookingRestrictionMaxWeeksBeforeVisit: number,
    private _bookingRestrictionMaxSimultaneousBookings: number,
    private _allowDoubleBookingAdmin: boolean,
    private _shouldNotifyAboutPlannedVisits: boolean,
    private _hideServiceDetailsInVisitMails: boolean,
    private _notificationMailAddress: string,
    private _shouldReceiveNewsletter: boolean,
    private _statusAndNewsMailAddress: string,
    private _sec2Fa: boolean,
    private _secMinPasswordLength: number,
    private _supportedLanguages: Set<string>,
    private _defaultLanguage: string,
    private _supportPhone: string,
    private _supportEmail: string,
    private _useSupportEmailAsReplyTo: boolean,
    private _visitGuidelines: TranslatedProperty | null,
    private _eventBusEnabled?: boolean,
    private _preferredCommunicationMethod?: CommunicationMethod
  ) {
    super(_id, _patientCanCancelVisit, _patientCanCancelVisitHoursAtForehand);
  }

  get id(): string {
    return this._id;
  }

  get isVisitReminderActive(): boolean {
    return this._isVisitReminderActive;
  }

  get visitReminderChannels(): string[] {
    return this._visitReminderChannels;
  }

  get visitReminderHoursBeforeSending(): number {
    return this._visitReminderHoursBeforeSending;
  }

  get shouldVisitBeConfirmedByInstitution(): boolean {
    return this._shouldVisitBeConfirmedByInstitution;
  }

  get shouldVisitBeReconfirmedByPatient(): boolean {
    return this._shouldVisitBeReconfirmedByPatient;
  }

  get patientCanCancelVisit(): boolean {
    return this._patientCanCancelVisit;
  }

  get patientCanCancelVisitHoursAtForehand(): number {
    return this._patientCanCancelVisitHoursAtForehand;
  }

  get referringPhysicianCanCancelVisit(): boolean {
    return this._referringPhysicianCanCancelVisit;
  }

  get referringPhysicianCanCancelVisitHoursAtForehand(): number {
    return this._referringPhysicianCanCancelVisitHoursAtForehand;
  }

  get bookingRestrictionWeekStartsOn(): number {
    return this._bookingRestrictionWeekStartsOn;
  }

  get bookingRestrictionMaxDaysBeforeVisit(): number {
    return this._bookingRestrictionMaxDaysBeforeVisit;
  }

  get bookingRestrictionMaxWeeksBeforeVisit(): number {
    return this._bookingRestrictionMaxWeeksBeforeVisit;
  }

  get isMaxSimultaneousBookingsEnabled(): boolean {
    return this._bookingRestrictionMaxSimultaneousBookings > 0;
  }

  get bookingRestrictionMaxSimultaneousBookings(): number {
    return this._bookingRestrictionMaxSimultaneousBookings;
  }

  get allowDoubleBookingAdmin(): boolean {
    return this._allowDoubleBookingAdmin;
  }

  get shouldNotifyAboutPlannedVisits(): boolean {
    return this._shouldNotifyAboutPlannedVisits;
  }

  get hideServiceDetailsInVisitMails(): boolean {
    return this._hideServiceDetailsInVisitMails;
  }

  get notificationMailAddress(): string {
    return this._notificationMailAddress;
  }

  get shouldReceiveNewsletter(): boolean {
    return this._shouldReceiveNewsletter;
  }

  get statusAndNewsMailAddress(): string {
    return this._statusAndNewsMailAddress;
  }

  get sec2Fa(): boolean {
    return this._sec2Fa;
  }

  get secMinPasswordLength(): number {
    return this._secMinPasswordLength;
  }

  get supportedLanguages(): Set<string> {
    return this._supportedLanguages;
  }

  set supportedLanguages(value: Set<string>) {
    this._supportedLanguages = value;
  }

  get defaultLanguage(): string {
    return this._defaultLanguage;
  }

  get supportPhone(): string {
    return this._supportPhone;
  }

  get supportEmail(): string {
    return this._supportEmail;
  }

  get useSupportEmailAsReplyTo(): boolean {
    return this._useSupportEmailAsReplyTo;
  }

  get visitGuidelines(): TranslatedProperty {
    return this._visitGuidelines;
  }

  get isEventBusEnabled(): boolean {
    return this._eventBusEnabled;
  }

  get preferredCommunicationMethod(): CommunicationMethod {
    return this._preferredCommunicationMethod;
  }

  static fromJson(json: InstitutionSettingsJson): InstitutionSettings {
    return json
      ? new InstitutionSettings(
        json.id,
        json.is_visit_reminder_active,
        json.visit_reminder_channels,
        json.visit_reminder_hours_before_sending,
        json.should_visit_be_confirmed_by_institution,
        json.should_visit_be_reconfirmed_by_patient,
        json.patient_can_cancel_visit,
        json.patient_can_cancel_visit_hours_at_forehand,
        json.referring_physician_can_cancel_visit,
        json.referring_physician_can_cancel_visit_hours_at_forehand,
        json.booking_restriction_week_starts_on,
        json.booking_restriction_max_days_before_visit,
        json.booking_restriction_max_weeks_before_visit,
        json.booking_restriction_max_simultaneous_bookings,
        json.allow_double_booking_admin,
        json.should_notify_about_planned_visits,
        json.hide_service_details_in_visit_mails,
        json.notification_mail_address,
        json.should_receive_newsletter,
        json.status_and_news_mail_address,
        json.sec_2fa,
        json.sec_min_password_length,
        new Set<string>(json.supported_languages),
        json.default_language ?? null,
        json.support_phone ?? null,
        json.support_email ?? null,
        json.support_email_as_reply_to ?? false,
        new TranslatedProperty(json.visit_guidelines ?? null),
        json.event_bus_enabled ?? false,
        json.preferred_communication_method ?? CommunicationMethod.MAIL
      )
      : null;
  }

  toJson(): InstitutionSettingsJson {
    return {
      id: this.id,
      is_visit_reminder_active: this.isVisitReminderActive,
      visit_reminder_channels: this.visitReminderChannels,
      visit_reminder_hours_before_sending: this.visitReminderHoursBeforeSending,
      should_visit_be_confirmed_by_institution: this.shouldVisitBeConfirmedByInstitution,
      should_visit_be_reconfirmed_by_patient: this.shouldVisitBeReconfirmedByPatient,
      patient_can_cancel_visit: this.patientCanCancelVisit,
      patient_can_cancel_visit_hours_at_forehand: this.patientCanCancelVisitHoursAtForehand,
      referring_physician_can_cancel_visit: this.referringPhysicianCanCancelVisit,
      referring_physician_can_cancel_visit_hours_at_forehand: this.referringPhysicianCanCancelVisitHoursAtForehand,
      booking_restriction_week_starts_on: this.bookingRestrictionWeekStartsOn,
      booking_restriction_max_days_before_visit: this.bookingRestrictionMaxDaysBeforeVisit,
      booking_restriction_max_weeks_before_visit: this.bookingRestrictionMaxWeeksBeforeVisit,
      booking_restriction_max_simultaneous_bookings: this.bookingRestrictionMaxSimultaneousBookings,
      allow_double_booking_admin: this.allowDoubleBookingAdmin,
      should_notify_about_planned_visits: this.shouldNotifyAboutPlannedVisits,
      hide_service_details_in_visit_mails: this.hideServiceDetailsInVisitMails,
      notification_mail_address: this.notificationMailAddress,
      should_receive_newsletter: this.shouldReceiveNewsletter,
      status_and_news_mail_address: this.statusAndNewsMailAddress,
      sec_2fa: this.sec2Fa,
      sec_min_password_length: this.secMinPasswordLength,
      supported_languages: Array.from(this.supportedLanguages.values()),
      default_language: this.defaultLanguage,
      support_phone: this.supportPhone,
      support_email: this.supportEmail,
      support_email_as_reply_to: this.useSupportEmailAsReplyTo,
      visit_guidelines: this._visitGuidelines.all() ?? null,
      event_bus_enabled: this.isEventBusEnabled,
      preferred_communication_method: this.preferredCommunicationMethod
    };
  }
}

export interface InstitutionSettingsJson extends PublicInstitutionSettingsJson {
  is_visit_reminder_active: boolean;
  visit_reminder_channels: string[];
  visit_reminder_hours_before_sending: number;
  should_visit_be_confirmed_by_institution: boolean;
  should_visit_be_reconfirmed_by_patient: boolean;
  referring_physician_can_cancel_visit: boolean;
  referring_physician_can_cancel_visit_hours_at_forehand: number;
  booking_restriction_week_starts_on: number;
  booking_restriction_max_days_before_visit: number;
  booking_restriction_max_weeks_before_visit: number;
  booking_restriction_max_simultaneous_bookings: number;
  allow_double_booking_admin: boolean;
  should_notify_about_planned_visits: boolean;
  hide_service_details_in_visit_mails: boolean;
  notification_mail_address: string;
  should_receive_newsletter: boolean;
  status_and_news_mail_address: string;
  sec_2fa: boolean;
  sec_min_password_length: number;
  default_language?: string;
  supported_languages: string[];
  support_phone: string | null;
  support_email: string | null;
  support_email_as_reply_to: boolean;
  visit_guidelines: Record<string, string> | string | null;
  event_bus_enabled?: boolean;
  preferred_communication_method?: CommunicationMethod
}
