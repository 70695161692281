import { Injectable } from '@angular/core';
import { AbstractFormService } from '@services/form-services/abstract-form.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DOCTOR_FORM_KEYS } from '@constants/form-keys/doctor-form-keys.constant';
import { Doctor } from '@models/doctor.model';

@Injectable({
  providedIn: 'root',
})
export class DoctorFormService extends AbstractFormService {
  createSpecialtyOnlyFormGroup(doctor: Doctor = null): UntypedFormGroup {
    return this.formBuilder.group({
      [DOCTOR_FORM_KEYS.get('specialty_id')]: [
        doctor?.specialty ? doctor.specialty.id : null,
        [Validators.required],
      ],
    });
  }
}
