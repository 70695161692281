<div [ngClass]="[
  status,
  position === 'right' ? 'flex-row-r-c' : '',
  position === 'left' ? 'flex-row-l-c' : '',
  position === 'center' ? 'flex-row-c-c' : ''
]">
  <div class="gap-h-4"
    [ngClass]="{
      'flex-row-r-c': position === 'right',
      'flex-row-l-c': position === 'left',
      'flex-row-c-c': position === 'center',
      'font-medium-22-28': size === 'large',
    }">
    <span>{{ 'conversations.status.' + status | translate }}</span>

    <vh-icon
      *ngIf="!refreshStatus"
      [fill]="getColor()"
      [stroke]="isFillableIcon() ? null : getColor()"
      [src]="'assets/icons/' + status + '.svg'"
      [width]="size === 'large' ? 20 : 16"
      [height]="size === 'large' ? 20 : 16">
    </vh-icon>
  </div>
</div>