import { NgModule } from '@angular/core';
import { AppointmentCardActionsComponent } from '@modules/patient/components/appointment-card-actions/appointment-card-actions.component';
import { CoreModule } from '@modules/shared/core/core.module';
import { EventsListComponent } from '@modules/patient/components/events-list/events-list.component';
import { FormatPipeModule } from 'ngx-date-fns';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormModule } from '@modules/shared/form/form.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [
  AppointmentCardActionsComponent,
  EventsListComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    FormatPipeModule,
    MatTooltipModule,
  ],
  exports: [
    ...sharedIngredients,
    // App modules
    CoreModule,
    FormModule,
  ],
})
export class PatientSharedModule {}
