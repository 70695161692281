/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const REGISTER_FORM_KEYS: Map<string, string> = new Map();
REGISTER_FORM_KEYS.set('firstName', 'first_name');
REGISTER_FORM_KEYS.set('lastName', 'last_name');
REGISTER_FORM_KEYS.set('birthdate', 'birthdate');
REGISTER_FORM_KEYS.set('username', 'username');
REGISTER_FORM_KEYS.set('gender', 'gender');
REGISTER_FORM_KEYS.set('language', 'language');
REGISTER_FORM_KEYS.set('socialSecurityNumber', 'social_security_number');
REGISTER_FORM_KEYS.set('phone', 'phone');
REGISTER_FORM_KEYS.set('canPhoneBeUsedToContact', 'can_phone_be_used_to_contact');
REGISTER_FORM_KEYS.set('email', 'email');
REGISTER_FORM_KEYS.set('password', 'password');
REGISTER_FORM_KEYS.set('accepted_privacy_policy', 'accepted_privacy_policy');
REGISTER_FORM_KEYS.set('accepted_terms', 'accepted_terms');
