export interface CalendarZoomPreset {
  segments: number;
  duration: number;
  showTimeEveryXMinutes: number;
}

export const calendarZoomPresets: Map<number, CalendarZoomPreset> = new Map<number, CalendarZoomPreset>([
  [0, { segments: 1, duration: 60, showTimeEveryXMinutes: 60 }],
  [1, { segments: 1, duration: 30, showTimeEveryXMinutes: 60 }],
  [2, { segments: 1, duration: 20, showTimeEveryXMinutes: 60 }],
  [3, { segments: 1, duration: 15, showTimeEveryXMinutes: 60 }],
  [4, { segments: 1, duration: 10, showTimeEveryXMinutes: 60 }],
  [5, { segments: 1, duration: 5, showTimeEveryXMinutes: 60 }],
]);
