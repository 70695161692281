<vh-button
  *ngFor="let item of items; let index = index"
  [style]="item.isActive ? activeStyle : inActiveStyle"
  [iconRightPath]="item.iconRightPath"
  [iconLeftPath]="item.iconLeftPath"
  [alignment]="item.alignment"
  [label]="item.label"
  [isDisabled]="isDisabled"
  [addClickableClass]="item.isClickable"
  [cornerStyle]="ButtonCornerStyle.SUBTLE_ROUND"
  (click)="onItemClick(index)">
</vh-button>
