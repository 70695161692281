import { add, addMinutes, eachDayOfInterval, format, isBefore, isValid, isWithinInterval, parse, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay } from 'date-fns';
import { RecurrenceFrequency } from '@enums/recurrence-frequency.enum';
import { Option } from '@interfaces/option.interface';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { DATE_BOUNDARIES } from '@constants/date-boundaries.constant';
import { Timeslot } from '@modules/shared/core/components/timeslot-picker/timeslot.model';

export const changeDateFormat = (date: Date, newFormat: string): Date => {
  return parse(format(date, newFormat), newFormat, new Date());
};

export const calculateEndDate = (start: Date, amount: number, skip: number, type: RecurrenceFrequency = RecurrenceFrequency.YEARLY): Date => {
  const skipPeriod = amount * skip - 1;

  if (skipPeriod === 0) {
    return start;
  }

  switch (type) {
    case RecurrenceFrequency.DAILY:
      return add(start, { days: skipPeriod });
    case RecurrenceFrequency.WEEKLY:
      return add(start, { weeks: skipPeriod });
    case RecurrenceFrequency.MONTHLY:
      return add(start, { months: skipPeriod });
    case RecurrenceFrequency.YEARLY:
      return add(start, { years: skipPeriod });
    case RecurrenceFrequency.NONE:
      return start;
  }
};

/**
 * Duration in minutes to time string.
 */
export const minutesToTimeString = (minutes: number): string => {
  return format(addMinutes(startOfDay(new Date()), minutes), 'HH:mm');
};

export const getDateTimeFromTime = (time: string, fixedDate: Date = new Date()): Date => {
  const values: string[] = time.split(':');

  return new Date(fixedDate.getFullYear(), fixedDate.getMonth(), fixedDate.getDate(), Number(values[0]), Number(values[1]));
};

export const getTimeStringWithTimeZone = (date: Date): string => {
  let dateString: string = format(date, DATE_FORMATS.serverDateTime);
  dateString += ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone;

  return dateString;
};

export const generateTimeslots = (start: number, end: number, interval: number): Timeslot[] => {
  const setTime = (x: Date, h: number = 0, m: number = 0, s: number = 0, ms: number = 0): Date => setHours(setMinutes(setSeconds(setMilliseconds(x, ms), s), m), h);

  const from = setTime(new Date(), start);
  const to = setTime(new Date(), end);
  const step = (x: Date): Date => addMinutes(x, interval);

  const slots = [];

  let cursor = from;

  while (isBefore(cursor, to)) {
    slots.push(new Timeslot(cursor, step(cursor)));
    cursor = step(cursor);
  }

  return slots;
};

/**
 * Tries to parse time input given by the user.
 * Examples:
 * 1    -> 01:00
 * 10   -> 10:00
 * 150  -> 01:50
 * 1500 -> 15:00
 * etc...
 */
export const bestEffortParseTimeInput = (value: string): string => {
  value = value.replace(/[^:\d]/g, '');

  if (value.endsWith(':')) {
    value = value.slice(0, -1);
  }

  if (value.includes(':')) {
    return value;
  }

  if (value.length === 1) {
    return `0${value}:00`;
  }

  if (value.length === 2) {
    return `${value}:00`;
  }

  if (value.length === 3) {
    return '0' + value.substring(0, 1) + ':' + value.substring(1);
  }

  if (value.length === 4) {
    return value.substring(0, 2) + ':' + value.substring(2);
  }

  return value;
};

export const bestEffortDetermineTimeUnit = (value: number): Option => {
  if (value % 30 === 0 && value > 30) {
    return { label: 'common.timeframes.plural.months', value: 'month' };
  }

  if (value % 7 === 0 && value > 7) {
    return { label: 'common.timeframes.plural.weeks', value: 'week' };
  }

  return { label: 'common.timeframes.plural.days', value: 'day' };
};

export const timeInDaysFromTimeUnit = (value: number, unit: string): number => {
  if (unit === 'month') {
    return value * 30;
  }

  if (unit === 'week') {
    return value * 7;
  }

  return value;
};

export const getDayOfWeekAsOptions = (): Option[] => {
  return [
    { label: 'common.days.1', value: 1 },
    { label: 'common.days.2', value: 2 },
    { label: 'common.days.3', value: 3 },
    { label: 'common.days.4', value: 4 },
    { label: 'common.days.5', value: 5 },
    { label: 'common.days.6', value: 6 },
    { label: 'common.days.7', value: 7 },
  ];
};

export const getTimeUnitsAsOptions = (): Option[] => {
  return [
    { label: 'common.timeframes.plural.days', value: 'day' },
    { label: 'common.timeframes.plural.weeks', value: 'week' },
    { label: 'common.timeframes.plural.months', value: 'month' },
  ];
};

export const bestEffortConvertTimeToTimeForUnit = (margin: number): number => {
  if (Math.abs(margin) % 30 === 0 && Math.abs(margin) > 30) {
    return margin / 30;
  }
  if (Math.abs(margin) % 7 === 0 && Math.abs(margin) > 7) {
    return margin / 7;
  }

  return margin;
};

export const isWithinRealisticDateBoundaries = (date: Date): boolean => {
  return !isValid(date) || !isWithinInterval(date, { start: DATE_BOUNDARIES.min.date, end: DATE_BOUNDARIES.max.date });
};

/**
 * Given a start and end date, and a list of available dates, returns the dates that are not available.
 */
export const invertDates = (startDate: Date, endDate: Date, availableDates: Date[]): Date[] => {
  const availableDateStrings: Set<string> = new Set(availableDates.map((date: Date) => format(date, 'yyyy-MM-dd')));

  return eachDayOfInterval({ start: startDate, end: endDate }).filter((date: Date) => {
    return !availableDateStrings.has(format(date, 'yyyy-MM-dd'));
  });
};
