import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonGroupStyle } from '@enums/button-style.enum';
import { COLORS } from '@constants/colors.constant';
import { ButtonOption } from '@modules/shared/core/components/button/button-option.interface';

@Component({
  selector: 'vh-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent implements OnInit {
  protected readonly COLORS: typeof COLORS = COLORS;
  protected readonly ButtonGroupStyle: typeof ButtonGroupStyle = ButtonGroupStyle;

  @Input() isDisabled: boolean;
  @Input() iconHeight: number | string;
  @Input() items: ButtonOption[];
  @Input() style: ButtonGroupStyle = ButtonGroupStyle.SEPARATED;
  @Input() singleItemSelectable: boolean = false;
  @Input() shouldWrap: boolean = true;

  @Output() itemClick: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.iconHeight = 16;
  }

  ngOnInit(): void {
    this.iconHeight = `var(--${this.iconHeight}px)`;
  }

  onItemClick(index: number): void {
    if (this.singleItemSelectable) {
      this.items.map((item: ButtonOption) => item.isActive = false);
    }

    this.items[index].isActive = !this.items[index].isActive;
    this.itemClick.emit(index);
  }
}
