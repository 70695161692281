<div class="container">
  <div class="flex-row-l-c content">
    <div class="top-bar" [ngClass]="{ 'collapsed': !expanded }">
      <div class="logo">
        <img
          [ngClass]="{ clickable: !isRootRoute }"
          [src]="'assets/images/logo/' + environment.assetsFolder + '/vesalius-logo.svg'"
          [alt]="'navigationBar.logoImage' | translate"
          (click)="navigateToHome()" />

        <div class="logo-text">
          <img
            [ngClass]="{ clickable: !isRootRoute }"
            [src]="'assets/images/logo/' + environment.assetsFolder + '/vesalius-logo-text.svg'"
            [alt]="'navigationBar.logoImage' | translate"
            (click)="navigateToHome()" />
        </div>
      </div>

      <div class="background"></div>
    </div>
    <ng-content></ng-content>
  </div>
</div>