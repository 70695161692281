import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { Role } from '@enums/role.enum';
import { ModuleKey } from '@enums/module-key.enum';
import { filterNavigationItems } from '@utils/helpers/navigation.util';

const workListNavigationItems: NavigationItem[] = [
  {
    labelTranslationKey: 'admin.pages.workLists.navigationBar.rescheduleLabel',
    route: NAVIGATION.adminDashboardWorkLists.rescheduling.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR],
  },
  {
    labelTranslationKey: 'admin.pages.settings.navigationBar.surveysLabel',
    route: NAVIGATION.adminDashboardWorkLists.surveys.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
    visibleForModules: [ModuleKey.SURVEY],
  },
];

export const getWorkListNavigationItems = (): NavigationItem[] => {
  return filterNavigationItems(workListNavigationItems);
};
