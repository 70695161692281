/*
 * Values must match with the API
 *
 * Use a Map instead of a plain object for the form control keys
 * because a Map keeps the original insertion order of keys which benefits the form validation order
 */
export const INSTITUTION_SETTINGS_FORM_KEYS: Map<string, string> = new Map([
  ['support_phone', 'support_phone'],
  ['support_email', 'support_email'],
  ['support_email_as_reply_to', 'support_email_as_reply_to'],
  ['default_language', 'default_language'],
  ['visit_guidelines', 'visit_guidelines'],
  ['hide_service_details_in_visit_mails', 'hide_service_details_in_visit_mails'],
  ['patient_can_cancel_visit', 'patient_can_cancel_visit'],
  ['patient_can_cancel_visit_hours_at_forehand', 'patient_can_cancel_visit_hours_at_forehand'],
  ['referring_physician_can_cancel_visit', 'referring_physician_can_cancel_visit'],
  ['referring_physician_can_cancel_visit_hours_at_forehand', 'referring_physician_can_cancel_visit_hours_at_forehand'],
  ['booking_restriction_week_starts_on', 'booking_restriction_week_starts_on'],
  ['booking_restriction_max_days_before_visit', 'booking_restriction_max_days_before_visit'],
  ['booking_restriction_max_weeks_before_visit', 'booking_restriction_max_weeks_before_visit'],
  ['booking_restriction_max_simultaneous_bookings', 'booking_restriction_max_simultaneous_bookings'],
  ['event_bus_enabled', 'event_bus_enabled'],
  ['allow_double_booking_admin', 'allow_double_booking_admin'],
  ['sec_2fa', 'sec_2fa'],
  ['sec_min_password_length', 'sec_min_password_length'],
  ['preferred_communication_method', 'preferred_communication_method'],

  // Frontend only
  ['booking_restriction', 'booking_restriction'],
]);
