import { NgModule } from '@angular/core';
import { VisitCardActionsComponent } from '@modules/booking/components/visit-card-actions/visit-card-actions.component';
import { CoreModule } from '@modules/shared/core/core.module';
import { AdminDashboardModule } from '@modules/admin/admin-dashboard/admin-dashboard.module';
import { DynamicVisitCardComponent } from '@modules/booking/components/dynamic-visit-card/dynamic-visit-card.component';
import { CardModule } from '@modules/shared/cards/card.module';
import { InstitutionDetailsDialogComponent } from '@modules/booking/dialogs/institution-details-dialog/institution-details-dialog.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedIngredients: any[] = [
  VisitCardActionsComponent,
  DynamicVisitCardComponent,
  InstitutionDetailsDialogComponent,
];

@NgModule({
  declarations: sharedIngredients,
  imports: [
    CoreModule,
    CardModule,
    AdminDashboardModule,
  ],
  exports: [
    ...sharedIngredients,
    CoreModule,
    CardModule,
  ],
})
export class BookingSharedModule {}
