<div class="human-body" #humanBodySvgContainer>
  <ng-container [ngTemplateOutlet]="humanBodySvgTemplate"></ng-container>
</div>

<ng-template #humanBodySvgTemplate>
  <svg xmlns="http://www.w3.org/2000/svg" width="171.005" height="401.05" viewBox="0 0 171.005 401.05">
    <g id="human-body" transform="translate(-59.27 0.5)">
      <path
        id="left-foot"
        d="M126.762,185.7l-14.483,3.1c-1.37,3.3-3.719,20.363-3.719,20.363-6.459,16.1-2.74,15.32-2.74,15.32,1.957,2.327,5.48.194,5.48.194a3.361,3.361,0,0,0,4.306-.388c2.153,1.745,4.893-.194,4.893-.194,2.74,1.357,5.48-1.164,5.48-1.164,1.566.776,1.957-.194,1.957-.194,4.893-.388-2.74-15.708-2.74-15.708C123.044,193.263,126.762,185.7,126.762,185.7Z"
        transform="translate(43.055 174.427)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-foot"
        d="M109.841,209.165S107.3,192.1,106.122,188.8l-14.483-3.1s3.523,7.563,1.761,21.332c0,0-7.437,15.32-2.74,15.514,0,0,.391.97,1.957.194,0,0,2.544,2.715,5.48,1.164,0,0,2.544,2.133,4.893.194a3.361,3.361,0,0,0,4.306.388s3.327,2.327,5.48-.194C112.776,224.486,116.3,225.262,109.841,209.165Z"
        transform="translate(28.076 174.427)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-shin"
        d="M109.763,188.786c.979-6.206.391-16.872-1.174-25.017L87.843,156.4c-4.7,12.993-4.11,22.108,7.633,56.627l14.483,3.1C107.414,209.925,109.176,192.665,109.763,188.786Z"
        transform="translate(24.239 146.906)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="left-shin"
        d="M130.854,156.5l-20.746,7.369c-1.566,8.145-2.153,18.811-1.174,25.017.587,3.685,2.349,21.138-.2,27.538l14.483-3.1C134.964,178.608,135.551,169.493,130.854,156.5Z"
        transform="translate(46.597 147)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-knee"
        d="M110.951,155.32,85.9,140c1.761,6.012.783,21.138,2.153,24.823.587,1.551.391,3.1-.979,6.788l20.746,7.369C106.646,173.356,109.777,162.108,110.951,155.32Z"
        transform="translate(25.008 131.501)"
        fill="#f1f1f1"
        stroke="#f1f1f1"
        stroke-width="1" />
      <path
        id="left-knee"
        d="M130.5,164.923c1.37-3.685.391-18.811,2.153-24.823L107.6,155.42c1.174,6.788,4.306,18.035,3.131,23.853l20.746-7.369C130.3,168.026,129.911,166.668,130.5,164.923Z"
        transform="translate(45.778 131.595)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-thigh"
        d="M121.626,123.73,81.7,111.9c1.761,13.769,4.893,43.052,8.22,54.688l25.051,15.32C117.32,167.364,122.213,130.517,121.626,123.73Z"
        transform="translate(20.988 105.107)"
        fill="#f1f1f1"
        stroke="#f1f1f1"
        stroke-width="1" />
      <path
        id="left-thigh"
        d="M144.25,111.8l-39.926,11.83c-.587,6.981,4.11,43.634,6.654,58.179l25.051-15.32C139.162,155.046,142.489,125.569,144.25,111.8Z"
        transform="translate(42.595 105.013)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-hip"
        d="M123.852,89.5H89.994s-10.373,24.823-8.024,43.246l39.926,11.83,1.957.776Z"
        transform="translate(20.914 84.067)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="left-hip"
        d="M137.154,89.5H103.3l-.2,55.852a4.117,4.117,0,0,0,2.349-.776l39.926-11.83C147.527,114.323,137.154,89.5,137.154,89.5Z"
        transform="translate(41.471 84.067)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-hand"
        d="M69.417,106.8C64.72,112.62,56.7,124.644,61,126.2c0,0,3.327.388,7.633-8.339,0,0,0,3.3-3.523,12.8-.587,1.745-3.523,10.86,1.174,7.563,0,0,2.153-1.551,5.089-10.86,0,0-1.566,15.708.2,16.484,2.153,1.164,3.523-1.939,4.5-15.708,0,0,.979-4.46,1.566,12.411,0,.97,1.37,5.236,3.131,1.551,1.566-3.1.783-11.442.783-14.157,0,0,1.957,10.666,3.719,10.666,0,0,2.153,2.521,1.174-10.86-.2-2.133.587-6.594.783-7.951l.2-5.042s-.587-5.818-.587-8.339L75.875,97.3C74.9,99.821,71.765,103.894,69.417,106.8Z"
        transform="translate(0 91.393)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="left-hand"
        d="M150.41,106.709c-2.349-2.909-5.48-6.981-6.263-9.309l-10.96,9.115c0,2.327-.587,8.339-.587,8.339l.2,5.042c0,1.357.783,5.818.783,7.951-.783,13.381,1.174,10.86,1.174,10.86,1.761,0,3.719-10.666,3.719-10.666,0,2.715-.587,11.054.783,14.157,1.761,3.685,3.131-.582,3.131-1.551.391-16.872,1.566-12.411,1.566-12.411.979,13.769,2.153,16.872,4.5,15.708,1.761-.776.2-16.484.2-16.484,2.936,9.309,5.089,10.86,5.089,10.86,4.7,3.3,1.761-5.818,1.174-7.563-3.523-9.5-3.523-12.8-3.523-12.8,4.306,8.533,7.633,8.339,7.633,8.339C162.936,124.744,154.911,112.526,150.41,106.709Z"
        transform="translate(69.706 91.487)"
        fill="#f1f1f1"
        stroke="#f1f1f1"
        stroke-width="1" />
      <path
        id="left-forearm"
        d="M152.51,113.5c-.391-15.127-4.306-21.72-4.306-21.72-6.459-10.278-7.633-29.283-7.633-29.283L121,69.094c4.7,7.175,4.5,19.781,4.5,19.781-.783,13.381,10.764,33.55,10.764,33.55,5.48,8.339,7.633,16.29,7.633,16.872l10.96-9.115C153.684,126.3,152.51,113.5,152.51,113.5Z"
        transform="translate(58.603 58.706)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="belly"
        d="M155.365,65.8H85.3s3.327,35.877.979,45.961h67.913C151.843,101.677,155.365,65.8,155.365,65.8Z"
        transform="translate(24.434 61.806)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-forearm"
        d="M74.654,91.692s-3.719,6.594-4.11,21.72c0,0-1.174,12.8-2.544,16.872L78.96,139.4c0-.582,2.153-8.533,7.633-16.872,0,0,11.547-20.363,10.764-33.55,0,0-.2-12.605,4.5-19.781L82.287,62.6C82.287,62.215,81.113,81.414,74.654,91.692Z"
        transform="translate(7.875 58.797)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="left-shoulder"
        d="M140.476,88.7l-.2-32.192C137.931,34.594,121.687,34.4,121.687,34.4L121.1,95.294Z"
        transform="translate(58.699 32.312)"
        fill="#f1f1f1"
        stroke="#f1f1f1"
        stroke-width="1" />
      <path
        id="chest"
        d="M155.953,34.4H85.3V95.294h70.065Z"
        transform="translate(24.434 32.312)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="right-shoulder"
        d="M75.591,56.508,75.2,88.7l19.571,6.594V34.4h-.587C94.38,34.4,77.94,34.4,75.591,56.508Z"
        transform="translate(14.767 32.312)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="neck"
        d="M137.351,37.445C133.828,32.4,135.785,22.9,135.785,22.9H105.254s1.957,9.5-1.566,14.545c0,0-2.349,5.236-18.788,7.757h71.24C139.7,42.681,137.351,37.445,137.351,37.445Z"
        transform="translate(24.051 21.51)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
      <path
        id="head"
        d="M133.718,35.877c4.893-3.685,4.5-9.115,2.349-8.921-1.761,0-1.37-1.358-1.37-1.358C137.828,1.164,116.1,0,116.1,0h-3.327S91.052,1.164,94.184,25.4c0,0,.391,1.551-1.37,1.358-2.153,0-2.349,5.236,2.349,8.921,0,0,1.174,6.206,4.11,8.533H129.8C132.739,42.083,133.718,35.877,133.718,35.877Z"
        transform="translate(30.228 0)"
        fill="#f1f1f1"
        stroke="#f2f2f2"
        stroke-width="1" />
    </g>
  </svg>
</ng-template>
