import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

/**
 * This directive can be used to add refresh mechanism to any component.
 */
@Directive({
  standalone: true,
  selector: '[vhAutoRefresh]',
})
export class AutoRefreshDirective implements OnInit, OnChanges, OnDestroy {
  private autoRefreshRef: ReturnType<typeof setInterval> = null;

  @Input() isAutoRefreshEnabled: boolean = true;
  @Input() autoRefreshInterval: number = 60000;

  @Output() refreshRequested: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.startAutoRefresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAutoRefreshEnabled && !changes.isAutoRefreshEnabled.firstChange) {
      this.toggleAutoRefresh();
    }
  }

  ngOnDestroy(): void {
    this.stopAutoRefresh();
  }

  resetAutoRefresh(): void {
    if (this.isAutoRefreshEnabled) {
      this.stopAutoRefresh();
      this.startAutoRefresh();
    }
  }

  toggleAutoRefresh(): void {
    if (this.isAutoRefreshEnabled) {
      this.startAutoRefresh();

      return;
    }

    this.stopAutoRefresh();
  }

  protected startAutoRefresh(): void {
    if (!this.isAutoRefreshEnabled) {
      return;
    }

    if (this.autoRefreshRef) {
      this.stopAutoRefresh();
    }

    this.autoRefreshRef = setInterval((): void => {
      this.refreshRequested.emit();
    }, this.autoRefreshInterval);
  }

  protected stopAutoRefresh(): void {
    clearInterval(this.autoRefreshRef);
    this.autoRefreshRef = null;
  }
}
