import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { NAVIGATION } from '@constants/navigation.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitService } from '@services/visits/visit.service';
import { Visit } from '@models/visit.model';
import { BaseVisitManagementComponent } from '@modules/shared/visits/components/base-visit-management/base-visit-management.component';
import { CalendarEventMapperService } from '@services/calendar-event-mapper.service';
import { VisitDetailsDialogResult } from '@modules/shared/visits/dialogs/visit-details-dialog/visit-details-dialog.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { getNavigationItems } from '@modules/patient/constants/navigation-items.constant';

@Component({
  selector: 'vh-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent extends BaseVisitManagementComponent implements OnInit {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @ViewChild('calendarTemplate') calendarTemplate: TemplateRef<any>;
  calendarDialog: MatDialogRef<TemplateRef<any>>;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  slotsWithAppointments: Visit[];
  futureAppointmentDates: Date[];
  getFutureAppointmentsErrorMessageTranslationKey: string;

  expiredSlotsWithAppointments: Visit[];
  getExpiredAppointmentsErrorMessageTranslationKey: string;

  currentDateTime: string = formatInTimeZone(new Date(), 'UTC', DATE_FORMATS.serverDateTime);

  navigationItems: NavigationItem[] = getNavigationItems();

  constructor(
    breakpointObserver: BreakpointObserver,
    dialogService: MatDialog,
    activatedRoute: ActivatedRoute,
    router: Router,
    calendarEventMapperService: CalendarEventMapperService,
    private readonly visitService: VisitService
  ) {
    super(breakpointObserver, dialogService, activatedRoute, router, calendarEventMapperService);
  }

  ngOnInit(): void {
    this.loadPastVisits();
    this.loadFutureVisits();
  }

  openCalendarDialog = (): void => {
    this.calendarDialog = this.dialogService.open(this.calendarTemplate, {
      panelClass: 'dialog-size-tiny',
    });
  };

  onFutureSlotClicked = (visit: Visit): void => {
    this.selectedVisit = visit;
    this.openVisitDetailsDialog({ isReadOnly: true });
  };

  onExpiredSlotClicked = (visit: Visit): void => {
    this.selectedVisit = visit;
    this.openVisitDetailsDialog({ isReadOnly: true });
  };

  getCurrentRoute(): string {
    return NAVIGATION.dashboard.route;
  }

  protected onVisitDetailsDialogClosed(result: VisitDetailsDialogResult): void {
    super.onVisitDetailsDialogClosed(result);

    if (result?.canceledVisit) {
      this.loadFutureVisits();
    }
  }

  private loadPastVisits(): void {
    this.visitService.getVisits$(null, this.currentDateTime)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (expiredAppointments: Visit[]): void => {
          this.expiredSlotsWithAppointments = expiredAppointments;
        },
        error: (): void => {
          this.getExpiredAppointmentsErrorMessageTranslationKey = 'common.unknownError';
        },
      });
  }

  private loadFutureVisits(): void {
    this.visitService.getVisits$(this.currentDateTime, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (futureAppointments: Visit[]): void => {
          this.slotsWithAppointments = futureAppointments;
          this.futureAppointmentDates = futureAppointments.map((slot: Visit): Date => slot.start);
        },
        error: (): void => {
          this.getFutureAppointmentsErrorMessageTranslationKey = 'common.unknownError';
        },
      });
  }
}
