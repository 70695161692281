import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATION } from '@constants/navigation.constant';

@Component({
  selector: 'vh-base-payment-flow-page',
  templateUrl: './base-payment-flow-page.component.html',
  styleUrls: ['./base-payment-flow-page.component.scss'],
})
export class BasePaymentFlowPageComponent {

  constructor(private readonly router: Router) {
  }

  cancelPurchase = (): void => {
    void this.router.navigate([NAVIGATION.wallet.route]);
  };
}
