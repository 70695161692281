import { Option } from '@interfaces/option.interface';

export enum SettingsCardStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export function getSettingsCardStatusOptions(): Option[] {
  return Object.keys(SettingsCardStatus).map((key: string): Option => ({
    label: settingsCardStatusTranslationKey(SettingsCardStatus[key]),
    value: SettingsCardStatus[key],
  }));
}

export function settingsCardStatusTranslationKey(status: SettingsCardStatus): string {
  return `cardStatusTypes.${status}`;
}
