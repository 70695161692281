import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModuleKey } from '@enums/module-key.enum';
import { ModuleService } from '@services/module.service';

@Directive({
  selector: '[vhRequiresModule]',
})
export class RequiresModuleDirective {
  @Input() set vhRequiresModule(moduleKey: ModuleKey) {
    this.updateView(moduleKey);
  }

  constructor(
    protected readonly element: ElementRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly moduleService: ModuleService
  ) {}

  private updateView(moduleKey: ModuleKey): void {
    if (this.moduleService.hasAccessToModule(moduleKey)) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    this.viewContainer.clear();
  }
}
