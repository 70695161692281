import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

@Component({
  selector: 'vh-survey-submit-success-page',
  templateUrl: './survey-submit-success-page.component.html',
  styleUrls: ['./survey-submit-success-page.component.scss'],
})
export class SurveySubmitSuccessPageComponent {
  constructor(private readonly router: Router, private readonly authenticationService: AuthenticationService) {}

  navigateBackToHome(): void {
    redirectToMostRelevantHome(this.authenticationService, this.router);
  }
}

