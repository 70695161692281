import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  selector: 'vh-payment-redirect-page',
  templateUrl: './payment-redirect-page.component.html',
  styleUrls: ['./payment-redirect-page.component.scss'],
})
@UntilDestroy()
export class PaymentRedirectPageComponent implements OnInit {

  ngOnInit(): void {
    setTimeout(function () {
      window.open('', '_self');
      window.close();
    }, 5000);
  }
}
