import { addDays, endOfDay, format, isToday, startOfDay } from 'date-fns';
import { IObject } from '@app-types/iobject.type';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { environment } from '@environments/environment';
import { Timeslot } from '@modules/shared/core/components/timeslot-picker/timeslot.model';

/**
 * Keeps track of all the filters set to make a booking
 *
 * Note: the difference between institution and scopedInstitution is that the institution indicates
 * the user clicked on a specific institution to filter on. The scoped institution means the application
 * was informed to only return visits from a specific institution keeping in mind the available
 * modalities and other relevant metadata.
 *
 * Long story short: in the frontend the behavior is different but API-wise it's the same result.
 */
export class BookingFlowFilter {
  private LOOK_AHEAD_DAYS: number = 7;

  private _selectedInstitutionId?: string | null;
  private _from?: Date;
  private _to?: Date;
  private _timeslot?: Timeslot;
  private _amountOfRequestedSlots: number = environment.app.slotAmount;
  private _latitude: number;
  private _longitude: number;
  private _radius: number;

  constructor() {
    this.reset();
  }

  get selectedInstitutionId(): string {
    return this._selectedInstitutionId;
  }

  set selectedInstitutionId(value: string | null) {
    this._selectedInstitutionId = value;
  }

  get from(): Date {
    return this._from;
  }

  set from(value: Date) {
    this._from = isToday(value) ? new Date() : startOfDay(value);
  }

  get to(): Date {
    return this._to;
  }

  set to(value: Date) {
    if (!value) {
      value = new Date();
    }

    this._to = addDays(endOfDay(value), this.LOOK_AHEAD_DAYS);
  }

  get timeslot(): Timeslot {
    return this._timeslot;
  }

  set timeslot(value: Timeslot) {
    this._timeslot = value;
  }

  get amountOfRequestedSlots(): number {
    return this._amountOfRequestedSlots;
  }

  get latitude(): number {
    return this._latitude;
  }

  set latitude(value: number) {
    this._latitude = value;
  }

  get longitude(): number {
    return this._longitude;
  }

  set longitude(value: number) {
    this._longitude = value;
  }

  get radius(): number {
    return this._radius;
  }

  set radius(value: number) {
    this._radius = value;
  }

  toQueryParams(): IObject {
    const queryParams: IObject = {
      params: {},
    };

    if (this.from) {
      queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.from] = format(this.from, DATE_FORMATS.serverDateTime);
    }

    if (this.to) {
      queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.to] = format(this.to, DATE_FORMATS.serverDateTime);
    }

    if (this.timeslot) {
      queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.timeslot] = this.timeslot.toString();
    }

    if (this.selectedInstitutionId) {
      queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.selectedInstitution] = this.selectedInstitutionId;
    }

    if (this.amountOfRequestedSlots) {
      queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.pageSize] = this.amountOfRequestedSlots;
    }

    queryParams.params[ENDPOINTS.getAvailableSlots.queryParams.timezone] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return queryParams;
  }

  reset(): void {
    this.from = new Date();
    this.to = null;
    this.timeslot = null;
    this.selectedInstitutionId = null;
  }

  hasFilter(): boolean {
    return !!(this.to || this.timeslot || this.selectedInstitutionId || !isToday(this.from));
  }

  static fromJson(json: BookingFlowFilterJson): BookingFlowFilter {
    const filter: BookingFlowFilter = new BookingFlowFilter();

    filter.selectedInstitutionId = json.selected_institution_id ?? null;
    // TODO: implement Timeslot.fromJson
    // filter.timeslot = json.timeslot ? Timeslot.fromJson(json.timeslot) : null;
    filter.from = json.from ? new Date(json.from) : null;
    filter.to = json.to ? new Date(json.to) : null;
    filter.latitude = json.latitude;
    filter.longitude = json.longitude;
    filter.radius = json.radius;

    return filter;
  }

  toJson(): BookingFlowFilterJson {
    return {
      selected_institution_id: this.selectedInstitutionId ?? null,
      from: this.from ? format(this.from, DATE_FORMATS.serverDate) : null,
      to: this.to ? format(this.to, DATE_FORMATS.serverDate) : null,
      timeslot: this.timeslot?.toString(),
      latitude: this.latitude,
      longitude: this.longitude,
      radius: this.radius,
    };
  }
}

export interface BookingFlowFilterJson {
  selected_institution_id?: string;
  from?: string;
  to?: string;
  timeslot?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
}
