import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { Role } from '@enums/role.enum';
import { filterNavigationItems } from '@utils/helpers/navigation.util';
import { ModuleKey } from '@enums/module-key.enum';

const navigationItems: NavigationItem[] = [
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.conversationsLabel',
    route: NAVIGATION.adminDashboardConversations.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER],
    icon: 'chat',
  },
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.appointmentsLabel',
    route: NAVIGATION.adminDashboardAppointments.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR, Role.INSTITUTION_VIEWER],
    visibleForModules: [ModuleKey.BOOKING],
    icon: 'calendar',
  },
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.insightsLabel',
    route: NAVIGATION.adminDashboardInsights.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN],
    visibleForModules: [ModuleKey.BOOKING],
    icon: 'chart',
  },
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.workListsLabel',
    route: NAVIGATION.adminDashboardWorkLists.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER, Role.INSTITUTION_EDITOR],
    visibleForModules: [ModuleKey.BOOKING],
    icon: 'list',
  },
  {
    labelTranslationKey: 'admin.navigationBar.dashboard.settingsLabel',
    route: NAVIGATION.adminDashboardSettings.route,
    visibleForRoles: [Role.INSTITUTION_ADMIN, Role.INSTITUTION_MANAGER],
    icon: 'settings',
    stroke: true,
  },
];

export const getNavigationItems = (): NavigationItem[] => {
  return filterNavigationItems(navigationItems);
};
