import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ENDPOINTS } from '@constants/endpoints.constant';
import { NAVIGATION } from '@constants/navigation.constant';
import { AuthenticationService } from '@services/authentication.service';
import { environment } from '@environments/environment';
import { LanguageService } from '@services/language.service';
import { LOCAL_STORAGE_KEYS } from '@constants/local-storage-keys.constant';
import { LocalStorageCacheService } from '@services/cache/local-storage-cache.service';
import { JwtToken } from '@interfaces/jwt-token.interface';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly excludedEndpoints: string[] = [
    ENDPOINTS.login.route,
    ENDPOINTS.logout.route,
    ENDPOINTS.bookVisit.route,
    ENDPOINTS.register.route,
  ];

  private readonly MAX_RETRIES: number = 3;
  private readonly RETRY_DELAY_MS: number = 1000;
  private isRefreshingToken: boolean = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly localStorageCacheService: LocalStorageCacheService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.addLanguageHeader(request);

    // Skip adding the bearer token for the refresh token request
    if (!this.isRequestToEndpoint(request, ENDPOINTS.refreshToken.route)) {
      request = this.addBearerTokenIfAvailable(request);
    }

    return this.handleRequestWithRetry(request, next, 0);
  }

  private handleRequestWithRetry(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    retryCount: number
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (error.status === 401 && !this.isRefreshingToken && !this.isRequestToExcludedEndpoint(request)) {
          // Refresh token immediately
          this.isRefreshingToken = true;

          return this.authenticationService.refreshToken$().pipe(
            switchMap(() => {
              this.isRefreshingToken = false;

              // Retry the original request with a new token
              const newRequest = this.addBearerTokenIfAvailable(request);

              return this.handleRequestWithRetry(newRequest, next, retryCount);
            }),
            catchError(() => {
              this.isRefreshingToken = false;
              this.forceLogout();

              return EMPTY;
            })
          );
        }

        // Retry logic only for transient errors (e.g., 500, 502)
        if (this.shouldRetryCall(error, retryCount, request)) {
          return timer(this.RETRY_DELAY_MS).pipe(
            switchMap(() => this.handleRequestWithRetry(request, next, retryCount + 1))
          );
        }

        return throwError(() => error);
      })
    );
  }

  private addLanguageHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        'Accept-Language': this.languageService.getPreferredLanguage(),
      },
    });
  }

  private addBearerTokenIfAvailable(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (!this.authenticationService.hasToken()) {
      return request;
    }

    const token: JwtToken | null = this.localStorageCacheService.get<JwtToken>(LOCAL_STORAGE_KEYS.token);

    return request.clone({
      setHeaders: {
        'Accept-Language': this.languageService.getPreferredLanguage(),
        'Authorization': `Bearer ${token.access_token}`,
      },
    });
  }

  private shouldRetryCall(error: HttpErrorResponse, retryCount: number, request: HttpRequest<unknown>): boolean {
    const transientErrors: number[] = [502, 503];

    return transientErrors.includes(error.status) &&
      retryCount < this.MAX_RETRIES &&
      !this.isRequestToExcludedEndpoint(request);
  }

  private isRequestToExcludedEndpoint(request: HttpRequest<unknown>): boolean {
    return this.excludedEndpoints.some((endpoint: string): boolean => this.isRequestToEndpoint(request, endpoint));
  }

  private isRequestToEndpoint(request: HttpRequest<unknown>, endpoint: string): boolean {
    const requestEndpoint: string = request.url.replace(environment.apiBaseUrl, '');

    if (requestEndpoint === endpoint) {
      return true;
    }

    const requestEndpointPaths = requestEndpoint.split('/');
    const endpointPaths = endpoint.split('/');

    if (requestEndpointPaths.length !== endpointPaths.length) {
      return false;
    }

    for (let i = 0; i < endpointPaths.length; i++) {
      if (endpointPaths[i].match(/{.*}/)) {
        endpointPaths[i] = requestEndpointPaths[i];
      }
    }

    return requestEndpoint === endpointPaths.join('/');
  }

  private forceLogout(): void {
    this.authenticationService.removeLoggedInUser();
    void this.router.navigate([NAVIGATION.login.route]);
  }
}
