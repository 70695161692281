<vh-secondary-page [headerTemplate]="institutionScopeHeader" [title]="'admin.pages.dashboard.createAppointmentButtonLabel' | translate" (navigateBackClick)="exitBookingFlow()">
  <vh-content-layout [hasVerticalSpacing]="true" [maxWidth]="currentPage === 'select_patient' ? 600 : null">
    <ng-container *ngIf="currentPage === 'account_confirmation'" [ngTemplateOutlet]="confirmAccountTemplate"></ng-container>
    <ng-container *ngIf="currentPage === 'select_patient'" [ngTemplateOutlet]="selectPatientTemplate"></ng-container>
    <ng-container *ngIf="currentPage === 'register_or_login'" [ngTemplateOutlet]="registerOrLoginTemplate"></ng-container>
  </vh-content-layout>
</vh-secondary-page>

<ng-template #registerOrLoginTemplate>
  <div class="flex-row login-wrapper">
    <div *ngIf="!loginLoading" class="flex-col-c-t">
      <div>
        <vh-dynamic-visit-card [visits]="bookingState.booking.visits"></vh-dynamic-visit-card>
      </div>
      <div [vhMarginTop]="32">
        <vh-register
          [autoLogin]="!this.currentUser"
          [headerIsVisible]="!this.currentUser"
          [hasPasswordInput]="!this.currentUser"
          [hasPrivacyPolicyInput]="!this.currentUser"
          [registerSelf]="!this.currentUser"
          [registerButtonIsVisible]="true"
          [afterSuccessfulRegisterFunction]="onUserRegisteredOrLoggedInSuccessfully">
        </vh-register>
      </div>
    </div>
    <div *ngIf="!this.currentUser && !loginLoading" class="flex-col-c-c">
      <div>
        <h2>{{ 'pages.createAppointment.registerOrLogin.loginTitle' | translate }}</h2>
        <vh-login [vhMarginTop]="16" [afterSuccessfulLoginFunction]="onUserRegisteredOrLoggedInSuccessfully"></vh-login>
      </div>
    </div>
    <vh-loader class="loader" *ngIf="loginLoading"></vh-loader>
  </div>
</ng-template>

<ng-template #confirmAccountTemplate>
    <vh-dynamic-visit-card [visits]="bookingState.booking.visits"></vh-dynamic-visit-card>
    <h1 [vhMarginTop]="64">{{ 'pages.createAppointment.accountConfirmation.title' | translate }}</h1>
    <p [vhMarginTop]="8">
      {{
        'pages.createAppointment.accountConfirmation.loggedInText'
          | translate
            : {
                name: patient.fullName,
                email: patient.email ?? 'pages.createAppointment.accountConfirmation.unknownEmail' | translate
              }
      }}
    </p>
    <vh-button
      [vhMarginTop]="16"
      [label]="'pages.createAppointment.accountConfirmation.reserveAppointmentButtonLabel' | translate"
      [iconRightPath]="'assets/icons/arrow-right-white.svg'"
      [alignment]="'center'"
      (buttonClick)="onPatientSelected(currentUser, true)">
    </vh-button>
    <p [vhMarginTop]="32">{{ 'pages.createAppointment.accountConfirmation.reserveForOtherPersonText' | translate }}</p>
    <vh-button
      [vhMarginTop]="16"
      [label]="'pages.createAppointment.accountConfirmation.logoutButtonLabel' | translate"
      [style]="ButtonStyle.TERTIARY"
      [alignment]="'center'"
      (buttonClick)="logout()">
    </vh-button>
    <vh-message
      *ngIf="logoutErrorMessageTranslationKey"
      [vhMarginTop]="8"
      [vhFontClass]="'font-regular-18-22'"
      [vhTextColor]="'var(--color-alert)'"
      [text]="logoutErrorMessageTranslationKey | translate"
      [textAlignment]="'center'">
    </vh-message>
</ng-template>

<ng-template #selectPatientTemplate>
  <vh-user-search
    (userSelected)="onPatientSelected($event, false)">
  </vh-user-search>

  <button class="clickable" (click)="currentPage = 'register_or_login'">
    <h2>{{ 'conversations.create.selectPatient.create' | translate }}</h2>
    <vh-icon src="assets/icons/plus.svg" [fill]="COLORS.secondary"></vh-icon>
  </button>

  <!-- Display 'Next' button once a user is selected -->
  <div class="button-loader-vertical">
    <vh-button 
      [label]="'common.next' | translate" 
      [isDisabled]="!selectedUser || isLoadingNextStep"
      (buttonClick)="goToNextStep()"
      [centered]="true"
      [vhMarginTop]="32"
      [iconRightPath]="'assets/icons/arrow-right-narrow.svg'"
      [iconRightWidth]="20"
      [iconRightHeight]="20"
      [iconRightStroke]="COLORS.white">
    </vh-button>
    <vh-loader *ngIf="isLoadingNextStep" class="loader"></vh-loader>
  </div>
</ng-template>

<ng-template #institutionScopeHeader>
  <vh-institution-header *ngIf="bookingState.scopedInstitution" [institution]="bookingState.scopedInstitution"></vh-institution-header>
</ng-template>
