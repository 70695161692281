<h1>Privacy Statement</h1>
<p>
  At Vesalius Health (“Vesalius Health BV”, "We", "Our" or "Us"), we care about your privacy. We treat your personal data (or “your data”) with the necessary
  respect and confidentiality at all times. This statement explains how we collect, process, and use your personal data. We analyse your
  data to be able to provide and optimise our services and your customer experience.
</p>
<p>Vesalius Health reserves the right to change this privacy policy at any time. Any significant change will be clearly communicated to the user.</p>
<p>Vesalius is a Paas (multi sided platform as a service) developed by and in the portfolio of Vesalius Health BV,</p>
<p>
  Our websites <a href="https://vesalius.ai" target="_blank">vesalius.ai</a> (service website) and
  <a href="https://vesalius.health" target="_blank">vesalius.health</a> (company website) are used to collect personal data by Vesalius Health BV, based on our
  privacy statement.
</p>
<h3>Who we are and how to contact us:</h3>
<p>
  Vesalius Health BV
  <br />
  Ottergemsesteenweg-Zuid 808b / 48, 9000 Gent
  <br />
  Enterprise number: 1011.125.426
</p>
<p>Email: <a href="mailto:privacy@vesalius.health">privacy&#64;vesalius.health</a></p>
<p>For any questions about the processing of your data, feel free to contact us.</p>
<h2>Content</h2>
<ol>
  <li>What data do we collect?</li>
  <li>Who is responsible for collecting and processing your personal data?</li>
  <li>How do we collect your data?</li>
  <li>How will we use your data?</li>
  <li>On what legal grounds do we collect your data?</li>
  <li>How do we store your data?</li>
  <li>How long do we store your data?</li>
  <li>What data do we share with third parties?</li>
  <li>What are your data protection rights?</li>
  <li>How can you act upon your rights?</li>
</ol>
<h2 class="indented">1) What data do we collect?</h2>
<p>
  Any person that visits our Website will leave a certain amount of personal data. We limit our collection of your personal data to the
  minimum amount necessary for the purposes for which we process the data. Depending on your relationship with Vesalius Health and the situation, we
  process the following data:
</p>
<h3>Personal data</h3>
<p>
  The collection of personal data means the collection of all information that allows us to directly or indirectly identify you, regardless
  of whether we actually do this. This includes:
</p>
<ul>
  <li>Your first and last name;</li>
  <li>Your gender</li>
  <li>Identification number of the National Register</li>
  <li>Your email address;</li>
  <li>Your telephone number</li>
  <li>Your contact history: all communication between you and us (e.g. by email or the contact form on our Website).</li>
  <li>Your history of appointments</li>
</ul>
<h3>Usage data</h3>
<p>We collect information on how you interact with our Website.</p>
<p>This includes:</p>
<ul>
  <li>
    the URL, referrer, your computer's Internet Protocol address (IP address), device and browser characteristics (User Agent), and
    timestamp
  </li>
  <li>how you end up on our Website;</li>
  <li>
    the pages of our Website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers.
  </li>
</ul>
<h3>Tracking cookie data</h3>
<p>We use cookies to track certain activities on our Website. In that way, we can improve your experience with it. This includes:</p>
<ul>
  <li>Keeping you signed in;</li>
  <li>Understanding how you use our Website.</li>
</ul>
<p>
  Tracking cookies are also used for marketing and advertising purposes, showing you content that you might find interesting. You can
  instruct your browser to refuse all cookies or to indicate when a cookie is being sent. When you use our Website, you must give your
  consent first in order for us to track your behavior.
</p>
<p>For more information about our usage of cookies, please head over to our <a href="" target="_blank">cookie policy</a>.</p>
<p>
  Not all this data (personal, usage, and tracking cookies) is necessarily processed. As mentioned above it depends on your relationship
  with us and the situation.
</p>
<p class="indented">
  E.g. if you fill in a form to get access to certain content, we’ll collect the necessary personal data including your first and last name,
  email address and telephone number. But if you only browse our Website, we won’t collect this information.
</p>
<h2 class="indented">2) Who is responsible for collecting and processing your personal data?</h2>
<p>
  Vesalius Health is responsible for collecting and processing your data. We are the <span>controller</span> of your personal data. This means we can
  decide alone or in cooperation with others what data is being collected and processed, as well as the purposes and the technical and
  organisational means with regard to the processing of those personal data.
</p>
<p>
  Vesalius Health is free to rely on <span>data processors</span>. A processor is the natural or legal person who processes your personal data upon
  request and on behalf of the data controller. The processor is required to ensure the security and confidentiality of the data. The
  processor shall always act on the instructions of the data controller.
</p>
<h2 class="indented">3) How do we collect your data?</h2>
<p>You directly provide Vesalius Health with most of the data we collect. We collect and process data when you:</p>
<ul>
  <li>Use or view or Website via your browser’s cookies;</li>
  <li>Fill in a form on our Website to access certain pieces of content;</li>
  <li>Fill in a form to contact us;</li>
  <li>Register for our newsletter;</li>
  <li>Contact us via email or phone.</li>
  <li>Fill in the registration form to create an account</li>
  <li>Book an appointment or add any other information to your account</li>
</ul>
<p>Vesalius Health may also receive data indirectly from the following sources:</p>
<ul>
  <li>
    Via our clients, hospitals, medical departments, physicians and other third parties, in order to contact you in response to the service
    we provide and to the project we’re working on with that partner. This means you are professionally connected to them.
  </li>
  <li>Direct messages or filled forms via any of our social media channels;</li>
  <li>Public sources (your company’s Website, your professional profile on social media, or other publicly available information).</li>
</ul>
<h2 class="indented">4) How will we use your data?</h2>
<p>
  As set out in the European General Data Protection Regulation (GDPR), we only process your data for legitimate purposes on the basis of
  the grounds for processing.
</p>
<p>Vesalius Health collects your data to:</p>
<ul>
  <li>provide and maintain the Vesalius platform;</li>
  <li>offer you a safe, personal, and optimised user experience;</li>
  <li>process your questions regarding our services or job opportunities;</li>
  <li>monitor the usage of our Website;</li>
  <li>detect, prevent and address technical issues;</li>
  <li>provide partner support services;</li>
  <li>
    email you content pieces that might be of your interest, project-related news and feedback, news, and general information about Vesalius Health
    and our services. We only send these emails if you have expressly agreed to it. You can always opt out;
  </li>
  <li>
    target you with personalised advertisements, if you have expressly agreed to it. In that case, you are free to withdraw your consent at
    any time.
  </li>
</ul>
<p>
  We only collect these data on the basis of your consent. When you accept marketing and tracking cookies we can process your data for
  marketing purposes. You can revoke this consent at any time by writing an email or letter. You can find our contact information at the
  beginning of this statement.
</p>
<p>
  Essential cookies do not depend on your consent. These cookies are necessary for us to provide our Website to you. Functional cookies are
  necessary for the Website to function properly. If you do not wish to accept essential and functional cookies, you cannot use our Website
  properly.
</p>
<p>
  You have the right to stop Vesalius Health from contacting you for marketing purposes, or from storing your data on our third parties databases
  (see 4).
</p>
<h2 class="indented">5) On what legal grounds do we collect your data?</h2>
<p>We process personal data in compliance with the GDPR, on the following legal grounds:</p>
<ul>
  <li>
    on the basis of your consent to create an account in order to offer you a personalised experience. You can delete your account and
    personal data at any time;
  </li>
  <li>on the basis of your consent to contact you with marketing related content. You can withdraw your consent at any time;</li>
  <li>
    on the basis of our legitimate interest in sending information and newsletters to our customers. In this case you have the right to
    object at any time.
  </li>
</ul>
<p>
  Are you a client or partner, job applicant or a visitor of our events and/or office? In this case we will process your personal data on
  the basis of other legal grounds as well:
</p>
<ul>
  <li>on the basis of your prior consent. You can withdraw your consent at any time;</li>
  <li>
    on the basis of our legitimate interest in sending information and newsletters. In this case you have the right to object at any time;
  </li>
  <li>on the basis of the necessity to conclude an employment, internship or cooperation agreement;</li>
  <li>
    on the basis of the execution of the contract negotiated with you or the execution, at your request, of pre-contractual steps taken;
  </li>
  <li>
    on the basis of compliance with legal or regulatory requirements relating to the management of the contractual relationship, invoicing.
  </li>
</ul>
<h2 class="indented">6) How do we store your data?</h2>
<p>
  We securely store your data in our own systems, on trusted third parties’ CRM databases or marketing services. These third parties
  function as data ‘processors’ on our behalf and are carefully selected. They meet high data protection and security standards. We and our
  processors take all the necessary measures to secure your data against any form of unlawful processing.
</p>
<h2 class="indented">7) How long do we store your data?</h2>
<p>
  Your data is stored as long as necessary to achieve the purpose for which the data has been collected. They will be erased as soon as they
  are no longer necessary or if you validly exercise your right to erasure.
</p>
<h2 class="indented">8) What data do we share with third parties?</h2>
<p>
  We do not share your data with any other third parties, except for our processors, for commercial reasons without your consent. Under
  certain circumstances, we can be required to disclose your personal data if necessary to do so by law or in response to valid requests by
  public authorities (e.g. a court or a government agency).
</p>
<h2 class="indented">9) What are your data protection rights?</h2>
<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
<h3>The right to access and copy</h3>
<p>
  You have the right to request Vesalius Health for access to your data. You can also request for copies of your personal data. We may charge you a
  small fee for this service.
</p>
<h3>The right to rectification</h3>
<p>
  You have the right to request that Vesalius Health correct any information you believe is inaccurate. You also have the right to request Vesalius Health to
  complete information you believe is incomplete.
</p>
<h3>Right to erasure (“right to be forgotten”)</h3>
<p>You have the right to request that we erase your data without unreasonable delay.</p>
<h3>Right to restriction of processing</h3>
<p>You have the right to restrict the processing of your data, under certain conditions.</p>
<h3>Right to object</h3>
<p>
  You have the right to object against the processing of your data that is based on our legitimate interests on legitimate grounds. You can,
  regardless of the legal basis upon which the processing is based, always object against the use of your data for direct marketing, after
  which we are obliged to stop processing for these purposes.
</p>
<h3>Right to data portability</h3>
<p>You have the right to receive the data, which you provided to us, in a structured, commonly used and modality-readable format.</p>
<p>You also have the right to request us to directly transfer your data to another organisation.</p>
<h2 class="indented">10) How can you act upon your rights?</h2>
<p>
  If you wish, you can make a request for any of the above mentioned rights. You must send a written request, meaning your request must be
  delivered to us by email or by letter. You can find our contact details at the beginning of this statement.
</p>
<p>Once we receive your request, we will respond as soon as possible. We have one month to respond.</p>
<p>
  If you did not add a proof of identity in your request, we first have to identify you by requesting a copy of your ID. We only need the
  information necessary to identify you. Therefore we will ask you to cross out all irrelevant information on your ID.
</p>
<p>
  The exercise of the above rights is subject to certain limitations, as provided in the GDPR and further specified in other applicable laws
  and regulations.
</p>
<p>You have the right to lodge a complaint with the Belgian Privacy Commission:</p>
<p>
  Commission for the Protection of Privacy, Drukpersstraat 35., 1000 Brussels
  <br />
  Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35
  <br />
  email: <a href="mailto:contact@apd-gba.be" target="_blank">contact&#64;apd-gba.be</a>
</p>
<p>
  These rights can be exercised free of charge by making a clear request to “Vesalius Health". This complaint will state which right you wish to
  invoke with an explanation, a copy of your identity card and how we can contact you.
</p>
<p>Last updated on September 1st, 2024</p>
