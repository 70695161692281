<ng-container [ngSwitch]="cardTemplate">
  <ng-container *ngSwitchCase="'single'" [ngTemplateOutlet]="singleVisitTemplate"></ng-container>
  <ng-container *ngSwitchCase="'multiple'" [ngTemplateOutlet]="multiVisitTemplate"></ng-container>
  <ng-container *ngSwitchCase="'skeleton'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <vh-generic-card-skeleton></vh-generic-card-skeleton>
</ng-template>

<ng-template #singleVisitTemplate>
  <vh-visit-card
    [visit]="visits[0]"
    (click)="onCardClicked()">
  </vh-visit-card>
</ng-template>

<ng-template #multiVisitTemplate>
  <vh-multi-visit-card
    class="clickable"
    (click)="onCardClicked()"
    [slots]="visits">
  </vh-multi-visit-card>
</ng-template>
