import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { NAVIGATION } from '@constants/navigation.constant';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { getWorkListNavigationItems } from '@modules/admin/admin-dashboard/pages/work-lists-page/constants/work-lists-navigation-items.constant';

@Component({
  selector: 'vh-work-lists-page',
  templateUrl: './work-lists-page.component.html',
  styleUrls: ['./work-lists-page.component.scss'],
})
export class WorkListsPageComponent implements OnInit {
  navigationItems: NavigationItem[];
  workListNavigationItems: NavigationItem[];

  constructor(private readonly router: Router) {
    this.navigationItems = getNavigationItems();
    this.workListNavigationItems = getWorkListNavigationItems();
  }

  ngOnInit(): void {
    // Activate the work lists subpage if none was specified when navigating. Otherwise, we end up with an empty screen
    if (this.router.url === NAVIGATION.adminDashboardWorkLists.route) {
      void this.router.navigate([this.workListNavigationItems.find((item: NavigationItem) => item.route === NAVIGATION.adminDashboardWorkLists.rescheduling.route).route]);
    }
  }
}
