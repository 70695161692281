import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.model';
import { getBackOfficeRoles, Role } from '@enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class UserPolicyService {
  constructor(private readonly authenticationService: AuthenticationService) {}

  protected get currentUser(): User {
    return this.authenticationService.currentUser;
  }

  canViewUserDetails(subject: User): boolean {
    if (this.isSelf(subject)) {
      return true;
    }

    return this.currentUser.hasAnyRole(getBackOfficeRoles());
  }

  canEditUserDetails(subject: User): boolean {
    if (this.isSelf(subject)) {
      return true;
    }

    if (this.currentUser.hasRole(Role.ADMIN)) {
      return true;
    }

    return !subject.hasRole(Role.SYSTEM);
  }

  canSendPasswordResetEmail(subject: User): boolean {
    if (this.isSelf(subject)) {
      return true;
    }

    return this.currentUser.hasAnyRole([Role.ADMIN, Role.INSTITUTION_ADMIN]);
  }

  canDetachUserFromInstitution(subject: User): boolean {
    if (this.isSelf(subject)) {
      return false;
    }

    return !subject.hasRole(Role.SYSTEM);
  }

  protected isSelf(subject: User): boolean {
    return subject.id === this.currentUser.id;
  }
}
