import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Bucket } from '@models/bucket.model';

@Component({
  selector: 'vh-bucket-card',
  templateUrl: './bucket-card.component.html',
  styleUrls: ['./bucket-card.component.scss'],
})
export class BucketCardComponent {

  @Input() selected: boolean = false;
  @Input() bucket: Bucket;

  @Output() itemSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  onCardClicked(): void {
    this.selected = !this.selected;
    this.itemSelected.emit(this.selected);
  }
}
