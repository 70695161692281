import { Component, Input } from '@angular/core';
import { DATE_FORMATS } from '@constants/date-formats.constant';
import { Conversation } from '@models/conversation.model';

@Component({
  selector: 'vh-conversations-by-day',
  templateUrl: './conversations-by-day.component.html',
  styleUrls: ['./conversations-by-day.component.scss'],
})
export class ConversationsByDayComponent {
  protected readonly DATE_FORMATS: typeof DATE_FORMATS = DATE_FORMATS;

  @Input() conversations: Conversation[];
  @Input() loading: boolean;

  @Input() clickConversation: (conversation: Conversation, event: Event) => void;
}
