import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { COLORS } from '@constants/colors.constant';
import { User } from '@models/user.model';
import { RegisterComponent } from '@modules/account/components/register/register.component';
import { CONVERSATION_NAV } from '@modules/conversation/constants/conversation-nav.constant';
import { CreateConversationService } from '@modules/conversation/services/create-conversation.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ToastService } from '@services/ui/toast.service';

@Component({
  selector: 'vh-create-conversation-new-patient-page',
  templateUrl: './create-conversation-new-patient-page.component.html',
  styleUrl: './create-conversation-new-patient-page.component.scss',
})
@UntilDestroy()
export class CreateConversationNewPatientPageComponent {
  protected readonly COLORS: typeof COLORS = COLORS;

  @ViewChild('registerComponent') registerComponent: RegisterComponent;

  usingExistentPatient: boolean;

  constructor(
    private readonly router: Router,
    private readonly conversationService: CreateConversationService,
    private readonly toastService: ToastService
  ) {
    this.conversationService.setStep(1);
  }

  register(): void {
    this.registerComponent.register();
  }

  goToNextStep = (user: User): void => {
    this.toastService.showInfo('conversations.create.createPatient.patientCreated').subscribe();
    this.conversationService.user = user;
    void this.router.navigate([CONVERSATION_NAV.create.planning.route]);
  };

  goBack(): void {
    history.back();
  }
}
