import { trigger } from '@angular/animations';
import { popInTransition } from '@modules/shared/core/animations/pop/pop-in.animation';
import { popOutTransition } from '@modules/shared/core/animations/pop/pop-out.animation';

export const popInOutAnimation = trigger(
  'popInOutAnimation',
  [
    popInTransition,
    popOutTransition,
  ]
);
