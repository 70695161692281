<div class="appointments-wrapper mobile-spacing" [vhMarginTop]="16">
  <div>
    <vh-calendar
      #calendarComponent
      [disabledDates]="unavailableDates"
      [onDateClickFunction]="onCalendarDateClick"
      [disablePastDates]="true"
      [selectedDate]="bookingState.filter.from"
      (monthChange)="onMonthChanged($event)">
    </vh-calendar>
    <h4 [vhMarginTop]="24">{{ 'pages.createAppointment.step4.staticPreferredTimeTitle' | translate }}</h4>
    <div [vhMarginTop]="8">
      <vh-timeslot-picker
        [startHour]="timeslotStartHour"
        [endHour]="timeslotEndHour"
        [minuteInterval]="timeslotMinuteInterval"
        [selectedSlot]="bookingState.filter.timeslot"
        (timeslotPick)="onTimeslotClicked($event)">
      </vh-timeslot-picker>
    </div>
    <h4 *ngIf="showLocationPicker" [vhMarginTop]="24">{{ 'pages.createAppointment.step4.staticPreferredLocationTitle' | translate }}</h4>
    <div *ngIf="showLocationPicker && googleMapsIsInitialized$ | async" class="map" [vhMarginTop]="8">
      <google-map #map [width]="'100%'" [height]="'100%'" [center]="mapCenter" [zoom]="mapZoom" (tilesloaded)="onMapTilesLoaded()">
        <ng-container *ngIf="institutionsToShowOnMap?.size !== 0">
          <map-marker
            vhInstitutionTracker
            *ngFor="let institution of institutionsToShowOnMap | keyvalue"
            [position]="institution.value.address.coordinate.latLngLiteral"
            [title]="institution.value.name"
            [trackingData]="institution.value"
            (mapClick)="onInstitutionMapMarkerClicked(institution.value)">
          </map-marker>
        </ng-container>
      </google-map>
    </div>
  </div>
  <div>
    <h1 [vhMarginTop]="16">{{ 'pages.createAppointment.step4.subTitle' | translate }}</h1>
    <div [vhMarginTop]="8" class="flex-row-l-c gap-h-16">
<!--      <vh-tab-menu-->
<!--        [tabLabelTranslationKeys]="tabLabelTranslationKeys"-->
<!--        [selectedTab]="selectedTab"-->
<!--        (selectedTabChange)="onSlotGroupClicked($event)">-->
<!--      </vh-tab-menu>-->
      <vh-message
        *ngIf="bookingState.filter.hasFilter()"
        class="clickable align-end"
        [vhTextColor]="'var(--color-primary)'"
        [text]="'pages.createAppointment.step4.resetFiltersLabel' | translate"
        (messageClick)="resetAvailableAppointmentsFilterData()">
      </vh-message>
    </div>

    <vh-recommendations-list
      [isLoading]="isLoading"
      [listFooterTemplate]="showContactCallToActionCard ? listFooterTemplate : null"
      [recommendations]="recommendations"
      (recommendationClick)="onSlotClicked($event)">
    </vh-recommendations-list>
  </div>
</div>

<ng-template #listFooterTemplate>
  <hr *ngIf="showContactCallToActionCard" [vhMarginTop]="24" />
  <div *ngIf="showContactCallToActionCard" class="card contact-card clickable" [vhMarginTop]="24" (click)="requestContactClick.emit()">
    <h2>{{ 'pages.createAppointment.step4.noPreferredAppointmentSuggestionFoundTitle' | translate }}</h2>
    <p [vhMarginTop]="4">{{ 'pages.createAppointment.step4.noPreferredAppointmentSuggestionFoundText' | translate }}</p>
    <vh-message
      [vhMarginTop]="8"
      [vhTextColor]="'var(--color-primary)'"
      iconRightPath="assets/icons/arrow-right.svg"
      [iconRightFill]="COLORS.primary"
      [iconRightWidth]="14"
      [text]="'pages.createAppointment.step4.contactMeLabel' | translate"
      [textAlignment]="'right'">
    </vh-message>
  </div>
</ng-template>
