import { Occurrence, OccurrenceJson } from '@models/occurrence.model';
import { Room, RoomJson } from '@models/room.model';

export class RoomPlanning {
  constructor(
    private _room: Room,
    private _occurrences: Occurrence[]
  ) {
  }

  get room(): Room {
    return this._room;
  }

  get occurrences(): Occurrence[] {
    return this._occurrences;
  }

  static fromJson(json: RoomPlanningJson): RoomPlanning {
    return new RoomPlanning(
      Room.fromJson(json.room),
      json.planning.map(Occurrence.fromJson)
    );
  }
}

export interface RoomPlanningJson {
  room: RoomJson;
  planning: OccurrenceJson[];
}
