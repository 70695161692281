import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[vhAutoScrollToBottom]',
})
export class AutoScrollToBottomDirective implements AfterViewInit, OnDestroy {
  private mutationObserver: MutationObserver;
  private resizeObserver: ResizeObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const scrollContainer = this.el.nativeElement;

    this.mutationObserver = new MutationObserver((mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.scrollToBottom();

          const messageResizeObserver = new ResizeObserver(() => {
            this.scrollToBottom();
          });

          const latestNode = mutation.addedNodes[mutation.addedNodes.length - 1] as Element;

          if (latestNode.tagName != undefined) {
            messageResizeObserver.observe(latestNode);
          }
        }
      }
    });

    const config = { childList: true };

    if (scrollContainer) {
      this.mutationObserver.observe(scrollContainer, config);
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.scrollToBottom();
    });

    this.resizeObserver.observe(scrollContainer);
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private scrollToBottom(): void {
    const messagesContainer = this.el.nativeElement;
    messagesContainer.scrollTo(0, messagesContainer.scrollHeight);
  }
}
