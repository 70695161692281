import { Injectable } from '@angular/core';
import { REGEX } from '@constants/regex.constant';

@Injectable({
  providedIn: 'root',
})
export class UrlParserService {
  extractUuid(url: string): string | null {
    // Regular expression to match UUID
    const uuidRegex: RegExp = new RegExp(REGEX.uuidV4);
    const match = url.match(uuidRegex);

    // Return the first matched group if available
    return match ? match[0] : null;
  }
}
