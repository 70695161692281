import { Address, AddressJson } from '@models/address.model';
import { InstitutionType, InstitutionTypeJson } from '@models/Institution-type.model';
import { ContactDetail, ContactDetailJson } from '@models/contact-detail.model';
import { Service, ServiceJson } from '@models/service.model';
import { InstitutionSettings, InstitutionSettingsJson } from '@models/institution-settings.model';
import { VisitCancellationReason, VisitCancellationReasonJson } from '@models/visit-cancellation-reason.model';
import { VisitCancellationAction, VisitCancellationActionJson } from '@models/visit-cancellation-action.model';
import { Module, ModuleJson } from '@models/module.model';
import { ModuleKey } from '@enums/module-key.enum';
import { BOOKING_NAV } from '@modules/booking/constants/booking-nav.constant';

export class Institution {
  constructor(
    private _id: string,
    private _name: string,
    private _slug: string,
    private _logoUrl: string,
    private _type: InstitutionType,
    private _parent: Institution,
    private _address: Address,
    private _contactDetails: ContactDetail[],
    private _modalities: Service[],
    private _settings: InstitutionSettings = null,
    private _cancellationReasons: VisitCancellationReason[] = [],
    private _cancellationActions: VisitCancellationAction[] = [],
    private _modules: Module[] = []
  ) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get slug(): string {
    return this._slug;
  }

  get type(): InstitutionType {
    return this._type;
  }

  get parent(): Institution {
    return this._parent;
  }

  get address(): Address {
    return this._address;
  }

  get contactDetails(): ContactDetail[] {
    return this._contactDetails;
  }

  get phones(): ContactDetail[] {
    return this._contactDetails.filter((d: ContactDetail) => d.type === 'PHONE');
  }

  get modalities(): Service[] {
    return this._modalities;
  }

  get settings(): InstitutionSettings {
    return this._settings;
  }

  set settings(value: InstitutionSettings) {
    this._settings = value;
  }

  get logoUrl(): string {
    return this._logoUrl;
  }

  get cancellationReasons(): VisitCancellationReason[] {
    return this._cancellationReasons;
  }

  get cancellationActions(): VisitCancellationAction[] {
    return this._cancellationActions;
  }

  get modules(): Module[] {
    return this._modules;
  }

  get moduleKeys(): ModuleKey[] {
    return this._modules.map((module: Module) => module.key as ModuleKey);
  }

  get reservationLink(): string {
    const path: string = BOOKING_NAV.createBookingViaInstitutionUniqueLink.route
      .replace(`:${BOOKING_NAV.createBookingViaInstitutionUniqueLink.params.institutionSlug}`, this.slug);

    return `${window.location.origin}${path}`;
  }

  hasModule(key: ModuleKey): boolean {
    return this.moduleKeys.includes(key);
  }

  static fromJson(json: InstitutionJson): Institution {
    return json
      ? new Institution(
        json.id,
        json.name,
        json.slug,
        json.logo ?? null,
        json.institution_type ? InstitutionType.fromJson(json.institution_type) : null,
        Institution.fromJson(json.parent),
        Address.fromJson(json.address),
        json.contact_details ? json.contact_details.map((detail: ContactDetailJson) => ContactDetail.fromJson(detail)) : [],
        json.modalities ? json.modalities.map((modality: ServiceJson) => Service.fromJson(modality)) : [],
        json.settings ? InstitutionSettings.fromJson(json.settings) : null,
        json.visit_cancellation_reasons?.map(VisitCancellationReason.fromJson) ?? [],
        json.visit_cancellation_actions?.map(VisitCancellationAction.fromJson) ?? [],
        json.modules?.map(Module.fromJson) ?? []
      )
      : null;
  }

  toJson(): InstitutionJson {
    return {
      id: this._id,
      name: this._name,
      slug: this._slug,
      logo: this._logoUrl,
      institution_type: this._type ? this._type.toJson() : null,
      parent: this._parent?.toJson(),
      address: this._address?.toJson(),
      settings: this._settings?.toJson(),
      modules: this._modules?.map((module: Module) => module.toJson()),
    };
  }
}

export interface InstitutionJson {
  id: string;
  name: string;
  slug: string;
  logo?: string;
  institution_type?: InstitutionTypeJson;
  parent: InstitutionJson;
  address?: AddressJson;
  contact_details?: ContactDetailJson[];
  modalities?: ServiceJson[];
  settings?: InstitutionSettingsJson;
  visit_cancellation_reasons?: VisitCancellationReasonJson[];
  visit_cancellation_actions?: VisitCancellationActionJson[];
  modules?: ModuleJson[];
}
