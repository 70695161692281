export class InstitutionType {
  constructor(private _id: string, private _name: string) {}

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  static fromJson(json: InstitutionTypeJson): InstitutionType {
    return json ? new InstitutionType(json.id, json.name) : null;
  }

  toJson(): InstitutionTypeJson {
    return {
      id: this._id,
      name: this.name,
    };
  }
}

export interface InstitutionTypeJson {
  id: string;
  name: string;
}
