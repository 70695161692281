<vh-wizard-progress-indicator [state]="state"></vh-wizard-progress-indicator>
<section class="flex-col gap-v-16" [vhMarginTop]="16">
  <h1>{{ 'new.onboarding.privacy.title' | translate }}</h1>
  <p>{{ 'new.onboarding.privacy.intro' | translate }}</p>
</section>

<form class="flex-col gap-v-16" [vhMarginTop]="24" [formGroup]="formGroup">
  <vh-checkbox
    [formControlName]="REGISTER_FORM_KEYS.get('accepted_terms')">
    <label
      class="font-regular-18-22 clickable"
      [innerHTML]="'onboarding.credentials.termsAndConditionsLabel' | translate : { url: termsAndConditionsLink}"
      (click)="formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).setValue(!formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).value)">
    </label>
  </vh-checkbox>
  
  <vh-button
    class="align-center"
    iconRightPath="assets/icons/arrow-narrow-right.svg"
    [iconRightStroke]="COLORS.white"
    [isDisabled]="!formGroup.get(REGISTER_FORM_KEYS.get('accepted_terms')).value || isSubmitting"
    [label]="'common.next' | translate"
    (buttonClick)="onNextClicked()">
  </vh-button>
</form>