<vh-content-layout [hasHorizontalSpacing]="true" [hasVerticalSpacing]="true">
  <div class="flex-col">
    <div class="flex-row gap-h-8 align-end">
      <vh-button [label]="'common.save' | translate" (buttonClick)="saveSettings()"
        [isDisabled]="!formGroup.dirty"></vh-button>
      <vh-button [label]="'common.cancel' | translate" (buttonClick)="resetForm()"
        [isDisabled]="!formGroup.dirty"></vh-button>
    </div>

    <vh-horizontal-divider
      [title]="'admin.pages.settings.navigationBar.conversationsLabel' | translate"></vh-horizontal-divider>

    <div [vhMarginTop]="16" class="settings-list" [formGroup]="formGroup">
      <label [vhMarginTop]="4">{{ 'admin.pages.settings.conversations.preferredCommunicationMethod' | translate }}</label>
      <vh-select-input
        [options]="communicationMethods"
        [formControlName]="INSTITUTION_SETTINGS_FORM_KEYS.get('preferred_communication_method')">
      </vh-select-input>
    </div>
  </div>
</vh-content-layout>