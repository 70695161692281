import { Observable, of, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const isTrue = (value: unknown): boolean => {
  return value === true;
};

export const switchToEmptyObservable = (): OperatorFunction<unknown, void> => {
  return switchMap((): Observable<void> => of(void 0));
};
