<div class="card-info font-light-12-14" [ngClass]="{ 'vertical': vertical }">
  <h2>{{ patient?.fullName }}
    <span class="font-light-12-14" *ngIf="patient?.birthdate">{{ patient?.birthdate | dfnsFormat: DATE_FORMATS.clientDate
      }}</span>
    <span class="font-light-12-14" *ngIf="patient?.socialSecurityNumber">{{ patient?.socialSecurityNumber }}</span>
  </h2>
  <div class="user-contact-info gap-h-16">
    <div class="flex-row-l-c">
      <vh-icon
        src="assets/icons/mail.svg"
        width="16"
        height="16"
        [fill]="COLORS.secondary">
      </vh-icon>
      <span>{{ email ?? "-" }}</span>
    </div>
    <div class="flex-row-l-c">
      <vh-icon
        src="assets/icons/phone.svg"
        width="16"
        height="16"
        [fill]="COLORS.secondary">
      </vh-icon>
      <span>{{ phone ?? "-" }}</span>
    </div>
  </div>

  <ng-container *ngIf="doctor">
    <hr />
    <div class="conversation-info">
      <h2>{{ doctor?.fullName }}
        <span class="font-light-12-14">{{ institution?.name }}</span>
      </h2>
      <div *ngIf="questionList" class="flex-row-l-c">
        <vh-icon
          src="assets/icons/chat.svg"
          width="16"
          height="16"
          [fill]="COLORS.secondary">
        </vh-icon>
        <span>{{ questionList }}</span>
      </div>
      <div class="flex-row-l-c">
        <vh-icon
          src="assets/icons/calendar.svg"
          width="16"
          height="16"
          [fill]="COLORS.secondary">
        </vh-icon>
        <span *ngIf="dueDate">{{ dueDate | dfnsFormat: DATE_FORMATS.clientDateTimeComma }}</span>
        <span *ngIf="!dueDate">-</span>
      </div>
      <div class="flex-row-l-c" *ngIf="language">
        <vh-icon
          src="assets/icons/world.svg"
          width="16"
          height="16"
          [stroke]="COLORS.secondary">
        </vh-icon>
        <span>{{ 'common.translatedLanguages.' + language | translate }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="communicationMethod">
    <hr />
    <div class="flex-col">
      <span>{{ 'conversations.summary.communicationMethod' | translate }}</span>

      <div class="flex-row-l-c gap-h-4">
        <h2>{{ communicationMethod }}</h2>

        <vh-button
          *ngIf="communicationMethod === COMMUNICATION_METHODS.MAIL && !isConversationInactive(conversation?.status)"
          [style]="ButtonStyle.ICON_ONLY"
          [matTooltip]="'conversations.details.resendEmail' | translate"
          [iconRightPath]="'assets/icons/mail-send.svg'"
          [alignment]="'center'"
          (buttonClick)="resendEmail()">
        </vh-button>
      </div>

      <div
        *ngIf="
          conversation &&
          communicationMethod === COMMUNICATION_METHODS.WHATSAPP &&
          conversation.status !== ConversationStatus.FINISHED &&
          conversation.status !== ConversationStatus.EXPIRED"
        [vhMarginTop]="8"
        class="flex-row-l-c">
        <vh-button
          *ngIf="activeWhatsapp"
          [label]="'conversations.details.activeWhatsapp' | translate"
          [labelClass]="'font-regular-16-19'"
          [style]="whatsappDisabled ? ButtonStyle.QUATERNARY : ButtonStyle.PRIMARY"
          [alignment]="'center'"
          (buttonClick)="toggleWhatsApp()"
          [isDisabled]="whatsappDisabled">
        </vh-button>
        <vh-button
          *ngIf="!activeWhatsapp && conversation.status !== ConversationStatus.REMOVED"
          [label]="'conversations.details.inactiveWhatsapp' | translate"
          [labelClass]="'font-regular-16-19'"
          [style]="whatsappDisabled ? ButtonStyle.QUATERNARY : ButtonStyle.SECONDARY"
          [alignment]="'center'"
          (buttonClick)="toggleWhatsApp()"
          [isDisabled]="whatsappDisabled">
        </vh-button>
      </div>
    </div>
  </ng-container>

  <div *ngIf="error" @fadeInOut class="flex-col gap-v-4">
    <p class="error-message font-medium-18-22">{{ 'common.errors.' + error | translate }}</p>

    <vh-message
      *ngIf="activeConversationId"
      class="font-medium-16-19 clickable"
      [text]="'conversations.summary.openConversation' | translate"
      iconRightPath="assets/icons/external-link-alt.svg"
      [iconRightFill]="COLORS.secondary"
      [iconRightHeight]="12"
      [iconRightWidth]="12"
      (messageClick)="openConversation()">
    </vh-message>
  </div>
</div>
