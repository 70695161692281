export enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  QUATERNARY,
  QUINARY,
  VALIDATION_ERROR,
  DANGER,
  WARNING,
  SIDE_TOGGLE,
  SKINNY,
  LOW,
  BORDEREDPRIMARY,
  BORDEREDERROR,
  INACTIVE,
  SQUARE,
  INACTIVE_SQUARE,
  ICON_ONLY,
}

export enum ButtonCornerStyle {
  ROUND,
  SUBTLE_ROUND,
  NONE,
  ROUND_LEFT,
  ROUND_RIGHT,
}

export enum ButtonGroupStyle {
  SEPARATED,
  CONNECTED,
  CALENDAR,
}
