import { CreditCard, CreditCardJson } from '@models/credit-card.model';
import { PaymentMethodType } from '@enums/payment-method-type.enum';

export class PaymentMethod {
  constructor(
    private readonly _id: string,
    private readonly _isDefault: boolean,
    private readonly _method: PaymentMethodType,
    private readonly _creditCard: CreditCard | null = null
  ) {
  }

  get id(): string {
    return this._id;
  }

  get method(): string {
    return this._method;
  }

  get creditCard(): CreditCard | null {
    return this._creditCard;
  }

  get isDefault(): boolean {
    return this._isDefault;
  }

  static fromJson(json: PaymentMethodJson): PaymentMethod {
    return new PaymentMethod(
      json.id,
      json.is_default,
      PaymentMethodType[json.method],
      json.credit_card ? CreditCard.fromJson(json.credit_card) : null
    );
  }
}

export interface PaymentMethodJson {
  id: string;
  method: string;
  credit_card: CreditCardJson | null;
  is_default: boolean;
}

