import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { Role } from '@enums/role.enum';
import { arrayWrap } from '@utils/helpers/array.util';
import { redirectToMostRelevantHome } from '@utils/helpers/navigation.util';

export const roleGuard = (roles: Role | Role[]): CanActivateFn => {
  const canAccessRoute = (authenticationService: AuthenticationService): boolean => {
    if (!authenticationService.isLoggedIn()) {
      return false;
    }

    if (authenticationService.currentUser?.hasRole(Role.ADMIN)) {
      return true;
    }

    const allowedRoles: Role[] = arrayWrap(roles);
    const userRoles: Role[] = Array.from(authenticationService.currentUser?.roles ?? []);

    return userRoles.some((role: Role) => allowedRoles.includes(role));
  };

  return (): boolean => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);

    const hasAccess: boolean = canAccessRoute(authenticationService);

    if (!hasAccess) {
      redirectToMostRelevantHome(authenticationService, router);
    }

    return hasAccess;
  };
};
