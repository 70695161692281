import { Directive, ElementRef, Host, HostListener, Input, NgZone } from '@angular/core';
import { AbstractTrackingService } from '@services/tracking/abstract-tracking.service';
import { TrackingEventType } from '@enums/tracking-event-type.enum';
import { VisibilityTrackerDirective } from '@modules/shared/core/directives/visibility-tracker.directive';

@Directive()
export abstract class AbstractTrackerDirective<T> extends VisibilityTrackerDirective {
  @Input() trackingData: T | T[];
  @Input() disableAutomaticClickTracking: boolean;
  @Input() disableAutomaticViewTracking: boolean;

  protected constructor(
    protected readonly el: ElementRef<HTMLElement>,
    protected readonly ngZone: NgZone,
    @Host() protected readonly trackingService: AbstractTrackingService<T>
  ) {
    super(el, ngZone);
  }

  @HostListener('click') onMouseClick(): void {
    this.track(TrackingEventType.CLICKED);
  }

  @HostListener('mapClick') onMapMarkerClicked(): void {
    this.track(TrackingEventType.CLICKED);
  }

  @HostListener('mapMouseover') onMapMarkerMouseOver(): void {
    this.track(TrackingEventType.VIEWED);
  }

  track(event: TrackingEventType): void {
    if (!this.shouldTrack(event)) {
      return;
    }

    if (Array.isArray(this.trackingData)) {
      this.bulkTrack(event);

      return;
    }

    const alreadySent = this.trackingService.hasTrackedEventAsViewed(this.trackingData);
    if (event === TrackingEventType.VIEWED && alreadySent) {
      return;
    }

    this.trackingService.track(event, this.trackingData);
  }

  bulkTrack(event: TrackingEventType): void {
    if (!this.shouldTrack(event)) {
      return;
    }

    const payload = event === TrackingEventType.VIEWED
      ? (this.trackingData as T[]).filter((item: T) => !this.trackingService.hasTrackedEventAsViewed(item))
      : this.trackingData as T[];

    if (payload.length === 0) {
      return;
    }

    this.trackingService.track(event, payload);
  }

  protected shouldTrack(event: TrackingEventType): boolean {
    return (!!this.trackingData || Array.isArray(this.trackingData) && this.trackingData.length !== 0) &&
      !(event === TrackingEventType.CLICKED && this.disableAutomaticClickTracking) &&
      !(event === TrackingEventType.VIEWED && this.disableAutomaticViewTracking);
  }

  protected onVisible(target: HTMLElement): void {
    super.onVisible(target);

    this.track(TrackingEventType.VIEWED);
  }
}
