import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationItem } from '@interfaces/navigation-item.interface';
import { Room } from '@models/room.model';
import { RoomService } from '@services/room.service';
import { NAVIGATION } from '@constants/navigation.constant';
import { NavigationObject } from '@interfaces/navigation-object.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, EventType, NavigationStart, Router } from '@angular/router';
import { ToastService } from '@services/ui/toast.service';
import { COLORS } from '@constants/colors.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getNavigationItems } from '@modules/admin/admin-dashboard/constants/navigation-items.constant';
import { Role } from '@enums/role.enum';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ExportPlanningDialogComponent } from '@modules/settings/dialogs/export-calendar-dialog/export-planning-dialog.component';
import { EventsCalendarMode } from '@modules/shared/calendar/components/events-calendar/events-calendar.component';

@Component({
  selector: 'vh-planning-page',
  templateUrl: './planning-page.component.html',
  styleUrls: ['./planning-page.component.scss'],
})
@UntilDestroy()
export class PlanningPageComponent implements OnInit, OnDestroy {
  protected readonly Role: typeof Role = Role;
  protected readonly NAVIGATION: typeof NAVIGATION = NAVIGATION;
  protected readonly COLORS: typeof COLORS = COLORS;

  selectedRoom: Room;
  navigationItems: NavigationItem[];
  events: Event[];
  weekStartDate: Date;
  errorMessageTranslationKey: string;
  currentUser: User;

  getRoomsSubscription: Subscription;
  private navigationSubscription: Subscription;

  supportedModes: EventsCalendarMode[] = ['week'];

  constructor(
    private readonly roomService: RoomService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly authenticationService: AuthenticationService,
    private readonly dialogService: MatDialog
  ) {
    this.navigationItems = getNavigationItems();
  }

  get rooms(): Room[] {
    return this.roomService.roomsOfInstitution ?? [];
  }

  ngOnInit(): void {
    this.loadRooms();
    this.calculateFirstDay();
    this.subscribeToRouterEvents();
    this.currentUser = this.authenticationService.currentUser;
  }

  ngOnDestroy(): void {
    this.navigationSubscription?.unsubscribe();
  }

  onCreateRoomClicked(): void {
    void this.router.navigate([NAVIGATION.adminDashboardRoomsCreate.route]);
  }

  loadRooms(forceLoad: boolean = false): void {
    this.getRoomsSubscription?.unsubscribe();
    this.getRoomsSubscription = this.roomService.getRoomsOfInstitution$(forceLoad)
      .pipe(untilDestroyed(this))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async() => {
        this.getRoomsSubscription = null;

        if (this.roomService.roomsOfInstitution.length === 0) {
          await this.router.navigate([NAVIGATION.adminDashboardSettings.rooms.route], { relativeTo: this.activatedRoute });

          this.toastService.showInfo('admin.pages.slotsOverview.slotsPage.noRoomsCreatedYetMessage', 'common.ok', { horizontalPosition: 'center', verticalPosition: 'top' })
            .subscribe();

          return;
        }

        this.selectRoom(this.getRoomFromRoute());
      });
  }

  selectRoom(room?: Room): void {
    if (!room || this.selectedRoom?.id === room.id) {
      return;
    }

    const route = NAVIGATION.adminDashboardSettings.slots.route
      .replace(`:${NAVIGATION.adminDashboardSettings.slots.params.roomId}`, room.id);

    void this.router.navigate([route], { relativeTo: this.activatedRoute });

    this.selectedRoom = room;
  }

  exportCalenderClick(): void {
    this.dialogService.open(ExportPlanningDialogComponent, {
      data: { room: this.selectedRoom },
      panelClass: 'dialog-size-tiny',
    });
  }

  navigateToSelectedRoomSettings(room: Room): void {
    const navigationObject: NavigationObject = NAVIGATION.adminDashboardRoomsSettings;
    void this.router.navigate([navigationObject.route.replace(`:${navigationObject.params.roomId}`, room.id)], {
      state: {
        room: room,
      },
    });
  }

  /**
   * This method calculates the date of this week's monday to have te calendar-component start on.
   */
  calculateFirstDay(): void {
    const currentDate = new Date();
    const first = currentDate.getDate() - (currentDate.getDay() - 1);
    this.weekStartDate = new Date(currentDate.setDate(first));
  }

  addWeek(): void {
    const newDate = new Date();
    newDate.setMonth(this.weekStartDate.getMonth());
    newDate.setFullYear(this.weekStartDate.getFullYear());
    newDate.setDate(this.weekStartDate.getDate() + 7);
    this.weekStartDate = newDate;
  }

  subtractWeek(): void {
    const newDate = new Date();
    newDate.setMonth(this.weekStartDate.getMonth());
    newDate.setFullYear(this.weekStartDate.getFullYear());
    newDate.setDate(this.weekStartDate.getDate() - 7);
    this.weekStartDate = newDate;
  }

  searchWeek(newDate: Date): void {
    if (!newDate) {
      return;
    }

    const mondayOfNewDate: number = newDate.getDate() - (newDate.getDay() - 1);
    this.weekStartDate = new Date(newDate.setDate(mondayOfNewDate));
  }

  private getRoomFromRoute(): Room {
    const roomId = this.activatedRoute.snapshot.params.roomId;

    return roomId
      ? this.roomService.roomsOfInstitution.find((r: Room) => r.id === roomId) ?? this.roomService.roomsOfInstitution[0]
      : this.roomService.roomsOfInstitution[0];
  }

  private subscribeToRouterEvents(): void {
    this.navigationSubscription?.unsubscribe();
    this.navigationSubscription = this.router.events.subscribe((event: NavigationStart) => {
      // Listen for when we return from the room edit view, so we can reload the rooms
      if (event.navigationTrigger === 'imperative' && event.type === EventType.NavigationStart) {
        this.loadRooms(true);
      }
    });
  }
}
